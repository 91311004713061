import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Box from '@material-ui/core/Box';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import CustomDialog from 'components/common/CustomDialog';
import { translate } from 'lib/intl';
import { FEATURE_FLAG_SCOPE_TYPES } from 'lib/configs/superAdmin';
import { featureFlagActions } from 'rdx/actions/featureFlags';
import { orgActions } from 'rdx/actions/orgs';

export default function DisableFeatureFlagModal({
  open,
  flagName,
  flagScope,
  orgIds,
  handleClose,
  setOpen,
  onApiResponse,
}) {
  const dispatch = useDispatch();

  const { organizations } = useSelector((state) => ({
    organizations: state.orgList?.orgs || [],
  }));

  function fetchOrgs() {
    // TODO: update with elastic orgs endpoint when available for type ahead
    return orgActions.fetchOrgs({ limit: 10000 });
  }

  React.useEffect(() => {
    dispatch(fetchOrgs());
  }, []);

  const [scope, setScope] = React.useState(flagScope);
  const [ids, setIds] = React.useState(orgIds);
  const selectedOrgs = ids.map((id) =>
    organizations.find((org) => org.orgId === id)
  );

  const handleEnable = async () => {
    try {
      const response = await dispatch(
        featureFlagActions.updateFeatureFlag({
          flagName,
          flagValue: true,
          orgIds: ids,
          flagScope: scope,
        })
      );
      onApiResponse(response);
    } catch (error) {
      onApiResponse(error);
    } finally {
      handleClose();
    }
  };
  const handleChange = (event) => {
    setScope(event.target.value);
  };
  const handleOrgIdsUpdate = (event, values) => {
    const updatedIds = values.map((value) => value.orgId);
    setIds(updatedIds);
  };
  return (
    <CustomDialog
      actionButtonLabel={translate('SAVE')}
      open={open}
      onClose={handleClose}
      handleActionButton={handleEnable}
      dialogHeader={translate('FEATURE_FLAGS_ENABLE')}
      setOpen={setOpen}
    >
      <Box display="flex" flexDirection="column" width={500}>
        <Box display="flex" pb={2}>
          <Typography variant="body2">
            {translate('FEATURE_FLAGS_ENABLE_FLAG_LABEL')}
          </Typography>
          <Box>&#58;&nbsp;</Box>
          <Typography variant="body1">{flagName}</Typography>
        </Box>
        <FormControlLabel
          value={FEATURE_FLAG_SCOPE_TYPES.GLOBAL}
          control={
            <Radio
              onChange={handleChange}
              checked={scope === FEATURE_FLAG_SCOPE_TYPES.GLOBAL}
            />
          }
          label={translate('FEATURE_FLAGS_ENABLE_ALL')}
        />
        <FormControlLabel
          value={FEATURE_FLAG_SCOPE_TYPES.ORG}
          control={
            <Radio
              onChange={handleChange}
              checked={scope === FEATURE_FLAG_SCOPE_TYPES.ORG}
            />
          }
          label={translate('FEATURE_FLAGS_ENABLE_LIST')}
        />
        {scope === FEATURE_FLAG_SCOPE_TYPES.ORG && (
          <Box mt={2}>
            <Autocomplete
              id="combo-box-demo"
              options={organizations}
              onChange={handleOrgIdsUpdate}
              value={selectedOrgs}
              getOptionLabel={(option) => option.displayName}
              multiple
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={translate('FEATURE_FLAGS_SEARCH_ORGS')}
                  variant="outlined"
                />
              )}
            />
          </Box>
        )}
      </Box>
    </CustomDialog>
  );
}

DisableFeatureFlagModal.defaultProps = {
  orgIds: [],
};
DisableFeatureFlagModal.propTypes = {
  open: PropTypes.bool.isRequired,
  orgIds: PropTypes.arrayOf(PropTypes.string),
  flagName: PropTypes.string.isRequired,
  flagScope: PropTypes.string.isRequired,
  handleClose: PropTypes.func.isRequired,
  onApiResponse: PropTypes.func.isRequired,
  setOpen: PropTypes.func.isRequired,
};
