import { upperCase } from 'voca';

// a helper functions that returns initials
const getInitials = (value, isUser = true) => {
  const parts = value?.split(' ');
  const partsLength = parts?.length;

  if (partsLength) {
    const partsToJoin = [parts[0]];

    if (isUser && partsLength > 1) {
      partsToJoin.push(parts[partsLength - 1] || '');
    }

    return partsToJoin.map((w) => upperCase(w?.charAt(0)))?.join('') || '';
  }

  return '';
};

export { getInitials };
