import axios from 'axios';
import env from '@beam-australia/react-env';
import { camelCaseKeys, snakeCaseKeys, upperCase } from 'lib/utils';
import { INSTIL_AUTH_HEADER, INSTIL_CLIENT_SCOPE_HEADER } from 'lib/constants';
import {
  AUTH_USER_ID_TOKEN,
  AUTH_X_INSTIL,
  CORRELATION_ID,
  OWNER_ORG_ID_PUB,
  USER_ID_PUB,
  USER_NAME_PUB,
} from 'lib/constants/localStorageKeys';
import { userService } from 'services/user';
import { translate } from 'lib/intl';

const refreshRetryLimit = 5;
const retryRequestTimeout = 2000;
const setTimeoutPromise = (ms) =>
  new Promise((resolve) => setTimeout(resolve, ms));
const handleData = (response) => {
  if (!response) return null;

  const { data } = response;

  return data;
};
const handleErrors = (error) => {
  let formattedError;
  let returnError = error;

  if (error.response && error.response.data) {
    returnError = error.response.data;
  }

  try {
    formattedError = error.toJSON();
  } catch (e) {
    // eslint-disable-next-line no-console
    console.warn('Failed to format JSON', error);
    formattedError = translate('GENERIC_ERROR');
  }

  // eslint-disable-next-line no-console
  console.warn(formattedError);

  return returnError;
};
const formatAuthBearerHeader = (accessToken) => `Bearer ${accessToken}`;

// Builds a base-64 encoded string from current user id and org id to provide the API with context
const buildClientScopeHeader = (userId, orgId, csId, userName) =>
  btoa(
    JSON.stringify({
      user_id: userId,
      org_id: orgId,
      cs_id: csId,
      user_name: userName,
    })
  );

axios.interceptors.response.use(null, (error) => {
  // Do not retry cases from login
  if (error.config && error.config.isFromLogin) {
    return Promise.reject(error);
  }

  // Authorized but forbidden, redirect to homepage
  /*
  if (error.config && error.response && error.response.status === 403) {
    window.location.replace(window.location.origin);

    return Promise.reject(error);
  }
  */

  if (error.config && error.response && error.response.status === 401) {
    return userService
      .refreshUserSession()
      .then((tokenResponse) => {
        const { token } = tokenResponse;

        if (token) {
          const { config } = error;
          const { attempts } = config;
          config.headers.Authorization = formatAuthBearerHeader(token);
          config.attempts = attempts ? attempts + 1 : 1;

          if (config.attempts >= refreshRetryLimit) {
            // Log user out if too many failed retries
            // eslint-disable-next-line no-console
            console.warn('Too many failed attempts, logging user out.');
            return userService
              .logout()
              .then((logoutError) => Promise.reject(logoutError));
          }

          return setTimeoutPromise(retryRequestTimeout).then(() =>
            axios.request(config)
          );
        }

        return Promise.reject(error);
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.warn('Refresh user session attempt failed', err);
      });
  }

  return Promise.reject(error);
});

const createApiRequest = ({
  config,
  data,
  method = 'post',
  postProcessMethod = camelCaseKeys,
  preProcessMethod = snakeCaseKeys,
  path,
  useAuth = true,
  throwErrors = false,
} = {}) => {
  if (!method || !path) {
    throw new Error('Invalid URL or Method');
  }

  const requestConfig = { ...config };

  const authToken = localStorage.getItem(AUTH_USER_ID_TOKEN);
  const instilXAuthToken = localStorage.getItem(AUTH_X_INSTIL);
  const headers = {};

  // Default content type header
  // TODO if API requests are integrated that do not required application/json add as a parameter
  headers['Content-Type'] = 'application/json';

  if (useAuth && authToken) {
    headers.Authorization = formatAuthBearerHeader(authToken);

    const csId = localStorage.getItem(CORRELATION_ID);
    const userId = localStorage.getItem(USER_ID_PUB);
    const orgId = localStorage.getItem(OWNER_ORG_ID_PUB);
    const userName = localStorage.getItem(USER_NAME_PUB);

    // Append the client scope header
    if (csId && userId && orgId && userName) {
      headers[INSTIL_CLIENT_SCOPE_HEADER.toLowerCase()] =
        buildClientScopeHeader(userId, orgId, csId, userName);
    }
  }

  // This header is required on all API requests for user informational purposes
  if (instilXAuthToken) {
    headers[INSTIL_AUTH_HEADER.toLowerCase()] = instilXAuthToken;
  }

  if (typeof requestConfig === 'object') {
    requestConfig.headers = headers;
  }

  const isProd = process.env.NODE_ENV === 'production';
  const apiPath = isProd
    ? `${env('API_URL')}${env('API_PREFIX')}/${path}`
    : `${env('API_PREFIX')}/${path}`;
  const requestParams = [apiPath, requestConfig];

  if (['POST', 'PATCH', 'PUT', 'DELETE'].includes(upperCase(method)) && data) {
    requestParams.splice(
      1,
      0,
      preProcessMethod ? preProcessMethod(data) : data
    );
  }

  return axios[method](...requestParams)
    .then((result) => postProcessMethod(handleData(result)))
    .catch((error) => {
      handleErrors(error);

      // Error API data response if exists
      if (throwErrors) {
        throw camelCaseKeys(error?.response?.data);
      }
    });
};

export { createApiRequest, handleData, handleErrors, buildClientScopeHeader };
