import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/styles/makeStyles';
import { CustomButtonType } from 'lib/utils';
import { CUSTOM_BUTTON_TYPES, EMPTY_FUNC } from 'lib/constants/common';

const useStyles = makeStyles((theme) => ({
  primary: {
    backgroundColor: theme.palette.primaryButton.main,
    color: theme.palette.primaryButton.contrastText,
    '&:hover': {
      backgroundColor: theme.palette.primaryButton.light,
      color: theme.palette.primaryButton.contrastText,
    },
    '&:focus': {
      backgroundColor: theme.palette.primaryButton.light,
      color: theme.palette.primaryButton.contrastText,
    },
    '&:active': {
      backgroundColor: theme.palette.primaryButton.dark,
      color: theme.palette.primaryButton.contrastText,
    },
  },
  secondary: {
    backgroundColor: theme.palette.secondaryButton.main,
    color: theme.palette.secondaryButton.contrastText,
    '&:hover': {
      backgroundColor: theme.palette.secondaryButton.light,
      color: theme.palette.secondaryButton.contrastText,
    },
    '&:focus': {
      backgroundColor: theme.palette.secondaryButton.light,
      color: theme.palette.secondaryButton.contrastText,
    },
    '&:active': {
      backgroundColor: theme.palette.secondaryButton.dark,
      color: theme.palette.secondaryButton.contrastText,
    },
  },
  link: {
    color: theme.palette.primaryLink.main,
    '&:hover': {
      backgroundColor: 'transparent',
      color: theme.palette.primaryLink.light,
    },
    '&:focus': {
      color: theme.palette.primaryLink.light,
    },
    '&:active': {
      color: theme.palette.primaryLink.dark,
    },
  },
  error: {
    color: theme.palette.error.main,
    '&:hover': {
      color: theme.palette.error.light,
    },
    '&:focus': {
      color: theme.palette.error.light,
    },
    '&:active': {
      color: theme.palette.error.main,
    },
  },
  customButton: {
    borderRadius: '8px',
    boxShadow: 'none',
    height: '56px',
    display: 'flex',
    alignItems: 'center',
    textTransform: 'none',
    minWidth: '100px',
    padding: '0 32px',
    '&:hover': {
      boxShadow: 'none',
    },
    [theme.breakpoints.down('xs')]: {
      minWidth: 'auto',
      padding: '0 16px',
    },
  },
}));

const CustomButton = ({
  buttonType,
  className,
  dataTestId,
  label,
  onClick,
  prefix,
  ...rest
}) => {
  const styles = useStyles();
  const variant = [
    CUSTOM_BUTTON_TYPES.LINK,
    CUSTOM_BUTTON_TYPES.ERROR,
  ].includes(buttonType)
    ? 'text'
    : 'contained';

  return (
    <Button
      className={classnames(`${styles.customButton} ${styles[buttonType]}`, {
        [className]: !!className,
      })}
      data-testid={dataTestId}
      onClick={onClick}
      variant={variant}
      {...rest}
    >
      {prefix && <Box mr={1}>{prefix}</Box>}
      <Typography variant="body1">{label}</Typography>
    </Button>
  );
};

CustomButton.propTypes = {
  buttonType: CustomButtonType,
  dataTestId: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  onClick: PropTypes.func,
  prefix: PropTypes.node,
  ...Button.propTypes,
};

CustomButton.defaultProps = {
  buttonType: 'primary',
  dataTestId: 'custom-button',
  prefix: null,
  onClick: EMPTY_FUNC,
  ...Button.defaultProps,
};

export { CustomButton };
