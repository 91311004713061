import React from 'react';
import PropTypes from 'prop-types';
import Collapse from '@material-ui/core/Collapse';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import { useFeature } from 'flagged';
import { startOfYear } from 'date-fns';
import { FEATURE_FLAG_ADVANCED_REPORTS } from 'lib/constants';
import { ControlledDateInput, ControlledSelect } from 'components/common/form';
import { translate } from 'lib/intl';
import { DateType } from 'lib/types/ReportFilterPropType';

export const startOfYtdYear = startOfYear(new Date());
export const today = new Date();

export default function ReportDateFilter({
  control,
  errors,
  setValue,
  trigger,
  watch,
}) {
  const hasAdvancedFiltering = useFeature(FEATURE_FLAG_ADVANCED_REPORTS);
  const watchDateType = watch('dateType');
  const watchStartDate = watch('startDate');
  const watchEndDate = watch('endDate');

  const validateCustomDates = React.useCallback(() => {
    if (!watchStartDate || !watchEndDate) {
      return;
    }

    // eslint-disable-next-line consistent-return
    return watchEndDate < watchStartDate
      ? translate('ERRORS_INVALID_START_DATE_BEFORE_END_DATE')
      : undefined;
  }, [watchStartDate, watchEndDate]);

  const isCustomDateType = watchDateType === DateType.CUSTOM_DATES;
  const isCalendarYtdType = watchDateType === DateType.CALENDAR_YTD;

  React.useEffect(() => {
    // Reset the start and end date values when date type value changes to something
    // other than custom dates or calendar YTD
    if (isCustomDateType && watchStartDate && watchEndDate) {
      trigger(['startDate', 'endDate']);
    } else if (isCalendarYtdType) {
      setValue('startDate', startOfYtdYear);
      setValue('endDate', today);
    } else {
      setValue('startDate', null);
      setValue('endDate', null);
    }
  }, [isCustomDateType, watchEndDate, watchStartDate]);

  // Only add these rules if custom date type. Or else the validation errors will
  // be reported when they are hidden from the user.
  const endDateRules = isCustomDateType
    ? {
        required: translate('REQUIRED'),
      }
    : null;

  const startDateRules = isCustomDateType
    ? {
        ...endDateRules,
        validate: {
          startBeforeEnd: validateCustomDates,
        },
      }
    : null;

  return (
    <>
      <FormControl fullWidth>
        <InputLabel id="report-filter-date-type">
          {translate('REPORTS_FILTER_DATE_TYPE_LABEL')}
        </InputLabel>

        <ControlledSelect
          autoWidth
          control={control}
          defaultValue={watchDateType}
          id="report-filter-date-type"
          label={translate('REPORTS_FILTER_DATE_TYPE_LABEL')}
          name="dateType"
        >
          {hasAdvancedFiltering ? (
            <MenuItem value={DateType.FISCAL_YTD}>
              {translate('REPORTS_FILTER_DATE_TYPE_FISCAL_YTD')}
            </MenuItem>
          ) : null}
          <MenuItem value={DateType.CALENDAR_YTD}>
            {translate('REPORTS_FILTER_DATE_TYPE_CALENDAR_YTD')}
          </MenuItem>
          <MenuItem value={DateType.CUSTOM_DATES}>
            {translate('REPORTS_FILTER_DATE_TYPE_CUSTOM_DATES')}
          </MenuItem>
        </ControlledSelect>
      </FormControl>

      <Collapse in={watchDateType === DateType.CUSTOM_DATES}>
        <Grid item>
          <ControlledDateInput
            control={control}
            disableToolbar
            fullWidth
            id="report-filter-date-picker-start-date"
            label={translate('REPORTS_FILTER_START_DATE_LABEL')}
            margin="none"
            name="startDate"
            rules={startDateRules}
            value={watchStartDate}
            variant="inline"
          />
        </Grid>

        <FormHelperText error={!!errors?.startDate?.message}>
          {errors?.startDate?.message}
        </FormHelperText>

        <Grid item>
          <ControlledDateInput
            control={control}
            disableToolbar
            fullWidth
            id="report-filter-date-picker-end-date"
            label={translate('REPORTS_FILTER_END_DATE_LABEL')}
            margin="none"
            name="endDate"
            rules={endDateRules}
            value={watchEndDate}
            variant="inline"
          />
        </Grid>
        <FormHelperText error={!!errors?.endDate?.message}>
          {errors?.endDate?.message}
        </FormHelperText>
      </Collapse>
    </>
  );
}

ReportDateFilter.propTypes = {
  control: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  setValue: PropTypes.func.isRequired,
  trigger: PropTypes.func.isRequired,
  watch: PropTypes.func.isRequired,
};
