import React from 'react';
import { func, string, oneOf } from 'prop-types';
import { Box, Typography, makeStyles } from '@material-ui/core';
import { LeftArrowIcon } from 'assets/images';
import { translate } from 'lib/intl';
import { useBackButton } from './hooks';

const useStyles = makeStyles((theme) => {
  const BACK_BUTTON_COLOR_ENUMS = {
    BLACK: 'black',
    PRIMARY: 'primary',
  };
  function determineColor(color) {
    let colorFromTheme;
    switch (color) {
      case BACK_BUTTON_COLOR_ENUMS.PRIMARY:
        colorFromTheme = theme.palette.primary.main;
        break;
      case BACK_BUTTON_COLOR_ENUMS.BLACK:
      default:
        colorFromTheme = theme.palette.text.primary;
    }
    return colorFromTheme;
  }
  return {
    backButtonContainer: {
      alignItems: 'center',
      cursor: 'pointer',
      display: 'flex',
      flexDirection: 'row',
      justifyItems: 'center',
    },
    backLabel: {
      color: (props) => determineColor(props.color),
      paddingLeft: theme.spacing(1),
    },
    leftArrowIcon: {
      '& path': { fill: (props) => determineColor(props.color) },
    },
  };
});

const BackButton = (props) => {
  const { label, path, callback } = props;
  const { onBackClick } = useBackButton({ path, callback });
  const styles = useStyles(props);

  return (
    <Box
      className={styles.backButtonContainer}
      data-testid="back-button"
      onClick={onBackClick}
    >
      <LeftArrowIcon className={styles.leftArrowIcon} />
      <Typography className={styles.backLabel} variant="caption">
        {label}
      </Typography>
    </Box>
  );
};

BackButton.propTypes = {
  path: string,
  label: string,
  callback: func,
  // eslint-disable-next-line react/no-unused-prop-types
  color: oneOf(['black', 'primary']),
};

BackButton.defaultProps = {
  path: '',
  label: translate('GO_BACK'),
  callback: null,
  color: 'black',
};

export { BackButton };
