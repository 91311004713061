import { useCallback } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { campaignFeedActions, feedActions } from 'rdx/actions';

const useHomeFeedRefresh = () => {
  const dispatch = useDispatch();

  const { ownerOrgId } = useSelector((state) => ({
    ownerOrgId: state.authentication?.ownerOrgId,
  }));

  const refreshFeed = () => dispatch(feedActions.refreshFeed({ ownerOrgId }));

  return {
    refreshFeed,
  };
};

const useCampaignFeed = (campaignId) => {
  const history = useHistory();
  const { id: activeCampaignId, historyId: activeHistoryItemId } = useParams();
  const dispatch = useDispatch();

  const { feed, ownerOrgId } = useSelector((state) => ({
    feed: state.campaignFeed,
    ownerOrgId: state.authentication?.ownerOrgId,
  }));

  const getFeedPage = useCallback(
    (pageFrom) =>
      dispatch(
        campaignFeedActions.getCampaignFeed(ownerOrgId, campaignId, pageFrom)
      ),
    [ownerOrgId, campaignId]
  );

  const setActiveFeedItem = (id, historyId) =>
    history.push(`/campaigns/${id}/history/${historyId}`);

  return {
    feed,
    getFeedPage,
    setActiveFeedItem,
    activeCampaignId,
    activeFeedItemId: activeHistoryItemId,
  };
};

// TODO(jon): Not currenlty being used by anything. Candidate for deletion.
const useCampaignFeedRefresh = () => {
  const dispatch = useDispatch();

  const { ownerOrgId } = useSelector((state) => ({
    ownerOrgId: state.authentication?.ownerOrgId,
  }));

  const refreshCampaignFeed = (campaignId) =>
    dispatch(campaignFeedActions.refreshCampaignFeed(ownerOrgId, campaignId));

  return {
    refreshCampaignFeed,
  };
};

export { useHomeFeedRefresh, useCampaignFeed, useCampaignFeedRefresh };
