import { createAction } from 'lib/utils';
import service from 'services/constituentsSearchService';

export const fetchTypes = {
  failure: 'CONSTITUENT_SEARCH_FAILURE',
  request: 'CONSTITUENT_SEARCH_REQUEST',
  success: 'CONSTITUENT_SEARCH_SUCCESS',
};

export const refreshTypes = {
  ...fetchTypes,
  success: 'CONSTITUENT_SEARCH_REFRESH_SUCCESS',
};

const fetch =
  ({ pageFrom = 0, ...rest } = {}) =>
  async (dispatch, getState) => {
    try {
      const { ownerOrgId } = getState().authentication;
      dispatch(createAction({ type: fetchTypes.request }));

      const response = await service.fetch({
        ownerOrgId,
        pageFrom,
        ...rest,
      });

      const data = {
        feedItems: response?.data?.feedItems,
      };

      dispatch(
        createAction({
          type: fetchTypes.success,
          data,
        })
      );

      return response;
    } catch (err) {
      dispatch(
        createAction({
          type: fetchTypes.failure,
          err,
        })
      );

      return err;
    }
  };

const refresh = (constituentId) => async (dispatch, getState) => {
  try {
    const { ownerOrgId } = getState().authentication;

    dispatch(
      createAction({
        type: refreshTypes.request,
        data: { constituentId },
      })
    );

    const response = await service.fetch({
      ownerOrgId,
      constituentId,
      pageFrom: 0,
    });

    dispatch(
      createAction({
        type: refreshTypes.refreshSuccess,
        data: { constituentId, ...response.data },
      })
    );
  } catch (error) {
    dispatch(createAction({ type: refreshTypes.failure, error }));
  }
};

export default {
  fetch,
  fetchTypes,
  refresh,
  refreshTypes,
};
