export const REPORTS_DAILY_TIME_PERIOD = 'daily';
export const REPORTS_WEEKLY_TIME_PERIOD = 'weekly';
export const REPORTS_MONTHLY_TIME_PERIOD = 'monthly';

export const REPORTS_X_AXIS_DAILY_FORMAT = 'MMM d';
export const REPORTS_X_AXIS_WEEKLY_FORMAT = 'ccc, MMM d';
export const REPORTS_X_AXIS_MONTHLY_FORMAT = 'MMM';
export const REPORTS_X_AXIS_MONTHLY_FULL_FORMAT = 'MMMM';

export default {
  REPORTS_DAILY_TIME_PERIOD,
  REPORTS_MONTHLY_TIME_PERIOD,
  REPORTS_WEEKLY_TIME_PERIOD,
  REPORTS_X_AXIS_DAILY_FORMAT,
  REPORTS_X_AXIS_MONTHLY_FORMAT,
  REPORTS_X_AXIS_MONTHLY_FULL_FORMAT,
  REPORTS_X_AXIS_WEEKLY_FORMAT,
};
