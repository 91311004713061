import { arrayOf, node, oneOf } from 'prop-types';
import { values } from 'rambdax';
import { USER_VIEW_ENUMS } from 'lib/constants';
import { hasIntersection } from 'lib/utils/hasIntersection';
import { useCurrentUser } from 'lib/hooks/user-data';

const ShowForViews = ({ children, fallback, showFor }) => {
  const { views, isSuperAdmin } = useCurrentUser(true);

  if (hasIntersection(showFor, views)) {
    return children;
  }

  if (isSuperAdmin && showFor.includes(USER_VIEW_ENUMS.SUPER_ADMIN)) {
    return children;
  }

  return fallback;
};

ShowForViews.propTypes = {
  children: node.isRequired,
  fallback: node,
  showFor: arrayOf(oneOf(values(USER_VIEW_ENUMS))).isRequired,
};

ShowForViews.defaultProps = {
  fallback: null,
};

export { ShowForViews };
