import React from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import { translate } from 'lib/intl';
import {
  Avatar,
  BackButton,
  LastInteraction,
  Loader,
  ViewFieldsColumn,
} from 'components';
import { getFullName, getInitials, isMobileView } from 'lib/utils';
import { useDonorConfig } from 'lib/hooks';
import { EMPTY_FUNC } from 'lib/constants';
import { AUTH_ROUTES } from 'lib/constants/routes';
import {
  USER_DONOR_INFO_SECTION_CONFIG,
  ORG_DONOR_INFO_SECTION_CONFIG,
} from 'lib/configs';
import { isPersonConstituentType } from 'lib/utils/constituentUtils';
import { ConstituentType, ConstituentTypes } from './constituentTypes';
import ConstituentInfoAssigned from './ConstituentInfoAssigned';
import ConstituentInfoAttributes from './ConstituentInfoAttributes';
import ConstituentInfoStage from './ConstituentInfoStage';
import ConstituentLabelChips from './ConstituentLabelChips';
import { CONSTITUENT_NOTES_CONFIG } from './constituentFormConfigs';

const AVATAR_MOB_SIZE = '64px';
const AVATAR_DESKTOP_SIZE = '90px';

// Info Section for users and orgs (displayed on the left in the desktop mode)
const ConstituentInfoSection = ({
  data,
  dataFetching,
  dataLoaded,
  constituentType,
  isEditable,
  isNewConstituent,
  openDonorForm,
  closeDonorForm,
}) => {
  const { avatar, displayName, orgName = '', lastInteractionDate } = data;

  const name = React.useMemo(
    () => displayName || translate('CONSTITUENT_NAME_LABEL'),
    [displayName]
  );

  const isMobile = isMobileView();

  const initials = React.useMemo(
    () =>
      getInitials(orgName || getFullName(data), isPersonConstituentType(data)),
    [data]
  );

  const { config } = useDonorConfig({
    constituentType: constituentType || data.constituentType,
    orgConfig: ORG_DONOR_INFO_SECTION_CONFIG,
    userConfig: USER_DONOR_INFO_SECTION_CONFIG,
  });

  const avatarSize = React.useMemo(
    () => (isMobile ? AVATAR_MOB_SIZE : AVATAR_DESKTOP_SIZE),
    [isMobile]
  );

  // makes sure to split a name on several lines for users like donors
  const DonorName = (
    isPersonConstituentType(data) ? name.split(' ') : [name]
  ).map((part) => (
    <Typography
      className="truncate"
      data-testid="donor-display-name"
      key={part}
      variant="h5"
    >
      {part.trim()}
    </Typography>
  ));

  return (
    <Box py={1} data-testid="donor-info-section">
      <Box px={isMobile ? 2 : 3}>
        <Box display="flex" justifyContent="space-between" py={2}>
          <BackButton
            callback={closeDonorForm}
            label={translate('ALL_DONORS_LABEL')}
            path={AUTH_ROUTES.DONORS}
          />
        </Box>
        <Box
          alignItems="center"
          display="flex"
          justifyContent="space-between"
          pb={3}
        >
          {dataFetching ? (
            <Loader size={avatarSize} />
          ) : (
            <Avatar size={avatarSize} src={avatar}>
              {initials}
            </Avatar>
          )}
          <Box
            alignSelf="center"
            className="overflow-hidden"
            flex="1"
            pl={2}
            pt={0.5}
          >
            {DonorName}
            <LastInteraction date={lastInteractionDate} variant="body2" />
          </Box>
          {/* TODO: @anna - update logic for icons */}
          {/* <Icons /> */}
        </Box>
        <ConstituentInfoAttributes constituent={data} />
        {!!data?.labels?.length && (
          <>
            <ConstituentLabelChips constituent={data} />
            <Divider className="my-4" />
          </>
        )}
        <ViewFieldsColumn
          config={config}
          data={data}
          title={translate('DONOR_KEY_INFO')}
          onNoDataLabelClick={openDonorForm}
          showNoDataLabel={isEditable}
          pb={3}
        />
      </Box>
      {dataLoaded && (
        <ViewFieldsColumn
          config={CONSTITUENT_NOTES_CONFIG}
          className="bg-grey-100 rounded-lg"
          data={data}
          m={1}
          mt={0}
          onNoDataLabelClick={openDonorForm}
          p={2}
          title={translate('DONOR_KEY_NOTES')}
          showNoDataLabel={isEditable}
        />
      )}
      {!isNewConstituent && (
        <Box px={isMobile ? 2 : 3} pt={2}>
          <ConstituentInfoStage
            constituent={data}
            dataFetching={dataFetching}
          />
          <Box pt={3}>
            <ConstituentInfoAssigned
              constituent={data}
              dataFetching={dataFetching}
            />
          </Box>
        </Box>
      )}
    </Box>
  );
};

ConstituentInfoSection.propTypes = {
  data: ConstituentType,
  dataLoaded: PropTypes.bool.isRequired,
  dataFetching: PropTypes.bool.isRequired,
  constituentType: ConstituentTypes.isRequired,
  openDonorForm: PropTypes.func,
  closeDonorForm: PropTypes.func,
  isEditable: PropTypes.bool,
  isNewConstituent: PropTypes.bool,
};

ConstituentInfoSection.defaultProps = {
  data: {},
  isNewConstituent: false,
  isEditable: false,
  openDonorForm: EMPTY_FUNC,
  closeDonorForm: EMPTY_FUNC,
};

export default ConstituentInfoSection;
