import {
  camelCaseKeys,
  createAction,
  showErrorMessage,
  showSuccessMessage,
} from 'lib/utils';
import { translate } from 'lib/intl';
import { invitesService } from 'services/invites';
import { TOAST_FEEDBACK_IDS } from 'lib/constants';
import userActionTypes from 'rdx/actionTypes';

const {
  SENDING_INVITES_REQUEST,
  SENDING_INVITES_SUCCESS,
  SENDING_INVITES_FAILURE,
  INVITES_REQUEST,
  INVITES_SUCCESS,
  INVITES_FAILURE,
  UPDATE_INVITES_REQUEST,
  UPDATE_INVITES_SUCCESS,
  UPDATE_INVITES_FAILURE,
  REMOVE_INVITES_REQUEST,
  REMOVE_INVITES_SUCCESS,
  REMOVE_INVITES_FAILURE,
} = userActionTypes;

const sendInvites =
  (ownerOrgPubId, dataToSend, isResending = false) =>
  async (dispatch) => {
    try {
      dispatch(createAction({ type: SENDING_INVITES_REQUEST }));

      const response = await invitesService.sendInvites(
        ownerOrgPubId,
        dataToSend
      );

      dispatch(
        createAction({
          type: SENDING_INVITES_SUCCESS,
          data: camelCaseKeys(response),
        })
      );

      showSuccessMessage({
        message: translate('COMPANY_PAGE_INVITE_SENT_LABEL'),
      });
    } catch (error) {
      if (isResending) {
        showErrorMessage({
          message: translate('COMPANY_INVITE_RESEND_INVITE_ERROR_MESSAGE', {
            email: dataToSend?.emails?.join(', '),
          }),
          containerId: TOAST_FEEDBACK_IDS.TEAM_MEMBERS,
        });
      }

      dispatch(
        createAction({
          type: SENDING_INVITES_FAILURE,
          error: translate('API_DEFAULT_ERROR_2'),
        })
      );
    }
  };

const fetchInvites = (ownerOrgPubId) => async (dispatch) => {
  try {
    dispatch(createAction({ type: INVITES_REQUEST }));

    const response = await invitesService.fetchInvites(ownerOrgPubId);

    dispatch(
      createAction({
        type: INVITES_SUCCESS,
        data: response?.data || {},
      })
    );
  } catch (error) {
    showErrorMessage({
      message: translate('COMPANY_FETCH_INVITES_ERROR_MESSAGE'),
    });

    dispatch(
      createAction({
        type: INVITES_FAILURE,
        error,
      })
    );
  }
};

const updateInvites = (ownerOrgPubId, data) => async (dispatch) => {
  try {
    dispatch(createAction({ type: UPDATE_INVITES_REQUEST, data }));

    const response = await invitesService.updateInvite(ownerOrgPubId, data);

    dispatch(
      createAction({
        type: UPDATE_INVITES_SUCCESS,
        data: camelCaseKeys(response?.data?.invite),
      })
    );

    dispatch(fetchInvites(ownerOrgPubId));
  } catch (error) {
    showErrorMessage({
      message: translate('COMPANY_INVITE_UPDATE_INVITE_ERROR_MESSAGE', {
        email: data?.email,
      }),
      containerId: TOAST_FEEDBACK_IDS.TEAM_MEMBERS,
    });

    dispatch(
      createAction({
        type: UPDATE_INVITES_FAILURE,
        data,
        error,
      })
    );
  }
};

const removeInvites = (ownerOrgPubId, data) => async (dispatch) => {
  try {
    dispatch(
      createAction({
        type: REMOVE_INVITES_REQUEST,
        data,
      })
    );

    const response = await invitesService.removeInvites(ownerOrgPubId, data);

    dispatch(
      createAction({
        type: REMOVE_INVITES_SUCCESS,
        data: camelCaseKeys(response?.data),
      })
    );

    dispatch(fetchInvites(ownerOrgPubId));
  } catch (error) {
    showErrorMessage({
      message: translate('COMPANY_INVITE_REMOVE_INVITE_ERROR_MESSAGE', {
        email: data?.email,
      }),
      containerId: TOAST_FEEDBACK_IDS.TEAM_MEMBERS,
    });

    dispatch(
      createAction({
        type: REMOVE_INVITES_FAILURE,
        data,
        error,
      })
    );
  }
};

export const invitesActions = {
  fetchInvites,
  sendInvites,
  updateInvites,
  removeInvites,
};
