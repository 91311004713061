const sortByCategories = (data, field, categories) =>
  data?.sort((a, b) => {
    const isCategoryIndex = (val) => (cat) => cat === val[field];

    return (
      categories.findIndex(isCategoryIndex(a)) -
      categories.findIndex(isCategoryIndex(b))
    );
  });

export { sortByCategories };
