import React, { useCallback } from 'react';
import { bool, func, node } from 'prop-types';
import styled from 'styled-components';
import { Box } from '@material-ui/core';
import { translate } from 'lib/intl';
import { isMobileView } from 'lib/utils';
import { ShowLessArrowsIcon, ShowMoreArrowsIcon } from 'assets/images';
import { CustomLink, Gap, Visible } from 'components';

const StyledShowMoreContent = styled(Box)`
  .MuiTypography-button {
    align-items: center;
    display: flex;
    padding-left: ${({ mobile }) => (mobile ? '64px' : '127px')};
  }
`;

const SHOW_MORE_CONFIG = {
  MORE: {
    label: translate('SHOW_MORE_OPTIONS_LABEL'),
    icon: <ShowMoreArrowsIcon />,
  },
  LESS: {
    label: translate('SHOW_LESS_OPTIONS_LABEL'),
    icon: <ShowLessArrowsIcon />,
  },
};

const ShowMoreContent = ({ children, showMore, setShowMore }) => {
  const toggleShowMore = useCallback(() => setShowMore(!showMore), [showMore]);

  const config = showMore ? SHOW_MORE_CONFIG.LESS : SHOW_MORE_CONFIG.MORE;

  const { label, icon } = config;

  const isMobile = isMobileView();

  return (
    <StyledShowMoreContent mobile={Number(isMobile)}>
      <Visible when={showMore}>{children}</Visible>
      <Box pt={2}>
        <CustomLink color="primary" onClick={toggleShowMore}>
          {label}
          <Gap size={1} />
          {icon}
        </CustomLink>
      </Box>
    </StyledShowMoreContent>
  );
};

ShowMoreContent.propTypes = {
  children: node.isRequired,
  showMore: bool.isRequired,
  setShowMore: func.isRequired,
};

export default ShowMoreContent;
