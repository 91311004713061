import { createApiRequest } from 'lib/utils';
import { VISIT_STREAK_CHECK_IN } from 'lib/constants/localStorageKeys';
import addDays from 'date-fns/addDays';
import startOfDay from 'date-fns/startOfDay';

const fetchVisitStreak = () =>
  createApiRequest({
    method: 'get',
    path: 'visits/streak',
  });

const updateVisitStreak = () =>
  createApiRequest({
    method: 'post',
    path: 'visits/visit',
  });

// Retrieve visit streak last checked from local storage
const getVisitStreakLastCheckIn = () => {
  let visitStreakCheckIn;

  try {
    visitStreakCheckIn = localStorage.getItem(VISIT_STREAK_CHECK_IN);

    if (visitStreakCheckIn) {
      visitStreakCheckIn = new Date(visitStreakCheckIn).toISOString();
    }
  } catch (e) {
    // eslint-disable-next-line no-console
    console.warn('Failed to retrieve visit streak check in data');
  }

  return visitStreakCheckIn;
};

// Sets the following day at midnight in PDT as the next visit streak check in
const setNextVisitStreakCheckIn = () => {
  const tomorrowPDTAtMidnight = startOfDay(
    addDays(new Date(), 1)
  ).toISOString();

  // Set the following day PDT at midnight to local storage
  try {
    localStorage.setItem(VISIT_STREAK_CHECK_IN, tomorrowPDTAtMidnight);
  } catch (e) {
    // eslint-disable-next-line no-console
    console.warn('Failed to set next visit streak check in date', e);
  }
};

// Call visit streak record API only once per day
// Day time point set around PDT at midnight
const shouldUpdateVisitStreak = () => {
  // Add the provided donor information to the cookie
  const nextCheckIn = getVisitStreakLastCheckIn();

  // Get Current time in PDT
  const currentTime = new Date().toISOString();

  // If next check in not set or current time is past the check in time, POST to visit streak
  return !nextCheckIn || currentTime > nextCheckIn;
};

export const visitStreakService = {
  fetchVisitStreak,
  updateVisitStreak,
  shouldUpdateVisitStreak,
  setNextVisitStreakCheckIn,
};
