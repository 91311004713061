import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/styles/makeStyles';
import userActionTypes from 'rdx/actionTypes';
import { translate } from 'lib/intl';
import { FeedType } from 'lib/types';
import { ThreeDotsIcon } from 'assets/images';
import {
  FULL_DATE_FORMAT,
  MULTI_ACTION_DIALOG_KEYS,
} from 'lib/constants/common';
import { FEED_CONTENT_TYPES, FEED_ENTITY_TYPES } from 'lib/configs/feedTypes';
import { formatDate } from 'lib/utils/dates';

const useStyles = makeStyles((theme) => ({
  iconButton: {
    padding: theme.spacing(2.75, 0),
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  dotsIcon: {
    height: '6px',
    width: '24px',
  },
}));

const CONTENT_TYPE_CONFIG = {
  [FEED_CONTENT_TYPES.INTERACTION]: {
    dialogKey: MULTI_ACTION_DIALOG_KEYS.INTERACTION,
    deleteConfirmationConfig: {
      entityType: FEED_ENTITY_TYPES[FEED_CONTENT_TYPES.INTERACTION],
      title: translate('DELETE_INTERACTION_TITLE'),
      label: translate('DELETE_INTERACTION_LABEL'),
    },
  },
};

const FeedSliderBottomContent = ({
  contentId,
  contentType,
  closeSlider,
  ...entity
}) => {
  const config = CONTENT_TYPE_CONFIG[contentType];

  if (!config) {
    return null;
  }

  const classes = useStyles();
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const openMenu = (event) => setAnchorEl(event.currentTarget);
  const closeMenu = () => setAnchorEl(null);

  const closeMenuAndSlider = () => {
    closeMenu();
    closeSlider();
  };

  const onEditClick = () => {
    closeMenuAndSlider();
    dispatch({
      type: userActionTypes.OPEN_ACTION_DIALOG,
      dialogKey: config.dialogKey,
      data: entity,
    });
  };

  const onDeleteClick = () => {
    closeMenuAndSlider();
    dispatch({
      type: userActionTypes.OPEN_ACTION_DIALOG,
      dialogKey: MULTI_ACTION_DIALOG_KEYS.DELETE_FEED_ITEM_CONFIRMATION,
      config: config.deleteConfirmationConfig,
      data: { contentId },
    });
  };

  const wasEdited = entity.created !== entity.updated;

  return (
    <Box py={2}>
      <Divider />
      <Box
        alignItems="flex-start"
        display="flex"
        justifyContent="space-between"
      >
        <Box py={2}>
          <Typography color="textSecondary" component="p" variant="caption">
            {translate('ADDED_DATE', {
              date: formatDate(entity.created, FULL_DATE_FORMAT),
            })}
          </Typography>
          {wasEdited && (
            <Typography color="textSecondary" component="p" variant="caption">
              {translate('LAST_EDITED_DATE', {
                date: formatDate(entity.updated, FULL_DATE_FORMAT),
              })}
            </Typography>
          )}
        </Box>
        <IconButton
          aria-controls="feed-slider-bottom-content-menu"
          aria-haspopup="true"
          className={classes.iconButton}
          onClick={openMenu}
        >
          <ThreeDotsIcon className={classes.dotsIcon} />
        </IconButton>
        <Menu
          anchorEl={anchorEl}
          id="feed-slider-bottom-content-menu"
          open={Boolean(anchorEl)}
          onClose={closeMenu}
          transformOrigin={{ vertical: 'center', horizontal: 'left' }}
        >
          <MenuItem onClick={onEditClick}>{translate('EDIT')}</MenuItem>
          <MenuItem onClick={onDeleteClick}>{translate('DELETE')}</MenuItem>
        </Menu>
      </Box>
    </Box>
  );
};

FeedSliderBottomContent.propTypes = {
  contentId: PropTypes.string.isRequired,
  contentType: FeedType.isRequired,
  closeSlider: PropTypes.func.isRequired,
  entity: PropTypes.shape({}),
};

FeedSliderBottomContent.defaultProps = {
  entity: null,
};

export { FeedSliderBottomContent };
