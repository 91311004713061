import React from 'react';
import { bool, string } from 'prop-types';
import { Box } from '@material-ui/core';
import styled from 'styled-components';
import { colors } from 'styles/theme';
import { CompanyTeamMember, GroupType } from 'lib/utils';
import {
  TextCellForInvites,
  ResendCellForInvites,
  TrashcanCellForInvites,
  PermissionCellForInvites,
  StatusCellForInvites,
  LoaderCellForInvites,
} from './config';

const StyledInviteMemberCard = styled(Box)`
  border-bottom-color: ${colors.transparentGrey};
  border-top-color: ${colors.transparentGrey};
`;

const InviteMemberCard = ({
  data,
  hasBottomBorder,
  ownerOrgId,
  ownerOrgName,
  groups,
}) => {
  const { inviteId, email, status } = data;

  return (
    <StyledInviteMemberCard
      alignItems="center"
      borderBottom={hasBottomBorder ? 1 : 0}
      borderTop={1}
      px={3}
      data-testid={`invite-member-${inviteId}`}
      py={2}
      display="flex"
      key={inviteId}
    >
      <Box width="100%">
        <Box
          alignItems="center"
          display="flex"
          justifyContent="flex-start"
          pb={1}
        >
          <Box pr={3}>
            <StatusCellForInvites value={status} />
          </Box>
          <TextCellForInvites data={data} value={email} />
        </Box>
        <Box
          alignItems="center"
          display="flex"
          flex="1"
          justifyContent="space-between"
        >
          <Box pr={3}>
            <PermissionCellForInvites
              data={data}
              groups={groups}
              ownerOrgId={ownerOrgId}
              ownerOrgName={ownerOrgName}
            />
          </Box>

          <Box pr={4}>
            <ResendCellForInvites
              data={data}
              ownerOrgId={ownerOrgId}
              ownerOrgName={ownerOrgName}
            />
          </Box>
          <TrashcanCellForInvites
            data={data}
            ownerOrgId={ownerOrgId}
            ownerOrgName={ownerOrgName}
            showLoaderOnFetching={false}
            value={inviteId}
          />
        </Box>
      </Box>
      <LoaderCellForInvites data={data} />
    </StyledInviteMemberCard>
  );
};

InviteMemberCard.propTypes = {
  groups: GroupType.isRequired,
  data: CompanyTeamMember.isRequired,
  hasBottomBorder: bool.isRequired,
  ownerOrgId: string.isRequired,
  ownerOrgName: string.isRequired,
};

export { InviteMemberCard };
