import { sort } from 'rambdax';

const dataComparator = (columns, sorting) => (a, b) => {
  const { order, orderBy } = sorting;

  const getComparableValue = columns?.find(
    (col) => col.field === orderBy
  )?.getComparableValue;

  const multiplier = order === 'desc' ? 1 : -1;

  const firstValue = getComparableValue
    ? getComparableValue(a[orderBy], a)
    : a[orderBy];
  const secondValue = getComparableValue
    ? getComparableValue(b[orderBy], b)
    : b[orderBy];

  let comparison = 0;

  if (secondValue < firstValue) {
    comparison = -1;
  }

  if (secondValue > firstValue) {
    comparison = 1;
  }

  return multiplier * comparison;
};

const filterDataBySearchValue = (data, searchValue, searchableFields) =>
  data?.filter((item = {}) => {
    const fieldValues = [];

    searchableFields.forEach((field) => {
      const value = item[field];

      if (typeof value !== 'undefined') {
        fieldValues.push(value);
      }
    });

    const searchString = fieldValues.join(',').toLowerCase();

    return searchString.includes(searchValue.toLowerCase());
  });

/*
    sortAndFilterCustomTableData a helper util that filters and then sort data
    depending on a provided search value and sorting config
*/
const sortAndFilterCustomTableData = ({
  data,
  columns,
  sorting,
  search,
  searchableFields,
}) => {
  let result = data;

  if (search && searchableFields.length) {
    result = filterDataBySearchValue(data, search, searchableFields);
  }

  if (sorting?.orderBy) {
    result = sort(dataComparator(columns, sorting), result);
  }

  return result;
};

export { sortAndFilterCustomTableData };
