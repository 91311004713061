import React, { useMemo } from 'react';
import { Box } from '@material-ui/core';
import { translate } from 'lib/intl';
import { mapCapacity, mapLastDonationDate } from 'lib/mappers';
import { Avatar } from 'components';
import { COLON } from 'lib/constants';
import {
  DonorType,
  formatCurrency,
  formatDonationTime,
  getInitials,
  isMobileView,
} from 'lib/utils';
import {
  HourGlassIcon,
  GiftIcon,
  MoneyBagIcon,
  ClocksIcon,
} from 'lib/configs/donor-icons';
import ConstituentSectionWrapper from './ConstituentSectionWrapper';
import ConstituentSummaryItem from './ConstituentSummaryItem';

const NA_LABEL = translate('NA');

const ConstituentSummary = ({ data }) => {
  const isMobile = isMobileView();

  const {
    referredBy,
    donor: {
      lastDonationDate,
      lastDonationSum = 0,
      lifetimeCount = 0,
      lifetimeSum = 0,
      lastYearSum = 0,
      planned = 0,
      thisYearCount = 0,
      thisYearSum = 0,
      fromN = 0,
      toN = 0,
    } = {},
    volunteer: {
      lastVolunteerDate,
      lastVolunteerMinutes = 0,
      lifetimeVolunteerCount = 0,
      lifetimeMinutes = 0,
      lastYearMinutes = 0,
      thisYearVolunteerCount = 0,
      thisYearMinutes = 0,
    } = {},
  } = data;

  const referredByPart = useMemo(() => {
    const parts = [translate('REFERRED_BY_LABEL')];

    if (referredBy?.alias) {
      parts.push(
        <Avatar size="24px">{getInitials(referredBy?.alias || '')}</Avatar>,
        referredBy?.alias
      );
    } else {
      parts.push(NA_LABEL);
    }

    return parts;
  }, [referredBy]);

  return (
    <ConstituentSectionWrapper noForm title={translate('DONOR_DIVING_SUMMARY')}>
      <Box
        display="flex"
        justifyContent="space-between"
        flexDirection={isMobile ? 'column' : 'row'}
        flexWrap="wrap"
      >
        <Box pr={2}>
          <ConstituentSummaryItem
            parts={[
              translate('LAST_LABEL'),
              MoneyBagIcon,
              GiftIcon,
              COLON,
              lastDonationSum && lastDonationDate
                ? `${formatCurrency(
                    lastDonationSum,
                    true
                  )} - ${mapLastDonationDate(lastDonationDate)}`
                : NA_LABEL,
            ]}
          />
          <ConstituentSummaryItem
            parts={[
              translate('THIS_YEAR_LABEL'),
              MoneyBagIcon,
              COLON,
              formatCurrency(thisYearSum, true),
            ]}
          />
          <ConstituentSummaryItem
            parts={[
              translate('THIS_YEAR_TOTAL_LABEL'),
              MoneyBagIcon,
              GiftIcon,
              COLON,
              thisYearCount,
            ]}
          />
          <ConstituentSummaryItem
            parts={[
              translate('LAST_YEAR_LABEL'),
              MoneyBagIcon,
              COLON,
              formatCurrency(lastYearSum, true),
            ]}
          />
          <ConstituentSummaryItem
            parts={[
              translate('LIFETIME_LABEL'),
              MoneyBagIcon,
              COLON,
              formatCurrency(lifetimeSum, true),
            ]}
          />
          <ConstituentSummaryItem
            parts={[
              translate('LIFETIME_TOTAL_LABEL'),
              MoneyBagIcon,
              COLON,
              lifetimeCount,
            ]}
          />
        </Box>
        <Box pr={2}>
          <ConstituentSummaryItem
            parts={[
              translate('LAST_LABEL'),
              ClocksIcon,
              GiftIcon,
              COLON,
              lastVolunteerMinutes && lastVolunteerDate
                ? `${formatDonationTime(
                    lastVolunteerMinutes
                  )} - ${mapLastDonationDate(lastVolunteerDate)}`
                : NA_LABEL,
            ]}
          />
          <ConstituentSummaryItem
            parts={[
              translate('THIS_YEAR_LABEL'),
              ClocksIcon,
              COLON,
              formatDonationTime(thisYearMinutes),
            ]}
          />
          <ConstituentSummaryItem
            parts={[
              translate('THIS_YEAR_TOTAL_LABEL'),
              ClocksIcon,
              GiftIcon,
              COLON,
              thisYearVolunteerCount,
            ]}
          />
          <ConstituentSummaryItem
            parts={[
              translate('LAST_YEAR_LABEL'),
              ClocksIcon,
              COLON,
              formatDonationTime(lastYearMinutes),
            ]}
          />
          <ConstituentSummaryItem
            parts={[
              translate('LIFETIME_LABEL'),
              ClocksIcon,
              COLON,
              formatDonationTime(lifetimeMinutes),
            ]}
          />
          <ConstituentSummaryItem
            parts={[
              translate('LIFETIME_TOTAL_LABEL'),
              ClocksIcon,
              COLON,
              lifetimeVolunteerCount,
            ]}
          />
        </Box>
        <Box>
          <ConstituentSummaryItem parts={referredByPart} />
          <ConstituentSummaryItem
            parts={[
              GiftIcon,
              translate('CAPACITY_LABEL'),
              MoneyBagIcon,
              COLON,
              mapCapacity(fromN, toN) || NA_LABEL,
            ]}
          />
          <ConstituentSummaryItem
            parts={[
              HourGlassIcon,
              GiftIcon,
              COLON,
              formatCurrency(planned, true),
              translate('PLANNED_LABEL'),
            ]}
          />
        </Box>
      </Box>
    </ConstituentSectionWrapper>
  );
};

ConstituentSummary.propTypes = {
  data: DonorType,
};

ConstituentSummary.defaultProps = {
  data: {},
};

export default ConstituentSummary;
