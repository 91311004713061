import { createAction } from 'lib/utils';
import { visitStreakService } from 'services/visit-streak';
import userActionTypes from 'rdx/actionTypes';

const {
  GET_VISIT_STREAK_REQUEST,
  GET_VISIT_STREAK_SUCCESS,
  GET_VISIT_STREAK_FAILURE,
  UPDATE_VISIT_STREAK_REQUEST,
  UPDATE_VISIT_STREAK_SUCCESS,
  UPDATE_VISIT_STREAK_FAILURE,
} = userActionTypes;

const getVisitStreak = () => async (dispatch) => {
  try {
    dispatch(createAction({ type: GET_VISIT_STREAK_REQUEST }));

    const response = await visitStreakService.fetchVisitStreak();

    dispatch(
      createAction({
        type: GET_VISIT_STREAK_SUCCESS,
        data: response?.data || {},
      })
    );
  } catch (error) {
    dispatch(
      createAction({
        type: GET_VISIT_STREAK_FAILURE,
        error,
      })
    );
  }
};

const updateVisitStreak = () => async (dispatch) => {
  // Update visit streak should only be called once per day
  if (!visitStreakService.shouldUpdateVisitStreak()) {
    return;
  }

  try {
    dispatch(createAction({ type: UPDATE_VISIT_STREAK_REQUEST }));

    const response = await visitStreakService.updateVisitStreak();

    // Upon response set the next check in time for visit streak
    visitStreakService.setNextVisitStreakCheckIn();

    dispatch(
      createAction({
        type: UPDATE_VISIT_STREAK_SUCCESS,
        data: response?.data || {},
      })
    );
  } catch (error) {
    dispatch(
      createAction({
        type: UPDATE_VISIT_STREAK_FAILURE,
        error,
      })
    );
  }
};

export const visitStreakActions = {
  getVisitStreak,
  updateVisitStreak,
};
