const PUBLIC_ROUTES = {
  LANDING: '/',
  LOGIN: '/login',
  LOGIN_EXPIRED: '/login/expired',
  ADMIN_LOGIN: '/cheese/login',
  ADMIN_LANDING: '/cheese/landing',
  ADMIN_REGISTER: '/cheese/register',
  VERIFICATION: '/login/verification',
  VERIFICATION_ERROR: '/login/verification-error',
  REGISTRATION: '/register',
  REGISTRATION_FROM_INVITE: '/register/invite',
  REGISTRATION_SUCCESS: '/register/success',
  REGISTRATION_VERIFICATION_ERROR: '/register/verification-error',
  REGISTRATION_INVITE_VERIFICATION_ERROR: '/register/invite-verification-error',
  RESET_PASSWORD: '/reset-password',
  RESET_PASSWORD_REQUEST_SUCCESS: '/reset-password/success',
  RESET_PASSWORD_SET_PASSWORD: '/reset-password/submit',
  RESET_PASSWORD_SET_PASSWORD_SUCCESS: '/reset-password/submit/success',
};

const AUTH_ROUTES = {
  LANDING: '/',
  COMPANY: '/company',
  COMPANY_TEAM: '/company/team',
  COMPANY_INVITES: '/company/invites',
  CONSTITUENT_NEW: '/new-constituent/:constituentType(prs|org)',
  CONSTITUENT: '/constituent/:constituentId',
  CONSTITUENT_HISTORY: '/constituent/:constituentId/history/:historyId?',
  CONSTITUENT_INFO: '/constituent/:constituentId/info',
  CONSTITUENTS: '/constituents',
  DONORS: '/donors',
  FEED: '/feed/:id',
  ADMIN: '/admin',
  CAMPAIGNS: '/campaigns',
  CAMPAIGN: '/campaigns/:campaignId',
  CAMPAIGN_FEED: '/campaigns/:campaignId/history/:historyId?',
  CAMPAIGN_DONATIONS: '/campaigns/:campaignId/donations',
  CAMPAIGN_REPORTS: '/campaigns/:campaignId/reports',
  REPORTS: '/reports',
  INTEGRATIONS: '/integrations',
  INTEGRATION: '/integrations/:integrationId',
  ORG_MANAGEMENT: '/admin/organizations',
  FEATURE_FLAG_MANAGEMENT: '/admin/feature-flags',
  USER: '/user',
  USER_PROFILE: '/user/profile',
  USER_DOWNLOADS: '/user/downloads',
};

export { PUBLIC_ROUTES, AUTH_ROUTES };
