import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
  integrationLogo: {
    borderRadius: '4px',
    height: '104px',
    width: '104px',
    [theme.breakpoints.down('xs')]: {
      height: '52px',
      width: '52px',
    },
  },
  pageBackground: {
    backgroundColor: theme.palette.background.light,
    height: '100%',
    width: '100%',
    paddingBottom: theme.spacing(30.5),
    [theme.breakpoints.down('xs')]: {
      paddingBottom: theme.spacing(8),
    },
  },
  infoSection: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: theme.spacing(3),
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'row',
      marginTop: theme.spacing(2.5),
    },
  },
  infoText: {
    [theme.breakpoints.down('xs')]: {
      paddingLeft: theme.spacing(1.5),
    },
  },
  infoName: {
    fontSize: '20px',
    fontWeight: theme.typography.subtitle3.fontWeight,
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      fontSize: theme.typography.body1.fontSize,
      paddingTop: '0',
      paddingBottom: theme.spacing(0.5),
    },
  },
  infoBox: {
    backgroundColor: theme.palette.background.white,
    border: `1px solid ${theme.palette.grey.light}`,
    borderRadius: theme.shape.borderRadius,
    marginBottom: theme.spacing(2),
  },
  helperTitle: {
    fontSize: '20px',
    fontWeight: '600',
    [theme.breakpoints.down('xs')]: {
      fontSize: '16px',
    },
  },
  helperDescription: {
    paddingTop: theme.spacing(2),
  },
  line: {
    border: `1px solid ${theme.palette.grey.light}`,
  },
  textBreak: {
    paddingBottom: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      paddingBottom: theme.spacing(0.5),
    },
  },
  helperContainer: {
    padding: `${theme.spacing(3)}px ${theme.spacing(4)}px`,
  },
  helperContainerInner: {
    borderLeft: `16px solid ${theme.palette.green.light}`,
    [theme.breakpoints.down('xs')]: {
      borderLeft: `8px solid ${theme.palette.green.light}`,
    },
  },
  helperSubtitle: {
    borderBottom: `2px solid ${theme.palette.primary.main}`,
    fontSize: '20px',
    fontWeight: '500',
    marginBottom: theme.spacing(2),
    whiteSpace: 'nowrap',
    width: '32px',
    [theme.breakpoints.down('xs')]: {
      fontSize: '16px',
    },
  },
  helperEndText: {
    textAlign: 'center',
    fontWeight: '500',
  },
  credentialsHeading: {
    fontSize: '20px',
    fontWeight: '600',
    paddingBottom: theme.spacing(3),
    [theme.breakpoints.down('xs')]: {
      fontSize: '16px',
      paddingBottom: theme.spacing(2),
    },
  },
  credentialsHelperText: {
    backgroundColor: theme.palette.background.light,
    borderRadius: theme.shape.borderRadius,
    border: `1px solid ${theme.palette.grey.light}`,
    padding: theme.spacing(1.5),
    margin: `0 ${theme.spacing(3)}px ${theme.spacing(3)}px`,
    textAlign: 'center',
  },
  credentialsButtonContainer: {
    padding: theme.spacing(3),
    display: 'flex',
    justifyContent: 'flex-end',
    [theme.breakpoints.down('xs')]: {
      flexWrap: 'wrap',
      '& #credentials-save, #credentials-edit': {
        order: 1,
      },
      '& #credentials-remove, #credentials-edit-cancel': {
        order: 2,
      },
    },
  },
  credentialsButton: {
    minWidth: '140px',
    marginLeft: theme.spacing(1),
    [theme.breakpoints.down('xs')]: {
      marginLeft: 0,
    },
  },
  credentialContainer: {
    alignItems: 'center',
    display: 'flex',
    height: '48px',
    paddingLeft: theme.spacing(6.5),
    paddingRight: theme.spacing(10.75),
    marginBottom: theme.spacing(3),
    '& .has-credentials > div > input': {
      color: theme.palette.text.primary,
    },
    [theme.breakpoints.down('xs')]: {
      paddingLeft: theme.spacing(1.5),
      paddingRight: theme.spacing(1.5),
    },
  },
  credentialIcon: {
    marginRight: theme.spacing(6),
    [theme.breakpoints.down('xs')]: {
      marginRight: theme.spacing(1.5),
    },
  },
  loader: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(9),
    textAlign: 'center',
  },
  loaderHeader: {
    fontSize: '20px',
    fontWeight: '600',
    paddingBottom: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      paddingBottom: theme.spacing(1.5),
      fontSize: '16px',
    },
  },
  loaderIntegrationId: {
    textTransform: 'capitalize',
  },
  loaderDescription: {
    paddingBottom: theme.spacing(4),
    [theme.breakpoints.down('xs')]: {
      paddingBottom: theme.spacing(2),
    },
  },
  credentialsModal: {
    backgroundColor: theme.palette.background.white,
    borderRadius: theme.shape.borderRadius,
    margin: '140px auto',
    width: '500px',
    [theme.breakpoints.down('xs')]: {
      width: '95%',
      margin: `${theme.spacing(1)}px auto`,
    },
  },
  credentialsModalHeaderContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(3),
  },
  credentialsModalHeader: {
    fontWeight: '500',
  },
  credentialsModalDescription: {
    padding: theme.spacing(3),
  },
  credentialsModalButtonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: theme.spacing(3),
    [theme.breakpoints.down('xs')]: {
      flexWrap: 'wrap',
      '& #credentials-remove-confirm': {
        order: 1,
      },
      '& #credentials-remove-cancel': {
        order: 2,
      },
    },
  },
  credentialsModalCancelButton: {
    borderRadius: '100px',
    color: theme.palette.primary.main,
    height: '56px',
    textTransform: 'capitalize',
    padding: `${theme.spacing(1.5)}px ${theme.spacing(3)}px`,
  },
  credentialsSuccessMessage: {
    backgroundColor: theme.palette.background.light,
    border: `1px solid ${theme.palette.grey.light}`,
    borderRadius: theme.shape.borderRadius,
    display: 'flex',
    marginBottom: theme.spacing(3),
    padding: `${theme.spacing(2.5)}px ${theme.spacing(2)}px`,
    [theme.breakpoints.down('xs')]: {
      alignItems: 'center',
      flexDirection: 'column',
      textAlign: 'center',
    },
  },
  credentialsSuccessCircularProgress: {
    flexShrink: 0,
    marginRight: theme.spacing(3.75),
    [theme.breakpoints.down('xs')]: {
      marginRight: 0,
      marginBottom: theme.spacing(1.5),
    },
  },
}));

export { useStyles };
