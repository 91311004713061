import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { useHistory } from 'react-router-dom';
import NewThemeProvider from 'components/common/NewThemeProvider';
import { AUTH_ROUTES } from 'lib/constants/routes';
import { isMobileView } from 'lib/utils';
import { translate } from 'lib/intl';
import { useSetOwnerOrgPubId } from 'lib/hooks/user-data';

const SuperAdminLayout = ({ children }) => {
  const history = useHistory();
  const isMobile = isMobileView();

  const { ownerOrgId, orgName } = useSelector((state) => ({
    orgName: state.orgInfo?.data?.orgName,
    ownerOrgId: state.user.ownerOrgId,
  }));

  const { setOwnerOrgPubId } = useSetOwnerOrgPubId();

  const handleReturnToInstilOrgClick = () => {
    setOwnerOrgPubId(ownerOrgId);
    history.push(AUTH_ROUTES.LANDING);
  };

  return (
    <Box className="h-full flex flex-col">
      <NewThemeProvider>
        <header className="bg-adminColor">
          <Box className="h-12 sm:h-16 flex items-center justify-between px-4">
            <Typography
              className="font-semibold"
              variant={isMobile ? 'subtitle1' : 'h2'}
            >
              {translate('SUPER_ADMIN')}
            </Typography>
            {!isMobile && orgName && (
              <Box
                alignItems="center"
                display="flex"
                flexDirection="column"
                justifyContent="center"
              >
                <Typography variant="caption">
                  {translate('CURRENTLY_VIEWING_LABEL')}
                </Typography>
                <Typography className="font-semibold leading-6" variant="h1">
                  {orgName}
                </Typography>
              </Box>
            )}
            <Typography
              variant="subtitle1"
              className="cursor-pointer underline text-sm sm:text-base"
              onClick={handleReturnToInstilOrgClick}
            >
              {translate('RETURN_TO_DOTORG_LABEL')}
            </Typography>
          </Box>
        </header>
      </NewThemeProvider>
      <Box className="flex-grow flex-shrink overflow-y-hidden">{children}</Box>
    </Box>
  );
};

SuperAdminLayout.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

SuperAdminLayout.defaultProps = {
  children: null,
};

export { SuperAdminLayout };
