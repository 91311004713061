import { createApiRequest } from 'lib/utils';

const fetchCompanyTeamMembers = (ownerOrgPubId) =>
  createApiRequest({
    method: 'get',
    path: `orgs/${ownerOrgPubId}/users`,
  });

const updateCompanyTeamMembers = (ownerOrgPubId, { userId, ...data }) =>
  createApiRequest({
    data,
    method: 'put',
    path: `orgs/${ownerOrgPubId}/users/${userId}`,
  });

const removeCompanyTeamMembers = (ownerOrgPubId, { userId }) =>
  createApiRequest({
    method: 'delete',
    path: `orgs/${ownerOrgPubId}/users/${userId}`,
  });

export const companyTeamMembersService = {
  fetchCompanyTeamMembers,
  updateCompanyTeamMembers,
  removeCompanyTeamMembers,
};
