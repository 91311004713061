import React from 'react';
import PropTypes from 'prop-types';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import makeStyles from '@material-ui/styles/makeStyles';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { TextSelectFieldValueType } from 'lib/utils';
import { EMPTY_FUNC } from 'lib/constants';
import TextSelectFieldInput from './TextSelectFieldInput';
import TextSelectFieldItem from './TextSelectFieldItem';
import TextSelectFieldPopper from './TextSelectFieldPopper';
import TextSelectFieldTags from './TextSelectFieldTags';

const useStyles = makeStyles(() => ({
  customListbox: {
    '& .MuiAutocomplete-option': {
      minHeight: '56px',
    },
  },
}));

export default function TextSelectField({
  PaperComponent,
  disableClearable,
  disabled,
  error,
  errorHelperText,
  filterOptions,
  formControlProps,
  id,
  items,
  label,
  loading,
  multiple,
  onInputChange,
  noOptionsText,
  primaryValue,
  required,
  setPrimaryValue,
  setValue,
  usePrimary,
  value,
}) {
  const styles = useStyles();

  const renderTags = React.useCallback(
    (values) => (
      <TextSelectFieldTags
        id={id}
        primaryValue={primaryValue}
        usePrimary={usePrimary}
        values={values}
      />
    ),
    [id, primaryValue, usePrimary]
  );

  const renderInput = React.useCallback(
    (props) => (
      <TextSelectFieldInput
        error={error}
        id={id}
        label={label}
        loading={loading}
        multiple={multiple}
        required={required}
        value={value}
        {...props}
      />
    ),
    [error, id, label, loading, required, value]
  );

  const renderOption = React.useCallback(
    (item) => (
      <TextSelectFieldItem
        id={id}
        item={item}
        multiple={multiple}
        value={value}
        usePrimary={usePrimary}
        primaryValue={primaryValue}
        setPrimaryValue={setPrimaryValue}
      />
    ),
    [id, multiple, value, usePrimary, primaryValue, setPrimaryValue]
  );

  const sanitizedLabel = label.replace(/ /g, '-');

  const getOptionSelected = React.useCallback((option, givenValue) => {
    // Handle case of empty value, otherwise autocomplete complains there is no match
    if (givenValue?.value === '') {
      return false;
    }

    return option?.value === givenValue?.value;
  }, []);

  const handleChange = React.useCallback((event, newValue) => {
    setValue(newValue);
  }, []);

  const getOptionLabel = React.useCallback((option) => option?.text || '', []);

  return (
    <FormControl
      data-testid={`text-select-field-select-${sanitizedLabel}`}
      fullWidth
      error={error}
      {...formControlProps}
    >
      <Autocomplete
        PopperComponent={TextSelectFieldPopper}
        disableClearable={disableClearable}
        disableCloseOnSelect={multiple}
        disabled={disabled}
        fullWidth
        getOptionLabel={getOptionLabel}
        getOptionSelected={getOptionSelected}
        // If no ID is provided here one will be generated and cause snapshots to fail on rebuild
        id={id}
        loading={loading}
        multiple={multiple}
        onChange={handleChange}
        options={items}
        renderInput={renderInput}
        renderOption={renderOption}
        renderTags={renderTags}
        value={value || (multiple ? [] : null)}
        {...(PaperComponent && { PaperComponent })}
        {...(multiple && {
          ListboxProps: {
            className: `MuiAutocomplete-listbox ${styles.customListbox}`,
          },
        })}
        {...(filterOptions && { filterOptions })}
        {...(onInputChange && { onInputChange })}
        {...(noOptionsText && { noOptionsText })}
      />
      {error && <FormHelperText>{errorHelperText}</FormHelperText>}
    </FormControl>
  );
}

// When filtering via an API, this option will allow for overriding the
// default behavior of <Autocomplete> by passing it in as `filterOptions` to
// <TextSelectField>. Adding it here as a method so that a parent component
// can opt into using it, or create their own if needed.
TextSelectField.asyncFilterOptions = (options) => options;

TextSelectField.propTypes = {
  PaperComponent: PropTypes.elementType,
  disableClearable: PropTypes.bool,
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  errorHelperText: PropTypes.string,
  filterOptions: PropTypes.func,
  formControlProps: PropTypes.shape(FormControl.propTypes),
  id: PropTypes.string,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      icon: PropTypes.string,
      text: PropTypes.string,
      hideRadio: PropTypes.bool,
    })
  ),
  label: PropTypes.string,
  loading: PropTypes.bool,
  multiple: PropTypes.bool,
  noOptionsText: PropTypes.string,
  onInputChange: PropTypes.func,
  primaryValue: PropTypes.string,
  required: PropTypes.bool,
  setPrimaryValue: PropTypes.func,
  setValue: PropTypes.func,
  usePrimary: PropTypes.bool,
  value: PropTypes.oneOfType([
    TextSelectFieldValueType,
    PropTypes.arrayOf(TextSelectFieldValueType),
  ]),
};

TextSelectField.defaultProps = {
  PaperComponent: null,
  disableClearable: false,
  disabled: false,
  error: false,
  errorHelperText: '',
  filterOptions: null,
  formControlProps: {},
  id: 'text-select-autocomplete-field',
  items: [],
  label: '',
  loading: false,
  multiple: false,
  noOptionsText: '',
  onInputChange: null,
  primaryValue: null,
  required: false,
  setPrimaryValue: EMPTY_FUNC,
  setValue: EMPTY_FUNC,
  usePrimary: false,
  value: null,
};
