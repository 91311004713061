import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormHelperText from '@material-ui/core/FormHelperText';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { FormProvider, useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import ReCAPTCHA from 'react-google-recaptcha';
import env from '@beam-australia/react-env';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { PasswordForm, OrgIdForm, Visible, CustomButton } from 'components';
import { translate } from 'lib/intl';
import {
  firstNameMaxLength,
  lastNameMaxLength,
  orgNameMinLength,
  orgNameMaxLength,
  emailRegex,
} from './authConstants';

const useStyles = makeStyles((theme) => ({
  header: {
    [theme.breakpoints.down('xs')]: {
      textAlign: 'left',
    },
  },
  headerContainer: {
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  },
  nameContainer: {
    display: 'flex',
    flexDirection: 'row',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  },
  firstNameItem: {
    marginRight: '2%',
    [theme.breakpoints.down('xs')]: {
      marginRight: '0',
      marginBottom: '24px',
    },
  },
  lastNameItem: {
    marginLeft: '2%',
    [theme.breakpoints.down('xs')]: {
      marginLeft: '0',
    },
  },
}));

const RegistrationForm = ({
  isFetching,
  isRegistrationSuccessful,
  error,
  registerAction,
  resetRegistrationState,
}) => {
  const formMethods = useForm({
    mode: 'onBlur',
  });
  const {
    register,
    unregister,
    handleSubmit,
    errors,
    setValue,
    getValues,
    trigger,
    clearErrors,
    watch,
  } = formMethods;
  const recaptchaSiteKey = env('GOOGLE_RECAPTCHA_SITE_KEY');
  const history = useHistory();
  const captchaFieldValue = 'captcha';
  const classes = useStyles();
  const values = getValues();

  if (isRegistrationSuccessful) {
    resetRegistrationState();
    history.push('/register/success');
  }

  // Use to register and monitor captcha field changes
  useEffect(() => {
    register({ name: captchaFieldValue }, { required: true });
  }, []);

  function onRecaptchaChange(value) {
    setValue(captchaFieldValue, value);
    trigger(captchaFieldValue);
  }

  return (
    <FormProvider {...formMethods}>
      <Box display="flex" flexDirection="column">
        <Box
          alignItems="center"
          className={classes.headerContainer}
          pb={4}
          display="flex"
        >
          <Typography
            className={classes.header}
            variant="h5"
            style={{ paddingRight: '5px' }}
          >
            {translate('REGISTER_HEADING_WELCOME')}
          </Typography>
          <Typography className={classes.header} variant="h6">
            {translate('REGISTER_ORG_SET_UP')}
          </Typography>
        </Box>
        <Box>
          <form onSubmit={handleSubmit((data) => registerAction(data))}>
            <Grid container spacing={3}>
              <Grid className={classes.nameContainer} item sm={6} xs={12}>
                <Grid className={classes.firstNameItem} item sm={6} xs={12}>
                  <TextField
                    fullWidth
                    error={!!errors.firstName}
                    helperText={errors?.firstName?.message || ''}
                    name="firstName"
                    inputRef={register({
                      required: 'Name Required',
                      maxLength: {
                        value: firstNameMaxLength,
                        message: translate(
                          'VALIDATION_MAX_CHARACTERS_ALLOWED',
                          { length: firstNameMaxLength }
                        ),
                      },
                    })}
                    inputProps={{
                      'data-testid': 'registration-first-name',
                    }}
                    label="First Name"
                    variant="outlined"
                  />
                </Grid>
                <Grid className={classes.lastNameItem} item sm={6} xs={12}>
                  <TextField
                    fullWidth
                    error={!!errors.lastName}
                    helperText={errors?.lastName?.message || ''}
                    name="lastName"
                    label="Last Name"
                    variant="outlined"
                    inputProps={{
                      'data-testid': 'registration-last-name',
                    }}
                    inputRef={register({
                      maxLength: {
                        value: lastNameMaxLength,
                        message: translate(
                          'VALIDATION_MAX_CHARACTERS_ALLOWED',
                          { length: lastNameMaxLength }
                        ),
                      },
                    })}
                  />
                </Grid>
              </Grid>
              <Grid item sm={6} xs={12}>
                <TextField
                  fullWidth
                  error={!!errors.orgName}
                  helperText={errors?.orgName?.message || ''}
                  name="orgName"
                  label="Org Name"
                  variant="outlined"
                  inputProps={{
                    'data-testid': 'registration-org-name',
                  }}
                  inputRef={register({
                    required: translate('VALIDATION_ORG_NAME_REQUIRED'),
                    minLength: {
                      value: orgNameMinLength,
                      message: translate('VALIDATION_MIN_CHARACTERS_REQUIRED', {
                        length: orgNameMinLength,
                      }),
                    },
                    maxLength: {
                      value: orgNameMaxLength,
                      message: translate('VALIDATION_MAX_CHARACTERS_ALLOWED', {
                        length: orgNameMaxLength,
                      }),
                    },
                  })}
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <TextField
                  fullWidth
                  error={!!errors.orgEmail}
                  helperText={errors?.orgEmail?.message || ''}
                  name="orgEmail"
                  label="Org Email Address"
                  variant="outlined"
                  inputProps={{
                    'data-testid': 'registration-org-email',
                  }}
                  inputRef={register({
                    required: translate('VALIDATION_ORG_EMAIL_REQUIRED'),
                    pattern: {
                      // eslint-disable-next-line
                      value: emailRegex,
                      message: translate('VALIDATION_INVALID_EMAIL'),
                    },
                  })}
                />
              </Grid>
              <Grid item container sm={6} xs={12}>
                <OrgIdForm
                  register={register}
                  unregister={unregister}
                  setValue={setValue}
                  clearErrors={clearErrors}
                  errors={errors}
                  watch={watch}
                  trigger={trigger}
                  values={values}
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <PasswordForm variant="outlined" />
              </Grid>
              <Grid item data-testid="registration-recaptcha" sm={6} xs={12}>
                <ReCAPTCHA
                  size="normal"
                  sitekey={recaptchaSiteKey}
                  onChange={onRecaptchaChange}
                  style={{ alignSelf: 'center' }}
                />
                <Visible
                  when={Boolean(
                    errors &&
                      errors.captcha &&
                      errors.captcha.type === 'required'
                  )}
                >
                  <p
                    className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error"
                    data-testid="registration-recaptcha-error"
                  >
                    {translate('VALIDATION_RECAPTCHA_REQUIRED')}
                  </p>
                </Visible>
              </Grid>
              <Visible when={Boolean(error)}>
                <Grid container item xs={12}>
                  <FormHelperText
                    error={!!error}
                    style={{
                      textAlign: 'center',
                      marginTop: '20px',
                      width: '100%',
                    }}
                  >
                    {error?.message}
                  </FormHelperText>
                </Grid>
              </Visible>
              <Grid container item xs={12} style={{ justifyContent: 'center' }}>
                <Visible when={isFetching}>
                  <CircularProgress
                    color="primary"
                    style={{ height: '54px', width: '54px' }}
                  />
                </Visible>
                <Visible when={!isFetching}>
                  <CustomButton
                    buttonType="primary"
                    dataTestId="registration-submit"
                    type="submit"
                    variant="contained"
                    disabled={isFetching}
                    color="primary"
                    label={translate('CREATE_ACCOUNT')}
                  />
                </Visible>
              </Grid>
            </Grid>
          </form>
        </Box>
      </Box>
    </FormProvider>
  );
};

RegistrationForm.propTypes = {
  isFetching: PropTypes.bool.isRequired,
  isRegistrationSuccessful: PropTypes.bool.isRequired,
  error: PropTypes.shape({ message: {} }),
  registerAction: PropTypes.func.isRequired,
  resetRegistrationState: PropTypes.func.isRequired,
};

RegistrationForm.defaultProps = {
  error: null,
};

export { RegistrationForm };
