import React from 'react';
import env from '@beam-australia/react-env';
import { Box, makeStyles } from '@material-ui/core';
import { translate } from 'lib/intl';

const UserProfileBottomLinks = () => {
  const helpLinkAddress = env('HELP_LINK_ADDRESS');
  const privacyLink = env('PRIVACY_LINK');
  const termsLink = env('TERMS_LINK');

  const useStyles = makeStyles((theme) => ({
    linkWrap: {
      display: 'flex ',
      justifyContent: 'center',
      borderTop: '1px solid #ECECEC',
      padding: '0 16px',
    },
    link: {
      fontSize: '12px',
      fontWeight: '500',
      textDecoration: 'none',
      color: theme.palette.grey.dark,
      padding: '12px 12px 4px',
    },
  }));
  const classes = useStyles();

  return (
    <Box data-testid="user-profile-bottom-links" className={classes.linkWrap}>
      <a
        data-testid="user-profile-bottom-link-help"
        href={`mailto:${helpLinkAddress}`}
        className={classes.link}
      >
        {translate('HELP_LINK')}
      </a>
      <a
        target="_blank"
        rel="noopener noreferrer"
        data-testid="user-profile-bottom-link-terms"
        href={termsLink}
        className={classes.link}
      >
        {translate('TERMS_LINK')}
      </a>
      <a
        target="_blank"
        rel="noopener noreferrer"
        data-testid="user-profile-bottom-link-privacy"
        href={privacyLink}
        className={classes.link}
      >
        {translate('PRIVACY_LINK')}
      </a>
    </Box>
  );
};

export { UserProfileBottomLinks };
