import React from 'react';
import propTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { interactionIcon } from 'assets/images';
import { formatDistance } from 'lib/utils';
import { translate } from 'lib/intl';
import { ICON_SIZES } from 'lib/constants/common';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
  icon: {
    height: ICON_SIZES.SM,
    marginRight: theme.spacing(0.5),
    width: ICON_SIZES.SM,
  },
}));

const LastInteraction = ({ date, variant }) => {
  const classes = useStyles();

  if (date) {
    return (
      <Box alignItems="center" display="flex">
        <img
          alt={translate('INTERACTION_ICON_ALT')}
          className={classes.icon}
          data-testid="LastInteraction-donor-icon"
          src={interactionIcon}
        />
        <Typography variant={variant}>{formatDistance(date)}</Typography>
      </Box>
    );
  }

  return null;
};

LastInteraction.propTypes = {
  date: propTypes.string,
  variant: propTypes.string,
};

LastInteraction.defaultProps = {
  date: '',
  variant: 'caption',
};

export { LastInteraction };
