import React, { useCallback } from 'react';
import { bool, func, string } from 'prop-types';
import { prop } from 'rambdax';
import { Box } from '@material-ui/core';
import styled from 'styled-components';
import { colors } from 'styles/theme';
import { translate } from 'lib/intl';
import { Visible } from 'components';
import {
  CompanyTeamMember,
  GroupType,
  formatDistance,
  getFullName,
} from 'lib/utils';
import {
  TextCell,
  LinkCell,
  LoaderCell,
  PermissionCell,
  TrashcanCell,
} from './config';

const StyledTeamMemberCard = styled(Box)`
  cursor: ${prop('cursor')};
  border-bottom-color: ${colors.transparentGrey};
  border-top-color: ${colors.transparentGrey};
`;

const TeamMemberCard = ({
  data,
  hasBottomBorder,
  groups,
  onClick,
  ownerOrgId,
  ownerOrgName,
  canRemove,
}) => {
  const { userId, displayName, firstName, lastName, lastVisit, email } = data;

  const onCardClick = useCallback(
    (event) => (onClick ? onClick({ data, element: event?.target }) : null),
    [data]
  );

  return (
    <StyledTeamMemberCard
      alignItems="center"
      borderBottom={hasBottomBorder ? 1 : 0}
      borderTop={1}
      onClick={onCardClick}
      cursor={onClick ? 'pointer' : 'default'}
      px={3}
      data-testid={`team-member-${userId}`}
      py={2}
      display="flex"
      key={userId}
    >
      <Box width="100%">
        <TextCell
          data={data}
          value={displayName || getFullName({ firstName, lastName })}
        />
        <Box py="4px">
          <LinkCell data={data} value={email} />
        </Box>
        <Box
          alignItems="center"
          display="flex"
          flex="1"
          justifyContent="space-between"
        >
          <Box display="flex">
            <Box pr={2}>
              <PermissionCell
                data={data}
                groups={groups}
                ownerOrgId={ownerOrgId}
                ownerOrgName={ownerOrgName}
              />
            </Box>
            {canRemove && (
              <TrashcanCell
                data={data}
                ownerOrgId={ownerOrgId}
                ownerOrgName={ownerOrgName}
                showLoaderOnFetching={false}
                value={userId}
              />
            )}
          </Box>
          <Visible when={Boolean(lastVisit)}>
            <TextCell
              data={data}
              value={translate('LAST_VISIT', {
                when: formatDistance(lastVisit),
              })}
            />
          </Visible>
        </Box>
      </Box>
      <LoaderCell data={data} />
    </StyledTeamMemberCard>
  );
};

TeamMemberCard.propTypes = {
  data: CompanyTeamMember.isRequired,
  groups: GroupType.isRequired,
  hasBottomBorder: bool.isRequired,
  ownerOrgId: string.isRequired,
  ownerOrgName: string.isRequired,
  onClick: func,
  canRemove: bool,
};

TeamMemberCard.defaultProps = {
  onClick: null,
  canRemove: true,
};

export { TeamMemberCard };
