import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import makeStyles from '@material-ui/styles/makeStyles';
import AddIcon from '@material-ui/icons/Add';

const BUTTON_SIZE = '56px';

const useStyles = makeStyles((theme) => ({
  container: {
    borderRadius: BUTTON_SIZE,
    bottom: theme.spacing(6),
    boxShadow:
      '0px 4px 4px rgba(0, 0, 0, 0.25), 0px 0px 2px rgba(0, 0, 0, 0.25)',
    height: BUTTON_SIZE,
    minWidth: 'auto',
    position: 'fixed',
    right: theme.spacing(9),
    width: BUTTON_SIZE,
    '&:hover': {
      boxShadow:
        '0px 4px 4px rgba(0, 0, 0, 0.25), 0px 0px 2px rgba(0, 0, 0, 0.25)',
    },
    [theme.breakpoints.down('sm')]: {
      right: theme.spacing(2),
      bottom: theme.spacing(2),
    },
  },
}));

const AddButton = ({ onClick }) => {
  const classes = useStyles();

  return (
    <Button
      className={classes.container}
      data-testid="add-button"
      onClick={onClick}
      variant="contained"
    >
      <AddIcon />
    </Button>
  );
};

AddButton.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export { AddButton };
