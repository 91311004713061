import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import getCategoryIcon from 'lib/utils/getCategoryIcon';
import makeStyles from '@material-ui/styles/makeStyles';
import { CustomLink, Loader } from 'components';
import { ShowForViews } from 'components/common/ShowForViews';
import { translate } from 'lib/intl';
import { useSelector } from 'react-redux';
import { StyledDialog } from 'lib/utils';
import { ICON_SIZES, USER_VIEW_ENUMS } from 'lib/constants/common';
import ConstituentPropType from 'lib/types/ConstituentPropType';
import ConstituentCardItemActionsMove from './ConstituentCardItemActionsMove';

const useStyles = makeStyles((theme) => ({
  header: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  container: {
    display: 'flex',
  },
  stageContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  icon: {
    width: ICON_SIZES.SM,
    height: ICON_SIZES.SM,
    marginRight: theme.spacing(1.5),
  },
  loaderContainer: {
    display: 'flex',
    justifyContent: 'center',
    paddingTop: theme.spacing(5),
  },
}));

const ConstituentInfoStage = ({ constituent, dataFetching }) => {
  const styles = useStyles();
  const [isOpen, setIsOpen] = useState(false);
  const { kanbanCategory, kanbanView } = constituent;
  const { categories } = useSelector((state) => ({
    categories: state.orgInfo?.data?.kanban?.columns,
  }));

  const currentCategory = (categories || []).find(
    ({ index }) => index === kanbanCategory
  );

  const closeActionDialog = () => setIsOpen(false);

  return (
    <Box>
      <Box className={styles.header}>
        <Typography variant="subtitle1">
          {translate('CONSTITUENTS_STAGE_INFO_TITLE')}
        </Typography>
        <ShowForViews showFor={[USER_VIEW_ENUMS.ADMIN, USER_VIEW_ENUMS.EDITOR]}>
          <CustomLink
            data-testid="donor-stage-move-btn"
            onClick={() => setIsOpen(true)}
          >
            {kanbanView ? translate('DONOR_STAGE_INFO_MOVE') : translate('ADD')}
          </CustomLink>
        </ShowForViews>
      </Box>
      {dataFetching ? (
        <Box className={styles.loaderContainer}>
          <Loader />
        </Box>
      ) : (
        <Box className={styles.container}>
          {currentCategory && kanbanView && (
            <Box className={styles.stageContainer}>
              <img
                className={styles.icon}
                alt="donor-info-stage-icon"
                src={getCategoryIcon(currentCategory.index)}
              />
              <Typography variant="subtitle2">
                {currentCategory.name}
              </Typography>
            </Box>
          )}
        </Box>
      )}
      <StyledDialog
        fullWidth
        maxWidth="md"
        open={isOpen}
        onClose={closeActionDialog}
      >
        {constituent && (
          <ConstituentCardItemActionsMove
            constituent={constituent}
            onClose={closeActionDialog}
          />
        )}
      </StyledDialog>
    </Box>
  );
};

ConstituentInfoStage.propTypes = {
  constituent: ConstituentPropType,
  dataFetching: PropTypes.bool,
};

ConstituentInfoStage.defaultProps = {
  constituent: {},
  dataFetching: false,
};

export default ConstituentInfoStage;
