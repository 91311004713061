import React from 'react';
import PropTypes from 'prop-types';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  divider: {
    margin: theme.spacing(0.75, 1.5),
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
}));

export default function OrgName({ orgName }) {
  const classes = useStyles();

  if (orgName) {
    return (
      <>
        <Divider orientation="vertical" flexItem className={classes.divider} />

        <Typography variant="h2" component="span" className={classes.orgName}>
          {orgName}
        </Typography>
      </>
    );
  }

  return null;
}

OrgName.propTypes = {
  orgName: PropTypes.string,
};

OrgName.defaultProps = {
  orgName: null,
};
