const FEED_CONTENT_TYPES = {
  DONATION: 'donation',
  INTERACTION: 'interaction',
  ANNOUNCEMENT: 'announcement',
  CAMPAIGN: 'campaign',
};

const FEED_ENTITY_TYPES = {
  [FEED_CONTENT_TYPES.DONATION]: 'donations',
  [FEED_CONTENT_TYPES.INTERACTION]: 'interactions',
  [FEED_CONTENT_TYPES.ANNOUNCEMENT]: 'announcements',
  [FEED_CONTENT_TYPES.CAMPAIGN]: 'campaigns',
};

export { FEED_CONTENT_TYPES, FEED_ENTITY_TYPES };
