import React from 'react';
import Box from '@material-ui/core/Box';
import makeStyles from '@material-ui/styles/makeStyles';
import { AdminLoginForm } from 'components';
import ImageIcon from 'components/common/ImageIcon';
import { translate } from 'lib/intl';
import { useLogin } from './loginPagesHooks';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
  },
  container: {
    borderRadius: theme.shape.borderRadius[0],
    borderColor: theme.palette.grey[500],
    maxWidth: 450,
    padding: `${theme.spacing(3.5)}px ${theme.spacing(6)}px`,
    [theme.breakpoints.down('xs')]: {
      border: 'none',
      padding: theme.spacing(2.5),
    },
  },
}));

const AdminLoginPage = () => {
  const classes = useStyles();
  const { error } = useLogin();

  return (
    <Box
      alignItems="center"
      className={classes.root}
      display="flex"
      justifyContent="center"
    >
      <Box border={1} className={classes.container} mb={6}>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          paddingBottom={1.5}
        >
          <ImageIcon
            alt={translate('INSTIL_LOGO_ALT')}
            type="instil"
            width={112}
          />
        </Box>
        <AdminLoginForm error={error} />
      </Box>
    </Box>
  );
};

export { AdminLoginPage };
