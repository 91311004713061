import React from 'react';
import PropTypes from 'prop-types';
import { EMPTY_FUNC, TEXT_SELECT_FIELD_EMPTY_VALUE } from 'lib/constants';
import { TextSelectFieldValueType, CompanyTeamMember } from 'lib/utils';
import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import { NotesInput, CurrencyInput, TextSelectField } from 'components';
import { translate } from 'lib/intl';
import { DonationPaymentMethodInput } from './donation-payment-method-input';

const NewDonationTypeGrant = ({
  developers,
  donationAmount,
  fieldErrors,
  grantName,
  restricted,
  restrictionCondition,
  notes,
  paymentMethod,
  setDevelopers,
  setDonationAmount,
  setGrantName,
  setRestricted,
  setRestrictionCondition,
  setNotes,
  setPaymentMethod,
  teamMembers,
}) => {
  return (
    <Grid container>
      <Grid className="pt-3" item container xs={12}>
        <CurrencyInput
          label={translate('GRANT_AMOUNT_LABEL')}
          amount={donationAmount}
          setAmount={setDonationAmount}
          error={!!fieldErrors?.donationAmount}
          errorHelperText={translate('DONATION_AMOUNT_ERROR_REQUIRED')}
        />
      </Grid>
      <Grid className="pt-3" item container xs={12}>
        <TextField
          id="grant-name-input"
          label={translate('GRANT_NAME_LABEL')}
          fullWidth
          value={grantName}
          onChange={(event) => setGrantName(event.target.value)}
        />
      </Grid>
      <Grid className="pt-3" item container xs={12}>
        <label className="flex items-center" htmlFor="grant-restricted">
          <Checkbox
            data-testid="grant-restricted"
            checked={!!restricted}
            color="primary"
            id="grant-restricted"
            name="restricted"
            onChange={(event) => setRestricted(event.target.checked)}
          />
          <span className="text-base font-normal">
            {translate('RESTRICTED_LABEL')}
          </span>
        </label>
      </Grid>
      {restricted && (
        <Grid className="pt-3" item container xs={12}>
          <TextField
            id="conditions-of-restrictions-input"
            label={translate('CONDITIONS_OF_RESTRICTION_LABEL')}
            fullWidth
            value={restrictionCondition}
            onChange={(event) => setRestrictionCondition(event.target.value)}
          />
        </Grid>
      )}
      <Grid className="pt-3" item container xs={12}>
        <TextSelectField
          id="interaction-employees-select-field"
          items={teamMembers}
          multiple
          value={developers}
          setValue={setDevelopers}
          label={translate('INTERACTION_EMPLOYEES_INVOLVED')}
        />
      </Grid>
      <Grid className="pt-3" item container xs={12}>
        <DonationPaymentMethodInput
          paymentMethod={paymentMethod}
          setPaymentMethod={setPaymentMethod}
          error={!!fieldErrors?.paymentMethod}
        />
      </Grid>
      <Grid className="pt-6" item container xs={12}>
        <NotesInput
          label={translate('DONATION_NOTES_LABEL')}
          notes={notes}
          setNotes={setNotes}
          maxLength={10000}
        />
      </Grid>
    </Grid>
  );
};

NewDonationTypeGrant.propTypes = {
  developers: PropTypes.arrayOf(CompanyTeamMember),
  donationAmount: PropTypes.number,
  fieldErrors: PropTypes.shape({
    donationAmount: PropTypes.bool,
    paymentMethod: PropTypes.bool,
  }),
  grantName: PropTypes.string,
  restricted: PropTypes.bool,
  restrictionCondition: PropTypes.string,
  notes: PropTypes.string,
  paymentMethod: TextSelectFieldValueType,
  setDevelopers: PropTypes.func,
  setDonationAmount: PropTypes.func,
  setGrantName: PropTypes.func,
  setRestricted: PropTypes.func,
  setRestrictionCondition: PropTypes.func,
  setNotes: PropTypes.func,
  setPaymentMethod: PropTypes.func,
  teamMembers: PropTypes.arrayOf(CompanyTeamMember),
};

NewDonationTypeGrant.defaultProps = {
  developers: [],
  donationAmount: 0,
  fieldErrors: {},
  grantName: null,
  restricted: false,
  restrictionCondition: null,
  notes: '',
  paymentMethod: TEXT_SELECT_FIELD_EMPTY_VALUE,
  setDevelopers: EMPTY_FUNC,
  setDonationAmount: EMPTY_FUNC,
  setGrantName: EMPTY_FUNC,
  setRestricted: EMPTY_FUNC,
  setRestrictionCondition: EMPTY_FUNC,
  setNotes: EMPTY_FUNC,
  setPaymentMethod: EMPTY_FUNC,
  teamMembers: [],
};

export { NewDonationTypeGrant };
