import userActionTypes from 'rdx/actionTypes';

const initialState = {
  data: [],
  isLoading: false,
  error: null,
};

const {
  FEATURE_FLAGS_LIST_REQUEST,
  FEATURE_FLAGS_LIST_SUCCESS,
  FEATURE_FLAGS_LIST_FAILURE,

  CREATE_FEATURE_FLAG_REQUEST,
  CREATE_FEATURE_FLAG_SUCCESS,
  CREATE_FEATURE_FLAG_FAILURE,

  UPDATE_FEATURE_FLAG_REQUEST,
  UPDATE_FEATURE_FLAG_SUCCESS,
  UPDATE_FEATURE_FLAG_FAILURE,

  REMOVE_FEATURE_FLAG_REQUEST,
  REMOVE_FEATURE_FLAG_SUCCESS,
  REMOVE_FEATURE_FLAG_FAILURE,
} = userActionTypes;

export function featureFlags(state = initialState, action) {
  switch (action.type) {
    case FEATURE_FLAGS_LIST_REQUEST:
    case CREATE_FEATURE_FLAG_REQUEST:
    case UPDATE_FEATURE_FLAG_REQUEST:
    case REMOVE_FEATURE_FLAG_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case FEATURE_FLAGS_LIST_SUCCESS:
      return {
        ...state,
        data: action.data.featureFlags,
        isLoading: false,
      };
    case FEATURE_FLAGS_LIST_FAILURE:
    case CREATE_FEATURE_FLAG_FAILURE:
    case UPDATE_FEATURE_FLAG_FAILURE:
    case REMOVE_FEATURE_FLAG_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };
    case CREATE_FEATURE_FLAG_SUCCESS:
      return {
        ...state,
        data: [...state.data, action.data.featureFlag],
        isLoading: false,
      };
    case UPDATE_FEATURE_FLAG_SUCCESS: {
      const { data } = state;
      return {
        ...state,
        data: data.map((flag) =>
          flag.flagName === action.data.featureFlag.flagName
            ? action.data.featureFlag
            : flag
        ),
        isLoading: false,
      };
    }
    case REMOVE_FEATURE_FLAG_SUCCESS: {
      const { data } = state;
      const removedFlagIdx = data.findIndex(
        (flag) => flag.flag_name === action.flagName
      );
      const newFlags = data
        .slice(0, removedFlagIdx)
        .concat(data.slice(removedFlagIdx + 1));
      return {
        ...state,
        data: [...newFlags],
        isLoading: false,
      };
    }
    default:
      return state;
  }
}
