import { translate } from 'lib/intl';
import {
  BoardMemberImage,
  MajorDonorImage,
  RecurringDonorImage,
  VolunteerImage,
  MemberImage,
} from 'assets/images';

export const DONOR_ATTRIBUTE_BOARD_MEMBER = 'boardMember';
export const DONOR_ATTRIBUTE_MAJOR = 'majorDonor';
export const DONOR_ATTRIBUTE_RECURRING = 'recurring';
export const DONOR_ATTRIBUTE_VOLUNTEER = 'volunteer';
export const DONOR_ATTRIBUTE_MEMBER = 'member';

export const DONOR_ATTRIBUTES_RENDER_ORDER = [
  DONOR_ATTRIBUTE_BOARD_MEMBER,
  DONOR_ATTRIBUTE_MAJOR,
  DONOR_ATTRIBUTE_RECURRING,
  DONOR_ATTRIBUTE_VOLUNTEER,
  DONOR_ATTRIBUTE_MEMBER,
];

export const DONOR_ATTRIBUTES = {
  [DONOR_ATTRIBUTE_BOARD_MEMBER]: {
    type: DONOR_ATTRIBUTE_BOARD_MEMBER,
    text: translate('BOARD_MEMBER_LABEL'),
    image: BoardMemberImage,
  },
  [DONOR_ATTRIBUTE_MAJOR]: {
    type: DONOR_ATTRIBUTE_MAJOR,
    text: translate('MAJOR_DONOR_LABEL'),
    image: MajorDonorImage,
  },
  [DONOR_ATTRIBUTE_RECURRING]: {
    type: DONOR_ATTRIBUTE_RECURRING,
    text: translate('RECURRING_LABEL'),
    image: RecurringDonorImage,
  },
  [DONOR_ATTRIBUTE_VOLUNTEER]: {
    type: DONOR_ATTRIBUTE_VOLUNTEER,
    text: translate('VOLUNTEER_LABEL'),
    image: VolunteerImage,
  },
  [DONOR_ATTRIBUTE_MEMBER]: {
    type: DONOR_ATTRIBUTE_MEMBER,
    text: translate('MEMBER_LABEL'),
    image: MemberImage,
  },
};
