import React from 'react';
import { arrayOf, string, shape } from 'prop-types';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { translate } from 'lib/intl';
import { FeedSliderContentAssignees } from './FeedSliderContentAssignees';

const FeedSliderGenericContent = ({ developers, notes, ownerOrgId }) => {
  const note = notes?.length ? notes[0].text : '';

  return (
    <>
      {note && (
        <Box pb={3}>
          <Box pb={2}>
            <Typography variant="subtitle1">
              {translate('FEED_SLIDER_GENERIC_NOTES_TITLE')}
            </Typography>
          </Box>
          <Typography style={{ whiteSpace: 'pre-line' }}>{note}</Typography>
        </Box>
      )}
      {Boolean(developers?.length) && (
        <Box>
          <Box pb={1}>
            <Typography variant="subtitle1">
              {translate('FEED_SLIDER_GENERIC_ASSIGNED_TITLE')}
            </Typography>
          </Box>
          <FeedSliderContentAssignees
            ownerOrgId={ownerOrgId}
            assignees={developers}
          />
        </Box>
      )}
    </>
  );
};

FeedSliderGenericContent.propTypes = {
  developers: arrayOf(
    shape({
      alias: string,
      userId: string,
    })
  ),
  notes: arrayOf(shape({ text: string })),
  ownerOrgId: string.isRequired,
};

FeedSliderGenericContent.defaultProps = {
  notes: null,
  developers: null,
};

export { FeedSliderGenericContent };
