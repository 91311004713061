import React from 'react';
import PropTypes from 'prop-types';
import { Grid, CircularProgress } from '@material-ui/core';
import { Visible, CustomButton } from 'components';

const defaultHeight = '48px';

const AuthActionButton = ({ className, isFetching, text }) => (
  <Grid
    container
    item
    xs={12}
    style={{
      justifyContent: 'center',
      marginTop: '30px',
    }}
  >
    <Visible when={isFetching}>
      <CircularProgress
        data-testid="auth-action-button-loading"
        color="primary"
        style={{
          height: defaultHeight,
          width: defaultHeight,
        }}
      />
    </Visible>
    <Visible when={!isFetching}>
      <CustomButton
        className={className}
        dataTestId="auth-action-button"
        buttonType="primary"
        type="submit"
        variant="contained"
        disabled={isFetching}
        color="primary"
        label={text}
      />
    </Visible>
  </Grid>
);

AuthActionButton.propTypes = {
  className: PropTypes.string,
  isFetching: PropTypes.bool.isRequired,
  text: PropTypes.string.isRequired,
};

AuthActionButton.defaultProps = {
  className: '',
};

export { AuthActionButton };
