import React from 'react';

import { func, string, shape, number } from 'prop-types';
import { EMPTY_FUNC, TEXT_SELECT_FIELD_EMPTY_VALUE } from 'lib/constants';
import { Grid, makeStyles } from '@material-ui/core';
import { NotesInput, CurrencyInput } from 'components';
import { translate } from 'lib/intl';
import { TextSelectFieldValueType } from 'lib/utils';
import { DonationPaymentMethodInput } from './donation-payment-method-input';

const useStyles = makeStyles(() => ({
  rowItem: {
    paddingTop: '12px',
  },
  notesContainer: {
    paddingTop: '24px',
  },
}));

const NewDonationTypeGeneral = ({
  fieldErrors,
  donationAmount,
  setDonationAmount,
  paymentMethod,
  setPaymentMethod,
  notes,
  setNotes,
}) => {
  const styles = useStyles();

  return (
    <Grid container>
      <Grid className={styles.rowItem} item container xs={12}>
        <CurrencyInput
          label={translate('DONATION_AMOUNT_LABEL')}
          amount={donationAmount}
          setAmount={setDonationAmount}
          error={!!fieldErrors?.donationAmount}
          errorHelperText={translate('DONATION_AMOUNT_ERROR_REQUIRED')}
        />
      </Grid>
      <Grid className={styles.rowItem} item container xs={12}>
        <DonationPaymentMethodInput
          paymentMethod={paymentMethod}
          setPaymentMethod={setPaymentMethod}
          error={!!fieldErrors?.paymentMethod}
        />
      </Grid>
      <Grid className={styles.notesContainer} item container xs={12}>
        <NotesInput
          label={translate('DONATION_NOTES_LABEL')}
          notes={notes}
          setNotes={setNotes}
          maxLength={10000}
        />
      </Grid>
    </Grid>
  );
};

NewDonationTypeGeneral.propTypes = {
  fieldErrors: shape({}),
  donationAmount: number,
  setDonationAmount: func,
  paymentMethod: TextSelectFieldValueType,
  setPaymentMethod: func,
  notes: string,
  setNotes: func,
};

NewDonationTypeGeneral.defaultProps = {
  fieldErrors: {},
  donationAmount: 0,
  setDonationAmount: EMPTY_FUNC,
  paymentMethod: TEXT_SELECT_FIELD_EMPTY_VALUE,
  setPaymentMethod: EMPTY_FUNC,
  notes: '',
  setNotes: EMPTY_FUNC,
};

export { NewDonationTypeGeneral };
