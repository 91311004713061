import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  label: {
    marginRight: '24px',
    [theme.breakpoints.down('sm')]: {
      marginRight: '0px',
    },
  },
  searchWrapper: {
    maxWidth: '360px',
    [theme.breakpoints.down('sm')]: {
      maxWidth: 'none',
    },
  },
}));

export { useStyles };
