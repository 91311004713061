/**
 * This holds the options used to render common select options. Every array of
 * options should have the `_OPTIONS` suffix.
 */
import { DonorAttributeOptionImageIcon } from 'components/common/DonorAttributeImageIcon';
import { DONATION_TYPES } from 'lib/configs/donations';
import { translate } from 'lib/intl';
import { ReportType, ReportDonorType } from 'lib/types/ReportFilterPropType';
import {
  DONOR_ATTRIBUTES,
  DONOR_ATTRIBUTES_RENDER_ORDER,
} from './donorAttributesConfig';

export const DONOR_TYPE_OPTIONS = [
  {
    value: ReportDonorType.INDIVIDUAL,
    text: translate('FORMS_DONOR_TYPE_OPTION_INDIVIDUAL'),
  },
  {
    value: ReportDonorType.ORGANIZATION,
    text: translate('FORMS_DONOR_TYPE_OPTION_ORGANIZATION'),
  },
];

export const REPORT_TYPE_OPTIONS = [
  {
    value: ReportType.CURRENT_STATE_DASHBOARD,
    text: translate('REPORTS_CURRENT_STATE_DASHBOARD'),
  },
  {
    value: ReportType.NEW_DONOR_GROWTH,
    text: translate('REPORTS_NEW_DONOR_GROWTH'),
  },
  {
    value: ReportType.CAMPAIGN_OVERVIEW,
    text: translate('REPORTS_CAMPAIGN_OVERVIEW'),
  },
];

export const DONATION_TYPE_OPTIONS = Object.keys(DONATION_TYPES || {})
  // Remove slice when MAJOR_GIFT DONATION_TYPES is removed
  .slice(1)
  .map((key) => {
    const type = DONATION_TYPES[key];

    return {
      value: type.value,
      icon: type.icon,
      text: type.displayText,
      featureFlag: type.featureFlag,
    };
  });

export const DONOR_ATTRIBUTE_OPTIONS = DONOR_ATTRIBUTES_RENDER_ORDER.map(
  (attributeType) => {
    const attribute = DONOR_ATTRIBUTES[attributeType];

    return {
      component: DonorAttributeOptionImageIcon,
      text: attribute.text,
      value: attribute.type,
    };
  }
);
