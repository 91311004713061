import PropTypes from 'prop-types';
import { ConstituentType } from 'lib/types/ConstituentPropType';

export const ConstituentRules = Object.freeze({
  IS_DONOR_RULESET: 'is_donor_ruleset',
  IS_MAJOR_DONOR_RULESET: 'is_major_donor_ruleset',
  IS_RECURRING_DONOR_RULESET: 'is_recurring_donor_ruleset',
});

export const ConstituentCategories = Object.freeze({
  CATEGORY_BOARD_MEMBER: 'category_board_member',
  CATEGORY_CORPORATION: 'category_corporation',
  CATEGORY_FOUNDATION: 'category_foundation',
  CATEGORY_MEMBER: 'category_member',
  CATEGORY_PARTNER: 'category_partner',
  CATEGORY_RECIPIENT: 'category_recipient',
  CATEGORY_VOLUNTEER: 'category_volunteer',
});

export const SortBy = Object.freeze({
  DISPLAY_NAME: 'display_name',
  LAST_INTERACTION_DATE: 'last_interaction_date',
});

export const SortOrder = Object.freeze({
  ASC: 'asc',
  DESC: 'desc',
});

const ConstituentFilterPropType = PropTypes.shape({
  userIds: PropTypes.string, // TODO(jon): Eventually this should support an array of IDs to match the API.
  displayName: PropTypes.string,
  sortBy: PropTypes.oneOf(Object.values(SortBy)),
  sortOrder: PropTypes.oneOf(Object.values(SortOrder)),
  constituentRules: PropTypes.arrayOf(
    PropTypes.oneOf(Object.values(ConstituentRules))
  ),
  constituentCategories: PropTypes.arrayOf(
    PropTypes.oneOf(Object.values(ConstituentCategories))
  ),
  constituentType: PropTypes.oneOf(Object.values(ConstituentType)),
});

export default ConstituentFilterPropType;
