import React, { useCallback, useEffect, useState } from 'react';
import { arrayOf, bool, func, number, shape, string } from 'prop-types';
import Box from '@material-ui/core/Box';
import {
  CustomTable,
  Loader,
  ProfileDescriptor,
  ToastFeedback,
} from 'components';
import { COMPANY_PAGE_TABS_ENUMS, TOAST_FEEDBACK_IDS } from 'lib/constants';
import { LOADER_SIZES } from 'lib/constants/common';
import {
  CompanyTeamMember,
  CustomTableFilterProps,
  sortAndFilterCustomTableData,
  GroupType,
  isMobileView,
} from 'lib/utils';
import { SEARCHABLE_FIELDS } from './config';

const DEFAULT_POPUP_PROPS = {
  anchor: null,
  displayName: '',
  userId: '',
  isOpen: false,
};

const CompanyTeamMembers = ({
  config,
  teamMembers,
  isLoading,
  ownerOrgId,
  ownerOrgName,
  groups,
  filter,
  setFilter,
}) => {
  const { key, CardComponent, columns: columnsConfig, idField } = config;

  // TODO: revisit the logic
  const isTeamMembersType = key === COMPANY_PAGE_TABS_ENUMS.TEAM_MEMBERS;
  const canRemoveLastMember = !(isTeamMembersType && teamMembers?.length === 1);
  const columns = canRemoveLastMember
    ? columnsConfig
    : // gets rid of the last column with a removal button
      columnsConfig.slice(0, columnsConfig.length - 1);

  const [membersData, setMembersData] = useState(
    sortAndFilterCustomTableData({
      data: teamMembers,
      columns,
      sorting: filter,
      search: filter?.searchValue,
      searchableFields: SEARCHABLE_FIELDS,
    })
  );

  const [popupProps, setPopupProps] = useState(DEFAULT_POPUP_PROPS);

  useEffect(() => {
    if (groups?.length) {
      setMembersData(
        sortAndFilterCustomTableData({
          data: teamMembers,
          columns,
          sorting: filter,
          search: filter?.searchValue,
          searchableFields: SEARCHABLE_FIELDS,
        })
      );
    }
  }, [groups, filter, teamMembers]);

  const isMobile = isMobileView();

  const onCellClick = useCallback(({ data, element }) => {
    const { displayName, userId } = data;

    if (!userId) {
      return;
    }

    setPopupProps({
      anchor: element,
      displayName,
      userId,
      isOpen: true,
    });
  }, []);

  const closeProfilePopup = useCallback(
    () => setPopupProps(DEFAULT_POPUP_PROPS),
    []
  );

  const TeamMembers = isMobile ? (
    membersData?.map((data, idx) => (
      <CardComponent
        data={data}
        hasBottomBorder={idx === membersData.length - 1}
        key={data?.id || idx}
        onClick={onCellClick}
        ownerOrgId={ownerOrgId}
        ownerOrgName={ownerOrgName}
        // hides the remove button for team members in case there is only one member left
        canRemove={canRemoveLastMember}
        groups={groups}
      />
    ))
  ) : (
    <CustomTable
      columns={columns}
      data={membersData}
      filter={filter}
      idField={idField}
      onRowClick={onCellClick}
      setFilter={setFilter}
    />
  );

  return (
    <Box overflow="hidden" px={isMobile ? 0 : 2}>
      <ToastFeedback
        containerId={TOAST_FEEDBACK_IDS.TEAM_MEMBERS}
        stickToModule={!isMobile}
      />
      {isLoading ? (
        <Box display="flex" justifyContent="center" p={3}>
          <Loader height={LOADER_SIZES.LG} />
        </Box>
      ) : (
        TeamMembers
      )}
      {Boolean(popupProps.anchor) && (
        <ProfileDescriptor
          onClose={closeProfilePopup}
          ownerOrgId={ownerOrgId}
          {...popupProps}
        />
      )}
    </Box>
  );
};

CompanyTeamMembers.propTypes = {
  config: shape({
    CardComponent: func.isRequired,
    columns: arrayOf(
      shape({
        field: string.isRequired,
        headerName: string,
        width: number,
        renderCell: func,
        sortable: bool,
        disableClickEventBubbling: bool,
      })
    ),
  }).isRequired,
  teamMembers: arrayOf(CompanyTeamMember),
  isLoading: bool,
  ownerOrgId: string,
  ownerOrgName: string,
  groups: GroupType.isRequired,
  filter: CustomTableFilterProps.isRequired,
  setFilter: func.isRequired,
};

CompanyTeamMembers.defaultProps = {
  teamMembers: [],
  isLoading: false,
  ownerOrgId: '',
  ownerOrgName: '',
};

export { CompanyTeamMembers };
