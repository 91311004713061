import loadScript from './loadScript';

export function loadD3Script() {
  return loadScript(
    'https://cdnjs.cloudflare.com/ajax/libs/d3/6.6.2/d3.min.js',
    {
      integrity:
        'sha384-g91RMmC1dm7SdAtGm0hKXUek3ThOdW49TfMD7mP1tflw4Rk49E3T0lorWnRt2QcR',
      crossOrigin: 'anonymous',
      async: true,
    }
  );
}

export function loadD3TimeScript() {
  return loadScript(
    'https://cdnjs.cloudflare.com/ajax/libs/d3-time-format/2.1.1/d3-time-format.min.js',
    {
      integrity:
        'sha384-cV+UVh1fewxYgADHl66JZYtn4ikYvn/KVEgy9CbVce8eylaBlBMTRUTq81mBpyo5',
      crossOrigin: 'anonymous',
      async: true,
    }
  );
}
