import React from 'react';
import PropTypes from 'prop-types';
import { camelCase } from 'voca';
import { EMPTY_FUNC } from 'lib/constants';
import ReportFilterPropType, { DateType } from 'lib/types/ReportFilterPropType';
import ReportModulesPropType from 'lib/types/ReportModulesPropType';
import {
  ORDERED_REPORT_MODULE_TYPES,
  REPORT_DATA_KEYS,
} from 'lib/constants/reports';
import D3ChartTooltipProvider from 'components/reports/D3ChartTooltipProvider';
import DonationsOverTimeReportContainer from 'components/reports/DonationsOverTimeReportContainer';
import DonationSummaryReport from './DonationSummaryReport';
import DonorListReport from './DonorListReport';

const ReportModules = ({ dateType, filters, modules, onSort }) => {
  function findComponent(moduleName, module) {
    switch (moduleName) {
      case REPORT_DATA_KEYS.DONATION_SUMMARY:
        return (
          <DonationSummaryReport
            module={module.report}
            dateType={dateType}
            key={moduleName}
          />
        );
      case REPORT_DATA_KEYS.DONOR_LIST:
        return (
          <DonorListReport
            filters={filters}
            key={moduleName}
            onSort={onSort}
            pagination={module.meta}
          />
        );
      case REPORT_DATA_KEYS.DONATIONS_OVER_TIME: {
        // Convert totalValue from cents to dollars so it is easier to work
        // with.
        const donations = React.useMemo(
          () =>
            module.report.map((donation) => ({
              ...donation,
              totalValue: donation.totalValue / 100,
            })),
          [module.report]
        );

        return (
          <D3ChartTooltipProvider key={moduleName}>
            <DonationsOverTimeReportContainer
              donations={donations}
              filters={filters}
            />
          </D3ChartTooltipProvider>
        );
      }
      default:
        return null;
    }
  }
  return (
    <div className="flex flex-col">
      {ORDERED_REPORT_MODULE_TYPES[filters.reportType].map((module) => {
        const moduleName = camelCase(module);

        if (modules[moduleName]) {
          return findComponent(moduleName, modules[moduleName]);
        }

        return null;
      })}
    </div>
  );
};

ReportModules.propTypes = {
  dateType: PropTypes.oneOf(Object.values(DateType)),
  filters: ReportFilterPropType.isRequired,
  modules: ReportModulesPropType,
  onSort: PropTypes.func,
};
ReportModules.defaultProps = {
  dateType: DateType.CALENDAR_YTD,
  modules: {},
  onSort: EMPTY_FUNC,
};

export default ReportModules;
