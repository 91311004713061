import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/styles/makeStyles';
import { translate } from 'lib/intl';
import { isMobileView } from 'lib/utils';
import {
  BoardMemberIcon,
  VolunteerIcon,
  MemberIcon,
  AttributeCorporationIcon,
  AttributeDonorIcon,
  AttributeFoundationIcon,
  AttributePartnerIcon,
  AttributeMajorDonorIcon,
  AttributeRecipientIcon,
  AttributeRecurringIcon,
} from 'lib/configs/donor-icons';
import ConstituentFilterPropType, {
  ConstituentType,
} from 'lib/types/ConstituentPropType';

const useStyles = makeStyles((theme) => ({
  iconContainer: {
    borderRadius: '5px',
  },
  icon: {
    borderTopRightRadius: '0px !important',
    borderBottomRightRadius: '0px !important',
    height: '28px !important',
    marginRight: `${theme.spacing(0.75)}px !important`,
    width: '28px !important',
    '& img': {
      maxHeight: '18px !important',
      maxWidth: '18px !important',
    },
  },
}));

const Categories = ({ categories }) => {
  const styles = useStyles();
  const isMobile = isMobileView();

  return (
    <Box className="flex flex-wrap">
      {categories.map(({ caption, display, icon, name }) => {
        return display ? (
          <Box
            key={name}
            className={classNames(
              'items-center border border-box flex pr-1.5 mb-4 mr-3 last:mr-0',
              {
                [styles.iconContainer]: true,
                'border-attribute-boardMember': name === 'boardMember',
                'border-attribute-partner': name === 'partner',
                'border-attribute-member': name === 'member',
                'border-attribute-recipient': name === 'recipient',
                'border-attribute-vip': name === 'vip',
                'border-attribute-volunteer': name === 'volunteer',
                'border-attribute-corporation': name === 'corporation',
                'border-attribute-foundation': name === 'foundation',
                'border-attribute-automation': [
                  'donor',
                  'majorDonor',
                  'recurring',
                ].includes(name),
              }
            )}
          >
            {React.cloneElement(icon, { className: styles.icon })}
            <Typography variant={isMobile ? 'caption' : 'body2'}>
              {caption}
            </Typography>
          </Box>
        ) : null;
      })}
    </Box>
  );
};

Categories.propTypes = {
  categories: PropTypes.arrayOf(
    PropTypes.shape({
      caption: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    })
  ).isRequired,
};

const ConstituentInfoAttributes = ({ constituent }) => {
  if (!constituent) return null;

  const {
    categoryBoardMember,
    categoryCorporation,
    categoryFoundation,
    categoryMember,
    categoryPartner,
    categoryRecipient,
    categoryVolunteer,
    constituentType,
    isDonorRuleset,
    isMajorDonorRuleset,
    isRecurringDonorRuleset,
  } = constituent;

  // Two of the components are using React.cloneElement to modify their props,
  // so using useMemo to prevent needless code execution.
  const categoryProps = React.useMemo(
    () => [
      {
        name: 'boardMember',
        display: categoryBoardMember,
        icon: BoardMemberIcon,
        caption: translate('BOARD_MEMBER_LABEL'),
        label: translate('CONSTITUENT_BOARD_MEMBER_LABEL'),
      },
      {
        name: 'recipient',
        display: categoryRecipient,
        caption: translate('CONSTITUENT_RECIPIENT_LABEL'),
        icon: React.cloneElement(AttributeRecipientIcon, {
          size: 'md',
        }),
      },
      {
        name: 'corporation',
        display: constituentType === ConstituentType.ORG && categoryCorporation,
        caption: translate('CONSTITUENT_CORPORATION_LABEL'),
        icon: React.cloneElement(AttributeCorporationIcon, {
          size: 'md',
        }),
      },
      {
        name: 'foundation',
        display: constituentType === ConstituentType.ORG && categoryFoundation,
        caption: translate('CONSTITUENT_FOUNDATION_LABEL'),
        icon: React.cloneElement(AttributeFoundationIcon, {
          size: 'md',
        }),
      },
      {
        name: 'member',
        display: categoryMember,
        icon: MemberIcon,
        caption: translate('CONSTITUENT_MEMBER_LABEL'),
      },
      {
        name: 'partner',
        display: categoryPartner,
        caption: translate('CONSTITUENT_PARTNER_LABEL'),
        icon: React.cloneElement(AttributePartnerIcon, {
          size: 'md',
        }),
      },
      {
        name: 'volunteer',
        display: categoryVolunteer,
        icon: VolunteerIcon,
        caption: translate('CONSTITUENT_VOLUNTEER_LABEL'),
      },
    ],
    [constituent]
  );

  const automatedCategoryProps = [
    {
      name: 'donor',
      display: isDonorRuleset,
      icon: AttributeDonorIcon,
      caption: translate('CONSTITUENT_DONOR_LABEL'),
    },
    {
      name: 'majorDonor',
      display: isMajorDonorRuleset,
      icon: AttributeMajorDonorIcon,
      caption: translate('CONSTITUENT_MAJOR_DONOR_LABEL'),
    },
    {
      name: 'recurring',
      display: isRecurringDonorRuleset,
      icon: AttributeRecurringIcon,
      caption: translate('CONSTITUENT_RECURRING_LABEL'),
    },
  ];

  const canShowAutomationCategories = [
    isDonorRuleset,
    isMajorDonorRuleset,
    isRecurringDonorRuleset,
  ].some((ruleset) => ruleset);

  return (
    <>
      <Categories categories={categoryProps} />
      <Divider className="mb-4" />

      {canShowAutomationCategories && (
        <>
          <Categories categories={automatedCategoryProps} />
          <Divider className="mb-4" />
        </>
      )}
    </>
  );
};

ConstituentInfoAttributes.propTypes = {
  constituent: ConstituentFilterPropType.isRequired,
};

export default ConstituentInfoAttributes;
