import { useEffect, useMemo, useRef, useState } from 'react';
import { campaignsActions, orgInfoActions } from 'rdx/actions';
import { useDispatch, useSelector } from 'react-redux';
import { generatePath, useHistory } from 'react-router-dom';
import { props } from 'rambdax';
import { AUTH_ROUTES, CAMPAIGN_STATUS_ENUM } from 'lib/constants';

const CAMPAIGN_KEYS_FOR_SEARCH = [
  'name',
  'displayName',
  'description',
  'url',
  'goalAmount',
  'totalAmount',
  'expectedProgress',
  'numDonorConstituents',
  'avgDonation',
  'maxDonation',
  'numNewDonors',
  'numReturningDonors',
];

const useCampaignsPage = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [showArchived, setShowArchived] = useState(false);
  const [search, setSearch] = useState('');

  const {
    campaignsState,
    ownerOrgId,
    removeCampaignsState,
    updateCampaignsState,
    orgInfo,
  } = useSelector((state) => ({
    campaignsState: state.campaigns,
    ownerOrgId: state.authentication?.ownerOrgId,
    removeCampaignsState: state.removeCampaign,
    updateCampaignsState: state.updateMultipleCampaigns,
    orgInfo: state.orgInfo,
  }));

  const {
    data: campaigns,
    isFetching: areCampaignsLoading,
    dataLoaded: areCampaignsLoaded,
  } = campaignsState;

  const {
    data: orgInfoData,
    isFetching: isOrgInfoLoading,
    dataLoaded: isOrgInfoLoaded,
  } = orgInfo;

  const fetchCampaigns = () =>
    dispatch(campaignsActions.fetchCampaigns(ownerOrgId));

  const removeCampaign = (campaignId) =>
    dispatch(campaignsActions.removeCampaign(campaignId));

  const openCampaignPage = (campaignId) =>
    history.push(
      generatePath(AUTH_ROUTES.CAMPAIGN, {
        campaignId,
      })
    );

  useEffect(() => {
    if (!isOrgInfoLoaded && !isOrgInfoLoading) {
      dispatch(orgInfoActions.fetchOrgInfo(ownerOrgId));
    }

    if (!areCampaignsLoading) {
      fetchCampaigns();
    }
  }, []);

  const filteredCampaigns = useMemo(
    () =>
      campaigns.filter((campaign) => {
        let show =
          showArchived || campaign.status !== CAMPAIGN_STATUS_ENUM.ARCHIVED;

        if (search) {
          show = JSON.stringify(props(CAMPAIGN_KEYS_FOR_SEARCH, campaign))
            .toLowerCase()
            .includes(search.toLowerCase());
        }

        return show;
      }),
    [campaigns, search, showArchived]
  );

  const updateCampaign = (campaignData, newData) => {
    const { campaignId, campaignType, displayName } = campaignData;

    dispatch(
      campaignsActions.updateMultipleCampaigns({
        data: {
          campaignId,
          campaignType,
          ownerOrgId,
          ...newData,
        },
        campaignId,
        name: displayName,
      })
    );
  };

  const showPageLoader = !areCampaignsLoaded || !isOrgInfoLoaded;

  const { totalCampaignRevenue, numDonorConstituents, avgCampaignDonation } =
    orgInfoData?.summaryMetrics?.yearToDate || {};

  return {
    areCampaignsLoading,
    campaigns: filteredCampaigns,
    openCampaignPage,
    removeCampaign,
    removeCampaignsState,
    showArchived,
    setShowArchived,
    search,
    setSearch,
    updateCampaignsState,
    updateCampaign,
    showPageLoader,
    // metrics related
    totalCampaignRevenue,
    numDonorConstituents,
    avgCampaignDonation,
  };
};

// TODO: DOT-409 integrate api once available and revisit the hook
const useGoals = () => {
  const dispatch = useDispatch();

  const { updateCampaignFiscalGoal } = useSelector((state) => ({
    updateCampaignFiscalGoal: state.updateCampaignFiscalGoal,
  }));

  const { isFetching } = updateCampaignFiscalGoal;

  const goalFormModalRef = useRef();
  const [showGoalModal, setShowGoalModal] = useState(false);

  const openGoalsFormModal = () => setShowGoalModal(true);
  const closeGoalsFormModal = () => setShowGoalModal(false);

  const saveGoal = (goal) => {
    dispatch(campaignsActions.updateCampaignFiscalGoal(goal));
  };

  useEffect(() => {
    if (!isFetching && showGoalModal) {
      closeGoalsFormModal();
    }
  }, [isFetching]);

  return {
    // expectedProgress,
    // goalAmount,
    // timeLeft,
    // totalPercentage,
    // CampaignGoalForm modal related props
    isGoalUpdating: isFetching,
    goalFormModalRef,
    showGoalModal,
    openGoalsFormModal,
    closeGoalsFormModal,
    saveGoal,
  };
};

export { useCampaignsPage, useGoals };
