import userActionTypes from 'rdx/actionTypes';

const {
  UPDATE_CAMPAIGNS_FISCAL_GOAL_REQUEST,
  UPDATE_CAMPAIGNS_FISCAL_GOAL_SUCCESS,
  UPDATE_CAMPAIGNS_FISCAL_GOAL_FAILURE,
} = userActionTypes;

const initialState = {
  dataLoaded: false,
  isFetching: false,
  error: null,
  data: {},
};

export function updateCampaignFiscalGoalReducer(state = initialState, action) {
  switch (action.type) {
    case UPDATE_CAMPAIGNS_FISCAL_GOAL_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: null,
      };
    case UPDATE_CAMPAIGNS_FISCAL_GOAL_SUCCESS:
      return {
        ...state,
        data: action.data,
        dataLoaded: true,
        isFetching: false,
        error: null,
      };
    case UPDATE_CAMPAIGNS_FISCAL_GOAL_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.error,
      };
    default:
      return state;
  }
}
