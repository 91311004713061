import { intl } from './intl';

const translateWithIntl = (_intl) => (id, values) =>
  _intl.formatMessage(
    {
      id,
    },
    values
  );

const translate = translateWithIntl(intl);

export { translate };
