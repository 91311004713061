import React from 'react';
import { Box, makeStyles } from '@material-ui/core';
import { Star } from '@material-ui/icons';
import { string, shape, arrayOf, bool } from 'prop-types';
import { ProfileDescriptorLink } from 'components';

const useStyles = makeStyles((theme) => ({
  icon: {
    width: '16px',
    height: '16px',
    paddingLeft: theme.spacing(1),
  },
  detailTitle: {
    color: theme.palette.grey.dark,
    paddingBottom: theme.spacing(0.5),
  },
  detailContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  assigneeItem: {
    display: 'flex',
    alignItems: 'center',
  },
}));

const FeedSliderContentAssignees = ({ assignees, ownerOrgId }) => {
  const styles = useStyles();

  return (
    <Box>
      {assignees.map((assignee) => {
        const { alias, displayName, userId, primary } = assignee;

        return (
          <Box
            key={`slider-assignee-${userId}`}
            className={styles.assigneeItem}
          >
            <ProfileDescriptorLink
              displayName={displayName || alias}
              ownerOrgId={ownerOrgId}
              userId={userId}
            />
            {primary && (
              <Star
                data-testid="primary-assignee"
                className={styles.icon}
                color="primary"
              />
            )}
          </Box>
        );
      })}
    </Box>
  );
};

FeedSliderContentAssignees.defaultProps = {
  assignees: [],
};

FeedSliderContentAssignees.propTypes = {
  assignees: arrayOf(
    shape({
      alias: string,
      displayName: string,
      primary: bool,
      userId: string,
    })
  ),
  ownerOrgId: string.isRequired,
};

export { FeedSliderContentAssignees };
