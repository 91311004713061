import React from 'react';
import Alert from '@material-ui/lab/Alert';
import Box from '@material-ui/core/Box';
import { useDispatch, useSelector } from 'react-redux';
import {
  CustomButton,
  Loader,
  PageLoader,
  SearchInput,
} from 'components/common';
import CreateOrgDialog from 'components/orgManagement/CreateOrgDialog';
import CreateOrgAlert from 'components/orgManagement/CreateOrgAlert';
import OrgListTable from 'components/orgManagement/OrgListTable';
import { SEARCH_INPUT_MAX_WIDTH } from 'lib/constants';
import { LOADER_SIZES } from 'lib/constants/common';
import { translate } from 'lib/intl';
import { isMobileView } from 'lib/utils';
import { orgActions } from 'rdx/actions';

export default function OrgManagementPage() {
  const isMobile = isMobileView();
  const dispatch = useDispatch();
  const [search, setSearch] = React.useState('');
  const [isCreateDialogOpen, setIsCreateDialogOpen] = React.useState(false);
  const [isInitialFetch, setIsInitialFetch] = React.useState(true);

  const {
    error: orgFetchError,
    hasNextPage,
    items,
    isFetching,
    nextPage: orgNextPage,
  } = useSelector((state) => ({
    error: state.orgList?.error,
    hasNextPage: state.orgList?.hasNextPage,
    items: state.orgList?.orgs || [],
    isFetching: state.orgList?.isFetching,
    nextPage: state.orgList?.nextPage,
  }));

  const [creationResponse, setCreationResponse] = React.useState(null);

  function fetchOrgs(page) {
    return orgActions.fetchOrgs({ page });
  }

  React.useEffect(() => {
    dispatch(fetchOrgs());
    setIsInitialFetch(false);
  }, []);

  const handleShowMoreClick = React.useCallback(async () => {
    await dispatch(fetchOrgs(orgNextPage));
  }, [orgNextPage]);

  const handleCreateDialogClose = () => {
    setIsCreateDialogOpen(false);
  };
  const handleCreateDialogOpen = () => {
    setIsCreateDialogOpen(true);
  };

  const handleCreateResponse = (status) => setCreationResponse(status);
  const handleAlertClose = () => setCreationResponse(null);

  const alert = (
    <CreateOrgAlert response={creationResponse} onClose={handleAlertClose} />
  );

  if (isInitialFetch) {
    return <PageLoader isVisible />;
  }

  if (orgFetchError) {
    return (
      <Alert severity="error" icon={false}>
        {translate('ORG_MGMT_FETCH_ORGS_ERROR')}
      </Alert>
    );
  }

  return (
    <>
      <Box
        display="flex"
        flexWrap={isMobile ? 'wrap' : 'nowrap'}
        justifyContent={isMobile ? 'flex-end' : 'space-between'}
        alignItems="center"
      >
        <Box
          flex={isMobile ? '1 1 100%' : 1}
          maxWidth={isMobile ? 'none' : SEARCH_INPUT_MAX_WIDTH}
          mr={isMobile ? 0 : 1}
        >
          <SearchInput
            onChange={setSearch}
            placeholder={translate('ORG_MGMT_SEARCH_LABEL')}
            value={search}
          />
        </Box>

        {isMobile && alert}

        <Box mt={{ xs: 1, sm: 1 }} mb={{ xs: 1, sm: 1 }}>
          <CustomButton
            buttonType="primary"
            label={
              <Box component="span" fontWeight={500}>
                {translate('ORG_MGMT_CREATE_ORG_CTA')}
              </Box>
            }
            onClick={handleCreateDialogOpen}
            style={{
              height: '48px',
            }}
            type="primary"
            variant="text"
          />
        </Box>
      </Box>

      {!isMobile && alert}

      <Box>
        <OrgListTable items={items} search={search} />
      </Box>

      {hasNextPage && (
        <Box display="flex" justifyContent="center" mt={2}>
          {isFetching ? (
            <Loader
              alignItems="center"
              justifyContent="center"
              display="flex"
              size={LOADER_SIZES.LG}
            />
          ) : (
            <CustomButton
              buttonType="primary"
              disabled={isFetching}
              label={translate('ORG_MGMT_SHOW_MORE')}
              onClick={handleShowMoreClick}
              variant="text"
            />
          )}
        </Box>
      )}

      <CreateOrgDialog
        isOpen={isCreateDialogOpen}
        onClose={handleCreateDialogClose}
        onCreationResponse={handleCreateResponse}
      />
    </>
  );
}
