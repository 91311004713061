import {
  endOfWeek,
  format as formatDate,
  parseISO,
  startOfDay,
  startOfMonth,
  startOfWeek,
} from 'date-fns';
import { pipe, partial, reduce, map, values } from 'rambdax';
import {
  REPORTS_DAILY_TIME_PERIOD,
  REPORTS_MONTHLY_TIME_PERIOD,
  REPORTS_WEEKLY_TIME_PERIOD,
  REPORTS_X_AXIS_DAILY_FORMAT,
  REPORTS_X_AXIS_MONTHLY_FORMAT,
  REPORTS_X_AXIS_WEEKLY_FORMAT,
} from 'lib/constants';

/**
 * @typedef {Object} Donation
 * @property {String} date
 * @property {String} donation
 */

/**
 * Converts date string to the start of the day so that all dates have the same
 * time. This helps with grouping them.
 *
 * @param {Donation[]}
 * @returns {Donation[]}
 */
const mapDate = map((item) => ({
  ...item,
  date: startOfDay(parseISO(item.date)).toISOString(),
}));

/**
 * Converts the date value to the corresponding start of the timePeriod param.
 * IE, if timePeriod is weekly, change the date so that is it the start of the
 * week.
 *
 * @param {Donation[]}
 * @returns {Donation[]}
 */
const mapDateByTimePeriod = (timePeriod, item) => {
  const date = new Date(item.date);
  const isWeekly = timePeriod === REPORTS_WEEKLY_TIME_PERIOD;

  return {
    ...item,
    date: startOfDay(
      isWeekly ? startOfWeek(date) : startOfMonth(date)
    ).toISOString(),
  };
};

/**
 * Partials of the mapDateByTimePeriod above for ease of use in "piped"
 * functions.
 *
 * @param {Donation[]}
 * @returns {Donation[]}
 */
const mapDateToWeek = map(
  partial(mapDateByTimePeriod, REPORTS_WEEKLY_TIME_PERIOD)
);
const mapDateToMonth = map(
  partial(mapDateByTimePeriod, REPORTS_MONTHLY_TIME_PERIOD)
);

/**
 * Groups donations by day, providing a sum of all donations
 *
 * @param {Donation[]}
 * @returns {Donation[]}
 */
const sumByDay = pipe(
  mapDate,
  reduce((acc, item) => {
    const { date } = item;

    if (!acc[date]) {
      acc[date] = {
        date,
        donations: 0,
      };
    }

    acc[date].donations += item.donation;

    return acc;
  }, {}),
  values
);

/**
 * This function creates a new 'reduceByStartEnd' function. If we use the
 * reduce function directly multiple times, all of the calls use the same
 * function and the accumulator will be reused across subsequent calls.
 *
 * @param {Donation[]}
 * @returns {Donation[]}
 */
const makeReduceByStartEnd = (...args) =>
  reduce((acc, item) => {
    const { date } = item;

    if (!acc[date]) {
      acc[date] = {
        date,
        donations: 0,
      };
    }

    acc[date].donations += item.donation;

    return acc;
  }, {})(...args);

/**
 * Groups donations by week, providing a sum of all donations
 *
 * @param {Donation[]}
 * @returns {Donation[]}
 */
const sumByWeek = pipe(mapDateToWeek, makeReduceByStartEnd(), values);

/**
 * Groups donations by week, providing a sum of all donations
 *
 * @param {Donation[]}
 * @returns {Donation[]}
 */
const sumByMonth = pipe(mapDateToMonth, makeReduceByStartEnd(), values);

/**
 * Formats dates according to the supplied format
 *
 * @param {String} format
 * @param {String} date
 * @returns {String} formatted date, ie 'Apr 15'
 */
const formatDateByTimePeriod = (timePeriod, format, date) => {
  return pipe(
    parseISO,
    // eslint-disable-next-line no-shadow
    (date) => {
      if (timePeriod === REPORTS_WEEKLY_TIME_PERIOD) {
        const start = formatDate(startOfWeek(date), format);
        const end = formatDate(endOfWeek(date), format);

        return `${start} - ${end}`;
      }

      return formatDate(date, format);
    }
  )(date);
};

/**
 * TODO: refactor this to be less complicated. Maybe keep this function but
 * rename it so we can use it with tickFormat. But the resulting functions
 * should be turned into separate functions that can be exported from this file
 * as well.
 *
 * Convenience function that formats dates according to the supplied format
 *
 * @param {String} format
 * @returns {String} formatted date, ie 'Apr 15'
 */
const formatDateForReport = (timePeriod) => {
  return (date) => {
    switch (timePeriod) {
      default:
      case REPORTS_DAILY_TIME_PERIOD:
        return formatDateByTimePeriod(
          timePeriod,
          REPORTS_X_AXIS_DAILY_FORMAT,
          date
        );
      case REPORTS_WEEKLY_TIME_PERIOD:
        return formatDateByTimePeriod(
          timePeriod,
          REPORTS_X_AXIS_WEEKLY_FORMAT,
          date
        );
      case REPORTS_MONTHLY_TIME_PERIOD:
        return formatDateByTimePeriod(
          timePeriod,
          REPORTS_X_AXIS_MONTHLY_FORMAT,
          date
        );
    }
  };
};

export {
  formatDateByTimePeriod,
  formatDateForReport,
  mapDate,
  mapDateByTimePeriod,
  mapDateToMonth,
  mapDateToWeek,
  sumByDay,
  sumByWeek,
  sumByMonth,
};
