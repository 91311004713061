import React from 'react';
import PropTypes from 'prop-types';
import {
  ListItem,
  ListItemAvatar,
  Typography,
  makeStyles,
} from '@material-ui/core';
import { NavLink } from 'react-router-dom';
import { Avatar } from 'components';
import { isMobileView } from 'lib/utils';
import { ICON_SIZES } from 'lib/constants/common';

const useStyles = makeStyles((theme) => ({
  navContainer: {
    padding: '0px',
    [theme.breakpoints.up('sm')]: {
      justifyContent: 'center',
    },
  },
  navItemWrap: {
    width: '100%',
  },
  navItem: {
    display: 'flex',
    textDecoration: 'none',
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
    [theme.breakpoints.up('xs')]: {
      justifyContent: 'center',
      '&.active > div': {
        backgroundColor: theme.palette.background.white,
        border: `1px solid ${theme.palette.grey.light}`,
      },
    },
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'left',
      '&.active': {
        backgroundColor: theme.palette.grey.light,
      },
    },
  },
  avatar: {
    background: 'none',
  },
  link: {
    alignSelf: 'center',
    fontWeight: theme.typography.fontWeightMedium,
    textDecoration: 'none',
    color: theme.palette.common.black,
    paddingLeft: theme.spacing(2),
  },
  icon: {
    height: ICON_SIZES.LG,
    width: ICON_SIZES.LG,
  },
}));

const NavigationListItem = ({
  children,
  navRoute,
  icon,
  iconAlt,
  itemText,
  exact,
  onClick,
}) => {
  const classes = useStyles();
  const imageTestId = `navigation-list-item-image-${iconAlt}`;

  return (
    <ListItem
      data-testid="navigation-list-item"
      button
      className={classes.navContainer}
    >
      <ListItemAvatar className={classes.navItemWrap}>
        <NavLink
          className={classes.navItem}
          to={navRoute}
          exact={exact}
          onClick={onClick}
        >
          {!children && icon ? (
            <Avatar className={classes.avatar} size={ICON_SIZES.XL}>
              <img
                alt={iconAlt}
                className={classes.icon}
                data-testid={imageTestId}
                src={icon}
              />
            </Avatar>
          ) : (
            children
          )}

          {isMobileView('xs') && itemText && (
            <Typography
              className={classes.link}
              variant="subtitle1"
              data-testid="navigation-list-item-text"
            >
              {itemText}
            </Typography>
          )}
        </NavLink>
      </ListItemAvatar>
    </ListItem>
  );
};

NavigationListItem.propTypes = {
  children: PropTypes.node,
  navRoute: PropTypes.string.isRequired,
  icon: PropTypes.string,
  iconAlt: PropTypes.string,
  itemText: PropTypes.string,
  exact: PropTypes.bool,
  onClick: PropTypes.func,
};

NavigationListItem.defaultProps = {
  children: null,
  icon: '',
  iconAlt: '',
  itemText: null,
  exact: false,
  onClick: null,
};

export { NavigationListItem };
