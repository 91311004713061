import React from 'react';
import { Box, Link, makeStyles, Typography } from '@material-ui/core';
import { shape } from 'prop-types';
import { donationSourceIcon } from 'assets/images';
import { Gap, Visible } from 'components';
import { isDonationTimeType } from 'lib/utils';
import { CampaignDonationType } from 'lib/configs/donations';
import { DonationIcon } from './DonationIcon';

const useStyles = makeStyles((theme) => ({
  link: {
    fontWeight: theme.typography.fontWeightMedium,
    [theme.breakpoints.down('sm')]: {
      ...theme.typography.caption,
      fontWeight: theme.typography.fontWeightMedium,
    },
  },
  text: {
    ...theme.typography.body2,
    [theme.breakpoints.down('sm')]: theme.typography.caption,
  },
}));

// TODO: add real data once available + tests
const DonationSource = ({ data }) => {
  const styles = useStyles();
  const isSimpleSource = isDonationTimeType(data);

  return (
    <Box alignItems="center" display="flex">
      <Visible
        fallback={<Typography className={styles.text}>Gala</Typography>}
        when={!isSimpleSource}
      >
        <DonationIcon alt="Source Icon" src={donationSourceIcon} />
        <Gap />
        <Link
          className={styles.link}
          href="https://google.com"
          rel="noopener noreferrer"
          target="_blank"
          variant="body2"
        >
          Website
        </Link>
      </Visible>
    </Box>
  );
};

DonationSource.propTypes = {
  data: shape(CampaignDonationType).isRequired,
};

export { DonationSource };
