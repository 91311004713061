import userActionTypes from 'rdx/actionTypes';
import { addUniqueFeedItems } from 'lib/utils';
import { DEFAULT_PAGE_SIZE } from 'lib/constants/common';

const initialState = {
  isFetching: false,
  error: null,
  activeFeedItemId: null,
  activeCampaignId: null,
  dataLoaded: false,
  hasNextPage: null,
  pageFrom: 0,
  items: [],
};

const {
  FETCH_CAMPAIGN_FEED_REQUEST,
  FETCH_CAMPAIGN_FEED_SUCCESS,
  FETCH_CAMPAIGN_FEED_REFRESH_SUCCESS,
  FETCH_CAMPAIGN_FEED_FAILURE,
} = userActionTypes;

/* eslint-disable no-case-declarations */
export function campaignFeedReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_CAMPAIGN_FEED_REQUEST:
      return {
        ...state,
        dataLoaded: state.activeCampaignId === action?.data?.campaignId,
        isFetching: true,
      };
    case FETCH_CAMPAIGN_FEED_SUCCESS:
      const feedItems = action?.data?.feedItems || [];
      const campaignId = action?.data?.campaignId;
      // Detect when a different campaign is active to clear the campaign feed
      const notActiveCampaign = campaignId !== state.activeCampaignId;
      const items = notActiveCampaign
        ? feedItems
        : addUniqueFeedItems(state.items, feedItems);

      return {
        ...state,
        isFetching: false,
        activeCampaignId: campaignId,
        dataLoaded: true,
        items,
        pageFrom: action.data.searchParams.pageFrom + DEFAULT_PAGE_SIZE,
        hasNextPage: action?.data.totalHits > items?.length,
      };
    case FETCH_CAMPAIGN_FEED_FAILURE:
      return {
        ...state,
        dataLoaded: false,
        isFetching: false,
        error: action.error,
      };
    case FETCH_CAMPAIGN_FEED_REFRESH_SUCCESS:
      return {
        ...state,
        isFetching: false,
        dataLoaded: true,
        items: addUniqueFeedItems(action?.data?.feedItems || [], state.items),
      };
    default:
      return state;
  }
}
