import { matchPath } from 'react-router-dom';

export const getTabId = (tabsConfig = [], location) => {
  let defaultTabIdx;
  let tabIdx;

  // eslint-disable-next-line no-unused-expressions
  tabsConfig?.some((tab, idx) => {
    if (tab?.default) {
      defaultTabIdx = idx;
    }

    const matchRes = matchPath(location?.pathname, {
      path: tab?.path,
      exact: true,
      strict: false,
    });

    if (matchRes?.isExact) {
      tabIdx = idx;
    }

    return matchRes?.isExact;
  });

  return tabIdx || defaultTabIdx;
};

export default {
  getTabId,
};
