import React from 'react';
import { format } from 'date-fns';
import { equals } from 'rambdax';
import { snakeCaseKeys } from 'lib/utils/transform-keys';
import { useHistory, useLocation } from 'react-router-dom';
import { API_DATE_FORMAT } from 'lib/constants/common';
import {
  DateType,
  ReportType,
  SortField,
  SortDirection,
} from 'lib/types/ReportFilterPropType';
import { startOfYtdYear, today } from 'components/reports/ReportDateFilter';
import usePaginationParams from 'lib/hooks/usePaginationParams';

const useReportFilters = () => {
  const history = useHistory();
  const location = useLocation();
  const { page } = usePaginationParams();
  const [cachedFilters, setCachedFilters] = React.useState({});

  const filters = React.useMemo(() => {
    const search = new URLSearchParams(location.search);
    const newFilters = {
      startDate:
        search.get('start_date') || format(startOfYtdYear, API_DATE_FORMAT),
      endDate: search.get('end_date') || format(today, API_DATE_FORMAT),
      dateType: search.get('date_type') || DateType.CALENDAR_YTD,
      page,
      reportType:
        search.get('report_type') || ReportType.CURRENT_STATE_DASHBOARD,
      sortField: search.get('sort_field') || SortField.DONATED,
      sortDirection: search.get('sort_direction') || SortDirection.DESC,
    };
    if (equals(newFilters, cachedFilters)) {
      return cachedFilters;
    }
    setCachedFilters(newFilters);
    return newFilters;
  }, [location]);

  const updateFilters = React.useCallback(
    (newFilters = {}) => {
      const combinedFilters = snakeCaseKeys({ ...filters, ...newFilters });
      const newSearch = new URLSearchParams(location.search);
      Object.entries(combinedFilters).forEach(([key, value]) => {
        if (value === undefined || value === null || !value.length) {
          newSearch.delete(key);
        } else {
          newSearch.set(key, Array.isArray(value) ? value.join(',') : value);
        }
      });
      history.replace({
        pathname: location.pathname,
        search: `?${newSearch.toString()}`,
      });
    },
    [location]
  );

  const resetFilters = () => {
    return history.replace({
      pathname: location.pathname,
    });
  };

  return { filters, resetFilters, updateFilters };
};

export default useReportFilters;
