import { createAction } from 'lib/utils';
import { recentlyViewedDonorsService } from 'services/recently-viewed-donors';
import userActionTypes from 'rdx/actionTypes';

const {
  RECENTLY_VIEWED_DONORS_REQUEST,
  RECENTLY_VIEWED_DONORS_SUCCESS,
  RECENTLY_VIEWED_DONORS_FAILURE,
} = userActionTypes;

const fetchRecentlyViewedDonors = () => (dispatch) => {
  try {
    dispatch(createAction({ type: RECENTLY_VIEWED_DONORS_REQUEST }));

    // This is a localStorage 'request' no need for await
    const response = recentlyViewedDonorsService.getRecentlyViewedDonors();

    dispatch(
      createAction({
        type: RECENTLY_VIEWED_DONORS_SUCCESS,
        data: response || {},
      })
    );
  } catch (error) {
    dispatch(
      createAction({
        type: RECENTLY_VIEWED_DONORS_FAILURE,
        error,
      })
    );
  }
};

const updateRecentlyViewedDonors = (donor) => (dispatch) => {
  try {
    dispatch(createAction({ type: RECENTLY_VIEWED_DONORS_REQUEST }));

    // This is a localStorage 'request' no need for await
    const response = recentlyViewedDonorsService.recordDonorView(donor);

    dispatch(
      createAction({
        type: RECENTLY_VIEWED_DONORS_SUCCESS,
        data: response || {},
      })
    );
  } catch (error) {
    dispatch(
      createAction({
        type: RECENTLY_VIEWED_DONORS_FAILURE,
        error,
      })
    );
  }
};

export default {
  fetchRecentlyViewedDonors,
  updateRecentlyViewedDonors,
};
