import { translate } from 'lib/intl';
import { isDonationTimeType } from 'lib/utils';
import {
  DonationAmount,
  DonationMethod,
  DonationDonor,
  DonationType,
  // DonationSource,
  DonationDate,
} from './CampaignDonation';

const TABLE_ID_FIELD = 'donationId';
const TABLE_STYLES = { minWidth: '800px', paddingBottom: '80px' };

const COLUMNS_CONFIG = [
  {
    id: 'donation-amount',
    field: 'amount',
    align: 'right',
    headerName: translate('CAMPAIGN_DONATION_AMOUNT_LABEL'),
    renderCell: DonationAmount,
    getComparableValue: (value, data) =>
      isDonationTimeType(data) ? data.time : value,
  },
  {
    id: 'donation-payment-type',
    field: 'paymentTypeEnum',
    align: 'center',
    headerName: translate('CAMPAIGN_DONATION_MENTOD_LABEL'),
    renderCell: DonationMethod,
  },
  {
    id: 'donation-donor-name',
    field: 'donorDisplayName',
    headerName: translate('CAMPAIGN_DONATION_DONOR_NAME_LABEL'),
    renderCell: DonationDonor,
  },
  {
    id: 'donation-type',
    field: 'donationTypeEnum',
    headerName: translate('CAMPAIGN_DONATION_TYPE_LABEL'),
    renderCell: DonationType,
    getComparableValue: (value, data) =>
      isDonationTimeType(data) ? data.timeCategory : value,
  },
  // {
  //   id: 'donation-source',
  //   field: 'source',
  //   headerName: translate('CAMPAIGN_DONATION_SOURCE_LABEL'),
  //   renderCell: DonationSource,
  //   // TODO: add sorting once data is available
  //   sortable: false,
  // },
  {
    id: 'donation-date',
    field: 'donationDateString',
    headerName: translate('CAMPAIGN_DONATION_DATE_LABEL'),
    renderCell: DonationDate,
  },
];

const SEARCHABLE_FIELDS = ['donorDisplayName'];

const CSV_LINK_HEADERS = [
  { label: translate('CAMPAIGN_DONATION_AMOUNT_LABEL'), key: 'amount' },
  { label: translate('CAMPAIGN_DONATION_MENTOD_LABEL'), key: 'method' },
  { label: translate('CAMPAIGN_DONATION_DONOR_NAME_LABEL'), key: 'donorName' },
  { label: translate('CAMPAIGN_DONATION_TYPE_LABEL'), key: 'type' },
  // TODO: uncomment once Source is available
  // { label: translate('CAMPAIGN_DONATION_SOURCE_LABEL'), key: 'source' },
  { label: translate('CAMPAIGN_DONATION_DATE_LABEL'), key: 'date' },
];

export {
  COLUMNS_CONFIG,
  TABLE_ID_FIELD,
  TABLE_STYLES,
  SEARCHABLE_FIELDS,
  CSV_LINK_HEADERS,
};
