import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import CustomTable from 'components/common/CustomTable2';
import { CUSTOM_DATE_FORMAT, DESC } from 'lib/constants';
import { translate } from 'lib/intl';
import { formatDate, sortAndFilterCustomTableData } from 'lib/utils';
import { AUTH_ROUTES } from 'lib/constants/routes';
import { useSetOwnerOrgPubId } from 'lib/hooks/user-data';

const NAME_FIELD = 'displayName';
const CREATED_FIELD = 'created';
const SEARCHABLE_FIELDS = [NAME_FIELD];

const COLUMNS_CONFIG = [
  {
    id: 'org-management-name',
    field: NAME_FIELD,
    headerName: translate('ORG_MGMT_TABLE_HEADER_NAME'),
    renderCell(props) {
      return (
        <Link component="span" variant="subtitle1">
          {/* eslint-disable-next-line react/prop-types */}
          {props.value}
        </Link>
      );
    },
  },
  {
    id: 'org-management-created',
    field: CREATED_FIELD,
    headerName: translate('ORG_MGMT_TABLE_HEADER_CREATED'),
    renderCell(props) {
      return (
        <Typography component="span" variant="subtitle2">
          {/* eslint-disable-next-line react/prop-types */}
          {formatDate(props.value, CUSTOM_DATE_FORMAT)}
        </Typography>
      );
    },
    style: {
      width: '20%',
    },
  },
];

function sortItems(items, search, sorting) {
  return sortAndFilterCustomTableData({
    data: items,
    columns: COLUMNS_CONFIG,
    sorting,
    search,
    searchableFields: SEARCHABLE_FIELDS,
  });
}

export default function OrgListTable({ items, search }) {
  const [sorting, setSorting] = React.useState({
    orderBy: 'created',
    order: DESC,
  });
  const [sortedItems, setSortedItems] = React.useState(() => {
    return sortItems(items, search, sorting);
  });

  React.useEffect(() => {
    setSortedItems(sortItems(items, search, sorting));
  }, [items, search, sorting]);

  const history = useHistory();
  const { setOwnerOrgPubId } = useSetOwnerOrgPubId();

  const handleClick = (row) => {
    if (row.data?.orgId) {
      setOwnerOrgPubId(row.data?.orgId);
      history.push(AUTH_ROUTES.LANDING);
    } else {
      // eslint-disable-next-line no-console
      console.warn(`No orgId found for org: ${row.data.orgName}`);
    }
  };

  return (
    <CustomTable
      columns={COLUMNS_CONFIG}
      data={sortedItems}
      filter={sorting}
      idField="org-management-list"
      key={items.length}
      onRowClick={handleClick}
      setFilter={setSorting}
    />
  );
}

OrgListTable.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
  search: PropTypes.string,
};

OrgListTable.defaultProps = {
  search: '',
};
