import React from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import makeStyles from '@material-ui/styles/makeStyles';
import { isMobileView } from 'lib/utils';
import { useCurrentUser } from 'lib/hooks/user-data';
import {
  DESKTOP_NAV_WIDTH,
  MOBILE_NAV_HEIGHT,
  MOBILE_SUPER_ADMIN_HEADER_HEIGHT,
  SUPER_ADMIN_HEADER_HEIGHT,
} from 'lib/constants/common';
import { Loader } from 'components';

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: theme.palette.grey.light,
    bottom: 0,
    left: DESKTOP_NAV_WIDTH,
    paddingRight: DESKTOP_NAV_WIDTH,
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    position: 'fixed',
    right: 0,
    top({ isSuperAdmin }) {
      return isSuperAdmin ? SUPER_ADMIN_HEADER_HEIGHT : 0;
    },
    zIndex: 3,
    [theme.breakpoints.down('sm')]: {
      left: 0,
      paddingRight: 0,
      top({ isSuperAdmin }) {
        return isSuperAdmin
          ? MOBILE_SUPER_ADMIN_HEADER_HEIGHT
          : MOBILE_NAV_HEIGHT;
      },
    },
  },
}));

const PageLoader = ({ isVisible }) => {
  const isMobile = isMobileView('xs');
  const { isSuperAdmin } = useCurrentUser(true);
  const classes = useStyles({
    isMobile,
    isSuperAdmin,
  });

  return isVisible ? (
    <Box className={classes.container}>
      <Loader isVisible />
    </Box>
  ) : null;
};

PageLoader.propTypes = {
  isVisible: PropTypes.bool.isRequired,
};

export { PageLoader };
