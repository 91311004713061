import React from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { PrimaryCheckbox } from 'components/common';
import { EMPTY_FUNC } from 'lib/constants';
import TextSelectFieldItemImage from './TextSelectFieldItemImage';

export default function TextSelectFieldTags({
  id,
  primaryValue,
  usePrimary,
  values,
}) {
  return values.map((item, idx) => (
    <Box
      alignItems="center"
      id={`${id}-${item.value}-option`}
      display="flex"
      key={item?.value || idx}
      pr={1}
    >
      <TextSelectFieldItemImage id={id} {...item} />
      <Typography>{item?.text}</Typography>
      {usePrimary && item.value === primaryValue && (
        <Box alignSelf="start" pl={0.5}>
          <PrimaryCheckbox
            onChange={EMPTY_FUNC}
            primary
            checkboxProps={{
              name: `${id}-${item.value}-option`,
            }}
          />
        </Box>
      )}
      <Typography pr={1}>{idx < values?.length - 1 ? ',' : ''}</Typography>
    </Box>
  ));
}

TextSelectFieldTags.propTypes = {
  id: PropTypes.string.isRequired,
  usePrimary: PropTypes.bool,
  primaryValue: PropTypes.string,
};

TextSelectFieldTags.defaultProps = {
  primaryValue: null,
  usePrimary: false,
};
