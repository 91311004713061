import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import makeStyles from '@material-ui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  loader: {
    height: theme.spacing(6),
    width: theme.spacing(6),
  },
}));

export default function LoadingButton({ children, color, isLoading, ...rest }) {
  const classes = useStyles();

  return isLoading ? (
    <CircularProgress className={classes.loader} color={color} />
  ) : (
    <Button color={color} {...rest}>
      {children}
    </Button>
  );
}

LoadingButton.propTypes = {
  children: PropTypes.node.isRequired,
  color: PropTypes.string,
  isLoading: PropTypes.bool.isRequired,
};

LoadingButton.defaultProps = {
  color: 'primary',
};
