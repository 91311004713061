import React, { useMemo } from 'react';
import classnames from 'classnames';
import {
  Box,
  FormHelperText,
  makeStyles,
  Tooltip,
  Typography,
} from '@material-ui/core';
import {
  upsetEmojiIcon,
  negativeEmojiIcon,
  neutralEmojiIcon,
  happyEmojiIcon,
  joyfulEmojiIcon,
} from 'assets/images';
import { EMPTY_FUNC } from 'lib/constants';
import { bool, number, func, string } from 'prop-types';
import { translate } from 'lib/intl';
import { Visible } from 'components';

const useStyles = makeStyles((theme) => ({
  header: {
    width: '100%',
    paddingBottom: theme.spacing(1.5),
  },
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },
  button: {
    width: '48px',
    height: '48px',
    border: `1px solid ${theme.palette.grey.light}`,
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    '&.error': {
      borderColor: theme.palette.error.main,
    },
    '&.isActive': {
      backgroundColor: theme.palette.primary.main,
    },
    '&:hover:not(.isActive)': {
      backgroundColor: theme.palette.grey.light,
    },
  },
}));

const TOOLTIP_DELAY = 500;

// Order set, indices are used
const EXPERIENCE_VALUES = [
  {
    descriptor: 'upset',
    icon: upsetEmojiIcon,
    tooltip: translate('BAD'),
  },
  {
    descriptor: 'negative',
    icon: negativeEmojiIcon,
    tooltip: translate('NOT_GOOD'),
  },
  {
    descriptor: 'neutral',
    icon: neutralEmojiIcon,
    tooltip: translate('OK'),
  },
  {
    descriptor: 'happy',
    icon: happyEmojiIcon,
    tooltip: translate('GOOD'),
  },
  {
    descriptor: 'joyful',
    icon: joyfulEmojiIcon,
    tooltip: translate('GREAT'),
  },
];

const ExperienceRating = ({
  experience,
  error,
  errorHelperText,
  setExperience,
}) => {
  const styles = useStyles();

  const Buttons = useMemo(
    () =>
      EXPERIENCE_VALUES.map(({ descriptor, icon, tooltip }, idx) => (
        <Tooltip
          key={`key-${descriptor}`}
          enterDelay={TOOLTIP_DELAY}
          // eslint-disable-next-line
          title={<Typography variant="caption">{tooltip}</Typography>}
        >
          <Box
            data-testid="experience-rating-button"
            role="button"
            tabIndex={idx}
            className={classnames(styles.button, {
              isActive: experience === idx,
              error,
            })}
            onClick={() => setExperience(idx)}
            onKeyDown={() => setExperience(idx)}
            height="50px"
          >
            <img alt={`satisfaction-button-${descriptor}`} src={icon} />
          </Box>
        </Tooltip>
      )),
    [experience, error]
  );

  return (
    <>
      <Box className={styles.header}>
        <Typography variant="subtitle2">
          {translate('INTERACTION_SATISFACTION_TITLE')}
        </Typography>
      </Box>
      <Box className={styles.container}>{Buttons}</Box>
      <Visible when={error && errorHelperText}>
        <Box display="flex" flex="1" justifyContent="center">
          <FormHelperText error>{errorHelperText}</FormHelperText>
        </Box>
      </Visible>
    </>
  );
};

ExperienceRating.propTypes = {
  experience: number,
  error: bool,
  errorHelperText: string,
  setExperience: func,
};

ExperienceRating.defaultProps = {
  experience: null,
  error: false,
  errorHelperText: '',
  setExperience: EMPTY_FUNC,
};

export { ExperienceRating };
