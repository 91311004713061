import { types } from '../actions/constituentActions';
import { types as formTypes } from '../actions/constituentFormPopupActions';

const initialState = {
  dataLoaded: false,
  isFetching: false,
  error: null,
  data: {},
};

export default function updateConstituentReducer(state = initialState, action) {
  switch (action.type) {
    case types.CREATE_CONSTITUENT_CONNECTION_REQUEST:
    case types.UPDATE_CONSTITUENT_CONNECTION_REQUEST:
    case types.CREATE_CONSTITUENT_REQUEST:
    case types.UPDATE_CONSTITUENT_REQUEST:
      return {
        ...state,
        dataLoaded: false,
        isFetching: true,
        error: null,
      };
    case types.CREATE_CONSTITUENT_CONNECTION_SUCCESS:
    case types.UPDATE_CONSTITUENT_CONNECTION_SUCCESS:
    case types.CREATE_CONSTITUENT_SUCCESS:
    case types.UPDATE_CONSTITUENT_SUCCESS:
      return {
        ...state,
        data: action.data,
        dataLoaded: true,
        isFetching: false,
        error: null,
      };
    case types.CREATE_CONSTITUENT_CONNECTION_FAILURE:
    case types.UPDATE_CONSTITUENT_CONNECTION_FAILURE:
    case types.CREATE_CONSTITUENT_FAILURE:
    case types.UPDATE_CONSTITUENT_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.error || true,
      };
    case formTypes.open: {
      return initialState;
    }
    default:
      return state;
  }
}
