import React from 'react';
import { string } from 'prop-types';
import { Box } from '@material-ui/core';
import { CompanyTeamMemberStatus } from 'lib/utils';
import { Loader } from 'components';

const TEAM_MEMBER_TABLE_LOADER_SIZE = '30px';

const TeamMemberLoaderCell = ({ status, justifyContent }) => (
  <Box
    height={TEAM_MEMBER_TABLE_LOADER_SIZE}
    display="flex"
    flex="1"
    flexDirection="row"
    justifyContent={justifyContent}
  >
    <Loader
      isVisible={Boolean(status?.isFetching)}
      size={TEAM_MEMBER_TABLE_LOADER_SIZE}
    />
  </Box>
);

TeamMemberLoaderCell.propTypes = {
  status: CompanyTeamMemberStatus,
  justifyContent: string,
};

TeamMemberLoaderCell.defaultProps = {
  status: {
    dataLoaded: false,
    isFetching: false,
    error: null,
  },
  justifyContent: 'flex-end',
};

export { TeamMemberLoaderCell };
