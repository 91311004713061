const URL_REGEXP =
  /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/;

const HTTP_REGEXP = /https?:\/\//;

const getSafeUrl = (url) => String(url).toLowerCase();

const isValidUrl = (url) => !url || URL_REGEXP.test(getSafeUrl(url));

// makes sure there is always a http/https protocol in the url
const formatUrl = (url) => {
  let formattedUrl = url;

  if (url && !HTTP_REGEXP.test(getSafeUrl(url))) {
    formattedUrl = `https://${url.replace(/^https?:\/\//, '')}`;
  }

  return formattedUrl;
};

export { formatUrl, isValidUrl, URL_REGEXP };
