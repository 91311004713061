import React from 'react';
import { number, shape } from 'prop-types';
import { Box, makeStyles, Typography } from '@material-ui/core';
import { translate } from 'lib/intl';
import { activateIcon } from 'assets/images';
import { Gap } from 'components';
import { ICON_SIZES, TABLE_SORT_ICON_SIZE } from 'lib/constants';
import { formatCurrency, isDonationTimeType } from 'lib/utils';
import { CampaignDonationType } from 'lib/configs/donations';

const useStyles = makeStyles((theme) => ({
  container: {
    marginRight: TABLE_SORT_ICON_SIZE,
    [theme.breakpoints.down('sm')]: {
      marginRight: theme.spacing(1),
    },
  },
  text: {
    ...theme.typography.body2,
    [theme.breakpoints.down('sm')]: theme.typography.subtitle1,
  },
}));

const DonationAmount = ({ data, value }) => {
  const styles = useStyles();
  const isTimeType = isDonationTimeType(data);

  return (
    <Box
      alignItems="center"
      className={styles.container}
      display="flex"
      justifyContent="flex-end"
    >
      {isTimeType && (
        <>
          <img
            alt={`${activateIcon}-alt`}
            height={ICON_SIZES.XS}
            src={activateIcon}
            width={ICON_SIZES.XS}
          />
          <Gap />
          <Typography className={styles.text}>
            {`${data.time} ${translate('DONATION_HOURS')}`}
          </Typography>
        </>
      )}
      {!isTimeType && (
        <Typography className={styles.text}>
          {formatCurrency(value, true)}
        </Typography>
      )}
    </Box>
  );
};

DonationAmount.propTypes = {
  data: shape(CampaignDonationType).isRequired,
  value: number.isRequired,
};

export { DonationAmount };
