// Creates String.format functionality for Strings
// Example use cases include:
// "Hello, {name}, are you feeling {adjective}?".format({name:"Gabriel", adjective: "OK"});
// "a{0}bcd{1}ef".format("foo", "bar"); // yields "aFOObcdBARef"
//
// Origination of solution: https://stackoverflow.com/a/18234317/4181653
/* eslint-disable */
const applyStringFormat = () => {
  String.prototype.format = function () {
    let str = this.toString();

    if (arguments.length) {
      const t = typeof arguments[0];
      let key;
      const args =
        t === 'string' || t === 'number'
          ? Array.prototype.slice.call(arguments)
          : arguments[0];

      for (key in args) {
        str = str.replace(new RegExp('\\{' + key + '\\}', 'gi'), args[key]);
      }
    }

    return str;
  };
};
/* eslint-enable */

const applyPrototypes = () => {
  applyStringFormat();
};

export { applyPrototypes };
