import React from 'react';
import propTypes from 'prop-types';
import { ProfileDescriptorLink } from 'components/common/ProfileDescriptorLink';
import { useOwnerOrgPubId } from 'lib/hooks/user-data';

// EmployeeLink wrapper for profile descriptor, can be adjusted as needed if API changes
const EmployeeLink = ({ displayName, userId }) => {
  const { ownerOrgId } = useOwnerOrgPubId();

  return (
    <ProfileDescriptorLink
      displayName={displayName}
      ownerOrgId={ownerOrgId}
      userId={userId}
    />
  );
};

EmployeeLink.propTypes = {
  displayName: propTypes.string.isRequired,
  userId: propTypes.string.isRequired,
};

export { EmployeeLink };
