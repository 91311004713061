const sortCampaigns = (campaigns) =>
  campaigns.sort((firstCampaign, secondCampaign) => {
    if (firstCampaign.isPinned && !secondCampaign.isPinned) {
      return -1;
    }

    if (!firstCampaign.isPinned && secondCampaign.isPinned) {
      return 1;
    }

    const firstCampaignDate = firstCampaign.date || firstCampaign.endDate;
    const lastCampaignDate = secondCampaign.date || secondCampaign.endDate;

    return lastCampaignDate > firstCampaignDate ? 1 : -1;
  });

export { sortCampaigns };
