import { camelCase } from 'voca';

/**
 * Creates an object of png images using a camel cased version of the file
 * name, and removing any icon suffix in the name that may be there. That
 * results taking the following images:
 *
 *  tag.png
 *  text-icon.png
 *  thinking-emoji.png
 *
 * and converts it into this object:
 *
 *  {
 *    tag: // image src
 *    text: // image src
 *    thinkingEmoji: // image src
 *  }
 */
const pngs = ((ctx) => {
  const keys = ctx.keys();
  const values = keys.map(ctx);

  return keys.reduce((o, k, i) => {
    const key = camelCase(k.split('.png')[0]).replace(/Icon$/, '');

    return {
      ...o,
      [key]: values[i],
    };
  }, {});
})(require.context('../../assets/images', true, /.png/));

export { pngs };
