import { parsePhoneNumber } from 'libphonenumber-js';
import { capitalize, titleCase } from 'voca';
import { keys, values } from 'rambdax';
import { translate } from 'lib/intl';
import {
  mapEmails,
  mapAddresses,
  mapPhoneNumbers,
  mapWebsite,
} from 'lib/mappers';
import {
  formatDate,
  formatDateObject,
  getFullName,
  isValidDate,
  isValidEmail,
  replaceEmptyValues,
} from 'lib/utils';
import { isValidUrl, formatUrl } from 'lib/utils/urls';
import {
  DONOR_SECTION_TYPES,
  EID_FIRST_PART_LENGTH,
  EID_NUM_LENGTH,
  EMAIL_LABELS,
  LABELS,
  PHONE_LABELS,
  RELATIVES,
  WORK_PHONE_LABELS,
} from 'lib/constants';
// TODO: check how to prevent tests from faling
// while using the absolute path for components/edit-donor
import {
  AddressInputs,
  DonorTextInput,
  EditDonorPhoneInput,
} from 'components/edit-donor';
import EditConstituentAttributes from 'components/constituents/EditConstituentAttributes';
import {
  EditAffiliationsIcon,
  BirthdayIcon,
  EditBirthdayIcon,
  BuildingIcon,
  EditBuildingIcon,
  ConcernsIcon,
  EditConcernsIcon,
  EditConnectionsIcon,
  EmailIcon,
  EditEmailIcon,
  EditEmployeesIcon,
  EditFamilyIcon,
  HomeIcon,
  EditHomeIcon,
  JobIcon,
  JobPositionIcon,
  LabelIcon,
  EditJobPositionIcon,
  PhoneIcon,
  EditWorkPhoneIcon,
  ProhibitionsIcon,
  EditProhibitionsIcon,
  RedPhoneIcon,
  SpouseIcon,
  WorkPhoneIcon,
  WebIcon,
  EditWebIcon,
  IndustryIcon,
  EditIndustryIcon,
  WinningIcon,
  EditWinningIcon,
  EditFacebookIcon,
  EditInstagramIcon,
  EditLinkedInIcon,
  EditTwitterIcon,
  EmployeeIdIcon,
  EditEmployeeIdIcon,
  TagIcon,
} from 'lib/configs/donor-icons';
import EditConstituentLabels from 'components/edit-donor/EditConstituentLabels';

const { HUSBAND, WIFE, SPOUSE } = RELATIVES;

const {
  EMPLOYEES,
  CONNECTIONS,
  HOUSEHOLD,
  AFFILIATIONS,
  CONTACT_INFORMATION,
  WORK_INFORMATION,
  PERSONAL_INFORMATION,
  COMPANY_INFORMATION,
} = DONOR_SECTION_TYPES;

/**
  Take a look on mapDonorData params from lib/mappers
  if you want to check which fields to use for configs
*/

const PHONE_LABEL_ICON_MAPPER = {
  [LABELS.HOME]: RedPhoneIcon,
  [LABELS.WORK]: WorkPhoneIcon,
  [LABELS.PERSONAL]: WorkPhoneIcon,
  [LABELS.MOBILE]: PhoneIcon,
  [LABELS.OFFICE]: WorkPhoneIcon,
  [LABELS.FAX]: WorkPhoneIcon,
  [LABELS.FREE_TEXT]: WorkPhoneIcon,
};

const withoutNoDataLabel = (props) => ({ ...props, noDataLabel: '' });

const formatWebAddress = (value = '') => value.replace(/http[s]*:\/\//, '');

const formatPhoneNumber = (value = '') => {
  try {
    return parsePhoneNumber(value).formatNational();
  } catch (e) {
    return value;
  }
};

// used for showing dob in view mode
const formatDateOfBirth = (value) => {
  try {
    let dob = formatDate(value, 'MMM dd');
    const year = formatDate(value, 'yyyy');

    if (year) {
      dob = `${dob}, ${year}`;
    }

    return dob;
  } catch (e) {
    return value;
  }
};

const formatEmployeeId = (value = '') => {
  if (
    value?.length > EID_FIRST_PART_LENGTH &&
    value?.length <= EID_NUM_LENGTH
  ) {
    const [firstNum, secondNum, ...rest] = value.split('');

    return `${firstNum}${secondNum}-${rest.join('')}`;
  }

  return value;
};

// used in forms
const mapBirthdayValue = (value) => {
  try {
    // formats a birthday in case data is entered fully
    return value?.length >= 10 ? formatDate(value, 'MM/dd/yyyy') : value;
  } catch (e) {
    // eslint-disable-next-line
    console.warn(`couldn't format the date: ${value}`);
    return value;
  }
};

// formats dob before saving
const formatDateBeforeSave = (value) => {
  try {
    return value ? formatDateObject(new Date(value), 'yyyy-MM-dd') : null;
  } catch (e) {
    // eslint-disable-next-line
    console.warn(`couldn't format the date: ${value}`);
    return value;
  }
};

const mapLabels = (labels) =>
  labels.map((label) => ({ label: titleCase(label), value: label }));

const withWorkPhones = (props) => ({
  ...props,
  labels: mapLabels(WORK_PHONE_LABELS),
  defaultValue: {
    email: '',
    label: LABELS.OFFICE,
    primary: false,
  },
});

const withBusinessDefaultLabel = (props) => ({
  ...props,
  defaultValue: {
    email: '',
    label: LABELS.BUSINESS,
    primary: false,
  },
});

// removes empty values in case of a new donor
const processBeforeSaving = (isExistingDonor = false, data) =>
  isExistingDonor
    ? data
    : keys(data).reduce((acc, key) => {
        const val = data[key];

        if (val) {
          acc[key] = val;
        }

        return acc;
      }, {});

// Something is wrong with this import, wondering if it is some babel cache / strangeness
// TODO revisit
const isNotEmpty = (value) => Boolean(value);

/**
 * Sections with configs for generation View and Edit Sections
 * of the Donor Page
 *
 * View Sections (used in ViewFieldsColumn)
 * - key*
 * - path*
 * - fieldToExtract - a field to extract after extracting data by path
 * - formatValue - formats an extracted value if necessary (used in src/lib/mappers)
 * - icon
 * - iconMapper - is used to map an icon by field label
 * - label
 * - labels - to filter data based on it (used in src/lib/mappers)
 * - mapper - for mapping field data (used in ViewFieldsColumn)
 * - multiline - allows or not to show data on multiple lines (necessary for rendering addresses)
 * - noDataLabel - a label to show in case a field is empty
 *
 *
 * Popup Sections (used in SectionsGenerator)
 * - key*
 * - path*
 * - Component - a component to render as an input.
 *   Is required in case useMultipleInputs prop is true.
 * - defaultValue - a default value for rendering a new input
 * - errorMessage - an error message to show in case validation is failed
 * - fieldToExtract - a field to extract after extracting data by path (used in MultipleInputs)
 * - icon
 * - label
 * - labels - for rendering a selector with types (used in MultipleInputs)
 * - required - if a value should be required or not
 * - useMultipleInputs - a flag for rendering inputs using MultipleInputs
 * - usePrimary - used in MultipleInputs to render or nor PrimaryCheckbox
 * - validate - a function which return if a value is valid or not
 * - valueMapper - a function which returns a formatted value for input
 */

const DEFAULT_PHONE_CONFIG = {
  key: 'PHONES',
  icon: WorkPhoneIcon,
  iconMapper: PHONE_LABEL_ICON_MAPPER,
  path: 'phones',
  fieldToExtract: 'number',
  mapper: mapPhoneNumbers,
  formatValue: formatPhoneNumber,
  noDataLabel: translate('ADD_PHONE'),
};

const DEFAULT_EMAIL_CONFIG = {
  key: 'EMAILS',
  icon: EmailIcon,
  path: 'emails',
  fieldToExtract: 'email',
  noDataLabel: translate('ADD_EMAIL'),
  mapper: mapEmails,
};

const DEFAULT_ADDRESS_CONFIG = {
  key: 'ADDRESSES',
  icon: BuildingIcon,
  path: 'addresses',
  noDataLabel: translate('ADD_ADDRESS'),
  mapper: mapAddresses,
  multiline: true,
};

const EDIT_ADDRESS_DEFAULT_VALUE = {
  country: '',
  label: LABELS.PERSONAL,
  line1: '',
  unit: '',
  city: '',
  state: '',
  postalCode: '',
};

const CONSTITUENT_FIELDS_CONFIG = {
  // users specific
  FIRST_NAME: {
    key: 'FIRST_NAME',
    label: translate('DONOR_FIRST_NAME'),
    path: 'firstName',
    required: true,
  },
  LAST_NAME: {
    key: 'LAST_NAME',
    label: translate('DONOR_LAST_NAME'),
    path: 'lastName',
  },
  MIDDLE_NAME: {
    key: 'MIDDLE_NAME',
    label: translate('DONOR_MIDDLE_NAME'),
    path: 'middleName',
  },
  CONNECTION_TYPE: {
    key: 'CONNECTION_TYPE',
    label: translate('CONNECTION_TYPE_LABEL'),
    path: 'label',
    required: true,
  },
  RELATIONSHIP_TYPE: {
    key: 'RELATIONSHIP_TYPE',
    label: translate('RELATIONSHIP_TYPE_LABEL'),
    path: 'label',
    required: true,
  },
  TITLE_TYPE: {
    key: 'TITLE_TYPE',
    label: translate('TITLE_LABEL'),
    path: 'label',
    icon: EditJobPositionIcon,
    required: true,
  },
  USER_SPOUSE: {
    key: 'USER_SPOUSE',
    icon: SpouseIcon,
    path: 'household',
    fieldToExtract: 'displayName',
    labels: [HUSBAND, WIFE, SPOUSE],
  },
  USER_EMPLOYER: {
    key: 'USER_EMPLOYER',
    icon: JobIcon,
    path: 'work',
    fieldToExtract: 'orgName',
    usePrimary: true,
  },
  USER_PERSONAL_EMAILS: DEFAULT_EMAIL_CONFIG,
  USER_BIRTHDAY: {
    key: 'USER_BIRTHDAY',
    icon: BirthdayIcon,
    path: 'dob',
    noDataLabel: translate('ADD_BIRTHDAY'),
    label: translate('BIRTHDAY_PLACEHOLDER'),
    formatValue: formatDateOfBirth,
    valueMapper: mapBirthdayValue,
    validate: (value) => isValidDate(value, 'MM/dd/yyyy'),
    errorMessage: translate('INVALID_DATE_FORMAT_MESSAGE'),
  },
  USER_HOME_ADDRESS: {
    ...DEFAULT_ADDRESS_CONFIG,
    icon: HomeIcon,
  },
  CONTACT_ADDRESS: {
    ...DEFAULT_ADDRESS_CONFIG,
    icon: HomeIcon,
  },
  USER_WORK_ADDRESS: {
    ...DEFAULT_ADDRESS_CONFIG,
    icon: BuildingIcon,
  },
  USER_JOB_TITLE: {
    key: 'USER_JOB_TITLE',
    path: 'roles',
    fieldToExtract: 'jobTitle',
    icon: JobPositionIcon,
    noDataLabel: translate('ADD_JOB_TITLE'),
    usePrimary: true,
    required: true,
  },
  USER_PHONE_NUMBERS: DEFAULT_PHONE_CONFIG,

  // orgs specific
  ORG_INDUSTRY: {
    key: 'ORG_INDUSTRY',
    icon: IndustryIcon,
    label: translate('DONOR_INDUSTRY_LABEL'),
    noDataLabel: translate('ADD_INDUSTRY'),
    path: 'industry',
  },
  ORG_ADDRESS: DEFAULT_ADDRESS_CONFIG,
  ORG_EMAILS: DEFAULT_EMAIL_CONFIG,
  ORG_PHONE_NUMBERS: DEFAULT_PHONE_CONFIG,
  COMPANY_AFFILIATION: {
    key: 'COMPANY_AFFILIATION',
    label: translate('COMPANY_AFFILIATION_LABEL'),
    path: 'orgName',
    icon: EditAffiliationsIcon,
    required: true,
  },
  COMPANY_NAME: {
    key: 'COMPANY_NAME',
    label: translate('COMPANY_NAME_LABEL'),
    path: 'orgName',
    icon: EditAffiliationsIcon,
    required: true,
  },

  // common
  DISPLAY_NAME: {
    key: 'DISPLAY_NAME',
    label: translate('DISPLAY_NAME_LABEL'),
    path: 'displayName',
  },
  PRIMARY_EMAIL: {
    ...DEFAULT_EMAIL_CONFIG,
    key: 'PRIMARY_EMAIL',
    usePrimary: true,
  },
  PRIMARY_PHONE_NUMBER: {
    ...DEFAULT_PHONE_CONFIG,
    key: 'PRIMARY_PHONE_NUMBER',
    iconMapper: PHONE_LABEL_ICON_MAPPER,
    usePrimary: true,
  },
  PRIMARY_CONNECTION: {
    key: 'PRIMARY_CONNECTION',
    icon: JobIcon,
    path: 'contacts',
    fieldToExtract: 'displayName',
    usePrimary: true,
  },
  WEBSITE: {
    key: 'WEBSITE',
    icon: WebIcon,
    path: 'website',
    label: translate('WEBSITE_LABEL'),
    mapper: mapWebsite,
    formatValue: formatWebAddress,
    noDataLabel: translate('ADD_WEBSITE'),
    validate: isValidUrl,
    errorMessage: translate('INVALID_URL_FORMAT_MESSAGE'),
  },
  NOTE_1: {
    key: 'NOTE_1',
    icon: WinningIcon,
    label: translate('CONSTITUENTS_NOTE_PLACEHOLDER_1'),
    path: 'note1',
    noDataLabel: translate('CONSTITUENTS_NOTE_PLACEHOLDER_1'),
  },
  NOTE_2: {
    key: 'NOTE_2',
    icon: ConcernsIcon,
    label: translate('CONSTITUENTS_NOTE_PLACEHOLDER_2'),
    path: 'note2',
    noDataLabel: translate('CONSTITUENTS_NOTE_PLACEHOLDER_2'),
  },
  NOTE_3: {
    key: 'NOTE_3',
    icon: ProhibitionsIcon,
    label: translate('CONSTITUENTS_NOTE_PLACEHOLDER_3'),
    path: 'note3',
    noDataLabel: translate('CONSTITUENTS_NOTE_PLACEHOLDER_3'),
  },
  INSTAGRAM: {
    key: 'INSTAGRAM',
    icon: EditInstagramIcon,
    label: translate('INSTAGRAM_LABEL'),
    path: 'instagram',
    validate: isValidUrl,
    errorMessage: translate('INVALID_URL_FORMAT_MESSAGE'),
  },
  TWITTER: {
    key: 'TWITTER',
    icon: EditTwitterIcon,
    label: translate('TWITTER_LABEL'),
    path: 'twitter',
    validate: isValidUrl,
    errorMessage: translate('INVALID_URL_FORMAT_MESSAGE'),
  },
  LINKEDIN: {
    key: 'LINKEDIN',
    icon: EditLinkedInIcon,
    label: translate('LINKEDIN_LABEL'),
    path: 'linkedin',
    validate: isValidUrl,
    errorMessage: translate('INVALID_URL_FORMAT_MESSAGE'),
  },
  FACEBOOK: {
    key: 'FACEBOOK',
    icon: EditFacebookIcon,
    label: translate('FACEBOOK_LABEL'),
    path: 'facebook',
    validate: isValidUrl,
    errorMessage: translate('INVALID_URL_FORMAT_MESSAGE'),
  },
  LABEL: {
    key: 'LABEL',
    path: 'label',
    formatValue: capitalize,
  },
  EMAILS: {
    ...DEFAULT_EMAIL_CONFIG,
    icon: null,
    noDataLabel: '',
  },
  PHONES: {
    ...DEFAULT_PHONE_CONFIG,
    icon: null,
    iconMapper: {},
    noDataLabel: '',
  },
  DESCRIPTION: {
    key: 'DESCRIPTION',
    label: translate('TYPE_LABEL'),
    path: 'description',
    required: true,
    formatValue: capitalize,
  },
  JOB_TITLE: {
    key: 'JOB_TITLE',
    label: translate('TITLE_LABEL'),
    path: 'description',
    required: true,
    noDataLabel: translate('ADD_JOB_TITLE'),
  },

  // forms related
  EDIT_EMAILS: {
    key: 'EMAILS',
    icon: EditEmailIcon,
    label: translate('EMAIL_ADDRESS_LABEL'),
    labels: mapLabels(EMAIL_LABELS),
    path: 'emails',
    fieldToExtract: 'email',
    useMultipleInputs: true,
    validate: isValidEmail,
    Component: DonorTextInput,
    defaultValue: {
      email: '',
      label: LABELS.PERSONAL,
      primary: false,
    },
    errorMessage: translate('INVALID_EMAIL_FORMAT_MESSAGE'),
    usePrimary: true,
  },
  EDIT_PHONES: {
    key: 'PHONES',
    icon: EditWorkPhoneIcon,
    labels: mapLabels(PHONE_LABELS),
    path: 'phones',
    fieldToExtract: 'number',
    useMultipleInputs: true,
    Component: EditDonorPhoneInput,
    defaultValue: {
      number: '',
      label: LABELS.MOBILE,
      primary: false,
    },
    usePrimary: true,
  },
  EDIT_ADDRESSES: {
    key: 'ADDRESSES',
    icon: EditHomeIcon,
    path: 'addresses',
    useMultipleInputs: true,
    Component: AddressInputs,
    defaultValue: EDIT_ADDRESS_DEFAULT_VALUE,
  },
  EDIT_CONSTITUENT_ATTRIBUTES: {
    key: 'CONSTITUENT_ATTRIBUTES',
    icon: TagIcon,
    Component: EditConstituentAttributes,
  },
  EDIT_CONSTITUENT_LABELS: {
    key: 'CONSTITUENT_LABELS',
    icon: LabelIcon,
    Component: EditConstituentLabels,
  },
};

// DonorInfoSection related configs
const USER_DONOR_INFO_SECTION_CONFIG = [
  withoutNoDataLabel(CONSTITUENT_FIELDS_CONFIG.USER_SPOUSE),
  withoutNoDataLabel(CONSTITUENT_FIELDS_CONFIG.PRIMARY_PHONE_NUMBER),
  withoutNoDataLabel(CONSTITUENT_FIELDS_CONFIG.PRIMARY_EMAIL),
  withoutNoDataLabel(CONSTITUENT_FIELDS_CONFIG.USER_EMPLOYER),
];

const ORG_DONOR_INFO_SECTION_CONFIG = [
  withoutNoDataLabel(CONSTITUENT_FIELDS_CONFIG.WEBSITE),
  withoutNoDataLabel(CONSTITUENT_FIELDS_CONFIG.PRIMARY_CONNECTION),
  withoutNoDataLabel(CONSTITUENT_FIELDS_CONFIG.PRIMARY_EMAIL),
  withoutNoDataLabel(CONSTITUENT_FIELDS_CONFIG.PRIMARY_PHONE_NUMBER),
];

const CONSTITUENT_NOTES_CONFIG = [
  CONSTITUENT_FIELDS_CONFIG.NOTE_1,
  CONSTITUENT_FIELDS_CONFIG.NOTE_2,
  CONSTITUENT_FIELDS_CONFIG.NOTE_3,
];

// CommonInformation related configs
const USER_COMMON_INFO_FIRST_COL_CONFIG = [
  CONSTITUENT_FIELDS_CONFIG.USER_HOME_ADDRESS,
  CONSTITUENT_FIELDS_CONFIG.USER_BIRTHDAY,
];

const USER_COMMON_INFO_SECOND_COL_CONFIG = [
  CONSTITUENT_FIELDS_CONFIG.USER_PERSONAL_EMAILS,
  CONSTITUENT_FIELDS_CONFIG.USER_PHONE_NUMBERS,
  CONSTITUENT_FIELDS_CONFIG.WEBSITE,
];

const ORG_COMMON_INFO_FIRST_COL_CONFIG = [
  CONSTITUENT_FIELDS_CONFIG.ORG_INDUSTRY,
  CONSTITUENT_FIELDS_CONFIG.ORG_ADDRESS,
];

const ORG_COMMON_INFO_SECOND_COL_CONFIG = [
  CONSTITUENT_FIELDS_CONFIG.ORG_EMAILS,
  CONSTITUENT_FIELDS_CONFIG.ORG_PHONE_NUMBERS,
  CONSTITUENT_FIELDS_CONFIG.WEBSITE,
];

// DonorConnectionSection related configs
const USER_CONNECTION_CARD_CONFIG = [
  CONSTITUENT_FIELDS_CONFIG.DISPLAY_NAME,
  CONSTITUENT_FIELDS_CONFIG.LABEL,
  CONSTITUENT_FIELDS_CONFIG.EMAILS,
  CONSTITUENT_FIELDS_CONFIG.PHONES,
];

// DonorConnectionSection related configs
const ORG_CONNECTION_CARD_CONFIG = [
  CONSTITUENT_FIELDS_CONFIG.DISPLAY_NAME,
  CONSTITUENT_FIELDS_CONFIG.DESCRIPTION,
  {
    ...CONSTITUENT_FIELDS_CONFIG.WEBSITE,
    icon: null,
    noDataLabel: '',
  },
  CONSTITUENT_FIELDS_CONFIG.EMAILS,
  CONSTITUENT_FIELDS_CONFIG.PHONES,
];

// DonorWorkAndContactsSection related configs
const ORG_CONTACTS_FIRST_COL_CONFIG = [
  {
    ...CONSTITUENT_FIELDS_CONFIG.LABEL,
    icon: JobPositionIcon,
  },
  CONSTITUENT_FIELDS_CONFIG.CONTACT_ADDRESS,
];

const ORG_CONTACTS_SECOND_COL_CONFIG = [
  {
    ...CONSTITUENT_FIELDS_CONFIG.EMAILS,
    icon: EmailIcon,
  },
  {
    ...CONSTITUENT_FIELDS_CONFIG.PHONES,
    iconMapper: PHONE_LABEL_ICON_MAPPER,
  },
  CONSTITUENT_FIELDS_CONFIG.WEBSITE,
  CONSTITUENT_FIELDS_CONFIG.USER_BIRTHDAY,
];

const USER_WORK_INFO_FIRST_COL_CONFIG = [
  {
    ...CONSTITUENT_FIELDS_CONFIG.DESCRIPTION,
    icon: JobPositionIcon,
  },
  CONSTITUENT_FIELDS_CONFIG.USER_WORK_ADDRESS,
];

const USER_WORK_INFO_SECOND_COL_CONFIG = [
  DEFAULT_EMAIL_CONFIG,
  DEFAULT_PHONE_CONFIG,
  CONSTITUENT_FIELDS_CONFIG.WEBSITE,
];

const EDIT_CONSTITUENT_NOTES_CONFIG = [
  {
    ...CONSTITUENT_FIELDS_CONFIG.NOTE_1,
    icon: EditWinningIcon,
  },
  {
    ...CONSTITUENT_FIELDS_CONFIG.NOTE_2,
    icon: EditConcernsIcon,
  },
  {
    ...CONSTITUENT_FIELDS_CONFIG.NOTE_3,
    icon: EditProhibitionsIcon,
  },
];

const DONOR_SECTION_CONFIGS = {
  [HOUSEHOLD]: {
    key: HOUSEHOLD,
    title: translate('DONOR_HOUSEHOLD_LABEL'),
    actionLabel: translate('DONOR_ADD_HOUSEHOLD_LABEL'),
    config: USER_CONNECTION_CARD_CONFIG,
  },
  [CONNECTIONS]: {
    key: CONNECTIONS,
    title: translate('DONOR_CONNECTIONS_LABEL'),
    actionLabel: translate('DONOR_ADD_CONNECTION_LABEL'),
    config: USER_CONNECTION_CARD_CONFIG,
  },
  [EMPLOYEES]: {
    key: EMPLOYEES,
    title: translate('DONOR_EMPLOYEES_LABEL'),
    actionLabel: translate('DONOR_ADD_EMPLOYEE_LABEL'),
    config: USER_CONNECTION_CARD_CONFIG,
  },
  [AFFILIATIONS]: {
    key: AFFILIATIONS,
    title: translate('DONOR_AFFILIATIONS_LABEL'),
    actionLabel: translate('DONOR_ADD_AFFILIATION_LABEL'),
    config: ORG_CONNECTION_CARD_CONFIG,
  },
  [CONTACT_INFORMATION]: {
    key: CONTACT_INFORMATION,
    title: translate('DONOR_CONTACTS_LABEL'),
    actionLabel: translate('DONOR_ADD_CONTACT_LABEL'),
    primaryLabel: translate('DONOR_PRIMARY_CONTACT_LABEL'),
    firstColConfig: ORG_CONTACTS_FIRST_COL_CONFIG,
    secondColConfig: ORG_CONTACTS_SECOND_COL_CONFIG,
  },
  [WORK_INFORMATION]: {
    key: WORK_INFORMATION,
    title: translate('DONOR_WORK_INFORMATION'),
    actionLabel: translate('DONOR_ADD_WORK_LABEL'),
    primaryLabel: translate('DONOR_PRIMARY_WORK_LABEL'),
    firstColConfig: USER_WORK_INFO_FIRST_COL_CONFIG,
    secondColConfig: USER_WORK_INFO_SECOND_COL_CONFIG,
  },
  [PERSONAL_INFORMATION]: {
    key: PERSONAL_INFORMATION,
    title: translate('DONOR_PERSONAL_INFORMATION'),
    firstColConfig: USER_COMMON_INFO_FIRST_COL_CONFIG,
    secondColConfig: USER_COMMON_INFO_SECOND_COL_CONFIG,
  },
  [COMPANY_INFORMATION]: {
    key: COMPANY_INFORMATION,
    title: translate('DONOR_COMPANY_INFORMATION'),
    firstColConfig: ORG_COMMON_INFO_FIRST_COL_CONFIG,
    secondColConfig: ORG_COMMON_INFO_SECOND_COL_CONFIG,
  },
};

const USER_DEFAULT_FORM_DATA = {
  firstName: '',
  lastName: '',
  label: '',
  phones: [],
  emails: [],
  summary: {},
};

const ORG_DEFAULT_FORM_DATA = {
  orgName: '',
  description: '',
  phones: [],
  emails: [],
  addresses: [],
  website: null,
  summary: {},
};

// is used for filtering phones and emails which has types but no actual data
const filterEmptyFieldItems = (data, field) =>
  data?.filter(({ label, ...item }) =>
    field ? item[field] : values(item)?.filter((val) => val).length
  );

// maps user data connections before saving
const mapUserConnectionFormData = (formData = {}) => {
  const {
    constituentId,
    displayName,
    firstName,
    lastName,
    label,
    phones = [],
    emails = [],
  } = replaceEmptyValues(formData);

  return processBeforeSaving(constituentId, {
    constituentId,
    displayName: displayName || getFullName({ firstName, lastName }),
    firstName,
    lastName,
    label,
    phones: filterEmptyFieldItems(phones),
    emails: filterEmptyFieldItems(emails, 'email'),
  });
};

// maps org data connections before saving
const mapOrgConnectionFormData = (formData = {}) => {
  const {
    constituentId,
    displayName,
    orgName,
    description,
    phones = [],
    emails = [],
    website,
  } = replaceEmptyValues(formData);

  return processBeforeSaving(constituentId, {
    constituentId,
    displayName: displayName || orgName,
    orgName,
    description,
    phones: filterEmptyFieldItems(phones),
    emails: filterEmptyFieldItems(emails, 'email'),
    website: formatUrl(website),
  });
};

// maps contact info data before saving
const mapContactInfoFormData = (formData = {}) => {
  const {
    constituentId,
    displayName,
    firstName,
    lastName,
    label,
    phones = [],
    emails = [],
    addresses = [],
    dob,
    website,
    instagram,
    twitter,
    linkedin,
    facebook,
  } = replaceEmptyValues(formData);

  return processBeforeSaving(constituentId, {
    constituentId,
    firstName,
    lastName,
    displayName: displayName || getFullName({ firstName, lastName }),
    label,
    phones: filterEmptyFieldItems(phones, 'number'),
    emails: filterEmptyFieldItems(emails, 'email'),
    addresses: filterEmptyFieldItems(addresses.map(replaceEmptyValues)),
    dob: formatDateBeforeSave(dob),
    website: formatUrl(website),
    instagram: formatUrl(instagram),
    twitter: formatUrl(twitter),
    linkedin: formatUrl(linkedin),
    facebook: formatUrl(facebook),
  });
};

// maps work info data before saving
const mapWorkInfoFormData = (formData = {}) => {
  const {
    constituentId,
    displayName,
    orgName,
    label,
    description,
    phones = [],
    emails = [],
    addresses = [],
    website,
    instagram,
    twitter,
    linkedin,
    facebook,
    roles = [],
  } = replaceEmptyValues(formData);

  return processBeforeSaving(constituentId, {
    constituentId,
    orgName,
    displayName: displayName || orgName,
    label,
    description,
    phones: filterEmptyFieldItems(phones, 'number'),
    emails: filterEmptyFieldItems(emails, 'email'),
    addresses: filterEmptyFieldItems(addresses.map(replaceEmptyValues)),
    roles,
    website: formatUrl(website),
    instagram: formatUrl(instagram),
    twitter: formatUrl(twitter),
    linkedin: formatUrl(linkedin),
    facebook: formatUrl(facebook),
  });
};

// maps personal info data before saving
const mapPersonalInfoFormData = (
  formData = {},
  { ownerOrgId, constituentType }
) => {
  const {
    constituentId,
    displayName,
    firstName,
    middleName,
    lastName,
    phones = [],
    emails = [],
    addresses = [],
    labels = [],
    dob,
    website,
    instagram,
    twitter,
    linkedin,
    facebook,
    note1,
    note2,
    note3,
  } = replaceEmptyValues(formData);

  return processBeforeSaving(constituentId, {
    ownerOrgId,
    constituentType,
    firstName,
    middleName,
    lastName,
    displayName:
      displayName || getFullName({ firstName, middleName, lastName }, true),
    phones: filterEmptyFieldItems(phones, 'number'),
    emails: filterEmptyFieldItems(emails, 'email'),
    addresses: filterEmptyFieldItems(addresses.map(replaceEmptyValues)),
    categoryBoardMember:
      formData.categoryBoardMember !== undefined
        ? formData.categoryBoardMember
        : formData.summary.categoryBoardMember,
    categoryCorporation:
      formData.categoryCorporation !== undefined
        ? formData.categoryCorporation
        : formData.summary.categoryCorporation,
    categoryFoundation:
      formData.categoryFoundation !== undefined
        ? formData.categoryFoundation
        : formData.summary.categoryFoundation,
    categoryPartner:
      formData.categoryPartner !== undefined
        ? formData.categoryPartner
        : formData.summary.categoryPartner,
    categoryMember:
      formData.categoryMember !== undefined
        ? formData.categoryMember
        : formData.summary.categoryMember,
    categoryRecipient:
      formData.categoryRecipient !== undefined
        ? formData.categoryRecipient
        : formData.summary.categoryRecipient,
    categoryVolunteer:
      formData.categoryVolunteer !== undefined
        ? formData.categoryVolunteer
        : formData.summary.categoryVolunteer,
    dob: formatDateBeforeSave(dob),
    website: formatUrl(website),
    instagram: formatUrl(instagram),
    twitter: formatUrl(twitter),
    linkedin: formatUrl(linkedin),
    facebook: formatUrl(facebook),
    labels,
    note1,
    note2,
    note3,
  });
};

// maps company info data before saving
const mapCompanyInfoFormData = (
  formData = {},
  { ownerOrgId, constituentType }
) => {
  const {
    constituentId,
    displayName,
    orgName,
    industry,
    phones = [],
    emails = [],
    addresses = [],
    labels = [],
    website,
    instagram,
    twitter,
    linkedin,
    facebook,
    note1,
    note2,
    note3,
  } = replaceEmptyValues(formData);

  return processBeforeSaving(constituentId, {
    ownerOrgId,
    constituentId,
    constituentType,
    orgName,
    displayName: displayName || orgName,
    industry,
    phones: filterEmptyFieldItems(phones, 'number'),
    emails: filterEmptyFieldItems(emails, 'email'),
    addresses: filterEmptyFieldItems(addresses.map(replaceEmptyValues)),
    website: formatUrl(website),
    categoryBoardMember:
      formData.categoryBoardMember !== undefined
        ? formData.categoryBoardMember
        : formData.summary.categoryBoardMember,
    categoryCorporation:
      formData.categoryCorporation !== undefined
        ? formData.categoryCorporation
        : formData.summary.categoryCorporation,
    categoryFoundation:
      formData.categoryFoundation !== undefined
        ? formData.categoryFoundation
        : formData.summary.categoryFoundation,
    categoryPartner:
      formData.categoryPartner !== undefined
        ? formData.categoryPartner
        : formData.summary.categoryPartner,
    categoryMember:
      formData.categoryMember !== undefined
        ? formData.categoryMember
        : formData.summary.categoryMember,
    categoryRecipient:
      formData.categoryRecipient !== undefined
        ? formData.categoryRecipient
        : formData.summary.categoryRecipient,
    categoryVolunteer:
      formData.categoryVolunteer !== undefined
        ? formData.categoryVolunteer
        : formData.summary.categoryVolunteer,
    instagram: formatUrl(instagram),
    twitter: formatUrl(twitter),
    linkedin: formatUrl(linkedin),
    facebook: formatUrl(facebook),
    labels,
    note1,
    note2,
    note3,
  });
};

/**
 * Donor forms related configs
 *
 * Consists of
 * - apiType - a part of the api used in /orgs/{ownerOrgId}/donors/{constituentId}/{apiType}
 * - idField - a name of the id field ("useId" is used by default)
 * - defaultData - default data structure used in the form (necessary for creating a new connection)
 * - config - is used by SectionsGenerator for generating different inputs
 *   (check the top of the page for more info)
 * - mapFormData - is used to map data before saving it
 *
 */
const CONSTITUENT_FORMS_CONFIG = {
  [CONNECTIONS]: {
    apiType: 'connections',
    defaultData: USER_DEFAULT_FORM_DATA,
    config: [
      {
        ...CONSTITUENT_FIELDS_CONFIG.FIRST_NAME,
        icon: EditConnectionsIcon,
      },
      CONSTITUENT_FIELDS_CONFIG.LAST_NAME,
      CONSTITUENT_FIELDS_CONFIG.DISPLAY_NAME,
      CONSTITUENT_FIELDS_CONFIG.CONNECTION_TYPE,
      CONSTITUENT_FIELDS_CONFIG.EDIT_EMAILS,
      CONSTITUENT_FIELDS_CONFIG.EDIT_PHONES,
    ],
    mapFormData: mapUserConnectionFormData,
  },
  [HOUSEHOLD]: {
    apiType: 'household',
    defaultData: USER_DEFAULT_FORM_DATA,
    config: [
      {
        ...CONSTITUENT_FIELDS_CONFIG.FIRST_NAME,
        icon: EditFamilyIcon,
      },
      CONSTITUENT_FIELDS_CONFIG.LAST_NAME,
      CONSTITUENT_FIELDS_CONFIG.DISPLAY_NAME,
      CONSTITUENT_FIELDS_CONFIG.RELATIONSHIP_TYPE,
      CONSTITUENT_FIELDS_CONFIG.EDIT_EMAILS,
      CONSTITUENT_FIELDS_CONFIG.EDIT_PHONES,
    ],
    mapFormData: mapUserConnectionFormData,
  },
  [EMPLOYEES]: {
    apiType: 'employees',
    defaultData: USER_DEFAULT_FORM_DATA,
    config: [
      {
        ...CONSTITUENT_FIELDS_CONFIG.FIRST_NAME,
        icon: EditEmployeesIcon,
      },
      CONSTITUENT_FIELDS_CONFIG.LAST_NAME,
      CONSTITUENT_FIELDS_CONFIG.DISPLAY_NAME,
      CONSTITUENT_FIELDS_CONFIG.TITLE_TYPE,
      CONSTITUENT_FIELDS_CONFIG.EDIT_EMAILS,
      withWorkPhones(CONSTITUENT_FIELDS_CONFIG.EDIT_PHONES),
    ],
    mapFormData: mapUserConnectionFormData,
  },
  [AFFILIATIONS]: {
    apiType: 'affiliations',
    idField: 'orgId',
    defaultData: ORG_DEFAULT_FORM_DATA,
    config: [
      CONSTITUENT_FIELDS_CONFIG.COMPANY_AFFILIATION,
      CONSTITUENT_FIELDS_CONFIG.DISPLAY_NAME,
      CONSTITUENT_FIELDS_CONFIG.DESCRIPTION,
      {
        ...CONSTITUENT_FIELDS_CONFIG.WEBSITE,
        icon: EditWebIcon,
      },
      withBusinessDefaultLabel(CONSTITUENT_FIELDS_CONFIG.EDIT_EMAILS),
      withWorkPhones(CONSTITUENT_FIELDS_CONFIG.EDIT_PHONES),
    ],
    mapFormData: mapOrgConnectionFormData,
  },
  [CONTACT_INFORMATION]: {
    apiType: 'contacts',
    defaultData: USER_DEFAULT_FORM_DATA,
    config: [
      CONSTITUENT_FIELDS_CONFIG.FIRST_NAME,
      CONSTITUENT_FIELDS_CONFIG.LAST_NAME,
      CONSTITUENT_FIELDS_CONFIG.DISPLAY_NAME,
      CONSTITUENT_FIELDS_CONFIG.TITLE_TYPE,
      CONSTITUENT_FIELDS_CONFIG.EDIT_EMAILS,
      CONSTITUENT_FIELDS_CONFIG.EDIT_PHONES,
      CONSTITUENT_FIELDS_CONFIG.EDIT_ADDRESSES,
      {
        ...CONSTITUENT_FIELDS_CONFIG.USER_BIRTHDAY,
        icon: EditBirthdayIcon,
      },
      {
        ...CONSTITUENT_FIELDS_CONFIG.WEBSITE,
        icon: EditWebIcon,
      },
    ],
    showMoreConfig: [
      CONSTITUENT_FIELDS_CONFIG.INSTAGRAM,
      CONSTITUENT_FIELDS_CONFIG.TWITTER,
      CONSTITUENT_FIELDS_CONFIG.LINKEDIN,
      CONSTITUENT_FIELDS_CONFIG.FACEBOOK,
    ],
    mapFormData: mapContactInfoFormData,
  },
  [WORK_INFORMATION]: {
    apiType: 'work',
    idField: 'workId',
    defaultData: ORG_DEFAULT_FORM_DATA,
    config: [
      CONSTITUENT_FIELDS_CONFIG.COMPANY_NAME,
      CONSTITUENT_FIELDS_CONFIG.DISPLAY_NAME,
      CONSTITUENT_FIELDS_CONFIG.JOB_TITLE,
      withBusinessDefaultLabel(CONSTITUENT_FIELDS_CONFIG.EDIT_EMAILS),
      withWorkPhones(CONSTITUENT_FIELDS_CONFIG.EDIT_PHONES),
      {
        ...CONSTITUENT_FIELDS_CONFIG.EDIT_ADDRESSES,
        icon: EditBuildingIcon,
        defaultValue: {
          ...EDIT_ADDRESS_DEFAULT_VALUE,
          label: LABELS.OFFICE,
        },
      },
      {
        ...CONSTITUENT_FIELDS_CONFIG.WEBSITE,
        icon: EditWebIcon,
      },
    ],
    showMoreConfig: [
      CONSTITUENT_FIELDS_CONFIG.INSTAGRAM,
      CONSTITUENT_FIELDS_CONFIG.TWITTER,
      CONSTITUENT_FIELDS_CONFIG.LINKEDIN,
      CONSTITUENT_FIELDS_CONFIG.FACEBOOK,
    ],
    mapFormData: mapWorkInfoFormData,
  },
  [PERSONAL_INFORMATION]: {
    idField: 'constituentId',
    defaultData: USER_DEFAULT_FORM_DATA,
    config: [
      CONSTITUENT_FIELDS_CONFIG.FIRST_NAME,
      CONSTITUENT_FIELDS_CONFIG.MIDDLE_NAME,
      CONSTITUENT_FIELDS_CONFIG.LAST_NAME,
      CONSTITUENT_FIELDS_CONFIG.DISPLAY_NAME,
      CONSTITUENT_FIELDS_CONFIG.EDIT_EMAILS,
      CONSTITUENT_FIELDS_CONFIG.EDIT_PHONES,
      CONSTITUENT_FIELDS_CONFIG.EDIT_ADDRESSES,
      CONSTITUENT_FIELDS_CONFIG.EDIT_CONSTITUENT_ATTRIBUTES,
      CONSTITUENT_FIELDS_CONFIG.EDIT_CONSTITUENT_LABELS,
    ],
    showMoreConfig: [
      {
        ...CONSTITUENT_FIELDS_CONFIG.USER_BIRTHDAY,
        icon: EditBirthdayIcon,
      },
      {
        ...CONSTITUENT_FIELDS_CONFIG.WEBSITE,
        icon: EditWebIcon,
      },
      CONSTITUENT_FIELDS_CONFIG.INSTAGRAM,
      CONSTITUENT_FIELDS_CONFIG.TWITTER,
      CONSTITUENT_FIELDS_CONFIG.LINKEDIN,
      CONSTITUENT_FIELDS_CONFIG.FACEBOOK,
    ],
    mapFormData: mapPersonalInfoFormData,
  },
  [COMPANY_INFORMATION]: {
    idField: 'constituentId',
    defaultData: ORG_DEFAULT_FORM_DATA,
    config: [
      CONSTITUENT_FIELDS_CONFIG.COMPANY_NAME,
      CONSTITUENT_FIELDS_CONFIG.DISPLAY_NAME,
      {
        ...CONSTITUENT_FIELDS_CONFIG.ORG_INDUSTRY,
        icon: EditIndustryIcon,
      },
      withBusinessDefaultLabel(CONSTITUENT_FIELDS_CONFIG.EDIT_EMAILS),
      withWorkPhones(CONSTITUENT_FIELDS_CONFIG.EDIT_PHONES),
      {
        ...CONSTITUENT_FIELDS_CONFIG.EDIT_ADDRESSES,
        icon: EditBuildingIcon,
        defaultValue: {
          ...EDIT_ADDRESS_DEFAULT_VALUE,
          label: LABELS.OFFICE,
        },
      },
      {
        ...CONSTITUENT_FIELDS_CONFIG.WEBSITE,
        icon: EditWebIcon,
      },
      CONSTITUENT_FIELDS_CONFIG.EDIT_CONSTITUENT_ATTRIBUTES,
      CONSTITUENT_FIELDS_CONFIG.EDIT_CONSTITUENT_LABELS,
    ],
    showMoreConfig: [
      CONSTITUENT_FIELDS_CONFIG.INSTAGRAM,
      CONSTITUENT_FIELDS_CONFIG.TWITTER,
      CONSTITUENT_FIELDS_CONFIG.LINKEDIN,
      CONSTITUENT_FIELDS_CONFIG.FACEBOOK,
    ],
    mapFormData: mapCompanyInfoFormData,
  },
};

const PHONE_NUMBER_COMMON_PROPS = {
  Component: EditDonorPhoneInput,
  icon: EditWorkPhoneIcon,
  path: 'phones',
  useMultipleInputs: true,
  useIndexes: false,
};

const EMPLOYEE_PROFILE_FIELDS_CONFIG = {
  USER_FIRST_NAME: {
    key: 'USER_FIRST_NAME',
    label: translate('FIRST_NAME_LABEL'),
    path: 'firstName',
    required: true,
    validate: isNotEmpty,
  },
  USER_LAST_NAME: {
    key: 'USER_LAST_NAME',
    label: translate('LAST_NAME_LABEL'),
    path: 'lastName',
  },
  USER_DISPLAY_NAME: CONSTITUENT_FIELDS_CONFIG.DISPLAY_NAME,
  USER_TITLE: {
    key: 'USER_TITLE',
    icon: JobIcon,
    label: translate('TITLE_LABEL'),
    path: 'jobTitle',
  },
  USER_WORK_EMAIL: {
    key: 'USER_WORK_EMAIL',
    icon: EmailIcon,
    label: translate('WORK_EMAIL_ADDRESS_LABEL'),
    path: 'workEmail',
    required: true,
    disabled: true,
    validate: isNotEmpty,
  },
  USER_PHONE_NUMBER: {
    key: 'USER_PHONE_NUMBER',
    icon: WorkPhoneIcon,
    iconMapper: PHONE_LABEL_ICON_MAPPER,
    path: 'phones',
    fieldToExtract: 'number',
    mapper: mapPhoneNumbers,
    formatValue: formatPhoneNumber,
    noDataLabel: translate('ADD_PHONE'),
    useMultipleInputs: true,
    Component: EditDonorPhoneInput,
    defaultValue: {
      number: '',
      label: LABELS.WORK,
      primary: false,
    },
    labels: [LABELS.MOBILE, LABELS.WORK].map((label) => ({
      label: titleCase(label),
      value: label,
    })),
    usePrimary: true,
  },
  USER_PHONE_NUMBER_OLD: {
    key: 'USER_PHONE_NUMBER',
    useMultipleInputs: true,
    defaultValue: {
      dataPath: 'phones.primary',
      type: 'primary',
    },
    types: [
      {
        label: translate('PRIMARY_LABEL'),
        value: 'primary',
      },
      {
        label: translate('MOBILE_LABEL'),
        value: 'mobile',
      },
    ],
    ...PHONE_NUMBER_COMMON_PROPS,
  },
};

const EDIT_DONOR_FORM_TYPES = {
  user: {
    label: translate('EDIT_DONOR_FORM_PERSON_LABEL'),
    value: 'user',
  },
  org: {
    label: translate('EDIT_DONOR_FORM_ORG_LABEL'),
    value: 'org',
  },
};

const EMPLOYEE_INFO_CONFIG = [
  EMPLOYEE_PROFILE_FIELDS_CONFIG.USER_FIRST_NAME,
  EMPLOYEE_PROFILE_FIELDS_CONFIG.USER_LAST_NAME,
  EMPLOYEE_PROFILE_FIELDS_CONFIG.USER_DISPLAY_NAME,
  EMPLOYEE_PROFILE_FIELDS_CONFIG.USER_TITLE,
  EMPLOYEE_PROFILE_FIELDS_CONFIG.USER_WORK_EMAIL,
  EMPLOYEE_PROFILE_FIELDS_CONFIG.USER_PHONE_NUMBER,
];

const COMPANY_FIELDS_CONFIG = {
  ORG_NAME: {
    key: 'ORG_NAME',
    label: translate('COMPANY_NAME_LABEL'),
    path: 'orgName',
    required: true,
    validate: isNotEmpty,
  },
  EMPLOYEE_ID: {
    key: 'EMPLOYEE_ID',
    label: translate('COMPANY_EMPLOYEE_ID_LABEL'),
    path: 'ein',
    noDataLabel: translate('COMPANY_ADD_EMPLOYEE_ID_LABEL'),
    formatValue: formatEmployeeId,
    valueMapper: formatEmployeeId,
    newValueMapper: (value) =>
      value?.replace(/\D/g, '')?.slice(0, EID_NUM_LENGTH) || '',
  },
  PRIMARY_PHONE: {
    key: 'PRIMARY_PHONE',
    Component: EditDonorPhoneInput,
    label: translate('COMPANY_PHONE_NUMBER_LABEL'),
    path: 'primaryPhone',
    mapper: mapPhoneNumbers,
    noDataLabel: translate('COMPANY_ADD_PHONE_NUMBER_LABEL'),
  },
  ADDRESS: {
    key: 'ADDRESS',
    Component: AddressInputs,
    path: 'primaryAddress',
    mapper: mapAddresses,
    noDataLabel: translate('COMPANY_ADD_ADDRESS_LABEL'),
    multiline: true,
  },
  WEBSITE: {
    key: 'WEBSITE',
    label: translate('COMPANY_WEBSITE_LABEL'),
    path: 'website',
    mapper: mapWebsite,
    formatValue: formatWebAddress,
    noDataLabel: translate('COMPANY_ADD_WEBSITE_LABEL'),
    validate: isValidUrl,
    errorMessage: translate('INVALID_URL_FORMAT_MESSAGE'),
  },
};

const getCompanyInfoConfig = (isEditConfig) => {
  const config = [];

  if (isEditConfig) {
    config.push({
      key: 'ORG_NAME',
      label: translate('COMPANY_NAME_LABEL'),
      path: 'orgName',
      required: true,
      validate: isNotEmpty,
    });
  }

  return [
    ...config,
    {
      ...COMPANY_FIELDS_CONFIG.EMPLOYEE_ID,
      icon: isEditConfig ? EditEmployeeIdIcon : EmployeeIdIcon,
    },
    {
      ...COMPANY_FIELDS_CONFIG.PRIMARY_PHONE,
      formatValue: formatPhoneNumber,
      icon: isEditConfig ? EditWorkPhoneIcon : WorkPhoneIcon,
    },
    {
      ...COMPANY_FIELDS_CONFIG.ADDRESS,
      icon: isEditConfig ? EditBuildingIcon : BuildingIcon,
    },
    {
      ...COMPANY_FIELDS_CONFIG.WEBSITE,
      icon: isEditConfig ? EditWebIcon : WebIcon,
    },
  ];
};

const VIEW_COMPANY_INFO_CONFIG = getCompanyInfoConfig(false);
const EDIT_COMPANY_INFO_CONFIG = getCompanyInfoConfig(true);

const USER_PROFILE_DESCRIPTOR_CONFIG_1 = [
  {
    key: 'USER_PROFILE_DESCRIPTOR_JOB_TITLE',
    icon: JobIcon,
    path: 'jobTitle',
  },
  {
    key: 'USER_WORK_EMAIL',
    icon: EmailIcon,
    path: 'workEmails',
    fieldToExtract: 'email',
    mapper: mapEmails,
  },
  {
    key: 'USER_WORK_PHONE_NUMBER',
    icon: PhoneIcon,
    path: 'mobilePhones',
    mapper: mapPhoneNumbers,
    fieldToExtract: 'number',
  },
  {
    key: 'USER_MOBILE_PHONE_NUMBER',
    icon: RedPhoneIcon,
    path: 'workPhones',
    fieldToExtract: 'number',
    mapper: mapPhoneNumbers,
  },
];

export {
  EDIT_DONOR_FORM_TYPES,
  EMPLOYEE_INFO_CONFIG,
  VIEW_COMPANY_INFO_CONFIG,
  EDIT_COMPANY_INFO_CONFIG,
  USER_PROFILE_DESCRIPTOR_CONFIG_1,
  CONSTITUENT_FIELDS_CONFIG,
  // ConstituentInfoSection related configs
  USER_DONOR_INFO_SECTION_CONFIG,
  ORG_DONOR_INFO_SECTION_CONFIG,
  CONSTITUENT_NOTES_CONFIG,
  // CommonInformation related configs
  USER_COMMON_INFO_FIRST_COL_CONFIG,
  USER_COMMON_INFO_SECOND_COL_CONFIG,
  USER_WORK_INFO_FIRST_COL_CONFIG,
  USER_WORK_INFO_SECOND_COL_CONFIG,
  ORG_COMMON_INFO_FIRST_COL_CONFIG,
  ORG_COMMON_INFO_SECOND_COL_CONFIG,
  // ConstituentConnectionSection related configs
  DONOR_SECTION_CONFIGS,
  // ConstituentWorkAndContactsSection related configs
  ORG_CONTACTS_FIRST_COL_CONFIG,
  ORG_CONTACTS_SECOND_COL_CONFIG,
  // Constituent forms related configs
  CONSTITUENT_FORMS_CONFIG,
  EDIT_CONSTITUENT_NOTES_CONFIG,
  // formatters
  formatWebAddress,
};
