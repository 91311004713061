import React from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { hotjar } from 'react-hotjar';
import env from '@beam-australia/react-env';

const selectAuthFields = (state) => ({
  isSuperAdmin: state.authentication?.isSuperAdmin,
  userId: state.authentication?.userId,
});
const useHotJar = () => {
  const { isSuperAdmin, userId } = useSelector(selectAuthFields, shallowEqual);
  const isProd = process.env.NODE_ENV === 'production';

  React.useEffect(() => {
    if (!isSuperAdmin && isProd && userId) {
      const id = env('HOTJAR_ID');
      const sv = env('HOTJAR_SV');
      hotjar.initialize(id, sv);
    }
  }, [isSuperAdmin, userId]);
};

export default useHotJar;
