// These are the new palette colors and are named according to their semantics
// or utility. Currently this is being merged into our old theme which is being
// used by legacy styled-components and a mix of current MUI themed components.
// Tailwind should only be using the current palette.
module.exports = {
  // new colors here
  adminColor: '#00EB80',
  attribute: {
    advocate: '#D42CB9',
    automation: '#DC0C70',
    boardMember: '#9029CF',
    corporation: '#00C7D4',
    foundation: '#00A35E',
    recipient: '#14AAEA',
    member: '#EF870C',
    partner: '#6EC517',
    vip: '#EBB814',
    volunteer: '#2C68DD',
  },
  error: {
    main: '#ed6257',
    light: '#ffcccc',
    contrastText: '#000',
  },
  grey: {
    100: '#F8F8F8',
    300: '#ececec',
    600: '#ccc',
    900: '#666',
    lighter: {
      backgroundColor: '#f7f7f7',
      color: '#707070',
    },
  },
  primary: {
    main: '#06838C',
    active: '#0175B6',
    focus: '#0175B6',
    hover: '#0175B6',
    dark: '#03484D',
    contrastText: '#fff',
  },
  secondary: {
    main: '#5039AD',
    active: '#6B5ADB',
    focus: '#6B5ADB',
    hover: '#6B5ADB',
    dark: '#2A1B66',
    contrastText: '#fff',
  },
  text: {
    primary: '#000',
    secondary: '#666',
    disabled: '#ccc',
  },
  success: {
    main: '#72C86A',
    light: '#CFFCCF',
    contrastText: '#000',
  },
  warning: {
    main: '#ffd464',
    contrastText: '#000',
  },
  primaryLink: {
    main: '#06838c',
    light: '#0175b6',
    dark: '#03484d',
  },
  primaryButton: {
    main: '#5039ad',
    light: '#6B5adb',
    dark: '#2a1b66',
    contrastText: '#fff',
  },
  secondaryButton: {
    main: '#c2bae4',
    light: '#cabefd',
    dark: '#9b8ed4',
    contrastText: '#2a1b66',
  },
  subNav: {
    active: '#03DAC6',
  },
  white: '#fff',
};
