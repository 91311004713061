import React from 'react';
import PropTypes from 'prop-types';
import CharacterCounter from 'components/common/form/CharacterCounter';
import { EMPTY_FUNC } from 'lib/constants';
import { TextField } from '@material-ui/core';

const NotesInput = ({ label, maxLength, notes, setNotes, showCounter }) => {
  const handleChange = (event) => {
    setNotes(event.target.value);
  };

  return (
    <TextField
      data-testid={`notes-input-${label}`}
      label={label}
      multiline
      fullWidth
      rows={4}
      variant="filled"
      value={notes}
      onChange={handleChange}
      inputProps={maxLength ? { maxLength } : {}}
      helperText={
        !!maxLength &&
        showCounter && (
          <CharacterCounter
            count={notes.length}
            limit={maxLength}
            className="text-right"
          />
        )
      }
    />
  );
};

NotesInput.propTypes = {
  label: PropTypes.string,
  maxLength: PropTypes.number,
  notes: PropTypes.string,
  setNotes: PropTypes.func,
  showCounter: PropTypes.func,
};

NotesInput.defaultProps = {
  label: '',
  maxLength: null,
  notes: '',
  setNotes: EMPTY_FUNC,
  showCounter: false,
};

export { NotesInput };
