import {
  camelCase,
  capitalize,
  lowerCase,
  snakeCase,
  titleCase,
  upperCase,
} from 'voca';

const transformKeys = (fn) => (obj) => {
  if (!!obj && typeof obj === 'object' && obj !== null) {
    const keys = Object.keys(obj);
    const copy = Object.create(obj);
    let i = keys.length;

    // eslint-disable-next-line
    while (i--) {
      const key = keys[i];
      const val = obj?.[key] ?? null;

      if (val instanceof Array) {
        copy[fn(key)] = val.map((innerVal) => transformKeys(fn)(innerVal));
      } else {
        copy[fn(key)] = transformKeys(fn)(val);
      }
    }

    return copy;
  }

  return obj;
};

const camelCaseKeys = transformKeys(camelCase);
const snakeCaseKeys = transformKeys(snakeCase);

export {
  camelCase,
  capitalize,
  snakeCase,
  titleCase,
  transformKeys,
  camelCaseKeys,
  lowerCase,
  snakeCaseKeys,
  upperCase,
};
