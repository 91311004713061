import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { feedActions } from 'rdx/actions';
import { FEED_ENTITY_TYPES } from 'lib/configs';

// Fetches initial page, handles active feed item scroll logic
const feedControl = (
  activeFeedItemId,
  mobileActiveItem,
  isMobile,
  isFetching,
  itemRefs,
  layoutContainerRef,
  getFeedPage,
  feed,
  scrollToRef
) => {
  const { pageFrom, items } = feed;

  // Fetch the first page of the feed
  useEffect(() => {
    if (!isFetching && !activeFeedItemId) {
      getFeedPage();
    }
  }, []);

  // Handle scroll behavior if active item is linked
  useEffect(() => {
    if (items && activeFeedItemId) {
      const foundItem = items.find(
        (item) => item.feedItemId === activeFeedItemId
      );

      // Logic to load until feed item is found
      if (!isFetching && !foundItem) {
        getFeedPage(pageFrom);
      }

      if (foundItem) {
        scrollToRef(itemRefs[foundItem.feedItemId]);
      }
    }

    // TODO refactor this part to be cleaner once behavior is approved
    // Mobile feed styling control for locking / scrolling container
    /* eslint-disable no-param-reassign */
    if (layoutContainerRef) {
      if (mobileActiveItem) {
        layoutContainerRef.current.style.overflowY = 'hidden';
      } else if (isMobile) {
        layoutContainerRef.current.style.overflowY = 'auto';
      } else {
        layoutContainerRef.current.style.overflowY = 'hidden';
      }
    }
  }, [feed, mobileActiveItem]);
};

const useFeedContent = (contentId, contentType) => {
  const dispatch = useDispatch();

  const { entity, isFetching, ownerOrgId, error } = useSelector((state) => ({
    entity: state.feedEntity?.entity,
    isFetching: state.feedEntity?.isFetching,
    ownerOrgId: state.authentication?.ownerOrgId,
    error: state.feedEntity?.error,
  }));

  useEffect(() => {
    dispatch(
      feedActions.fetchFeedEntity(FEED_ENTITY_TYPES[contentType], contentId)
    );
  }, [contentId, contentType]);

  let entityType;

  // Get data from matching content key of resource
  // makes sure to pass all the other props from entity as well
  if (entity) {
    const { [contentType]: entityData, ...rest } = entity;

    entityType = { ...entityData, ...rest };
  }

  return {
    entity: entityType,
    isFetching,
    error,
    ownerOrgId,
  };
};

export { feedControl, useFeedContent };
