import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import CustomDialog from 'components/common/CustomDialog';
import { translate } from 'lib/intl';
import { featureFlagActions } from 'rdx/actions/featureFlags';

export default function DisableFeatureFlagModal({
  open,
  flagName,
  orgIds,
  handleClose,
  setOpen,
  onApiResponse,
}) {
  const dispatch = useDispatch();
  const handleDisable = async () => {
    try {
      const response = await dispatch(
        featureFlagActions.updateFeatureFlag({
          flagName,
          flagValue: false,
          orgIds,
          flagScope: 'global',
        })
      );
      onApiResponse(response);
    } catch (error) {
      onApiResponse(error);
    } finally {
      handleClose();
    }
  };
  return (
    <CustomDialog
      actionButtonLabel={translate('DISABLE')}
      open={open}
      onClose={handleClose}
      handleActionButton={handleDisable}
      dialogHeader={translate('FEATURE_FLAGS_DISABLE')}
      setOpen={setOpen}
    >
      <Box display="flex" flexWrap="wrap" flexDirection="row">
        <Box>
          <Typography variant="body1">
            {translate('FEATURE_FLAGS_DISABLE_TEXT_1')}
          </Typography>
        </Box>
        <Box>&nbsp;</Box>
        <Box>
          <Typography variant="body2">{flagName}</Typography>
        </Box>
        <Box>
          <Typography variant="body1">
            {translate('FEATURE_FLAGS_DISABLE_TEXT_2')}
          </Typography>
        </Box>
      </Box>
    </CustomDialog>
  );
}

DisableFeatureFlagModal.defaultProps = {
  orgIds: [],
};
DisableFeatureFlagModal.propTypes = {
  open: PropTypes.bool.isRequired,
  orgIds: PropTypes.arrayOf(PropTypes.string),
  flagName: PropTypes.string.isRequired,
  handleClose: PropTypes.func.isRequired,
  onApiResponse: PropTypes.func.isRequired,
  setOpen: PropTypes.func.isRequired,
};
