import {
  createAction,
  mapCampaign,
  sortCampaigns,
  showErrorMessage,
  timeout,
} from 'lib/utils';
import { translate } from 'lib/intl';
import actionTypes from 'rdx/actionTypes';
import { campaignsService } from 'services/campaigns';
import { mapCampaignDonations } from './campaigns.utils';

const {
  FETCH_CAMPAIGNS_REQUEST,
  FETCH_CAMPAIGNS_SUCCESS,
  FETCH_CAMPAIGNS_FAILURE,

  FETCH_CAMPAIGN_REQUEST,
  FETCH_CAMPAIGN_SUCCESS,
  FETCH_CAMPAIGN_FAILURE,

  CREATE_CAMPAIGN_REQUEST,
  CREATE_CAMPAIGN_SUCCESS,
  CREATE_CAMPAIGN_FAILURE,

  UPDATE_CAMPAIGN_REQUEST,
  UPDATE_CAMPAIGN_SUCCESS,
  UPDATE_CAMPAIGN_FAILURE,
  UPDATE_CAMPAIGN_RESET,

  UPDATE_MULTIPLE_CAMPAIGNS_REQUEST,
  UPDATE_MULTIPLE_CAMPAIGNS_SUCCESS,
  UPDATE_MULTIPLE_CAMPAIGNS_FAILURE,

  REMOVE_CAMPAIGN_REQUEST,
  REMOVE_CAMPAIGN_SUCCESS,
  REMOVE_CAMPAIGN_FAILURE,

  UPDATE_CAMPAIGNS_FISCAL_GOAL_REQUEST,
  UPDATE_CAMPAIGNS_FISCAL_GOAL_SUCCESS,
  UPDATE_CAMPAIGNS_FISCAL_GOAL_FAILURE,

  FETCH_CAMPAIGN_DONATIONS_REQUEST,
  FETCH_CAMPAIGN_DONATIONS_SUCCESS,
  FETCH_CAMPAIGN_DONATIONS_FAILURE,
} = actionTypes;

const fetchCampaigns =
  (ownerOrgId, { limit = 1000 } = {}) =>
  async (dispatch, getState) => {
    try {
      const { page } = getState().campaigns;
      dispatch(createAction({ type: FETCH_CAMPAIGNS_REQUEST }));

      const response = await campaignsService.getCampaigns(ownerOrgId, {
        limit,
        page,
      });

      dispatch(
        createAction({
          type: FETCH_CAMPAIGNS_SUCCESS,
          data: sortCampaigns(response.data.campaigns.map(mapCampaign)),
          page: response.data.page,
        })
      );

      return response;
    } catch (error) {
      showErrorMessage({
        message: translate('FETCH_CAMPAIGNS_ERROR'),
      });

      dispatch(
        createAction({
          type: FETCH_CAMPAIGNS_FAILURE,
          error,
        })
      );

      return error;
    }
  };

const fetchCampaign = (campaignId) => async (dispatch) => {
  try {
    dispatch(createAction({ type: FETCH_CAMPAIGN_REQUEST, campaignId }));

    const response = await campaignsService.getCampaign(campaignId);

    dispatch(
      createAction({
        type: FETCH_CAMPAIGN_SUCCESS,
        campaignId,
        data: mapCampaign(response.data.campaign),
      })
    );
  } catch (error) {
    showErrorMessage({
      message: translate('FETCH_CAMPAIGNS_ERROR'),
    });

    dispatch(
      createAction({
        type: FETCH_CAMPAIGN_FAILURE,
        campaignId,
        error,
      })
    );
  }
};

const fetchCampaignDonations = (campaignId) => async (dispatch) => {
  try {
    dispatch(
      createAction({ type: FETCH_CAMPAIGN_DONATIONS_REQUEST, campaignId })
    );

    const response = await campaignsService.fetchCampaignDonations(campaignId);

    dispatch(
      createAction({
        type: FETCH_CAMPAIGN_DONATIONS_SUCCESS,
        campaignId,
        data: mapCampaignDonations(response.data.donations),
      })
    );
  } catch (error) {
    showErrorMessage({
      message: translate('FETCH_CAMPAIGN_DONATIONS_ERROR'),
    });

    dispatch(
      createAction({
        campaignId,
        type: FETCH_CAMPAIGN_DONATIONS_FAILURE,
        error,
      })
    );
  }
};

const updateCampaign =
  ({ campaignId, data }) =>
  async (dispatch) => {
    try {
      dispatch(
        createAction({
          type: campaignId ? UPDATE_CAMPAIGN_REQUEST : CREATE_CAMPAIGN_REQUEST,
        })
      );

      const response = await campaignsService.createOrUpdateCampaign({
        campaignId,
        data,
      });

      dispatch(
        createAction({
          type: campaignId ? UPDATE_CAMPAIGN_SUCCESS : CREATE_CAMPAIGN_SUCCESS,
          data: mapCampaign(response.data.campaign),
        })
      );
    } catch (error) {
      showErrorMessage({
        message: translate(
          campaignId
            ? 'UPDATE_CAMPAIGN_ERROR_MESSAGE'
            : 'CREATE_CAMPAIGN_ERROR_MESSAGE',
          {
            name: data?.name,
          }
        ),
      });

      dispatch(
        createAction({
          type: campaignId ? UPDATE_CAMPAIGN_FAILURE : CREATE_CAMPAIGN_FAILURE,
          error,
        })
      );
    }
  };

const updateMultipleCampaigns =
  ({ campaignId, data }) =>
  async (dispatch) => {
    try {
      dispatch(
        createAction({
          type: UPDATE_MULTIPLE_CAMPAIGNS_REQUEST,
          campaignId,
        })
      );

      const response = await campaignsService.createOrUpdateCampaign({
        campaignId,
        data,
      });

      dispatch(
        createAction({
          type: UPDATE_MULTIPLE_CAMPAIGNS_SUCCESS,
          campaignId,
          data: mapCampaign(response.data.campaign),
        })
      );
    } catch (error) {
      showErrorMessage({
        message: translate('UPDATE_CAMPAIGN_ERROR_MESSAGE', {
          name: data?.name,
        }),
      });

      dispatch(
        createAction({
          type: UPDATE_MULTIPLE_CAMPAIGNS_FAILURE,
          campaignId,
          error,
        })
      );
    }
  };

const removeCampaign = (campaignId) => async (dispatch) => {
  try {
    dispatch(createAction({ type: REMOVE_CAMPAIGN_REQUEST, campaignId }));

    const response = await campaignsService.removeCampaign(campaignId);

    dispatch(
      createAction({
        campaignId,
        type: REMOVE_CAMPAIGN_SUCCESS,
        data: response.data,
      })
    );
  } catch (error) {
    showErrorMessage({
      message: translate('REMOVE_CAMPAIGN_ERROR'),
    });

    dispatch(
      createAction({
        campaignId,
        type: REMOVE_CAMPAIGN_FAILURE,
        error,
      })
    );
  }
};

const resetUpdatingCampaign = () => (dispatch) =>
  dispatch({ type: UPDATE_CAMPAIGN_RESET });

const updateCampaignFiscalGoal = (goal) => async (dispatch) => {
  try {
    dispatch(createAction({ type: UPDATE_CAMPAIGNS_FISCAL_GOAL_REQUEST }));

    // TODO: DOT-409 add api call

    await timeout(1000);

    dispatch(
      createAction({
        type: UPDATE_CAMPAIGNS_FISCAL_GOAL_SUCCESS,
        data: { goal },
      })
    );
  } catch (error) {
    showErrorMessage({
      message: translate('UPDATE_CAMPAIGN_FISCAL_GOAL_ERROR'),
    });

    dispatch(
      createAction({
        type: UPDATE_CAMPAIGNS_FISCAL_GOAL_FAILURE,
        error,
      })
    );
  }
};

export const campaignsActions = {
  fetchCampaigns,
  fetchCampaign,
  fetchCampaignDonations,
  updateCampaign,
  updateMultipleCampaigns,
  resetUpdatingCampaign,
  removeCampaign,
  updateCampaignFiscalGoal,
};
