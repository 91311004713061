import { createAction, showErrorMessage, showSuccessMessage } from 'lib/utils';
import { interactionsService } from 'services/interactions';
import userActionTypes from 'rdx/actionTypes';
import { translate } from 'lib/intl';
import { transformConstituentInteraction } from 'lib/utils/constituentDataTransformers';

const {
  INTERACTION_REQUEST,
  INTERACTION_SUCCESS,
  INTERACTION_FAILURE,

  ADD_INTERACTION_REQUEST,
  ADD_INTERACTION_SUCCESS,
  ADD_INTERACTION_FAILURE,

  EDIT_INTERACTION_REQUEST,
  EDIT_INTERACTION_SUCCESS,
  EDIT_INTERACTION_FAILURE,
} = userActionTypes;

const fetchInteraction = (interactionId) => async (dispatch) => {
  try {
    dispatch(createAction({ type: INTERACTION_REQUEST }));

    const response = await interactionsService.fetchInteraction(interactionId);

    dispatch(
      createAction({
        type: INTERACTION_SUCCESS,
        data: response?.data || {},
      })
    );
  } catch (error) {
    dispatch(
      createAction({
        type: INTERACTION_FAILURE,
        error,
      })
    );
  }
};

const submitInteraction =
  ({ data }) =>
  async (dispatch) => {
    const { interactionId } = data;
    try {
      dispatch(
        createAction({
          type: interactionId
            ? EDIT_INTERACTION_REQUEST
            : ADD_INTERACTION_REQUEST,
        })
      );

      const response = await interactionsService.submitInteraction({
        data: transformConstituentInteraction(data),
        interactionId,
      });

      dispatch(
        createAction({
          type: interactionId
            ? EDIT_INTERACTION_SUCCESS
            : ADD_INTERACTION_SUCCESS,
          data: response?.data || {},
        })
      );

      showSuccessMessage({
        message: translate(
          interactionId
            ? 'EDIT_INTERACTION_SUCCESS_MESSAGE'
            : 'ADD_NEW_INTERACTION_SUCCESS_MESSAGE'
        ),
      });
    } catch (error) {
      showErrorMessage({
        message: translate(
          interactionId
            ? 'EDIT_NEW_INTERACTION_ERROR'
            : 'ADD_NEW_INTERACTION_ERROR'
        ),
      });

      dispatch(
        createAction({
          type: interactionId
            ? EDIT_INTERACTION_FAILURE
            : ADD_INTERACTION_FAILURE,
          error,
        })
      );
    }
  };

export const interactionActions = {
  fetchInteraction,
  submitInteraction,
};
