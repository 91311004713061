import React from 'react';
import { fetchConstituent } from 'services/constituentServices';
import { useSelector } from 'react-redux';

const useConstituentById = (constituentId) => {
  const [constituent, setConstituent] = React.useState(null);
  const [isFetching, setIsFetching] = React.useState(false);
  const { ownerOrgId } = useSelector((state) => ({
    ownerOrgId: state.authentication?.ownerOrgId || '',
  }));

  React.useEffect(() => {
    if (!constituent || constituent?.constituentId !== constituentId) {
      (async () => {
        setIsFetching(true);
        try {
          const { data } = await fetchConstituent({
            ownerOrgId,
            constituentId,
          });
          setConstituent(data.constituent);
        } catch (err) {
          // TODO(jon): Do something with the error here?
        } finally {
          setIsFetching(false);
        }
      })();
    }
  }, [constituentId]);

  return { constituent, isFetching };
};

export default useConstituentById;
