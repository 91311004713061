import React from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/styles/makeStyles';
import PropTypes from 'prop-types';
import { EMPTY_FUNC } from 'lib/constants';
import { translate } from 'lib/intl';
import { Gap, CustomButton, SearchInput, Visible, Loader } from 'components';
import { colors } from 'styles/theme';
import { Close } from '@material-ui/icons';
import ConstituentPropType from 'lib/types/ConstituentPropType';
import ConstituentCardTeamMemberRowItem from './ConstituentCardTeamMemberRowItem';
import useConstituentWithTeamMembers from './useConstituentWithTeamMembers';
import useUpdateConstituentAssignment from './useUpdateConstituentAssignment';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    background: colors.white,
    flexDirection: 'column',
    [theme.breakpoints.down('xs')]: {
      width: 'auto',
    },
  },
  itemContainer: {
    height: '56px',
    width: '100%',
    borderBottom: `1px solid ${colors.lightGrey}`,
    position: 'relative',
  },
  item: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    padding: '0 16px',
  },
  icon: {
    height: '18px',
    width: '18px',
  },
  radio: {
    position: 'absolute',
    right: '4px',
  },
  buttonContainer: {
    height: '88px',
    padding: '16px',
    display: 'flex',
    justifyContent: 'flex-end',
    flexDirection: 'row',
  },
  orderSelect: {
    width: '100px',
  },
  listContainer: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    maxHeight: '360px',
    overflowY: 'auto',
    minHeight: '70px',
  },
  searchContainer: {
    padding: '16px',
  },
}));

const ConstituentCardItemActionAssign = ({ constituent, header, onClose }) => {
  const styles = useStyles();
  const {
    teamMemberFilter,
    setTeamMemberFilter,
    filterTeamMembers,
    assignedTeamMembers,
    isTeamMembersLoading,
    setPrimary,
    setSecondary,
  } = useConstituentWithTeamMembers(constituent);
  const { isUpdating, updateConstituentAssignment } =
    useUpdateConstituentAssignment();

  const handleSubmit = () => {
    const formattedData = assignedTeamMembers
      .filter((member) => member.isPrimary || member.isSecondary)
      .map((member) => ({
        userId: member.userId,
        primary: !!member.isPrimary,
        displayName: member.displayName,
      }));

    updateConstituentAssignment(constituent.constituentId, {
      developers: formattedData,
    });

    onClose();
  };

  const renderTeamMembers = () =>
    filterTeamMembers(assignedTeamMembers).map((teamMember) => (
      <ConstituentCardTeamMemberRowItem
        key={teamMember?.userId}
        teamMemberId={teamMember?.userId}
        title={teamMember?.displayName}
        primary={teamMember?.isPrimary}
        setPrimary={setPrimary}
        secondary={teamMember?.isSecondary}
        setSecondary={setSecondary}
      />
    ));

  return (
    <Box className={styles.container}>
      <Box className={styles.itemContainer}>
        <Box
          className={styles.item}
          style={{ justifyContent: 'space-between' }}
        >
          <Typography variant="button" style={{ textTransform: 'capitalize' }}>
            {translate('DONOR_CARD_ACTION_ASSIGN')}
          </Typography>
          <Close
            data-testid="constituent-card-item-actions-close"
            className={styles.icon}
            style={{ cursor: 'pointer' }}
            onClick={onClose}
          />
        </Box>
      </Box>
      <Box>
        {header}
        <Box className={styles.searchContainer}>
          <SearchInput
            onChange={setTeamMemberFilter}
            placeholder={translate('DONOR_CARD_ACTION_ASSIGN_SEARCH')}
            value={teamMemberFilter}
          />
        </Box>
        <Box className={styles.listContainer}>
          <Visible
            when={!isTeamMembersLoading}
            fallback={
              <Box style={{ marginTop: '16px', height: '70px' }}>
                <Loader />
              </Box>
            }
          >
            {renderTeamMembers()}
          </Visible>
        </Box>
      </Box>
      <Box className={styles.buttonContainer}>
        <Visible
          when={!isUpdating}
          fallback={<Loader isCentered size="24px" />}
        >
          <CustomButton
            dataTestId="constituent-card-item-actions-assign-close"
            buttonType="link"
            color="primary"
            label={translate('DONOR_CARD_ACTION_CANCEL')}
            onClick={onClose}
          />
          <Gap />
          <CustomButton
            buttonType="primary"
            label={translate('DONOR_CARD_ACTION_ASSIGN_SUBMIT')}
            onClick={handleSubmit}
          />
        </Visible>
      </Box>
    </Box>
  );
};

ConstituentCardItemActionAssign.propTypes = {
  constituent: ConstituentPropType,
  header: PropTypes.node,
  onClose: PropTypes.func,
};

ConstituentCardItemActionAssign.defaultProps = {
  constituent: {},
  header: null,
  onClose: EMPTY_FUNC,
};

export default ConstituentCardItemActionAssign;
