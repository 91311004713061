import { createApiRequest } from 'lib/utils';

const fetchDonation = (donationId) => {
  // eslint-disable-next-line no-console
  console.warn('fetchDonation to be implemented', donationId);

  return {};
};

const createDonation = (data) =>
  createApiRequest({
    data,
    method: 'post',
    path: 'donations',
    throwErrors: true,
  });

const deleteDonation = (donationId) =>
  createApiRequest({
    method: 'delete',
    path: `donations/${donationId}`,
  });

export const donationsService = {
  fetchDonation,
  createDonation,
  deleteDonation,
};
