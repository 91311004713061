import React from 'react';
import { bool, func } from 'prop-types';
import { useForm } from 'react-hook-form';
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
} from '@material-ui/core';
import styled from 'styled-components';
import {
  CustomButton,
  Gap,
  Visible,
  EditEmployeeProfilePersonalInfo,
  EditEmployeeChangePassword,
  Loader,
} from 'components';
import { EMPTY_FUNC } from 'lib/constants';
import { CloseIcon } from 'assets/images';
import { isMobileView } from 'lib/utils';
import { translate } from 'lib/intl';
import { useEmployeeEditForm } from 'lib/hooks';
import { colors } from 'styles/theme';

const StyledDialog = styled(Dialog)`
  ${({ mobile }) =>
    mobile
      ? `
  .MuiDialog-paperFullWidth {
    border-radius: 8px;
    height: 100%;
    margin: 6px;
    max-height: calc(100vh - 12px);
    width: 100%;
  }
  `
      : ''}
`;

const Header = styled(Box)`
  border-bottom: 1px solid ${colors.lightGrey};

  h2 {
    font-size: 20px;
    font-weight: 500;
  }
`;

const StyledDialogContent = styled(DialogContent)`
  overflow-y: auto;
  padding: 0px;
`;

const StyledBottomContent = styled(Box)`
  border-top: 1px solid ${colors.progressLightGrey};
  height: 104px;
`;

const StyledCloseButton = styled(Box)`
  cursor: pointer;
  position: absolute;
  top: 22px;
  right: 16px;
`;

const EditProfileModal = ({ open, handleClose }) => {
  const isMobile = isMobileView();
  const title = translate('EDIT_PROFILE');

  const {
    formData,
    setFormData,
    errors,
    setErrors,
    showErrors,
    setShowErrors,
    saveEmployeeInfo,
    validateEmployeeEditForm,
    isUpdating,
    updateError,
  } = useEmployeeEditForm({
    onSuccessfulUpdate: handleClose,
  });

  const {
    register,
    errors: passwordErrors,
    trigger,
    getValues,
    setError,
  } = useForm();

  const handleSetErrors = (errorsToSet) => {
    setErrors(errorsToSet);
    setShowErrors(errorsToSet.length > 0);
  };

  const handleSave = (saveData, validateErrors) => {
    // handleSetErrors(validateEmployeeEditForm(saveData, validateErrors));
    const infoErrors = validateEmployeeEditForm(saveData, validateErrors);

    // Trigger errors on password form component
    trigger().then(() => {
      // Check for any existing errors before submitting
      if (
        !passwordErrors.originalPassword &&
        !passwordErrors.password &&
        infoErrors.length === 0
      ) {
        // Extract old and new password values for submission
        const { originalPassword, password } = getValues();

        if (validateErrors.length === 0) {
          saveEmployeeInfo(originalPassword, password);
        }
      }
    });
  };

  const updateStateData = (data) => {
    setFormData(data);
    handleSetErrors([]);
  };

  return (
    <StyledDialog
      aria-labelledby="edit-donor-dialog"
      fullWidth
      maxWidth="md"
      mobile={Number(isMobile)}
      onClose={handleClose}
      open={open}
    >
      <Header
        alignItems="center"
        display="flex"
        justifyContent="space-between"
        pr={3}
      >
        <DialogTitle id="edit-profile-dialog-title">{title}</DialogTitle>
        <Visible when={isMobile}>
          <StyledCloseButton
            alignItems="center"
            display="flex"
            onClick={handleClose}
          >
            <CloseIcon />
          </StyledCloseButton>
        </Visible>
      </Header>
      <StyledDialogContent mobile={Number(isMobile)}>
        <EditEmployeeProfilePersonalInfo
          data={formData}
          errors={errors}
          setErrors={handleSetErrors}
          showErrors={showErrors}
          updateData={updateStateData}
        />
        <EditEmployeeChangePassword
          register={register}
          errors={passwordErrors}
          trigger={trigger}
          setError={setError}
        />
      </StyledDialogContent>
      <StyledBottomContent
        display="flex"
        alignItems="center"
        justifyContent="center"
        px={2}
        py={3}
      >
        <Visible when={Boolean(errors?.length && showErrors)}>
          <Box px={1}>
            <Typography color="error">
              {translate('EMPLOYEE_EDIT_FORM_ERRORS_GENERAL')}
            </Typography>
          </Box>
        </Visible>
        <Visible when={Boolean(updateError)}>
          <Box px={1}>
            <Typography color="error">{updateError}</Typography>
          </Box>
        </Visible>
        <Visible fallback={<Loader />} when={!isUpdating}>
          <Box
            display="flex"
            flex="1"
            flexDirection="row"
            justifyContent="flex-end"
          >
            <CustomButton
              buttonType="link"
              color="primary"
              dataTestId="edit-donor-modal-cancel-button"
              label={translate('CANCEL')}
              onClick={handleClose}
            />
            <Gap />
            <CustomButton
              buttonType="primary"
              dataTestId="edit-donor-modal-save-button"
              label={translate('SAVE')}
              disabled={Boolean(errors.length)}
              onClick={() => handleSave(formData, errors)}
            />
          </Box>
        </Visible>
      </StyledBottomContent>
    </StyledDialog>
  );
};

EditProfileModal.propTypes = {
  open: bool,
  handleClose: func,
};

EditProfileModal.defaultProps = {
  open: false,
  handleClose: EMPTY_FUNC,
};

export { EditProfileModal };
