import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { format } from 'date-fns';
import { startOfYtdYear, today } from 'components/reports/ReportDateFilter';
import { REPORT_TYPE_OPTIONS } from 'lib/configs/selectOptionsConfig';
import { API_DATE_FORMAT } from 'lib/constants/common';
import { ORDERED_REPORT_MODULE_TYPES } from 'lib/constants/reports';
import usePaginationParams from 'lib/hooks/usePaginationParams';
import {
  DateType,
  SortDirection,
  SortField,
} from 'lib/types/ReportFilterPropType';
import { formatTeamMembers } from 'lib/utils/formatTeamMembers';
import { companyTeamMembersActions } from 'rdx/actions/company-team-members';
import { fetchReport } from 'rdx/actions/reportActions';
import useReportFilters from './useReportFilters';

export function useFetchReportPageData() {
  const dispatch = useDispatch();
  const { filters } = useReportFilters();
  const { page, updatePage } = usePaginationParams();

  const [donorListPages, setDonorListPages] = React.useState({});
  const [prevPage, setPrevPage] = React.useState(page);

  const defaultValues = {
    dateType: DateType.CALENDAR_YTD,
    endDate: format(today, API_DATE_FORMAT),
    reportType: REPORT_TYPE_OPTIONS[0],
    sortDirection: SortDirection.DESC,
    sortField: SortField.DONATED,
    startDate: format(startOfYtdYear, API_DATE_FORMAT),
  };

  const {
    ownerOrgId,
    teamMembers,
    areTeamMembersLoaded,
    isFetchingTeamMembers,
    reportModules,
    areReportsLoaded,
  } = useSelector((state) => ({
    ownerOrgId: state.authentication?.ownerOrgId,
    teamMembers: formatTeamMembers(state.companyTeamMembers.data?.users || []),
    areTeamMembersLoaded: state.companyTeamMembers.dataLoaded,
    isFetchingTeamMembers: state.companyTeamMembers.isFetching,
    reportModules: state.reports?.data,
    areReportsLoaded: state.reports?.dataLoaded,
  }));

  React.useEffect(() => {
    const newPages = { ...donorListPages };
    newPages[page] = reportModules?.donorList?.report;
    setDonorListPages(newPages);
  }, [reportModules]);

  React.useEffect(() => {
    if (!areTeamMembersLoaded && !isFetchingTeamMembers) {
      dispatch(companyTeamMembersActions.fetchCompanyTeamMembers(ownerOrgId));
    }
  }, [areTeamMembersLoaded, isFetchingTeamMembers]);

  const fetchReportData = React.useCallback(() => {
    const orderedReportModules =
      ORDERED_REPORT_MODULE_TYPES[filters.reportType];
    orderedReportModules.forEach((module) => {
      dispatch(
        fetchReport({
          ...filters,
          moduleType: module,
        })
      );
    });
  }, [filters]);

  React.useEffect(() => {
    if (
      page !== prevPage &&
      donorListPages[prevPage] &&
      !donorListPages[page]
    ) {
      fetchReportData();
      setPrevPage(page);
    }
  }, [page]);

  const donorListResults = React.useMemo(
    () => donorListPages[page] || [],
    [donorListPages, page]
  );

  return {
    areReportsLoaded,
    areTeamMembersLoaded,
    donorListResults,
    defaultValues,
    fetchReportData,
    page,
    reportModules,
    teamMembers,
    updatePage,
  };
}
