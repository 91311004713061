import React, { useState } from 'react';
import { Box } from '@material-ui/core';
import { bool, func, node } from 'prop-types';
import styled from 'styled-components';
import { colors } from 'styles/theme';
import { CustomLink, Gap, Loader, Visible } from 'components';
import { isMobileView, wrapWithStopPropagation } from 'lib/utils';
import { translate } from 'lib/intl';
import { LOADER_SIZES } from 'lib/constants';

const StyledCard = styled(Box)`
  cursor: pointer;
  height: 100%;
  min-height: 140px;
  position: relative;
  width: 100%;

  &:hover {
    background-color: ${colors.offWhite};
  }

  // changes font weight only for the first view field
  & > div {
    & > div.MuiBox-root:first-child span {
      font-weight: 600;
      padding-right: 100px;
    }
  }
`;

const StyledAbsoluteContainer = styled(Box)`
  position: absolute;
  right: 12px;
  top: 16px;
`;

/**
 * This is a wrapper component for donor cards
 * which adds hover state and actions such as edit and delete
 */
const ConstituentCardWrapper = ({
  loading,
  onClick,
  onDeleteClick,
  onEditClick,
  children,
  ...rest
}) => {
  const [isHovered, setIsHovered] = useState(false);

  const isMobile = isMobileView();

  return (
    <StyledCard
      data-testid="donor-card-wrapper"
      onMouseLeave={() => setIsHovered(false)}
      onMouseEnter={() => setIsHovered(true)}
      onClick={onClick}
      p={2}
      {...rest}
    >
      {children}
      <Visible when={isHovered || isMobile || loading}>
        <StyledAbsoluteContainer display="flex">
          <Visible when={!loading} fallback={<Loader size={LOADER_SIZES.SM} />}>
            <CustomLink onClick={wrapWithStopPropagation(onDeleteClick)}>
              {translate('DELETE')}
            </CustomLink>
            <Gap size={2} />
            <CustomLink onClick={wrapWithStopPropagation(onEditClick)}>
              {translate('EDIT')}
            </CustomLink>
          </Visible>
        </StyledAbsoluteContainer>
      </Visible>
    </StyledCard>
  );
};

ConstituentCardWrapper.propTypes = {
  children: node.isRequired,
  loading: bool,
  onClick: func.isRequired,
  onDeleteClick: func.isRequired,
  onEditClick: func.isRequired,
};

ConstituentCardWrapper.defaultProps = {
  loading: false,
};

export default ConstituentCardWrapper;
