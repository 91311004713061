import React from 'react';
import Box from '@material-ui/core/Box';
import propTypes from 'prop-types';
import { DONOR_SECTION_TYPES } from 'lib/constants';
import { CONSTITUENT_TYPES } from 'lib/constants/constituentConstants';
import { ConstituentType, ConstituentTypes } from './constituentTypes';
import ConstituentInformationSection from './ConstituentInformationSection';
import ConstituentSummary from './ConstituentSummary';
import ConstituentConnectionSection from './ConstituentConnectionSection';
import ConstituentWorkAndContactsSection from './ConstituentWorkAndContactsSection';

const {
  EMPLOYEES,
  CONNECTIONS,
  HOUSEHOLD,
  AFFILIATIONS,
  CONTACT_INFORMATION,
  WORK_INFORMATION,
  PERSONAL_INFORMATION,
  COMPANY_INFORMATION,
} = DONOR_SECTION_TYPES;

/**
 * This is a component for rendering
 * Info tab content of the Constituent Page
 */
const ConstituentInfoTab = ({
  constituent,
  constituentType,
  isEditable,
  isNewConstituent,
}) => {
  const isPersonType = constituentType === CONSTITUENT_TYPES.PERSON;
  const isOrgType = constituentType === CONSTITUENT_TYPES.ORG;

  if (isPersonType) {
    return (
      <Box>
        <ConstituentInformationSection
          data={constituent}
          constituentType={constituentType}
          isEditable={isEditable}
          isNewConstituent={isNewConstituent}
          type={PERSONAL_INFORMATION}
        />
        {!isNewConstituent && (
          <>
            <ConstituentWorkAndContactsSection
              data={constituent?.work}
              isEditable={isEditable}
              type={WORK_INFORMATION}
            />
            <ConstituentConnectionSection
              data={constituent}
              isEditable={isEditable}
              type={HOUSEHOLD}
            />
            <ConstituentConnectionSection
              data={constituent}
              isEditable={isEditable}
              type={AFFILIATIONS}
            />
            <ConstituentConnectionSection
              data={constituent}
              isEditable={isEditable}
              type={CONNECTIONS}
            />
            <ConstituentSummary data={constituent} />
          </>
        )}
      </Box>
    );
  }

  if (isOrgType) {
    return (
      <Box>
        <ConstituentInformationSection
          data={constituent}
          constituentType={constituentType}
          isEditable={isEditable}
          isNewConstituent={isNewConstituent}
          type={COMPANY_INFORMATION}
        />
        {!isNewConstituent && (
          <>
            <ConstituentWorkAndContactsSection
              data={constituent?.contacts}
              isEditable={isEditable}
              type={CONTACT_INFORMATION}
            />
            <ConstituentConnectionSection
              data={constituent}
              isEditable={isEditable}
              type={EMPLOYEES}
            />
            <ConstituentConnectionSection
              data={constituent}
              isEditable={isEditable}
              type={AFFILIATIONS}
            />
            <ConstituentConnectionSection
              data={constituent}
              isEditable={isEditable}
              type={CONNECTIONS}
            />
            <ConstituentSummary data={constituent} />
          </>
        )}
      </Box>
    );
  }

  return null;
};

ConstituentInfoTab.propTypes = {
  constituent: ConstituentType.isRequired,
  constituentType: ConstituentTypes.isRequired,
  isEditable: propTypes.bool.isRequired,
  isNewConstituent: propTypes.bool.isRequired,
};

export default ConstituentInfoTab;
