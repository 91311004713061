import { FEATURE_FLAGS } from 'lib/constants/localStorageKeys';
import { ALL_FLAGS_ARRAY } from 'lib/constants/featureFlags';

function turnOnFeature(flagName) {
  const flagValue = localStorage.getItem(`${FEATURE_FLAGS}_${flagName}`);
  const value =
    flagValue === 'true' || flagValue === 'false'
      ? JSON.parse(flagValue)
      : false;
  return value;
}

function parseFeatureFlags() {
  const features = {};
  ALL_FLAGS_ARRAY.forEach((flag) => {
    if (localStorage.getItem(`${FEATURE_FLAGS}_${flag}`)) {
      const value = turnOnFeature(flag);
      features[flag] = value;
    }
  });

  return features;
}

export { parseFeatureFlags, turnOnFeature };
