import { useSelector } from 'react-redux';
import data from './data/integrations';

const useIntegrationsPage = () => {
  const { integrations } = data;
  const { classyIntegration } = useSelector((state) => ({
    classyIntegration: state.orgInfo.data.classy,
  }));

  const orgIntegrations = [];

  const hasClassyCredentials = classyIntegration && classyIntegration.clientId;
  if (hasClassyCredentials) {
    orgIntegrations.push({ id: 'classy', ...classyIntegration });
  }

  const orgHasIntegrations = orgIntegrations.length > 0;

  let remainingIntegrations = integrations;
  const connectedIntegrations = [];
  if (orgHasIntegrations) {
    remainingIntegrations = [];
    integrations.forEach((integ) => {
      if (orgIntegrations.find((i) => i.id === integ.id)) {
        connectedIntegrations.push(integ);
      } else {
        remainingIntegrations.push(integ);
      }
    });
  }

  return {
    orgHasIntegrations,
    connectedIntegrations,
    remainingIntegrations,
  };
};

export { useIntegrationsPage };
