import userActionTypes from 'rdx/actionTypes';

const {
  INVITE_FORM_UPDATE_GROUPS,
  INVITE_FORM_UPDATE_EMAILS,
  INVITE_FORM_SET_ERROR,
  INVITE_FORM_RESET_ALL,
  SENDING_INVITES_SUCCESS,
  SENDING_INVITES_FAILURE,
} = userActionTypes;

const initialState = {
  groups: [],
  emails: [],
  error: null,
};

export function inviteForm(state = initialState, action) {
  switch (action.type) {
    case INVITE_FORM_UPDATE_GROUPS:
      return {
        ...state,
        groups: action.groups,
        error: null,
      };
    case INVITE_FORM_UPDATE_EMAILS:
      return {
        ...state,
        emails: action.emails,
        error: null,
      };
    case INVITE_FORM_SET_ERROR:
    case SENDING_INVITES_FAILURE:
      return {
        ...state,
        error: action.error,
      };
    case SENDING_INVITES_SUCCESS:
      return initialState;
    case INVITE_FORM_RESET_ALL:
      return initialState;
    default:
      return state;
  }
}
