import {
  API_DATE_FORMAT,
  CAMPAIGN_MAIN_TYPE,
  DEFAULT_CURRENCY,
  CAMPAIGN_GOAL_TYPE_ENUM,
} from 'lib/constants';
import { formatDateObject } from 'lib/utils/dates';
import { replaceEmptyValues } from 'lib/utils/replaceEmptyValues';
import { CAMPAIGN_EVENT_TYPES } from 'lib/configs/campaigns';

const formatCampaignBeforeSave = (formData, ownerOrgId) => {
  const currentDate = formatDateObject(new Date(), API_DATE_FORMAT);

  const {
    name,
    campaignType,
    displayName,
    description,
    url,
    category,
    goalType,
    goalAmount,
    stretchAmount,
    startDate,
    endDate,
    location,
    date,
    ticketPrice,
    ticketsSold,
    itemType,
    itemQuantity,
    itemValue,
    assignees,
    primaryAssigneeId,
  } = formData;

  const isEventType = CAMPAIGN_EVENT_TYPES.includes(category);
  const isInKindGoalType = goalType === CAMPAIGN_GOAL_TYPE_ENUM.IN_KIND;

  const data = {
    assignees: assignees?.map((assignee) => ({
      displayName: assignee.displayName,
      primary: assignee.value === primaryAssigneeId,
      userId: assignee.value,
    })),
    description,
    displayName: displayName || name,
    campaignType: campaignType || CAMPAIGN_MAIN_TYPE,
    category,
    goals: [
      {
        goalType,
        goalAmount,
        stretchAmount,
        currency: DEFAULT_CURRENCY,
        ...(isInKindGoalType && {
          itemType,
          itemQuantity,
          itemValue,
        }),
      },
    ],
    endDate: (isEventType ? date : endDate) || currentDate,
    name,
    ownerOrgId,
    startDate: (isEventType ? date : startDate) || currentDate,
    url,
  };

  if (isEventType) {
    data.events = [
      {
        location,
        date: date || currentDate,
        ticketPrice,
        ticketsSold,
      },
    ];
  }

  return replaceEmptyValues(data);
};

export { formatCampaignBeforeSave };
