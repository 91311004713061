import React from 'react';
import PropTypes from 'prop-types';
import { AuthContainer, ResetPasswordSubmitForm } from 'components';

const ResetPasswordSetPasswordPage = ({ location }) => (
  <AuthContainer size="sm">
    <ResetPasswordSubmitForm location={location} />
  </AuthContainer>
);

ResetPasswordSetPasswordPage.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
};

export { ResetPasswordSetPasswordPage };
