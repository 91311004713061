import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import Box from '@material-ui/core/Box';
import Dialog from '@material-ui/core/Dialog';
import Fab from '@material-ui/core/Fab';
import Typography from '@material-ui/core/Typography';
import { Close } from '@material-ui/icons';
import userActionTypes from 'rdx/actionTypes';
import { feedActions } from 'rdx/actions';
import { NewDonationFormWithHeader, InteractionForm } from 'components';
import { AddNewConstituentModalContent } from 'components/constituents';
import NewThemeProvider from 'components/common/NewThemeProvider';
import { CustomDialogContent } from 'components/common/CustomDialog';
import { EMPTY_FUNC } from 'lib/constants';
import { translate } from 'lib/intl';
import { interactionIcon, giftIcon, personIcon } from 'assets/images';
import { MULTI_ACTION_DIALOG_KEYS } from 'lib/constants/common';
import { useStyles } from './MultiActionDialog.styles';

const MultiActionDialog = ({ open, handleClose, onEntityCreated }) => {
  const classes = useStyles();

  const { actionDialog } = useSelector((state) => ({
    actionDialog: state.actionDialog,
  }));
  const dispatch = useDispatch();

  const handleActionClick = (dialogKey) => {
    // Close the primary modal
    handleClose();
    dispatch({ type: userActionTypes.OPEN_ACTION_DIALOG, dialogKey });
  };

  const onActionDialogClose = () => {
    dispatch({ type: userActionTypes.CLOSE_ACTION_DIALOG });
  };

  const onActionDialogSuccess = () => {
    onActionDialogClose();
    onEntityCreated();
  };

  const ActionDialogContent = React.useMemo(() => {
    switch (actionDialog.dialogKey) {
      case MULTI_ACTION_DIALOG_KEYS.ADD_CONSTITUENT:
        return (
          <AddNewConstituentModalContent handleClose={onActionDialogClose} />
        );
      case MULTI_ACTION_DIALOG_KEYS.INTERACTION:
        return (
          <InteractionForm
            data={actionDialog.data}
            onClose={onActionDialogClose}
            onSuccess={onActionDialogSuccess}
          />
        );
      case MULTI_ACTION_DIALOG_KEYS.DONATION:
        return (
          <NewDonationFormWithHeader
            onClose={onActionDialogClose}
            onSuccess={onActionDialogSuccess}
          />
        );
      case MULTI_ACTION_DIALOG_KEYS.DELETE_FEED_ITEM_CONFIRMATION:
        return (
          <NewThemeProvider>
            <CustomDialogContent
              actionButtonLabel={translate('DELETE')}
              dialogHeader={actionDialog.config.title}
              handleActionButton={() => {
                onActionDialogClose();
                dispatch(
                  feedActions.deleteFeedEntity(
                    actionDialog.config.entityType,
                    actionDialog.data.contentId
                  )
                );
              }}
              onClose={onActionDialogClose}
            >
              <Box my={{ xs: 0, sm: 1 }}>
                <Typography>{actionDialog.config.label}</Typography>
              </Box>
            </CustomDialogContent>
          </NewThemeProvider>
        );
      default:
        return null;
    }
  }, [actionDialog.dialogKey]);

  return (
    <>
      <Dialog
        className={classes.mainDialog}
        data-testid="multi-action-dialog"
        onClose={handleClose}
        open={open && !actionDialog.open}
      >
        <Box className={classes.container}>
          <Box className={classes.item}>
            <Typography
              className={classes.itemTitle}
              variant="subtitle1"
              onClick={() =>
                handleActionClick(MULTI_ACTION_DIALOG_KEYS.ADD_CONSTITUENT)
              }
            >
              {translate('ADD_CONSTITUENT')}
            </Typography>
            <Fab
              size="small"
              className={classes.buttonSmall}
              onClick={() =>
                handleActionClick(MULTI_ACTION_DIALOG_KEYS.ADD_CONSTITUENT)
              }
            >
              <img
                alt="add-donor-action"
                className={classes.icon}
                data-testid="add-donor-action"
                src={personIcon}
              />
            </Fab>
          </Box>
          <Box className={classes.item}>
            <Typography
              className={classes.itemTitle}
              variant="subtitle1"
              onClick={() =>
                handleActionClick(MULTI_ACTION_DIALOG_KEYS.DONATION)
              }
            >
              {translate('ADD_DONATION')}
            </Typography>
            <Fab
              size="small"
              className={classes.buttonSmall}
              onClick={() =>
                handleActionClick(MULTI_ACTION_DIALOG_KEYS.DONATION)
              }
            >
              <img
                alt="add-donation-action"
                className={classes.icon}
                data-testid="add-donation-action"
                src={giftIcon}
              />
            </Fab>
          </Box>
          <Box className={classes.item}>
            <Typography
              className={classes.itemTitle}
              variant="subtitle1"
              onClick={() =>
                handleActionClick(MULTI_ACTION_DIALOG_KEYS.INTERACTION)
              }
            >
              {translate('ADD_INTERACTION')}
            </Typography>
            <Fab
              size="small"
              className={classes.buttonSmall}
              onClick={() =>
                handleActionClick(MULTI_ACTION_DIALOG_KEYS.INTERACTION)
              }
            >
              <img
                alt="add-interaction-action"
                className={classes.icon}
                data-testid="add-interaction-action"
                src={interactionIcon}
              />
            </Fab>
          </Box>
          <Box className={classes.item}>
            <Typography
              className={classes.itemTitle}
              data-testid="close-multi-action-dialog"
              variant="subtitle1"
              style={{ paddingRight: '12px' }}
              onClick={handleClose}
            >
              {translate('MULTI_ACTION_CLOSE')}
            </Typography>
            <Fab className={classes.button} onClick={handleClose}>
              <Close color="primary" />
            </Fab>
          </Box>
        </Box>
      </Dialog>
      <Dialog
        className={classes.contentDialog}
        fullWidth
        maxWidth="md"
        open={actionDialog.open}
        onClose={onActionDialogClose}
      >
        {ActionDialogContent}
      </Dialog>
    </>
  );
};

MultiActionDialog.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  onEntityCreated: PropTypes.func,
};

MultiActionDialog.defaultProps = {
  open: false,
  handleClose: EMPTY_FUNC,
  onEntityCreated: EMPTY_FUNC,
};

export { MultiActionDialog };
