import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { generatePath, useHistory } from 'react-router-dom';
import { AUTH_ROUTES } from 'lib/constants';
import { focusFormField } from 'lib/utils';
import { uniq } from 'rambdax';
import constituentFormPopupActions from 'rdx/actions/constituentFormPopupActions';
import constituentActions from 'rdx/actions/constituentActions';
import orgInfoActions from 'rdx/actions/org-info';
import { CONSTITUENT_FORMS_CONFIG } from './constituentFormConfigs';

const HIDING_FORM_TIMEOUT = 500;

const scrollToSection = (sectionName) =>
  sectionName &&
  document
    .getElementById(sectionName)
    ?.scrollIntoView({ behavior: 'smooth', block: 'start' });

/**
  A hook responsible for
  - managing form states
  - managing form errors states
  - updating/removing/creating conenctions
  for conenctions like section of the Donor Page
 */
const useConstituentForm = ({
  type,
  constituentType,
  isNewConstituent = false,
}) => {
  const formConfig = CONSTITUENT_FORMS_CONFIG[type];

  const {
    constituentFormPopup,
    ownerOrgId,
    constituentId,
    orgLabels,
    removingDonorInfo,
    updatingConstituent,
  } = useSelector((state) => ({
    constituentFormPopup: state.constituentFormPopup,
    ownerOrgId: state.authentication?.ownerOrgId,
    constituentId: state.constituents?.data?.constituent?.constituentId,
    orgLabels: state.orgInfo?.data?.labels || [],
    removingDonorInfo: state.removingDonorInfo,
    updatingConstituent: state.updatingConstituent,
  }));

  const dispatch = useDispatch();
  const history = useHistory();

  const {
    formName,
    // editing otherwise
    isCreating,
    fieldToFocus,
    // some other data as ids
    data: constituentFormPopupData,
  } = constituentFormPopup;

  const {
    apiType,
    idField = 'userId',
    defaultData,
    config: sectionGeneratorConfig,
    showMoreConfig: sectionGeneratorShowMoreConfig,
    mapFormData,
  } = formConfig;

  const [formData, setFormData] = useState(defaultData);
  const [formErrors, setErrors] = useState([]);
  const [showErrors, setShowErrors] = useState(false);
  const [showMoreSection, setShowMoreSection] = useState(false);

  useEffect(() => {
    setFormData(isCreating ? defaultData : constituentFormPopupData);
  }, [constituentFormPopup]);

  const openForm = useCallback(
    ({
      type: formType,
      isCreating: isCreatingForm = true,
      fieldToFocus: formFieldToFocus = '',
      data: otherFormData = null,
    }) => {
      dispatch(
        constituentFormPopupActions.openFormPopup({
          formName: formType,
          isCreating: isCreatingForm,
          fieldToFocus: formFieldToFocus,
          data: otherFormData,
        })
      );
    },
    []
  );

  const closeForm = useCallback(
    (redirectToDonors = true) => {
      if (!isNewConstituent) {
        scrollToSection(formName);
        dispatch(constituentFormPopupActions.closeFormPopup());
      } else if (redirectToDonors) {
        history.push(generatePath(AUTH_ROUTES.DONORS));
      }
    },
    [isNewConstituent, formName]
  );

  useEffect(() => {
    if (updatingConstituent.dataLoaded) {
      closeForm(false);
    }
  }, [updatingConstituent.dataLoaded]);

  // form is open when formName is available or when it's creating a new donor flow
  const isPopupOpen = useMemo(
    () => type === formName || isNewConstituent,
    [formName, isNewConstituent]
  );

  const [showForm, setShowForm] = useState(isPopupOpen);

  const toggleShowingForm = (isOpen) => {
    if (isOpen) {
      setShowForm(isOpen);
      scrollToSection(formName);
    } else {
      setTimeout(() => {
        setShowForm(isOpen);
        setErrors([]);
        setShowErrors(false);
        setShowMoreSection(false);
      }, HIDING_FORM_TIMEOUT);
    }
  };

  useEffect(() => {
    toggleShowingForm(isPopupOpen);
  }, [isPopupOpen]);

  const onConstituentCreation = (newConstituent) => {
    history.push(
      generatePath(AUTH_ROUTES.CONSTITUENT, {
        constituentId: newConstituent.constituentId,
      })
    );
  };

  const handleSaveNewConstituent = useCallback(() => {
    const action =
      constituentActions[apiType ? 'createConnection' : 'createConstituent'];

    dispatch(
      action({
        apiType,
        constituentId,
        data: mapFormData(formData, { constituentType }),
        idField,
        onSuccess: onConstituentCreation,
      })
    );
  }, [apiType, constituentId, constituentType, formData, idField]);

  const handleUpdateConstituent = useCallback(() => {
    const action =
      constituentActions[apiType ? 'updateConnection' : 'updateConstituent'];

    dispatch(
      action({
        apiType,
        constituentId,
        idField,
        data: mapFormData(formData, { ownerOrgId, constituentType }),
      })
    );
  }, [apiType, constituentId, constituentType, formData, idField, ownerOrgId]);

  const onSave = useCallback(() => {
    if (formErrors?.length) {
      setShowErrors(true);
    } else if (isCreating) {
      handleSaveNewConstituent();
    } else {
      handleUpdateConstituent();
    }
    // Save any newly created labels to the org.
    const { labels = [] } = formData;
    if (labels.some((label) => !orgLabels.includes(label))) {
      dispatch(
        orgInfoActions.updateOrgInfo(ownerOrgId, {
          labels: uniq([...orgLabels, ...labels]).sort(),
        })
      );
    }
  }, [
    isCreating,
    constituentId,
    constituentType,
    ownerOrgId,
    formConfig,
    formData,
    formErrors,
    orgLabels,
  ]);

  const onRemove = useCallback(
    (connectionData) => {
      dispatch(
        constituentActions.removeConnection({
          constituentId,
          apiType,
          idField,
          data: connectionData,
        })
      );
    },
    [constituentId, formConfig]
  );

  const onPrimaryChange = useCallback(
    (primary) => setFormData({ ...formData, primary }),
    [formData]
  );

  useEffect(() => {
    if (fieldToFocus) {
      if (
        sectionGeneratorShowMoreConfig?.find(({ key }) => key === fieldToFocus)
      ) {
        setShowMoreSection(true);
      }

      focusFormField(fieldToFocus);
    }
  }, [fieldToFocus]);

  return {
    isPopupOpen,
    showForm,
    openForm,
    closeForm,
    fieldToFocus,
    handleSaveNewConstituent,
    idField,
    onSave,
    onRemove,
    onPrimaryChange,
    sectionGeneratorConfig,
    sectionGeneratorShowMoreConfig,
    formData,
    formErrors,
    removingDonorInfo,
    setErrors,
    showErrors,
    setShowErrors,
    setFormData,
    updatingConstituent,
    showMoreSection,
    setShowMoreSection,
  };
};

export default useConstituentForm;
