import { translate } from 'lib/intl';

const CSV_LINK_HEADERS = [
  { label: translate('CAMPAIGN_NAME_LABEL'), key: 'name' },
  { label: translate('CAMPAIGN_CATEGORY_LABEL'), key: 'category' },
  { label: translate('CAMPAIGN_DESCRIPTION_LABEL'), key: 'description' },
  { label: translate('CAMPAIGN_WEBSITE_LABEL'), key: 'url' },
  { label: translate('CAMPAIGN_START_DATE_LABEL'), key: 'startDate' },
  { label: translate('CAMPAIGN_END_DATE_LABEL'), key: 'endDate' },
  { label: translate('CAMPAIGN_GOAL_AMOUNT_LABEL'), key: 'goalAmount' },
  {
    label: translate('CAMPAIGN_TOTAL_AMOUNT_DONATED_LABEL'),
    key: 'totalAmount',
  },
  {
    label: translate('CAMPAIGNS_TOTAL_DONORS_LABEL'),
    key: 'numDonorConstituents',
  },
  { label: translate('CAMPAIGN_LARGEST_DONATION_LABEL'), key: 'maxDonation' },
  {
    label: translate('CAMPAIGN_RETURN_DONORS_LABEL'),
    key: 'numReturningDonors',
  },
  { label: translate('CAMPAIGN_NEW_DONORS_LABEL'), key: 'numNewDonors' },
];

export { CSV_LINK_HEADERS };
