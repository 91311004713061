import React from 'react';
import Report from 'reports/DonationsOverTimeReport';
import { formatDateForReport } from 'reports/donationReportUtils';
import { DonationsOverTimePropType } from 'lib/types/ReportModulesPropType';
import { REPORTS_MONTHLY_TIME_PERIOD } from 'lib/constants';
import { translate } from 'lib/intl';
import { formatCurrency } from 'lib/utils';
import { D3ChartTooltipContext } from './D3ChartTooltipProvider';
import D3ChartTooltip from './D3ChartTooltip';

const CHART_ID = 'donations-over-time-report';

export default function DonationsOverTimeReportContainer({ donations }) {
  const { handleMouseover, handleMouseleave } = React.useContext(
    D3ChartTooltipContext
  );
  const containerRef = React.useRef();
  const reportRef = React.useRef();

  const [tooltipMonth, setTooltipMonth] = React.useState('');
  const [tooltipValue, setTooltipValue] = React.useState('');

  // The usage of the anchor ref in the mouse events is to provide an "anchor"
  // for popper.js. These mouseover events get passed to the chart <rect>
  // elements. On mouseover, we update the ref, which tells the <Popper>
  // component (which tells popper.js) "use this element to anchor the tooltip
  // with". On mouseout, we update the anchor ref to be the document, causing
  // the tooltip to not be anchored to any specific DOM element.
  function handleBarMouseover(event, d) {
    setTooltipMonth(
      formatDateForReport(REPORTS_MONTHLY_TIME_PERIOD)(d.timeUnitStart)
    );
    setTooltipValue(formatCurrency(d.totalValue, true));
    handleMouseover(event);
  }

  function handleBarMouseleave(event) {
    handleMouseleave(event);
  }

  React.useEffect(() => {
    /* eslint-disable-next-line no-unused-expressions */
    reportRef.current?.update(donations);
  }, [donations]);

  React.useEffect(() => {
    reportRef.current = new Report({
      id: CHART_ID,
      onBarMouseover: handleBarMouseover,
      onBarMouseleave: handleBarMouseleave,
    });

    reportRef.current.create(donations);

    return () => {
      reportRef.current.destroy();
    };
  }, []);

  return (
    <div>
      <h2 className="text-xl mb-3 xs:mb-4">
        {translate('REPORTS_DONATIONS_OVER_TIME')}
      </h2>

      <D3ChartTooltip>
        <div className="text-center text-text-secondary text-sm leading-5 uppercase">
          {tooltipMonth}
        </div>
        <div className="text-center text-text-primary text-base leading-5">
          {tooltipValue}
        </div>
      </D3ChartTooltip>

      <div
        className="chart-container mb-10 sm:h-80 h-64"
        ref={containerRef}
        id={CHART_ID}
      />
    </div>
  );
}

DonationsOverTimeReportContainer.propTypes = {
  donations: DonationsOverTimePropType.isRequired,
};
