import React from 'react';
import { func, string } from 'prop-types';
import styled from 'styled-components';
import { Box, Typography } from '@material-ui/core';
import { colors } from 'styles/theme';

const StyledLinkLikeButton = styled(Box)`
  border-bottom: 1px solid ${colors.primaryGreen};
  cursor: pointer;
  padding-bottom: 2px;

  .MuiTypography-root {
    font-weight: 500;
    font-size: ${({ fontSize }) => fontSize};
  }

  &: hover {
    .MuiTypography-root {
      color: ${colors.mediumGreen};
    }
  }
`;

const LinkLikeButton = ({ children, fontSize, onClick }) => (
  <StyledLinkLikeButton
    data-testid="link-like-button"
    fontSize={fontSize}
    onClick={onClick}
  >
    <Typography variant="body1">{children}</Typography>
  </StyledLinkLikeButton>
);

LinkLikeButton.propTypes = {
  onClick: func.isRequired,
  children: string.isRequired,
  fontSize: string,
};

LinkLikeButton.defaultProps = {
  fontSize: '16px',
};

export { LinkLikeButton };
