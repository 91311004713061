import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Link from '@material-ui/core/Link';
import Radio from '@material-ui/core/Radio';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/core/styles/makeStyles';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import CustomTable from 'components/common/CustomTable2';
import DeleteFeatureFlagDialog from 'components/featureFlagManagement/DeleteFeatureFlagDialog';
import DisableFeatureFlagDialog from 'components/featureFlagManagement/DisableFeatureFlagDialog';
import EnableFeatureFlagDialog from 'components/featureFlagManagement/EnableFeatureFlagDialog';
import { translate } from 'lib/intl';
import { isMobileView } from 'lib/utils';

const useStyles = makeStyles(() => ({
  buttonRoot: {
    padding: 0,
    margin: 0,
    minWidth: 0,
  },
  buttonLabel: {
    padding: 0,
    margin: 0,
  },
}));

const DeleteButton = ({ flagName, onApiResponse }) => {
  const classes = useStyles();
  const [openDeleteFF, setOpenDeleteFF] = React.useState(false);
  const handleClose = () => {
    setOpenDeleteFF(false);
  };
  const handleOpenCreateFF = () => {
    setOpenDeleteFF(true);
  };
  return (
    <>
      <Button
        classes={{ root: classes.buttonRoot, label: classes.buttonLabel }}
        onClick={() => {
          handleOpenCreateFF();
        }}
        color="inherit"
      >
        <HighlightOffIcon fontSize="large" color="error" />
      </Button>
      <DeleteFeatureFlagDialog
        open={openDeleteFF}
        flagName={flagName}
        handleClose={handleClose}
        setOpen={setOpenDeleteFF}
        onApiResponse={onApiResponse}
      />
    </>
  );
};
DeleteButton.propTypes = {
  flagName: PropTypes.string.isRequired,
  onApiResponse: PropTypes.func.isRequired,
};

export default function FeatureFlagListTable({ items, onApiResponse }) {
  const COLUMNS_CONFIG = [
    {
      id: 'feature-flag-management-name',
      headerName: translate('FEATURE_FLAGS_TABLE_HEADER_NAME'),
      sortable: false,
      renderCell(props) {
        const isMobile = isMobileView();
        // eslint-disable-next-line react/prop-types
        const { flagName } = props?.data;
        return (
          <Box display="flex" justifyContent="space-between">
            <Typography>{flagName}</Typography>
            {isMobile ? (
              <DeleteButton flagName={flagName} onApiResponse={onApiResponse} />
            ) : null}
          </Box>
        );
      },
      style: {
        width: '50%',
      },
    },
    {
      id: 'feature-flag-management-status',
      headerName: translate('FEATURE_FLAGS_TABLE_HEADER_STATUS'),
      sortable: false,
      renderCell(props) {
        const isMobile = isMobileView();
        const {
          flagName,
          flagScope,
          flagValue,
          orgIds,
          // eslint-disable-next-line react/prop-types
        } = props?.data;
        const [showViewEditLink, setShowViewEditLink] = React.useState(false);
        const [openDisableFF, setOpenDisableFF] = React.useState(false);
        const [openEnableFF, setOpenEnableFF] = React.useState(false);

        useEffect(() => {
          if (flagValue !== false) {
            setShowViewEditLink(true);
          } else {
            setShowViewEditLink(false);
          }
        }, [flagValue]);

        const handleClose = () => {
          setOpenDisableFF(false);
          setOpenEnableFF(false);
        };
        const handleChange = (event) => {
          switch (event.target.value) {
            case 'off':
              setOpenDisableFF(true);
              break;
            case 'on':
            default:
              setOpenEnableFF(true);
              break;
          }
        };

        return (
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Box display="flex" flexDirection={isMobile ? 'column' : 'row'}>
              <FormControlLabel
                value="off"
                control={<Radio onChange={handleChange} checked={!flagValue} />}
                label="Off"
              />
              <Box display="flex">
                <FormControlLabel
                  value="on"
                  control={
                    <Radio onChange={handleChange} checked={flagValue} />
                  }
                  label="On"
                />
                {showViewEditLink ? (
                  <Box display="flex" alignItems="center">
                    &mdash;
                    <Box pl={2}>
                      <Link onClick={handleChange}>{`${translate(
                        'FEATURE_FLAGS_VIEW_EDIT_LIST'
                      )} (${
                        flagScope === 'global'
                          ? translate('ALL')
                          : orgIds?.length
                      })`}</Link>
                    </Box>
                  </Box>
                ) : null}
              </Box>
            </Box>
            {!isMobile ? (
              <DeleteButton flagName={flagName} onApiResponse={onApiResponse} />
            ) : null}
            <DisableFeatureFlagDialog
              open={openDisableFF}
              flagName={flagName}
              orgIds={orgIds}
              handleClose={handleClose}
              setOpen={setOpenDisableFF}
              onApiResponse={onApiResponse}
            />
            <EnableFeatureFlagDialog
              open={openEnableFF}
              flagName={flagName}
              flagScope={flagScope}
              orgIds={orgIds}
              handleClose={handleClose}
              setOpen={setOpenEnableFF}
              onApiResponse={onApiResponse}
            />
          </Box>
        );
      },
      style: {
        width: '50%',
      },
    },
  ];
  return (
    <CustomTable
      columns={COLUMNS_CONFIG}
      data={items}
      idField="feature-flag-management-list"
      key={items.length}
      onApiResponse={onApiResponse}
    />
  );
}

FeatureFlagListTable.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
  onApiResponse: PropTypes.func.isRequired,
};
