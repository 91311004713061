import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { bool, string, func, number } from 'prop-types';
import styled from 'styled-components';
import { Box, Chip, TextField } from '@material-ui/core';
import { KEY_RETURN, KEY_TAB, KEY_SPACE } from 'keycode-js';
import { prop } from 'rambdax';
import { Visible } from 'components';
import { EMPTY_FUNC } from 'lib/constants';
import { isValidEmail } from 'lib/utils';
import { getEmailInputWidth } from 'lib/mappers';
import { colors } from 'styles/theme';
import { DeleteIcon } from 'assets/images';

const DeleteIconComponent = <DeleteIcon data-testid="delete-email-icon" />;

const StyledEmailChip = styled(Box)`
  .MuiChip-root {
    max-width: ${prop('max')}px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .MuiChip-outlined.MuiChip-outlinedPrimary {
    background-color: ${colors.transparentGreen};
    border-color: ${colors.primaryGreen};
    color: ${colors.black};
  }

  .MuiChip-outlined.MuiChip-outlinedSecondary {
    background-color: ${colors.transparentError};
    border-color: ${colors.errorMain};
    color: ${colors.black};
  }

  .MuiChip-outlined.MuiChip-deletable .MuiChip-deleteIcon {
    height: 12px;
    margin-right: 10px;
    width: 12px;
  }
`;

const StyledTextField = styled(TextField)`
  width: ${prop('width')}px;
`;

const EmailChip = ({
  email,
  handleDelete,
  handleChange,
  handleClick,
  isEditing,
  isFocused,
  maxWidth,
}) => {
  const ref = useRef();

  const color = useMemo(
    () =>
      // eslint-disable-next-line no-nested-ternary
      isFocused ? 'primary' : isValidEmail(email) ? 'default' : 'secondary',
    [email, isFocused]
  );

  const [value, setValue] = useState(email);

  const onChange = useCallback(({ target }) => setValue(target?.value), []);

  const onBlur = useCallback(() => {
    handleChange(value);
  }, [handleChange, value]);

  const onKeyDown = useCallback(
    ({ keyCode }) => {
      if ([KEY_RETURN, KEY_SPACE, KEY_TAB].includes(keyCode)) {
        onBlur();
      }
    },
    [onBlur]
  );

  const inputWidth = useMemo(() => {
    const width = getEmailInputWidth(value);

    return width <= maxWidth ? width : maxWidth;
  }, [value, maxWidth]);

  const EmailInput = useMemo(
    () => (
      <StyledTextField
        error={!isValidEmail(value)}
        onBlur={onBlur}
        onChange={onChange}
        onKeyDown={onKeyDown}
        innerRef={ref}
        value={value}
        width={inputWidth}
      />
    ),
    [onBlur, value, inputWidth]
  );

  useEffect(() => {
    if (isEditing) {
      // eslint-disable-next-line
      ref?.current?.childNodes[0]?.childNodes[0]?.focus();
    }
  }, [isEditing]);

  return (
    <StyledEmailChip mr={1} mb={1} max={maxWidth - 20}>
      <Visible when={!isEditing} fallback={EmailInput}>
        <Chip
          clickable
          blurbehavior="ignore"
          color={color}
          data-testid="email-chip"
          deleteIcon={DeleteIconComponent}
          label={email}
          onDelete={handleDelete}
          onClick={handleClick}
          variant="outlined"
        />
      </Visible>
    </StyledEmailChip>
  );
};

EmailChip.propTypes = {
  email: string,
  handleChange: func,
  handleClick: func,
  handleDelete: func,
  isEditing: bool,
  isFocused: bool,
  maxWidth: number,
};

EmailChip.defaultProps = {
  email: '',
  handleChange: EMPTY_FUNC,
  handleClick: EMPTY_FUNC,
  handleDelete: EMPTY_FUNC,
  isEditing: false,
  isFocused: false,
  maxWidth: 100,
};

export { EmailChip };
