import {
  arrayOf,
  bool,
  func,
  node,
  number,
  oneOf,
  oneOfType,
  shape,
  string,
} from 'prop-types';
import { values } from 'rambdax';
import {
  CUSTOM_BUTTON_TYPES,
  DONOR_TYPES,
  DONOR_SECTION_TYPES,
} from 'lib/constants';

const DonorSingleAssociationType = shape({
  displayName: string,
  userId: string,
  primaryEmail: string,
  primaryPhone: string,
  role: string,
});

const DonorAssociationType = arrayOf(DonorSingleAssociationType);

const SocialTypes = {
  facebook: string,
  instagram: string,
  linkedin: string,
  twitter: string,
};

const DonorTypes = oneOf(values(DONOR_TYPES));

const DonorSectionTypes = oneOf(values(DONOR_SECTION_TYPES));

const EMAIL_TYPE = {
  email: string,
  primary: bool,
  label: string,
};

const PHONE_TYPE = {
  number: string,
  primary: bool,
  label: string,
};

const ADDRESS_TYPE = {
  city: string,
  country: string,
  line1: string,
  postalCode: string,
  state: string,
  unit: string,
};

const DonorWorkType = {
  created: string,
  deleted: bool,
  ownerOrgId: string,
  orgName: string,
  updated: string,
  workId: string,
  industry: string,
  description: string,
  label: string,
  avatar: string,
  website: string,
  addresses: arrayOf(shape(ADDRESS_TYPE)),
  emails: arrayOf(shape(EMAIL_TYPE)),
  phones: arrayOf(shape(PHONE_TYPE)),
  roles: arrayOf(
    shape({
      orgId: string,
      dateFrom: string,
      jobTitle: string,
      jobDescription: string,
      managerName: string,
    })
  ),
  ...SocialTypes,
};

const DonorUserType = {
  lastName: string,
  label: string,
  created: string,
  deleted: bool,
  userId: string,
  displayName: string,
  ownerOrgId: string,
  updated: string,
  firstName: string,
  addresses: arrayOf(shape(ADDRESS_TYPE)),
  emails: arrayOf(shape(EMAIL_TYPE)),
  phones: arrayOf(shape(PHONE_TYPE)),
  ...SocialTypes,
};

const DonorType = shape({
  avatar: string,
  work: arrayOf(shape(DonorWorkType)),
  household: arrayOf(shape(DonorUserType)),
  affiliations: arrayOf(shape(DonorWorkType)),
  connections: arrayOf(shape(DonorUserType)),
  summary: shape({
    donorType: DonorTypes,
    created: string,
    primaryEmail: string,
    deleted: bool,
    displayName: string,
    ownerOrgId: string,
    primaryPhone: string,
    donorId: string,
  }),
  lastName: string,
  addresses: arrayOf(shape(ADDRESS_TYPE)),
  emails: arrayOf(shape(EMAIL_TYPE)),
  donorType: DonorTypes,
  created: string,
  primaryEmail: string,
  deleted: bool,
  displayName: string,
  phones: arrayOf(shape(PHONE_TYPE)),
  ownerOrgId: string,
  updated: string,
  firstName: string,
  primaryPhone: string,
  note1: string,
  note2: string,
  note3: string,
  donorId: string,
  acquaintances: arrayOf(shape(DonorUserType)),
  contacts: arrayOf(shape(DonorUserType)),
  employees: arrayOf(shape(DonorUserType)),
  planned: number,
  referredBy: shape({
    displayName: string,
    userId: string,
  }),
  lastDonationDate: string,
  lifetimeCount: number,
  lifetimeSum: number,
  lastYearSum: number,
  thisYearCount: number,
  thisYearSum: number,
  fromN: number,
  toN: number,
  ...SocialTypes,
});

const DonorConfigType = {
  key: string.isRequired,
  icon: node,
  path: string.isRequired,
  fieldToExtract: string,
  usePrimary: bool,
  labels: arrayOf(string),
  mapper: func,
  noDataLabel: string,
};

const GapType = {
  size: oneOfType([string, number]),
  vertical: bool,
};

// TODO: fix once it's known
const ProfileDescriptorType = DonorType;

const CustomButtonType = oneOf(Object.values(CUSTOM_BUTTON_TYPES));

const GroupType = arrayOf(
  shape({
    // groupDisplayName behaves as a uniq identifier based on the api response
    groupDisplayName: string.isRequired,
    // groupId is absent in case of team members
    groupId: string,
  })
);

const CompanyTeamMember = shape({
  userId: string,
  inviteId: string,
  firstName: string,
  lastName: string,
  lastVisit: string,
  email: string,
  groups: GroupType,
});

const CompanyTeamMemberStatus = (props, propName, componentName) => {
  const status = props[propName];

  if (
    typeof status?.dataLoaded === 'undefined' ||
    typeof status?.isFetching === 'undefined' ||
    typeof status?.error === 'undefined'
  ) {
    throw Error(`Invalid prop ${propName} supplied to ${componentName}`);
  }
};

const CustomTableFilterProps = shape({
  searchValue: string,
  order: oneOf(['asc', 'desc']),
  orderBy: string,
});

const CustomTableProps = {
  columns: arrayOf(
    shape({
      id: string.isRequired,
      field: string,
      align: oneOf(['left', 'right', 'center']),
      getComparableValue: func,
      headerName: string.isRequired,
      width: number,
      renderCell: func,
      sortable: bool,
      disableClickEventBubbling: bool,
      valueFormatter: func,
      style: shape({}),
    })
  ),
  data: arrayOf(shape({})),
  filter: CustomTableFilterProps,
  idField: string.isRequired,
  onRowClick: func,
  setFilter: func,
  tableStyles: shape({}),
};

const TextSelectFieldValueType = shape({
  value: oneOfType([string, number]),
  text: string,
  icon: string,
});

export {
  ADDRESS_TYPE,
  EMAIL_TYPE,
  PHONE_TYPE,
  DonorAssociationType,
  DonorWorkType,
  DonorUserType,
  DonorType,
  DonorTypes,
  DonorSectionTypes,
  DonorConfigType,
  GapType,
  SocialTypes,
  CustomButtonType,
  ProfileDescriptorType,
  CompanyTeamMember,
  GroupType,
  CompanyTeamMemberStatus,
  CustomTableProps,
  CustomTableFilterProps,
  TextSelectFieldValueType,
};
