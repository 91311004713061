import { keys } from 'rambdax';

// replaces empty values with null
const replaceEmptyValues = (data) =>
  keys(data).reduce((acc, key) => {
    const val = data[key];
    acc[key] = val || null;

    return acc;
  }, {});

export { replaceEmptyValues };
