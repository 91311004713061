import React, { useCallback, useMemo } from 'react';
import classnames from 'classnames';
import {
  arrayOf,
  bool,
  func,
  node,
  number,
  oneOf,
  shape,
  string,
} from 'prop-types';
import Box from '@material-ui/core/Box';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import { makeStyles } from '@material-ui/core';
import NewThemeProvider from 'components/common/NewThemeProvider';

const useStyles = makeStyles(() => ({
  tabWrapper: {
    display: 'flex',
    flex: '1',
    flexDirection: 'column',
    overflow: 'auto',
  },
  tabContent: {
    overflow: 'auto',
    flex: 1,
  },
  tabsFlexContainer: {
    paddingLeft({ offset }) {
      return offset;
    },
  },
}));

const PageTabs = ({ onChange, offset, tabs, variant, tabIdx }) => {
  const classes = useStyles();
  const handleChange = useCallback(
    (_event, newValue) => onChange(newValue),
    [onChange]
  );

  const RenderedTabs = useMemo(
    () =>
      tabs?.map(({ id, label, disabled }, idx) => (
        <Tab
          classes={{
            root: classes.tabRoot,
          }}
          data-testid={`page-tab-${idx}`}
          disabled={disabled}
          label={label}
          key={id}
        />
      )),
    [tabs]
  );

  return (
    <NewThemeProvider>
      <Tabs
        classes={{
          flexContainer: classes.tabsFlexContainer,
        }}
        onChange={handleChange}
        offset={offset}
        value={tabIdx}
        variant={variant}
      >
        {RenderedTabs}
      </Tabs>
    </NewThemeProvider>
  );
};

PageTabs.propTypes = {
  onChange: func.isRequired,
  offset: string,
  tabs: arrayOf(
    shape({
      id: string,
      label: string,
      disabled: bool,
    })
  ),
  variant: oneOf(['fullWidth', 'standard', 'scrollable']),
  tabIdx: number.isRequired,
};

PageTabs.defaultProps = {
  offset: '0px',
  tabs: [],
  variant: 'standard',
};

function PageTabsWrapper({ children }) {
  const classes = useStyles();

  return <Box className={classes.tabWrapper}>{children}</Box>;
}

PageTabsWrapper.propTypes = {
  children: node.isRequired,
};

function PageTabsContent({ children, className, ...props }) {
  const classes = useStyles();

  return (
    <Box
      className={classnames(classes.tabContent, {
        [className]: !!className,
      })}
      {...props}
    >
      {children}
    </Box>
  );
}

PageTabsContent.propTypes = {
  children: node.isRequired,
  className: string,
  ...Box.propTypes,
};

PageTabsContent.defaultProps = {
  className: '',
};

PageTabs.Wrapper = PageTabsWrapper;
PageTabs.Content = PageTabsContent;

export { PageTabs };
