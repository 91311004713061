import React from 'react';
import { string } from 'prop-types';
import { makeStyles, Typography } from '@material-ui/core';
import { formatDate } from 'lib/utils';

const useStyles = makeStyles((theme) => ({
  text: {
    ...theme.typography.body2,
    [theme.breakpoints.down('sm')]: theme.typography.caption,
  },
}));

const DonationDate = ({ value }) => {
  const styles = useStyles();

  return <Typography className={styles.text}>{formatDate(value)}</Typography>;
};

DonationDate.propTypes = {
  value: string.isRequired,
};

export { DonationDate };
