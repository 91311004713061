import React from 'react';
import PropTypes from 'prop-types';
import Alert from '@material-ui/lab/Alert';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { FormProvider, useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import PasswordForm from 'components/auth/PasswordForm';
import { PUBLIC_ROUTES, REGISTRATION_TYPE_INVITE } from 'lib/constants';
import { translate } from 'lib/intl';
import { firstNameMaxLength, lastNameMaxLength } from './authConstants';

const useStyles = makeStyles((theme) => ({
  setUp: {
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.h1.fontSize,
    [theme.breakpoints.up('sm')]: {
      display: 'inline-block',
    },
  },
  yourEmail: {
    fontWeight: theme.typography.fontWeightRegular,
    lineHeight: 1.75,
    letterSpacing: 0.5,
    [theme.breakpoints.up('sm')]: {
      marginBottom: theme.spacing(0.5),
    },
  },
  email: {
    lineHeight: 1.4,
    [theme.breakpoints.down('sm')]: {
      fontWeight: theme.typography.h6.fontWeight,
      fontSize: theme.typography.subtitle1.fontSize,
      lineHeight: 1.75,
    },
  },
  firstName: {
    width: `calc(50% - ${theme.spacing(1)}px)`,
    marginRight: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      marginRight: 0,
      marginBottom: theme.spacing(2.5),
      width: '100%',
    },
  },
  lastName: {
    width: `calc(50% - ${theme.spacing(1)}px)`,
    marginLeft: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(2.5),
      marginLeft: '0',
      width: '100%',
    },
  },
  lowerFormContainer: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
  },
}));

const RegistrationByInviteForm = ({
  isFetching,
  isRegistrationSuccessful,
  orgName,
  registrationError,
  registerAction,
  resetRegistrationState,
  code,
  email,
}) => {
  const formMethods = useForm({
    mode: 'onBlur',
  });
  const { errors, handleSubmit, register } = formMethods;
  const history = useHistory();
  const classes = useStyles();

  React.useEffect(() => {
    if (isRegistrationSuccessful) {
      resetRegistrationState();
      history.push(`${PUBLIC_ROUTES.REGISTRATION_SUCCESS}?org_name=${orgName}`);
    }
  }, [isRegistrationSuccessful]);

  return (
    <FormProvider {...formMethods}>
      <form
        onSubmit={handleSubmit((data) => {
          /* eslint-disable no-param-reassign */
          data.registrationType = REGISTRATION_TYPE_INVITE;
          data.inviteCode = code;
          data.orgEmail = email;
          /* eslint-enable no-param-reassign */

          return registerAction(data);
        })}
      >
        <Box display="flex" flexDirection="column">
          <Box flexDirection="column" mb={3}>
            <Box mb={2.5}>
              <Box display={{ xs: 'block', sm: 'inline-block' }}>
                <Typography variant="h1">
                  {translate('REGISTER_HEADING_WELCOME')}
                </Typography>
              </Box>{' '}
              <Typography variant="h2" className={classes.setUp}>
                {translate('REGISTER_GET_SET_UP')}
              </Typography>
            </Box>
            <Typography
              className={classes.yourEmail}
              variant="body1"
              component="div"
            >
              {translate('REGISTER_YOUR_EMAIL')}
            </Typography>
            <Typography className={classes.email} variant="h3" component="span">
              {email}
            </Typography>

            {registrationError && (
              <Box xs={12} mt={2}>
                <Alert severity="error">{registrationError.message}</Alert>
              </Box>
            )}
          </Box>
          <Box className={classes.nameContainer}>
            <TextField
              className={classes.firstName}
              error={!!errors.firstName}
              helperText={errors?.firstName?.message || ''}
              inputRef={register({
                required: 'Name Required',
                maxLength: {
                  value: firstNameMaxLength,
                  message: translate('VALIDATION_MAX_CHARACTERS_ALLOWED', {
                    length: firstNameMaxLength,
                  }),
                },
              })}
              name="firstName"
              label="First Name"
            />
            <TextField
              className={classes.lastName}
              error={!!errors.lastName}
              helperText={errors?.lastName?.message || ''}
              inputRef={register({
                maxLength: {
                  value: lastNameMaxLength,
                  message: translate('VALIDATION_MAX_CHARACTERS_ALLOWED', {
                    length: lastNameMaxLength,
                  }),
                },
              })}
              label="Last Name"
              name="lastName"
            />

            <PasswordForm />
          </Box>
        </Box>
        <Box xs={12} mt={3} className={classes.lowerFormContainer}>
          {isFetching ? (
            <CircularProgress
              color="primary"
              style={{
                height: '54px',
                width: '54px',
              }}
            />
          ) : (
            <Button
              type="submit"
              variant="contained"
              disabled={isFetching}
              color="primary"
            >
              {translate('CREATE_ACCOUNT')}
            </Button>
          )}
        </Box>
      </form>
    </FormProvider>
  );
};

RegistrationByInviteForm.propTypes = {
  isFetching: PropTypes.bool.isRequired,
  isRegistrationSuccessful: PropTypes.bool.isRequired,
  orgName: PropTypes.string.isRequired,
  registrationError: PropTypes.shape({ message: PropTypes.string }),
  registerAction: PropTypes.func.isRequired,
  resetRegistrationState: PropTypes.func.isRequired,
  code: PropTypes.string,
  email: PropTypes.string,
};

RegistrationByInviteForm.defaultProps = {
  code: null,
  email: null,
  registrationError: null,
};

export { RegistrationByInviteForm };
