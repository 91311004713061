import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  generatePath,
  useHistory,
  useParams,
  useLocation,
} from 'react-router-dom';
import { constituentActions } from 'rdx/actions';
import { useDispatch, useSelector } from 'react-redux';
import { DONOR_SECTION_TYPES, USER_VIEW_ENUMS } from 'lib/constants/common';
import { CONSTITUENT_TYPES } from 'lib/constants/constituentConstants';
import { hasIntersection } from 'lib/utils/hasIntersection';
import { recordConstituentView } from 'lib/utils/recentlyViewedConstituentsUtils';
import { useCurrentUser } from 'lib/hooks/user-data';
import constituentFormPopupActions from 'rdx/actions/constituentFormPopupActions';
import {
  getConstituentPageTabs,
  getConstituentPageTabId,
} from './constituentPageUtils';

const { PERSONAL_INFORMATION, COMPANY_INFORMATION } = DONOR_SECTION_TYPES;

const SECOND_TAB_IDX = 1;

const useConstituentPage = () => {
  const history = useHistory();
  const location = useLocation();
  const { constituentType: typeParam = '', constituentId = '' } = useParams();

  const dispatch = useDispatch();

  const { views } = useCurrentUser(true);
  const isEditable = hasIntersection(
    [USER_VIEW_ENUMS.ADMIN, USER_VIEW_ENUMS.EDITOR],
    views
  );

  const isNewConstituent = !constituentId;

  // makes sure to start with showing a page loader in case it's not a creating flow
  const [showPageLoader, setShowPageLoader] = useState(!isNewConstituent);

  const {
    constituent,
    constituentFetching,
    constituentLoaded,
    ownerOrgId,
    constituentActivityLoaded,
  } = useSelector((state) => {
    const constituentData = state.constituents?.data?.constituent || {};

    return {
      constituent:
        constituentData?.constituentId === constituentId ? constituentData : {},
      constituentFetching: state.constituents.isFetching,
      constituentLoaded: state.constituents.dataLoaded,
      ownerOrgId: state.authentication?.ownerOrgId || '',
      constituentActivityLoaded: state.constituentFeed.dataLoaded,
    };
  });

  const constituentType = constituent.constituentType || typeParam;
  const isPersonType = constituentType === CONSTITUENT_TYPES.PERSON;

  const tabsConfig = useMemo(
    () => getConstituentPageTabs(isNewConstituent, isPersonType),
    [isNewConstituent, isPersonType]
  );

  const [tabIdx, setTabIdx] = useState(
    getConstituentPageTabId(tabsConfig, location)
  );

  useEffect(() => {
    setTabIdx(getConstituentPageTabId(tabsConfig, location));
  }, [location, tabsConfig]);

  const fetchConstituent = useCallback(
    (id) => {
      if (!constituentFetching) {
        dispatch(constituentActions.fetchConstituent({ constituentId: id }));
      }
    },
    [constituentFetching]
  );

  const onTabClick = (idx) => {
    const { path } = tabsConfig[idx];

    if (path) {
      setTabIdx(idx);

      history.push(
        generatePath(path, {
          constituentType,
          constituentId,
        })
      );
    }
  };

  const openDonorForm = useCallback(
    (formFieldToFocus) => {
      if (!isNewConstituent) {
        onTabClick(SECOND_TAB_IDX);
        dispatch(
          constituentFormPopupActions.openFormPopup({
            formName: isPersonType ? PERSONAL_INFORMATION : COMPANY_INFORMATION,
            isCreating: false,
            fieldToFocus: formFieldToFocus,
            data: constituent,
          })
        );
      } else if (formFieldToFocus) {
        dispatch(
          constituentFormPopupActions.openFormPopup({
            fieldToFocus: formFieldToFocus,
            isCreating: true,
          })
        );
      }
    },
    [isNewConstituent, constituent]
  );

  const closeDonorForm = () =>
    dispatch(constituentFormPopupActions.closeFormPopup());

  useEffect(() => {
    if (constituentId) {
      fetchConstituent(constituentId);
    }
  }, [constituentId]);

  useEffect(() => {
    const { constituentId: id, displayName, avatar } = constituent;

    // only records existing users
    if (!isNewConstituent && id && displayName) {
      recordConstituentView({
        constituentId: id,
        constituentType,
        displayName,
        avatar,
      });
    }
  }, [constituent]);

  useEffect(() => {
    // Hides a page loader in case all necessary data is loaded
    if (constituentLoaded && !(tabIdx === 0 && !constituentActivityLoaded)) {
      setShowPageLoader(false);
    }
  }, [constituentLoaded, constituentActivityLoaded, tabIdx]);

  return {
    constituent,
    constituentFetching,
    constituentLoaded,
    constituentType,
    isNewConstituent,
    isPersonType,
    constituentId,
    fetchConstituent,
    onTabClick,
    ownerOrgId,
    setTabIdx,
    tabIdx,
    tabsConfig,
    openDonorForm,
    closeDonorForm,
    showPageLoader,
    isEditable,
  };
};

export { useConstituentPage };
