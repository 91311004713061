/**
 * Loads scripts into a <script> tag, returning a Promise that resolves when
 * the script "onload" event fires, or rejects when the "onerror" event fires.
 *
 * @param {string} scriptSrc - The path to the javascript resource
 * @param {object} options
 * @param {string} options.crossOrigin - <script crossorigin="...">
 * @param {string} options.integrity - <script integrity="...">
 */
export default function loadScript(scriptSrc, options) {
  // If the script is already in the DOM, resolve.
  if (document.querySelector(`script[src="${scriptSrc}"]`)) {
    // eslint-disable-next-line no-eval
    return Promise.resolve();
  }

  return new Promise((resolve, reject) => {
    // All 3rd party scripts should have the integrity and crossOrigin options
    // so that we can trust the contents that are coming over from them. If
    // they don't, throw an error.
    // https://developer.mozilla.org/en-US/docs/Web/Security/Subresource_Integrity
    if (!options.integrity || !options.crossOrigin) {
      reject(
        new Error(
          'loadScript: missing "integrity" and / or "crossOrigin" option(s)'
        )
      );

      return;
    }

    const script = document.createElement('script');

    ['crossOrigin', 'integrity'].forEach((attribute) => {
      const optionValue = options[attribute];

      if (typeof optionValue === 'undefined') {
        return;
      }

      script.setAttribute(attribute, optionValue === true ? '' : optionValue);
    });

    // eslint-disable-next-line no-eval
    script.onload = resolve;
    script.onerror = reject;
    script.type = 'text/javascript';
    script.src = scriptSrc;

    document.head.appendChild(script);
  });
}
