/**
 * This is a "controlled" CurrencyInput component, made to work with
 * react-hooks-form.
 */
import React from 'react';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';
import { CurrencyInput } from './CurrencyInput';

export default function ControlledCurrencyInput({
  control,
  defaultValue,
  error,
  name,
  rules,
  ...props
}) {
  return (
    <Controller
      {...(defaultValue && { defaultValue })}
      {...(rules && { rules })}
      control={control}
      name={name}
      render={({ onChange, value }) => {
        return (
          <CurrencyInput
            {...{
              ...props,
              amount: value,
              error: !!error,
              errorHelperText: error,
              setAmount: onChange,
            }}
          />
        );
      }}
    />
  );
}

ControlledCurrencyInput.propTypes = {
  control: PropTypes.object.isRequired,
  defaultValue: CurrencyInput.propTypes.amount,
  error: PropTypes.string,
  name: PropTypes.string.isRequired,
  rules: PropTypes.object,
  label: CurrencyInput.propTypes.label,
};

ControlledCurrencyInput.defaultProps = {
  defaultValue: null,
  error: '',
  label: CurrencyInput.defaultProps.label,
  rules: null,
};
