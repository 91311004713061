import React from 'react';
import { func, string, shape, number } from 'prop-types';
import { EMPTY_FUNC, TEXT_SELECT_FIELD_EMPTY_VALUE } from 'lib/constants';
import { DateInput } from 'components/common/form';
import { Grid, makeStyles } from '@material-ui/core';
import { NotesInput, CurrencyInput, TextSelectField } from 'components';
import { translate } from 'lib/intl';
import { TextSelectFieldValueType } from 'lib/utils';
import { DONATION_RECURRING_CADENCES } from 'lib/configs/donations';
import { DonationPaymentMethodInput } from './donation-payment-method-input';

const useStyles = makeStyles(() => ({
  rowItem: {
    paddingTop: '12px',
    alignItems: 'flex-end',
  },
  notesContainer: {
    paddingTop: '24px',
  },
}));

const NewDonationTypeRecurring = ({
  fieldErrors,
  donationAmount,
  setDonationAmount,
  paymentMethod,
  setPaymentMethod,
  notes,
  setNotes,
  recurringCadence,
  setRecurringCadence,
  recurringStartDate,
  setRecurringStartDate,
}) => {
  const styles = useStyles();

  return (
    <>
      <Grid className={styles.rowItem} item container xs={12}>
        <CurrencyInput
          label={translate('DONATION_AMOUNT_LABEL')}
          amount={donationAmount}
          setAmount={setDonationAmount}
          error={!!fieldErrors?.donationAmount}
          errorHelperText={translate('DONATION_AMOUNT_ERROR_REQUIRED')}
        />
      </Grid>
      <Grid className={styles.rowItem} container spacing={2}>
        <Grid className={styles.rowItem} item xs={12} sm={8}>
          <TextSelectField
            id="text-select-field-recurring-cadences"
            items={DONATION_RECURRING_CADENCES}
            value={recurringCadence}
            setValue={setRecurringCadence}
            label={translate('RECURRING_CADENCE_LABEL')}
            error={!!fieldErrors?.recurringCadence}
            errorHelperText={translate('RECURRING_CADENCE_ERROR_REQUIRED')}
          />
        </Grid>
        <Grid className={styles.rowItem} item xs={12} sm={4}>
          <DateInput
            id="donation-date-picker-recurring-date"
            label={translate('RECURRING_START_DATE_LABEL')}
            onChange={setRecurringStartDate}
            value={recurringStartDate}
          />
        </Grid>
      </Grid>
      <Grid className={styles.rowItem} item container xs={12}>
        <DonationPaymentMethodInput
          paymentMethod={paymentMethod}
          setPaymentMethod={setPaymentMethod}
          error={!!fieldErrors?.paymentMethod}
        />
      </Grid>
      <Grid className={styles.notesContainer} item container xs={12}>
        <NotesInput
          label={translate('DONATION_NOTES_LABEL')}
          notes={notes}
          setNotes={setNotes}
          maxLength={10000}
        />
      </Grid>
    </>
  );
};

NewDonationTypeRecurring.propTypes = {
  fieldErrors: shape({}),
  donationAmount: number,
  setDonationAmount: func,
  paymentMethod: TextSelectFieldValueType,
  setPaymentMethod: func,
  notes: string,
  setNotes: func,
  recurringCadence: TextSelectFieldValueType,
  setRecurringCadence: func,
  recurringStartDate: shape({}),
  setRecurringStartDate: func,
};

NewDonationTypeRecurring.defaultProps = {
  fieldErrors: {},
  donationAmount: 0,
  setDonationAmount: EMPTY_FUNC,
  paymentMethod: TEXT_SELECT_FIELD_EMPTY_VALUE,
  setPaymentMethod: EMPTY_FUNC,
  notes: '',
  setNotes: EMPTY_FUNC,
  recurringCadence: TEXT_SELECT_FIELD_EMPTY_VALUE,
  setRecurringCadence: EMPTY_FUNC,
  recurringStartDate: null,
  setRecurringStartDate: EMPTY_FUNC,
};

export { NewDonationTypeRecurring };
