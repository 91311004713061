import React from 'react';
import { AuthContainer, AdminRegistrationForm } from 'components';
import { translate } from 'lib/intl';
import { PUBLIC_ROUTES } from 'lib/constants';
import { useRegistration } from './registrationPagesHooks';

const AdminRegistrationPage = () => {
  const {
    adminRegisterAction,
    resetRegistrationState,
    isFetching,
    isRegistrationSuccessful,
    error,
    userEmail,
  } = useRegistration();

  return (
    <AuthContainer
      size="md"
      navLinkText={translate('SIGN_IN_INSTEAD')}
      navLinkRoute={PUBLIC_ROUTES.ADMIN_LOGIN}
    >
      <AdminRegistrationForm
        isFetching={isFetching}
        error={error}
        isRegistrationSuccessful={isRegistrationSuccessful}
        registerAction={adminRegisterAction}
        resetRegistrationState={resetRegistrationState}
        email={userEmail}
      />
    </AuthContainer>
  );
};

export { AdminRegistrationPage };
