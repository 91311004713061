import { combineReducers } from 'redux';
import userActionTypes from 'rdx/actionTypes';
import { registration } from 'rdx/reducers/registration';
import { authentication } from 'rdx/reducers/authentication';
import { resendVerification } from 'rdx/reducers/resend-verification';
import { inviteForm } from 'rdx/reducers/invite-form';
import { sendingInvites } from 'rdx/reducers/sending-invites';
import { invites } from 'rdx/reducers/invites';
import { updatingInvites } from 'rdx/reducers/updating-invites';
import { resetPassword } from 'rdx/reducers/reset-password';
import { resetPasswordSubmit } from 'rdx/reducers/reset-password-submit';
import { location } from 'rdx/reducers/location';
import { orgFeed } from 'rdx/reducers/feed';
import { recentlyViewedDonors } from 'rdx/reducers/recently-viewed-donors';
import { user } from 'rdx/reducers/user';
import { orgList } from 'rdx/reducers/org-list';
import { publicUsers } from 'rdx/reducers/public-users';
import { companyTeamMembers } from 'rdx/reducers/company-team-members';
import { updatingCompanyTeamMembers } from 'rdx/reducers/updating-company-team-members';
import { orgInfo } from 'rdx/reducers/org-info';
import { updatingOrgInfo } from 'rdx/reducers/updating-org-info';
import { usersList } from 'rdx/reducers/users-list';
import { newDonation } from 'rdx/reducers/new-donation';
import { donations } from 'rdx/reducers/donations';
import { interactions } from 'rdx/reducers/interactions';
import { addInteraction } from 'rdx/reducers/add-interaction';
import { visitStreak } from 'rdx/reducers/visit-streak';
import { removingDonorInfo } from 'rdx/reducers/removing-donor-info';
import { announcements } from 'rdx/reducers/announcements';
import { feedEntity } from 'rdx/reducers/feed-entity';
import { campaigns } from 'rdx/reducers/campaigns';
import { campaignReducer } from 'rdx/reducers/campaignReducer';
import { updateCampaign } from 'rdx/reducers/updateCampaign';
import { updateMultipleCampaigns } from 'rdx/reducers/updateMultipleCampaigns';
import { removeCampaign } from 'rdx/reducers/removeCampaign';
import { campaignFeedReducer } from 'rdx/reducers/campaignFeedReducer';
import { updateCampaignFiscalGoalReducer } from 'rdx/reducers/updateCampaignFiscalGoalReducer';
import { campaignDonationsReducer } from 'rdx/reducers/campaignDonationsReducer';
import { featureFlags } from 'rdx/reducers/featureFlags';
import { actionDialogReducer } from 'rdx/reducers/actionDialogReducer';
import { deleteFeedEntityReducer } from 'rdx/reducers/deleteFeedEntityReducer';
import constituentReducer from './constituentReducer';
import constituentFormPopupReducer from './constituentFormPopupReducer';
import updatingConstituentReducer from './updatingConstituentReducer';
import constituentsSearchReducer from './constituentsSearchReducer';
import constituentFeedReducer from './constituentFeedReducer';
import reportsReducer from './reportsReducer';

const appReducer = combineReducers({
  actionDialog: actionDialogReducer,
  addInteraction,
  announcements,
  authentication,
  campaign: campaignReducer,
  campaignDonations: campaignDonationsReducer,
  campaignFeed: campaignFeedReducer,
  campaigns,
  companyTeamMembers,
  constituents: constituentReducer,
  constituentFeed: constituentFeedReducer,
  constituentFormPopup: constituentFormPopupReducer,
  constituentsSearch: constituentsSearchReducer,
  deleteFeedEntity: deleteFeedEntityReducer,
  donations,
  featureFlags,
  feedEntity,
  interactions,
  inviteForm,
  invites,
  location,
  newDonation,
  orgFeed,
  orgInfo,
  orgList,
  publicUsers,
  recentlyViewedDonors,
  registration,
  removeCampaign,
  removingDonorInfo,
  reports: reportsReducer,
  resendVerification,
  resetPassword,
  resetPasswordSubmit,
  sendingInvites,
  updateCampaign,
  updateCampaignFiscalGoal: updateCampaignFiscalGoalReducer,
  updateMultipleCampaigns,
  updatingCompanyTeamMembers,
  updatingConstituent: updatingConstituentReducer,
  updatingInvites,
  updatingOrgInfo,
  user,
  usersList,
  visitStreak,
});

export default function (state, action) {
  return appReducer(
    action.type === userActionTypes.LOGOUT ? undefined : state,
    action
  );
}
