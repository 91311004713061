import React from 'react';
import { useFeature } from 'flagged';
import { ShowForViews } from 'components/common/ShowForViews';
import { NavigationListItem } from './NavigationListItem';

export function UnmemoizedNavigationList({ onClick, items }) {
  return items.map(({ featureFlag, showFor, ...item }) => {
    const { navRoute } = item;
    let navItem = (
      <NavigationListItem
        {...{
          key: navRoute,
          ...item,
          ...(onClick && { onClick }),
        }}
      />
    );

    if (featureFlag) {
      navItem = useFeature(featureFlag) ? navItem : null;
    }

    if (showFor) {
      navItem = (
        <ShowForViews key={navRoute} showFor={showFor}>
          {navItem}
        </ShowForViews>
      );
    }

    return navItem;
  });
}

export default React.memo(UnmemoizedNavigationList);
