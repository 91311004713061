import React from 'react';
import { string } from 'prop-types';
import { useHistory } from 'react-router-dom';
import { CustomLink } from './CustomLink';

// TODO: possibly remove the component and use react-router-dom's <Link>
// by adding style overrides for links to src/styles/theme.js
const RouterLink = ({ children, to, ...rest }) => {
  const history = useHistory();

  const onClick = (e) => {
    e.stopPropagation();

    history.push(to);
  };

  return (
    <CustomLink onClick={onClick} {...rest}>
      {children}
    </CustomLink>
  );
};

RouterLink.propTypes = {
  children: string.isRequired,
  to: string.isRequired,
};

export { RouterLink };
