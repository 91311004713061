export const INTERACTIONS = {
  ADD_NEW_INTERACTION: 'Add New Interaction',
  EDIT_INTERACTION: 'Edit Interaction',
  INTERACTION_CONTACT_NAME: 'Contact Name',
  INTERACTION_CONTACT_NAME_ERROR_REQUIRED: 'Contact Name Required',
  INTERACTION_DATE: 'Interaction Date',
  INTERACTION_DATE_ERROR_REQUIRED: 'Interaction Date Required',
  INTERACTION_METHOD: 'Interaction Method',
  INTERACTION_METHOD_ERROR_REQUIRED: 'Interaction Method Required',
  INTERACTION_NARRATIVE: 'Narrative',
  INTERACTION_EMPLOYEES_INVOLVED: 'Employees Involved',
  INTERACTION_EMPLOYEES_INVOLVED_ERROR_REQUIRED: 'Employee Involved Required',
  INTERACTION_EXPERIENCE_ERROR_REQUIRED: 'Experience Required',
  INTERACTION_SATISFACTION_TITLE: 'How did it go?',
  INTERACTION_METHOD_EMAIL: 'Email',
  INTERACTION_METHOD_IN_PERSON: 'In Person',
  INTERACTION_METHOD_VIDEO_CALL: 'Video Call',
  INTERACTION_METHOD_PHONE_CALL: 'Phone Call',
  INTERACTION_METHOD_TEXT: 'Text',
  INTERACTION_METHOD_SOCIAL: 'Social',
  INTERACTION_METHOD_DIRECT_MAIL: 'Direct Mail',
  ADD_NEW_INTERACTION_ERROR: "Couldn't add a new interaction",
  EDIT_NEW_INTERACTION_ERROR: "Couldn't edit the interaction",
  ADD_NEW_INTERACTION_SUCCESS_MESSAGE:
    'A new interaction was succesfully added',
  EDIT_INTERACTION_SUCCESS_MESSAGE: 'The interaction was succesfully edited',
  INTERACTION_CAMPAIGN: 'Campaign',
  INTERACTION_CAMPAIGN_ERROR_REQUIRED: 'Campaign Required',
  DELETE_INTERACTION_TITLE: 'Delete Interaction',
  DELETE_INTERACTION_LABEL: 'Are you sure you want to delete this interaction?',
};
