export const DONORS = {
  DONORS_TITLE: 'Donors',
  NEW_DONOR_LABEL: 'New Donor',
  DONOR_CREATED_LABEL: 'Donor Created!',
  DONOR_CREATED_MESSAGE: 'Donor was successfully created',
  DONOR_OPEN_PROFILE: 'Open Profile',
  DONOR_COLUMN_BACKLOG: 'Backlog',
  DONOR_COLUMN_BACKLOG_CONTENT_1: 'Researching prospects.',
  DONOR_COLUMN_BACKLOG_CONTENT_2: 'Performing wealth screens.',
  DONOR_COLUMN_BACKLOG_CONTENT_3:
    'Categorizing on inclination, capacity and readiness.',
  DONOR_COLUMN_CULTIVATING: 'Cultivating',
  DONOR_COLUMN_CULTIVATING_CONTENT_1:
    'Evaluating top prospects based on greatest inclination, capacity and readiness.',
  DONOR_COLUMN_CULTIVATING_CONTENT_2:
    'Creating custom moves plan for top prospects.',
  DONOR_COLUMN_CULTIVATING_CONTENT_3: 'Identifying the ask amount.',
  DONOR_COLUMN_REQUESTING: 'Requesting',
  DONOR_COLUMN_REQUESTING_CONTENT_1: 'Developing the request proposal.',
  DONOR_COLUMN_REQUESTING_CONTENT_2:
    'Setting up the appointment with a clear purpose to share a request proposal.',
  DONOR_COLUMN_REQUESTING_CONTENT_3: 'Making the request.',
  DONOR_COLUMN_STEWARDSHIP: 'Stewardship',
  DONOR_COLUMN_STEWARDSHIP_CONTENT_1: 'Thanking the donor for the gift.',
  DONOR_COLUMN_STEWARDSHIP_CONTENT_2:
    'Developing a plan to update the donor about their gift and the impact it created.',
  DONOR_COLUMN_PROSPECTING: 'Prospecting',
  DONOR_COLUMN_PROSPECTING_CONTENT_1: 'Researching prospects.',
  DONOR_COLUMN_PROSPECTING_CONTENT_2: 'Performing wealth screens.',
  DONOR_COLUMN_PROSPECTING_CONTENT_3:
    'Categorizing on inclination, capacity and readiness.',
  DONOR_ASSIGNED_TO: 'assigned to:',
  DONOR_SEARCH: 'Search Donors',
  DONOR_FILTER: 'Filter',
  DONOR_ADD_NEW: 'Add new donor',
  DONOR_CARD_ACTION_MOVE_FIRST: 'Priority',
  DONOR_CARD_ACTION_MOVE: 'Move Card',
  DONOR_CARD_ACTION_MOVE_COLUMN_POSITION: 'Choose Column',
  DONOR_CARD_ACTION_CANCEL: 'Cancel',
  DONOR_CARD_ACTION_MOVE_SUBMIT: 'Move',
  DONOR_CARD_ACTION_ASSIGN: 'Change Assigned',
  DONOR_CARD_ACTION_ASSIGN_SUBMIT: 'Update',
  DONOR_CARD_ACTION_ASSIGN_SEARCH: 'Search Team Members',
  DONOR_CARD_ACTION_REMOVE_CARD: 'Remove Card',
  DONORS_ASSIGNEE_FILTER_TITLE: 'Show Donors For',
  DONORS_HEADER_ASSIGNEE_FILTER_ALL: 'Show all donors',
  DONORS_ADD_NEW_DONOR_LABEL: 'Add New Donor As',
  DONORS_NEW_DONOR_INDIVIDUAL_LABEL: 'Individual',
  DONORS_NEW_DONOR_ORG_LABEL: 'Organization',
  DONORS_DONOR_WITH_DEVELOPERS_GENERIC_ERROR:
    'Error fetching donor developers.',
  DONORS_RECENTLY_VIEWED_EMPTY: 'No Recently Viewed Donors',
  ALL_DONORS_LABEL: 'All Constituents',
};
