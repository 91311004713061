import React from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import Box from '@material-ui/core/Box';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormHelperText from '@material-ui/core/FormHelperText';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { CloseIcon } from 'assets/images';
import {
  ControlledOrgIdInputs,
  ControlledTextField,
} from 'components/common/form';
import { CustomButton, Loader } from 'components/common';
import { LOADER_SIZES } from 'lib/constants/common';
import { translate } from 'lib/intl';
import { isMobileView } from 'lib/utils';
import { orgActions } from 'rdx/actions';

const defaultValues = {
  orgName: '',
  ein: '',
  primaryPhone: '+1',
};

const useStyles = makeStyles((theme) => ({
  dialogPaperFullWidth: {
    [theme.breakpoints.down('sm')]: {
      margin: theme.spacing(1),
      width: `calc(100% - ${theme.spacing(2)}px)`,
    },
  },
  dialogTitleRoot: {
    borderBottom: `1px solid ${theme.palette.grey.light}`,
  },
  dialogTitleText: {
    fontWeight: theme.typography.fontWeightMedium,
  },
  dialogContentRoot: {
    paddingBottom: theme.spacing(4),
    paddingTop: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      paddingRight: theme.spacing(1.5),
      paddingLeft: theme.spacing(1.5),
    },
  },
  dialogActionsRoot: {
    paddingBottom: theme.spacing(3),
    borderTop: `1px solid ${theme.palette.grey.light}`,
    height({ isSaving }) {
      return isSaving && theme.spacing(13);
    },
    padding({ isSaving }) {
      return isSaving && `${theme.spacing(3)}px ${theme.spacing(2)}`;
    },
    justifyContent({ isSaving }) {
      return isSaving && 'center';
    },
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
      paddingBottom: theme.spacing(1.5),
      paddingTop: theme.spacing(1.5),
    },
  },
  clearButton: {
    marginRight: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      order: 1,
      marginTop: theme.spacing(1),
      marginRight: 0,
    },
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.common.black,
  },
}));

export default function CreateOrgDialog({
  isOpen,
  onClose,
  onCreationResponse,
}) {
  const [isSaving, setIsSaving] = React.useState(false);
  const dispatch = useDispatch();
  const isMobile = isMobileView();
  const classes = useStyles({
    isSaving,
  });

  const { clearErrors, control, errors, handleSubmit, watch } = useForm({
    mode: 'onBlur',
    defaultValues,
  });

  const handleFormSubmit = async (formData) => {
    setIsSaving(true);

    try {
      const response = await dispatch(orgActions.createOrg(formData));

      onCreationResponse(response);
    } catch (error) {
      onCreationResponse({
        error: true,
        error_num: 'GENERAL_ERROR',
        message: error.message,
      });
    } finally {
      setIsSaving(false);
      onClose();
    }
  };

  return (
    <Dialog
      classes={{
        paperFullWidth: classes.dialogPaperFullWidth,
      }}
      data-testid="create-org-dialog"
      fullWidth
      maxWidth="xs"
      mobile={Number(isMobile)}
      onClose={onClose}
      open={isOpen}
    >
      <DialogTitle
        classes={{
          root: classes.dialogTitleRoot,
        }}
        disableTypography
      >
        <Typography className={classes.dialogTitleText}>
          {translate('ORG_MGMT_CREATE_ORG_DIALOG_TITLE')}
        </Typography>

        <IconButton onClick={onClose} className={classes.closeButton}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent classes={{ root: classes.dialogContentRoot }}>
        <form>
          <Box pb={2}>
            <ControlledTextField
              control={control}
              fullWidth
              errors={errors}
              label={translate('ORG_MGMT_CREATE_ORG_DIALOG_NAME')}
              name="orgName"
              required
              rules={{
                required: translate('FORMS_VALIDATION_REQUIRED'),
              }}
            />
            {errors.orgName && (
              <FormHelperText error>{errors.orgName.message}</FormHelperText>
            )}
          </Box>
          <ControlledOrgIdInputs
            clearErrors={clearErrors}
            control={control}
            errors={errors}
            watch={watch}
          />
        </form>
      </DialogContent>

      <DialogActions classes={{ root: classes.dialogActionsRoot }}>
        {isSaving ? (
          <Loader
            alignItems="center"
            justifyContent="center"
            display="flex"
            size={LOADER_SIZES.LG}
          />
        ) : (
          <Box
            display="flex"
            flex={isMobile ? 1 : 'none'}
            flexWrap={isMobile ? 'wrap' : 'nowrap'}
            pt={isMobile ? 0 : 2.5}
          >
            <CustomButton
              buttonType="link"
              classes={{
                root: classes.clearButton,
              }}
              color="secondary"
              fullWidth
              label={translate('FORMS_BUTTON_CANCEL')}
              onClick={onClose}
              type="button"
              variant="text"
            />{' '}
            <CustomButton
              buttonType="primary"
              color="secondary"
              fullWidth
              label={translate('FORMS_SUBMIT_CREATE')}
              onClick={handleSubmit(handleFormSubmit)}
              type="submit"
              variant="contained"
            />
          </Box>
        )}
      </DialogActions>
    </Dialog>
  );
}

CreateOrgDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onCreationResponse: PropTypes.func.isRequired,
};
