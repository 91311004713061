/* eslint-disable no-useless-escape */
const VALID_EMAIL_REG =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const EMAIL_REG =
  /(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))/g;
const EMAIL_CHUNKS_REG =
  /((\w+[ -]+)*[<]*(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))[>]*)|(\w+[ -]*)+/g;

const isValidEmail = (email) =>
  VALID_EMAIL_REG.test(String(email).toLowerCase());

const extractEmails = (text) =>
  String(text)
    .match(EMAIL_CHUNKS_REG)
    ?.reduce((res, substr) => {
      const email = substr.match(EMAIL_REG);

      if (email?.length || substr) {
        res.push(email ? email[0] : substr);
      }

      return res;
    }, []) || text;

export { extractEmails, isValidEmail };
