import { capitalize } from 'voca';
import { createAction, showErrorMessage } from 'lib/utils';
import { translate } from 'lib/intl';
import { integrationService } from 'services/integrations';
import userActionTypes from 'rdx/actionTypes';

const {
  CREATE_INTEGRATION_KEY_REQUEST,
  CREATE_INTEGRATION_KEY_SUCCESS,
  CREATE_INTEGRATION_KEY_FAILURE,

  UPDATE_INTEGRATION_KEY_REQUEST,
  UPDATE_INTEGRATION_KEY_SUCCESS,
  UPDATE_INTEGRATION_KEY_FAILURE,

  REMOVE_INTEGRATION_KEY_REQUEST,
  REMOVE_INTEGRATION_KEY_SUCCESS,
  REMOVE_INTEGRATION_KEY_FAILURE,
} = userActionTypes;

const createIntegrationKey =
  (ownerOrgPubId, integrationName, integrationId, data) => async (dispatch) => {
    try {
      dispatch(createAction({ type: CREATE_INTEGRATION_KEY_REQUEST }));
      // eslint-disable-next-line max-len
      await integrationService.createIntegrationKey(
        ownerOrgPubId,
        integrationName,
        integrationId,
        data
      );
      // eslint-disable-next-line max-len
      dispatch(
        createAction({
          type: CREATE_INTEGRATION_KEY_SUCCESS,
          data: { [integrationName]: { ...data } },
        })
      );
    } catch (err) {
      showErrorMessage({
        message: `${translate('INTEGRATIONS_GENERIC_ERROR_MESSAGE')} ${
          err?.message
            ? `${capitalize(integrationName)} says: "${err.message}"`
            : ''
        }`,
      });
      dispatch(
        createAction({ type: CREATE_INTEGRATION_KEY_FAILURE, error: err })
      );
    }
  };

const removeIntegrationKey =
  (ownerOrgPubId, integrationName, integrationId) => async (dispatch) => {
    try {
      dispatch(createAction({ type: REMOVE_INTEGRATION_KEY_REQUEST }));
      await integrationService.removeIntegrationKey(
        ownerOrgPubId,
        integrationName,
        integrationId
      );
      dispatch(
        createAction({
          type: REMOVE_INTEGRATION_KEY_SUCCESS,
          data: integrationName,
        })
      );
    } catch (err) {
      showErrorMessage({
        message: `${translate('INTEGRATIONS_GENERIC_ERROR_MESSAGE')} ${
          err?.message
            ? `${capitalize(integrationName)} says: "${err.message}"`
            : ''
        }`,
      });
      dispatch(createAction({ type: REMOVE_INTEGRATION_KEY_FAILURE }));
    }
  };

const updateIntegrationKey =
  (ownerOrgPubId, integrationName, integrationId, data) => async (dispatch) => {
    try {
      dispatch(createAction({ type: UPDATE_INTEGRATION_KEY_REQUEST }));
      // eslint-disable-next-line max-len
      await integrationService.updateIntegrationKey(
        ownerOrgPubId,
        integrationName,
        integrationId,
        data
      );
      // eslint-disable-next-line max-len
      dispatch(
        createAction({
          type: UPDATE_INTEGRATION_KEY_SUCCESS,
          data: { [integrationName]: { ...data } },
        })
      );
    } catch (err) {
      showErrorMessage({
        message: `${translate('INTEGRATIONS_GENERIC_ERROR_MESSAGE')} ${
          err?.message
            ? `${capitalize(integrationName)} says: "${err.message}"`
            : ''
        }`,
      });
      dispatch(createAction({ type: UPDATE_INTEGRATION_KEY_FAILURE }));
    }
  };

export const integrationActions = {
  createIntegrationKey,
  removeIntegrationKey,
  updateIntegrationKey,
};
