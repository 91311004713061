import userActionTypes from 'rdx/actionTypes';

const {
  FETCH_CAMPAIGNS_REQUEST,
  FETCH_CAMPAIGNS_SUCCESS,
  FETCH_CAMPAIGNS_FAILURE,

  REMOVE_CAMPAIGN_SUCCESS,
  UPDATE_MULTIPLE_CAMPAIGNS_SUCCESS,
} = userActionTypes;

const initialState = {
  dataLoaded: false,
  isFetching: false,
  error: null,
  data: [],
  page: null,
};

export function campaigns(state = initialState, action) {
  switch (action.type) {
    case FETCH_CAMPAIGNS_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: null,
      };
    case FETCH_CAMPAIGNS_SUCCESS: {
      let { data } = action;

      // If there is page in the action, then we're lazy loading data so we
      // need to add it.
      // If there is a state.page, then we're lazy loading data so we need to
      // add it.
      // What can happen is that if we hit the limit of the data that is
      // available for return, then action.page will be null. In that event, we
      // know that there was a page from the previous fetch (which is
      // state.page) and we are now hitting the limit.
      if (state.page || action.page) {
        data = [...state.data, ...action.data];
      }

      return {
        ...state,
        data,
        dataLoaded: true,
        isFetching: false,
        error: null,
        page: action.page,
      };
    }
    case FETCH_CAMPAIGNS_FAILURE:
      return {
        ...state,
        isFetching: false,
        dataLoaded: false,
        error: action.error,
      };
    case REMOVE_CAMPAIGN_SUCCESS:
      return {
        ...state,
        data: state.data.filter(
          ({ campaignId }) => campaignId !== action.campaignId
        ),
      };
    case UPDATE_MULTIPLE_CAMPAIGNS_SUCCESS:
      return {
        ...state,
        data: state.data.reduce((acc, campaign) => {
          let updatedCampaign = campaign;

          if (campaign.campaignId === action.campaignId) {
            updatedCampaign = action.data;
          }

          acc.push(updatedCampaign);

          return acc;
        }, []),
      };
    default:
      return state;
  }
}
