import React from 'react';
import { Box, Typography, makeStyles } from '@material-ui/core';
import { func, bool } from 'prop-types';
import { EMPTY_FUNC } from 'lib/constants';
import { translate } from 'lib/intl';
import { colors } from 'styles/theme';
import { TurnedIn, TurnedInNot } from '@material-ui/icons';

const useStyles = makeStyles(() => ({
  container: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    padding: '0 16px',
  },
  title: {
    flexGrow: 1,
  },
  label: {
    marginRight: '16px',
  },
  icon: {
    color: colors.primaryGreen,
    cursor: 'pointer',
  },
}));

const ConstituentCardItemActionsMovePrioritize = ({
  isPrioritized,
  onClick,
}) => {
  const styles = useStyles();

  const RenderPrioritizationBanner = () => {
    if (isPrioritized) {
      return (
        <TurnedIn
          data-testid="constituent-card-item-move-prioritized"
          onClick={onClick}
          className={styles.icon}
        />
      );
    }

    return (
      <TurnedInNot
        data-testid="constituent-card-item-move-unprioritized"
        onClick={onClick}
        className={styles.icon}
      />
    );
  };

  return (
    <Box className={styles.container}>
      <Typography className={styles.title} variant="subtitle1">
        {translate('DONOR_CARD_ACTION_MOVE_COLUMN_POSITION')}
      </Typography>
      <Typography className={styles.label} variant="body1">
        {translate('DONOR_CARD_ACTION_MOVE_FIRST')}
      </Typography>
      <RenderPrioritizationBanner />
    </Box>
  );
};

ConstituentCardItemActionsMovePrioritize.propTypes = {
  isPrioritized: bool,
  onClick: func,
};

ConstituentCardItemActionsMovePrioritize.defaultProps = {
  isPrioritized: false,
  onClick: EMPTY_FUNC,
};

export default ConstituentCardItemActionsMovePrioritize;
