import {
  CAMPAIGN_GOALS,
  CAMPAIGN_EVENT_TYPES,
  CAMPAIGN_DEFAULT_REQ_FIELDS,
  CAMPAIGN_EVENT_REQ_FIELDS,
  CAMPAIGN_IN_KIND_REQ_FIELDS,
} from 'lib/configs/campaigns';

const validateCampaignFormData = (formData) => {
  const errors = {};

  const { category, goalType } = formData;

  let requiredFields = CAMPAIGN_DEFAULT_REQ_FIELDS;

  // for events like campaigns
  if (CAMPAIGN_EVENT_TYPES.includes(category)) {
    requiredFields = requiredFields.concat(CAMPAIGN_EVENT_REQ_FIELDS);
  }

  // for in kind campaigns
  if (goalType === CAMPAIGN_GOALS[1].value) {
    requiredFields = requiredFields.concat(CAMPAIGN_IN_KIND_REQ_FIELDS);
  }

  requiredFields.forEach((field) => {
    const val = formData[field];

    if (val?.length === 0 || (!val && val !== 0)) {
      errors[field] = true;
    }
  });

  return errors;
};

export { validateCampaignFormData };
