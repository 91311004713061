/*
  This component is currently set up to support USD currency, it could be adapted though
*/

import React from 'react';
import propTypes from 'prop-types';
import { kebabCase } from 'voca';
import { values } from 'rambdax';
import NumberFormat from 'react-number-format';
import TextField from '@material-ui/core/TextField';
import { EMPTY_FUNC, NUMBER_FORMAT_RETURNED_VALUES } from 'lib/constants';

const CurrencyInput = ({
  label,
  amount,
  setAmount,
  error,
  errorHelperText,
  required,
  returnedValue,
}) => {
  const handleChange = (res) => setAmount(res[returnedValue]);

  // Select the entire value on focus
  const handleFocus = (event) => event.target.select();

  const id = `currency-input-${kebabCase(label)}`;

  return (
    <NumberFormat
      id={id}
      data-testid={id}
      tabIndex={0}
      label={label}
      fullWidth
      customInput={TextField}
      thousandSeparator
      value={amount}
      prefix="$"
      onValueChange={handleChange}
      error={error}
      helperText={error ? errorHelperText : ''}
      fixedDecimalScale
      decimalScale={2}
      onFocus={handleFocus}
      required={required}
      inputProps={{ inputMode: 'decimal' }}
    />
  );
};

CurrencyInput.propTypes = {
  amount: propTypes.oneOfType([propTypes.number, propTypes.string]),
  setAmount: propTypes.func,
  error: propTypes.bool,
  errorHelperText: propTypes.string,
  label: propTypes.string,
  required: propTypes.bool,
  // check https://www.npmjs.com/package/react-number-format#values-object for more info
  returnedValue: propTypes.oneOf(values(NUMBER_FORMAT_RETURNED_VALUES)),
};

CurrencyInput.defaultProps = {
  amount: 0,
  setAmount: EMPTY_FUNC,
  error: false,
  errorHelperText: '',
  label: '',
  required: false,
  returnedValue: NUMBER_FORMAT_RETURNED_VALUES.FORMATTED,
};

export { CurrencyInput };
