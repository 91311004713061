import React from 'react';
import PropTypes from 'prop-types';
import { useMediaQuery, useTheme } from '@material-ui/core';
import { useCurrentUser } from 'lib/hooks';
import { SuperAdminLayout } from 'components';
import DesktopNavigation from './DesktopNavigation';
import MobileNavigation from './MobileNavigation';

const NavigationControl = ({ children }) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('sm'), { noSsr: true });
  const { isSuperAdmin } = useCurrentUser();
  let layout;

  if (matches) {
    layout = <DesktopNavigation>{children}</DesktopNavigation>;
  } else {
    layout = <MobileNavigation>{children}</MobileNavigation>;
  }

  if (isSuperAdmin) {
    return <SuperAdminLayout>{layout}</SuperAdminLayout>;
  }

  return layout;
};

NavigationControl.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

NavigationControl.defaultProps = {
  children: null,
};

export { NavigationControl };
