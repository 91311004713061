import {
  directMailIcon,
  socialIcon,
  textIcon,
  phoneIcon,
  videoIcon,
  inPersonIcon,
  emailIcon,
} from 'assets/images';
import { translate } from 'lib/intl';

const INTERACTION_METHOD_FIELD_CONFIG = [
  {
    value: 'email',
    icon: emailIcon,
    text: translate('INTERACTION_METHOD_EMAIL'),
  },
  {
    value: 'in_person',
    icon: inPersonIcon,
    text: translate('INTERACTION_METHOD_IN_PERSON'),
  },
  {
    value: 'video',
    icon: videoIcon,
    text: translate('INTERACTION_METHOD_VIDEO_CALL'),
  },
  {
    value: 'phone',
    icon: phoneIcon,
    text: translate('INTERACTION_METHOD_PHONE_CALL'),
  },
  {
    value: 'text',
    icon: textIcon,
    text: translate('INTERACTION_METHOD_TEXT'),
  },
  {
    value: 'social',
    icon: socialIcon,
    text: translate('INTERACTION_METHOD_SOCIAL'),
  },
  {
    value: 'direct_mail',
    icon: directMailIcon,
    text: translate('INTERACTION_METHOD_DIRECT_MAIL'),
  },
];

const isFieldValid = (data) => data && data?.value !== '';

const INTERACTION_FIELD_CONFIG = {
  selectedDonor: {
    key: 'selectedDonor',
    isValid: isFieldValid,
  },
  selectedDate: {
    key: 'selectedDate',
    isRequired: true,
  },
  interactionMethod: {
    key: 'interactionMethod',
    isValid: isFieldValid,
  },
  narrative: {
    key: 'narrative',
  },
  employeesInvolved: {
    key: 'employeesInvolved',
    isValid: (items) => items.every(isFieldValid),
  },
  experienceRating: {
    key: 'experienceRating',
    isValid: Number.isInteger,
  },
  // TODO add required validation once API is ready
  campaign: {
    key: 'campaign',
  },
};

export { INTERACTION_FIELD_CONFIG, INTERACTION_METHOD_FIELD_CONFIG };
