import {
  TEAM_MEMBERS_COLUMNS,
  INVITES_COLUMNS,
  TeamMemberCard,
  InviteMemberCard,
} from 'components';
import { translate } from 'lib/intl';
import { AUTH_ROUTES, COMPANY_PAGE_TABS_ENUMS } from 'lib/constants';

const CONPANY_PAGE_TABS = [
  {
    id: COMPANY_PAGE_TABS_ENUMS.TEAM_MEMBERS,
    label: translate('COMPANY_TEAM_MEMBERS_LABEL'),
    location: AUTH_ROUTES.COMPANY_TEAM,
  },
  {
    id: COMPANY_PAGE_TABS_ENUMS.INVITES,
    label: translate('COMPANY_INVITES_LABEL'),
    location: AUTH_ROUTES.COMPANY_INVITES,
  },
];

const TEAM_MEMBERS_SORT_OPTIONS = [
  {
    value: 'displayName',
    label: translate('COMPANY_TEAM_MEMBER_NAME_LABEL'),
  },
  // {
  //   value: 'lastVisit',
  //   label: translate('COMPANY_TEAM_MEMBER_LAST_VISIT_LABEL'),
  // },
  {
    value: 'group',
    label: translate('COMPANY_TEAM_MEMBER_PERMISSIONS_LABEL'),
  },
];

const INVITES_SORT_OPTIONS = [
  {
    value: 'status',
    label: translate('COMPANY_TEAM_MEMBER_STATUS_LABEL'),
  },
  {
    value: 'email',
    label: translate('COMPANY_TEAM_MEMBER_EMAIL_LABEL'),
  },
  {
    value: 'permissions',
    label: translate('COMPANY_TEAM_MEMBER_PERMISSIONS_LABEL'),
  },
];

const CONPANY_PAGE_TAB_IDX_MAPPER = [
  [AUTH_ROUTES.COMPANY, AUTH_ROUTES.COMPANY_TEAM],
  [AUTH_ROUTES.COMPANY_INVITES],
];

const COMPANY_TEAM_MEMBER_CONFIG = {
  key: COMPANY_PAGE_TABS_ENUMS.TEAM_MEMBERS,
  CardComponent: TeamMemberCard,
  columns: TEAM_MEMBERS_COLUMNS,
  idField: 'userId',
};

const COMPANY_INVITES_CONFIG = {
  key: COMPANY_PAGE_TABS_ENUMS.INVITES,
  CardComponent: InviteMemberCard,
  columns: INVITES_COLUMNS,
  idField: 'inviteId',
};

const SORT_PICKER_MOBILE_LENGTH = '182px';

export {
  CONPANY_PAGE_TABS,
  CONPANY_PAGE_TAB_IDX_MAPPER,
  COMPANY_TEAM_MEMBER_CONFIG,
  COMPANY_INVITES_CONFIG,
  TEAM_MEMBERS_SORT_OPTIONS,
  INVITES_SORT_OPTIONS,
  SORT_PICKER_MOBILE_LENGTH,
};
