import minutesToHours from 'date-fns/minutesToHours';
import { translate } from 'lib/intl';

/**
 * formats donation time
 * @param {number} value - a numeric value
 * @return {string} - formatted donation time
 */
export const formatDonationTime = (value) => {
  const hours = minutesToHours(value);
  const postfix = translate(hours <= 1 ? 'HOUR' : 'HOURS');

  return `${hours} ${postfix}`;
};
