export const GOALS = {
  GOALS_ADD: 'Add Goal',
  GOALS_EDIT: 'Edit',
  GOALS_DELETE: 'Delete',
  GOALS_CANCEL: 'Cancel',
  GOALS_SAVE: 'Save',
  GOALS_SET: 'Set Your Annual Goal - {year}',
  GOALS_NAME: 'Goal Name',
  GOALS_NAME_ERROR_REQUIRED: 'Goal Name Required',
  GOALS_TOTAL: 'Goal Total',
  GOALS_TOTAL_ERROR_REQUIRED: 'Goal Total Required',
  GOALS_DURATION: 'Duration',
  GOALS_DURATION_ERROR_REQUIRED: 'Duration Required',
  GOALS_START_DATE: 'Start Date',
  GOALS_END_DATE: 'End Date',
  GOALS_DURATION_MONTHLY: 'Monthly',
  GOALS_DURATION_QUARTERLY: 'Quarterly',
  GOALS_DURATION_YEARLY: 'Yearly',
  GOALS_DURATION_CUSTOM: 'Custom',
  ANNUAL_GOAL_TITLE: 'Annual Goal',
};
