import React from 'react';
import { string } from 'prop-types';
import { DONATION_VALUE_OF_TIME_CONFIG } from 'lib/configs/donations';

const TimeValue = ({ value }) => {
  const config = DONATION_VALUE_OF_TIME_CONFIG.find(
    (donationConfig) => donationConfig.value === Number(value)
  );

  if (!config) {
    return null;
  }

  const { icon, iconAlt, text } = config;

  return (
    <span>
      <img
        alt={iconAlt}
        className="relative mr-1 -top-0.5 inline-block"
        src={icon}
      />
      <span>{text}</span>
    </span>
  );
};

TimeValue.propTypes = {
  value: string.isRequired,
};

export default TimeValue;
