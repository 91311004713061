import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { orgInfoActions, visitStreakActions } from 'rdx/actions';
import {
  formatCurrencyInputToCents,
  convertCentsToDollars,
} from 'lib/utils/currency';
import { translate } from 'lib/intl';
import { DEFAULT_GOALS_DURATION_ENUM } from 'lib/constants';

const useGoals = () => {
  const { errors, orgInfoData, isFetching, isUpdating, ownerOrgId } =
    useSelector((state) => ({
      errors: state.orgInfo?.errors,
      orgInfoData: state.orgInfo?.data || {},
      isFetching: state.orgInfo?.isFetching,
      isUpdating: state.updatingOrgInfo?.isFetching,
      ownerOrgId: state.authentication?.ownerOrgId,
    }));

  const { goals = [], summaryMetrics } = orgInfoData;

  return {
    errors,
    goals,
    summaryMetrics,
    isFetching,
    isUpdating,
    ownerOrgId,
  };
};

const useGoalForm = (existingGoal, closeForm) => {
  const dispatch = useDispatch();
  const [goalTotal, setGoalTotal] = useState(
    convertCentsToDollars(existingGoal?.target)
  );
  const [hasError, setHasError] = useState(false);

  const { errors, goals, isFetching, isUpdating, ownerOrgId } = useSelector(
    (state) => ({
      errors: state.orgInfo?.errors,
      goals: state.orgInfo?.data?.goals || [],
      isFetching: state.orgInfo?.isFetching,
      isUpdating: state.updatingOrgInfo?.isFetching,
      ownerOrgId: state.authentication?.ownerOrgId,
    })
  );

  const handleSave = () => {
    const total = formatCurrencyInputToCents(goalTotal);

    if (total !== 0) {
      const updatedGoal = {
        durationEnum: DEFAULT_GOALS_DURATION_ENUM,
        name: translate('ANNUAL_GOAL_TITLE'),
        target: total,
      };

      dispatch(
        orgInfoActions.updateOrgInfo(ownerOrgId, {
          goals: [updatedGoal],
        })
      );

      closeForm();
    } else {
      setHasError(true);
    }
  };

  const handleDelete = () => {
    dispatch(
      orgInfoActions.updateOrgInfo(ownerOrgId, {
        goals: [],
      })
    );

    closeForm();
  };

  return {
    goals,
    goalTotal,
    setGoalTotal,
    errors,
    handleSave,
    handleDelete,
    hasError,
    isUpdating,
    isFetching,
  };
};

const useVisitStreak = () => {
  const dispatch = useDispatch();

  const {
    visitStreakText,
    visitStreakEncouragementText,
    visitStreakIsFetching,
  } = useSelector((state) => ({
    visitStreakText: state.visitStreak?.visitStreakText,
    visitStreakEncouragementText:
      state.visitStreak?.visitStreakEncouragementText,
    visitStreakIsFetching: state.visitStreak?.isFetching,
  }));

  useEffect(() => {
    dispatch(visitStreakActions.getVisitStreak());
  }, []);

  return {
    visitStreakText,
    visitStreakEncouragementText,
    visitStreakIsFetching,
  };
};

const useUpdateVisitStreak = () => {
  const dispatch = useDispatch();
  const isAuthenticated = useSelector(
    (state) => !!state.authentication?.isAuthenticated
  );

  useEffect(() => {
    if (isAuthenticated) {
      dispatch(visitStreakActions.updateVisitStreak());
    }
  }, []);
};

export { useGoalForm, useGoals, useUpdateVisitStreak, useVisitStreak };
