import userActionTypes from 'rdx/actionTypes';

const {
  CREATE_CAMPAIGN_REQUEST,
  CREATE_CAMPAIGN_SUCCESS,
  CREATE_CAMPAIGN_FAILURE,

  UPDATE_CAMPAIGN_REQUEST,
  UPDATE_CAMPAIGN_SUCCESS,
  UPDATE_CAMPAIGN_FAILURE,
  UPDATE_CAMPAIGN_RESET,
} = userActionTypes;

const initialState = {
  dataLoaded: false,
  isFetching: false,
  error: null,
  data: {},
};

export function updateCampaign(state = initialState, action) {
  switch (action.type) {
    case CREATE_CAMPAIGN_REQUEST:
    case UPDATE_CAMPAIGN_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: null,
      };
    case CREATE_CAMPAIGN_SUCCESS:
    case UPDATE_CAMPAIGN_SUCCESS:
      return {
        ...state,
        data: action.data,
        dataLoaded: true,
        isFetching: false,
        error: null,
      };
    case CREATE_CAMPAIGN_FAILURE:
    case UPDATE_CAMPAIGN_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.error,
      };
    case UPDATE_CAMPAIGN_RESET:
      return initialState;
    default:
      return state;
  }
}
