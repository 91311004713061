export const COMPANY = {
  COMPANY_TITLE: 'Company',
  COMPANY_TEAM_MEMBERS_LABEL: 'Team Members',
  COMPANY_INVITES_LABEL: 'Invites',
  COMPANY_EDIT_LABEL: 'Edit Org',
  COMPANY_ADD_EMPLOYEE_ID_LABEL: 'Add Employee ID',
  COMPANY_ADD_PHONE_NUMBER_LABEL: 'Add Phone Number',
  COMPANY_ADD_ADDRESS_LABEL: 'Add Address',
  COMPANY_ADD_WEBSITE_LABEL: 'Add Website',
  COMPANY_EDIT_PROFILE_LABEL: 'Edit Org Profile',
  COMPANY_FORM_FIX_ERRORS_MESSAGE: 'Fix errors to save the data',
  COMPANY_NAME_LABEL: 'Company Name',
  COMPANY_EMPLOYEE_ID_LABEL: 'Employee ID Number',
  COMPANY_PHONE_NUMBER_LABEL: 'Phone Number',
  COMPANY_WEBSITE_LABEL: 'Org Website',
  COMPANY_INFO_UPDATED_LABEL: 'Company Info Updated!',
  COMPANY_INFO_UPDATED_MESSAGE: 'Company Info was successfully updated',
  COMPANY_TEAM_MEMBER_NAME_LABEL: 'Team Member',
  COMPANY_TEAM_MEMBER_FIRST_NAME_LABEL: 'First',
  COMPANY_TEAM_MEMBER_LAST_NAME_LABEL: 'Last',
  COMPANY_TEAM_MEMBER_LAST_VISIT_LABEL: 'Last Visit',
  COMPANY_TEAM_MEMBER_EMAIL_LABEL: 'Email',
  COMPANY_TEAM_MEMBER_PERMISSIONS_LABEL: 'Permissions',
  COMPANY_TEAM_MEMBER_STATUS_LABEL: 'Status',
  COMPANY_SEARCH_LABEL: 'Find Team member',
  COMPANY_RESEND_LABEL: 'Resend',
  COMPANY_INVITE_BTN_LABEL: 'Invite Team Member',
  COMPANY_SEND_INVITES_MODAL_DESCRIPTION:
    'Set the permission type and add your teammates emails below.',
  COOMPANY_PAGE_INVITE_TEAM_LABEL: 'Invite your team',
  COMPANY_PAGE_INVITE_TEAM_PLACEHOLDER:
    'Copy and paste or type your teammates emails here.',
  COMPANY_PAGE_PERMISSION_TYPE_LABEL: 'Permission type',
  COMPANY_PAGE_NO_INVITES_LABEL: 'You have no pending invites',
  COMPANY_PAGE_INVITE_SENT_LABEL: 'Invite Sent!',
  COMPANY_PAGE_INVITE_SENT_MESSAGE:
    'Have your teammates check their email for the link to join.',
  COMPANY_INVITE_FORM_FIX_EMAILS_MESSAGE: 'Fix emails to send invites',
  COMPANY_INVITE_UPDATE_INVITE_ERROR_MESSAGE:
    'There was a problem updating the invite of {email}',
  COMPANY_INVITE_REMOVE_INVITE_ERROR_MESSAGE:
    'There was a problem removing the invite of {email}',
  COMPANY_INVITE_RESEND_INVITE_ERROR_MESSAGE:
    'There was a problem resending an invite to {email}',
  COMPANY_UPDATE_MEMBER_ERROR_MESSAGE: 'There was a problem updating {name}',
  COMPANY_REMOVE_MEMBER_ERROR_MESSAGE: 'There was a problem removing {name}',
  COMPANY_FETCH_INVITES_ERROR_MESSAGE: 'There was a problem fetching invites',
  COMPANY_FETCH_TEAM_MEMBERS_ERROR_MESSAGE:
    'There was a problem fetching team members',
  COMPANY_FETCH_COMPANY_INFO_ERROR_MESSAGE:
    'There was a problem fetching the company info',
  CONFIRM_TEAM_MEMBER_REMOVAL_MESSAGE:
    'Are you sure you want to deactivate  this user?',
  CONFIRM_INVITE_REMOVAL_MESSAGE:
    'Are you sure you want to remove this invite?',
  CLEAR_ALL_EMAILS: 'Clear All',
};
