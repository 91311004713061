/**
 * This is a "controller" TextSelectField component, made to work with
 * react-hooks-form.
 */
import React from 'react';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';
import TextSelectField from './TextSelectField';

export default function ControlledTextSelectField({
  control,
  name,
  defaultValue,
  ...props
}) {
  return (
    <Controller
      {...(defaultValue && { defaultValue })}
      control={control}
      name={name}
      render={({ onChange, value }) => {
        return (
          <TextSelectField
            {...{
              ...props,
              ...(value && { value }),
              setValue: onChange,
            }}
          />
        );
      }}
    />
  );
}

ControlledTextSelectField.propTypes = {
  name: PropTypes.string.isRequired,
  ...TextSelectField.propTypes,
};
