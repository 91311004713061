import { createAction, showSuccessMessage } from 'lib/utils';
import { translate } from 'lib/intl';
import { announcementsService } from 'services/announcements';
import userActionTypes from 'rdx/actionTypes';

const {
  CREATE_ANNOUNCEMENT_REQUEST,
  CREATE_ANNOUNCEMENT_SUCCESS,
  CREATE_ANNOUNCEMENT_FAILURE,
  CREATE_ANNOUNCEMENT_RESET,
} = userActionTypes;

const createAnnouncement = (ownerOrgId, data, isGlobal) => async (dispatch) => {
  try {
    dispatch(createAction({ type: CREATE_ANNOUNCEMENT_REQUEST }));

    let response;

    if (isGlobal) {
      const dataCopy = { ...data, ...{ globalType: 'all_orgs' } };

      response = await announcementsService.createDotOrgAnnouncement(dataCopy);
    } else {
      response = await announcementsService.createAnnouncement(
        ownerOrgId,
        data
      );
    }

    dispatch(
      createAction({
        type: CREATE_ANNOUNCEMENT_SUCCESS,
        data: response?.data || {},
      })
    );

    showSuccessMessage({
      message: translate(
        isGlobal
          ? 'GLOBAL_ANNOUNCEMENT_ADDED_MESSAGE'
          : 'ANNOUNCEMENT_ADDED_MESSAGE'
      ),
    });
  } catch (error) {
    dispatch(
      createAction({
        type: CREATE_ANNOUNCEMENT_FAILURE,
        error,
      })
    );
  }
};

const resetAnnouncement = () => async (dispatch) => {
  dispatch(createAction({ type: CREATE_ANNOUNCEMENT_RESET }));
};

export const announcementActions = {
  createAnnouncement,
  resetAnnouncement,
};
