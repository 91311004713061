import { arrayOf, bool, func, number, oneOf, string } from 'prop-types';
import { values } from 'rambdax';
import {
  GeneralCategoryIcon,
  CrowdfundingCategoryIcon,
  PeerToPeerCategoryIcon,
  EventCategoryIcon,
  RegistrationCategoryIcon,
  MonetaryGoalIcon,
  InKindGoalIcon,
  editProfilePencil,
  linkedPaperclips,
  checkMark,
  cardDividers,
  activateIcon,
  trashcanIcon,
} from 'assets/images';
import {
  CAMPAIGN_TYPE_ENUM,
  CAMPAIGN_GOAL_TYPE_ENUM,
  CAMPAIGN_STATUS_ENUM,
  CAMPAIGN_MAIN_TYPE,
} from 'lib/constants';
import { TextSelectFieldValueType } from 'lib/utils';
import { translate } from 'lib/intl';

const CAMPAIGN_CATEGORIES = [
  {
    value: CAMPAIGN_TYPE_ENUM.GENERAL,
    icon: GeneralCategoryIcon,
    text: translate('CAMPAIGN_GENERAL_LABEL'),
  },
  {
    value: CAMPAIGN_TYPE_ENUM.CROWDFUNDING,
    icon: CrowdfundingCategoryIcon,
    text: translate('CAMPAIGN_CROWDFUNDING_LABEL'),
  },
  {
    value: CAMPAIGN_TYPE_ENUM.PEER_TO_PEER,
    icon: PeerToPeerCategoryIcon,
    text: translate('CAMPAIGN_PEER_TO_PEER_LABEL'),
  },
  {
    value: CAMPAIGN_TYPE_ENUM.EVENT,
    icon: EventCategoryIcon,
    text: translate('CAMPAIGN_EVENT_LABEL'),
  },
  {
    value: CAMPAIGN_TYPE_ENUM.REGISTRATION,
    icon: RegistrationCategoryIcon,
    text: translate('CAMPAIGN_REGISTRATION_LABEL'),
  },
];

const CAMPAIGN_GOALS = [
  {
    value: CAMPAIGN_GOAL_TYPE_ENUM.MONETARY,
    icon: MonetaryGoalIcon,
    text: translate('CAMPAIGN_MONETARY_LABEL'),
  },
  {
    value: CAMPAIGN_GOAL_TYPE_ENUM.IN_KIND,
    icon: InKindGoalIcon,
    text: translate('CAMPAIGN_IN_KIND_LABEL'),
  },
];

const INIT_CAMPAIGN_PROPS = {
  campaignType: CAMPAIGN_MAIN_TYPE,
  name: '',
  displayName: '',
  description: '',
  url: '',
  category: CAMPAIGN_CATEGORIES[0].value,
  goalType: CAMPAIGN_GOALS[0].value,
  assignees: [],
  goalAmount: 0,
  stretchAmount: 0,
  ticketPrice: 0,
  itemValue: 0,
};

const SUMMARY_METRICS_DEFAULT_PROPS = {
  avgDonation: 0,
  avgDonationPerDonor: 0,
  maxDonation: 0,
  numDonations: 0,
  numNewDonors: 0,
  numReturningDonors: 0,
  totalAmount: 0,
  numDonorConstituents: 0,
};

const DEFAULT_CAMPAIGN_PROPS = {
  ...INIT_CAMPAIGN_PROPS,
  campaignId: '',
  startDate: '',
  endDate: '',
  expectedProgress: 0,
  location: '',
  date: '',
  ticketsSold: 0,
  itemType: '',
  itemQuantity: 0,
  primaryAssigneeId: '',
  status: CAMPAIGN_STATUS_ENUM.ACTIVE,
  updated: '',
  isPinned: false,
  ...SUMMARY_METRICS_DEFAULT_PROPS,
};

const CampaignType = {
  campaignId: string,
  campaignType: string,
  name: string.isRequired,
  displayName: string,
  description: string,
  url: string,
  category: oneOf(values(CAMPAIGN_TYPE_ENUM)),
  goalType: oneOf(values(CAMPAIGN_GOAL_TYPE_ENUM)),
  stretchAmount: number,
  startDate: string,
  endDate: string,
  location: string,
  date: string,
  ticketPrice: number,
  ticketsSold: number,
  itemType: string,
  itemQuantity: number,
  itemValue: number,
  assignees: arrayOf(TextSelectFieldValueType),
  primaryAssigneeId: string,
  status: oneOf(values(CAMPAIGN_STATUS_ENUM)),
  goalMet: bool,
  updated: string,
  // statistics
  expectedProgress: number,
  goalAmount: number,
  avgDonation: number,
  avgDonationPerDonor: number,
  maxDonation: number,
  numDonations: number,
  numNewDonors: number,
  numReturningDonors: number,
  totalAmount: number,
  numDonorConstituents: number,
  isPinned: bool,
};

const CardType = {
  ...CampaignType,
  isLoading: bool,
  onClick: func.isRequired,
  openCampaignModal: func.isRequired,
  removeCampaign: func.isRequired,
  updateCampaign: func.isRequired,
};

const CampaignCardDefaultProps = {
  ...DEFAULT_CAMPAIGN_PROPS,
  isLoading: false,
};

const CAMPAIGN_EVENT_TYPES = [
  CAMPAIGN_TYPE_ENUM.EVENT,
  CAMPAIGN_TYPE_ENUM.REGISTRATION,
];

const CAMPAIGN_DEFAULT_REQ_FIELDS = ['name', 'goalAmount', 'assignees'];

const CAMPAIGN_EVENT_REQ_FIELDS = ['location', 'ticketPrice', 'ticketsSold'];

const CAMPAIGN_IN_KIND_REQ_FIELDS = ['itemType', 'itemQuantity', 'itemValue'];

const CAMPAIGN_ACTIONS_CONFIG = [
  {
    key: 'edit',
    icon: editProfilePencil,
    text: translate('CAMPAIGNS_EDIT_ACTION_LABEL'),
    handlerName: 'onEditClick',
  },
  {
    key: 'duplicate',
    icon: linkedPaperclips,
    text: translate('CAMPAIGNS_DUPLICATE_ACTION_LABEL'),
    handlerName: 'onDuplicateClick',
  },
  {
    key: 'complete',
    icon: checkMark,
    text: translate('CAMPAIGNS_COMPLETE_ACTION_LABEL'),
    hideFor: [CAMPAIGN_STATUS_ENUM.COMPLETED, CAMPAIGN_STATUS_ENUM.ARCHIVED],
    handlerName: 'onCompleteClick',
  },
  {
    key: 'archive',
    icon: cardDividers,
    text: translate('CAMPAIGNS_ARCHIVE_ACTION_LABEL'),
    hideFor: [CAMPAIGN_STATUS_ENUM.ARCHIVED],
    handlerName: 'onArchiveClick',
  },
  {
    key: 'activate',
    icon: activateIcon,
    text: translate('CAMPAIGNS_ACTIVATE_ACTION_LABEL'),
    hideFor: [CAMPAIGN_STATUS_ENUM.ACTIVE],
    handlerName: 'onActivateClick',
  },
  {
    key: 'delete',
    icon: trashcanIcon,
    text: translate('CAMPAIGNS_DELETE_ACTION_LABEL'),
    handlerName: 'onDeleteClick',
  },
];

export {
  INIT_CAMPAIGN_PROPS,
  DEFAULT_CAMPAIGN_PROPS,
  SUMMARY_METRICS_DEFAULT_PROPS,
  CAMPAIGN_CATEGORIES,
  CAMPAIGN_GOALS,
  CampaignType,
  CardType,
  CampaignCardDefaultProps,
  CAMPAIGN_EVENT_TYPES,
  CAMPAIGN_DEFAULT_REQ_FIELDS,
  CAMPAIGN_EVENT_REQ_FIELDS,
  CAMPAIGN_IN_KIND_REQ_FIELDS,
  CAMPAIGN_ACTIONS_CONFIG,
};
