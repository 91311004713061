import { createApiRequest } from 'lib/utils';
import { snakeCaseKeys } from 'lib/utils/transform-keys';

function buildPath({ apiType, connectionId, constituentId, ownerOrgId }) {
  let path = `orgs/${ownerOrgId}/constituents`;

  if (constituentId) {
    path = `${path}/${constituentId}`;
  }

  if (apiType) {
    path = `${path}/${apiType}`;
  }

  if (connectionId) {
    path = `${path}/${connectionId}`;
  }

  return path;
}

export const fetchConstituent = ({ ownerOrgId, constituentId }) =>
  createApiRequest({
    method: 'get',
    path: buildPath({ constituentId, ownerOrgId }),
    throwErrors: true,
  });

export const fetchConstituents = ({ ownerOrgId }) =>
  createApiRequest({
    method: 'get',
    path: buildPath({ ownerOrgId }),
    throwErrors: true,
  });

export const removeConnection = ({
  apiType,
  constituentId,
  connectionId,
  ownerOrgId,
}) =>
  createApiRequest({
    method: 'delete',
    path: buildPath({ apiType, constituentId, connectionId, ownerOrgId }),
    throwErrors: true,
  });

export const createConstituent = async ({
  apiType,
  constituentId,
  data,
  ownerOrgId,
}) => {
  const result = await createApiRequest({
    method: 'post',
    path: buildPath({ apiType, constituentId, ownerOrgId }),
    data,
    throwErrors: true,
  });

  return result;
};

export const searchConstituents = async ({ ownerOrgId, params = {} }) =>
  createApiRequest({
    method: 'get',
    path: buildPath({ ownerOrgId, apiType: 'search' }),
    config: { params: snakeCaseKeys({ ...params }) },
    throwErrors: true,
  });

export const updateConstituent = async ({
  apiType,
  connectionId,
  constituentId,
  data,
  ownerOrgId,
}) => {
  const result = await createApiRequest({
    method: 'put',
    path: buildPath({ apiType, connectionId, constituentId, ownerOrgId }),
    data,
    throwErrors: true,
  });

  return result;
};

export default {
  createConstituent,
  fetchConstituent,
  fetchConstituents,
  removeConnection,
  updateConstituent,
};
