import userActionTypes from 'rdx/actionTypes';

const {
  SENDING_INVITES_REQUEST,
  SENDING_INVITES_SUCCESS,
  SENDING_INVITES_FAILURE,
  SENDING_INVITES_RESET,
} = userActionTypes;

const initialState = {
  dataLoaded: false,
  isFetching: false,
  error: null,
  data: {},
};

export function sendingInvites(state = initialState, action) {
  switch (action.type) {
    case SENDING_INVITES_REQUEST:
      return {
        ...state,
        dataLoaded: false,
        isFetching: true,
        error: null,
      };
    case SENDING_INVITES_SUCCESS:
      return {
        ...state,
        data: action.data,
        dataLoaded: true,
        isFetching: false,
        error: null,
      };
    case SENDING_INVITES_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.error,
      };
    case SENDING_INVITES_RESET:
      return initialState;
    default:
      return state;
  }
}
