import { SUMMARY_METRICS_DEFAULT_PROPS } from 'lib/configs/campaigns';
import { formatTeamMembers } from './formatTeamMembers';

const mapCampaign = ({
  assignees,
  goals,
  events,
  summaryMetrics = SUMMARY_METRICS_DEFAULT_PROPS,
  ...props
}) => {
  const eventProps = events?.length && events[0];
  const goalProps = goals?.length && goals[0];

  return {
    ...props,
    ...eventProps,
    ...goalProps,
    ...summaryMetrics,
    assignees: formatTeamMembers(assignees),
  };
};

export { mapCampaign };
