import React, { useState } from 'react';
import {
  Grid,
  Typography,
  IconButton,
  OutlinedInput,
  InputLabel,
  InputAdornment,
  FormControl,
  makeStyles,
  TextField,
} from '@material-ui/core';
import { colors } from 'styles/theme';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import ClearIcon from '@material-ui/icons/Clear';
import { func, shape } from 'prop-types';
import { Check } from '@material-ui/icons';
import { translate } from 'lib/intl';
import { isValidPassword } from 'lib/utils';

const useStyles = makeStyles((theme) => ({
  container: {
    borderTop: `1px solid ${colors.lightGrey}`,
    padding: '24px',
    margin: '0px',
  },
  header: {
    fontWeight: '500',
    padding: '0px',
  },
  validationCaseContainer: {
    display: 'flex',
    flexWrap: 'nowrap',
  },
  typographySubTitleOverride: {
    fontSize: '10px',
    [theme.breakpoints.down('xs')]: {
      fontSize: '12px',
    },
  },
  // Custom styles taken from material
  errorStyles: {
    color: colors.errorMain,
    margin: '3px 14px 0',
    fontSize: '12px',
    textAlign: 'left',
    fontWeight: '400',
    lineHeight: '1.66',
    letterSpacing: '.25px',
  },
}));

const originalPasswordFieldValue = 'originalPassword';
const newPasswordFieldValue = 'password';

const EditEmployeeChangePassword = ({ register, errors, trigger }) => {
  const styles = useStyles();
  const [originalPassword, setOriginalPassword] = useState({
    value: '',
    showPassword: false,
  });
  const [password, setPassword] = useState({
    value: '',
    showPassword: false,
    hasCharacterCount: null,
    hasNumber: null,
    hasUpper: null,
    hasLower: null,
    hasSpecial: null,
  });

  const handleOriginalPasswordChange = (event) => {
    setOriginalPassword({
      ...originalPassword,
      value: event.target.value,
    });
  };

  const handleClickShowOriginalPassword = () => {
    setOriginalPassword({
      ...originalPassword,
      showPassword: !originalPassword.showPassword,
    });
  };

  // Password change validation and state handler
  const handlePasswordChange = (event) => {
    const updatedValue = event.target.value;

    // Handle empty password change condition
    if (!updatedValue) {
      setPassword({
        ...password,
        value: updatedValue,
        hasCharacterCount: null,
        hasNumber: null,
        hasUpper: null,
        hasLower: null,
        hasSpecial: null,
      });

      return true;
    }

    // Handle password form change with value
    const { hasCharacterCount, hasNumber, hasUpper, hasLower, hasSpecial } =
      isValidPassword(updatedValue);

    setPassword({
      ...password,
      value: updatedValue,
      hasCharacterCount,
      hasNumber,
      hasUpper,
      hasLower,
      hasSpecial,
    });

    return true;
  };

  const handleClickShowPassword = () => {
    setPassword({ ...password, showPassword: !password.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const renderPasswordValidationCase = (text, isValid) => {
    const iconStyles = { fontSize: '1rem', paddingRight: '4px' };

    // Pristine state
    let icon = <ClearIcon color="disabled" style={iconStyles} />;

    if (isValid) {
      icon = (
        <Check
          data-testid="password-condition-success"
          color="primary"
          style={iconStyles}
        />
      );
    } else if (isValid === false) {
      icon = (
        <ClearIcon
          data-testid="password-condition-error"
          style={{ ...iconStyles, color: 'red' }}
        />
      );
    }

    return (
      <Grid className={styles.validationCaseContainer} item container xs={6}>
        {icon}
        <Typography
          variant="caption"
          className={styles.typographySubTitleOverride}
        >
          {text}
        </Typography>
      </Grid>
    );
  };

  return (
    <Grid
      className={styles.container}
      item
      container
      spacing={2}
      display="flex"
      xs={12}
    >
      <Grid item xs={12}>
        <Typography className={styles.header} variant="subtitle1">
          {translate('EMPLOYEE_CHANGE_PASSWORD')}
        </Typography>
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          variant="outlined"
          name={originalPasswordFieldValue}
          id="outlined-adornment-original-password"
          fullWidth
          label={translate('EMPLOYEE_ORIGINAL_PASSWORD')}
          type={originalPassword.showPassword ? 'text' : 'password'}
          value={originalPassword.value}
          error={!!errors.originalPassword}
          onBlur={trigger}
          onChange={handleOriginalPasswordChange}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowOriginalPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {originalPassword.showPassword ? (
                    <Visibility />
                  ) : (
                    <VisibilityOff />
                  )}
                </IconButton>
              </InputAdornment>
            ),
          }}
          inputRef={register({
            validate: () => {
              const isNewPasswordProvided = Boolean(password.value);
              const isOriginalPasswordProvided = Boolean(
                originalPassword.value
              );

              // If new password is provided require the original password
              if (isNewPasswordProvided) {
                if (!isOriginalPasswordProvided) {
                  return 'Original password required';
                }

                return isOriginalPasswordProvided;
              }

              // If new password not provided, this field is not required
              return true;
            },
          })}
        />
        {/* Only show text for password required, custom icon error state shows the rest */}
        {errors && errors.originalPassword ? (
          <p
            data-testid="password-form-error-state"
            className={`MuiFormHelperText-root MuiFormHelperText-contained Mui-error ${styles.errorStyles}`}
          >
            {errors.originalPassword.message}
          </p>
        ) : null}
      </Grid>
      <Grid item xs={12} sm={6}>
        <FormControl fullWidth error={!!errors.password} variant="outlined">
          <InputLabel htmlFor="outlined-adornment-password">
            {translate('EMPLOYEE_NEW_PASSWORD')}
          </InputLabel>
          <OutlinedInput
            name={newPasswordFieldValue}
            id="outlined-adornment-password"
            fullWidth
            type={password.showPassword ? 'text' : 'password'}
            value={password.value}
            onBlur={trigger}
            onChange={handlePasswordChange}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {password.showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            }
            labelWidth={125}
            inputRef={register({
              validate: (value) => {
                // New password is not required
                if (!value) {
                  return true;
                }

                const { isValid } = isValidPassword(value);

                return isValid;
              },
            })}
          />
          {/* Only show text for password required, custom icon error state shows the rest */}
          {errors && errors.password && errors.password.type === 'required' ? (
            <p
              data-testid="password-form-error-state"
              className={`MuiFormHelperText-root MuiFormHelperText-contained Mui-error ${styles.errorStyles}`}
            >
              {errors.password.message}
            </p>
          ) : null}
        </FormControl>
        <Grid
          item
          container
          xs={12}
          style={{ paddingTop: '6px', display: 'flex' }}
        >
          {renderPasswordValidationCase(
            translate('PASSWORD_CHARACTER_LIMIT'),
            password.hasCharacterCount
          )}
          {renderPasswordValidationCase(
            translate('PASSWORD_CONTAINS_NUMBER'),
            password.hasNumber
          )}
          {renderPasswordValidationCase(
            translate('PASSWORD_CONTAINS_UPPERCASE'),
            password.hasUpper
          )}
          {renderPasswordValidationCase(
            translate('PASSWORD_CONTAINS_SYMBOL'),
            password.hasSpecial
          )}
          {renderPasswordValidationCase(
            translate('PASSWORD_CONTAINS_LOWERCASE'),
            password.hasLower
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

EditEmployeeChangePassword.propTypes = {
  register: func.isRequired,
  errors: shape({}).isRequired,
  trigger: func.isRequired,
};

export { EditEmployeeChangePassword };
