import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  TextField,
  Grid,
  FormHelperText,
  CircularProgress,
  Typography,
} from '@material-ui/core';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { Visible, CustomButton } from 'components';
import { congratulationIcon } from 'assets/images';
import { AUTH_ROUTES } from 'lib/constants';
import { translate } from 'lib/intl';
import {
  firstNameMaxLength,
  lastNameMaxLength,
  regAdminType,
} from './authConstants';

const useStyles = makeStyles((theme) => ({
  header: {
    paddingRight: '5px',
    [theme.breakpoints.down('xs')]: {
      textAlign: 'left',
      width: '100%',
    },
  },
  headerContainer: {
    direction: 'flex',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  },
  nameContainer: {
    display: 'flex',
    flexDirection: 'row',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  },
  firstNameItem: {
    marginRight: '2%',
    [theme.breakpoints.down('xs')]: {
      marginRight: '0',
      marginBottom: '24px',
    },
  },
  lastNameItem: {
    marginLeft: '2%',
    [theme.breakpoints.down('xs')]: {
      marginLeft: '0',
    },
  },
  formHelperText: {
    textAlign: 'center',
    marginTop: '20px',
    width: '100%',
  },
  imageWrap: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'center',
      padding: '10px 0',
    },
  },
  lowerFormContainer: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
  },
}));

const AdminRegistrationForm = ({
  isFetching,
  error,
  isRegistrationSuccessful,
  registerAction,
  resetRegistrationState,
  email,
}) => {
  const { register, handleSubmit, errors } = useForm({
    mode: 'onBlur',
  });
  const history = useHistory();
  const classes = useStyles();

  useEffect(() => {
    if (isRegistrationSuccessful) {
      resetRegistrationState();
      history.push(AUTH_ROUTES.ADMIN);
    }
  }, [isRegistrationSuccessful]);

  return (
    <form
      onSubmit={handleSubmit((data) => {
        /* eslint-disable no-param-reassign */
        data.registrationType = regAdminType;
        data.email = email;
        /* eslint-enable no-param-reassign */

        return registerAction(data);
      })}
    >
      <Grid container>
        <Grid item sm={7} xs={12}>
          <Box display="flex" flexDirection="column">
            <Box className={classes.headerContainer} pb={2}>
              <Box display="flex" pb={2} alignItems="center">
                <Typography className={classes.header} variant="h5">
                  {translate('REGISTER_HEADING_WELCOME')}
                </Typography>
                <Typography className={classes.header} variant="body1">
                  {translate('REGISTER_GET_SET_UP')}
                </Typography>
              </Box>
              <Typography
                className={classes.header}
                variant="body1"
                style={{ paddingBottom: '8px' }}
              >
                {translate('REGISTER_YOUR_USER_NAME')}
              </Typography>
              <Typography className={classes.header} variant="subtitle1">
                {email}
              </Typography>
            </Box>
            <Box>
              <Grid container spacing={3}>
                <Grid className={classes.nameContainer} item xs={12}>
                  <Grid className={classes.firstNameItem} item sm={6} xs={12}>
                    <TextField
                      fullWidth
                      error={!!errors.firstName}
                      helperText={errors?.firstName?.message || ''}
                      name="firstName"
                      inputRef={register({
                        required: 'Name Required',
                        maxLength: {
                          value: firstNameMaxLength,
                          message: translate(
                            'VALIDATION_MAX_CHARACTERS_ALLOWED',
                            { length: firstNameMaxLength }
                          ),
                        },
                      })}
                      label="First Name"
                      variant="outlined"
                    />
                  </Grid>
                  <Grid className={classes.lastNameItem} item sm={6} xs={12}>
                    <TextField
                      fullWidth
                      error={!!errors.lastName}
                      helperText={errors?.lastName?.message || ''}
                      name="lastName"
                      label="Last Name"
                      variant="outlined"
                      inputRef={register({
                        maxLength: {
                          value: lastNameMaxLength,
                          message: translate(
                            'VALIDATION_MAX_CHARACTERS_ALLOWED',
                            { length: lastNameMaxLength }
                          ),
                        },
                      })}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Grid>
        <Grid item sm={5} xs={12} className={classes.imageWrap}>
          <img
            alt={translate('REGISTER_HEADING_WELCOME')}
            src={congratulationIcon}
          />
        </Grid>
        <Box xs={12} pt={2} className={classes.lowerFormContainer}>
          <Visible when={!!error}>
            <FormHelperText error={!!error} className={classes.formHelperText}>
              {error?.message}
            </FormHelperText>
          </Visible>
        </Box>
        <Box xs={12} pt={2} className={classes.lowerFormContainer}>
          <Visible when={isFetching}>
            <CircularProgress
              color="primary"
              style={{
                height: '54px',
                width: '54px',
              }}
            />
          </Visible>
          <Visible when={!isFetching}>
            <CustomButton
              buttonType="primary"
              type="submit"
              variant="contained"
              disabled={isFetching}
              color="primary"
              label={translate('CREATE_ACCOUNT')}
            />
          </Visible>
        </Box>
      </Grid>
    </form>
  );
};

AdminRegistrationForm.propTypes = {
  isFetching: PropTypes.bool.isRequired,
  isRegistrationSuccessful: PropTypes.bool.isRequired,
  error: PropTypes.shape({ message: {} }),
  registerAction: PropTypes.func.isRequired,
  resetRegistrationState: PropTypes.func.isRequired,
  email: PropTypes.string,
};

AdminRegistrationForm.defaultProps = {
  error: null,
  email: null,
};

export { AdminRegistrationForm };
