import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useSize } from '@umijs/hooks';
import { arrayOf, func, string } from 'prop-types';
import { max, prop } from 'rambdax';
import { Box, TextField } from '@material-ui/core';
import styled from 'styled-components';
import {
  KEY_BACK_SPACE,
  KEY_RETURN,
  KEY_TAB,
  KEY_SPACE,
  KEY_LEFT,
  KEY_RIGHT,
} from 'keycode-js';
import { colors } from 'styles/theme';
import { extractEmails } from 'lib/utils';
import { getEmailInputWidth } from 'lib/mappers';
import { translate } from 'lib/intl';
import { EmailChip } from './email-chip';

const SCROLL_TO_INPUT_TIMEOUT = 100;
const MIN_INPUT_WIDTH = 60;

const StyledContainer = styled(Box)`
  background-color: ${colors.grey300};
  cursor: text;
  max-height: 100%;
  min-height: 150px;
  padding: 12px;
  padding-bottom: 4px;
`;

const StyledTextInput = styled(TextField)`
  flex: 1;
  min-width: ${prop('width')};

  .MuiInputBase-input {
    text-overflow: ellipsis;
  }

  .MuiInput-underline:before,
  .MuiInput-underline:after {
    border: none !important;
  }
`;

const EmailsInput = ({
  inputValue,
  onInputValueChange,
  values,
  onValuesChange,
  onFocusChange,
}) => {
  const [container, containerRef] = useSize();
  const inputRef = useRef();

  const [isInputFocused, setIsInputFocused] = useState(false);
  const [focusedChipIdx, setFocusedChipIdx] = useState(values.length);
  const [isFocusedChipEditing, setFocusedChipEditing] = useState(false);

  const [pastedValue, setPastedValue] = useState('');

  const focusInput = () =>
    inputRef?.current?.childNodes[0]?.childNodes[0]?.focus();

  useEffect(() => {
    // makes sure that you always see input on loading the component
    setTimeout(() => {
      // eslint-disable-next-line no-unused-expressions
      inputRef?.current?.scrollIntoView();
    }, [SCROLL_TO_INPUT_TIMEOUT]);
  }, []);

  useEffect(() => {
    onFocusChange(isInputFocused || isFocusedChipEditing);

    if (!isInputFocused && !isFocusedChipEditing) {
      setFocusedChipIdx(values.length);
    }
  }, [isInputFocused, isFocusedChipEditing]);

  const addChip = useCallback(() => {
    const chipValue = inputValue.trim();

    if (chipValue) {
      const updatedValues = [...values, inputValue.trim()];
      onValuesChange(updatedValues);
      onInputValueChange('');
      setFocusedChipIdx(updatedValues?.length);
    }
  }, [values, inputValue]);

  const deleteChip = useCallback(
    (idx) => {
      const updatedValues = [...values];
      updatedValues.splice(idx, 1);

      onValuesChange(updatedValues);
      setFocusedChipIdx(
        idx === updatedValues.length ? updatedValues.length : idx
      );
      focusInput();
    },
    [values]
  );

  const updateChip = useCallback(
    (idx, newVal) => {
      const updatedValues = [...values];
      updatedValues[idx] = newVal;
      onValuesChange(updatedValues);
      setFocusedChipEditing(false);
      focusInput();
    },
    [values, inputValue]
  );

  const onChipClick = useCallback(
    (idx, e) => {
      // eslint-disable-next-line no-unused-expressions
      e.stopPropagation();
      setFocusedChipIdx(idx);
      setFocusedChipEditing(true);
    },
    [values, inputValue]
  );

  const Chips = useMemo(
    () =>
      values?.map((val, idx) => {
        const isFocused = focusedChipIdx === idx;

        return (
          <EmailChip
            email={val}
            handleDelete={() => deleteChip(idx)}
            handleChange={(newVal) => updateChip(idx, newVal)}
            handleClick={(e) => onChipClick(idx, e)}
            isEditing={isFocused && isFocusedChipEditing}
            isFocused={isFocused}
            // eslint-disable-next-line react/no-array-index-key
            key={idx}
            maxWidth={container?.width}
          />
        );
      }),
    [values, inputValue, focusedChipIdx, isFocusedChipEditing, container?.width]
  );

  const changeInputVal = useCallback(
    (e) => {
      const newValue = e?.target?.value?.trim();

      if (pastedValue) {
        const updatedValues = [...values, ...extractEmails(pastedValue.trim())];
        onValuesChange(updatedValues);
        setPastedValue('');
        onInputValueChange('');
        setFocusedChipIdx(updatedValues.length);
      } else {
        onInputValueChange(newValue);
        setFocusedChipIdx(values.length);
      }
    },
    [pastedValue, values]
  );

  const onInputKeyDown = useCallback(
    ({ keyCode }) => {
      const hasFocusedChip = focusedChipIdx < values?.length;
      const inputIsEmpty = !inputValue.length;

      if ([KEY_RETURN, KEY_SPACE, KEY_TAB].includes(keyCode)) {
        if (hasFocusedChip) {
          setFocusedChipEditing(true);
        } else {
          addChip();
        }
      } else if (inputIsEmpty && keyCode === KEY_BACK_SPACE && values.length) {
        deleteChip(hasFocusedChip ? focusedChipIdx : values.length - 1);
      } else if (inputIsEmpty && keyCode === KEY_LEFT && focusedChipIdx) {
        setFocusedChipIdx(focusedChipIdx - 1);
      } else if (inputIsEmpty && keyCode === KEY_RIGHT && hasFocusedChip) {
        setFocusedChipIdx(focusedChipIdx + 1);
      }
    },
    [inputValue, addChip, focusedChipIdx, values.length]
  );

  const onInputPaste = useCallback((e) => {
    setPastedValue(e?.clipboardData.getData('text/plain'));
  }, []);

  const onFocusInput = useCallback(() => {
    setIsInputFocused(true);
    setFocusedChipEditing(false);
  }, [values.length]);

  const onBlurInput = useCallback(() => {
    setIsInputFocused(false);
  }, []);

  const inputPlaceholder = useMemo(
    () =>
      values?.length ? '' : translate('COMPANY_PAGE_INVITE_TEAM_PLACEHOLDER'),
    [values.length]
  );

  const inputWidth = useMemo(() => {
    const minWidth = max(getEmailInputWidth(inputValue), MIN_INPUT_WIDTH);

    return minWidth <= container?.width ? `${minWidth}px` : 'auto';
  }, [container?.width, inputValue]);

  return (
    <StyledContainer onClick={focusInput}>
      <Box display="flex" flexWrap="wrap" ref={containerRef}>
        {Chips}
        <StyledTextInput
          data-testid="email-input"
          onBlur={onBlurInput}
          onChange={changeInputVal}
          onFocus={onFocusInput}
          onKeyDown={onInputKeyDown}
          onPaste={onInputPaste}
          placeholder={inputPlaceholder}
          ref={inputRef}
          value={inputValue}
          width={inputWidth}
        />
      </Box>
    </StyledContainer>
  );
};

EmailsInput.propTypes = {
  inputValue: string.isRequired,
  onInputValueChange: func.isRequired,
  values: arrayOf(string).isRequired,
  onValuesChange: func.isRequired,
  onFocusChange: func.isRequired,
};

export { EmailsInput };
