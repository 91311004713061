import React from 'react';
import PropTypes from 'prop-types';
import ConstituentPropType from 'lib/types/ConstituentPropType';
import Button from '@material-ui/core/Button';
import Radio from '@material-ui/core/Radio';
import Typography from '@material-ui/core/Typography';
import constituentActions from 'rdx/actions/constituentActions';
import getCategoryIcon from 'lib/utils/getCategoryIcon';
import { useDispatch, useSelector } from 'react-redux';
import { translate } from 'lib/intl';
import { CustomButton, Loader } from 'components';
import { Close } from '@material-ui/icons';
import { PRIORITIES } from 'lib/configs';
import ConstituentCardItemActionsMovePrioritize from './ConstituentCardItemActionsMovePrioritize';

const ConstituentCardItemActionsMove = ({
  constituent,
  onClose,
  onComplete,
  showRemove,
}) => {
  const { kanbanCategory, kanbanPriority, constituentId } = constituent;
  const dispatch = useDispatch();
  const [selectedValue, setSelectedValue] = React.useState(kanbanCategory);
  const [isPrioritized, setIsPrioritized] = React.useState(
    Boolean(kanbanPriority)
  );
  const [isUpdating, setIsUpdating] = React.useState(false);
  const { categories } = useSelector((state) => ({
    categories: state.orgInfo?.data?.kanban?.columns,
  }));

  const handleColumnSelection = (event) => {
    setSelectedValue(+event.target.value);
  };

  const handleSubmit = async () => {
    setIsUpdating(true);
    const data = {
      kanbanCategory: selectedValue,
      kanbanPriority: isPrioritized ? PRIORITIES.HIGH : PRIORITIES.LOW,
      kanbanView: true,
    };
    await dispatch(
      constituentActions.updateConstituent({ constituentId, data })
    );

    setIsUpdating(false);
    onComplete({ ...constituent, ...data });
    onClose();
  };

  const handleRemove = async () => {
    setIsUpdating(true);
    const data = {
      kanbanView: false,
    };
    await dispatch(
      constituentActions.updateConstituent({ constituentId, data })
    );

    setIsUpdating(false);
    onComplete({ ...constituent, ...data });
    onClose();
  };

  return (
    <div className="bg-white flex flex-col">
      <div className="h-14 w-full border-b border-grey-300 relative">
        <div className="h-full justify-between flex items-center px-4">
          <div className="type-h2">{translate('DONOR_CARD_ACTION_MOVE')}</div>
          <Close
            data-testid="constituent-card-item-actions-move-close"
            className="h-5 w-5 cursor-pointer"
            onClick={onClose}
          />
        </div>
      </div>
      <div className="h-14 w-full border-b border-grey-300 relative">
        <ConstituentCardItemActionsMovePrioritize
          isPrioritized={isPrioritized}
          onClick={() => setIsPrioritized(!isPrioritized)}
        />
      </div>
      {categories.map(({ name, index: category }) => (
        <label
          className="h-14 w-full border-b border-grey-300 relative py-4 pl-4 pr-2 flex items-center"
          key={name}
          htmlFor={`move-${constituentId}-${category}`}
        >
          <img
            className="h-5 w-5 mr-2"
            alt=""
            src={getCategoryIcon(category)}
          />
          <span className="type-body1">{name}</span>
          <Radio
            className="ml-auto"
            checked={selectedValue === category}
            onChange={handleColumnSelection}
            value={category}
            inputProps={{ 'aria-label': name }}
            id={`move-${constituentId}-${category}`}
          />
        </label>
      ))}
      <div className="p-4 flex items-center justify-end">
        {isUpdating && <Loader isCentered size="24px" />}
        {!isUpdating && (
          <>
            {showRemove && (
              <Button variant="text" onClick={handleRemove} className="mr-auto">
                <Typography color="error">
                  {translate('DONOR_CARD_ACTION_REMOVE_CARD')}
                </Typography>
              </Button>
            )}
            <CustomButton
              dataTestId="constituent-card-item-actions-assign-move"
              buttonType="link"
              color="primary"
              label={translate('DONOR_CARD_ACTION_CANCEL')}
              onClick={onClose}
              className="ml-2"
            />
            <CustomButton
              buttonType="primary"
              label={translate('DONOR_CARD_ACTION_MOVE_SUBMIT')}
              onClick={handleSubmit}
              className="ml-2"
            />
          </>
        )}
      </div>
    </div>
  );
};

ConstituentCardItemActionsMove.propTypes = {
  constituent: ConstituentPropType.isRequired,
  onClose: PropTypes.func,
  onComplete: PropTypes.func,
  showRemove: PropTypes.bool,
};

ConstituentCardItemActionsMove.defaultProps = {
  onClose: () => {},
  onComplete: () => {},
  showRemove: true,
};

export default ConstituentCardItemActionsMove;
