import React, { useMemo } from 'react';
import { arrayOf, bool, func, string } from 'prop-types';
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
} from '@material-ui/core';
import styled from 'styled-components';
import {
  Avatar,
  CustomButton,
  Gap,
  Loader,
  SectionsGenerator,
  Visible,
} from 'components';
import { colors } from 'styles/theme';
import { EMPTY_FUNC } from 'lib/constants';
import { CloseIcon } from 'assets/images';
import { DonorType, getInitials, isMobileView } from 'lib/utils';
import { translate } from 'lib/intl';
import { EDIT_COMPANY_INFO_CONFIG } from 'lib/configs';

const StyledDialog = styled(Dialog)`
  ${({ mobile }) =>
    mobile
      ? `
  .MuiDialog-paperFullWidth {
    border-radius: 8px;
    height: 100%;
    margin: 6px;
    max-height: calc(100vh - 12px);
    width: 100%;
  }

  .MuiFormControl-root {
    margin-top: 10px;
  }
  `
      : ''}
`;

const Header = styled(Box)`
  border-bottom: 1px solid ${colors.lightGrey};

  h2 {
    font-size: 20px;
    font-weight: 500;
  }
`;

const StyledDialogContent = styled(DialogContent)`
  padding: 0px;
`;

const StyledBottomContent = styled(Box)`
  border-top: 1px solid ${colors.progressLightGrey};
  height: 104px;
`;

const StyledWrapper = styled(Box)`
  position: relative;
`;

const StyledAvatar = styled(Avatar)`
  left: ${({ mobile }) => (mobile ? '20px' : '30px')};
  position: absolute;
  top: ${({ mobile }) => (mobile ? '36px' : '10px')};
`;

const StyledCloseButton = styled(Box)`
  cursor: pointer;
  position: absolute;
  top: 22px;
  right: 16px;
`;

const EditCompanyModal = ({
  data,
  errors,
  open,
  handleClose,
  handleSave,
  isLoading,
  setErrors,
  showErrors,
  error,
  updateData,
}) => {
  const { avatar, orgName } = data;

  const isMobile = isMobileView();

  const initials = useMemo(() => getInitials(orgName), [orgName]);

  return (
    <StyledDialog
      aria-labelledby="edit-dialog"
      fullWidth
      maxWidth="md"
      mobile={Number(isMobile)}
      onClose={handleClose}
      open={open}
    >
      <Header
        alignItems="center"
        display="flex"
        justifyContent="space-between"
        pr={3}
      >
        <DialogTitle id="edit-dialog-title">
          {translate('COMPANY_EDIT_PROFILE_LABEL')}
        </DialogTitle>
        <Visible when={isMobile}>
          <StyledCloseButton
            alignItems="center"
            display="flex"
            onClick={handleClose}
          >
            <CloseIcon />
          </StyledCloseButton>
        </Visible>
      </Header>
      <StyledDialogContent>
        <StyledWrapper pb={3} pr={3} pt={2}>
          <StyledAvatar
            alt="company-form-avatar"
            data-testid="company-form-avatar"
            size={isMobile ? '40px' : '64px'}
            src={avatar}
            mobile={Number(isMobile)}
          >
            {initials}
          </StyledAvatar>
          <SectionsGenerator
            config={EDIT_COMPANY_INFO_CONFIG}
            data={data}
            errors={errors}
            setErrors={setErrors}
            showErrors={error}
            updateData={updateData}
          />
        </StyledWrapper>
      </StyledDialogContent>
      <StyledBottomContent
        display="flex"
        alignItems="center"
        justifyContent={isLoading ? 'center' : 'flex-start'}
        px={2}
        py={3}
      >
        <Visible fallback={<Loader />} when={!isLoading}>
          <Visible when={showErrors}>
            <Box px={1}>
              <Typography color="error">
                {translate(
                  error
                    ? 'API_DEFAULT_ERROR_2'
                    : 'COMPANY_FORM_FIX_ERRORS_MESSAGE'
                )}
              </Typography>
            </Box>
          </Visible>
          <Box
            display="flex"
            flex="1"
            flexDirection="row"
            justifyContent="flex-end"
          >
            <CustomButton
              buttonType="link"
              color="primary"
              dataTestId="edit-modal-cancel-button"
              label={translate('CANCEL')}
              onClick={handleClose}
            />
            <Gap />
            <CustomButton
              buttonType="primary"
              dataTestId="edit-modal-save-button"
              label={translate('SAVE')}
              onClick={handleSave}
            />
          </Box>
        </Visible>
      </StyledBottomContent>
    </StyledDialog>
  );
};

EditCompanyModal.propTypes = {
  data: DonorType.isRequired,
  errors: arrayOf(string),
  isLoading: bool,
  open: bool,
  handleClose: func,
  handleSave: func,
  error: bool,
  setErrors: func,
  showErrors: bool,
  updateData: func,
};

EditCompanyModal.defaultProps = {
  errors: [],
  open: false,
  handleClose: EMPTY_FUNC,
  handleSave: EMPTY_FUNC,
  isLoading: false,
  setErrors: EMPTY_FUNC,
  showErrors: false,
  error: false,
  updateData: EMPTY_FUNC,
};

export { EditCompanyModal };
