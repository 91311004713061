import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { DonationAmount } from './DonationAmount';

const CampaignStatisticsBlock = ({ children, className, ...rest }) => {
  return (
    <div
      className={classNames(
        className,
        'border border-grey-300 flex flex-col items-end p-4 md:p-6 rounded-lg w-full'
      )}
    >
      <DonationAmount
        valueProps={{
          className: 'font-semiBold md:font-regular my-1 text-2xl md:text-4xl',
        }}
        {...rest}
      />
      {children}
    </div>
  );
};

CampaignStatisticsBlock.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  isCurrencyBased: PropTypes.bool,
  label: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired,
};

CampaignStatisticsBlock.defaultProps = {
  children: null,
  className: '',
  isCurrencyBased: false,
};

export { CampaignStatisticsBlock };
