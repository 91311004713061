import React, { useCallback, useMemo, useRef, useState } from 'react';
import { bool, string } from 'prop-types';
import { useDispatch } from 'react-redux';
import { Box } from '@material-ui/core';
import styled from 'styled-components';
import { ConfirmationPopup, Visible } from 'components';
import { translate } from 'lib/intl';
import { CompanyTeamMember, CompanyTeamMemberStatus } from 'lib/utils';
import { companyTeamMembersActions, invitesActions } from 'rdx/actions';
import { STOP_PROPAGATION } from 'lib/constants';
import { trashcanIcon } from 'assets/images';
import { TeamMemberLoaderCell } from './team-member-loader-cell';

const { removeCompanyTeamMembers } = companyTeamMembersActions;

const { removeInvites } = invitesActions;

const StyledContainer = styled(Box)`
  height: 50px;
  width: 50px;
`;

const StyledTrashcanIcon = styled.img`
  cursor: pointer;
  height: 16px;
  width: 16px;
`;

const TRASHCAN_CELL_CONFIG = {
  TEAM_MEMBERS: {
    popupButtonLabel: translate('DEACTIVATE'),
    popupMessage: translate('CONFIRM_TEAM_MEMBER_REMOVAL_MESSAGE'),
    removeAction: removeCompanyTeamMembers,
  },
  INVITES: {
    popupButtonLabel: translate('REMOVE'),
    popupMessage: translate('CONFIRM_INVITE_REMOVAL_MESSAGE'),
    removeAction: removeInvites,
  },
};

const TeamMemberTrashcanCell = ({
  data,
  isForInvites,
  ownerOrgId,
  status,
  showLoaderOnFetching,
}) => {
  const dispatch = useDispatch();
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const ref = useRef();

  const { popupButtonLabel, popupMessage, removeAction } = useMemo(
    () =>
      isForInvites
        ? TRASHCAN_CELL_CONFIG.INVITES
        : TRASHCAN_CELL_CONFIG.TEAM_MEMBERS,
    [isForInvites]
  );

  const onClick = useCallback(() => {
    setIsPopupOpen(false);
    dispatch(removeAction(ownerOrgId, data));
  }, [removeAction]);

  return (
    <StyledContainer
      alignItems="center"
      display="flex"
      data-testid="team-member-trashcan-cell"
      justifyContent="center"
      ref={ref}
      onClick={STOP_PROPAGATION}
    >
      <Visible
        fallback={
          <TeamMemberLoaderCell status={status} justifyContent="center" />
        }
        when={!showLoaderOnFetching || !status?.isFetching}
      >
        <StyledTrashcanIcon
          src={trashcanIcon}
          onClick={() => setIsPopupOpen(true)}
        />
        <ConfirmationPopup
          anchorEl={ref?.current}
          confirmButtonLabel={popupButtonLabel}
          message={popupMessage}
          onConfirm={onClick}
          onClose={() => setIsPopupOpen(false)}
          open={isPopupOpen}
        />
      </Visible>
    </StyledContainer>
  );
};

TeamMemberTrashcanCell.propTypes = {
  data: CompanyTeamMember.isRequired,
  isForInvites: bool,
  ownerOrgId: string,
  status: CompanyTeamMemberStatus,
  showLoaderOnFetching: bool,
};

TeamMemberTrashcanCell.defaultProps = {
  isForInvites: false,
  ownerOrgId: '',
  status: {
    dataLoaded: false,
    isFetching: false,
    error: null,
  },
  showLoaderOnFetching: true,
};

export { TeamMemberTrashcanCell };
