import React from 'react';
import { values } from 'rambdax';
import { oneOf, shape } from 'prop-types';
import { Box, Typography } from '@material-ui/core';
import { upperCase } from 'voca';
import { Gap } from 'components';
import { isDonationTimeType } from 'lib/utils';
import { DONATION_TYPE_ENUMS } from 'lib/constants/common';
import {
  CampaignDonationType,
  DONATION_TYPES,
  DONATION_VOLUNTEER_CONFIG,
} from 'lib/configs/donations';
import { DonationIcon } from './DonationIcon';

const DonationType = ({ data, value }) => {
  const {
    displayText,
    icon,
    iconAlt,
    text,
    value: key,
  } = isDonationTimeType(data)
    ? DONATION_VOLUNTEER_CONFIG.find(
        (config) => config.value === data.timeCategory
      )
    : DONATION_TYPES[upperCase(value)];

  return (
    <Box alignItems="center" data-testid={`${key}-type-cell`} display="flex">
      <DonationIcon alt={iconAlt} src={icon} />
      <Gap />
      <Typography variant="body2">{displayText || text}</Typography>
    </Box>
  );
};

DonationType.propTypes = {
  data: shape(CampaignDonationType).isRequired,
  value: oneOf(values(DONATION_TYPE_ENUMS)).isRequired,
};

export { DonationType };
