import React from 'react';
import env from '@beam-australia/react-env';
import { Grid, Typography } from '@material-ui/core';
import { thinkingEmoji } from 'assets/images';
import {
  authNavLinkStyle,
  errorPageIconStyle,
} from 'components/auth/authStyles';
import { translate } from 'lib/intl';

const NotFoundErrorPageRaw = () => (
  <Grid
    container
    style={{
      padding: '48px 56px',
    }}
  >
    <Grid item sm={6} xs={12}>
      <Typography variant="h5">{translate('OOPS_EXCLAMATION')}</Typography>
      <Typography variant="h6" style={{ marginTop: '30px' }}>
        {translate('NOTHING_HERE')}
      </Typography>
      <Typography variant="body1" style={{ marginTop: '30px' }}>
        {translate('SEARCHING_FOR_HELP')}
        &nbsp;
        <a href={`mailto:${env('HELP_LINK_ADDRESS')}`} style={authNavLinkStyle}>
          {translate('CONTACT_US')}
        </a>
      </Typography>
    </Grid>
    <Grid item sm={6} xs={12} style={errorPageIconStyle}>
      <img alt="Thinking emoji" src={thinkingEmoji} />
    </Grid>
  </Grid>
);

export { NotFoundErrorPageRaw };
