export const ANNOUNCEMENTS = {
  ADD_ANNOUNCEMENT_1: 'Add',
  ADD_ANNOUNCEMENT_2: 'Announcement',
  ANNOUNCEMENT_MESSAGE_LABEL: 'Announcement Message',
  ANNOUNCEMENT_MESSAGE_CHARACTER_COUNT: 'Characters: {value}',
  ANNOUNCEMENT_LINK_PREVIEW_SECTION: 'Link with Preview Section',
  ANNOUNCEMENT_LINK_LABEL: 'Announcement Link',
  INVALID_ANNOUNCEMENT_LINK_LABEL: 'Invalid Link Format',
  PIN_ANNOUNCEMENT_LABEL: 'Pin this announcement?',
  ANNOUNCEMENT_PINNED_DURATION: 'Pin for',
  ANNOUNCEMENT_PINNED_DURATION_DAY: 'day',
  ANNOUNCEMENT_PINNED_DURATION_DAY_PLURAL: 'days',
  ANNOUNCEMENT_CREATION_ERROR: 'There was a problem posting. Please try again.',
  GLOBAL_ANNOUNCEMENT_INFO:
    'Global announcements will appear in the feed of ALL organizations on Instil. Please use caution when posting.',
  ANNOUNCEMENT_ADDED_MESSAGE: 'Success! Your Announcement has been posted!',
  GLOBAL_ANNOUNCEMENT_ADDED_MESSAGE:
    'Success! Your Global Announcement has been posted!',
};
