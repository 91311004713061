import userActionTypes from 'rdx/actionTypes';

const {
  FETCH_CAMPAIGN_DONATIONS_REQUEST,
  FETCH_CAMPAIGN_DONATIONS_SUCCESS,
  FETCH_CAMPAIGN_DONATIONS_FAILURE,
} = userActionTypes;

const initialState = {
  dataLoaded: false,
  data: [],
  isFetching: false,
  error: null,
};

export function campaignDonationsReducer(state = {}, action) {
  switch (action.type) {
    case FETCH_CAMPAIGN_DONATIONS_REQUEST:
      return {
        ...state,
        [action.campaignId]: {
          ...(state[action.campaignId] || initialState),
          isFetching: true,
          error: null,
        },
      };
    case FETCH_CAMPAIGN_DONATIONS_SUCCESS:
      return {
        ...state,
        [action.campaignId]: {
          data: action.data,
          dataLoaded: true,
          isFetching: false,
          error: null,
        },
      };
    case FETCH_CAMPAIGN_DONATIONS_FAILURE:
      return {
        ...state,
        [action.campaignId]: {
          ...state[action.campaignId],
          isFetching: false,
          error: action.error,
        },
      };
    default:
      return state;
  }
}
