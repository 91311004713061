import React, { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { ControlledPasswordInput } from 'components/common/form';
import { translate } from 'lib/intl';
import PasswordValidationCase from './PasswordValidationCase';

const PASSWORD_INPUT_NAME = 'password';

const PasswordForm = ({ variant }) => {
  const { errors } = useFormContext();

  const [password, setPassword] = useState({
    hasCharacterCount: null,
    hasNumber: null,
    hasUpper: null,
    hasLower: null,
    hasSpecial: null,
  });

  // Check password validation, update password validation state
  const isValidPassword = (value) => {
    const hasCharacterCount = value.length >= 10;
    const hasNumber = /[0-9]/.test(value);
    const hasUpper = /[A-Z]/.test(value);
    const hasLower = /[a-z]/.test(value);
    const hasSpecial = /_|[^\w]/.test(value);

    setPassword({
      hasCharacterCount,
      hasNumber,
      hasUpper,
      hasLower,
      hasSpecial,
    });

    return hasCharacterCount && hasNumber && hasUpper && hasLower && hasSpecial;
  };

  const handlePasswordChange = () => (event) => {
    isValidPassword(event.target.value);
  };

  const hasPasswordError = !!errors[PASSWORD_INPUT_NAME];

  return (
    <Grid item container display="flex" xs={12}>
      <ControlledPasswordInput
        dataTestid="password-form-input"
        fullWidth
        name={PASSWORD_INPUT_NAME}
        onChange={handlePasswordChange(PASSWORD_INPUT_NAME)}
        rules={{
          validate: {
            isValidPassword: (value) => isValidPassword(value),
          },
        }}
        {...(variant && { variant })}
      />
      <Grid
        item
        container
        xs={12}
        style={{ paddingTop: '6px', display: 'flex' }}
      >
        <PasswordValidationCase
          hasPasswordError={hasPasswordError}
          isValid={password.hasCharacterCount}
          text={translate('PASSWORD_CHARACTER_LIMIT')}
        />
        <PasswordValidationCase
          hasPasswordError={hasPasswordError}
          isValid={password.hasNumber}
          text={translate('PASSWORD_CONTAINS_NUMBER')}
        />
        <PasswordValidationCase
          hasPasswordError={hasPasswordError}
          isValid={password.hasUpper}
          text={translate('PASSWORD_CONTAINS_UPPERCASE')}
        />
        <PasswordValidationCase
          hasPasswordError={hasPasswordError}
          isValid={password.hasSpecial}
          text={translate('PASSWORD_CONTAINS_SYMBOL')}
        />
        <PasswordValidationCase
          hasPasswordError={hasPasswordError}
          isValid={password.hasLower}
          text={translate('PASSWORD_CONTAINS_LOWERCASE')}
        />
      </Grid>
    </Grid>
  );
};

PasswordForm.propTypes = {
  variant: PropTypes.string,
};

PasswordForm.defaultProps = {
  variant: '',
};

export default PasswordForm;
