import { camelCaseKeys, createAction, showErrorMessage } from 'lib/utils';
import { translate } from 'lib/intl';
import actionTypes from 'rdx/actionTypes';
import { orgsService } from 'services/orgs';
import { GROUP_TYPES } from 'lib/constants/common';

const {
  ORG_INFO_REQUEST,
  ORG_INFO_SUCCESS,
  ORG_INFO_FAILURE,
  UPDATE_ORG_INFO_REQUEST,
  UPDATE_ORG_INFO_SUCCESS,
  UPDATE_ORG_INFO_FAILURE,
} = actionTypes;

const mapGroups = (groups) =>
  groups?.reduce((res, { groupType, groupDisplayName, groupId }) => {
    if (groupType === GROUP_TYPES.PERMISSION) {
      res.push({
        groupDisplayName,
        groupId,
      });
    }

    return res;
  }, []);

const findPrimaryItem = (list) => list?.find(({ primary }) => primary);

const fetchOrgInfo = (ownerOrgPubId) => async (dispatch) => {
  try {
    dispatch(createAction({ type: ORG_INFO_REQUEST }));

    const response = await orgsService.getOrgInfo(ownerOrgPubId);

    const orgData = camelCaseKeys(response?.data?.org);

    const primaryAddress = findPrimaryItem(orgData?.addresses);

    const groups = mapGroups(orgData?.groups);

    dispatch(
      createAction({
        type: ORG_INFO_SUCCESS,
        data: camelCaseKeys({
          ...orgData,
          groups,
          primaryAddress,
        }),
      })
    );
  } catch (error) {
    showErrorMessage({
      message: translate('COMPANY_FETCH_COMPANY_INFO_ERROR_MESSAGE'),
    });

    dispatch(
      createAction({
        type: ORG_INFO_FAILURE,
        error,
      })
    );
  }
};

const updateOrgInfo = (ownerOrgPubId, data) => async (dispatch) => {
  try {
    dispatch(createAction({ type: UPDATE_ORG_INFO_REQUEST }));

    const response = await orgsService.updateOrgInfo(ownerOrgPubId, data);

    dispatch(
      createAction({
        type: UPDATE_ORG_INFO_SUCCESS,
        data: camelCaseKeys(response?.data?.org),
      })
    );

    dispatch(fetchOrgInfo(ownerOrgPubId));
  } catch (error) {
    dispatch(
      createAction({
        type: UPDATE_ORG_INFO_FAILURE,
        error,
      })
    );
  }
};

export const orgInfoActions = {
  fetchOrgInfo,
  updateOrgInfo,
};

export default orgInfoActions;
