import {
  backlog,
  prospecting,
  cultivating,
  requestingIcon,
  stewardshipIcon,
} from 'assets/images';
import { translate } from 'lib/intl';

// Client configuration, keys match up to API response columns
// TODO for the distant future, the API may provide the columns in a
// dynamic or customizable way, the UI will need to be updated to suppoer
// that behavior going forward
// NOTE: if these keys change in the API, the UI must be updated
const DONOR_CATEGORY_COLUMNS = {
  // Currently not in use, but already implemented
  // keeping around in case backlog becomes an option in the future
  backlog: {
    key: 99,
    name: translate('DONOR_COLUMN_BACKLOG'),
    image: backlog,
    emptyContent: [
      translate('DONOR_COLUMN_BACKLOG_CONTENT_1'),
      translate('DONOR_COLUMN_BACKLOG_CONTENT_2'),
      translate('DONOR_COLUMN_BACKLOG_CONTENT_3'),
    ],
  },
  prospecting: {
    key: 0,
    name: translate('DONOR_COLUMN_PROSPECTING'),
    image: prospecting,
    emptyContent: [
      translate('DONOR_COLUMN_PROSPECTING_CONTENT_1'),
      translate('DONOR_COLUMN_PROSPECTING_CONTENT_2'),
      translate('DONOR_COLUMN_PROSPECTING_CONTENT_3'),
    ],
  },
  cultivating: {
    key: 1,
    name: translate('DONOR_COLUMN_CULTIVATING'),
    image: cultivating,
    emptyContent: [
      translate('DONOR_COLUMN_CULTIVATING_CONTENT_1'),
      translate('DONOR_COLUMN_CULTIVATING_CONTENT_2'),
      translate('DONOR_COLUMN_CULTIVATING_CONTENT_3'),
    ],
  },
  requesting: {
    key: 2,
    name: translate('DONOR_COLUMN_REQUESTING'),
    image: requestingIcon,
    emptyContent: [
      translate('DONOR_COLUMN_REQUESTING_CONTENT_1'),
      translate('DONOR_COLUMN_REQUESTING_CONTENT_2'),
      translate('DONOR_COLUMN_REQUESTING_CONTENT_3'),
    ],
  },
  stewardship: {
    key: 3,
    name: translate('DONOR_COLUMN_STEWARDSHIP'),
    image: stewardshipIcon,
    emptyContent: [
      translate('DONOR_COLUMN_STEWARDSHIP_CONTENT_1'),
      translate('DONOR_COLUMN_STEWARDSHIP_CONTENT_2'),
    ],
  },
};

const PRIORITIES = {
  HIGH: 1,
  LOW: 0,
};

export { DONOR_CATEGORY_COLUMNS, PRIORITIES };
