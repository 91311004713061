import React from 'react';
import Box from '@material-ui/core/Box';
import PropTypes from 'prop-types';
import { ProfileDescriptor } from 'components/common/ProfileDescriptor';
import { CustomLink } from 'components/common/CustomLink';

const hoverDelayMs = 400;

const ProfileDescriptorLink = ({ displayName, ownerOrgId, userId }) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [hoverDelayHandler, setHoverDelayHandler] = React.useState(null);
  const ref = React.useRef();

  const handleClick = (event) => {
    event.stopPropagation();

    setIsOpen(true);
  };

  const handleMouseEnter = (event) => {
    event.stopPropagation();

    setHoverDelayHandler(
      setTimeout(() => {
        setIsOpen(true);
      }, hoverDelayMs)
    );
  };

  const handleMouseLeave = (event) => {
    event.stopPropagation();

    clearTimeout(hoverDelayHandler);
  };

  const closeProfilePopup = () => {
    setIsOpen(false);
  };

  return (
    <Box display="inline" ref={ref}>
      <CustomLink
        data-testid="profile-descriptor-link"
        onClick={handleClick}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        {`@${displayName}`}
      </CustomLink>
      <ProfileDescriptor
        anchor={ref?.current}
        isOpen={isOpen}
        onClose={closeProfilePopup}
        displayName={displayName}
        ownerOrgId={ownerOrgId}
        userId={userId}
      />
    </Box>
  );
};

ProfileDescriptorLink.propTypes = {
  displayName: PropTypes.string.isRequired,
  ownerOrgId: PropTypes.string.isRequired,
  userId: PropTypes.string.isRequired,
};

export { ProfileDescriptorLink };
