import userActionTypes from 'rdx/actionTypes';

const {
  UPDATE_MULTIPLE_CAMPAIGNS_REQUEST,
  UPDATE_MULTIPLE_CAMPAIGNS_SUCCESS,
  UPDATE_MULTIPLE_CAMPAIGNS_FAILURE,
} = userActionTypes;

const initialState = {};

export function updateMultipleCampaigns(state = initialState, action) {
  switch (action.type) {
    case UPDATE_MULTIPLE_CAMPAIGNS_REQUEST:
      return {
        ...state,
        [action.campaignId]: {
          dataLoaded: false,
          isFetching: true,
          error: null,
        },
      };
    case UPDATE_MULTIPLE_CAMPAIGNS_SUCCESS:
      return {
        ...state,
        [action.campaignId]: {
          data: action.data,
          dataLoaded: true,
          isFetching: false,
          error: null,
        },
      };
    case UPDATE_MULTIPLE_CAMPAIGNS_FAILURE:
      return {
        ...state,
        [action.campaignId]: {
          dataLoaded: false,
          isFetching: false,
          error: action.error || 'error',
        },
      };
    default:
      return state;
  }
}
