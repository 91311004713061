import React from 'react';
import { Grid, Box, makeStyles, Typography } from '@material-ui/core';
import { func, string } from 'prop-types';
import { useFeature } from 'flagged';
import { EMPTY_FUNC } from 'lib/constants';
import {
  TextSelectField,
  Visible,
  Loader,
  CustomButton,
  Gap,
} from 'components';
import ConstituentSearchField from 'components/common/form/ConstituentSearchField';
import { translate } from 'lib/intl';
import { colors } from 'styles/theme';
import { DONATION_TYPE_ENUMS } from 'lib/constants/common';
import { DONATION_TYPE_OPTIONS } from 'lib/configs/selectOptionsConfig';
import { DateInput } from 'components/common/form';
import { NewDonationTypeGeneral } from './new-donation-type-general';
import { NewDonationTypePledge } from './new-donation-type-pledge';
import { NewDonationTypeRecurring } from './new-donation-type-recurring';
import { NewDonationTypeRestricted } from './new-donation-type-restricted';
import { NewDonationTypeInKind } from './new-donation-type-in-kind';
import { NewDonationTypePlannedGiving } from './new-donation-type-planned-giving';
import { NewDonationTypeTime } from './new-donation-type-time';
import { NewDonationTypeGrant } from './NewDonationTypeGrant';
import { useNewDonationForm } from './hooks';

const { PLEDGE, RECURRING, RESTRICTED, IN_KIND, PLANNED_GIVING, TIME, GRANT } =
  DONATION_TYPE_ENUMS;

const useStyles = makeStyles(() => ({
  formContainer: {
    padding: '20px 20px 0px',
    overflowY: 'auto',
    // provide height for loading state to prevent scroll bar bouncing on svg movement
    minHeight: '100px',
  },
  rowItem: {
    paddingTop: '12px',
  },
  notesContainer: {
    paddingTop: '30px',
  },
  buttonContainer: {
    borderTop: `1px solid ${colors.progressLightGrey}`,
  },
}));

export default function NewDonationForm({
  onClose,
  preSelectedDonorId,
  onSuccess,
}) {
  const styles = useStyles();
  const {
    amountOfTime,
    areaOfRestriction,
    areCampaignsLoading,
    campaigns,
    developers,
    donationAmount,
    donationType,
    errors,
    fieldErrors,
    grantName,
    restricted,
    restrictionCondition,
    handleSave,
    isLoading,
    itemDonated,
    itemQuantity,
    notes,
    paymentMethod,
    pledgeDueDate,
    recurringCadence,
    recurringStartDate,
    selectedCampaign,
    selectedDate,
    selectedDonor,
    setAmountOfTime,
    setAreaOfRestriction,
    setDevelopers,
    setDonationAmount,
    setDonationType,
    setGrantName,
    setRestricted,
    setRestrictionCondition,
    setItemDonated,
    setItemQuantity,
    setNotes,
    setPaymentMethod,
    setPledgeDueDate,
    setRecurringCadence,
    setRecurringStartDate,
    setSelectedCampaign,
    setSelectedDate,
    setSelectedDonor,
    setTimeCategory,
    setTimeType,
    setTimeValue,
    teamMembers,
    timeCategory,
    timeType,
    timeValue,
  } = useNewDonationForm(onClose, onSuccess);

  const renderFormTypeComponent = () => {
    let donationFormType;

    switch (donationType?.value) {
      case PLEDGE:
        donationFormType = (
          <NewDonationTypePledge
            fieldErrors={fieldErrors}
            donationAmount={donationAmount}
            setDonationAmount={setDonationAmount}
            pledgeDueDate={pledgeDueDate}
            setPledgeDueDate={setPledgeDueDate}
            paymentMethod={paymentMethod}
            setPaymentMethod={setPaymentMethod}
            notes={notes}
            setNotes={setNotes}
          />
        );
        break;
      case RECURRING:
        donationFormType = (
          <NewDonationTypeRecurring
            fieldErrors={fieldErrors}
            donationAmount={donationAmount}
            setDonationAmount={setDonationAmount}
            paymentMethod={paymentMethod}
            setPaymentMethod={setPaymentMethod}
            notes={notes}
            setNotes={setNotes}
            recurringCadence={recurringCadence}
            setRecurringCadence={setRecurringCadence}
            recurringStartDate={recurringStartDate}
            setRecurringStartDate={setRecurringStartDate}
          />
        );
        break;
      case RESTRICTED:
        donationFormType = (
          <NewDonationTypeRestricted
            fieldErrors={fieldErrors}
            donationAmount={donationAmount}
            setDonationAmount={setDonationAmount}
            paymentMethod={paymentMethod}
            setPaymentMethod={setPaymentMethod}
            notes={notes}
            setNotes={setNotes}
            areaOfRestriction={areaOfRestriction}
            setAreaOfRestriction={setAreaOfRestriction}
          />
        );
        break;
      case IN_KIND:
        donationFormType = (
          <NewDonationTypeInKind
            fieldErrors={fieldErrors}
            donationAmount={donationAmount}
            setDonationAmount={setDonationAmount}
            notes={notes}
            setNotes={setNotes}
            itemDonated={itemDonated}
            setItemDonated={setItemDonated}
            itemQuantity={itemQuantity}
            setItemQuantity={setItemQuantity}
          />
        );
        break;
      case PLANNED_GIVING:
        donationFormType = (
          <NewDonationTypePlannedGiving
            fieldErrors={fieldErrors}
            donationAmount={donationAmount}
            setDonationAmount={setDonationAmount}
            notes={notes}
            setNotes={setNotes}
          />
        );
        break;
      case TIME:
        donationFormType = (
          <NewDonationTypeTime
            fieldErrors={fieldErrors}
            timeCategory={timeCategory}
            setTimeCategory={setTimeCategory}
            timeValue={timeValue}
            setTimeValue={setTimeValue}
            amountOfTime={amountOfTime}
            setAmountOfTime={setAmountOfTime}
            timeType={timeType}
            setTimeType={setTimeType}
            notes={notes}
            setNotes={setNotes}
          />
        );
        break;
      case GRANT:
        donationFormType = (
          <NewDonationTypeGrant
            developers={developers}
            donationAmount={donationAmount}
            fieldErrors={fieldErrors}
            grantName={grantName}
            restricted={restricted}
            restrictionCondition={restrictionCondition}
            notes={notes}
            paymentMethod={paymentMethod}
            setDevelopers={setDevelopers}
            setDonationAmount={setDonationAmount}
            setGrantName={setGrantName}
            setRestricted={setRestricted}
            setRestrictionCondition={setRestrictionCondition}
            setNotes={setNotes}
            setPaymentMethod={setPaymentMethod}
            teamMembers={teamMembers}
          />
        );
        break;
      default:
        donationFormType = (
          <NewDonationTypeGeneral
            fieldErrors={fieldErrors}
            donationAmount={donationAmount}
            setDonationAmount={setDonationAmount}
            paymentMethod={paymentMethod}
            setPaymentMethod={setPaymentMethod}
            notes={notes}
            setNotes={setNotes}
          />
        );
    }

    return donationFormType;
  };

  // this checks the FEATURE_FLAG_GRANTS flag to
  // include the GRANTS option if turned on
  const donationTypeOptions = DONATION_TYPE_OPTIONS.filter(
    ({ featureFlag }) =>
      !featureFlag || (featureFlag && useFeature(featureFlag))
  );
  // end grants feature flag check

  return (
    <>
      <Grid className={styles.formContainer}>
        <Visible when={!areCampaignsLoading} fallback={<Loader isCentered />}>
          <Grid container spacing={2} style={{ alignItems: 'flex-end' }}>
            <Grid item xs={12} sm={7}>
              <ConstituentSearchField
                error={!!fieldErrors?.selectedDonor}
                errorHelperText={translate(
                  'DONATION_CONTACT_NAME_ERROR_REQUIRED'
                )}
                initialConstituentId={preSelectedDonorId}
                id="text-select-field-donors"
                label={translate('DONATION_CONTACT_NAME_LABEL')}
                setValue={setSelectedDonor}
                value={selectedDonor}
              />
            </Grid>
            <Grid item xs={12} sm={5}>
              <DateInput
                fullWidth
                id="donation-date-picker-date"
                label={translate('DONATION_DATE_LABEL')}
                onChange={setSelectedDate}
                value={selectedDate}
              />
            </Grid>
          </Grid>
          <Grid className={styles.rowItem} item container xs={12}>
            <TextSelectField
              id="campaign-select-field"
              items={campaigns}
              label={translate('DONATION_CAMPAIGN')}
              loading={areCampaignsLoading}
              setValue={setSelectedCampaign}
              value={selectedCampaign}
            />
          </Grid>
          <Grid className={styles.rowItem} item container xs={12}>
            <TextSelectField
              items={donationTypeOptions}
              value={donationType}
              setValue={setDonationType}
              label={translate('DONATION_TYPE_LABEL')}
              error={!!fieldErrors?.donationType}
              errorHelperText={translate('DONATION_TYPE_ERROR_REQUIRED')}
            />
          </Grid>
          {renderFormTypeComponent()}
          {/* Spacer div to preserve 'padding space on safari' as it is collapsed */}
          <div style={{ height: '20px' }} />
        </Visible>
      </Grid>
      <Box
        className={styles.buttonContainer}
        display="flex"
        justifyContent={isLoading ? 'center' : 'flex-start'}
        px={2}
        py={3}
      >
        <Visible fallback={<Loader isCentered />} when={!isLoading}>
          <Visible when={Boolean(errors && errors.error)}>
            <Box px={1}>
              <Typography color="error">
                {errors?.message ? errors.message : translate('GENERIC_ERROR')}
              </Typography>
            </Box>
          </Visible>
          <Box
            display="flex"
            flex="1"
            flexDirection="row"
            justifyContent="flex-end"
          >
            <CustomButton
              buttonType="link"
              color="primary"
              dataTestId="new-donation-modal-cancel-button"
              label={translate('CANCEL')}
              onClick={onClose}
            />
            <Gap />
            <CustomButton
              buttonType="primary"
              dataTestId="new-donation-modal-save-button"
              label={translate('SAVE')}
              onClick={handleSave}
            />
          </Box>
        </Visible>
      </Box>
    </>
  );
}

NewDonationForm.propTypes = {
  onClose: func,
  preSelectedDonorId: string,
  onSuccess: func,
};

NewDonationForm.defaultProps = {
  onClose: EMPTY_FUNC,
  preSelectedDonorId: null,
  onSuccess: EMPTY_FUNC,
};
