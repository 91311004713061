import userActionTypes from 'rdx/actionTypes';

const { PUBLIC_USERS_REQUEST, PUBLIC_USERS_SUCCESS, PUBLIC_USERS_FAILURE } =
  userActionTypes;

const initialState = {
  isFetching: false,
  error: null,
  users: {},
};

let baseUserData;

export function publicUsers(state = initialState, action) {
  switch (action.type) {
    case PUBLIC_USERS_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: null,
      };
    case PUBLIC_USERS_SUCCESS: {
      baseUserData = action?.data?.user;
      const users = { ...state.users };

      // Update user in state
      if (baseUserData && baseUserData.userId) {
        // eslint-disable-next-line no-param-reassign
        users[baseUserData.userId] = baseUserData;
      }

      return {
        ...state,
        isFetching: false,
        users,
      };
    }
    case PUBLIC_USERS_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.error,
      };
    default:
      return state;
  }
}
