import { snakeCase } from 'voca';

/**
 * A helper util to join params
 * @param {Object} params
 */
export const joinNonEmptyParams = (params = {}) =>
  Object.entries(params)
    .reduce((acc, [key, value]) => {
      if (value !== undefined) {
        acc.push(
          `${encodeURIComponent(snakeCase(key))}=${encodeURIComponent(value)}`
        );
      }

      return acc;
    }, [])
    .join('&');
