import React, { useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Link from '@material-ui/core/Link';
import makeStyles from '@material-ui/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import { isMobileView } from 'lib/utils';
import { translate } from 'lib/intl';
import { EMPTY_FUNC } from 'lib/constants';
import { ADD_NEW_CONSTITUENT_CONFIG } from 'lib/constants/constituentConstants';

const useStyles = makeStyles((theme) => ({
  dialogTitle: {
    padding: 0,
  },
  dialogTitleText: {
    fontSize: '20px',
    fontWeight: 600,
  },
  content: {
    padding: 0,
  },
  card: {
    border: `1px solid ${theme.palette.grey.light}`,
    borderRadius: theme.shape.borderRadius[0],
    cursor: 'pointer',
    height: '156px',
    width: '220px',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
    '&:hover': {
      border: `1px solid ${theme.palette.primary.main}`,
    },
  },
  image: {
    height: '64px',
    marginBottom: theme.spacing(3),
    width: '64px',
  },
}));

const AddNewConstituentModalContent = ({ handleClose }) => {
  const classes = useStyles();
  const isMobile = isMobileView();
  const history = useHistory();

  const onCardClick = (path) => {
    handleClose();
    history.push(path);
  };

  const config = ADD_NEW_CONSTITUENT_CONFIG;

  const Cards = useMemo(
    () =>
      config.map(({ iconSrc, label, path }, idx) => (
        <Box
          alignItems="center"
          className={classes.card}
          data-testid={`${label}-card`}
          display="flex"
          idx={`${idx}`}
          justifyContent="center"
          key={label}
          mb={isMobile && idx === 0 ? 2 : 0}
          onClick={() => onCardClick(path)}
        >
          <Box alignItems="center" display="flex" flexDirection="column">
            <img alt={label} className={classes.image} src={iconSrc} />
            <Typography>{label}</Typography>
          </Box>
        </Box>
      )),
    [history, isMobile]
  );

  return (
    <>
      <Box
        alignItems="center"
        display="flex"
        justifyContent="space-between"
        p={3}
        pb={2}
      >
        <DialogTitle
          disableTypography
          id="edit-dialog-title"
          classes={{ root: classes.dialogTitle }}
        >
          <Typography className={classes.dialogTitleText}>
            {translate('CONSTITUENTS_ADD_CONSTITUENT_LABEL')}
          </Typography>
        </DialogTitle>
        <Link data-testid="close" onClick={handleClose}>
          {translate('CANCEL')}
        </Link>
      </Box>
      <DialogContent className={classes.content}>
        <Box
          display="flex"
          flexDirection={isMobile ? 'column' : 'row'}
          justifyContent="space-between"
          px={3}
          pb={4}
        >
          {Cards}
        </Box>
      </DialogContent>
    </>
  );
};

AddNewConstituentModalContent.propTypes = {
  handleClose: PropTypes.func,
};

AddNewConstituentModalContent.defaultProps = {
  handleClose: EMPTY_FUNC,
};

export default AddNewConstituentModalContent;
