import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Box, Typography, makeStyles } from '@material-ui/core';
import { colors } from 'styles/theme';
import { ListItemContainer, Avatar } from 'components';
import { getInitials } from 'lib/utils';
import { AUTH_ROUTES } from 'lib/constants';
import { useHistory } from 'react-router-dom';
import { useSetOwnerOrgPubId } from 'lib/hooks';

const useStyles = makeStyles((theme) => ({
  orgItem: {
    border: `1px solid ${colors.lightGrey}`,
    background: theme.palette.background.white,
    borderRadius: theme.shape.borderRadius,
    height: '72px',
    cursor: 'pointer',
    padding: '16px',
    '&:hover': {
      border: `1px solid ${theme.palette.primary.main}`,
    },
  },
}));

const OrgPickerItem = ({ orgOwnerId, orgName, orgImage }) => {
  const styles = useStyles();
  const history = useHistory();
  const { setOwnerOrgPubId } = useSetOwnerOrgPubId();

  const handleClick = () => {
    if (orgOwnerId) {
      setOwnerOrgPubId(orgOwnerId);
      history.push(AUTH_ROUTES.LANDING);
    } else {
      // eslint-disable-next-line no-console
      console.warn('No orgId found for org', orgName);
    }
  };

  return (
    <Grid
      item
      sm={6}
      xs={12}
      // TODO update with org ID before re-routing
      onClick={handleClick}
    >
      <ListItemContainer className={styles.orgItem}>
        <Box
          style={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Box>
            <Avatar size="40px" src={orgImage}>
              {getInitials(orgName, false)}
            </Avatar>
          </Box>
          <Box
            style={{
              display: 'flex',
              flexDirection: 'column',
              paddingLeft: '12px',
            }}
          >
            <Box>
              <Typography
                variant="button"
                style={{ letterSpace: '.25px', textTransform: 'capitalize' }}
              >
                {orgName}
              </Typography>
            </Box>
          </Box>
        </Box>
      </ListItemContainer>
    </Grid>
  );
};

OrgPickerItem.propTypes = {
  orgOwnerId: PropTypes.string.isRequired,
  orgName: PropTypes.string,
  orgImage: PropTypes.string,
};

OrgPickerItem.defaultProps = {
  orgName: '',
  orgImage: '',
};

export { OrgPickerItem };
