/* global d3 */
/* eslint no-shadow: 0 */

import { REPORTS_MONTHLY_TIME_PERIOD } from 'lib/constants';
import theme from 'styles/newTheme';
import { debounce } from 'rambdax';

const MOBILE_BREAKPOINT = '(max-width:450px)';

export default class Chart {
  constructor(options) {
    // The debug option allows for additional rendering of SVG elements that
    // help with viewing aspects of the SVG that would be otherwise invisible,
    // such as <group>s.
    this.debug = options.debug || false;
    this.id = options.id;
    this.transitionDuration = 200;
    this.width = 0;
    this.height = 0;
    this.xScale = null;
    this.yScale = null;
    this.timePeriod = REPORTS_MONTHLY_TIME_PERIOD;
    this.container = null;
    this.svg = null;
    this.isMounted = false;

    this.resizeListener = debounce(this.baseResize.bind(this), 100);

    this.mediaQueryList = null;
    this.baseMediaQueryListener = this.baseMediaQueryListener.bind(this);
  }

  destroy() {
    this.isMounted = false;
    this.container = null;
    this.svg = null;

    this.mediaQueryList.removeEventListener(
      'change',
      this.baseMediaQueryListener
    );
    window.removeEventListener('resize', this.resizeListener);
  }

  /**
   * The baseResize method provides a way to tap into the `isMounted` logic to
   * determine if we need to invoke any logic when resizing. By checking to see
   * if there is a `resize` method on the class (which would be added by a
   * child class) we reduce the need for `isMounted` logic in that child resize
   * method
   */
  baseResize(e) {
    if (!this.isMounted) {
      return;
    }

    this.setDimensions();

    if (typeof this.resize === 'function') {
      this.resize(e);
    }
  }

  baseMediaQueryListener(e) {
    if (!this.isMounted) {
      return;
    }

    if (typeof this.mediaQueryListener === 'function') {
      this.mediaQueryListener(e);
    }
  }

  calculateRelativeX(x) {
    return `${(x / this.width) * 100}%`;
  }

  setDimensions() {
    const { height, width } = this.container.node().getBoundingClientRect();

    this.height = height;
    this.width = width;
  }

  create(data) {
    this.isMounted = true;

    window.addEventListener('resize', this.resizeListener);

    this.mediaQueryList = window.matchMedia(MOBILE_BREAKPOINT);
    this.mediaQueryList.addEventListener('change', this.baseMediaQueryListener);

    this.data = data;
    this.container = d3.select(`#${this.id}`);
    this.svg = this.container.append('svg');
    let svgClasses = 'w-full h-full';

    if (this.debug) {
      svgClasses += ' border border-1';
    }

    this.svg.attr('class', svgClasses);

    this.setDimensions();
  }
}

Chart.removeDomain = (g) => g.select('.domain').remove();
Chart.setSmallFontSize = (g) => g.selectAll('text').attr('class', 'text-sm');

Chart.measureXAxisWidth = (selection) =>
  selection
    .nodes()
    .map((data, i, nodeList) => {
      return nodeList[i].getBBox().width;
    })
    .reduce((memo, width) => {
      // eslint-disable-next-line no-param-reassign
      memo += width;
      return memo;
    }, 0);

Chart.rotateXAxisText = (selection, hasTilt) =>
  selection
    .nodes()
    .forEach((data, i, nodeList) =>
      nodeList[i].setAttribute(
        'transform',
        hasTilt
          ? `rotate(45), translate(${theme.spacing(1.25)}, -${theme.spacing(
              1
            )})`
          : ''
      )
    );

// Svg <group>s are used widely with D3 charts yet they're hard to visualize
// because you can't add a border or background to them. This helper function
// adds a <rect> to the group that matches the size of the group to help with
// doing responsive development. It's not perfect but it's Good Enough™.
Chart.addDebugRectToGroup = (selection, canReverseTransform = false) => {
  const node = selection.node();
  const { height, width } = node.getBBox();
  const rect = document.createElementNS('http://www.w3.org/2000/svg', 'rect');

  rect.setAttributeNS(null, 'x', '0');
  rect.setAttributeNS(null, 'y', '0');
  // substract 2 pixels or else it will just grow and grow and grow and
  // grow... We make sure the height is greater than 2 to account for
  // subtraction, or else we can a negative value in certain instances that
  // throw annoying errors on first render of the rectangle.
  rect.setAttributeNS(null, 'height', height > 2 ? height - 2 : 0);
  rect.setAttributeNS(null, 'width', width > 2 ? width - 2 : 0);
  rect.setAttributeNS(null, 'fill', '#bada55');
  rect.setAttributeNS(null, 'stroke', '#000');
  rect.setAttributeNS(null, 'class', 'opacity-20');

  // If there is a transform on the parent group, we need to transform the rect in
  // reverse so that it stays within the bounds of the group. Failure to so
  // will have the rect grow and grow and grow... This only happens for certain
  // groups. Should probably try and figure that out at some point.
  const { transform } = node;

  if (transform.baseVal.length && canReverseTransform) {
    const { e: x, f: y } = transform.baseVal[0].matrix;
    rect.setAttributeNS(null, 'transform', `translate(-${x}, -${y})`);
  }

  node.appendChild(rect);

  const resizeObserver = new ResizeObserver((entries) => {
    rect.setAttributeNS(null, 'width', 0);
    rect.setAttributeNS(null, 'height', 0);

    const entry = entries[0];
    const { height, width } = entry.target.getBBox();

    rect.setAttributeNS(null, 'width', width > 2 ? width - 2 : 0);
    rect.setAttributeNS(null, 'height', height > 2 ? height - 2 : 0);
  });

  resizeObserver.observe(node);

  // Since this is debugging code, we're taking a short cut here to not remove
  // the event listener. Doing so would involve too much time and complexity
  // for something that should only be turned on by a dev who is going to be
  // refreshing the screen ad infinitum.
};
