import userActionTypes from 'rdx/actionTypes';

const initialState = {
  path: '',
  prevPath: '',
};

export function location(state = initialState, action) {
  switch (action.type) {
    case userActionTypes.SET_LOCATION:
      return {
        path: action.path,
        prevPath: state.path,
      };
    default:
      return state;
  }
}
