import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Controller } from 'react-hook-form';
import TextField from '@material-ui/core/TextField';
import InputMask from 'react-input-mask';

const useStyles = makeStyles((theme) => ({
  required: {
    '& span': {
      display: 'none',
    },
    '&:after': {
      content: '""',
      width: '6px',
      height: '6px',
      background: theme.palette.error.main,
      display: 'inline-block',
      marginBottom: theme.spacing(0.25),
      marginLeft: theme.spacing(0.75),
      borderRadius: '50%',
    },
  },
}));

export default function ControlledEINTextField({
  control,
  defaultValue,
  errors,
  label,
  name,
  rules,
  ...props
}) {
  const classes = useStyles();
  const invalid = !!errors?.[name];

  return (
    <Controller
      control={control}
      defaultValue={defaultValue}
      name={name}
      {...(rules && { rules })}
      render={({ onChange, value }) => {
        return (
          <InputMask
            mask="99-9999999"
            maskChar=" "
            onChange={onChange}
            onBlur={onChange}
            value={value}
          >
            {() => (
              <TextField
                InputLabelProps={{
                  classes: { required: classes.required },
                }}
                label={label}
                onChange={onChange}
                value={value}
                error={invalid}
                {...props}
              />
            )}
          </InputMask>
        );
      }}
    />
  );
}

ControlledEINTextField.propTypes = {
  control: PropTypes.object.isRequired,
  defaultValue: PropTypes.string.isRequired,
  errors: PropTypes.object,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  rules: PropTypes.object,
};

ControlledEINTextField.defaultProps = {
  errors: null,
  rules: null,
};
