import React, { useCallback } from 'react';
import styled from 'styled-components';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
} from '@material-ui/core';
import { prop } from 'rambdax';
import { colors } from 'styles/theme';
import { Visible } from 'components';
import { ASC, DESC, DEFAULT_COMPANY_FILTER, EMPTY_FUNC } from 'lib/constants';
import { CustomTableProps } from 'lib/utils';

const StyledTableContainer = styled(TableContainer)`
  display: flex;
  overflow: auto;

  &::-webkit-scrollbar {
    -webkit-appearance: none;
  }

  &::-webkit-scrollbar:vertical {
    width: 11px;
  }

  &::-webkit-scrollbar:horizontal {
    height: 11px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 8px;
    border: 2px solid ${colors.white};
    background-color: ${colors.scrollbarColor};
  }

  .MuiTableCell-stickyHeader {
    background-color: ${colors.white};
  }

  th.MuiTableCell-sizeSmall {
    padding: 8px 10px;
  }

  td.MuiTableCell-sizeSmall {
    padding: 16px 10px;
  }

  .MuiButtonBase-root.MuiTableSortLabel-root {
    flex-direction: row;
    font-size: 12px;
    font-weight: 600;
  }

  .MuiTableCell-sizeSmall {
    font-size: 12px;
    font-weight: 600;
  }
`;

const StyledTableRow = styled(TableRow)`
  cursor: ${prop('cursor')};
`;

// TODO: create CustomTableCell component and make 'left' to be the default `align` value
const renderCells = (columns, row) =>
  columns?.map(({ id, field, align = 'left', renderCell, valueFormatter }) => {
    const value = valueFormatter
      ? valueFormatter({ value: row[field] })
      : row[field];

    const CellContent = renderCell ? renderCell({ value, data: row }) : value;

    return (
      <TableCell key={`custom-table-cell-${id}`} align={align}>
        {CellContent}
      </TableCell>
    );
  });

const CustomTable = ({
  columns,
  data,
  idField,
  onRowClick,
  tableStyles,
  filter,
  setFilter,
}) => {
  const { order, orderBy } = filter;

  const createSortHandler = useCallback(
    (property) => () => {
      const isAsc = orderBy === property && order === ASC;

      setFilter({
        ...filter,
        order: isAsc ? DESC : ASC,
        orderBy: property,
      });
    },
    [filter]
  );

  const handleRowClick = useCallback(
    (rowData) => (event) =>
      onRowClick ? onRowClick({ data: rowData, element: event.target }) : null,
    []
  );

  // TODO: create CustomTableHeaderCell component and make 'left' to be the default `align` value
  const HeaderCells = columns?.map(
    ({ id, field, align = 'left', headerName, sortable = true, style }) => {
      const key = `custom-table-header-cell-${id}`;

      return (
        <TableCell
          key={key}
          align={align}
          sortDirection={orderBy === field ? order : false}
          style={style}
        >
          <Visible when={sortable} fallback={headerName}>
            <TableSortLabel
              active={orderBy === field}
              data-testid={key}
              direction={orderBy === field ? order : ASC}
              onClick={createSortHandler(field)}
            >
              {headerName}
            </TableSortLabel>
          </Visible>
        </TableCell>
      );
    }
  );

  const TableRows = data?.map((row, idx) => {
    const key = `custom-table-row-${row[idField] || idx}`;

    return (
      <StyledTableRow
        cursor={onRowClick ? 'pointer' : 'default'}
        hover
        onClick={handleRowClick(row)}
        data-testid="custom-table-row"
        key={key}
      >
        {renderCells(columns, row)}
      </StyledTableRow>
    );
  });

  return (
    <StyledTableContainer style={tableStyles}>
      <Table stickyHeader size="small">
        <TableHead>
          <TableRow>{HeaderCells}</TableRow>
        </TableHead>
        <TableBody>{TableRows}</TableBody>
      </Table>
    </StyledTableContainer>
  );
};

CustomTable.propTypes = CustomTableProps;

CustomTable.defaultProps = {
  data: null,
  filter: DEFAULT_COMPANY_FILTER,
  onRowClick: null,
  setFilter: EMPTY_FUNC,
  tableStyles: {},
};

export { CustomTable };
