import { formatCurrency, formatDate, formatNumber } from 'lib/utils';
import { CUSTOM_DATE_FORMAT } from 'lib/constants';
import { CAMPAIGN_CATEGORIES } from 'lib/configs';

const mapCampaignsForExport = (campaigns) =>
  campaigns.map(
    ({
      name,
      category,
      description,
      url,
      startDate,
      endDate,
      goalAmount,
      totalAmount,
      numDonorConstituents,
      maxDonation,
      numReturningDonors,
      numNewDonors,
    }) => ({
      name,
      category: CAMPAIGN_CATEGORIES.find((cat) => cat.value === category).text,
      description,
      url,
      startDate: formatDate(startDate, CUSTOM_DATE_FORMAT),
      endDate: formatDate(endDate, CUSTOM_DATE_FORMAT),
      goalAmount: formatCurrency(goalAmount, true),
      totalAmount: formatCurrency(totalAmount, true),
      numDonorConstituents: formatNumber(numDonorConstituents, true),
      maxDonation: formatCurrency(maxDonation, true),
      numReturningDonors: formatNumber(numReturningDonors),
      numNewDonors: formatNumber(numNewDonors),
    })
  );

export { mapCampaignsForExport };
