import { feedService } from 'services/feed';
import { createAction } from 'lib/utils';

export default function createFetchFeedAction(types) {
  return ({ ownerOrgId, pageFrom = 0, ...params }) =>
    async (dispatch) => {
      try {
        dispatch(createAction({ type: types.request }));

        const response = await feedService.fetchFeed({
          ownerOrgId,
          pageFrom,
          ...params,
        });

        dispatch(createAction({ type: types.success, data: response?.data }));
      } catch (error) {
        dispatch(createAction({ type: types.failure, error }));
      }
    };
}
