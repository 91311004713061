import React from 'react';
import PropTypes from 'prop-types';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/styles/makeStyles';
import useTheme from '@material-ui/styles/useTheme';
import { colors } from 'styles/theme';
import { ASC, DESC, DEFAULT_COMPANY_FILTER, EMPTY_FUNC } from 'lib/constants';

const initCellStyles = (paddingLeft) =>
  makeStyles((theme) => ({
    tableCellSizeSmall: {
      padding: `${theme.spacing(2)}px ${theme.spacing(1.25)}px`,
      fontSize: theme.typography.body2.fontSize,
      '&:last-child': {
        paddingRight: theme.spacing(1.25),
      },
      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(0.75),
      },
    },

    tableCellRoot: {
      padding: theme.spacing(1.5),
      [theme.breakpoints.down('sm')]: {
        display: 'block',
        position: 'relative',
        paddingTop: theme.spacing(0.375),
        paddingBottom: theme.spacing(0.375),
        paddingLeft: `${paddingLeft + theme.spacing(2)}px`,
        paddingRight: theme.spacing(2),
        borderStyle: 'none',

        // set all cell content to have same line height for standardized layout on mobile
        '& > *': {
          lineHeight: `${theme.spacing(3)}px`,
        },

        '&:before': {
          position: 'absolute',
          top: theme.spacing(0.25),
          left: 0,
          width: 'auto',
          whiteSpace: 'nowrap',
          fontWeight: 600,
          content: 'attr(data-content)',
          ...theme.typography.subtitle1,
        },
      },
    },
  }));

const CustomTableCell = ({ align, columnName, paddingLeft, id, children }) => {
  const classes = initCellStyles(paddingLeft)();

  return (
    <TableCell
      align={align}
      classes={{
        root: classes.tableCellRoot,
        sizeSmall: classes.tableCellSizeSmall,
      }}
      data-content={columnName}
      key={`custom-table-cell-${id}`}
    >
      {children}
    </TableCell>
  );
};

CustomTableCell.propTypes = {
  align: PropTypes.string,
  children: PropTypes.node.isRequired,
  columnName: PropTypes.string.isRequired,
  id: PropTypes.string,
  paddingLeft: PropTypes.number.isRequired,
};

CustomTableCell.defaultProps = {
  id: '',
  align: 'left',
};

// TODO: create CustomTableCell component and make 'left' to be the default `align` value
const renderCells = (classes, columns, row, maxHeaderTextLength) =>
  columns?.map(({ id, field, align, renderCell, valueFormatter }, idx) => {
    const value = valueFormatter
      ? valueFormatter({ value: row[field] })
      : row[field];
    const CellContent = renderCell ? renderCell({ value, data: row }) : value;

    return (
      <CustomTableCell
        align={align}
        classes={{
          sizeSmall: classes.tableCellSizeSmall,
        }}
        columnName={columns[idx].headerName}
        key={`custom-table-cell-${id}`}
        paddingLeft={maxHeaderTextLength}
      >
        {CellContent}
      </CustomTableCell>
    );
  });

const useStyles = makeStyles((theme) => ({
  tableContainerRoot: {
    display: 'flex',
    overflow: 'auto',
    '&::-webkit-scrollbar': {
      '-webkit-appearance': 'none',
    },
    '&::-webkit-scrollbar:vertical': {
      width: '11px',
    },
    '&::-webkit-scrollbar:horizontal': {
      height: '11px',
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: theme.spacing(1),
      border: `2px solid ${theme.palette.common.white}`,
      backgroundColor: colors.scrollbarColor,
    },
    [theme.breakpoints.down('sm')]: {
      width: `calc(100% + ${theme.spacing(4)}px)`,
      marginLeft: -theme.spacing(2),
      marginRight: -theme.spacing(2),
    },
  },

  tableRoot: {
    [theme.breakpoints.down('sm')]: {
      display: 'block',
    },
  },

  tableCellStickyHeader: {
    backgroundColor: theme.palette.common.white,
  },

  tableHeaderRoot: {
    [theme.breakpoints.down('sm')]: {
      display: 'block',
    },
  },
  tableBodyRoot: {
    [theme.breakpoints.down('sm')]: {
      display: 'block',
    },
  },
  tableHeaderCellSizeSmall: {
    padding: `${theme.spacing(1)}px ${theme.spacing(1.25)}px`,
    fontSize: theme.typography.body2.fontSize,
    fontWeight: theme.typography.body2.fontWeight,
    '&:last-child': {
      paddingRight: theme.spacing(1.25),
    },
  },
  tableHeaderRowRoot: {
    [theme.breakpoints.down('sm')]: {
      position: 'absolute',
      top: '-9999px',
      left: '-9999px',
    },
  },

  tableSortLabelRoot: {
    flexDirection: 'row',
    ...theme.typography.subtitle1,
  },

  tableRowRoot: {
    cursor: ({ cursor }) => cursor,
    [theme.breakpoints.down('sm')]: {
      borderTop: `1px solid ${theme.palette.grey.light}`,
      '&:last-child': {
        borderBottom: `1px solid ${theme.palette.grey.light}`,
      },
      display: 'block',
      padding: `${theme.spacing(1.125)}px ${theme.spacing(1.5)}px`,
    },
  },
  tableRowHover: {
    '&:hover': {
      backgroundColor({ useNewTheme }) {
        return `${
          useNewTheme ? theme.pallete.grey[100] : '#F8F8F8'
        } !important`;
      },
    },
  },
}));

export default function CustomTable({
  columns,
  data,
  filter,
  idField,
  onRowClick,
  setFilter,
  tableStyles,
  useNewTheme,
}) {
  const classes = useStyles({
    cursor: onRowClick ? 'pointer' : 'default',
    useNewTheme,
  });

  const { order, orderBy } = filter;

  const createSortHandler = React.useCallback(
    (property) => () => {
      const isAsc = orderBy === property && order === ASC;

      setFilter({
        ...filter,
        order: isAsc ? DESC : ASC,
        orderBy: property,
      });
    },
    [filter]
  );

  const handleRowClick = React.useCallback(
    (rowData) => (event) =>
      onRowClick ? onRowClick({ data: rowData, element: event.target }) : null,
    []
  );

  const theme = useTheme();
  const maxHeaderTextLength = React.useMemo(() => {
    let greatestWidth = 0;

    columns.forEach((column) => {
      const span = document.createElement('span');
      span.style.fontSize = theme.typography.subtitle2.fontSize;
      span.textContent = column.headerName;
      document.body.appendChild(span);
      const { width } = span.getBoundingClientRect();

      if (width > greatestWidth) {
        greatestWidth = width;
      }

      span.parentNode.removeChild(span);
    });

    return greatestWidth;
  }, [columns]);

  return (
    <TableContainer
      classes={{ root: classes.tableContainerRoot }}
      style={tableStyles}
    >
      <Table stickyHeader size="medium">
        <TableHead
          classes={{
            root: classes.tableHeaderRoot,
          }}
        >
          <TableRow
            classes={{
              root: classes.tableHeaderRowRoot,
            }}
          >
            {columns?.map(
              ({
                id,
                field,
                align = 'left',
                headerName,
                sortable = true,
                style,
              }) => {
                const key = `custom-table-header-cell-${id}`;
                return (
                  <TableCell
                    classes={{
                      sizeSmall: classes.tableHeaderCellSizeSmall,
                      stickyHeader: classes.tableCellStickyHeader,
                    }}
                    key={key}
                    align={align}
                    sortDirection={orderBy === field ? order : false}
                    variant="head"
                    style={style}
                  >
                    {sortable ? (
                      <TableSortLabel
                        classes={{
                          root: classes.tableSortLabelRoot,
                        }}
                        active={orderBy === field}
                        data-testid={key}
                        direction={orderBy === field ? order : ASC}
                        onClick={createSortHandler(field)}
                      >
                        {headerName}
                      </TableSortLabel>
                    ) : (
                      <Typography variant="body2">{headerName}</Typography>
                    )}
                  </TableCell>
                );
              }
            )}
          </TableRow>
        </TableHead>

        <TableBody>
          {data?.map((row, idx) => (
            <TableRow
              cursor={onRowClick ? 'pointer' : 'default'}
              classes={{
                root: classes.tableRowRoot,
                hover: classes.tableRowHover,
              }}
              hover
              onClick={handleRowClick(row)}
              data-testid="custom-table-row"
              key={`custom-table-row-${row[idField] || idx}`}
            >
              {renderCells(classes, columns, row, maxHeaderTextLength)}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

CustomTable.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      field: PropTypes.string,
      align: PropTypes.oneOf(['left', 'right', 'center']),
      getComparableValue: PropTypes.func,
      headerName: PropTypes.node.isRequired,
      width: PropTypes.number,
      renderCell: PropTypes.func,
      sortable: PropTypes.bool,
      disableClickEventBubbling: PropTypes.bool,
      valueFormatter: PropTypes.func,
      style: PropTypes.shape({}),
    })
  ),
  data: PropTypes.arrayOf(PropTypes.shape({})),
  filter: PropTypes.shape({
    searchValue: PropTypes.string,
    order: PropTypes.oneOf(['asc', 'desc']),
    orderBy: PropTypes.string,
  }),
  idField: PropTypes.string.isRequired,
  onRowClick: PropTypes.func,
  setFilter: PropTypes.func,
  tableStyles: PropTypes.shape({}),
  useNewTheme: PropTypes.bool,
};

CustomTable.defaultProps = {
  columns: [],
  data: null,
  filter: DEFAULT_COMPANY_FILTER,
  onRowClick: null,
  setFilter: EMPTY_FUNC,
  tableStyles: {},
  useNewTheme: false,
};
