import React from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import { makeStyles } from '@material-ui/core/styles';
import {
  ControlledEINTextField,
  ControlledPhoneInput,
} from 'components/common/form';
import { translate } from 'lib/intl';
import { phoneNumberRegex } from 'components/auth/authConstants';

const EIN_NAME = 'ein';
const PRIMARY_PHONE_NAME = 'primaryPhone';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
  },
  toggleButton: {
    borderBottom: `1px solid ${theme.palette.primaryLink.main}`,
    borderRadius: 0,
    fontSize: theme.typography.body2.fontSize,
    fontWeight: theme.typography.fontWeightMedium,
    padding: theme.spacing(0.5),
    textDecoration: 'none',
    textTransform: 'none',
    '&:hover': {
      borderBottom: `1px solid ${theme.palette.primaryLink.light}`,
    },
    '&:focus': {
      borderBottom: `1px solid ${theme.palette.primaryLink.light}`,
    },
    '&:active': {
      borderBottom: `1px solid ${theme.palette.primaryLink.dark}`,
    },
  },
  toggleButtonTextPrimary: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
}));

export default function ControlledOrgIdInputs({
  clearErrors,
  control,
  errors,
  watch,
}) {
  const watchedPrimaryPhone = watch(PRIMARY_PHONE_NAME);
  const watchedEIN = watch(EIN_NAME);
  const [isEIN, setIsEIN] = React.useState(true);
  const toggleEIN = () => setIsEIN((prev) => !prev);

  const Wrapper = isEIN ? Box : FormControl;

  const classes = useStyles({
    isEIN,
  });

  React.useEffect(() => {
    clearErrors(isEIN ? PRIMARY_PHONE_NAME : EIN_NAME);
  }, [clearErrors, isEIN]);

  return (
    <>
      <Wrapper className={classes.container}>
        {isEIN ? (
          <ControlledEINTextField
            control={control}
            defaultValue={watchedEIN}
            errors={errors}
            fullWidth
            flex={1}
            label={translate('FORMS_EIN_LABEL')}
            name={EIN_NAME}
            required
            rules={{
              required: translate('FORMS_VALIDATION_REQUIRED'),
              pattern: {
                value: /^\d{2}-?\d{7}$/,
                message: translate('FORMS_VALIDATION_FORMAT_EIN'),
              },
            }}
          />
        ) : (
          <ControlledPhoneInput
            control={control}
            defaultValue={watchedPrimaryPhone}
            errors={errors}
            label={translate('FORMS_ORG_PHONE_LABEL')}
            name={PRIMARY_PHONE_NAME}
            required
            rules={{
              pattern: {
                value: phoneNumberRegex,
                message: translate('FORMS_VALIDATION_FORMAT_PHONE'),
              },
              validate: {
                required(value) {
                  return value === '+1'
                    ? translate('FORMS_VALIDATION_REQUIRED')
                    : undefined;
                },
              },
            }}
          />
        )}
        <Box
          alignItems="flex-end"
          display="flex"
          flex="1 0 auto"
          flexDirection="row"
          ml={2}
        >
          <Button
            classes={{
              root: classes.toggleButton,
              textPrimary: classes.toggleButtonTextPrimary,
            }}
            color="primary"
            data-testid="org-id-inputs"
            onClick={toggleEIN}
            role="button"
            tabIndex={0}
          >
            {translate(isEIN ? 'FORMS_TOGGLE_NO_EIN' : 'FORMS_TOGGLE_USE_EIN')}
          </Button>
        </Box>
      </Wrapper>
      {Object.keys(errors)
        .filter((key) => [EIN_NAME, PRIMARY_PHONE_NAME].includes(key))
        .map((key) => (
          <FormHelperText key={key} error>
            {errors[key].message}
          </FormHelperText>
        ))}
    </>
  );
}

ControlledOrgIdInputs.propTypes = {
  clearErrors: PropTypes.func.isRequired,
  control: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  watch: PropTypes.func.isRequired,
};
