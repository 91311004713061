import React from 'react';
import { Typography, Popover, Box } from '@material-ui/core';
import styled from 'styled-components';
import { bool, func, string } from 'prop-types';
import { CustomButton, Gap } from 'components';
import { translate } from 'lib/intl';

const StyledPopover = styled(Popover)`
  margin: 0 10px;
`;

const StyledPopoverContent = styled(Box)`
  width: 275px;
`;

const ConfirmationPopup = ({
  confirmButtonLabel,
  message,
  onConfirm,
  onClose,
  open,
  ...rest
}) => (
  <StyledPopover
    open={open}
    onClose={onClose}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...rest}
  >
    <StyledPopoverContent p={3}>
      <Typography>{message}</Typography>
      <Box
        display="flex"
        flex="1"
        flexDirection="row"
        justifyContent="space-between"
        pt={2}
      >
        <CustomButton
          buttonType="link"
          color="primary"
          dataTestId="confirmation-popup-cancel-button"
          label={translate('CANCEL')}
          onClick={onClose}
        />
        <Gap />
        <CustomButton
          buttonType="primary"
          dataTestId="confirmation-popup-confirm-button"
          label={confirmButtonLabel}
          onClick={onConfirm}
        />
      </Box>
    </StyledPopoverContent>
  </StyledPopover>
);

ConfirmationPopup.propTypes = {
  confirmButtonLabel: string.isRequired,
  message: string.isRequired,
  onConfirm: func.isRequired,
  onClose: func.isRequired,
  open: bool.isRequired,
};

export { ConfirmationPopup };
