import React from 'react';
import { Box, makeStyles } from '@material-ui/core';
import { CampaignDonationType } from 'lib/configs/donations';
import {
  DonationAmount,
  DonationMethod,
  DonationDonor,
  DonationType,
  DonationSource,
  DonationDate,
} from './CampaignDonation';

const useStyles = makeStyles((theme) => ({
  container: {
    borderBottom: `1px solid ${theme.palette.grey.light}`,
    margin: `0 ${theme.spacing(1.5)}px`,
    padding: theme.spacing(2),
  },
}));

const CampaignDonationCard = ({ ...donation }) => {
  const styles = useStyles();

  const {
    amount,
    donationId,
    donorDisplayName,
    donationDateString,
    donationTypeEnum,
    paymentTypeEnum,
  } = donation;

  return (
    <Box
      className={styles.container}
      data-testid={`${donationId}-campaign-donation-card`}
    >
      <Box width="fit-content">
        <DonationDonor data={donation} value={donorDisplayName} />
      </Box>
      <Box
        alignItems="center"
        display="flex"
        justifyContent="space-between"
        py={1.5}
      >
        <DonationType data={donation} value={donationTypeEnum} />
        <Box display="flex">
          <DonationAmount data={donation} value={amount} />
          <DonationMethod data={donation} value={paymentTypeEnum} />
        </Box>
      </Box>
      <Box display="flex" justifyContent="space-between">
        <DonationSource data={donation} />
        <DonationDate value={donationDateString} />
      </Box>
    </Box>
  );
};

CampaignDonationCard.propTypes = CampaignDonationType;

CampaignDonationCard.defaultProps = {
  amount: 0,
  paymentTypeEnum: '',
  timeCategory: '',
  time: 0,
};

export { CampaignDonationCard };
