import React from 'react';
import useDebouncedEffect from 'lib/hooks/useDebouncedEffect';
import { searchConstituents } from 'services/constituentServices';
import { useSelector } from 'react-redux';

const DEFAULT_PARAMS = {
  displayName: '',
  pageFrom: 0,
  pageSize: 100,
  sortBy: 'display_name',
  sortOrder: 'asc',
};

const useConstituentSearch = (initialParams = {}, debounce = 300) => {
  const { ownerOrgId } = useSelector((state) => ({
    ownerOrgId: state.authentication?.ownerOrgId || '',
  }));
  const [isFetching, setIsFetching] = React.useState(false);
  const [results, setResults] = React.useState([]);
  const [searchParams, setSearchParams] = React.useState(initialParams);
  const [total, setTotal] = React.useState(null);

  useDebouncedEffect(
    () => {
      if (!ownerOrgId) return;
      (async () => {
        const params = {
          ...DEFAULT_PARAMS,
          ...searchParams,
        };
        setIsFetching(true);
        try {
          const { data } = await searchConstituents({ ownerOrgId, params });
          setResults(data.constituents);
          setTotal(data.totalHits);
        } catch (e) {
          // do something with error here?
        } finally {
          setIsFetching(false);
        }
      })();
    },
    [ownerOrgId, searchParams],
    debounce
  );

  return { isFetching, results, searchParams, setSearchParams, total };
};

export default useConstituentSearch;
