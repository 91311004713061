import userActionTypes from 'rdx/actionTypes';

// TODO(jon): I originally started writing out a reducer for deleting donations,
// but found that this state isn't being used by ANYTHING. So not going to waste
// my time. This all is probably worth deleting in a future refactor.
const initialState = {
  donations: {},
  isLoading: false,
  errors: null,
};

const { DONATION_REQUEST, DONATION_SUCCESS, DONATION_FAILURE } =
  userActionTypes;

export function donations(state = initialState, action) {
  switch (action.type) {
    case DONATION_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case DONATION_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case DONATION_FAILURE:
      return {
        ...state,
        isLoading: false,
        errors: action.errors,
      };
    default:
      return state;
  }
}
