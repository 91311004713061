import React, { useState } from 'react';
import Box from '@material-ui/core/Box';
import Dialog from '@material-ui/core/Dialog';
import Typography from '@material-ui/core/Typography';
import Popover from '@material-ui/core/Popover';
import makeStyles from '@material-ui/styles/makeStyles';
import differenceInDays from 'date-fns/differenceInDays';
import { translate } from 'lib/intl';
import { isMobileView } from 'lib/utils';
import {
  CustomLink,
  GoalsProgress,
  GoalsForm,
  Loader,
  VisitStreak,
} from 'components';
import { ShowForViews } from 'components/common/ShowForViews';
import { LOADER_SIZES, USER_VIEW_ENUMS } from 'lib/constants';
import { useGoals, useVisitStreak } from 'lib/hooks/goals';

const useStyles = makeStyles((theme) => ({
  container: {
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
    },
  },
  loaderContainer: {
    paddingTop: theme.spacing(3.75),
    [theme.breakpoints.down('sm')]: {
      alignItems: 'center',
      display: 'flex',
      padding: theme.spacing(3),
    },
  },
  horizontalScrollContainer: {
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      flexWrap: 'nowrap',
      minHeight: '100px',
      overflowY: 'hidden',
      overflowX: 'auto',
      paddingLeft: theme.spacing(1.5),
      whiteSpace: 'nowrap',
      msOverflowStyle: 'none',
      webkitOverflowScrolling: 'touch',
      scrollbarWidth: 'none',
      '&::-webkit-scrollbar': {
        display: 'none',
      },
      '&::after': {
        content: '""',
        minWidth: theme.spacing(0.5),
      },
    },
  },
  goalHeaderContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      padding: `0 ${theme.spacing(2)}px`,
    },
  },
  addGoalContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    paddingTop: theme.spacing(1.25),
  },
  paper: {
    minWidth: '304px',
  },
}));

const GoalsSection = () => {
  const isMobile = isMobileView();
  const classes = useStyles();

  const [isFormOpen, setIsFormOpen] = useState(false);
  const [editingGoal, setEditingGoal] = useState(null);

  const anchor = React.useRef(null);

  const {
    visitStreakText,
    visitStreakEncouragementText,
    visitStreakIsFetching,
  } = useVisitStreak();

  const { goals, summaryMetrics, isFetching, isUpdating } = useGoals();

  const handleEdit = (e, goal) => {
    setEditingGoal(goal);
    setIsFormOpen(true);
  };

  const handleNew = () => {
    setIsFormOpen(true);
  };

  const handleClose = () => {
    setIsFormOpen(false);
    setEditingGoal(null);
  };

  const goal = goals?.length ? goals[0] : null;

  const showAddGoals = !goal && !isFetching && !isUpdating;

  const AddGoalButton = showAddGoals ? (
    <ShowForViews showFor={[USER_VIEW_ENUMS.ADMIN, USER_VIEW_ENUMS.EDITOR]}>
      <CustomLink data-testid="add-goal-btn" onClick={handleNew}>
        {translate('GOALS_ADD')}
      </CustomLink>
    </ShowForViews>
  ) : null;

  const currentDate = new Date();
  const daysLeft = differenceInDays(
    new Date(currentDate.getFullYear() + 1, 0),
    currentDate
  );

  return (
    <>
      <Box className={classes.goalHeaderContainer}>
        <Typography color="textPrimary" variant="subtitle1">
          {translate('GOALS_TITLE')}
        </Typography>
        {isMobile && AddGoalButton}
      </Box>
      <Box className={classes.horizontalScrollContainer} ref={anchor}>
        <Box className={classes.container}>
          {!isFetching && !isUpdating ? (
            <>
              {goal && (
                <GoalsProgress
                  daysLeft={daysLeft}
                  onEditClick={(e) => handleEdit(e, goal)}
                  goalTitle={goal?.name}
                  goalAmount={goal?.target}
                  goalCurrentAmount={summaryMetrics?.yearToDate?.totalRevenue}
                  goalProjectedRate={goal?.expectation}
                />
              )}
              {!isMobile && (
                <Box className={classes.addGoalContainer}>{AddGoalButton}</Box>
              )}
              {isMobile ? (
                <Dialog
                  classes={{ paper: classes.paper }}
                  onClose={handleClose}
                  open={isFormOpen}
                >
                  <GoalsForm onClose={handleClose} existingGoal={editingGoal} />
                </Dialog>
              ) : (
                <Popover
                  data-testid="goals-section-menu-button"
                  id="goals-section-form-container"
                  anchorEl={anchor?.current}
                  classes={{ paper: classes.paper }}
                  transformOrigin={{ horizontal: 10, vertical: 0 }}
                  onClose={handleClose}
                  open={isFormOpen}
                >
                  <GoalsForm onClose={handleClose} existingGoal={editingGoal} />
                </Popover>
              )}
            </>
          ) : (
            <Box className={classes.loaderContainer}>
              <Loader isCentered size={LOADER_SIZES.SM} />
            </Box>
          )}
        </Box>
        <VisitStreak
          streakCountText={visitStreakText}
          streakEncouragementText={visitStreakEncouragementText}
          isFetching={visitStreakIsFetching}
        />
      </Box>
    </>
  );
};

export { GoalsSection };
