import React from 'react';
import PropTypes from 'prop-types';

export const D3ChartTooltipContext = React.createContext();

export default function D3ChartTooltipProvider({ children }) {
  // The anchor ref is what popper.js uses to determine where to position the
  // tooltip. The anchor ref is the D3 generated <rect>.
  // Borrowed from <Tooltip> implementation.
  const [anchorRef, setAnchorRef] = React.useState(document.documentElement);
  const [showTooltip, setShowTooltip] = React.useState(false);

  // The usage of the anchor ref in the mouse events is to provide an "anchor"
  // for popper.js. These mouseover events get passed to the chart <rect>
  // elements. On mouseover, we update the ref, which tells the <Popper>
  // component (which tells popper.js) "use this element to anchor the tooltip
  // with". On mouseout, we update the anchor ref to be the document, causing
  // the tooltip to not be anchored to any specific DOM element.
  function handleMouseover(event) {
    setAnchorRef(document.querySelector(`[data-anchor=${event.target.id}]`));
    setShowTooltip(true);
  }

  function handleMouseleave() {
    setShowTooltip(false);
    setAnchorRef(document.documentElement);
  }

  return (
    <D3ChartTooltipContext.Provider
      value={{
        anchor: anchorRef,
        handleMouseover,
        handleMouseleave,
        showTooltip,
      }}
    >
      {children}
    </D3ChartTooltipContext.Provider>
  );
}

D3ChartTooltipProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
