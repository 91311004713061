import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { fetchReport } from 'rdx/actions/reportActions';
import ConstituentIconTiles from 'components/constituents/ConstituentIconTiles';
import ConstituentAvatar from 'modules/ConstituentListView/components/ConstituentAvatar';
import PageSizes from 'lib/constants/PageSizes';
import { ReportMetaPropType } from 'lib/types/ReportModulesPropType';
import ReportFilterPropType, {
  SortField,
  SortDirection,
} from 'lib/types/ReportFilterPropType';
import Pagination from '@material-ui/lab/Pagination';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import classNames from 'classnames';
import makeStyles from '@material-ui/styles/makeStyles';
import { EMPTY_FUNC } from 'lib/constants';
import { DONOR_LIST } from 'lib/constants/reports';
import { translate } from 'lib/intl';
import { formatCurrencyWithDecimals } from 'lib/utils/currency';
import { useFetchReportPageData } from './useFetchReportPageData';

const useStyles = makeStyles((theme) => ({
  tbody: {
    '& td': {
      border: 'none',
      [theme.breakpoints.down('sm')]: {
        alignItems: 'baseline',
        display: 'flex',
        padding: '6px 0',
      },
    },
  },
  avatarCell: {
    [theme.breakpoints.down('sm')]: {
      maxWidth: 'calc(100% - 90px)',
    },
  },
}));

const DonorListReport = ({ filters, onSort, pagination }) => {
  const { donorListResults, page, updatePage } = useFetchReportPageData();
  const dispatch = useDispatch();
  const styles = useStyles();

  const onSortClick = React.useCallback(
    (property) => () => {
      // If the sortBy property has not changed, toggle the sort order between
      // "asc" or "desc". If the sortBy property has changed, default to "asc"
      // unless this is sorting by interaction date, in which case show the most
      // recent first, e.g. "desc").

      const hasPropertyChanged = property !== filters.sortField;
      let sortDirection;
      if (!hasPropertyChanged) {
        sortDirection =
          filters.sortDirection === SortDirection.ASC
            ? SortDirection.DESC
            : SortDirection.ASC;
      } else {
        sortDirection =
          property === SortField.DONATED
            ? SortDirection.DESC
            : SortDirection.ASC;
      }
      onSort({ sortField: property, sortDirection });

      dispatch(
        fetchReport({
          ...filters,
          moduleType: DONOR_LIST,
          sortField: property,
          sortDirection,
          page: 1,
        })
      );
    },
    [filters]
  );

  const onPageChange = (e, value) => {
    if (value === page) return;
    updatePage(value);
  };

  const totalPages = Math.ceil((pagination.count || 0) / PageSizes[0]);

  return (
    <div>
      <h2 className="text-xl">
        {translate('REPORTS_DONOR_LIST')} ({pagination.count ?? '-'})
      </h2>
      <TableContainer>
        <Table>
          <TableHead className="hidden md:table-header-group">
            <TableRow>
              <TableCell>
                <TableSortLabel
                  active={filters.sortField === SortField.DISPLAY_NAME}
                  direction={filters.sortDirection}
                  onClick={onSortClick(SortField.DISPLAY_NAME)}
                >
                  {translate('NAME')}
                </TableSortLabel>
              </TableCell>
              <TableCell>{translate('CATEGORIES')}</TableCell>
              <TableCell className="text-right">
                <TableSortLabel
                  active={filters.sortField === SortField.DONATED}
                  direction={filters.sortDirection}
                  onClick={onSortClick(SortField.DONATED)}
                >
                  {translate('CAMPAIGN_MONETARY_LABEL')}
                </TableSortLabel>
              </TableCell>
              <TableCell className="text-right">
                {translate('CAMPAIGN_IN_KIND_LABEL')}
              </TableCell>
              <TableCell className="text-right">
                {translate('DONATION_TIME')}
              </TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody className={classNames(styles.tbody, 'md:table-row-group')}>
            {donorListResults.map((constituent, i) => (
              <TableRow
                key={constituent.constituentId}
                className={classNames(
                  { 'md:border-t': !i },
                  'flex flex-wrap border-b border-grey-300 items-center md:table-row p-3 md:p-0'
                )}
              >
                <TableCell
                  className={classNames(
                    styles.avatarCell,
                    'flex-shrink order-2'
                  )}
                >
                  <ConstituentAvatar constituent={constituent} />
                </TableCell>
                <TableCell className="order-last w-full md:w-auto">
                  <div className="block md:hidden w-1/3 text-base font-semibold">
                    {translate('CONSTITUENT_CATEGORY')}
                  </div>
                  <ConstituentIconTiles constituent={constituent} />
                </TableCell>
                <TableCell className="order-last w-full md:w-auto md:text-right">
                  <div className="block md:hidden w-1/3 text-base font-semibold">
                    {translate('CAMPAIGN_MONETARY_LABEL')}
                  </div>
                  {constituent.donated === 0
                    ? '0'
                    : formatCurrencyWithDecimals(constituent.donated, true)}
                </TableCell>
                <TableCell className="order-last w-full md:w-auto md:text-right">
                  <div className="block md:hidden w-1/3 text-base font-semibold">
                    {translate('CAMPAIGN_IN_KIND_LABEL')}
                  </div>
                  {constituent.totalInKind === 0
                    ? '0'
                    : formatCurrencyWithDecimals(constituent.totalInKind, true)}
                </TableCell>
                <TableCell className="order-last w-full md:w-auto md:text-right">
                  <div className="block md:hidden w-1/3 text-base font-semibold">
                    {translate('DONATION_HOURS')}
                  </div>
                  {constituent.totalHours}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {!!donorListResults.length && (
        <div className="flex flex-col items-center mt-6">
          <Pagination
            count={totalPages}
            page={page}
            onChange={onPageChange}
            shape="rounded"
          />
        </div>
      )}
    </div>
  );
};

DonorListReport.propTypes = {
  filters: ReportFilterPropType.isRequired,
  onSort: PropTypes.func,
  pagination: ReportMetaPropType,
};

DonorListReport.defaultProps = {
  onSort: EMPTY_FUNC,
  pagination: { count: null },
};

export default DonorListReport;
