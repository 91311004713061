import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery, useTheme } from '@material-ui/core';
import { translate } from 'lib/intl';
import { getInitials } from 'lib/utils';
import { orgInfoActions } from 'rdx/actions';

const useCompanyNavListItem = () => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'));

  const dispatch = useDispatch();

  const { avatar, orgName, ownerOrgId } = useSelector((state) => ({
    avatar: state.orgInfo?.data?.avatar || '',
    orgName: state.orgInfo?.data?.orgName || '',
    ownerOrgId: state.authentication?.ownerOrgId,
  }));

  useEffect(() => {
    if (ownerOrgId) {
      dispatch(orgInfoActions.fetchOrgInfo(ownerOrgId));
    }
  }, [ownerOrgId]);

  const itemText = useMemo(
    () => (isDesktop ? '' : translate('COMPANY_TITLE')),
    [isDesktop]
  );

  return {
    avatar,
    orgName,
    orgNameInitials: getInitials(orgName, false),
    itemText,
  };
};

export { useCompanyNavListItem };
