import React from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import ImageIcon from 'components/common/ImageIcon';
import { EMPTY_FUNC, ICON_SIZES } from 'lib/constants';
import { translate } from 'lib/intl';

export function CustomDialogContent({
  actionButtonLabel,
  handleActionButton,
  disableActionButton,
  dialogActions,
  dialogHeader,
  onClose,
  children,
}) {
  return (
    <>
      {dialogHeader && (
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <DialogTitle onClose={onClose} disableTypography>
            <h3 className="type-h3 sm:type-h2">{dialogHeader}</h3>
          </DialogTitle>

          <Button data-testid="custom-dialog-close-btn" onClick={onClose}>
            <ImageIcon
              width={ICON_SIZES.SM}
              height={ICON_SIZES.SM}
              type="close"
              alt={translate('MULTI_ACTION_CLOSE')}
            />
          </Button>
        </Box>
      )}
      {children && <DialogContent dividers>{children}</DialogContent>}

      <DialogActions
        className="flex flex-col sm:flex-row-reverse justify-start"
        disableSpacing
      >
        {dialogActions || (
          <>
            <Button
              className="w-full sm:w-auto"
              color="primary"
              data-testid="custom-dialog-action-btn"
              disableElevation
              disabled={disableActionButton}
              onClick={handleActionButton}
              variant="contained"
            >
              <Typography variant="subtitle1">{actionButtonLabel}</Typography>
            </Button>
            <Button
              className="w-full sm:w-auto"
              data-testid="custom-dialog-cancel-btn"
              disableElevation
              onClick={onClose}
              variant="text"
            >
              <Typography variant="subtitle1">{translate('CANCEL')}</Typography>
            </Button>
          </>
        )}
      </DialogActions>
    </>
  );
}

CustomDialogContent.propTypes = {
  actionButtonLabel: PropTypes.string.isRequired,
  handleActionButton: PropTypes.func.isRequired,
  disableActionButton: PropTypes.bool,
  dialogActions: PropTypes.node,
  dialogHeader: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  children: PropTypes.node,
};
CustomDialogContent.defaultProps = {
  children: null,
  dialogActions: null,
  disableActionButton: false,
};

export default function CustomDialog({
  actionButtonLabel,
  handleActionButton,
  dialogActions,
  disableActionButton,
  dialogHeader,
  onClose,
  open,
  children,
  ...dialogProps
}) {
  return (
    <Dialog open={open} onClose={onClose} {...dialogProps}>
      <CustomDialogContent
        actionButtonLabel={actionButtonLabel}
        handleActionButton={handleActionButton}
        disableActionButton={disableActionButton}
        dialogActions={dialogActions}
        dialogHeader={dialogHeader}
        onClose={onClose}
      >
        {children}
      </CustomDialogContent>
    </Dialog>
  );
}

CustomDialog.propTypes = {
  actionButtonLabel: PropTypes.string,
  handleActionButton: PropTypes.func,
  disableActionButton: PropTypes.bool,
  dialogActions: PropTypes.node,
  dialogHeader: PropTypes.string,
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired,
  children: PropTypes.node,
  ...Dialog.propTypes,
};
CustomDialog.defaultProps = {
  children: null,
  disableActionButton: false,
  actionButtonLabel: translate('SUBMIT'),
  handleActionButton: EMPTY_FUNC,
  dialogActions: null,
  dialogHeader: '',
  onClose: EMPTY_FUNC,
};
