import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Box, makeStyles, TextField } from '@material-ui/core';
import { colors } from 'styles/theme';
import { Visible, Loader, OrgPickerItem } from 'components';
import { EMPTY_FUNC } from 'lib/constants';

const useStyles = makeStyles((theme) => ({
  orgItem: {
    border: `1px solid ${colors.lightGrey}`,
    background: theme.palette.background.white,
    borderRadius: theme.shape.borderRadius,
    height: '72px',
    cursor: 'pointer',
    padding: '16px',
    '&:hover': {
      border: `1px solid ${theme.palette.primary.main}`,
    },
  },
  active: {
    border: `1px solid ${theme.palette.primary.main}`,
  },
  loading: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
  },
  orgItemContainer: {
    marginTop: '30px',
    [theme.breakpoints.down('xs')]: {
      marginTop: '16px',
    },
  },
  input: {
    backgroundColor: theme.palette.background.white,
  },
}));

const OrgPicker = ({ orgs, filter, filterOrgs }) => {
  const styles = useStyles();
  const { isFetching, items } = orgs;
  let filteredItems = [];

  if (typeof items === 'object' && items.length) {
    filteredItems = items?.filter((item) => {
      const isEmptyFilter = filter === '';
      const hasFilterValue =
        item.orgName &&
        item.orgName.toLowerCase().includes(filter.toLowerCase());

      return isEmptyFilter || hasFilterValue;
    });
  }

  const onChange = (event) => {
    const appliedFilter = event.target.value;

    filterOrgs(appliedFilter);
  };

  const renderOrgItem = (item, idx) => {
    const { orgId, orgName, orgImage } = item;

    return (
      <React.Fragment key={idx}>
        <OrgPickerItem
          orgOwnerId={orgId}
          orgName={orgName}
          orgImage={orgImage}
        />
      </React.Fragment>
    );
  };

  return (
    <Grid container>
      <Visible when={isFetching}>
        <Box className={styles.loading}>
          <Loader size="48px" />
        </Box>
      </Visible>
      <Visible when={!isFetching}>
        <Grid item xs={12}>
          <TextField
            onChange={onChange}
            fullWidth
            InputProps={{
              className: styles.input,
            }}
            name="orgSearchFilter"
            label="Find Org"
            variant="outlined"
          />
        </Grid>
        <Grid container spacing={2} className={styles.orgItemContainer}>
          {filteredItems.map(renderOrgItem)}
        </Grid>
      </Visible>
    </Grid>
  );
};

OrgPicker.propTypes = {
  orgs: PropTypes.shape({
    items: PropTypes.arrayOf(PropTypes.shape({})),
    isFetching: PropTypes.bool,
  }),
  filter: PropTypes.string,
  filterOrgs: PropTypes.func,
};

OrgPicker.defaultProps = {
  orgs: {
    items: [],
    isFetching: false,
  },
  filter: '',
  filterOrgs: EMPTY_FUNC,
};

export { OrgPicker };
