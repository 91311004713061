import React from 'react';
import PropTypes from 'prop-types';
import InputAdornment from '@material-ui/core/InputAdornment';
import makeStyles from '@material-ui/styles/makeStyles';
import { Avatar } from 'components/common';
import { getInitials } from 'lib/utils';

const useStyles = makeStyles((theme) => ({
  icon: {
    height: theme.spacing(2.125),
    width: theme.spacing(2.125),
  },
}));

export default function TextSelectFieldItemImage({
  id,
  icon,
  component: Component,
  isAvatar,
  text,
  value,
}) {
  const classes = useStyles();
  let renderType = '';

  if (isAvatar) {
    renderType = 'avatar';
  } else if (icon) {
    renderType = 'icon';
  } else if (Component) {
    renderType = 'customComponent';
  }

  if (!renderType) {
    return null;
  }
  return (
    <>
      <InputAdornment position="start">
        {
          {
            avatar: (
              <Avatar
                id={`${id}-${value}-option-avatar`}
                size="18px"
                src={icon}
              >
                {getInitials(text, false)}
              </Avatar>
            ),
            icon: (
              <img
                className={classes.icon}
                id={`${id}-${value}-option-img`}
                alt={`${icon}-alt`}
                src={icon}
              />
            ),
            customComponent: <Component {...{ id, value }} />,
          }[renderType]
        }
      </InputAdornment>{' '}
    </>
  );
}

TextSelectFieldItemImage.propTypes = {
  component: PropTypes.elementType,
  id: PropTypes.string.isRequired,
  icon: PropTypes.string,
  isAvatar: PropTypes.bool,
  text: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

TextSelectFieldItemImage.defaultProps = {
  component: '',
  icon: '',
  isAvatar: false,
  text: '',
  value: '',
};
