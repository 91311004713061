import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { campaignsActions } from 'rdx/actions';
import { useDispatch, useSelector } from 'react-redux';
import { sortAndFilterCustomTableData } from 'lib/utils';
import { CUSTOM_TABLE_DEFAULT_SORTING } from 'lib/constants';
import { COLUMNS_CONFIG, SEARCHABLE_FIELDS } from './CampaignDonations.config';

const useCampaignDonations = () => {
  const dispatch = useDispatch();

  const { campaignId } = useParams();

  const [search, setSearch] = useState('');
  const [sorting, setSorting] = useState(CUSTOM_TABLE_DEFAULT_SORTING);

  const { campaignDonations, campaignState } = useSelector((state) => ({
    campaignDonations: state.campaignDonations[campaignId] || {},
    campaignState: state.campaign[campaignId] || {},
  }));

  const {
    data = [],
    isFetching = false,
    dataLoaded = false,
  } = campaignDonations;

  useEffect(() => {
    dispatch(campaignsActions.fetchCampaignDonations(campaignId));
  }, []);

  const donations = sortAndFilterCustomTableData({
    data,
    columns: COLUMNS_CONFIG,
    sorting,
    search,
    searchableFields: SEARCHABLE_FIELDS,
  });

  const showLoader = !dataLoaded && isFetching;

  const campaignName = campaignState?.data?.displayName || '';

  return {
    campaignName,
    donations,
    search,
    setSearch,
    sorting,
    setSorting,
    showLoader,
  };
};

export { useCampaignDonations };
