import React from 'react';

import { func, string, shape, number } from 'prop-types';
import { EMPTY_FUNC } from 'lib/constants';
import { Grid, makeStyles } from '@material-ui/core';
import { NotesInput, CurrencyInput } from 'components';
import { translate } from 'lib/intl';

const useStyles = makeStyles(() => ({
  rowItem: {
    paddingTop: '12px',
  },
  notesContainer: {
    paddingTop: '24px',
  },
}));

const NewDonationTypePlannedGiving = ({
  fieldErrors,
  donationAmount,
  setDonationAmount,
  notes,
  setNotes,
}) => {
  const styles = useStyles();

  return (
    <Grid container>
      <Grid className={styles.rowItem} item container xs={12}>
        <CurrencyInput
          label={translate('PLANNED_GIVING_ESTIMATED_VALUE_LABEL')}
          amount={donationAmount}
          setAmount={setDonationAmount}
          error={!!fieldErrors?.donationAmount}
          errorHelperText={translate(
            'PLANNED_GIVING_ESTIMATED_VALUE_ERROR_REQUIRED'
          )}
        />
      </Grid>
      <Grid className={styles.notesContainer} item container xs={12}>
        <NotesInput
          label={translate('DONATION_NOTES_LABEL')}
          notes={notes}
          setNotes={setNotes}
          maxLength={10000}
        />
      </Grid>
    </Grid>
  );
};

NewDonationTypePlannedGiving.propTypes = {
  fieldErrors: shape({}),
  donationAmount: number,
  setDonationAmount: func,
  notes: string,
  setNotes: func,
};

NewDonationTypePlannedGiving.defaultProps = {
  fieldErrors: {},
  donationAmount: 0,
  setDonationAmount: EMPTY_FUNC,
  notes: '',
  setNotes: EMPTY_FUNC,
};

export { NewDonationTypePlannedGiving };
