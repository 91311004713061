import React, { useMemo } from 'react';
import { arrayOf, bool, func, string, shape } from 'prop-types';
import styled from 'styled-components';
import { Box, Typography, makeStyles } from '@material-ui/core';
import { translate } from 'lib/intl';
import { EMPTY_FUNC } from 'lib/constants';
import { Avatar, SectionsGenerator, MultipleInputs } from 'components';
import { getInitials, isMobileView } from 'lib/utils';
import { EMPLOYEE_INFO_CONFIG } from 'lib/configs';

const useStyles = makeStyles((theme) => ({
  container: {
    position: 'relative',
    padding: '24px 85px 36px 0',
    [theme.breakpoints.down('sm')]: {
      '& .MuiFormControl-root': {
        marginTop: '10px',
      },
    },
  },
  header: {
    fontWeight: '500',
    padding: '0 24px',
  },
}));

const StyledAvatar = styled(Avatar)`
  left: ${({ mobile }) => (mobile ? '12px' : '30px')};
  position: absolute;
  top: ${({ mobile }) => (mobile ? '70px' : '60px')};
`;

const EditEmployeeProfilePersonalInfo = ({
  data,
  errors,
  setErrors,
  showErrors,
  updateData,
}) => {
  const styles = useStyles();
  const { firstName, lastName, avatar } = data;

  const initials = useMemo(
    () => getInitials(`${firstName} ${lastName}`),
    [firstName, lastName]
  );
  const isMobile = isMobileView();

  return (
    <Box className={styles.container}>
      <Typography className={styles.header} variant="subtitle1">
        {translate('EMPLOYEE_PERSONAL_INFORMATION')}
      </Typography>
      <StyledAvatar
        alt="donor-form-avatar"
        data-testid="donor-form-avatar"
        size={isMobile ? '40px' : '64px'}
        src={avatar}
        mobile={Number(isMobile)}
      >
        {initials}
      </StyledAvatar>
      <form>
        <SectionsGenerator
          MultipleInputsComponent={MultipleInputs}
          config={EMPLOYEE_INFO_CONFIG}
          data={data}
          errors={errors}
          setErrors={setErrors}
          showErrors={showErrors}
          updateData={updateData}
        />
      </form>
    </Box>
  );
};

EditEmployeeProfilePersonalInfo.propTypes = {
  data: shape({}),
  errors: arrayOf(string).isRequired,
  setErrors: func,
  showErrors: bool,
  updateData: func,
};

EditEmployeeProfilePersonalInfo.defaultProps = {
  data: {},
  setErrors: EMPTY_FUNC,
  showErrors: true,
  updateData: EMPTY_FUNC,
};

export { EditEmployeeProfilePersonalInfo };
