import React from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import makeStyles from '@material-ui/styles/makeStyles';
import { isMobileView } from 'lib/utils';
import AddNewConstituentModalContent from './AddNewConstituentModalContent';

const useStyles = makeStyles(() => ({
  paperFullWidth: {
    width: '510px',
  },
}));

const AddNewConstituentModal = ({ open, handleClose }) => {
  const classes = useStyles();
  const isMobile = isMobileView();

  return (
    <Dialog
      aria-labelledby="edit-dialog"
      classes={{
        paperFullWidth: classes.paperFullWidth,
      }}
      data-testid="add-new-constituent-modal"
      fullWidth
      maxWidth="md"
      mobile={Number(isMobile)}
      onClose={handleClose}
      open={open}
    >
      <AddNewConstituentModalContent handleClose={handleClose} />
    </Dialog>
  );
};

AddNewConstituentModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default AddNewConstituentModal;
