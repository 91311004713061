import userActionTypes from 'rdx/actionTypes';

const {
  COMPANY_TEAM_MEMBERS_REQUEST,
  COMPANY_TEAM_MEMBERS_SUCCESS,
  COMPANY_TEAM_MEMBERS_FAILURE,
} = userActionTypes;

const initialState = {
  dataLoaded: false,
  isFetching: false,
  error: null,
  data: [],
};

export function companyTeamMembers(state = initialState, action) {
  switch (action.type) {
    case COMPANY_TEAM_MEMBERS_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: null,
      };
    case COMPANY_TEAM_MEMBERS_SUCCESS:
      return {
        ...state,
        data: action.data,
        dataLoaded: true,
        isFetching: false,
        error: null,
      };
    case COMPANY_TEAM_MEMBERS_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.error,
      };
    default:
      return state;
  }
}
