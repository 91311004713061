const isValidPassword = (value) => {
  const hasCharacterCount = value.length >= 10;
  const hasNumber = /[0-9]/.test(value);
  const hasUpper = /[A-Z]/.test(value);
  const hasLower = /[a-z]/.test(value);
  const hasSpecial = /_|[^\w]/.test(value);

  return {
    isValid:
      hasCharacterCount && hasNumber && hasUpper && hasLower && hasSpecial,
    hasCharacterCount,
    hasNumber,
    hasUpper,
    hasLower,
    hasSpecial,
  };
};

export { isValidPassword };
