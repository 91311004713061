import React, { useCallback } from 'react';
import { bool, func, shape, string } from 'prop-types';
import styled from 'styled-components';
import { Box, Checkbox } from '@material-ui/core';
import { CustomLink, Gap, Visible } from 'components';
import { NotPrimaryIcon, PrimaryIcon } from 'assets/images';

const CheckboxCheckedIcon = <PrimaryIcon />;
const CheckboxIcon = <NotPrimaryIcon />;

const StyledPrimaryCheckbox = styled(Box)`
  .MuiCheckbox-root {
    padding: 0px;
  }
`;

const PrimaryCheckbox = ({
  primary,
  onChange,
  label,
  checkboxProps,
  ...rest
}) => {
  const toggleState = useCallback(
    () => onChange(!primary),
    [onChange, primary]
  );

  return (
    <StyledPrimaryCheckbox alignItems="center" display="flex" {...rest}>
      <Visible when={Boolean(label)}>
        <CustomLink onClick={toggleState}>{label}</CustomLink>
        <Gap />
      </Visible>
      <Checkbox
        checked={primary}
        checkedIcon={CheckboxCheckedIcon}
        icon={CheckboxIcon}
        onChange={toggleState}
        {...checkboxProps}
      />
    </StyledPrimaryCheckbox>
  );
};

PrimaryCheckbox.propTypes = {
  checkboxProps: shape({}),
  primary: bool.isRequired,
  onChange: func.isRequired,
  label: string,
};

PrimaryCheckbox.defaultProps = {
  checkboxProps: {},
  label: '',
};

export { PrimaryCheckbox };
