import { createApiRequest } from 'lib/utils';

const getOrgList = (limit, page) => {
  let path = 'orgs';

  if (limit) {
    path = `${path}?limit=${limit}`;

    if (page) {
      path = `${path}&page=${page}`;
    }
  }

  return createApiRequest({
    method: 'get',
    path,
    throwErrors: true,
  });
};

const createOrg = (data) =>
  createApiRequest({
    method: 'post',
    path: 'orgs',
    data,
    throwErrors: true,
  });

const getOrgInfo = (ownerOrgPubId) =>
  createApiRequest({
    method: 'get',
    path: `orgs/${ownerOrgPubId}`,
    throwErrors: true,
  });

const updateOrgInfo = (ownerOrgPubId, data) =>
  createApiRequest({
    data,
    method: 'put',
    path: `orgs/${ownerOrgPubId}`,
    throwErrors: true,
  });

export const orgsService = {
  createOrg,
  getOrgList,
  getOrgInfo,
  updateOrgInfo,
};
