import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  donationActions,
  campaignsActions,
  companyTeamMembersActions,
} from 'rdx/actions';
import {
  formatCurrencyInputToCents,
  formatCampaignForTextSelect,
} from 'lib/utils';
import { formatDateObject } from 'lib/utils/dates';
import {
  API_DATE_FORMAT,
  DONOR_TYPES,
  TEXT_SELECT_FIELD_EMPTY_VALUE,
} from 'lib/constants';
import { DONATION_TYPE_ENUMS } from 'lib/constants/common';
import {
  DONATION_TYPES_VALIDATION_FIELDS,
  DONATION_TYPES,
  DONATION_FIELD_NAMES,
} from 'lib/configs/donations';

const { PLEDGE, RECURRING, RESTRICTED, IN_KIND, PLANNED_GIVING, TIME, GRANT } =
  DONATION_TYPE_ENUMS;

// Purpose is to remove errors from fieldErrors when the user input a value in to an empty field
const checkErrorsOnChange = (
  currentFieldValues,
  currentErrors,
  setUpdatedErrors
) => {
  const updatedErrors = { ...currentErrors };
  let shouldUpdate = false;

  // Search for newly input values which can remove that field from the error condition
  Object.keys(currentErrors).forEach((key) => {
    if (currentFieldValues[key]) {
      shouldUpdate = true;
      delete updatedErrors[key];
    }
  });

  // If a value is found for a previously empty field, update the field errors
  if (shouldUpdate) {
    setUpdatedErrors(updatedErrors);
  }
};

const defaultDonationType = {
  value: DONATION_TYPES?.GENERAL?.value || '',
  icon: DONATION_TYPES?.GENERAL?.icon || '',
  text: DONATION_TYPES?.GENERAL?.displayText || '',
};

const useNewDonationForm = (closeFormDialog, providedOnSuccess) => {
  const dispatch = useDispatch();
  const currentDate = new Date();

  const [selectedDonor, setSelectedDonor] = React.useState();
  const [selectedCampaign, setSelectedCampaign] = React.useState(
    TEXT_SELECT_FIELD_EMPTY_VALUE
  );
  const [donationType, setDonationType] = React.useState(defaultDonationType);
  const [selectedDate, setSelectedDate] = React.useState(currentDate);
  const [paymentMethod, setPaymentMethod] = React.useState();
  const [donationAmount, setDonationAmount] = React.useState(0);
  const [notes, setNotes] = React.useState('');
  const [pledgeDueDate, setPledgeDueDate] = React.useState(currentDate);
  const [recurringStartDate, setRecurringStartDate] =
    React.useState(currentDate);
  const [recurringCadence, setRecurringCadence] = React.useState();
  const [fieldErrors, setFieldErrors] = React.useState({});
  const [areaOfRestriction, setAreaOfRestriction] = React.useState('');
  const [itemDonated, setItemDonated] = React.useState('');
  const [itemQuantity, setItemQuantity] = React.useState('');
  const [timeCategory, setTimeCategory] = React.useState();
  const [timeValue, setTimeValue] = React.useState();
  const [amountOfTime, setAmountOfTime] = React.useState();
  const [timeType, setTimeType] = React.useState();
  const [grantName, setGrantName] = React.useState();
  const [restricted, setRestricted] = React.useState(false);
  const [restrictionCondition, setRestrictionCondition] = React.useState();
  const [developers, setDevelopers] = React.useState([]);

  const fieldValues = {
    selectedDonor,
    selectedCampaign,
    donationType,
    selectedDate,
    paymentMethod,
    donationAmount,
    notes,
    pledgeDueDate,
    recurringStartDate,
    recurringCadence,
    areaOfRestriction,
    itemDonated,
    itemQuantity,
    timeCategory,
    timeValue,
    amountOfTime,
    timeType,
    grantName,
    restricted,
    restrictionCondition,
    developers,
  };

  const handleSetDonationType = (type) => {
    setFieldErrors({});
    setDonationType(type);
  };

  // On update check the error state for values to remove
  React.useEffect(() => {
    checkErrorsOnChange(fieldValues, fieldErrors, setFieldErrors);
  }, [fieldValues, fieldErrors]);

  const {
    campaigns: {
      data: campaigns,
      page: campaignsPage,
      dataLoaded: areCampaignsLoaded,
      isFetching: areCampaignsLoading,
    },
    isSuperAdmin,
    newDonation: { errors, isLoading, onSuccess },
    ownerOrgId,
    team,
    areTeamMembersLoaded,
    currentUserId,
  } = useSelector((state) => ({
    campaigns: state.campaigns,
    isSuperAdmin: state.authentication?.isSuperAdmin,
    newDonation: state.newDonation,
    ownerOrgId: state.authentication?.ownerOrgId,
    team: state.companyTeamMembers.data?.users || [],
    areTeamMembersLoaded: state.companyTeamMembers.dataLoaded,
    currentUserId: state.authentication?.userId,
  }));

  const formatTeamMember = (memberToFormat) => ({
    value: memberToFormat.userId,
    icon: '',
    text: `@${memberToFormat.displayName}`,
    isAvatar: true,
    donorType: DONOR_TYPES.USER,
  });

  React.useEffect(() => {
    if (!areCampaignsLoaded || isSuperAdmin) {
      dispatch(campaignsActions.fetchCampaigns(ownerOrgId));
    }
    if (!areTeamMembersLoaded) {
      dispatch(companyTeamMembersActions.fetchCompanyTeamMembers(ownerOrgId));
    }

    // resets donation form on exit
    return () => dispatch(donationActions.resetDonationForm());
  }, []);

  React.useEffect(() => {
    if (onSuccess) {
      closeFormDialog();
      providedOnSuccess();
    }
  }, [onSuccess]);

  React.useEffect(() => {
    if (areTeamMembersLoaded && donationType === DONATION_TYPES.GRANT) {
      // makes sure to preselect a current employee
      const currentEmployee = team?.find(
        (teamMember) => teamMember.userId === currentUserId
      );

      if (currentEmployee) {
        setDevelopers([formatTeamMember(currentEmployee)]);
      }
    }
  }, [areTeamMembersLoaded, donationType]);

  const handleSave = () => {
    const donationTypeValidationFields =
      DONATION_TYPES_VALIDATION_FIELDS[donationType.value.toUpperCase()];

    const newFieldErrors = {};

    donationTypeValidationFields.forEach((field) => {
      const value = fieldValues[field];

      if (!value && DONATION_FIELD_NAMES[field].isRequired) {
        newFieldErrors[field] = true;
      }
    });

    if (Object.keys(newFieldErrors).length > 0) {
      setFieldErrors(newFieldErrors);

      return;
    }

    const { value: donationTypeEnum } = donationType;
    const isTimeType = donationTypeEnum === TIME;

    // Format state date for API request
    const formattedData = {
      constituentType: selectedDonor.constituentType,
      donorId: selectedDonor.value,
      donationTypeEnum,
      ownerOrgId,
      donorDisplayName: selectedDonor.text,
      donationDateString: formatDateObject(selectedDate, API_DATE_FORMAT),
      notes,
    };

    // Campaign is currently optional
    if (selectedCampaign?.value) {
      formattedData.campaignId = selectedCampaign.value;
      formattedData.campaignDisplayName = selectedCampaign.text;
    }

    if (isTimeType) {
      formattedData.timeCategory = timeCategory.value;
      formattedData.timeValue = timeValue.value;
      formattedData.time = Number(amountOfTime);
      formattedData.timeUnit = timeType.value;
    } else {
      formattedData.amount = formatCurrencyInputToCents(donationAmount);

      if (![IN_KIND, PLANNED_GIVING].includes(donationTypeEnum)) {
        formattedData.paymentTypeEnum = paymentMethod.value;
      }
    }

    // Apply type specific fields to request
    if (donationTypeEnum === PLEDGE) {
      formattedData.pledgeDueDateString = formatDateObject(
        pledgeDueDate,
        API_DATE_FORMAT
      );
    } else if (donationTypeEnum === RECURRING) {
      formattedData.recurringCadenceEnum = recurringCadence.value;
      formattedData.recurringStartDateString = formatDateObject(
        recurringStartDate,
        API_DATE_FORMAT
      );
    } else if (donationTypeEnum === RESTRICTED) {
      formattedData.restrictionDescription = areaOfRestriction;
    } else if (donationTypeEnum === IN_KIND) {
      try {
        formattedData.inKindItemQuantity = parseInt(itemQuantity, 10);
      } catch (error) {
        setFieldErrors(newFieldErrors[DONATION_FIELD_NAMES.itemQuantity.key]);
        return;
      }

      formattedData.inKindItemDescription = itemDonated;
    } else if (donationTypeEnum === PLANNED_GIVING) {
      // TODO Descriptoon required but no field present
      formattedData.description = '';
    } else if (donationTypeEnum === GRANT) {
      const formattedDevelopers = developers?.map(({ value, text }) => ({
        userId: value,
        displayName: text.replace(/^@/, ''),
      }));
      formattedData.grantName = grantName;
      formattedData.restricted = restricted;
      formattedData.restrictionCondition = restrictionCondition;
      formattedData.developers = formattedDevelopers;
    }

    if (selectedDonor.icon) {
      formattedData.donorAvatarUrl = selectedDonor.icon;
    }

    dispatch(donationActions.createDonation(formattedData));
  };

  const formattedCampaigns = campaigns?.map((campaign) =>
    formatCampaignForTextSelect(campaign)
  );

  const formattedTeamMembers = team?.map(formatTeamMember);

  return {
    amountOfTime,
    areaOfRestriction,
    areCampaignsLoading,
    campaigns: formattedCampaigns,
    campaignsPage,
    developers,
    donationAmount,
    donationType,
    errors,
    fieldErrors,
    grantName,
    restricted,
    restrictionCondition,
    handleSave,
    isLoading,
    itemDonated,
    itemQuantity,
    notes,
    onSuccess,
    paymentMethod,
    pledgeDueDate,
    recurringCadence,
    recurringStartDate,
    selectedCampaign,
    selectedDate,
    selectedDonor,
    setAmountOfTime,
    setAreaOfRestriction,
    setDevelopers,
    setDonationAmount,
    setDonationType: handleSetDonationType,
    setGrantName,
    setRestricted,
    setRestrictionCondition,
    setItemDonated,
    setItemQuantity,
    setNotes,
    setPaymentMethod,
    setPledgeDueDate,
    setRecurringCadence,
    setRecurringStartDate,
    setSelectedCampaign,
    setSelectedDate,
    setSelectedDonor,
    setTimeCategory,
    setTimeType,
    setTimeValue,
    teamMembers: formattedTeamMembers,
    timeCategory,
    timeType,
    timeValue,
  };
};

export { useNewDonationForm };
