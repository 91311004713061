import React from 'react';
import { string } from 'prop-types';
import { makeStyles } from '@material-ui/core';
import { ICON_SIZES } from 'lib/constants';

const useStyles = makeStyles((theme) => ({
  img: {
    height: ICON_SIZES.MD,
    width: ICON_SIZES.MD,
    [theme.breakpoints.down('sm')]: {
      height: ICON_SIZES.SM,
      width: ICON_SIZES.SM,
    },
  },
}));

const DonationIcon = ({ alt, className, src, ...rest }) => {
  const styles = useStyles();

  return (
    <img
      alt={alt}
      className={`${styles.img} ${className}`}
      src={src}
      {...rest}
    />
  );
};

DonationIcon.propTypes = {
  alt: string.isRequired,
  className: string,
  src: string.isRequired,
};

DonationIcon.defaultProps = {
  className: '',
};

export { DonationIcon };
