export const HOME = {
  GOALS_TITLE: 'Goals',
  FEED_TITLE: 'Feed',
  RECENTLY_VIEWED_TITLE: 'Recently Viewed',
  DONOR_WEEKLY_GOAL: 'Your Weekly Goal',
  DONOR_MONTHLY_GOAL: 'Your Monthly Goal',
  DAILY_SIGN_IN_STREAK: 'Daily Sign in Streak',
  DAILY_SIGN_IN_STREAK_UNITS: 'Days',
  HOME: 'Home',
  VISIT_STREAK: 'Visit Streak',
  ERROR_UPDATING_ORG_INFO: 'Error updating org info',
  ADD_ANNOUNCEMENT: 'Add Announcement',
  ADD_GLOBAL_ANNOUNCEMENT: 'Add Global Announcement',
};
