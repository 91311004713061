import userActionTypes from 'rdx/actionTypes';

const {
  ORGS_LIST_REQUEST,
  ORGS_LIST_SUCCESS,
  ORGS_LIST_FAILURE,
  ORGS_LIST_SET_FILTER,
  ORGS_CREATE_SUCCESS,
} = userActionTypes;

const initialState = {
  isFetching: false,
  error: null,
  orgs: [],
  filter: '',
  hasNextPage: null,
  nextPage: null,
};

export function orgList(state = initialState, action) {
  switch (action.type) {
    case ORGS_LIST_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case ORGS_LIST_SUCCESS:
      return {
        ...state,
        orgs: [
          ...new Map(
            [...state.orgs, ...action?.data?.orgs].map((item) => [
              item.orgId,
              item,
            ])
          ).values(),
        ],
        isFetching: false,
        nextPage: action?.data?.page,
        hasNextPage: !!action?.data?.page,
      };
    case ORGS_LIST_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.error,
      };
    case ORGS_LIST_SET_FILTER:
      return {
        ...state,
        filter: action.filter,
      };
    case ORGS_CREATE_SUCCESS:
      return {
        ...state,
        orgs: [
          {
            ...action.data.org,
          },
          ...state.orgs,
        ],
      };
    default:
      return state;
  }
}
