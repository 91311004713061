import React from 'react';
import propTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/styles/makeStyles';
import { CustomLink } from 'components/common/CustomLink';
import { isMobileView, skipPassingEvent } from 'lib/utils';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    backgroundColor: theme.palette.background.white,
    border: `1px solid ${theme.palette.grey.transparent}`,
    borderRadius: theme.shape.borderRadius[0],
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(4),
    marginBottom: theme.spacing(1),
    position: 'relative',
  },
}));

/**
 * This is a wrapper for donor sections
 * which adds title and custom action
 */
const ConstituentSectionWrapper = ({
  actionLabel,
  boxProps,
  children,
  isEditable,
  id,
  onActionClick,
  title,
}) => {
  const classes = useStyles();
  const isMobile = isMobileView();

  return (
    <Box className={classes.wrapper} id={id}>
      <Box
        alignItems="center"
        display="flex"
        justifyContent="space-between"
        px={isMobile ? 2 : 4}
      >
        <Typography variant="subtitle1">{title}</Typography>
        {Boolean(isEditable && actionLabel && onActionClick) && (
          <CustomLink
            data-testid="donor-section-action-btn"
            onClick={skipPassingEvent(onActionClick)}
          >
            {actionLabel}
          </CustomLink>
        )}
      </Box>
      <Box
        pb={isMobile ? 1 : 2}
        pt={isMobile ? 2 : 3}
        px={isMobile ? 2 : 4}
        {...boxProps}
      >
        {children}
      </Box>
    </Box>
  );
};

ConstituentSectionWrapper.propTypes = {
  actionLabel: propTypes.string,
  boxProps: propTypes.shape({}),
  children: propTypes.node,
  isEditable: propTypes.bool,
  onActionClick: propTypes.func,
  title: propTypes.string.isRequired,
  id: propTypes.string,
};

ConstituentSectionWrapper.defaultProps = {
  actionLabel: '',
  boxProps: {},
  children: null,
  isEditable: false,
  onActionClick: null,
  id: 'donorSection',
};

export default ConstituentSectionWrapper;
