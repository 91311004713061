import { translate } from 'lib/intl';
import { AUTH_ROUTES } from 'lib/constants';
import { matchPath } from 'react-router-dom';

const { CAMPAIGN, CAMPAIGN_DONATIONS, CAMPAIGN_REPORTS } = AUTH_ROUTES;

const CAMPAIGN_PAGE_TABS = [
  {
    id: 'campaignHistory',
    default: true,
    label: translate('CAMPAIGN_OVERVIEW_TAB'),
    path: CAMPAIGN,
  },
  {
    id: 'campaignDonations',
    default: false,
    label: translate('CAMPAIGN_DONATIONS_TAB'),
    path: CAMPAIGN_DONATIONS,
  },
];

const CAMPAIGN_PAGE_TABS_WITH_REPORTS = [
  ...CAMPAIGN_PAGE_TABS,
  {
    id: 'campaignReports',
    default: false,
    label: translate('CAMPAIGN_REPORTS_TAB'),
    path: CAMPAIGN_REPORTS,
  },
];

const getCampaignPageTabId = (tabsConfig = [], location) => {
  let defaultTabIdx;
  let tabIdx;

  // eslint-disable-next-line no-unused-expressions
  tabsConfig?.some((tab, idx) => {
    if (tab?.default) {
      defaultTabIdx = idx;
    }

    const matchRes = matchPath(location?.pathname, {
      path: tab?.path,
      exact: true,
      strict: false,
    });

    if (matchRes?.isExact) {
      tabIdx = idx;
    }

    return matchRes?.isExact;
  });

  return tabIdx || defaultTabIdx;
};

export {
  CAMPAIGN_PAGE_TABS,
  CAMPAIGN_PAGE_TABS_WITH_REPORTS,
  getCampaignPageTabId,
};
