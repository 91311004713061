import React from 'react';
import PropTypes from 'prop-types';
import CustomDialog from 'components/common/CustomDialog';
import { EMPTY_FUNC } from 'lib/constants';
import { donationActions } from 'rdx/actions';
import { translate } from 'lib/intl';
import { useDispatch } from 'react-redux';
import { withNewThemeProvider } from 'components/common/NewThemeProvider';

const DeleteDonationModal = ({ donationId, onClose, onSuccess, open }) => {
  const dispatch = useDispatch();
  const handleActionButton = async () => {
    await dispatch(donationActions.deleteDonation(donationId));
    onSuccess();
  };

  return (
    <CustomDialog
      actionButtonLabel={translate('DELETE')}
      dialogHeader={translate('DONATION_DELETE_DONATION')}
      handleActionButton={handleActionButton}
      onClose={onClose}
      open={open}
      fullWidth
    >
      <div className="type-body1 md:w-96">
        {translate('DONATION_DELETE_CONFIRMATION')}
      </div>
    </CustomDialog>
  );
};

DeleteDonationModal.propTypes = {
  donationId: PropTypes.string.isRequired,
  onClose: PropTypes.func,
  onSuccess: PropTypes.func,
  open: PropTypes.bool,
};

DeleteDonationModal.defaultProps = {
  onClose: EMPTY_FUNC,
  onSuccess: EMPTY_FUNC,
  open: false,
};

export default withNewThemeProvider(DeleteDonationModal);
