import React from 'react';
import PropTypes from 'prop-types';
import CustomDialog from 'components/common/CustomDialog';
import { ReportsFilterForm } from 'components/reports';
import { translate } from 'lib/intl';

export default function ReportsFilterDialog({ isOpen, onClose }) {
  return (
    <CustomDialog
      dialogHeader={translate('REPORTS_PAGE_FILTER_MODAL_TITLE')}
      fullWidth
      maxWidth="sm"
      onClose={onClose}
      open={isOpen}
      dialogActions={<></>}
    >
      <ReportsFilterForm />
    </CustomDialog>
  );
}

ReportsFilterDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};
