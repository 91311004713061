import React from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import propTypes from 'prop-types';
import { Avatar, SocialAccounts, ViewFieldsColumn } from 'components';
import { EMPTY_FUNC } from 'lib/constants/common';
import {
  DonorType,
  DonorConfigType,
  isMobileView,
  isUserDonorType,
  getInitials,
  getFullName,
} from 'lib/utils';

const AVATAR_MOB_SIZE = '48px';
const AVATAR_DESKTOP_SIZE = '36px';

/**
 * This is a component for rendering donor cards
 * with two columns and social accounts based on passed configs
 */
const ConstituentInformationCard = ({
  data,
  firstColConfig,
  secondColConfig,
  isEditable,
  openForm,
}) => {
  const { avatar, facebook, instagram, orgName, linkedin, twitter } = data;

  const isMobile = isMobileView();

  const avatarSize = React.useMemo(
    () => (isMobile ? AVATAR_MOB_SIZE : AVATAR_DESKTOP_SIZE),
    [isMobile]
  );

  const name = React.useMemo(() => orgName || getFullName(data, true), [data]);

  const initials = React.useMemo(
    () => getInitials(orgName || getFullName(data), isUserDonorType(data)),
    [data]
  );

  return (
    <Box>
      {name && (
        <Box alignItems="center" display="flex" mb={2}>
          <Avatar size={avatarSize} src={avatar}>
            {initials}
          </Avatar>
          <Box pl={2}>
            <Typography variant="h5">{name}</Typography>
          </Box>
        </Box>
      )}
      <Box
        display="flex"
        flexDirection={isMobile ? 'column' : 'row'}
        flexWrap="wrap"
      >
        <Box flex="1 0 50%">
          <ViewFieldsColumn
            config={firstColConfig}
            data={data}
            iconContainerWidth={avatarSize}
            onNoDataLabelClick={openForm}
            showNoDataLabel={isEditable}
          />
        </Box>
        <Box
          display="flex"
          flex="1 0 50%"
          flexDirection={isMobile ? 'column' : 'row'}
          justifyContent="space-between"
        >
          <Box pr={2} pb={isMobile ? 3 : 0}>
            <ViewFieldsColumn
              config={secondColConfig}
              data={data}
              iconContainerWidth={avatarSize}
              onNoDataLabelClick={openForm}
              showNoDataLabel={isEditable}
            />
          </Box>
          <SocialAccounts
            facebook={facebook}
            instagram={instagram}
            linkedin={linkedin}
            twitter={twitter}
          />
        </Box>
      </Box>
    </Box>
  );
};

ConstituentInformationCard.propTypes = {
  data: DonorType,
  firstColConfig: propTypes.arrayOf(propTypes.shape(DonorConfigType))
    .isRequired,
  secondColConfig: propTypes.arrayOf(propTypes.shape(DonorConfigType))
    .isRequired,
  isEditable: propTypes.bool,
  openForm: propTypes.func,
};

ConstituentInformationCard.defaultProps = {
  data: {},
  isEditable: false,
  openForm: EMPTY_FUNC,
};

export default ConstituentInformationCard;
