import React, { useMemo, memo } from 'react';
import { useSelector } from 'react-redux';
import { prop } from 'rambdax';
import styled from 'styled-components';
import { Box, Typography } from '@material-ui/core';

const StyledTeamMemberCellWrapper = styled(Box)`
  cursor: ${prop('cursor')};

  ${({ loading }) =>
    loading === 'true'
      ? `
      opacity: 0.5;
      pointer-events: none;
    `
      : ''}
`;

const StyledCellText = styled(Typography)`
  font-size: 16px;
`;

/* eslint react/prop-types: 0 */
const TeamMemberCellWrapper = ({
  CellComponent,
  justifyContent = 'flex-start',
  cursor = 'default',
  withOpacityOnLoading = true,
  isForInvites = false,
} = {}) =>
  memo(({ colDef, data, value, ...rest }) => {
    const { groups, status, ownerOrgId, ownerOrgName } = useSelector(
      (state) => ({
        groups: state?.orgInfo?.data?.groups,
        status: isForInvites
          ? state.updatingInvites[data?.inviteId]
          : state.updatingCompanyTeamMembers[data?.userId],
        ownerOrgId: state.authentication?.ownerOrgId,
        ownerOrgName:
          state?.authentication?.ownerOrgName || state?.user?.ownerOrgName,
      })
    );

    const { valueFormatter } = colDef || {};

    const formattedValue = useMemo(
      () => (valueFormatter ? valueFormatter({ value }) : value),
      [value]
    );

    const Content = useMemo(() => {
      if (CellComponent) {
        return (
          <CellComponent
            data={data}
            groups={groups}
            isForInvites={isForInvites}
            ownerOrgId={ownerOrgId}
            ownerOrgName={ownerOrgName}
            status={status}
            value={formattedValue}
            {...rest}
          />
        );
      }

      return <StyledCellText>{formattedValue || ''}</StyledCellText>;
    }, [
      data,
      formattedValue,
      groups,
      isForInvites,
      ownerOrgId,
      ownerOrgName,
      status,
      rest,
    ]);

    return (
      <StyledTeamMemberCellWrapper
        alignItems="center"
        cursor={cursor}
        display="flex"
        justifyContent={justifyContent}
        height="100%"
        loading={String(status?.isFetching && withOpacityOnLoading)}
      >
        {Content}
      </StyledTeamMemberCellWrapper>
    );
  });

export { TeamMemberCellWrapper };
