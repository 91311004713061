import React from 'react';
import { CSVLink } from 'react-csv';
import Link from '@material-ui/core/Link';
import { translate } from 'lib/intl';
import { arrayOf, shape, string } from 'prop-types';

const ExportButton = ({ data, filename, headers }) => (
  <Link
    component={CSVLink}
    data={data}
    data-testid="export-button"
    filename={`${filename}.csv`}
    headers={headers}
    target="_blank"
    uFEFF={false}
    variant="body1"
  >
    {translate('EXPORT')}
  </Link>
);

ExportButton.propTypes = {
  data: arrayOf(shape({})).isRequired,
  filename: string.isRequired,
  headers: arrayOf(
    shape({
      label: string,
      key: string,
    })
  ).isRequired,
};

export { ExportButton };
