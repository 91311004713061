import React from 'react';
import { func, string, bool } from 'prop-types';
import { Box, Typography, makeStyles } from '@material-ui/core';
import { translate } from 'lib/intl';
import { Close } from '@material-ui/icons';
import { EMPTY_FUNC } from 'lib/constants';
import { colors } from 'styles/theme';
import { NewDonationForm } from 'components';

const useStyles = makeStyles(() => ({
  itemContainer: {
    height: '56px',
    width: '100%',
    borderBottom: `1px solid ${colors.lightGrey}`,
    position: 'relative',
  },
  item: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    padding: '0 16px',
  },
  icon: {
    height: '18px',
    width: '18px',
  },
}));

const NewDonationFormWithHeader = ({
  onClose,
  preSelectedDonorId,
  shouldFetchDonors,
  onSuccess,
}) => {
  const styles = useStyles();

  return (
    <>
      <Box className={styles.itemContainer}>
        <Box
          className={styles.item}
          style={{ justifyContent: 'space-between' }}
        >
          <Typography variant="button" style={{ textTransform: 'capitalize' }}>
            {translate('ADD_NEW_DONATION')}
          </Typography>
          <Close
            data-testid="interaction-close"
            className={styles.icon}
            style={{ cursor: 'pointer' }}
            onClick={onClose}
          />
        </Box>
      </Box>
      <NewDonationForm
        onClose={onClose}
        preSelectedDonorId={preSelectedDonorId}
        shouldFetchDonors={shouldFetchDonors}
        onSuccess={onSuccess}
      />
    </>
  );
};

NewDonationFormWithHeader.propTypes = {
  onClose: func,
  preSelectedDonorId: string,
  shouldFetchDonors: bool,
  onSuccess: func,
};

NewDonationFormWithHeader.defaultProps = {
  onClose: EMPTY_FUNC,
  preSelectedDonorId: null,
  shouldFetchDonors: true,
  onSuccess: EMPTY_FUNC,
};

export { NewDonationFormWithHeader };
