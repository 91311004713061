import React from 'react';
import { node, func } from 'prop-types';
import { Button } from '@material-ui/core';
import { isMobileView } from 'lib/utils';

const FullWidthMobileButton = ({ children, onClick, ...rest }) => {
  const isMobile = isMobileView();

  return (
    <Button
      fullWidth={isMobile}
      color="primary"
      data-testid="full-width-mobile-button"
      onClick={onClick}
      variant="contained"
      {...rest}
    >
      {children}
    </Button>
  );
};

FullWidthMobileButton.propTypes = {
  onClick: func.isRequired,
  children: node.isRequired,
};

export { FullWidthMobileButton };
