import React from 'react';
import propTypes from 'prop-types';
import { MultipleInputs, SectionsGenerator } from 'components';
import { DonorType, getFullName, getInitials, isMobileView } from 'lib/utils';
import { DONOR_SECTION_CONFIGS } from 'lib/configs';
import { ConstituentSectionTypes } from './constituentTypes';
import useConstituentForm from './useConstituentForm';
import ConstituentSectionWrapper from './ConstituentSectionWrapper';
import ConstituentCardWrapper from './ConstituentCardWrapper';
import ConstituentInformationCard from './ConstituentInformationCard';
import AddMissingDataLink from './AddMissingDataLink';
import ConstituentFormPopup from './ConstituentFormPopup';
import ShowMoreContent from './ShowMoreContent';

// TODO: remove this method when data is removed by backend
const getExistingCardsData = (data) =>
  data?.reduce((acc, cardData) => {
    if (!cardData?.deleted) {
      acc.push(cardData);
    }

    return acc;
  }, []);

/**
 * This is a component for rendering
 * Work Information and Contacts sections
 */
const ConstituentsWorkAndContactsSection = ({ data, isEditable, type }) => {
  const {
    actionLabel,
    key,
    title,
    primaryLabel,
    firstColConfig,
    secondColConfig,
  } = DONOR_SECTION_CONFIGS[type];

  const {
    isPopupOpen,
    idField,
    openForm,
    showForm,
    closeForm,
    onSave,
    onRemove,
    onPrimaryChange,
    sectionGeneratorConfig,
    sectionGeneratorShowMoreConfig,
    formData,
    formErrors,
    removingDonorInfo,
    setErrors,
    showErrors,
    setFormData,
    updatingConstituent,
    showMoreSection,
    setShowMoreSection,
  } = useConstituentForm({
    type,
  });

  const {
    dataLoaded: isDataUpdated,
    error: formHasError,
    isFetching: isDataLoading,
  } = updatingConstituent;

  const filteredData = getExistingCardsData(data);

  const isMobile = isMobileView();

  const Cards = React.useMemo(
    () =>
      filteredData?.map((donor, idx) => {
        const onEditClick = () =>
          openForm({
            type,
            isCreating: false,
            data: donor,
          });

        const id = donor[idField];

        return (
          <ConstituentCardWrapper
            key={id || idx}
            loading={removingDonorInfo[id]?.isFetching}
            onClick={onEditClick}
            onDeleteClick={() => onRemove(donor)}
            onEditClick={onEditClick}
            mt={idx ? 3 : 0}
          >
            <ConstituentInformationCard
              data={donor}
              firstColConfig={firstColConfig}
              secondColConfig={secondColConfig}
            />
          </ConstituentCardWrapper>
        );
      }),
    [filteredData, removingDonorInfo]
  );

  const avatarInitials = React.useMemo(
    () => getInitials(formData.orgName || getFullName(formData)),
    [formData]
  );

  const handleOpenFormClick = React.useCallback(() => {
    openForm({ type });
  }, [type]);

  return (
    <ConstituentSectionWrapper
      actionLabel={actionLabel}
      boxProps={{ px: isMobile ? 0 : 2, pt: 2 }}
      isEditable={isEditable}
      id={key}
      onActionClick={handleOpenFormClick}
      primaryLabel={primaryLabel}
      title={title}
    >
      {Cards ||
        (isEditable && (
          <AddMissingDataLink
            label={actionLabel}
            onClick={handleOpenFormClick}
          />
        ))}
      <ConstituentFormPopup
        avatar={formData?.avatar}
        avatarInitials={avatarInitials}
        title={title}
        // makes sure to show loading until the form is closed
        isLoading={isDataLoading || isDataUpdated}
        isOpen={isPopupOpen}
        onClose={closeForm}
        onSave={onSave}
        onPrimaryChange={onPrimaryChange}
        primary={formData?.primary}
        primaryLabel={primaryLabel}
        showAvatar
        apiError={Boolean(formHasError)}
        validationError={showErrors}
      >
        {showForm && (
          <>
            <SectionsGenerator
              MultipleInputsComponent={MultipleInputs}
              config={sectionGeneratorConfig}
              data={formData}
              errors={formErrors}
              setErrors={setErrors}
              showErrors={showErrors}
              updateData={setFormData}
            />
            <ShowMoreContent
              showMore={showMoreSection}
              setShowMore={setShowMoreSection}
            >
              <SectionsGenerator
                config={sectionGeneratorShowMoreConfig}
                data={formData}
                errors={formErrors}
                setErrors={setErrors}
                showErrors={showErrors}
                updateData={setFormData}
              />
            </ShowMoreContent>
          </>
        )}
      </ConstituentFormPopup>
    </ConstituentSectionWrapper>
  );
};

ConstituentsWorkAndContactsSection.propTypes = {
  data: propTypes.arrayOf(DonorType),
  isEditable: propTypes.bool,
  type: ConstituentSectionTypes.isRequired,
};

ConstituentsWorkAndContactsSection.defaultProps = {
  data: [],
  isEditable: false,
};

export default ConstituentsWorkAndContactsSection;
