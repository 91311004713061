import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { IntegrationsIcon } from 'assets/images';
import { BasicPageLayout, BasicPageTitle } from 'components/common';
import { translate } from 'lib/intl';
import IntegrationCard from './IntegrationCard';
import { useIntegrationsPage } from './integrationsPageHooks';

export default function IntegrationsPage() {
  const { orgHasIntegrations, connectedIntegrations, remainingIntegrations } =
    useIntegrationsPage();

  const Subtitle = ({ subtitle = '' }) => (
    <Box my={2}>
      <Typography variant="subtitle1">{subtitle}</Typography>
    </Box>
  );

  return (
    <BasicPageLayout
      data-testid="integrations-page"
      className="p-3 md:p-4 md:flex-col"
    >
      <BasicPageTitle
        iconSrc={IntegrationsIcon}
        iconAlt={translate('INTEGRATIONS_PAGE_TITLE_ICON_ALT')}
        title={translate('INTEGRATIONS_PAGE_TITLE')}
      />
      <Typography>{translate('INTEGRATIONS_PAGE_DESCRIPTION')}</Typography>
      {orgHasIntegrations ? (
        <>
          <Subtitle
            subtitle={translate('INTEGRATIONS_PAGE_SUBTITLE_YOUR_CONNECTIONS')}
          />
          <Box display="flex" flexWrap="wrap">
            {connectedIntegrations.map((integration) => (
              <IntegrationCard
                integration={integration}
                cta={translate('INTEGRATIONS_CTA_SETTINGS')}
              />
            ))}
          </Box>
        </>
      ) : null}
      {remainingIntegrations.length > 0 ? (
        <>
          <Subtitle
            subtitle={translate('INTEGRATIONS_PAGE_SUBTITLE_ALL_APPS')}
          />
          <Box display="flex" flexWrap="wrap">
            {remainingIntegrations.map((integration) => (
              <IntegrationCard
                integration={integration}
                cta={translate('INTEGRATIONS_CTA_CONNECT')}
              />
            ))}
          </Box>
        </>
      ) : null}
    </BasicPageLayout>
  );
}
