const { pxToRem } = require('./styleUtils');

const fontWeight = {
  semiBold: 600,
  medium: 500,
  regular: 400,
};

module.exports = {
  fontWeight,
  variants: {
    h1: {
      fontSize: pxToRem(24),
      fontWeight: fontWeight.semiBold,
      lineHeight: 1.5,
      letterSpacing: 0,
    },
    h2: {
      fontSize: pxToRem(20),
      fontWeight: fontWeight.medium,
      lineHeight: 1.5,
      letterSpacing: 0,
    },
    h3: {
      fontSize: pxToRem(16),
      fontWeight: fontWeight.medium,
      lineHeight: 1.5,
      letterSpacing: 0,
    },
    h4: {
      fontSize: pxToRem(10),
      fontWeight: fontWeight.semiBold,
      lineHeight: 1.5,
      letterSpacing: 1,
      textTransform: 'uppercase',
    },
    body1: {
      fontSize: pxToRem(16),
      fontWeight: fontWeight.regular,
      lineHeight: 1.5,
      letterSpacing: 0,
    },
    body2: {
      fontSize: pxToRem(16),
      fontWeight: fontWeight.medium,
      lineHeight: 1.5,
      letterSpacing: 0,
    },
    body3: {
      fontSize: pxToRem(14),
      fontWeight: fontWeight.regular,
      lineHeight: 1.5,
      letterSpacing: 0,
    },
    body4: {
      fontSize: pxToRem(14),
      fontWeight: fontWeight.medium,
      lineHeight: 1.5,
      letterSpacing: 0,
    },
    caption: {
      fontSize: pxToRem(12),
      fontWeight: fontWeight.regular,
      lineHeight: 1.5,
      letterSpacing: 0,
    },
  },
};
