export const CONSTITUENTS = {
  ALL_CONSTITUENTS: 'All Constituents',
  CONSTITUENTS: 'Constituents',
  CONSTITUENTS_ADD_CONSTITUENT_LABEL: 'Add constituent as',
  CONSTITUENTS_NEW_CONSTITUENT_INDIVIDUAL_LABEL: 'Individual',
  CONSTITUENTS_NEW_CONSTITUENT_ORG_LABEL: 'Organization',
  CONSTITUENT_REMOVE_CONNECTION_ERROR_MESSAGE:
    'There was a problem removing {name}',
  CONSTITUENT_CREATE_CONNECTION_ERROR_MESSAGE:
    'There was a problem creating {name}',
  CONSTITUENT_CREATE_UPDATING_ERROR_MESSAGE:
    'There was a problem updating {name}',
  CREATE_CONSTITUENT_ERROR_MESSAGE: 'There was a problem creating {name}',
  UPDATE_CONSTITUENT_ERROR_MESSAGE: 'There was a problem updating {name}',
  CONSTITUENTS_ACTIVITY_TAB: 'Activity',
  CONSTITUENTS_STAGE_INFO_TITLE: 'Constituent Stage',
  CONSTITUENTS_NOTE_PLACEHOLDER_1: 'Goal with this constituent',
  CONSTITUENTS_NOTE_PLACEHOLDER_2: 'Interesting tidbit',
  CONSTITUENTS_NOTE_PLACEHOLDER_3: 'Things to Avoid',
  CONSTITUENT_NAME_LABEL: 'Constituent Name',
  FETCH_CONSTITUENT_ERROR_MESSAGE: 'There was a problem fetching a constituent',
  CONSTITUENT_ADVOCATE_LABEL: 'Advocate',
  CONSTITUENT_PARTNER_LABEL: 'Partner',
  CONSTITUENT_VIP_LABEL: 'VIP',
  CONSTITUENT_CATEGORY: 'Category',
  CONSTITUENT_CONSTITUENT_CATEGORY: 'Constituent Category',
  CONSTITUENT_CORPORATION_LABEL: 'Corporation',
  CONSTITUENT_FOUNDATION_LABEL: 'Foundation',
  CONSTITUENT_RECIPIENT_LABEL: 'Client',
  CONSTITUENT_BOARD_MEMBER_LABEL: 'Board Member',
  CONSTITUENT_DONOR_LABEL: 'Donor',
  CONSTITUENT_MAJOR_DONOR_LABEL: 'Major Donor',
  CONSTITUENT_RECURRING_LABEL: 'Recurring',
  CONSTITUENT_VOLUNTEER_LABEL: 'Volunteer',
  CONSTITUENT_MEMBER_LABEL: 'Member',
  CONSTITUENT_CATEGORIES_REQUIRED_ERROR: 'Select at least 1 category',
  CONSTITUENT_TYPE: 'Constituent Type',
  CONSTITUENTS_RECENTLY_VIEWED_EMPTY: 'No Recently Viewed',
  CONSTITUENT_SEND_EMAIL: 'Send Email',
  CONSTITUENT_COPY_ADDRESS: 'Copy Address',
  CONSTITUENT_CALL: 'Call',
  CONSTITUENT_COPY_NUMBER: 'Copy Number',
  CONSTITUENT_SEARCH: 'Search Constituents',
  CONSTITUENT_UNCATEGORIZED: 'Uncategorized',
  CONSTITUENT_SORT_BY: 'Sort by',
  CONSTITUENT_SORT_A_Z: 'Name A-Z',
  CONSTITUENT_SORT_Z_A: 'Name Z-A',
  CONSTITUENT_SORT_NEWEST: 'Newest Interaction',
  CONSTITUENT_SORT_OLDEST: 'Oldest Interaction',
  CONSTITUENT_ASSIGNED_TO_ME: 'Assigned to me',
  CONSTITUENT_ASSIGNED_TO_ALL: 'Show all',
  CONSTITUENT_ASSIGNEE: 'Assignee',
  CONSTITUENT_ASSIGNEE_ASSIGNED: 'All assigned',
  CONSTITUENT_ASSIGNEE_UNASSIGNED: 'All unassigned',
  CONSTITUENT_LABELS: 'Labels',
  CONSTITUENT_REMOVE_CONFIRMATION:
    "Are you sure you want to remove this constituent's card from the kanban board?",
};
