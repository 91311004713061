import React from 'react';
import { func, string } from 'prop-types';
import { Box } from '@material-ui/core';
import styled from 'styled-components';
import { colors } from 'styles/theme';
import { CustomLink } from 'components';

const CustomLinkWrapper = styled(Box)`
  span {
    color: ${colors.darkGrey};
    font-size: 16px !important;
    font-style: italic;
    text-transform: capitalize;
  }
`;

/**
 * This is component
 * which updates CustomLink
 * with styles necessary for donors
 */
const AddMissingDataLink = ({ label, onClick }) => (
  <CustomLinkWrapper px={2}>
    <CustomLink onClick={onClick}>{label}</CustomLink>
  </CustomLinkWrapper>
);

AddMissingDataLink.propTypes = {
  label: string.isRequired,
  onClick: func.isRequired,
};

export default AddMissingDataLink;
