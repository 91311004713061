const data = {
  integrations: [
    {
      id: 'classy',
      name: 'Classy',
      logo: 'https://images.g2crowd.com/uploads/product/image/large_detail/large_detail_79c51f868a9565dbdd6b67e6199e556d/classy.png',
      shortDescription:
        'Automate your donation information from the Classy online fundraising platform.',
      longDescription:
        'Classy Enables Nonprofits To Build Simple Yet Powerful Online & Mobile Giving Experiences.',
      website: 'https://www.classy.org/',
      helperSteps: {
        title: 'How to Find Your Classy Credentials',
        description: null,
        steps: [
          {
            id: 'step-1',
            title: 'Step 1',
            description:
              '<style>.container { display: flex; } .two-thirds-left { width: 66%; padding-right: 32px; } .one-third-right { width: 33%; margin-top: -35px; } @media (max-width: 600px) { .container { flex-direction: column; } .two-thirds-left { width: 100%; } .one-third-right { width: 85%; margin: 16px auto 0; } }</style><div class="container"><div class="two-thirds-left">Head over to <a href="www.classy.org" style="color: #00A85D; text-decoration: none;">Classy.org</a> and log in to your account. If you are part of multiple organizations, be sure to choose the correct one that you wish to connect with Instil.</div><div class="one-third-right"><img style="width: 100%;" src="https://i.imgur.com/j0jOexQ.png"/></div>',
          },
          {
            id: 'step-2',
            title: 'Step 2',
            description:
              '<style>.long-image { width: 80%; } @media (max-width: 600px) { .long-image { width: 100% } } </style><div style="display: flex; flex-direction: column"><div>Once logged into Classy your brower’s navigation bar will contain a set of numbers after /admin/. This set of numbers is your Organization ID. Copy this from your Navigation bar to the form field above.</div><div style="display: flex; justify-content: center; padding-top: 12px;"><img class="long-image" src="https://i.imgur.com/grXicsc.png" /></div></div>',
          },
          {
            id: 'step-3',
            title: 'Step 3',
            description:
              '<style>.container { display: flex; } .two-thirds-left { width: 66%; padding-right: 32px; } .one-third-right { width: 33%; margin-top: -35px; } @media (max-width: 600px) { .container { flex-direction: column; } .two-thirds-left { width: 100%; } .one-third-right { width: 85%; margin: 16px auto 0; } }</style><div class="container"><div class="two-thirds-left">From your Classy account, select the Apps & Extensions link on the bottom left of the main navigation menu.</div><div class="one-third-right"><img style="width: 100%;" src="https://i.imgur.com/bNEyv0T.png"/></div>',
          },
          {
            id: 'step-4',
            title: 'Step 4',
            description:
              '<style>.container { display: flex; } .two-thirds-left { width: 66%; padding-right: 32px; } .one-third-right { width: 33%; margin-top: -35px; } @media (max-width: 600px) { .container { flex-direction: column; } .two-thirds-left { width: 100%; } .one-third-right { width: 85%; margin: 16px auto 0; } }</style><div class="container"><div class="two-thirds-left">From the Apps & Extensions screen, select “Classy API” to access your API credentials. If you don’t see this option, please contact Classy to enable access.</div><div class="one-third-right"><img style="width: 100%;" src="https://i.imgur.com/FipjMqO.png"/></div>',
          },
          {
            id: 'step-5',
            title: 'Step 5',
            description:
              '<style>.container { display: flex; } .two-thirds-left { width: 66%; padding-right: 32px; } .one-third-right { width: 33%; margin-top: -35px; } @media (max-width: 600px) { .container { flex-direction: column; } .two-thirds-left { width: 100%; } .one-third-right { width: 85%; margin: 16px auto 0; } }</style><div class="container"><div class="two-thirds-left"><div style="display: flex; flex-direction: column;">Create a new App by filling in the Application Name and Oauth2 Redirect URL with the details found below.</div><div style="background: #F8F9F9; border-radius: 8px; margin-top:16px; padding: 12px; display: flex; justify-content: space-between; flex-wrap: wrap;"><div><div style="font-weight: 600">Application Name</div><div>Instil</div></div><div><div style="font-weight: 600">Oauth2 Redirect URI</div><div>http://instil.io</div></div></div><div class="one-third-right"><img style="width: 100%;" src="https://i.imgur.com/2zwgtTE.png"/></div>',
          },
          {
            id: 'step-6',
            title: 'Step 6',
            description:
              '<style>.long-image { width: 80%; } @media (max-width: 600px) { .long-image { width: 100% } } </style><div style="display: flex; flex-direction: column"><div>Once created, your new app will appear below in the “Your Applications” list. To view your full Client ID and Client Secret, click Edit in the row of your newly created application.</div><div style="display: flex; justify-content: center; padding-top: 12px;"><img class="long-image" src="https://i.imgur.com/jCQXmdq.png" /></div></div>',
          },
          {
            id: 'step-7',
            title: 'Step 7',
            description:
              '<style>.container { display: flex; } .two-thirds-left { width: 66%; padding-right: 32px; } .one-third-right { width: 33%; margin-top: -35px; } @media (max-width: 600px) { .container { flex-direction: column; } .two-thirds-left { width: 100%; } .one-third-right { width: 85%; margin: 16px auto 0; } }</style><div class="container"><div class="two-thirds-left">Find your Client ID and Client Secret. Copy each value to it’s corosponding field in the form above. Once both have been copied over, save the connection.</div><div class="one-third-right"><img style="width: 100%;" src="https://i.imgur.com/3uL8f5J.png"/></div>',
          },
        ],
      },
    },
    // {
    //   id: 'mailchimp',
    //   name: 'MailChimp',
    //   logo: 'https://img.icons8.com/windows/452/mailchimp.png',
    //   shortDescription: 'Small description of the app purpose goes here.',
    //   longDescription: 'Long description of the app purpose goes here.',
    //   website: 'http://mailchimp.com',
    //   helperSteps: {
    //     title: 'How to Find Your Mailchimp Credentials',
    //     description: 'Lorem ipsum. You can get your MailChimp API Key here.',
    //     steps: [
    //       {
    //         id: 'step-1',
    //         title: 'Step 1',
    //         description: 'TBD MAILCHIMP',
    //       }
    //     ],
    //   },
    // },
    // {
    //   id: 'twitter',
    //   name: 'Twitter',
    //   logo: 'https://seeklogo.com/images/T/twitter-2012-positive-logo-916EDF1309-seeklogo.com.png',
    //   shortDescription: 'Small description of the app purpose goes here.',
    //   longDescription: 'Long description of the app purpose goes here.',
    //   website: 'http://twitter.com',
    //   helperSteps: {
    //     title: 'How to Find Your Twitter Credentials',
    //     description: 'Lorem ipsum. You can get your Twitter API Key here.',
    //     steps: [
    //       {
    //         id: 'step-1',
    //         title: 'Step 1',
    //         description: 'TBD TWITTER',
    //       }
    //     ],
    //   },
    // },
  ],
};

export default data;
