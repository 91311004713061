import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Box } from '@material-ui/core';

function CommonInfoSection({ children, className, ...props }) {
  return (
    <Box
      className={classNames(
        className,
        'md:border-r border-grey-300 flex-grow-0 flex-shrink-0 overflow-y-auto p-3 md:p-4 md:w-80'
      )}
      {...props}
    >
      {children}
    </Box>
  );
}

CommonInfoSection.propTypes = {
  ...Box.propTypes,
  className: PropTypes.string,
};

CommonInfoSection.defaultProps = {
  className: '',
};

export { CommonInfoSection };
