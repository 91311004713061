import React from 'react';
import { func } from 'prop-types';
import { makeStyles, Typography, Box, Grid } from '@material-ui/core';
import { Avatar, CustomLink } from 'components';
import { translate } from 'lib/intl';
import { useCurrentUser } from 'lib/hooks';
import { getInitials } from 'lib/utils';
import { EMPTY_FUNC } from 'lib/constants';

const useStyles = makeStyles((theme) => ({
  upperContainer: {
    padding: '26px 26px 16px',
    [theme.breakpoints.down('xs')]: {
      padding: '8px 16px 24px',
    },
  },
  emailText: {
    margin: theme.spacing(2),
    marginTop: 0,
  },
}));

// eslint-disable-next-line no-empty-pattern
const UserProfileUpper = React.forwardRef(
  ({ closeProfile, setEditModalOpen }, ref) => {
    const classes = useStyles();
    const { user } = useCurrentUser();

    const handleEditModalOpen = () => {
      closeProfile();
      setEditModalOpen(true);
    };

    return (
      <>
        <Grid
          data-testid="user-profile-upper"
          ref={ref}
          container
          className={classes.upperContainer}
        >
          <Grid
            item
            xs={12}
            style={{
              paddingBottom: '8px',
            }}
          >
            <Avatar
              alt={user?.displayName}
              // TODO add actual image once available
              size="56px"
              src="UserProfileImage"
            >
              {getInitials(user?.displayName)}
            </Avatar>
          </Grid>
          <Grid item xs={12}>
            <Typography
              className={classes.link}
              variant="subtitle1"
              style={{
                lineHeight: '16px',
                fontWeight: 500,
              }}
            >
              {user?.displayName}
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            style={{
              paddingBottom: '4px',
            }}
          >
            <Typography variant="caption">{user?.email}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Box display="inline-block" color="primary.main">
              <CustomLink onClick={handleEditModalOpen}>
                {translate('EDIT_PROFILE')}
              </CustomLink>
            </Box>
          </Grid>
        </Grid>
      </>
    );
  }
);

UserProfileUpper.propTypes = {
  setEditModalOpen: func,
  closeProfile: func,
};

UserProfileUpper.defaultProps = {
  setEditModalOpen: EMPTY_FUNC,
  closeProfile: EMPTY_FUNC,
};

export { UserProfileUpper };
