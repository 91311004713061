import React from 'react';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import { ICON_SIZES } from 'lib/constants/common';
import { translate } from 'lib/intl';
import { isMobileView } from 'lib/utils';
import { useStyles } from './integrationPageStyles';

export default function CredentialsSuccessMessage() {
  const isMobile = isMobileView();
  const styles = useStyles();
  return (
    <Box className={styles.credentialsSuccessMessage}>
      <CircularProgress
        classes={{ root: styles.credentialsSuccessCircularProgress }}
        variant="static"
        size={ICON_SIZES.XXL}
        value={100}
      />
      <Box>
        <Typography variant="subtitle1">
          {translate('INTEGRATIONS_SUCCESS')}
        </Typography>
        <Typography variant={isMobile ? 'body2' : 'body1'}>
          {translate('INTEGRATIONS_SUCCESS_MESSAGE')}
        </Typography>
      </Box>
    </Box>
  );
}
