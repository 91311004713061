import React, { memo } from 'react';
import { Box } from '@material-ui/core';
import { GapType } from 'lib/utils';

const Gap = memo(({ size, vertical }) => (
  <Box
    data-testid={`gap-${size}`}
    display="flex"
    pl={vertical ? 0 : size}
    pt={vertical ? size : 0}
  />
));

Gap.propTypes = GapType;

Gap.defaultProps = {
  size: 1,
  vertical: false,
};

export { Gap };
