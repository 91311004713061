import React from 'react';
import { toast } from 'react-toastify';
import { ToastFeedbackMessage } from 'components';
import { TOAST_TYPES, TOAST_FEEDBACK_IDS } from 'lib/constants';

const showFeedbackMessage = ({
  containerId = TOAST_FEEDBACK_IDS.APP,
  message,
  type,
}) =>
  toast(<ToastFeedbackMessage message={message} />, {
    containerId,
    type,
  });

const showErrorMessage = ({ message, containerId }) =>
  showFeedbackMessage({
    containerId,
    message,
    type: TOAST_TYPES.ERROR,
  });

const showSuccessMessage = ({ message, containerId }) =>
  showFeedbackMessage({
    containerId,
    message,
    type: TOAST_TYPES.SUCCESS,
  });

export { showErrorMessage, showSuccessMessage };
