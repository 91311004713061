import React from 'react';
import { Grid, Box, Link, makeStyles, Typography } from '@material-ui/core';
import { string } from 'prop-types';
import {
  CAMPAIGN_CATEGORIES,
  CAMPAIGN_GOALS,
  CampaignType,
  DEFAULT_CAMPAIGN_PROPS,
  formatWebAddress,
} from 'lib/configs';
import { CUSTOM_DATE_FORMAT, USER_VIEW_ENUMS } from 'lib/constants/common';
import { useCampaignForm } from 'lib/hooks';
import { CampaignModal, CustomLink } from 'components';
import { ShowForViews } from 'components/common/ShowForViews';
import { translate } from 'lib/intl';
import { formatCurrency, formatDate, formatUrl, mapCampaign } from 'lib/utils';
import { FeedSliderContentDetail } from './FeedSliderContentDetail';
import { FeedSliderContentAssignees } from './FeedSliderContentAssignees';

const useStyles = makeStyles((theme) => ({
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingBottom: theme.spacing(1.5),
  },
  container: {
    position: 'relative',
    padding: theme.spacing(3),
  },
  deleteIcon: {
    position: 'absolute',
    right: theme.spacing(3),
    top: theme.spacing(3),
    cursor: 'pointer',
  },
  bodyContainer: {
    paddingTop: theme.spacing(2.5),
  },
  detailTitle: {
    color: theme.palette.grey.dark,
  },
  detailContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  rowContainer: {
    paddingBottom: theme.spacing(1.5),
  },
  urlLink: {
    fontWeight: 500,
  },
}));

const FeedSliderCampaignContent = ({ ownerOrgId, ...campaignData }) => {
  const styles = useStyles();

  const { assignees, category, goals, url, startDate, endDate } = campaignData;

  const categoryConfig = CAMPAIGN_CATEGORIES.find(
    (config) => config.value === category
  );

  let goalAmount;
  let goalType;
  let goalConfig;
  let stretchAmount;

  // Currently the UI design only supports one goal, may change in the future
  if (goals?.length) {
    goalAmount = goals[0].goalAmount;
    goalType = goals[0].goalType;
    stretchAmount = goals[0].stretchAmount;
    goalConfig = CAMPAIGN_GOALS.find((config) => config.value === goalType);
  }

  const {
    // modal state related
    isCampaignModalOpen,
    openCampaignModal,
    closeCampaignModal,
    modalData,
    setModalData,
    modalErrors,
    setModalErrors,

    // api related
    compaignApiError,
    isCampaignSaving,
    saveCampaign,
    teamMembers,
  } = useCampaignForm();

  return (
    <Grid container>
      <Grid item xs={12} className={styles.titleContainer}>
        <Typography variant="subtitle1">
          {translate('CAMPAIGN_DETAILS')}
        </Typography>
        <ShowForViews showFor={[USER_VIEW_ENUMS.ADMIN, USER_VIEW_ENUMS.EDITOR]}>
          <CustomLink
            data-testid="campaign-edit"
            onClick={() => openCampaignModal(mapCampaign(campaignData))}
          >
            {translate('CAMPAIGN_DETAILS_EDIT')}
          </CustomLink>
        </ShowForViews>
      </Grid>
      {url && (
        <Grid container item xs={12} className={styles.rowContainer}>
          <Box>
            <Typography variant="caption" className={styles.detailTitle}>
              {translate('CAMPAIGN_DETAILS_WEBSITE')}
            </Typography>
            <Box className={styles.detailContainer}>
              <Link
                className={styles.urlLink}
                href={formatUrl(url)}
                rel="noopener noreferrer"
                target="_blank"
                variant="body1"
              >
                {formatWebAddress(url)}
              </Link>
            </Box>
          </Box>
        </Grid>
      )}
      <Grid container item xs={12} className={styles.rowContainer}>
        <Grid item xs={12} sm={6}>
          <FeedSliderContentDetail
            title={translate('CAMPAIGN_DETAILS_CATEGORY')}
            iconAlt={categoryConfig?.iconAlt}
            iconSrc={categoryConfig?.icon}
            value={categoryConfig?.text}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FeedSliderContentDetail
            title={translate('CAMPAIGN_DETAILS_GOAL_TYPE')}
            iconAlt={goalConfig?.iconAlt}
            iconSrc={goalConfig?.icon}
            value={goalConfig?.text}
          />
        </Grid>
      </Grid>
      <Grid container item xs={12} className={styles.rowContainer}>
        <Grid item xs={12} sm={6}>
          <FeedSliderContentDetail
            title={translate('CAMPAIGN_DETAILS_GOAL')}
            value={formatCurrency(goalAmount, true)}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FeedSliderContentDetail
            title={translate('CAMPAIGN_DETAILS_STRETCH_GOAL')}
            value={formatCurrency(stretchAmount, true)}
          />
        </Grid>
      </Grid>
      <Grid container item xs={12} className={styles.rowContainer}>
        <Grid item xs={12} sm={6}>
          <FeedSliderContentDetail
            title={translate('CAMPAIGN_DETAILS_START_DATE')}
            value={formatDate(startDate, CUSTOM_DATE_FORMAT)}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FeedSliderContentDetail
            title={translate('CAMPAIGN_DETAILS_END_DATE')}
            value={formatDate(endDate, CUSTOM_DATE_FORMAT)}
          />
        </Grid>
      </Grid>
      <Grid item xs={12} className={styles.titleContainer}>
        <Typography variant="subtitle1">
          {translate('CAMPAIGN_DETAILS_ADMINISTRATORS')}
        </Typography>
      </Grid>
      <Grid container item xs={12} className={styles.rowContainer}>
        <FeedSliderContentAssignees
          assignees={assignees}
          ownerOrgId={ownerOrgId}
        />
      </Grid>
      <CampaignModal
        data={modalData}
        teamMembers={teamMembers}
        errors={modalErrors}
        handleClose={closeCampaignModal}
        handleSave={saveCampaign}
        hasApiError={Boolean(compaignApiError)}
        isCreating={!modalData.campaignId}
        isLoading={isCampaignSaving}
        open={isCampaignModalOpen}
        updateData={setModalData}
        updateErrors={setModalErrors}
      />
    </Grid>
  );
};

FeedSliderCampaignContent.defaultProps = DEFAULT_CAMPAIGN_PROPS;

FeedSliderCampaignContent.propTypes = {
  ...CampaignType,
  ownerOrgId: string.isRequired,
};

export { FeedSliderCampaignContent };
