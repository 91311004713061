/**
 * The tooltip implementation that comes out-of-the-box with Material UI will
 * not work with D3 charts. MUI's <Tooltip> is meant to wrap the element that
 * the tooltip is for, such as a button. We need a tooltip to be position next
 * to a <rect> element generated by D3. To achieve that, this component
 * recreates some of the <Tooltip> functionality, borrowing liberally from it's
 * source code here:
 *
 * https://github.com/mui-org/material-ui/blob/6db7fb2fe09b60a3137df0211e3c29d7dc53abfa/packages/material-ui/src/Tooltip/Tooltip.js
 *
 * Those areas will be commented as "borrowed from <Tooltip> implementation".
 */

import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import Fade from '@material-ui/core/Fade';
import Popper from '@material-ui/core/Popper';
import { styles as tooltipStyles } from '@material-ui/core/Tooltip/Tooltip';
import { makeStyles, withStyles } from '@material-ui/styles';
import { capitalize } from 'voca';
import { D3ChartTooltipContext } from './D3ChartTooltipProvider';

const useStyles = makeStyles((theme) => ({
  arrow: {
    '&:before': {
      border: `1px solid ${theme.palette.grey[300]}`,
    },
  },
}));

function D3ChartTooltip({ children, classes, placement }) {
  const { anchor, showTooltip } = React.useContext(D3ChartTooltipContext);

  const styles = useStyles();
  // The arrow ref is passed to the underlying popper.js code that <Popper>
  // uses under the hood so we use this ref to get the DOM element.
  // Borrowed from <Tooltip> implementation.
  const [arrowRef, setArrowRef] = React.useState(null);

  // Borrowed from <Tooltip> implementation
  const popperOptions = React.useMemo(() => {
    return {
      modifiers: {
        arrow: {
          element: arrowRef,
        },
        flip: {
          enabled: false,
        },
        offset: {
          offset: '0, -6',
        },
      },
    };
  }, [arrowRef]);

  return (
    <Popper
      anchorEl={anchor}
      className={classnames(
        classes.popper, // popper classes borrowed from <Tooltip> implementation
        classes.popperArrow,
        classes.popperInteractive
      )}
      open={showTooltip}
      placement={placement}
      popperOptions={popperOptions}
      transition
    >
      {({ placement: popperPlacement, TransitionProps }) => (
        <Fade {...TransitionProps} timeout={250}>
          <div
            className={classnames(
              classes.tooltip, // tooltip classes borrowed from <Tooltip> implementation
              classes.tooltipArrow,
              classes[
                `tooltipPlacement${capitalize(popperPlacement.split('-')[0])}`
              ],
              'rounded bg-white border border-grey-300'
            )}
          >
            {children}
            <span
              className={classnames(classes.arrow, styles.arrow, 'text-white')} // arrow class borrowed from <Tooltip> implementation
              ref={setArrowRef}
            />
          </div>
        </Fade>
      )}
    </Popper>
  );
}

// withStyles borrowed from <Tooltip> implementation
export default withStyles(tooltipStyles, { name: 'MuiTooltip', flip: false })(
  D3ChartTooltip
);
D3ChartTooltip.propTypes = {
  children: PropTypes.node.isRequired,
  classes: PropTypes.object.isRequired,
  placement: PropTypes.string,
};

D3ChartTooltip.defaultProps = {
  placement: 'right',
};
