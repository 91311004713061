import { translate } from 'lib/intl';
import { FEATURE_FLAG_RESPONSE_TYPES } from 'lib/configs/superAdmin';

function determineAlertMessage(type, error) {
  let message = translate('FEATURE_FLAGS_ALERT_SUCCESS_EDIT');
  if (error) {
    if (type === FEATURE_FLAG_RESPONSE_TYPES.NEW) {
      message = translate('FEATURE_FLAGS_ALERT_ERROR_NEW');
    } else if (type === FEATURE_FLAG_RESPONSE_TYPES.DELETE) {
      message = translate('FEATURE_FLAGS_ALERT_ERROR_DELETE');
    } else {
      message = translate('FEATURE_FLAGS_ALERT_ERROR_EDIT');
    }
  } else if (type === FEATURE_FLAG_RESPONSE_TYPES.NEW) {
    message = translate('FEATURE_FLAGS_ALERT_SUCCESS_NEW');
  } else if (type === FEATURE_FLAG_RESPONSE_TYPES.DELETE) {
    message = translate('FEATURE_FLAGS_ALERT_SUCCESS_DELETE');
  }
  return message;
}

export { determineAlertMessage };
