import React, { useRef, useState } from 'react';
import classnames from 'classnames';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Collapse from '@material-ui/core/Collapse';
import Dialog from '@material-ui/core/Dialog';
import LinearProgress from '@material-ui/core/LinearProgress';
import Link from '@material-ui/core/Link';
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';
import { omit } from 'rambdax';
import {
  ExpandMore as ExpandMoreIcon,
  ExpandLess as ExpandLessIcon,
} from '@material-ui/icons';
import { ConfirmationPopup, Loader, PrimaryCheckbox } from 'components';
import { ShowForViews } from 'components/common/ShowForViews';
import { translate } from 'lib/intl';
import {
  formatCurrency,
  formatNumberOfDays,
  formatUrl,
  isMobileView,
  wrapWithStopPropagation,
} from 'lib/utils';
import { getNormalizedPercentage } from 'lib/utils/numberUtils';
import {
  CardType,
  CampaignCardDefaultProps,
  CAMPAIGN_EVENT_TYPES,
  formatWebAddress,
} from 'lib/configs';
import { CAMPAIGN_STATUS_ENUM, STOP_PROPAGATION } from 'lib/constants';
import { USER_VIEW_ENUMS } from 'lib/constants/common';
import { CalendarIcon, ThreeDotsIcon } from 'assets/images';
import { DonationAmount } from './DonationAmount';
import { CampaignActions } from './CampaignActions';
import { getLineColor, getDateInfoDistance } from './CampaignCard.utils';
import {
  useStyles,
  LEFT_COLUMN_MAX_WIDTH,
  DONATION_AMOUNTS_FLEX,
  CONTROL_ELS_WIDTH,
  CONTROL_ELS_MOBILE_WIDTH,
  BOOKMARKED_CHECKBOX_PROPS,
  ARCHIVED_LABEL_STYLE,
  POPOVER_TRANSFORM_ORIGIN,
  POPOVER_ANCHOR_ORIGIN,
  CONFIRM_POPOVER_ANCHOR_ORIGIN,
  CONFIRM_POPOVER_TRANSFORM_ORIGIN,
  LOADER_SIZE,
} from './CampaignCard.styles';

const CampaignCard = ({
  // handlers
  onClick,
  openCampaignModal,
  removeCampaign,
  updateCampaign,
  // states
  isLoading,
  ...campaignData
}) => {
  const {
    // campaign related props
    campaignId,
    description,
    displayName,
    category,
    date: eventDate,
    endDate,
    startDate,
    status,
    url,
    // statistics
    expectedProgress,
    goalAmount,
    avgDonation,
    maxDonation,
    numNewDonors,
    numReturningDonors,
    totalAmount,
    numDonorConstituents,
    isPinned,
    goalMet,
  } = campaignData;

  const classes = useStyles();

  const [showMore, setShowMore] = useState(false);

  const [anchorEl, setAnchorEl] = useState(null);

  const ref = useRef();
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [isConfirmationPopupOpen, setIsConfirmationPopupOpen] = useState(false);

  const isMobile = isMobileView();

  const isArchived = status === CAMPAIGN_STATUS_ENUM.ARCHIVED;
  const isCompleted = status === CAMPAIGN_STATUS_ENUM.COMPLETED;
  const isGoalReachable = goalMet || expectedProgress >= goalAmount;
  const isEventType = CAMPAIGN_EVENT_TYPES.includes(category);

  const distanceInfo = getDateInfoDistance({
    isArchived,
    isEventType,
    eventDate,
    endDate,
    startDate,
  });

  const formattedGoalAmount = formatCurrency(goalAmount, true);
  const formattedTotalAmount = formatCurrency(totalAmount, true);

  const lineColor = getLineColor({
    isArchived,
    isCompleted,
    isEventType,
    isGoalReachable,
  });

  const closeActionsModal = () => setAnchorEl(null);
  const onActionsModalExit = () => {
    if (showConfirmation) {
      setIsConfirmationPopupOpen(true);
    }
  };

  const openActionsModal = (e) => setAnchorEl(e.currentTarget);

  const toggleShowMore = () => setShowMore(!showMore);

  const closeConfirmationPopup = () => {
    setShowConfirmation(false);
    setIsConfirmationPopupOpen(false);
  };

  const onRemovalConfirmation = () => {
    closeConfirmationPopup();
    removeCampaign(campaignId);
  };

  const Actions = (
    <CampaignActions
      onActivateClick={() =>
        updateCampaign(campaignData, { status: CAMPAIGN_STATUS_ENUM.ACTIVE })
      }
      onArchiveClick={() =>
        updateCampaign(campaignData, { status: CAMPAIGN_STATUS_ENUM.ARCHIVED })
      }
      onCompleteClick={() =>
        updateCampaign(campaignData, {
          status: CAMPAIGN_STATUS_ENUM.COMPLETED,
        })
      }
      onCloseClick={closeActionsModal}
      onDeleteClick={() => setShowConfirmation(true)}
      onDuplicateClick={() =>
        openCampaignModal(omit(['campaignId'], campaignData))
      }
      onEditClick={() => openCampaignModal(campaignData)}
      status={status}
    />
  );

  return (
    <Box
      className={classnames(classes.card, {
        [classes.archived]: isArchived,
      })}
      data-testid="campaign-card"
      onClick={() => onClick(campaignId)}
      ref={ref}
    >
      <Box
        className={classes.coloredLine}
        data-testid="campaign-card-colored-line"
        css={{ bgcolor: lineColor }}
      />
      <ShowForViews showFor={[USER_VIEW_ENUMS.ADMIN, USER_VIEW_ENUMS.EDITOR]}>
        <Box className={classes.controlElements}>
          {isLoading ? (
            <Loader data-testid="campaign-card-loader" size={LOADER_SIZE} />
          ) : (
            <>
              <PrimaryCheckbox
                checkboxProps={{
                  ...BOOKMARKED_CHECKBOX_PROPS,
                  'data-testid': 'bookmarked-checkbox',
                  id: `${campaignId}-bookmarked-checkbox`,
                  name: `${campaignId}-bookmarked-checkbox`,
                }}
                onClick={STOP_PROPAGATION}
                onChange={() =>
                  updateCampaign(campaignData, { isPinned: !isPinned })
                }
                primary={isPinned}
              />
              {!isMobile && (
                <Box pl={1}>
                  <Button
                    className={classes.showActionsBtn}
                    data-testid="campaign-card-actions-button"
                    onClick={wrapWithStopPropagation(openActionsModal)}
                  >
                    <ThreeDotsIcon />
                  </Button>
                </Box>
              )}
            </>
          )}
        </Box>
      </ShowForViews>
      <Box>
        <Box pr={isMobile ? CONTROL_ELS_MOBILE_WIDTH : CONTROL_ELS_WIDTH}>
          <Typography className={classes.title}>{displayName}</Typography>
        </Box>
        <Box display="flex" flexWrap="wrap">
          <Box
            flex={`1 1 ${isMobile ? '100%' : LEFT_COLUMN_MAX_WIDTH}`}
            pr={isMobile ? 0 : 2}
            py={1}
          >
            <Box
              display="flex"
              flex="1"
              flexDirection={isMobile ? 'row' : 'column'}
              alignItems={isMobile ? 'center' : 'normal'}
            >
              {isMobile ? (
                <Typography variant="body2">{formattedTotalAmount}</Typography>
              ) : (
                <Typography variant="body2">
                  {translate('CAMPAIGN_GOAL_AMOUNT_VALUE_LABEL', {
                    value: formattedGoalAmount,
                  })}
                </Typography>
              )}
              <Box
                flex={isMobile ? '1' : 'none'}
                maxWidth={isMobile ? 'auto' : LEFT_COLUMN_MAX_WIDTH}
                pb={2}
                pt={isMobile ? 2 : 1}
                px={isMobile ? 1 : 0}
              >
                <LinearProgress
                  classes={{ root: classes.linearProgress }}
                  color={isArchived ? 'secondary' : 'primary'}
                  value={getNormalizedPercentage(totalAmount, goalAmount)}
                  valueBuffer={getNormalizedPercentage(
                    expectedProgress,
                    goalAmount
                  )}
                  variant="buffer"
                />
              </Box>
              {isMobile && (
                <Typography variant="body2">{formattedGoalAmount}</Typography>
              )}
            </Box>
            {isCompleted ? (
              <Typography className={classes.completedLabel}>
                {translate('COMPLETED')}
              </Typography>
            ) : (
              <Box display="flex" alignItems="center">
                <Box pr={1}>
                  <CalendarIcon />
                </Box>
                <Typography
                  variant="body2"
                  style={isArchived ? ARCHIVED_LABEL_STYLE : {}}
                >
                  {distanceInfo.label &&
                    translate(distanceInfo.label, {
                      days: formatNumberOfDays(distanceInfo.days),
                    })}
                </Typography>
              </Box>
            )}
          </Box>
          {!isMobile && (
            <Box
              display="flex"
              flex={DONATION_AMOUNTS_FLEX}
              flexWrap="wrap"
              justifyContent="space-between"
              py={1}
            >
              <DonationAmount
                isCurrencyBased
                label={translate('CAMPAIGN_TOTAL_AMOUNT_DONATED_LABEL')}
                value={totalAmount}
              />
              <DonationAmount
                label={translate('CAMPAIGN_TOTAL_NUMBER_OF_DONORS_LABEL')}
                value={numDonorConstituents}
              />
              <DonationAmount
                isCurrencyBased
                label={translate('CAMPAIGN_AVG_DONATION_LABEL')}
                value={avgDonation}
              />
            </Box>
          )}
        </Box>
        {!isMobile && (
          <>
            <Collapse in={showMore}>
              <Box display="flex" flexWrap="wrap">
                <Box
                  flex={`1 1 ${isMobile ? '100%' : LEFT_COLUMN_MAX_WIDTH}`}
                  flexWrap="wrap"
                  pr={2}
                  py={1}
                >
                  <Typography variant="body2">{description}</Typography>
                  {url && (
                    <Box pt={1}>
                      <Link
                        href={formatUrl(url)}
                        rel="noopener noreferrer"
                        target="_blank"
                        variant="body2"
                      >
                        {formatWebAddress(url)}
                      </Link>
                    </Box>
                  )}
                </Box>
                <Box
                  display="flex"
                  flex={DONATION_AMOUNTS_FLEX}
                  flexWrap="wrap"
                  justifyContent="space-between"
                  pb={1}
                  py={1}
                >
                  <DonationAmount
                    isCurrencyBased
                    label={translate('CAMPAIGN_LARGEST_DONATION_LABEL')}
                    value={maxDonation}
                  />
                  <DonationAmount
                    label={translate('CAMPAIGN_NEW_DONORS_LABEL')}
                    value={numNewDonors}
                  />
                  <DonationAmount
                    label={translate('CAMPAIGN_RETURN_DONORS_LABEL')}
                    value={numReturningDonors}
                  />
                </Box>
              </Box>
            </Collapse>
            <Button
              classes={{
                root: classes.showMoreBtn,
                text: classes.showMoreBtnText,
                endIcon: classes.showMoreBtnIcon,
              }}
              endIcon={showMore ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              onClick={wrapWithStopPropagation(toggleShowMore)}
            >
              {translate(showMore ? 'COLLAPSE' : 'EXPAND')}
            </Button>
          </>
        )}
        {isMobile && !isLoading && (
          <Button
            className={classes.showActionsBtn}
            onClick={wrapWithStopPropagation(openActionsModal)}
          >
            <ThreeDotsIcon />
          </Button>
        )}
      </Box>
      {isMobile ? (
        <Dialog
          classes={{ paperFullWidth: classes.paperFullWidth }}
          data-testid="campaign-card-actions-dialog"
          fullWidth
          maxWidth="md"
          onClick={STOP_PROPAGATION}
          onClose={closeActionsModal}
          onExited={onActionsModalExit}
          open={!!anchorEl}
        >
          {Actions}
        </Dialog>
      ) : (
        <Popover
          data-testid="campaign-card-actions-popover"
          anchorEl={anchorEl}
          transformOrigin={POPOVER_TRANSFORM_ORIGIN}
          anchorOrigin={POPOVER_ANCHOR_ORIGIN}
          onClick={STOP_PROPAGATION}
          open={!!anchorEl}
          onClose={closeActionsModal}
          onExited={onActionsModalExit}
        >
          {Actions}
        </Popover>
      )}
      <ConfirmationPopup
        anchorEl={ref?.current}
        anchorOrigin={CONFIRM_POPOVER_ANCHOR_ORIGIN}
        confirmButtonLabel={translate('DELETE')}
        message={translate('CAMPAIGNS_REMOVE_DESCRIPTION')}
        onClick={STOP_PROPAGATION}
        onConfirm={onRemovalConfirmation}
        onClose={closeConfirmationPopup}
        open={isConfirmationPopupOpen}
        transformOrigin={CONFIRM_POPOVER_TRANSFORM_ORIGIN}
      />
    </Box>
  );
};

CampaignCard.propTypes = CardType;

CampaignCard.defaultProps = CampaignCardDefaultProps;

export { CampaignCard };
