export const INTEGRATIONS = {
  INTEGRATIONS_PAGE_TITLE: 'Apps & Extensions',
  INTEGRATIONS_PAGE_TITLE_ICON_ALT: 'Apps & Extensions Icon',
  INTEGRATIONS_PAGE_DESCRIPTION:
    "Connect your existing apps and take advantage of Instil's powerful tools!",
  INTEGRATIONS_PAGE_SUBTITLE_YOUR_CONNECTIONS: 'Your Connections',
  INTEGRATIONS_PAGE_SUBTITLE_ALL_APPS: 'All Apps & Extensions',
  INTEGRATIONS_CTA_SETTINGS: 'Settings',
  INTEGRATIONS_CTA_CONNECT: 'Connect',
  INTEGRATIONS_TROUBLE: 'Trouble connecting your {name} account?',
  INTEGRATIONS_CONTACT: 'Contact',
  INTEGRATIONS_FOR_HELP: 'for help!',
  INTEGRATIONS_NEED_HELP:
    'Need help? See our instructions below or contact us at',
  INTEGRATIONS_CLASSY_HEADING: 'Classy API Credentials',
  INTEGRATIONS_CLASSY_ORG_ID: 'Classy Organization ID',
  INTEGRATIONS_CLASSY_CLIENT_ID: 'Classy Client ID',
  INTEGRATIONS_CLASSY_CLIENT_SECRET: 'Classy Client Secret',
  INTEGRATIONS_GENERIC_ERROR_MESSAGE:
    'Something went wrong with your integration, please try again.',
  INTEGRATIONS_SAVE: 'Save',
  INTEGRATIONS_EDIT: 'Edit Credentials',
  INTEGRATIONS_REMOVE_CONNECTION: 'Remove Connection',
  INTEGRATIONS_CONNECTING: 'Connecting to',
  INTEGRATIONS_VERIFY:
    'Hang tight while we verify your credentials and gather your data.',
  INTEGRATIONS_CANCEL: 'Cancel',
  INTEGRATIONS_REMOVE: 'Remove',
  INTEGRATIONS_CONFIRM_HEADER: 'Are you sure?',
  INTEGRATIONS_CONFIRM_DESCRIPTION:
    'Are you sure you want to remove this connection? If removed, your data will no longer be synced with Instil. Current data will remain, but no new data will be imported.',
  INTEGRATIONS_UPDATE: 'Update',
  INTEGRATIONS_SUCCESS: 'Connection Successful',
  INTEGRATIONS_SUCCESS_MESSAGE:
    'Your data is being imported. This may take several minutes to complete. If we run into any problems, we will let you know!',
};
