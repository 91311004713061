import { createAction } from 'lib/utils';

export const types = {
  open: 'OPEN_CONSTITUENT_FORM_POPUP',
  close: 'CLOSE_CONSTITUENT_FORM_POPUP',
};

const openFormPopup = (data) => createAction({ type: types.open, data });

const closeFormPopup = () => createAction({ type: types.close });

export default {
  closeFormPopup,
  openFormPopup,
  types,
};
