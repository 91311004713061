import React from 'react';
import { Box } from '@material-ui/core';
import { CustomTable, ExportButton, Loader, SearchInput } from 'components';
import { DEFAULT_DATE_FORMAT, SEARCH_INPUT_MAX_WIDTH } from 'lib/constants';
import { formatDateObject, isMobileView } from 'lib/utils';
import { translate } from 'lib/intl';
import { CampaignDonationCard } from './CampaignDonationCard';
import { useCampaignDonations } from './CampaignDonations.hooks';
import {
  COLUMNS_CONFIG,
  CSV_LINK_HEADERS,
  TABLE_ID_FIELD,
  TABLE_STYLES,
} from './CampaignDonations.config';
import { mapDonationsForExport } from './CampaignDonations.utils';

const CampaignDonations = () => {
  const isMobile = isMobileView();

  const {
    campaignName,
    donations,
    search,
    setSearch,
    sorting,
    setSorting,
    showLoader,
  } = useCampaignDonations();

  const exportFileName = [
    campaignName,
    translate('CAMPAIGN_DONATIONS_TAB'),
    formatDateObject(Date.now(), DEFAULT_DATE_FORMAT),
  ].join('_');

  if (showLoader) {
    return <Loader isCentered py={2} />;
  }

  return (
    <Box pb={isMobile ? 2 : 0}>
      <Box
        alignItems="center"
        display="flex"
        justifyContent="space-between"
        m={2}
      >
        <Box
          flex={1}
          maxWidth={isMobile ? 'none' : SEARCH_INPUT_MAX_WIDTH}
          pr={isMobile ? 2 : 0}
        >
          <SearchInput
            onChange={setSearch}
            placeholder={translate('CAMPAIGN_SEARCH_DONORS_LABEL')}
            value={search}
          />
        </Box>
        {Boolean(donations?.length) && (
          <ExportButton
            data={mapDonationsForExport(donations)}
            filename={exportFileName}
            headers={CSV_LINK_HEADERS}
          />
        )}
      </Box>
      {isMobile &&
        donations?.map((donation) => (
          <CampaignDonationCard key={donation.donationId} {...donation} />
        ))}
      {!isMobile && (
        <CustomTable
          columns={COLUMNS_CONFIG}
          data={donations}
          filter={sorting}
          idField={TABLE_ID_FIELD}
          key={donations.length}
          // TODO: add onRowClick handler if necessary
          setFilter={setSorting}
          tableStyles={TABLE_STYLES}
        />
      )}
    </Box>
  );
};

export default CampaignDonations;
