import React from 'react';
import DeleteDonationModal from 'components/common/donation/DeleteDonationModal';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import format from 'date-fns/format';
import { AUTH_ROUTES } from 'lib/constants/routes';
import { DonationDetailsType } from 'lib/configs/donations';
import {
  FEATURE_FLAG_DELETE_DONATIONS,
  FEATURE_FLAG_EDIT_DONATIONS,
} from 'lib/constants/featureFlags';
import { MoreHoriz } from '@material-ui/icons';
import { translate } from 'lib/intl';
import { useFeature } from 'flagged';
import {
  generatePath,
  useHistory,
  useParams,
  useRouteMatch,
} from 'react-router-dom';

const FeedSliderDonationFooter = ({ created, donationId, updated }) => {
  const id = 'feed-slider-menu';
  const [menuAnchor, setMenuAnchor] = React.useState(null);
  const [deleteModalActive, setDeleteModalActive] = React.useState(false);
  const history = useHistory();
  const match = useRouteMatch();
  const { campaignId, constituentId } = useParams();

  const showDelete = useFeature(FEATURE_FLAG_DELETE_DONATIONS);
  const showEdit = useFeature(FEATURE_FLAG_EDIT_DONATIONS);

  const openMenu = (e) => setMenuAnchor(e.currentTarget);
  const closeMenu = () => setMenuAnchor(null);
  const afterDelete = () => {
    let path;
    switch (match.path) {
      case AUTH_ROUTES.CAMPAIGN_FEED:
        path = generatePath(AUTH_ROUTES.CAMPAIGN, { campaignId });
        break;
      case AUTH_ROUTES.CONSTITUENT_HISTORY:
        path = generatePath(AUTH_ROUTES.CONSTITUENT, { constituentId });
        break;
      case AUTH_ROUTES.FEED:
      default:
        path = generatePath(AUTH_ROUTES.LANDING);
        break;
    }
    setDeleteModalActive(false);
    history.push(path);
  };

  const items = [
    showEdit && {
      label: translate('EDIT'),
      onClick: () => {
        // TODO(jon): Editing to come.
      },
    },
    showDelete && {
      label: translate('DELETE'),
      onClick: () => {
        closeMenu();
        setDeleteModalActive(true);
      },
    },
  ].filter(Boolean);
  return (
    <div className="border-t border-grey-300 flex items-start pt-1">
      <div className="mr-auto mt-3 type-caption text-text-secondary">
        <div>
          {translate('ADDED_DATE', {
            date: format(new Date(created), 'PPPp'),
          })}
        </div>
        {created !== updated && (
          <div>
            {translate('LAST_EDITED_DATE', {
              date: format(new Date(updated), 'PPPp'),
            })}
          </div>
        )}
      </div>
      {!!items.length && (
        <>
          <IconButton
            onClick={openMenu}
            className="ml-4 text-text-secondary"
            aria-controls={menuAnchor ? id : undefined}
            aria-haspopup="true"
          >
            <MoreHoriz />
          </IconButton>

          <Menu
            id={id}
            onClose={closeMenu}
            open={!!menuAnchor}
            anchorEl={menuAnchor}
          >
            {items.map(({ label, onClick }) => (
              <MenuItem key={label} onClick={onClick}>
                {label}
              </MenuItem>
            ))}
          </Menu>
        </>
      )}
      <DeleteDonationModal
        donationId={donationId}
        onClose={() => setDeleteModalActive(false)}
        onSuccess={afterDelete}
        open={deleteModalActive}
      />
    </div>
  );
};

FeedSliderDonationFooter.propTypes = DonationDetailsType;

export default FeedSliderDonationFooter;
