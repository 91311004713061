import userActionTypes from 'rdx/actionTypes';

const { USERS_LIST_REQUEST, USERS_LIST_SUCCESS, USERS_LIST_FAILURE } =
  userActionTypes;

const initialState = {
  isFetching: false,
  error: null,
  users: [],
};

export function usersList(state = initialState, action) {
  switch (action.type) {
    case USERS_LIST_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: null,
      };
    case USERS_LIST_SUCCESS:
      return {
        ...state,
        isFetching: false,
        users: action?.data?.users || [],
      };
    case USERS_LIST_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.error,
      };
    default:
      return state;
  }
}
