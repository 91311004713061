import React from 'react';
import { matchPath, Route, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { values } from 'rambdax';
import { connect, useSelector } from 'react-redux';
import { AUTH_ROUTES } from 'lib/constants/routes';

const AuthRoute = ({ isAuthenticated, component: Component, ...rest }) => {
  const { prevPath } = useSelector((state) => ({
    prevPath: state.location.prevPath,
  }));
  const isPathPublic = values(AUTH_ROUTES)?.some((route) => {
    const matchRes = matchPath(prevPath, {
      path: route,
      exact: true,
      strict: false,
    });

    return matchRes?.isExact;
  });

  // If the user is authenticated and the prevPath is a public route, it is
  // possible that they could get caught in a redirect loop between two public
  // routes, when the user really should be sent into the logged-in portion of the
  // app. This happened with reset password. If the user is authenticated, then we
  // want to send them to the authenticated landing page.
  const redirectPath =
    isAuthenticated && !isPathPublic ? AUTH_ROUTES.LANDING : prevPath;

  return (
    <Route
      {...rest}
      render={(props) =>
        !isAuthenticated ? (
          <Component {...props} />
        ) : (
          <Redirect to={redirectPath} />
        )
      }
    />
  );
};

AuthRoute.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
  component: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  const { isAuthenticated } = state.authentication;

  return { isAuthenticated };
};

const ConnectedAuthRoute = connect(mapStateToProps)(AuthRoute);

export default ConnectedAuthRoute;
