import React from 'react';
import Popper from '@material-ui/core/Popper';
import PropTypes from 'prop-types';

/*
  Overrides default Popper for Autocomplete and
  makes sure that Popper has always a proper width to fit its content.
  props in this case are provided by Autocomplete component
*/
export default function TextSelectFieldPopper({ style, ...props }) {
  return (
    <Popper
      {...props}
      style={{ ...style, minWidth: 'fit-content' }}
      placement="bottom-start"
    />
  );
}

TextSelectFieldPopper.propTypes = {
  style: PropTypes.shape({}).isRequired,
};
