import {
  format,
  formatDistanceStrict,
  isMatch,
  parse,
  parseISO,
} from 'date-fns';
import {
  API_DATE_FORMAT,
  DEFAULT_DATE_FORMAT,
  HOURS_IN_DAY,
  MINUTES_IN_HOUR,
  TIME_TYPE_ENUM,
} from 'lib/constants';
import { roundToFixed } from './numberUtils';

const formatDistance = (
  date,
  baseDate = new Date(Date.now()),
  props = { addSuffix: true, unit: 'day' }
) => (date ? formatDistanceStrict(new Date(date), baseDate, props) : '');

const formatDate = (date, dateFormat = DEFAULT_DATE_FORMAT) => {
  try {
    return format(parseISO(date), dateFormat);
  } catch (e) {
    return date;
  }
};

const formatDateObject = (date, dateFormat = DEFAULT_DATE_FORMAT) => {
  try {
    return format(date, dateFormat);
  } catch (e) {
    return date;
  }
};

const isValidDate = (date, dateFormat = DEFAULT_DATE_FORMAT) =>
  isMatch(date, dateFormat);

const getTimeInHours = (value, type = TIME_TYPE_ENUM.HOURS) => {
  if (type === TIME_TYPE_ENUM.MINUTES) {
    return roundToFixed(value / MINUTES_IN_HOUR);
  }
  if (type === TIME_TYPE_ENUM.DAYS) {
    return value * HOURS_IN_DAY;
  }

  return Number(value);
};

const parseDateValue = (date) => {
  return date ? parse(date, API_DATE_FORMAT, new Date()) : new Date();
};

export {
  formatDate,
  formatDateObject,
  formatDistance,
  isValidDate,
  getTimeInHours,
  parseDateValue,
};
