import userActionTypes from 'rdx/actionTypes';

const {
  REMOVE_DONOR_CONNECTION_REQUEST,
  REMOVE_DONOR_CONNECTION_SUCCESS,
  REMOVE_DONOR_CONNECTION_FAILURE,
} = userActionTypes;

const initialState = {};

export function removingDonorInfo(state = initialState, action) {
  switch (action.type) {
    case REMOVE_DONOR_CONNECTION_REQUEST:
      return {
        ...state,
        [action.id]: {
          dataLoaded: false,
          isFetching: true,
          error: null,
        },
      };
    case REMOVE_DONOR_CONNECTION_SUCCESS:
      return {
        ...state,
        [action.id]: {
          data: action.data,
          dataLoaded: true,
          isFetching: false,
          error: null,
        },
      };
    case REMOVE_DONOR_CONNECTION_FAILURE:
      return {
        ...state,
        [action.id]: {
          dataLoaded: false,
          isFetching: false,
          error: action.error || 'error',
        },
      };
    default:
      return state;
  }
}
