import React from 'react';
import { AuthContainer, Loader } from 'components';
import { Box } from '@material-ui/core';
import { translate } from 'lib/intl';
import { PUBLIC_ROUTES } from 'lib/constants';

const AdminLandingPage = () => (
  <AuthContainer
    size="md"
    navLinkText={translate('SIGN_IN_INSTEAD')}
    navLinkRoute={PUBLIC_ROUTES.ADMIN_LOGIN}
  >
    <Box
      style={{
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      <Loader />
    </Box>
  </AuthContainer>
);

export { AdminLandingPage };
