import colors from './colors';

export default {
  // old colors here
  primaryBackground: '#fff',

  /* Style guide specific colors */
  darkGreen: '#01562F',
  primaryGreen: '#00A85D',
  darkerGreenHover: '#008F4F',
  secondaryGreen: '#00D473',
  lightGreen: '#E1F9E5',
  darkerLightGreenHover: '#A6E7BA',
  darkerLightGreenPressed: '#7AD593',

  // Used on button hover style
  mediumGreen: '#008F4F',

  black: '#000',
  darkGrey: '#666',
  lightGrey: '#ECECEC',
  offWhite: '#F8F9F9',
  red: '#FF0500',
  lightBlue: '#0094FF',
  green: '#5DD79F',

  // Donor attribute icon fill colors
  boardMember: '#9029CF',
  majorDonor: '#DC0C70',
  recurring: '#DC0C70',
  donor: '#DC0C70',
  automation: '#DC0C70',
  volunteer: '#2C68DD',
  member: '#EF870C',
  partner: '#6EC517',
  client: '#14AAEA',
  advocate: '#D42CB9',
  vip: '#EBB814',

  /* End Style guide specific colors */

  /* Let's move away from using the below styles going forward, or check with designer for usage */
  transparentGreen: 'rgba(0, 168, 93, 0.1)',
  grey300: '#F8F9FA',
  grey500: '#C4C4C4',
  transparentGrey: 'rgba(0, 0, 0, 0.12)',
  transparentBack: 'rgba(0, 0, 0, 0.4)',
  transparentBlack2: 'rgba(0, 0, 0, 0.6)',
  transparentBlack3: 'rgba(0, 0, 0, 0.8)',
  white: '#fff',
  transparentWhite: 'rgba(255, 255, 255, 0.4)',
  transparentError: 'rgba(255, 12, 62, 0.5)',
  greyCustom2: '#F3F5F5',
  transparentGreyCustom2: 'rgba(243, 245, 245, 0.6)',
  greyCustom3: '#696969',

  progressGreen: '#00A85D',
  progressDarkGrey: '#D5D5D5',
  progressLightGrey: '#E4E4E4',

  feedInteraction: '#FF7A00',
  feedAnnouncement: '#0038FF',
  feedDonation: '#FF0500',
  feedTrend: '#0256B7',
  feedCampaign: '#9319DD',

  scrollbarColor: '#C4C4CC',
  ...colors,
};
