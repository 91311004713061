import { createAction } from 'lib/utils';
import { orgsService } from 'services/orgs';
import userActionTypes from 'rdx/actionTypes';

const {
  ORGS_LIST_SUCCESS,
  ORGS_LIST_REQUEST,
  ORGS_LIST_FAILURE,
  ORGS_CREATE_SUCCESS,
} = userActionTypes;

const fetchOrgs =
  ({ limit = 50, page = null } = {}) =>
  async (dispatch) => {
    dispatch(
      createAction({
        type: ORGS_LIST_REQUEST,
      })
    );

    try {
      const response = await orgsService.getOrgList(limit, page);

      if (response?.name === 'Error') {
        dispatch(
          createAction({
            type: ORGS_LIST_FAILURE,
            error: response.message,
          })
        );
      } else {
        dispatch(
          createAction({
            type: ORGS_LIST_SUCCESS,
            data: response?.data || {},
          })
        );
      }
    } catch (err) {
      dispatch(
        createAction({
          type: ORGS_LIST_FAILURE,
          error: 'Network error',
        })
      );
    }
  };

const createOrg = (org) => async (dispatch) => {
  try {
    const response = await orgsService.createOrg(org);

    dispatch(
      createAction({
        type: ORGS_CREATE_SUCCESS,
        data: response?.data || {},
      })
    );

    return response;
  } catch (err) {
    return err;
  }
};
export const orgActions = {
  createOrg,
  fetchOrgs,
};
