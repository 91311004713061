import React, { useRef } from 'react';
import { useDispatch } from 'react-redux';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core';
import {
  BasicPageLayout,
  CommonInfoSection,
  PageLoader,
  PageTabs,
  Visible,
  Loader,
  MultiActionDialogManager,
} from 'components';
import { isMobileView } from 'lib/utils';
import { PAGE_TABS_HEIGHT, ZERO } from 'lib/constants';
import {
  ConstituentInfoSection,
  ConstituentHistoryContent,
  ConstituentInfoTab,
} from 'components/constituents';
import searchActions from 'rdx/actions/constituentsSearchActions';
import { useConstituentPage } from './constituentPageHooks';

const useStyles = makeStyles({
  tabContent: {
    overflowY({ isFeed }) {
      return isFeed ? 'hidden' : 'auto';
    },
  },
});

const ConstituentPage = () => {
  const {
    constituent,
    constituentFetching,
    constituentLoaded,
    constituentType,
    isNewConstituent,
    onTabClick,
    tabIdx,
    tabsConfig,
    openDonorForm,
    closeDonorForm,
    showPageLoader,
    isEditable,
  } = useConstituentPage();
  const constituentId = constituent?.constituentId;
  const dispatch = useDispatch();

  const refreshConstituentSearch = React.useCallback(
    () => dispatch(searchActions.refresh(constituentId)),
    [constituentId]
  );

  const isMobile = isMobileView();
  const containerRef = useRef(null);
  const topContainerId = 'donor-page-container';
  // eslint-disable-next-line no-nested-ternary
  const tabPadding = tabIdx === ZERO ? 0 : isMobile ? 1 : 2;

  const classes = useStyles({
    isFeed: tabIdx === ZERO,
  });

  return (
    <>
      <BasicPageLayout id={topContainerId} ref={containerRef}>
        <CommonInfoSection>
          <ConstituentInfoSection
            data={constituent}
            dataLoaded={isNewConstituent || constituentLoaded}
            dataFetching={constituentFetching}
            constituentType={constituentType}
            isEditable={isEditable}
            isNewConstituent={isNewConstituent}
            openDonorForm={openDonorForm}
            closeDonorForm={closeDonorForm}
          />
        </CommonInfoSection>
        <PageTabs.Wrapper>
          <Box width="100%">
            <PageTabs tabs={tabsConfig} onChange={onTabClick} tabIdx={tabIdx} />
          </Box>
          <PageTabs.Content className={classes.tabContent} p={tabPadding}>
            <Visible when={Boolean(tabIdx === ZERO)}>
              <Visible
                when={Boolean(!isNewConstituent && constituentId)}
                fallback={
                  <Box dislay="flex" pt={3.75}>
                    <Loader isCentered />
                  </Box>
                }
              >
                <ConstituentHistoryContent
                  constituentId={constituent?.constituentId}
                  constituentType={constituentType}
                  layoutContainerId={topContainerId}
                  layoutContainerRef={containerRef}
                  topOffset={PAGE_TABS_HEIGHT}
                />
              </Visible>
            </Visible>
            <Visible when={tabIdx !== ZERO}>
              <ConstituentInfoTab
                constituent={constituent}
                constituentType={constituentType}
                isEditable={isEditable}
                isNewConstituent={isNewConstituent}
              />
            </Visible>
          </PageTabs.Content>
        </PageTabs.Wrapper>
      </BasicPageLayout>
      <MultiActionDialogManager onEntityCreated={refreshConstituentSearch} />
      <PageLoader isVisible={showPageLoader} />
    </>
  );
};

export default ConstituentPage;
