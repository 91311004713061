import { types } from '../actions/constituentActions';

const initialState = {
  dataLoaded: false,
  isFetching: false,
  error: null,
  data: {},
};

/* eslint-disable no-case-declarations */
export default function constituentReducer(state = initialState, action) {
  switch (action.type) {
    case types.CONSTITUENT_REQUEST:
      return {
        ...state,
        dataLoaded: false,
        isFetching: true,
        error: null,
      };
    case types.CONSTITUENT_REQUEST_SUCCESS:
      return {
        ...state,
        data: action.data,
        dataLoaded: true,
        isFetching: false,
        error: null,
      };
    case types.CONSTITUENT_REQUEST_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.error,
      };
    // Updating 'Assign' for a card now present on constituent page
    // Track the changes made by that API on the constituent info to maintain a consitent UI state
    case types.CONSTITUENT_UPDATE_SUCCESS:
      const updatedConstituent = action?.data?.constituent;
      const currentConstituentCopy = { ...state.data.constituent };

      // Copy over the kanban properties as the PUT request does not return them as of yet
      // TODO revisit when API is updated
      updatedConstituent.kanbanCategory =
        currentConstituentCopy?.kanbanCategory;
      updatedConstituent.kanbanPriority =
        currentConstituentCopy?.kanbanPriority;

      return {
        ...state,
        isUpdating: false,
        data: { constituent: updatedConstituent },
      };
    // Updating 'Move' for a card is a kanban functionality but it now exists on the constituent page
    // When the category or priority is changed, the constituent must be updated in this store to
    // properly reflect the update on the constituent page
    case types.CONSTITUENT_BOARD_UPDATE_SUCCESS:
      const constituentState = state?.data?.constituent;
      const updatedConstituents = action?.data?.constituents || [];
      const matchingUpdatedConstituent = updatedConstituents.find(
        (constituent) =>
          constituent.constituentId === constituentState?.constituentId
      );

      if (matchingUpdatedConstituent) {
        constituentState.summary.category = matchingUpdatedConstituent.category;
        constituentState.summary.priority = matchingUpdatedConstituent.priority;
      }

      return {
        ...state,
        data: matchingUpdatedConstituent
          ? { constituent: constituentState }
          : state.data,
      };
    case types.REMOVE_CONSTITUENT_CONNECTION_SUCCESS: {
      const { id, apiType } = action;

      const constituentData = state.data.constituent;
      const filteredConnections = constituentData[apiType]?.filter(
        (item) => item.constituentId !== id
      );

      return {
        ...state,
        data: {
          constituent: {
            ...constituentData,
            [apiType]: filteredConnections,
          },
        },
      };
    }
    case types.CREATE_CONSTITUENT_CONNECTION_SUCCESS: {
      const { apiType, data } = action;

      const constituentData = state.data.constituent;
      const connections = [...(constituentData[apiType] || []), { ...data }];

      return {
        ...state,
        data: {
          constituent: {
            ...constituentData,
            [apiType]: connections,
          },
        },
      };
    }
    case types.UPDATE_CONSTITUENT_CONNECTION_SUCCESS: {
      const { id, apiType, data } = action;

      const constituentData = state.data.constituent;
      const connections = constituentData[apiType]?.map((connection) => {
        if (connection.constituentId === id) {
          return {
            ...connection,
            ...data,
          };
        }

        return connection;
      });

      return {
        ...state,
        data: {
          constituent: {
            ...constituentData,
            [apiType]: connections,
          },
        },
      };
    }
    case types.UPDATE_CONSTITUENT_SUCCESS: {
      const constituentData = state.data.constituent;

      [
        'categoryBoardMember',
        'categoryPartner',
        'categoryMember',
        'categoryRecipient',
        'categoryVolunteer',
      ].forEach((constituentType) => {
        if (action.data[constituentType] !== undefined) {
          constituentData[constituentType] = action.data[constituentType];
        }
      });

      return {
        ...state,
        data: {
          constituent: {
            ...constituentData,
            ...action.data,
          },
        },
      };
    }
    default:
      return state;
  }
}
