import React from 'react';
import PropTypes from 'prop-types';
import { ToastContainer } from 'react-toastify';
import Box from '@material-ui/core/Box';
import makeStyles from '@material-ui/styles/makeStyles';
import {
  TOAST_AUTO_CLOSE_TIME,
  TOASTS_LIMIT,
  TOAST_FEEDBACK_IDS,
} from 'lib/constants';
import { CloseButton } from './CloseButton';
import 'react-toastify/dist/ReactToastify.css';

const useStyles = makeStyles((theme) => ({
  container: {
    '& .Toastify__toast-container': {
      position: ({ stickToModule }) => (stickToModule ? 'relative' : 'fixed'),
      top: 0,
      width: ({ stickToModule }) => (stickToModule ? '100%' : '100vw'),

      '& .Toastify__toast': {
        borderRadius: theme.shape.borderRadius[0],
        boxShadow: 'none',
        color: theme.typography.color,
        marginBottom: theme.spacing(0.5),
        minHeight: 44,
        padding: `0 ${theme.spacing(1.5)}px`,

        '&--success': {
          backgroundColor: theme.palette.success.light,
          borderColor: theme.palette.success.light,
        },
        '&--error': {
          backgroundColor: theme.palette.error.light,
          borderColor: theme.palette.error.light,
        },
      },
    },
  },
}));

const ToastFeedback = ({ containerId, stickToModule }) => {
  const classes = useStyles({ stickToModule });

  return (
    <Box className={classes.container}>
      <ToastContainer
        autoClose={TOAST_AUTO_CLOSE_TIME}
        containerId={containerId}
        closeButton={CloseButton}
        enableMultiContainer
        hideProgressBar
        newestOnTop
        limit={TOASTS_LIMIT}
        position="top-center"
      />
    </Box>
  );
};

ToastFeedback.propTypes = {
  containerId: PropTypes.string,
  stickToModule: PropTypes.bool,
};

ToastFeedback.defaultProps = {
  containerId: TOAST_FEEDBACK_IDS.APP,
  stickToModule: false,
};

export { ToastFeedback };
