import { createApiRequest } from 'lib/utils';

function buildPath({ limit, ownerOrgId, page }) {
  let path = `campaigns?scope=org&owner_org_id=${ownerOrgId}`;

  if (limit) {
    path = `${path}&limit=${limit}`;
  }

  if (page) {
    path = `${path}&page=${page}`;
  }

  return path;
}

const getCampaigns = (ownerOrgId, { limit, page } = {}) =>
  createApiRequest({
    method: 'get',
    // TODO: remove limit once api is finalized and we took care of ux
    path: buildPath({ limit, ownerOrgId, page }),
    throwErrors: true,
  });

const getCampaign = (campaignId) =>
  createApiRequest({
    method: 'get',
    path: `campaigns/${campaignId}`,
    throwErrors: true,
  });

const removeCampaign = (campaignId) =>
  createApiRequest({
    method: 'delete',
    path: `campaigns/${campaignId}`,
    throwErrors: true,
  });

const createOrUpdateCampaign = ({ campaignId, data }) =>
  createApiRequest({
    data,
    method: campaignId ? 'put' : 'post',
    path: campaignId ? `campaigns/${campaignId}` : 'campaigns',
    throwErrors: true,
  });

const fetchCampaignDonations = (campaignId) =>
  createApiRequest({
    method: 'get',
    path: `donations?scope=cmp&campaign_id=${campaignId}`,
    throwErrors: true,
  });

export const campaignsService = {
  createOrUpdateCampaign,
  getCampaigns,
  getCampaign,
  removeCampaign,
  fetchCampaignDonations,
};
