import {
  camelCaseKeys,
  createAction,
  getFullName,
  showErrorMessage,
} from 'lib/utils';
import { translate } from 'lib/intl';
import actionTypes from 'rdx/actionTypes';
import { TOAST_FEEDBACK_IDS } from 'lib/constants';
import { companyTeamMembersService } from 'services/company-team-members';

const {
  COMPANY_TEAM_MEMBERS_REQUEST,
  COMPANY_TEAM_MEMBERS_SUCCESS,
  COMPANY_TEAM_MEMBERS_FAILURE,
  UPDATE_COMPANY_TEAM_MEMBERS_REQUEST,
  UPDATE_COMPANY_TEAM_MEMBERS_SUCCESS,
  UPDATE_COMPANY_TEAM_MEMBERS_FAILURE,
  REMOVE_COMPANY_TEAM_MEMBERS_REQUEST,
  REMOVE_COMPANY_TEAM_MEMBERS_SUCCESS,
  REMOVE_COMPANY_TEAM_MEMBERS_FAILURE,
} = actionTypes;

// eslint-disable-next-line no-confusing-arrow
const mapUserGroups = (groupName) =>
  groupName ? [{ groupDisplayName: groupName }] : [];

const fetchCompanyTeamMembers = (ownerOrgPubId) => async (dispatch) => {
  try {
    dispatch(createAction({ type: COMPANY_TEAM_MEMBERS_REQUEST }));

    const response = await companyTeamMembersService.fetchCompanyTeamMembers(
      ownerOrgPubId
    );

    const users = camelCaseKeys(response?.data?.users)?.map((userData) => ({
      groups: mapUserGroups(userData?.group),
      ...userData,
    }));

    dispatch(
      createAction({
        type: COMPANY_TEAM_MEMBERS_SUCCESS,
        data: { users },
      })
    );
  } catch (error) {
    showErrorMessage({
      message: translate('COMPANY_FETCH_TEAM_MEMBERS_ERROR_MESSAGE'),
    });

    dispatch(
      createAction({
        type: COMPANY_TEAM_MEMBERS_FAILURE,
        error,
      })
    );
  }
};

const updateCompanyTeamMembers = (ownerOrgPubId, data) => async (dispatch) => {
  try {
    dispatch(
      createAction({
        type: UPDATE_COMPANY_TEAM_MEMBERS_REQUEST,
        data,
      })
    );

    const response = await companyTeamMembersService.updateCompanyTeamMembers(
      ownerOrgPubId,
      data
    );

    const userData = camelCaseKeys(response?.data?.user);
    const groups = mapUserGroups(userData?.group);

    dispatch(
      createAction({
        type: UPDATE_COMPANY_TEAM_MEMBERS_SUCCESS,
        data: {
          groups,
          ...userData,
        },
      })
    );

    dispatch(fetchCompanyTeamMembers(ownerOrgPubId));
  } catch (error) {
    showErrorMessage({
      message: translate('COMPANY_UPDATE_MEMBER_ERROR_MESSAGE', {
        name: getFullName(data),
      }),
      containerId: TOAST_FEEDBACK_IDS.TEAM_MEMBERS,
    });

    dispatch(
      createAction({
        type: UPDATE_COMPANY_TEAM_MEMBERS_FAILURE,
        data,
        error,
      })
    );
  }
};

const removeCompanyTeamMembers = (ownerOrgPubId, data) => async (dispatch) => {
  try {
    dispatch(
      createAction({
        type: REMOVE_COMPANY_TEAM_MEMBERS_REQUEST,
        data,
      })
    );

    const response = await companyTeamMembersService.removeCompanyTeamMembers(
      ownerOrgPubId,
      data
    );

    dispatch(
      createAction({
        type: REMOVE_COMPANY_TEAM_MEMBERS_SUCCESS,
        data: camelCaseKeys(response?.data?.user),
      })
    );

    dispatch(fetchCompanyTeamMembers(ownerOrgPubId));
  } catch (error) {
    showErrorMessage({
      message: translate('COMPANY_REMOVE_MEMBER_ERROR_MESSAGE', {
        name: getFullName(data),
      }),
      containerId: TOAST_FEEDBACK_IDS.TEAM_MEMBERS,
    });

    dispatch(
      createAction({
        type: REMOVE_COMPANY_TEAM_MEMBERS_FAILURE,
        data,
        error,
      })
    );
  }
};

export const companyTeamMembersActions = {
  fetchCompanyTeamMembers,
  updateCompanyTeamMembers,
  removeCompanyTeamMembers,
};
