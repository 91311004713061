import React, { useCallback, useState } from 'react';
import { bool, func } from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
} from '@material-ui/core';
import styled from 'styled-components';
import {
  AnimatedUnderline,
  CustomButton,
  CustomLink,
  EmailsInput,
  Gap,
  Loader,
  HeadingWithIcon,
  InviteSelector,
  Visible,
} from 'components';
import { colors } from 'styles/theme';
import { CloseIcon, inviteMessageIcon } from 'assets/images';
import userActionTypes from 'rdx/actionTypes';
import { EMPTY_FUNC } from 'lib/constants';
import { extractEmails, GroupType, isMobileView } from 'lib/utils';
import { translate } from 'lib/intl';

const { INVITE_FORM_UPDATE_GROUPS, INVITE_FORM_UPDATE_EMAILS } =
  userActionTypes;

const StyledDialog = styled(Dialog)`
  .MuiDialogContent-root {
    display: flex;
    flex-direction: column;
  }

  ${({ mobile }) =>
    mobile
      ? `
    .MuiDialog-paperFullWidth {
      border-radius: 8px;
      border: 1px solid ${colors.lightGrey};
      height: 100%;
      margin: 8px;
      max-height: calc(100vh - 16px);
      width: 100%;
    }
    `
      : ''}
`;

const Header = styled(Box)`
  h5 {
    font-size: 20px;
  }
`;

const StyledBottomContent = styled(Box)`
  height: 104px;
`;

const StyledCloseButton = styled(Box)`
  cursor: pointer;
`;

const StyledChipsContainer = styled(Box)`
  display: flex;
  flex: 1;
  max-height: 100%;
  overflow-y: scroll;

  & > div {
    flex: 1;
  }
`;

const SendInvitesModal = ({
  groups,
  handleClose,
  isLoading,
  open,
  sendInvites,
}) => {
  const [inputValue, setInputValue] = useState('');
  const [isEmailsInputActive, setChipInputActive] = useState(true);

  const isMobile = isMobileView();

  const dispatch = useDispatch();

  const { selectedGroups, emails, error } = useSelector((state) => ({
    selectedGroups: state.inviteForm.groups?.length
      ? state.inviteForm?.groups
      : [groups[0]],
    emails: state.inviteForm.emails,
    error: state.inviteForm.error,
  }));

  const updateGroups = (newGroups) =>
    dispatch({
      type: INVITE_FORM_UPDATE_GROUPS,
      groups: newGroups,
    });

  const updateEmails = (newEmails) =>
    dispatch({
      type: INVITE_FORM_UPDATE_EMAILS,
      emails: newEmails,
    });

  const onSendInvites = useCallback(() => {
    const updatedEmails = [...emails, ...extractEmails(inputValue)];

    if (inputValue) {
      updateEmails(updatedEmails);
      setInputValue('');
    }

    sendInvites({
      emails: updatedEmails,
      groups: selectedGroups,
    });
  }, [emails, inputValue, selectedGroups]);

  const onModalClose = useCallback((_e, reason) => {
    if (reason !== 'backdropClick') {
      updateEmails([]);
    }

    handleClose();
  }, []);

  return (
    <StyledDialog
      aria-labelledby="edit-dialog"
      fullWidth
      maxWidth="md"
      mobile={Number(isMobile)}
      onClose={onModalClose}
      open={open}
    >
      <Header
        alignItems="center"
        display="flex"
        justifyContent="space-between"
        pr={3}
      >
        <DialogTitle>
          <HeadingWithIcon
            iconSrc={inviteMessageIcon}
            title={translate('COOMPANY_PAGE_INVITE_TEAM_LABEL')}
          />
        </DialogTitle>
        <StyledCloseButton onClick={onModalClose}>
          <CloseIcon fill={colors.black} />
        </StyledCloseButton>
      </Header>
      <DialogContent p={0} px={3}>
        <Typography>
          {translate('COMPANY_SEND_INVITES_MODAL_DESCRIPTION')}
        </Typography>
        <Box
          alignItems="center"
          display="flex"
          justifyContent="space-between"
          mt={3}
        >
          <InviteSelector
            handleChange={updateGroups}
            options={groups}
            selectedOptions={selectedGroups}
            showBorder
          />
          <Visible when={Boolean(emails?.length)}>
            <CustomLink onClick={() => updateEmails([])}>
              {translate('CLEAR_ALL_EMAILS')}
            </CustomLink>
          </Visible>
        </Box>
        <StyledChipsContainer mobile={Number(isMobile)} mt={2}>
          <EmailsInput
            inputValue={inputValue}
            onInputValueChange={setInputValue}
            values={emails}
            onValuesChange={updateEmails}
            onFocusChange={setChipInputActive}
          />
        </StyledChipsContainer>
        <AnimatedUnderline
          active={isEmailsInputActive || !!error}
          error={!!error}
        />
      </DialogContent>
      <StyledBottomContent
        display="flex"
        alignItems="center"
        justifyContent={isLoading ? 'center' : 'flex-start'}
        px={2}
        py={3}
      >
        <Visible fallback={<Loader />} when={!isLoading}>
          <Visible when={Boolean(error)}>
            <Box px={1}>
              <Typography color="error">{error}</Typography>
            </Box>
          </Visible>
          <Box
            display="flex"
            flex="1"
            flexDirection="row"
            justifyContent="flex-end"
          >
            <CustomButton
              buttonType="link"
              color="primary"
              dataTestId="send-invites-cancel-button"
              label={translate('CANCEL')}
              onClick={handleClose}
            />
            <Gap />
            <CustomButton
              buttonType="primary"
              dataTestId="send-invites-button"
              label={translate('SEND_INVITE')}
              onClick={onSendInvites}
            />
          </Box>
        </Visible>
      </StyledBottomContent>
    </StyledDialog>
  );
};

SendInvitesModal.propTypes = {
  groups: GroupType.isRequired,
  isLoading: bool,
  sendInvites: func.isRequired,
  open: bool,
  handleClose: func,
};

SendInvitesModal.defaultProps = {
  open: false,
  handleClose: EMPTY_FUNC,
  isLoading: false,
};

export { SendInvitesModal };
