export const NAVIGATION = {
  HELP_LINK: 'Help',
  PRIVACY_LINK: 'Privacy',
  TERMS_LINK: 'Terms',
  EDIT_PROFILE: 'Edit profile',
  BACK_TO_SIGN_IN: 'Back to sign in',
  HOME_NAV_LABEL: 'Home',
  DONORS_NAV_LABEL: 'Donors',
  CAMPAIGNS_NAV_LABEL: 'Campaigns',
  REPORTS_NAV_LABEL: 'Reports',
  INVITES_NAV_LABEL: 'Invite your team',
  CONTACTS_NAV_LABEL: 'Contacts',
  INTEGRATIONS_NAV_LABEL: 'Apps & Extensions',
  CONSTITUENTS_NAV_LABEL: 'Constituents',
};
