import { useCallback, useEffect, useState } from 'react';
import { campaignsActions, companyTeamMembersActions } from 'rdx/actions';
import { useDispatch, useSelector } from 'react-redux';
import { keys } from 'rambdax';
import { formatTeamMembers } from 'lib/utils/formatTeamMembers';
import { validateCampaignFormData } from 'lib/utils/validateCampaignFormData';
import { formatCampaignBeforeSave } from 'lib/utils/formatCampaignBeforeSave';
import { INIT_CAMPAIGN_PROPS } from 'lib/configs/campaigns';

const useCampaignForm = () => {
  const dispatch = useDispatch();
  const [isCampaignModalOpen, setCampaignModalOpen] = useState(false);
  const [modalData, setModalData] = useState(INIT_CAMPAIGN_PROPS);
  const [modalErrors, setModalErrors] = useState({});

  const {
    compaignApiError,
    isCampaignSaving,
    isCampaignSaved,
    ownerOrgId,
    teamMembers,
    areTeamMembersLoaded,
    isFetchingTeamMembers,
  } = useSelector((state) => ({
    compaignApiError: state.updateCampaign.error,
    isCampaignSaving: state.updateCampaign.isFetching,
    isCampaignSaved: state.updateCampaign.dataLoaded,
    ownerOrgId: state.authentication?.ownerOrgId,
    teamMembers: formatTeamMembers(state.companyTeamMembers.data?.users),
    areTeamMembersLoaded: state.companyTeamMembers.dataLoaded,
    isFetchingTeamMembers: state.companyTeamMembers.isFetching,
  }));

  useEffect(() => {
    if (!areTeamMembersLoaded && !isFetchingTeamMembers) {
      dispatch(companyTeamMembersActions.fetchCompanyTeamMembers(ownerOrgId));
    }
  }, [areTeamMembersLoaded, isFetchingTeamMembers]);

  const openCampaignModal = (data) => {
    if (data) {
      setModalData(data);
    }
    setCampaignModalOpen(true);
  };

  const closeCampaignModal = () => {
    setCampaignModalOpen(false);
    // resets modal data
    dispatch(campaignsActions.resetUpdatingCampaign(modalData.campaignId));
    setModalData(INIT_CAMPAIGN_PROPS);
    setModalErrors({});
  };

  const saveCampaign = useCallback(() => {
    const errors = validateCampaignFormData(modalData);

    if (keys(errors).length) {
      setModalErrors(errors);
    } else {
      const campaign = formatCampaignBeforeSave(modalData, ownerOrgId);

      dispatch(
        campaignsActions.updateCampaign({
          data: campaign,
          campaignId: modalData?.campaignId,
        })
      );
    }
  }, [modalData, ownerOrgId]);

  // resets modal state after successful saving
  useEffect(() => {
    if (isCampaignSaved) {
      closeCampaignModal();
      dispatch(campaignsActions.fetchCampaigns(ownerOrgId));
    }
  }, [isCampaignSaved]);

  return {
    // modal state related
    isCampaignModalOpen,
    openCampaignModal,
    closeCampaignModal,
    modalData,
    setModalData,
    modalErrors,
    setModalErrors,

    // api related
    compaignApiError,
    isCampaignSaving,
    saveCampaign,
    teamMembers,
  };
};

export { useCampaignForm };
