import { createAction } from 'lib/utils';
import { usersService } from 'services/users';
import userActionTypes from 'rdx/actionTypes';

const { PUBLIC_USERS_REQUEST, PUBLIC_USERS_SUCCESS, PUBLIC_USERS_FAILURE } =
  userActionTypes;

const fetchPublicUser = (ownerOrgId, userId) => async (dispatch) => {
  try {
    dispatch(createAction({ type: PUBLIC_USERS_REQUEST }));

    const response = await usersService.fetchUsers(ownerOrgId, userId);

    dispatch(
      createAction({
        type: PUBLIC_USERS_SUCCESS,
        data: response?.data || {},
      })
    );
  } catch (err) {
    dispatch(
      createAction({
        type: PUBLIC_USERS_FAILURE,
        err,
      })
    );
  }
};

export const publicUsersActions = {
  fetchPublicUser,
};
