import React from 'react';
import PropTypes from 'prop-types';
import { Box, makeStyles } from '@material-ui/core';
import { colors } from 'styles/theme';

const useStyles = makeStyles((theme) => ({
  container: {
    border: `1px solid ${colors.lightGrey}`,
    borderRadius: theme.shape.borderRadius,
    flex: '0 0 auto',
    marginTop: '12px',
    padding: '10px',
    [theme.breakpoints.down('sm')]: {
      width: '302px',
      marginRight: theme.spacing(2),
    },
  },
}));

const ListItemContainer = ({ children, ...props }) => {
  const classes = useStyles();

  return (
    <Box
      data-testid="list-item-container"
      className={classes.container}
      {...props}
    >
      {children}
    </Box>
  );
};

ListItemContainer.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

ListItemContainer.defaultProps = {
  children: null,
};

export { ListItemContainer };
