import userActionTypes from 'rdx/actionTypes';
import { userService } from 'services/user';

function logout() {
  return { type: userActionTypes.LOGOUT };
}

function register(user) {
  function request(requestUser) {
    return { type: userActionTypes.REGISTER_REQUEST, requestUser };
  }
  function success(successUser) {
    return { type: userActionTypes.REGISTER_SUCCESS, successUser };
  }
  function failure(error) {
    return { type: userActionTypes.REGISTER_FAILURE, error };
  }

  return (dispatch) => {
    dispatch(request(user));

    userService.register(user).then(
      () => {
        dispatch(success());
      },
      (error) => {
        const { code, message } = error;

        // We do not want to fail the registration flow on the DotOrg Custom request at this time
        // Detect a failure on the instil request and pass the user through to be handled later
        // This may change in the future
        if (error && error.error_enum) {
          dispatch(success());
        } else {
          dispatch(failure({ code, message }));
        }
      }
    );
  };
}

function resetRegistrationState() {
  function resetAll() {
    return { type: userActionTypes.REGISTER_RESET_ALL };
  }

  return (dispatch) => dispatch(resetAll());
}

function resetPassword(username) {
  function request(user) {
    return { type: userActionTypes.RESET_PASSWORD_REQUEST, user };
  }
  function success() {
    return { type: userActionTypes.RESET_PASSWORD_SUCCESS };
  }
  function failure(error) {
    return { type: userActionTypes.RESET_PASSWORD_FAILURE, error };
  }

  return (dispatch) => {
    dispatch(request(username));

    userService.resetPassword(username).then(
      () => {
        dispatch(success());
      },
      (error) => {
        const { message } = error;

        dispatch(failure({ message }));
      }
    );
  };
}

function resetPasswordSubmit(username, code, password) {
  function request(user) {
    return { type: userActionTypes.RESET_PASSWORD_SUBMIT_REQUEST, user };
  }
  function success() {
    return { type: userActionTypes.RESET_PASSWORD_SUBMIT_SUCCESS };
  }
  function failure(error) {
    return { type: userActionTypes.RESET_PASSWORD_SUBMIT_FAILURE, error };
  }

  return (dispatch) => {
    dispatch(request(username));

    userService.resetPasswordSubmit(username, code, password).then(
      () => {
        dispatch(success());
      },
      (error) => {
        const { message } = error;

        dispatch(failure({ message }));
      }
    );
  };
}

function resetPasswordResetState() {
  function resetAll() {
    return { type: userActionTypes.RESET_PASSWORD_RESET };
  }

  return (dispatch) => dispatch(resetAll());
}

function resetPasswordResetSubmitState() {
  function resetAll() {
    return { type: userActionTypes.RESET_PASSWORD_SUBMIT_RESET };
  }

  return (dispatch) => dispatch(resetAll());
}

export const userActions = {
  logout,
  register,
  resetRegistrationState,
  resetPassword,
  resetPasswordResetState,
  resetPasswordSubmit,
  resetPasswordResetSubmitState,
};
