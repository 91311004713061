export const BUTTONS = {
  ADD: 'Add',
  CANCEL: 'Cancel',
  SAVE: 'Save',
  REMOVE: 'Remove',
  DEACTIVATE: 'Deactivate',
  RESEND: 'Resend',
  SEND_INVITE: 'Send Invite',
  GOT_IT: 'Got it',
  EDIT: 'Edit',
  DELETE: 'Delete',
  ADD_ADDRESS: 'Add Address',
  ADD_BIRTHDAY: 'Add Birthday',
  ADD_JOB_TITLE: 'Add Job Title',
  ADD_EMAIL: 'Add Email',
  ADD_PHONE: 'Add Phone',
  ADD_WEBSITE: 'Add Website',
  SORT: 'Sort',
  ADD_DONOR: 'Add Donor',
  ADD_CONSTITUENT: 'Add Constituent',
  ADD_DONATION: 'Add Donation',
  ADD_INTERACTION: 'Add Interaction',
  MULTI_ACTION_CLOSE: 'Close',
  BUTTON_FILTER: 'Filter',
  CREATE: 'Create',
  DISABLE: 'Disable',
  PUBLISH: 'Publish',
  SEND_EMAIL: 'Send Email',
  COPY_ADDRESS: 'Copy Address',
  CALL: 'Call',
  COPY_NUMBER: 'Copy Number',
};
