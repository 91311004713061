import createFetchFeedAction from './createFetchFeedAction';

export const types = {
  request: 'CONSTITUENT_FEED_REQUEST',
  success: 'CONSTITUENT_FEED_SUCCESS',
  failure: 'CONSTITUENT_FEED_FAILURE',
};

export const fetch = createFetchFeedAction(types);

export default {
  fetch,
  types,
};
