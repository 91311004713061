/* global d3 */
/* eslint no-shadow: 0, no-underscore-dangle: 0 */
import { formatDateForReport } from 'reports/donationReportUtils';
import { formatCurrencyAbbrev } from 'lib/utils';
import Chart from './Chart';
import {
  BAR_RX,
  BAR_WIDTH,
  BAR_WIDTH_MOBILE,
  MARGIN_LEFT,
  MARGIN_TOP,
  Y_TICK_PADDING,
  X_AXIS_GROUP_HEIGHT,
  Y_AXIS_GROUP_Y,
  Y_AXIS_GROUP_MARGIN_BOTTOM,
  Y_AXIS_GROUP_MARGIN_BOTTOM_LARGE,
  Y_GRID_GROUP_Y,
} from './barChartConstants';

export default class DonationsOverTimeReport extends Chart {
  constructor(options) {
    super(options);

    this.onBarMouseover = options.onBarMouseover;
    this.onBarMouseleave = options.onBarMouseleave;
    this.yAxisGroupMarginBottom = Y_AXIS_GROUP_MARGIN_BOTTOM;
    this.xAxisGroup = null;
    this.yAxisGroup = null;
    this.yGridGroup = null;
    this.barGroup = null;
    this.barWidth = 0;
    this.hasRotatedXAxis = false;

    // TODO: add es6 class arrow methods so we can code like its 2021.
    this.measureAndRotateXAxis = this.measureAndRotateXAxis.bind(this);
  }

  destroy() {
    super.destroy();
    this.xAxisGroup = null;
    this.yAxisGroup = null;
    this.yGridGroup = null;
    this.barGroup = null;
  }

  setXScaleAndRange() {
    this.xScale = d3
      .scaleBand()
      .rangeRound([0, this.width - MARGIN_LEFT], 0.5)
      .padding(0.1)
      .domain(this.data.map((d) => d.timeUnitStart));

    this.xAxis = d3
      .axisBottom()
      .scale(this.xScale)
      .tickFormat(formatDateForReport(this.timePeriod));
  }

  setYScaleAndRange() {
    this.yScale = d3
      .scaleLinear()
      .range([this.height - this.yAxisGroupMarginBottom - MARGIN_TOP, 0])
      .domain([0, d3.max(this.data, (d) => d.totalValue)])
      .nice();

    this.yAxis = d3
      .axisLeft()
      .scale(this.yScale)
      .tickSize(0)
      .tickPadding(Y_TICK_PADDING)
      .ticks(this.data.length)
      .tickFormat((d) => formatCurrencyAbbrev(d));

    this.yGridAxis = d3
      .axisLeft()
      .scale(this.yScale)
      .tickFormat('')
      .ticks(this.data.length)
      .tickSizeOuter(0)
      .tickSizeInner(-this.width + MARGIN_LEFT);
  }

  addXGroup() {
    this.xAxisGroup = this.svg
      .append('g')
      .attr('id', `${this.id}-x-axis`)
      .attr('class', 'x-axis')
      .attr(
        'transform',
        `translate(${MARGIN_LEFT}, ${
          this.height - X_AXIS_GROUP_HEIGHT + Y_TICK_PADDING
        })`
      )
      .call(this.xAxis.ticks(null).tickSize(0));
  }

  addYGroups() {
    this.setYAxisGroupMarginBottom();

    this.yAxisGroup = this.svg
      .append('g')
      .attr('id', `${this.id}-y-axis`)
      .attr('class', 'y-axis')
      .attr('transform', `translate(${MARGIN_LEFT}, ${Y_AXIS_GROUP_Y})`)
      .call(this.yAxis)
      .call(Chart.removeDomain);

    this.yGridGroup = this.svg
      .append('g')
      .attr('id', `${this.id}-y-grid`)
      .attr('class', 'y-grid')
      .attr('transform', `translate(${MARGIN_LEFT}, ${Y_GRID_GROUP_Y})`);
  }

  measureAndRotateXAxis(selection) {
    const xAxisTextWidth = Chart.measureXAxisWidth(selection);

    const prevHasRotatedXAxis = this.hasRotatedXAxis;
    const hasRotatedXAxis =
      xAxisTextWidth + 6 * this.data.length > this.width - MARGIN_LEFT;

    if (prevHasRotatedXAxis !== hasRotatedXAxis) {
      this.hasRotatedXAxis = hasRotatedXAxis;

      this.setYAxisGroupMarginBottom();

      // When initialing rendering the chart, this can be called before the bar
      // group has been added to the DOM.
      if (this.barGroup) {
        this.updateBarAttributes();
      }

      Chart.rotateXAxisText(selection, this.hasRotatedXAxis);
    }
  }

  drawXAxis() {
    this.xAxisGroup
      .transition() // animate the x-axis for window resizing
      .duration(this.transitionDuration)
      .call(this.xAxis.ticks(null).tickSize(0));

    // Every time we draw the x-axis, it adds a <domain> by default. This
    // removes it to better match the desired layout. Calling it here means it
    // gets removed right away vs waiting until the transition is done.
    this.xAxisGroup
      .call(Chart.removeDomain)
      .call(Chart.setSmallFontSize)
      .selectAll('text')
      .call(this.measureAndRotateXAxis);
  }

  drawYAxis() {
    this.yAxisGroup
      .call(this.yAxis)
      .call(Chart.removeDomain)
      .call(Chart.setSmallFontSize);

    this.yGridGroup
      .transition() // animate the y-grid for window resizing
      .duration(this.transitionDuration)
      .call(this.yGridAxis);

    // Update the layout of the grid to match the designs
    this.yGridGroup
      .call(Chart.removeDomain)
      .selectAll('line')
      .attr('class', 'stroke-grey-300');
  }

  calculateBarYOffScreenPosition() {
    return this.height - this.yAxisGroupMarginBottom;
  }

  calculateBarYPosition(d) {
    return MARGIN_TOP + this.yScale(d.totalValue);
  }

  calculateBarXPosition(d) {
    return (
      MARGIN_LEFT +
      this.xScale.bandwidth() / 2 -
      this.barWidth / 2 +
      this.xScale(d.timeUnitStart)
    );
  }

  calculateBarHeight(d) {
    return (
      this.height -
      this.yScale(d.totalValue) -
      MARGIN_TOP -
      this.yAxisGroupMarginBottom
    );
  }

  updateBars() {
    const transition = this.svg.transition().duration(this.transitionDuration);

    this.barGroup
      .selectAll('rect.bar')
      .data(this.data, (d) => d.timeUnitStart)
      .join(
        (enter) =>
          enter
            .append('rect')
            .attr('class', 'bar fill-secondaryButton-dark')
            .attr('id', (d, i) => `${this.id}-${i}-bar`)
            .attr('x', (d) => this.calculateBarXPosition(d))
            .attr('y', () => this.calculateBarYOffScreenPosition())
            .attr('rx', BAR_RX)
            .attr('width', this.barWidth)
            .attr('height', 0)
            .call((enter) =>
              enter
                .transition(transition)
                .attr('height', (d) => this.calculateBarHeight(d))
                .attr('y', (d) => this.calculateBarYPosition(d))
            ),
        (update) =>
          update
            .attr('y', (d, i, group) => group[i].getAttribute('y'))
            .attr('x', (d, i, group) => group[i].getAttribute('x'))
            .attr('width', (d, i, group) => group[i].getAttribute('width'))
            .attr('height', (d, i, group) => group[i].getAttribute('height'))
            .call((update) =>
              update
                .transition(transition)
                .attr('width', this.barWidth)
                .attr('height', (d) => this.calculateBarHeight(d))
                .attr('x', (d) => this.calculateBarXPosition(d))
                .attr('y', (d) => this.calculateBarYPosition(d))
            ),
        (exit) =>
          exit.call((exit) =>
            exit
              .transition(transition)
              .attr('y', () => this.calculateBarYOffScreenPosition())
              .attr('height', 0)
              .remove()
          )
      );
  }

  drawBarMouseoverTargets() {
    // In order for the tooltips to show up where we want them do, we create
    // transparent rectangle that is placed at the top of bar <rect>. We can't
    // use the bar <rect> because the tooltip will show up in the middle of the
    // element instead of towards the top. Normally we would adjust the offset
    // of the tooltip however that messes with the position of the arrow. This
    // is the compromise we make to deal with that.
    this.barGroup
      .selectAll('rect.bar-tooltip-anchor')
      .data(this.data, (d) => d.timeUnitStart)
      .join('rect')
      .attr('class', 'bar-tooltip-anchor pointer-events-none opacity-0')
      .attr('data-anchor', (d, i) => `${this.id}-${i}-bar`)
      .attr('width', this.barWidth)
      .attr('height', this.barWidth)
      .attr('x', (d) => this.calculateBarXPosition(d))
      .attr('y', (d) => this.calculateBarYPosition(d));
  }

  setBarAttributes(isMobile) {
    this.barWidth = isMobile ? BAR_WIDTH_MOBILE : BAR_WIDTH;
  }

  updateBarAttributes() {
    this.barGroup
      .selectAll('rect.bar')
      .attr('rx', BAR_RX)
      .attr('width', this.barWidth)
      .attr('x', (d) => this.calculateBarXPosition(d))
      .attr('y', (d) => this.calculateBarYPosition(d));
  }

  setYAxisGroupMarginBottom() {
    this.yAxisGroupMarginBottom = this.hasRotatedXAxis
      ? Y_AXIS_GROUP_MARGIN_BOTTOM_LARGE
      : Y_AXIS_GROUP_MARGIN_BOTTOM;
  }

  resize() {
    this.setXScaleAndRange();
    this.drawXAxis();
    this.setYScaleAndRange();
    this.drawYAxis();
    this.updateBars();
    this.drawBarMouseoverTargets();
  }

  mediaQueryListener({ matches }) {
    this.setBarAttributes(matches);
    this.updateBarAttributes();
    this.drawBarMouseoverTargets();
  }

  create(data) {
    super.create(data);

    // We have to completely draw the X-Axis before we draw the Y-Axis.
    // The X-Axis can potentially end up with rotated text to better
    // accommodate smaller screen sizes. When that happens, the
    // `yAxisGroupMarginBottom` can be larger, affecting the layout of the
    // Y-Axis and bars. Failure to maintain the order set out here will result
    // in layout bugs and hurt feelings.
    this.setXScaleAndRange();
    this.addXGroup();
    this.drawXAxis();

    this.setYScaleAndRange();
    this.addYGroups();
    this.drawYAxis();

    this.barGroup = this.svg.append('g').attr('id', `${this.id}-bars`);

    if (this.debug) {
      Chart.addDebugRectToGroup(this.xAxisGroup);
      Chart.addDebugRectToGroup(this.yAxisGroup, true);
      Chart.addDebugRectToGroup(this.yGridGroup);
      Chart.addDebugRectToGroup(this.barGroup);
    }

    this.setBarAttributes(this.mediaQueryList.matches);
    this.updateBars();
    this.drawBarMouseoverTargets();

    this.barGroup
      .selectAll('rect.bar')
      .on('mouseover', (event, d) => {
        this.onBarMouseover(event, d);
      })
      .on('mouseleave', (event, d) => {
        this.onBarMouseleave(event, d);
      });
  }

  update(data) {
    this.data = data;
    this.drawXAxis();
    this.drawYAxis();
    this.updateBars();
  }
}
