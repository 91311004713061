import React from 'react';
import propTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/styles/makeStyles';
import { Avatar } from 'components';
import { useHistory, generatePath } from 'react-router-dom';
import { translate } from 'lib/intl';
import { getInitials } from 'lib/utils';
import { isPersonConstituentType } from 'lib/utils/constituentUtils';
import { isMobileView } from 'lib/utils/isMobileView';
import { DonorType } from 'lib/utils/custom-prop-types';
import { AUTH_ROUTES } from 'lib/constants/routes';
import { getRecentlyViewedConstituents } from 'lib/utils/recentlyViewedConstituentsUtils';

const useStyles = makeStyles((theme) => ({
  noDonorsLabel: {
    color: theme.palette.grey.dark,
  },
  donorCard: {
    border: `1px solid ${theme.palette.grey.light}`,
    borderRadius: theme.shape.borderRadius[0],
    display: 'flex',
    cursor: 'pointer',
    marginBottom: theme.spacing(1),
    marginRight: theme.spacing(2),
    padding: `${theme.spacing(1.5)}px ${theme.spacing(2)}px`,
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      flex: '0 0 302px',
    },
    '&:last-child': {
      marginRight: 0,
    },
  },
  donorName: {
    fontWeight: 500,
    lineHeight: 1.5,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}));

const RecentlyViewedDonors = () => {
  const classes = useStyles();
  const history = useHistory();
  const isMobile = isMobileView();
  const items = getRecentlyViewedConstituents();

  if (!items?.length) {
    return (
      <Box
        display="flex"
        flex="1"
        justifyContent={isMobile ? 'center' : 'flex-start'}
      >
        <Typography className={classes.noDonorsLabel} variant="caption">
          {translate('CONSTITUENTS_RECENTLY_VIEWED_EMPTY')}
        </Typography>
      </Box>
    );
  }

  const handleClick = (user) => {
    const path = generatePath(AUTH_ROUTES.CONSTITUENT, {
      constituentId: user.constituentId,
    });

    history.push(path);
  };

  return items.map((item) => {
    const id = item.constituentId;
    const key = `recently-viewed-donor-constituent-${id}`;
    const isUser = isPersonConstituentType(item);

    return (
      <Box
        key={key}
        className={classes.donorCard}
        onClick={() => handleClick(item)}
      >
        <Avatar src={item?.avatar}>
          {getInitials(item?.displayName, isUser)}
        </Avatar>
        <Box alignItems="center" display="flex" overflow="hidden" pl={1.5}>
          <Typography className={classes.donorName} variant="subtitle1">
            {item?.displayName}
          </Typography>
        </Box>
      </Box>
    );
  });
};

RecentlyViewedDonors.propTypes = {
  donors: propTypes.arrayOf(DonorType),
  isFetching: propTypes.bool,
};

RecentlyViewedDonors.defaultProps = {
  donors: [],
  isFetching: false,
};

export { RecentlyViewedDonors };
