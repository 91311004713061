import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { upperCase } from 'voca';
import Grid from '@material-ui/core/Grid';
import { translate } from 'lib/intl';
import { formatCurrency, formatDate, formatNumber } from 'lib/utils';
import { CUSTOM_DATE_FORMAT, DONATION_TYPE_ENUMS } from 'lib/constants/common';
import {
  DonationDetailsType,
  DONATION_TYPES,
  DONATION_PAYMENT_METHODS,
  DONATION_RECURRING_CADENCES,
  DONATION_TIME_TYPE_CONFIG,
  DONATION_VALUE_OF_TIME_CONFIG,
  DONATION_VOLUNTEER_CONFIG,
} from 'lib/configs/donations';
import FeedSliderDonationFooter from './FeedSliderDonationFooter';
import { FeedSliderContentDetail } from './FeedSliderContentDetail';
import { FeedSliderContentAssignees } from './FeedSliderContentAssignees';

const FeedSliderDonationContent = (props) => {
  const {
    actualValue,
    campaignDisplayName,
    donationDateString,
    donationPlan,
    donationTypeEnum,
    developers,
    estimatedValue,
    grantData,
    inKind,
    notes,
    paymentTypeEnum,
    pledgeData,
    restrictions,
    timeData,
  } = props;
  const { ownerOrgId } = useSelector((state) => ({
    ownerOrgId: state.authentication?.ownerOrgId,
  }));

  // common props
  const type = DONATION_TYPES[upperCase(donationTypeEnum)];
  const paymentType = DONATION_PAYMENT_METHODS.find(
    ({ value }) => value === paymentTypeEnum
  );
  const note = notes.length ? notes[0].text : '';
  // time donation related props
  const timeValueProps = DONATION_VALUE_OF_TIME_CONFIG.find(
    ({ value }) => value === timeData?.timeValue
  );
  const timeCategoryProps = DONATION_VOLUNTEER_CONFIG.find(
    ({ value }) => value === timeData?.timeCategory
  );
  const timeUnitProps = DONATION_TIME_TYPE_CONFIG.find(
    ({ value }) => value === timeData?.timeUnit
  );
  // in kind donation related props
  const inKindData = inKind?.length && inKind[0];
  // restrictions donations related props
  const restrictionArea =
    restrictions?.length && restrictions[0]?.restrictionDescription;
  // recurring donations related props
  const recurringCadenceProps = DONATION_RECURRING_CADENCES.find(
    ({ value }) => value === donationPlan?.recurringCadenceEnum
  );
  const recurringFormattedDate = formatDate(
    donationPlan?.recurringStartDateString,
    CUSTOM_DATE_FORMAT
  );

  const PaymentMethod = (
    <FeedSliderContentDetail
      iconAlt={paymentType?.iconAlt}
      iconSrc={paymentType?.icon}
      title={translate('DONATION_PAYMENT_LABEL')}
      value={paymentType?.text}
    />
  );

  const DetailsGridItem = ({ width, children }) => (
    <Grid style={{ midWidth: '140px' }} item xs={width}>
      {children}
    </Grid>
  );
  DetailsGridItem.propTypes = {
    children: PropTypes.node,
    width: PropTypes.number,
  };
  DetailsGridItem.defaultProps = {
    children: null,
    width: 6,
  };

  const TypeRelatedDetails = () => {
    switch (donationTypeEnum) {
      case DONATION_TYPE_ENUMS.GENERAL:
      case DONATION_TYPE_ENUMS.MAJOR_GIFT:
        return (
          <Grid container wrap="wrap">
            <DetailsGridItem>{PaymentMethod}</DetailsGridItem>
            <DetailsGridItem>
              <FeedSliderContentDetail
                title={translate('DONATION_AMOUNT_LABEL')}
                value={formatCurrency(actualValue, true)}
              />
            </DetailsGridItem>
          </Grid>
        );
      case DONATION_TYPE_ENUMS.PLEDGE:
        return (
          <Grid container wrap="wrap">
            <DetailsGridItem>{PaymentMethod}</DetailsGridItem>
            <DetailsGridItem>
              <FeedSliderContentDetail
                title={translate('PLEDGE_AMOUNT_LABEL')}
                value={formatCurrency(estimatedValue, true)}
              />
            </DetailsGridItem>
            <DetailsGridItem>
              <FeedSliderContentDetail
                title={translate('PLEDGE_DUE_DATE_LABEL')}
                value={formatDate(
                  pledgeData.pledgeDueDateString,
                  CUSTOM_DATE_FORMAT
                )}
              />
            </DetailsGridItem>
          </Grid>
        );
      case DONATION_TYPE_ENUMS.IN_KIND:
        return (
          <Grid container wrap="wrap">
            <DetailsGridItem>
              <FeedSliderContentDetail
                title={translate('IN_KIND_ITEM_DONATED_LABEL')}
                value={inKindData?.inKindItemDescription}
              />
            </DetailsGridItem>
            <DetailsGridItem>
              <FeedSliderContentDetail
                title={translate('IN_KIND_ITEM_VALUE_LABEL')}
                value={formatCurrency(inKindData.inKindTotalValue, true)}
              />
            </DetailsGridItem>
            <DetailsGridItem>
              <FeedSliderContentDetail
                title={translate('IN_KIND_ITEM_QUANTITY_LABEL')}
                value={formatNumber(inKindData.inKindItemQuantity)}
              />
            </DetailsGridItem>
          </Grid>
        );
      case DONATION_TYPE_ENUMS.RESTRICTED:
        return (
          <Grid container wrap="wrap">
            <DetailsGridItem>{PaymentMethod}</DetailsGridItem>
            <DetailsGridItem>
              <FeedSliderContentDetail
                title={translate('DONATION_AMOUNT_LABEL')}
                value={formatCurrency(estimatedValue, true)}
              />
            </DetailsGridItem>
            <DetailsGridItem>
              <FeedSliderContentDetail
                title={translate('RESTRICTION_AREA_LABEL')}
                value={restrictionArea}
              />
            </DetailsGridItem>
          </Grid>
        );
      case DONATION_TYPE_ENUMS.TIME:
        return (
          <Grid container wrap="wrap">
            <DetailsGridItem>
              <FeedSliderContentDetail
                iconAlt={timeValueProps?.iconAlt}
                iconSrc={timeValueProps?.icon}
                title={translate('DONATION_TIME_VALUE_LABEL')}
                value={timeValueProps?.text}
              />
            </DetailsGridItem>
            <DetailsGridItem>
              <FeedSliderContentDetail
                iconAlt={timeCategoryProps?.iconAlt}
                iconSrc={timeCategoryProps?.icon}
                title={translate('DONATION_VOLUNTEER_LABEL')}
                value={timeCategoryProps?.text}
              />
            </DetailsGridItem>
            <DetailsGridItem>
              <FeedSliderContentDetail
                title={translate('DONATION_TIME_AMOUNT')}
                value={`${timeData.time} ${timeUnitProps.text}`}
              />
            </DetailsGridItem>
          </Grid>
        );
      case DONATION_TYPE_ENUMS.RECURRING:
        return (
          <Grid container wrap="wrap">
            <DetailsGridItem>{PaymentMethod}</DetailsGridItem>
            <DetailsGridItem>
              <FeedSliderContentDetail
                title={translate('DONATION_AMOUNT_LABEL')}
                value={formatCurrency(estimatedValue, true)}
              />
            </DetailsGridItem>
            <DetailsGridItem>
              <FeedSliderContentDetail
                title={translate('DONATION_START_DATE_CADENCE')}
                value={
                  recurringFormattedDate &&
                  recurringCadenceProps &&
                  `${recurringFormattedDate} - ${recurringCadenceProps?.text}`
                }
              />
            </DetailsGridItem>
          </Grid>
        );
      case DONATION_TYPE_ENUMS.PLANNED_GIVING:
        return (
          <FeedSliderContentDetail
            title={translate('DONATION_AMOUNT_LABEL')}
            value={formatCurrency(estimatedValue, true)}
          />
        );
      case DONATION_TYPE_ENUMS.GRANT:
        return (
          <Grid container wrap="wrap">
            <DetailsGridItem width={12}>
              <FeedSliderContentDetail
                title={translate('GRANT_NAME_LABEL')}
                value={grantData.grantName}
              />
            </DetailsGridItem>
            <DetailsGridItem>{PaymentMethod}</DetailsGridItem>
            <DetailsGridItem>
              <FeedSliderContentDetail
                title={translate('GRANT_AMOUNT_LABEL')}
                value={formatCurrency(estimatedValue, true)}
              />
            </DetailsGridItem>
            <DetailsGridItem width={12}>
              <FeedSliderContentDetail
                title={translate('CONDITIONS_OF_RESTRICTION_LABEL')}
                value={grantData.restrictionCondition}
              />
            </DetailsGridItem>
          </Grid>
        );
      default:
        return null;
    }
  };

  return (
    <>
      <h6 className="text-base font-semibold pb-4">
        {translate('FEED_SLIDER_DETAILS_TITLE')}
      </h6>
      <FeedSliderContentDetail
        title={translate('DONATION_CAMPAIGN')}
        value={campaignDisplayName}
      />
      <Grid container wrap="wrap">
        <DetailsGridItem>
          <FeedSliderContentDetail
            iconAlt={type.iconAlt}
            iconSrc={type.icon}
            title={translate('DONATION_TYPE_LABEL')}
            value={type.displayText}
          />
        </DetailsGridItem>
        <DetailsGridItem>
          <FeedSliderContentDetail
            title={translate('DONATION_DATE_LABEL')}
            value={formatDate(donationDateString, CUSTOM_DATE_FORMAT)}
          />
        </DetailsGridItem>
      </Grid>
      <TypeRelatedDetails />
      <FeedSliderContentDetail
        title={translate('DONATION_NOTES_LABEL')}
        value={note}
      />
      {Boolean(developers?.length) && (
        <>
          <div className="text-base font-semibold pb-3 pt-1.5">
            {translate('ASSOCIATED_TEAM_MEMBERS_LABEL')}
          </div>
          <FeedSliderContentAssignees
            ownerOrgId={ownerOrgId}
            assignees={developers}
          />
        </>
      )}
      <FeedSliderDonationFooter {...props} />
    </>
  );
};

FeedSliderDonationContent.propTypes = DonationDetailsType;

FeedSliderDonationContent.defaultProps = {
  actualValue: 0,
  campaignDisplayName: '',
  developers: [],
  donationDateString: '',
  donationPlan: null,
  donationTypeEnum: '',
  estimatedValue: 0,
  grantData: {},
  inKind: [],
  notes: [],
  paymentTypeEnum: '',
  pledgeData: null,
  restrictions: [],
  timeData: null,
};

export { FeedSliderDonationContent };
