/**
 * This component allows for passing in a name of png image from the "assets/images"
 * folder as a "type" prop. This will eventually replace the donor-icons.jsx file by
 * allowing for dynamic generation of an image icon by simply doing the following:
 *
 *  <ImageIcon type="home" />
 */
import React from 'react';
import PropTypes from 'prop-types';
import { pngs } from 'lib/utils/imageFileLoader';

const imageTypes = Object.keys(pngs);

export default function IconImage({
  alt,
  dataTestid,
  className,
  width,
  height,
  type,
}) {
  const src = pngs[type];

  if (!src) {
    // eslint-disable-next-line no-console
    console.warn(`There is no src for the icon type of "${type}"`);
    return null;
  }

  return (
    <img
      {...(className && { className })}
      {...(dataTestid && { 'data-testid': dataTestid })}
      {...(height && { height })}
      {...(width && { width })}
      alt={alt}
      src={src}
    />
  );
}

IconImage.propTypes = {
  alt: PropTypes.string.isRequired,
  className: PropTypes.string,
  dataTestid: PropTypes.string,
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  type: PropTypes.oneOf(imageTypes).isRequired,
};

IconImage.defaultProps = {
  dataTestid: '',
  width: '',
  height: '',
  className: '',
};
