import React from 'react';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import PropTypes from 'prop-types';
import { CUSTOM_DATE_FORMAT } from 'lib/constants/common';
import { EMPTY_FUNC } from 'lib/constants';

export default function DateInput({ utils, ...datePickerProps }) {
  return (
    <MuiPickersUtilsProvider utils={utils}>
      <KeyboardDatePicker {...datePickerProps} />
    </MuiPickersUtilsProvider>
  );
}

DateInput.propTypes = {
  label: PropTypes.string,
  autoOk: PropTypes.bool,
  disableToolbar: PropTypes.bool,
  format: PropTypes.string,
  fullWidth: PropTypes.bool,
  id: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  utils: MuiPickersUtilsProvider.propTypes.utils,
  value: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
  variant: PropTypes.string,
};

DateInput.defaultProps = {
  autoOk: true,
  disableToolbar: true,
  format: CUSTOM_DATE_FORMAT,
  fullWidth: false,
  onChange: EMPTY_FUNC,
  label: '',
  utils: DateFnsUtils,
  value: null,
  variant: 'inline',
};
