import { oneOf } from 'prop-types';
import { values } from 'rambdax';
import { translate } from 'lib/intl';
import {
  interactionIcon,
  hornIcon,
  giftIcon,
  barGraphIcon,
  campaignIcon,
} from 'assets/images';
import { colors } from 'styles/theme';

const FEED_TYPE_ENUMS = {
  INTERACTION: 'Interaction',
  ANNOUNCEMENT: 'Announcement',
  DONATION: 'Donation',
  TREND: 'Trend',
  CAMPAIGN: 'Campaign',
  CONTRIBUTION: 'Contribution',
};

const FeedTypeMap = {
  [FEED_TYPE_ENUMS.INTERACTION]: {
    color: colors.feedInteraction,
    icon: interactionIcon,
    label: translate('FEED_INTERACTION_LABEL'),
  },
  [FEED_TYPE_ENUMS.ANNOUNCEMENT]: {
    color: colors.feedAnnouncement,
    icon: hornIcon,
    label: translate('FEED_ANNOUNCEMENT_LABEL'),
  },
  [FEED_TYPE_ENUMS.DONATION]: {
    color: colors.feedDonation,
    icon: giftIcon,
    label: translate('FEED_DONATION_LABEL'),
  },
  [FEED_TYPE_ENUMS.TREND]: {
    color: colors.feedTrend,
    icon: barGraphIcon,
    label: translate('FEED_TREND_LABEL'),
  },
  [FEED_TYPE_ENUMS.CAMPAIGN]: {
    color: colors.feedCampaign,
    icon: campaignIcon,
    label: translate('FEED_CAMPAIGN_LABEL'),
  },
  [FEED_TYPE_ENUMS.CONTRIBUTION]: {
    color: colors.feedDonation,
    icon: giftIcon,
    label: translate('FEED_CONTRIBUTION_LABEL'),
  },
};

const FeedTypes = values(FEED_TYPE_ENUMS);

const FeedType = oneOf(FeedTypes);

export { FeedTypeMap, FeedTypes, FeedType };
