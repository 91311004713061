import React from 'react';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { omit } from 'rambdax';
import env from '@beam-australia/react-env';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Link from '@material-ui/core/Link';
import makeStyles from '@material-ui/styles/makeStyles';
import { authNavLinkStyle } from 'components/auth/authStyles';
import ImageIcon from 'components/common/ImageIcon';
import { OrgName } from 'components';
import { PUBLIC_ROUTES } from 'lib/constants';
import { translate } from 'lib/intl';

const useStyles = makeStyles((theme) => ({
  container: {
    height: '100vh',
    flexGrow: 1,
    [theme.breakpoints.up('xs')]: {
      padding: theme.spacing(3, 1.5, 3, 1.5),
    },
    [theme.breakpoints.up('sm')]: {
      marginTop: theme.spacing(7),
    },
  },
  content: {
    borderRadius: '8px',
    borderColor: theme.palette.grey[600],
    padding: theme.spacing(6, 7, 6, 7),
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(3),
    },
  },
  navLinkContainer: {
    marginLeft: 'auto',
  },
  routerLink: {
    ...omit('color', authNavLinkStyle),
    borderBottom: 'none',
    marginLeft: 'auto',
  },
  footer: {
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'center',
    },
    '& > *': {
      marginLeft: theme.spacing(1.5),
      marginRight: theme.spacing(1.5),
      '&:first-child': {
        marginLeft: 0,
      },
      '&:last-child': {
        marginRight: 0,
      },
    },
  },
}));

const AuthContainer = ({
  size,
  navLinkText,
  navLinkRoute,
  orgName,
  children,
}) => {
  const helpLinkAddress = env('HELP_LINK_ADDRESS');
  const privacyLink = env('PRIVACY_LINK');
  const termsLink = env('TERMS_LINK');

  const classes = useStyles();
  // TODO move to central style location

  return (
    <Container className={classes.container} maxWidth={size}>
      <Box className={classes.content} mb={1} border={1}>
        <Box alignItems="center" display="flex" mb={{ xs: 4, sm: 6 }}>
          <Box
            display="flex"
            alignItems={{ xs: 'left', sm: 'center' }}
            flexDirection={{ xs: 'column', sm: 'row' }}
          >
            <RouterLink to={PUBLIC_ROUTES.LANDING}>
              <ImageIcon
                alt={translate('INSTIL_LOGO_ALT')}
                type="instil"
                width={112}
              />
            </RouterLink>

            <OrgName orgName={orgName} />
          </Box>
          {navLinkText && navLinkRoute && (
            <Box className={classes.navLinkContainer}>
              <Link
                component={RouterLink}
                data-testid="auth-container-nav-link"
                to={{ pathname: navLinkRoute }}
                className={classes.routerLink}
              >
                {navLinkText}
              </Link>
            </Box>
          )}
        </Box>
        <Box mt={3}>{children}</Box>
      </Box>
      <Box
        className={classes.footer}
        display="flex"
        justifyContent="flex-end"
        mb={7}
        width="100%"
      >
        <Link href={`mailto:${helpLinkAddress}`}>{translate('HELP_LINK')}</Link>
        <Link href={privacyLink} rel="noopener noreferrer" target="_blank">
          {translate('PRIVACY_LINK')}
        </Link>
        <Link href={termsLink} rel="noopener noreferrer" target="_blank">
          {translate('TERMS_LINK')}
        </Link>
      </Box>
    </Container>
  );
};

AuthContainer.propTypes = {
  size: PropTypes.string.isRequired,
  navLinkText: PropTypes.string,
  navLinkRoute: PropTypes.string,
  orgName: PropTypes.string,
  children: PropTypes.node.isRequired,
};

AuthContainer.defaultProps = {
  navLinkText: '',
  navLinkRoute: '',
  orgName: null,
};

export default AuthContainer;
