/**
 * This is a "controlled" DateInput component, made to work with
 * react-hooks-form.
 */
import React from 'react';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';
import DateInput from './DateInput';

export default function ControlledDateInput({
  control,
  defaultValue,
  name,
  rules,
  ...props
}) {
  return (
    <Controller
      {...(defaultValue && { defaultValue })}
      {...(rules && { rules })}
      control={control}
      name={name}
      render={({ onChange, value }) => {
        return (
          <DateInput
            {...{
              ...props,
              ...(value && { value }),
              onChange,
            }}
          />
        );
      }}
    />
  );
}

ControlledDateInput.propTypes = {
  control: PropTypes.object.isRequired,
  defaultValue: PropTypes.instanceOf(Date),
  name: PropTypes.string.isRequired,
  rules: PropTypes.object,
  ...DateInput.propTypes,
};

ControlledDateInput.defaultProps = {
  ...DateInput.defaultProps,
  defaultValue: null,
  rules: null,
};
