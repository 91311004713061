import { useCallback, useEffect } from 'react';
import { KEY_ESCAPE } from 'keycode-js';

/**
  This is a hook which makes sure to call a handler on Esc
  @param {func} handler - handler function
*/
const useEscHandler = (handler) => {
  const onKeyDown = useCallback(
    ({ keyCode }) => {
      if (keyCode === KEY_ESCAPE) {
        handler();
      }
    },
    [handler]
  );

  useEffect(() => {
    document.addEventListener('keydown', onKeyDown);

    return () => {
      document.removeEventListener('keydown', onKeyDown);
    };
  }, [onKeyDown]);
};

export { useEscHandler };
