import React from 'react';
import { func, number, string, shape } from 'prop-types';
import { EMPTY_FUNC } from 'lib/constants';
import { TextSelectFieldValueType } from 'lib/utils';
import { Grid, makeStyles, TextField } from '@material-ui/core';
import { NotesInput, TextSelectField } from 'components';
import { translate } from 'lib/intl';
import {
  DONATION_VOLUNTEER_CONFIG,
  DONATION_VALUE_OF_TIME_CONFIG,
  DONATION_TIME_TYPE_CONFIG,
} from 'lib/configs/donations';

const useStyles = makeStyles(() => ({
  rowItem: {
    paddingTop: '12px',
  },
  notesContainer: {
    paddingTop: '24px',
  },
}));

const NewDonationTypeTime = ({
  fieldErrors,
  timeCategory,
  setTimeCategory,
  timeValue,
  setTimeValue,
  amountOfTime,
  setAmountOfTime,
  timeType,
  setTimeType,
  notes,
  setNotes,
}) => {
  const styles = useStyles();

  return (
    <Grid container>
      <Grid
        alignItems="flex-end"
        className={styles.rowItem}
        container
        spacing={2}
      >
        <Grid item xs={12} sm={7}>
          <TextSelectField
            id="donation-volunteer-selector"
            items={DONATION_VOLUNTEER_CONFIG}
            value={timeCategory}
            setValue={setTimeCategory}
            label={translate('DONATION_VOLUNTEER_LABEL')}
            error={!!fieldErrors?.timeCategory}
            errorHelperText={translate('DONATION_VOLUNTEER_ERROR_REQUIRED')}
          />
        </Grid>
        <Grid item xs={12} sm={5}>
          <TextSelectField
            id="donation-value-selector"
            items={DONATION_VALUE_OF_TIME_CONFIG}
            value={timeValue}
            setValue={setTimeValue}
            label={translate('DONATION_TIME_VALUE_LABEL')}
            error={!!fieldErrors?.timeValue}
            errorHelperText={translate('DONATION_VALUE_REQUIRED')}
          />
        </Grid>
      </Grid>
      <Grid
        alignItems="flex-end"
        className={styles.rowItem}
        container
        spacing={2}
      >
        <Grid item xs={12} sm={7}>
          <TextField
            id="donation-amount-of-time-input"
            label={translate('DONATION_TIME_AMOUNT')}
            fullWidth
            value={amountOfTime}
            onChange={(e) => setAmountOfTime(e.target.value)}
            error={!!fieldErrors?.amountOfTime}
            helperText={
              fieldErrors?.amountOfTime
                ? translate('DONATION_TIME_AMOUNT_REQUIRED')
                : ''
            }
            type="number"
          />
        </Grid>
        <Grid item xs={12} sm={5}>
          <TextSelectField
            id="donation-time-selector"
            items={DONATION_TIME_TYPE_CONFIG}
            value={timeType}
            setValue={setTimeType}
            label={translate('DONATION_TIME_TYPE')}
            error={!!fieldErrors?.timeType}
            errorHelperText={translate('DONATION_TIME_TYPE_REQUIRED')}
          />
        </Grid>
      </Grid>
      <Grid className={styles.notesContainer} item container xs={12}>
        <NotesInput
          label={translate('DONATION_NOTES_LABEL')}
          notes={notes}
          setNotes={setNotes}
          maxLength={10000}
        />
      </Grid>
    </Grid>
  );
};

NewDonationTypeTime.propTypes = {
  fieldErrors: shape({}),
  timeCategory: TextSelectFieldValueType,
  setTimeCategory: func,
  timeValue: TextSelectFieldValueType,
  setTimeValue: func,
  amountOfTime: number,
  setAmountOfTime: func,
  timeType: TextSelectFieldValueType,
  setTimeType: func,
  notes: string,
  setNotes: func,
};

NewDonationTypeTime.defaultProps = {
  fieldErrors: {},
  timeCategory: null,
  setTimeCategory: EMPTY_FUNC,
  timeValue: null,
  setTimeValue: EMPTY_FUNC,
  amountOfTime: undefined,
  setAmountOfTime: EMPTY_FUNC,
  timeType: null,
  setTimeType: EMPTY_FUNC,
  notes: '',
  setNotes: EMPTY_FUNC,
};

export { NewDonationTypeTime };
