import React from 'react';
import PropTypes from 'prop-types';
import Link from '@material-ui/core/Link';
import { EMPTY_FUNC } from 'lib/constants';

const CustomLink = ({
  children,
  className,
  dataTestid,
  onClick,
  onMouseEnter,
  onMouseLeave,
  variant,
  ...rest
}) => (
  <Link
    className={className}
    data-testid={dataTestid}
    onClick={onClick}
    onMouseEnter={onMouseEnter}
    onMouseLeave={onMouseLeave}
    variant={variant}
    {...rest}
  >
    {children}
  </Link>
);

CustomLink.propTypes = {
  className: PropTypes.string,
  dataTestid: PropTypes.string,
  onClick: PropTypes.func,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
  children: PropTypes.node.isRequired,
  variant: PropTypes.string,
};

CustomLink.defaultProps = {
  className: '',
  dataTestid: 'custom-link',
  onClick: EMPTY_FUNC,
  onMouseEnter: EMPTY_FUNC,
  onMouseLeave: EMPTY_FUNC,
  variant: 'button',
};

export { CustomLink };
