import { types } from '../actions/constituentFormPopupActions';

const initialState = {
  // use formName from CONSTITUENT_SECTION_TYPES
  formName: '',
  // editing otherwise
  isCreating: true,
  fieldToFocus: '',
  // some other data as ids
  data: null,
  // data which is used in InfoTabContent for creating a new constituent
  updatedData: null,
};

export default function constituentFormPopup(state = initialState, action) {
  switch (action.type) {
    case types.open:
      return {
        ...state,
        ...action.data,
      };
    case types.close:
      return initialState;
    default:
      return state;
  }
}
