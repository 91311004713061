import React from 'react';
import { string } from 'prop-types';
import { Typography } from '@material-ui/core';

const ToastFeedbackMessage = ({ message }) => (
  <Typography align="center">{message}</Typography>
);

ToastFeedbackMessage.propTypes = {
  message: string.isRequired,
};

export { ToastFeedbackMessage };
