import { values } from 'rambdax';
import { COUNTRY_CODES } from 'lib/constants';
import { UsaFlagIcon } from 'assets/images';

const PHONE_NUMBERS_CONFIG = {
  [COUNTRY_CODES.US]: {
    id: COUNTRY_CODES.US,
    label: 'USA',
    flag: UsaFlagIcon,
  },
};

const PHONE_NUMBERS_CONFIG_VALUES = values(PHONE_NUMBERS_CONFIG);

export { PHONE_NUMBERS_CONFIG, PHONE_NUMBERS_CONFIG_VALUES };
