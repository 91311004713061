import { useDispatch, useSelector } from 'react-redux';
import constituentActions from 'rdx/actions/constituentActions';

export default function useUpdateConstituentAssignment() {
  const dispatch = useDispatch();

  const { isUpdating } = useSelector((state) => ({
    isUpdating: state.constituents?.isUpdating,
  }));

  const updateConstituentAssignment = (constituentId, data) =>
    dispatch(constituentActions.updateConstituent({ constituentId, data }));

  return {
    isUpdating,
    updateConstituentAssignment,
  };
}
