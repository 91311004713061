import React from 'react';
import { string } from 'prop-types';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/styles/makeStyles';
import { ICON_SIZES } from 'lib/constants/common';

const useStyles = makeStyles((theme) => ({
  title: {
    color: theme.palette.grey.dark,
  },
  img: {
    height: ICON_SIZES.SM,
    marginRight: theme.spacing(1),
    width: ICON_SIZES.SM,
  },
  value: {
    whiteSpace: 'pre-line',
  },
}));

const FeedSliderContentDetail = ({ iconAlt, iconSrc, title, value }) => {
  if (!value) {
    return null;
  }

  const classes = useStyles();

  return (
    <Box pb={1.5}>
      <Typography variant="caption" className={classes.title}>
        {title}
      </Typography>
      <Box alignItems="center" display="flex">
        {iconSrc && <img alt={iconAlt} className={classes.img} src={iconSrc} />}
        <Typography className={classes.value}>{value}</Typography>
      </Box>
    </Box>
  );
};

FeedSliderContentDetail.propTypes = {
  iconSrc: string,
  iconAlt: string,
  title: string.isRequired,
  value: string,
};

FeedSliderContentDetail.defaultProps = {
  iconSrc: '',
  iconAlt: '',
  value: '',
};

export { FeedSliderContentDetail };
