import { getCompanyTeamMemberState } from 'lib/utils';
import userActionTypes from 'rdx/actionTypes';

const {
  UPDATE_INVITES_REQUEST,
  UPDATE_INVITES_SUCCESS,
  UPDATE_INVITES_FAILURE,
  REMOVE_INVITES_REQUEST,
  REMOVE_INVITES_SUCCESS,
  REMOVE_INVITES_FAILURE,
} = userActionTypes;

const initialState = {};

export function updatingInvites(state = initialState, action) {
  switch (action.type) {
    case UPDATE_INVITES_REQUEST:
    case REMOVE_INVITES_REQUEST:
      return {
        ...state,
        ...getCompanyTeamMemberState(
          action,
          {
            dataLoaded: false,
            isFetching: true,
            error: null,
          },
          'inviteId'
        ),
      };
    case UPDATE_INVITES_SUCCESS:
    case REMOVE_INVITES_SUCCESS:
      return {
        ...state,
        ...getCompanyTeamMemberState(
          action,
          {
            data: action.data,
            dataLoaded: true,
            isFetching: false,
            error: null,
          },
          'inviteId'
        ),
      };
    case UPDATE_INVITES_FAILURE:
    case REMOVE_INVITES_FAILURE:
      return {
        ...state,
        ...getCompanyTeamMemberState(
          action,
          {
            dataLoaded: false,
            isFetching: false,
            error: action.error || 'error',
          },
          'inviteId'
        ),
      };
    default:
      return state;
  }
}
