import React, { useCallback, useMemo } from 'react';
import {
  arrayOf,
  bool,
  elementType,
  func,
  object,
  oneOfType,
  number,
  shape,
  string,
} from 'prop-types';
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from '@material-ui/core';
import { isMobileView } from 'lib/utils';
import { translate } from 'lib/intl';
import { AddIcon, RemoveIcon } from 'assets/images';
import { PrimaryCheckbox, Visible } from 'components';
import styled from 'styled-components';
import { assocPath, path as getByPath } from 'rambdax';
import { colors } from 'styles/theme';

const StyledWrapper = styled(Box)`
  position: relative;
`;

const StyledInputWrapper = styled(Box)`
  flex: ${({ mobile }) => (mobile ? 'none' : '1 250px')};
  position: relative;

  .primary-checkbox {
    position: absolute;
    top: ${({ mobile }) => (mobile ? '36px' : '24px')};
    right: 0px;
  }

  ${({ primary }) =>
    primary
      ? `
    .MuiFilledInput-input {
      padding-right: 24px !important;
      text-overflow: ellipsis;
    }`
      : ''}
`;

const StyledIcons = styled(Box)`
  display: flex;
  justify-content: space-between;
  position: absolute;
  right: -64px;
  top: ${({ mobile }) => (mobile ? '40px' : '30px')};
  width: 50px;
`;

const IconWrapper = styled(Box)`
  cursor: pointer;
  height: 17px;
  flex: 0 0 17px;
  visibility: ${({ show }) => (show ? 'visible' : 'hidden')};
`;

const TypesWrapper = styled(FormControl)`
  flex: 1 1 ${({ mobile }) => (mobile ? '100%' : '0%')};
  min-width: 160px;

  .MuiInput-underline:before,
  .MuiInput-underline:hover:not(.Mui-disabled):before {
    border-bottom: 1px solid ${colors.grey500};
  }

  label + .MuiInput-formControl {
    margin-top: 0px;
    padding: 24px 12px 3px 0px;
  }

  .MuiInputLabel-formControl {
    transform: translate(0, 30px) scale(1);
  }

  .MuiInputLabel-formControl.MuiInputLabel-shrink {
    transform: translate(0px, 10px) scale(0.75);
  }

  .MuiSelect-icon {
    top: 50%;
  }

  .MuiSelect-select:focus {
    background: none;
  }

  .MuiInputBase-input {
    border-radius: 0px;
    height: 24px;
    padding: 0px 12px 0px 0px;
  }
`;

const MultipleInputsItem = ({
  Component,
  data,
  dataToMapLength,
  fieldToExtract,
  idx,
  onAdd,
  onRemove,
  onUpdate,
  onPrimaryUpdate,
  primaryIdx,
  labels,
  usePrimary,
  ...rest
}) => {
  const isMobile = isMobileView();
  const labelLength = labels?.length;

  const isLastInput = useMemo(
    () => dataToMapLength - 1 === idx,
    [dataToMapLength, idx]
  );

  // shows add button only for the last items
  const showAddButton = useMemo(
    () => (labelLength ? isLastInput && labelLength > 1 : isLastInput),
    [labels, isLastInput]
  );

  const MenuItems = useMemo(
    () =>
      labels?.map(({ label, value }) => (
        <MenuItem key={value} value={value}>
          <Typography color="textPrimary">{label}</Typography>
        </MenuItem>
      )),
    [labels]
  );

  const selectValue = useMemo(() => data?.label || '', [data]);

  const RenderedComponent = useMemo(
    () => (
      <Component
        data={data}
        path={fieldToExtract}
        updateData={(updatedData) => onUpdate(updatedData, idx)}
        {...rest}
      />
    ),
    [data, fieldToExtract, onUpdate, rest]
  );

  return (
    <StyledWrapper mobile={Number(isMobile)}>
      <Box
        display="flex"
        flexDirection={isMobile ? 'column' : 'row'}
        flexWrap="wrap"
        className="items-end"
      >
        <StyledInputWrapper
          primary={Number(usePrimary)}
          mobile={Number(isMobile)}
          mr={isMobile || !labels.length ? 0 : 2}
        >
          {RenderedComponent}
          <Visible when={usePrimary && dataToMapLength > 1}>
            <PrimaryCheckbox
              className="primary-checkbox"
              primary={primaryIdx === idx}
              onChange={(primary) => onPrimaryUpdate(primary, idx)}
              checkboxProps={{
                name: 'primary-checkbox',
              }}
            />
          </Visible>
        </StyledInputWrapper>
        <Visible when={Boolean(labels.length)}>
          <TypesWrapper mobile={Number(isMobile)}>
            <InputLabel id="multiple-input-type-select">
              {translate('TYPE_LABEL')}
            </InputLabel>
            <Select
              data-testid="multiple-input-type-select"
              id="multiple-input-type-select"
              onChange={({ target: { value } }) =>
                onUpdate({ label: value }, idx)
              }
              value={selectValue}
            >
              {MenuItems}
            </Select>
          </TypesWrapper>
        </Visible>
      </Box>
      <StyledIcons mobile={Number(isMobile)}>
        <IconWrapper show={Number(dataToMapLength > 1)}>
          <RemoveIcon onClick={() => onRemove(idx)} />
        </IconWrapper>
        <IconWrapper show={Number(showAddButton)}>
          <AddIcon onClick={onAdd} />
        </IconWrapper>
      </StyledIcons>
    </StyledWrapper>
  );
};

MultipleInputsItem.propTypes = {
  Component: elementType.isRequired,
  data: shape({
    label: string,
    email: string,
    number: string,
    primary: bool,
  }).isRequired,
  dataToMapLength: number.isRequired,
  fieldToExtract: string.isRequired,
  onAdd: func.isRequired,
  onRemove: func.isRequired,
  primaryIdx: number.isRequired,
  idx: number.isRequired,
  labels: arrayOf(
    shape({
      label: string,
      value: string,
    })
  ).isRequired,
  onUpdate: func.isRequired,
  onPrimaryUpdate: func.isRequired,
  usePrimary: bool.isRequired,
};

const MultipleInputs = ({
  data,
  defaultValue,
  labels,
  path,
  updateData,
  usePrimary,
  ...rest
}) => {
  const dataToMap = useMemo(() => {
    const dataByPath = getByPath(path, data);

    return dataByPath?.length ? dataByPath : [defaultValue];
  }, [data]);

  const primaryIdx = useMemo(() => {
    const index = dataToMap.findIndex((item) => item?.primary);

    return index >= 0 ? index : 0;
  }, [dataToMap]);

  const onAdd = useCallback(() => {
    updateData(assocPath(path, [...dataToMap, defaultValue], data), path);
  }, [data]);

  const onRemove = useCallback(
    (idx) => {
      const updatedData = [...dataToMap];
      updatedData.splice(idx, 1);

      updateData(
        assocPath(path, updatedData?.length ? updatedData : null, data),
        path
      );
    },
    [data]
  );

  const onUpdate = useCallback(
    (newData, idx) => {
      const updatedData = [...dataToMap];

      updatedData[idx] = {
        ...dataToMap[idx],
        ...newData,
      };

      updateData(assocPath(path, updatedData, data), path);
    },
    [data]
  );

  const onPrimaryUpdate = useCallback(
    (isPrimary, idx) => {
      const updatedData = dataToMap?.map((item, index) => ({
        ...item,
        primary: (isPrimary && idx === index) || (!isPrimary && idx === 0),
      }));

      updateData(assocPath(path, updatedData, data), path);
    },
    [data]
  );

  return dataToMap?.map((inputData, idx) => (
    <MultipleInputsItem
      data={inputData}
      dataToMapLength={dataToMap.length}
      idx={idx}
      // eslint-disable-next-line react/no-array-index-key
      key={idx}
      onAdd={onAdd}
      onRemove={onRemove}
      labels={labels}
      onUpdate={onUpdate}
      onPrimaryUpdate={onPrimaryUpdate}
      primaryIdx={primaryIdx}
      usePrimary={usePrimary}
      {...rest}
    />
  ));
};

MultipleInputs.propTypes = {
  Component: elementType.isRequired,
  data: oneOfType([
    arrayOf(
      shape({
        label: string,
        email: string,
        number: string,
        primary: bool,
      })
    ),
    shape({}),
  ]),
  defaultValue: oneOfType([string, object]).isRequired,
  path: string.isRequired,
  fieldToExtract: string,
  labels: arrayOf(
    shape({
      label: string,
      value: string,
    })
  ),
  updateData: func.isRequired,
  usePrimary: bool,
};

MultipleInputs.defaultProps = {
  labels: [],
  fieldToExtract: '',
  usePrimary: false,
};

export { MultipleInputs };
