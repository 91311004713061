import { translate } from 'lib/intl';

const MESSAGE_MAX_LENGTH = 400;
const PINNED_DURATION_LIMIT = 7;
const DURATION_OPTIONS = [...new Array(PINNED_DURATION_LIMIT)].map(
  (_val, idx) => {
    const value = idx + 1;
    const label = translate(
      value === 1
        ? 'ANNOUNCEMENT_PINNED_DURATION_DAY'
        : 'ANNOUNCEMENT_PINNED_DURATION_DAY_PLURAL'
    );

    return {
      value,
      text: `${value} ${label}`,
    };
  }
);

export { MESSAGE_MAX_LENGTH, DURATION_OPTIONS };
