/**
 * This is a "controller" Password component, made to work with
 * react-hooks-form.
 */
import React from 'react';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { Controller, useFormContext } from 'react-hook-form';
import { translate } from 'lib/intl';

export default function ControlledCreatePasswordInput({
  dataTestid,
  id,
  name,
  onBlur,
  onChange,
  rules: rulesProp,
  ...props
}) {
  const { control, errors } = useFormContext();

  const [showPassword, setShowPassword] = React.useState(false);

  const rules = {
    required: translate('FORMS_VALIDATION_REQUIRED'),
    ...(rulesProp && rulesProp),
  };

  const handleClickShowPassword = () => setShowPassword((prev) => !prev);
  const handleMouseDownPassword = (event) => event.preventDefault();
  const passwordError = errors[name];

  return (
    <Controller
      control={control}
      defaultValue=""
      name={name}
      rules={rules}
      render={({ onChange: onFieldChange, onBlur: onFieldBlur, ...field }) => {
        const handleBlur = (e) => {
          onFieldBlur(e);
          // eslint-disable-next-line no-unused-expressions
          onBlur && onBlur(e);
        };
        const handleChange = (e) => {
          onFieldChange(e);
          // eslint-disable-next-line no-unused-expressions
          onChange && onChange(e);
        };

        return (
          <TextField
            error={!!passwordError}
            id={id}
            helperText={passwordError ? passwordError.message : ''}
            InputProps={{
              endAdornment: (
                <InputAdornment>
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
              inputProps: {
                'data-testid': dataTestid,
              },
            }}
            label={translate('PASSWORD')}
            name={name}
            onBlur={handleBlur}
            onChange={handleChange}
            type={showPassword ? 'text' : 'password'}
            {...props}
            {...field}
          />
        );
      }}
    />
  );
}

ControlledCreatePasswordInput.propTypes = {
  dataTestid: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  rules: PropTypes.object,
};

ControlledCreatePasswordInput.defaultProps = {
  id: 'password',
  dataTestid: 'password',
  name: 'password',
  onBlur: null,
  onChange: null,
  rules: null,
};
