import React from 'react';
import Box from '@material-ui/core/Box';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import { Redirect } from 'react-router';
import BasicPageLayout from 'components/common/BasicPageLayout';
import { CommonInfoSection } from 'components/common/CommonInfoSection';
import MainContent from 'components/common/MainContent';
import { BackButton } from 'components/common/back-button';
import { translate } from 'lib/intl';
import { isMobileView } from 'lib/utils';
import { useIntegrationPage } from './integrationPageHooks';
import { getIntegrationCredentialsComponent } from './integrationPageUtils';
import { useStyles } from './integrationPageStyles';
import data from '../IntegrationsPage/data/integrations';
import IntegrationHelperSteps from './IntegrationHelperSteps';

export default function IntegrationPage() {
  const isMobile = isMobileView();
  const styles = useStyles();

  const { integrationId, showHelperSteps } = useIntegrationPage();

  // TODO update; placeholder dependent on API response
  const { integrations } = data;
  const activeIntegration = integrations.find((i) => i.id === integrationId);
  if (!activeIntegration) return <Redirect to="/integrations" />;

  const {
    longDescription,
    logo,
    name,
    helperSteps,
    website,
    shortDescription,
  } = activeIntegration;

  function createMarkup(description) {
    return { __html: description };
  }

  return (
    <BasicPageLayout data-testid="integration-page">
      <CommonInfoSection>
        <BackButton label={translate('GO_BACK')} color="primary" />
        <Box className={styles.infoSection}>
          <img className={styles.integrationLogo} alt={name} src={logo} />
          <Box className={styles.infoText}>
            <Typography
              className={styles.infoName}
              variant={isMobile ? 'subtitle1' : 'h6'}
            >
              {name}
            </Typography>
            <Typography
              className={styles.textBreak}
              variant={isMobile ? 'body2' : 'body1'}
            >
              {isMobile ? shortDescription : longDescription}
            </Typography>
            <Link href={website}>{website}</Link>
          </Box>
        </Box>
      </CommonInfoSection>
      <MainContent className={styles.pageBackground}>
        <Box className={styles.infoBox} p={isMobile ? 1.5 : 3}>
          {getIntegrationCredentialsComponent(integrationId)}
        </Box>
        {showHelperSteps ? (
          <Box className={styles.infoBox}>
            <Box p={isMobile ? 1.5 : 3}>
              <Typography className={styles.helperTitle}>
                {helperSteps.title}
              </Typography>
              <Typography
                variant={isMobile ? 'body2' : 'body1'}
                dangerouslySetInnerHTML={createMarkup(helperSteps.description)}
              />
            </Box>
            <Box className={styles.line} />

            <IntegrationHelperSteps
              helperSteps={helperSteps.steps}
              createMarkup={createMarkup}
              name={name}
            />
          </Box>
        ) : null}
      </MainContent>
    </BasicPageLayout>
  );
}
