import styled from 'styled-components';
import { prop } from 'rambdax';
import { Box, Typography } from '@material-ui/core';
import { colors } from 'styles/theme';

const CampaignActionsContainer = styled(Box)`
  background: ${colors.white};
  width: 360px;

  ${({ mobile }) =>
    mobile &&
    `
    width: auto;
  `}
`;

const CampaignActionsRow = styled(Box)`
  align-items: center;
  border-bottom: 1px solid ${colors.lightGrey};
  cursor: ${prop('cursor')};
  display: flex;
  flex: 1;
  height: 56px;
  padding: 0 16px;
`;

const CampaignActionsTitle = styled(Typography)`
  font-weight: 500;
`;

const CampaignActionsIcon = styled.img`
  cursor: pointer;
  height: 18px;
  width: 18px;
`;

export {
  CampaignActionsContainer,
  CampaignActionsRow,
  CampaignActionsTitle,
  CampaignActionsIcon,
};
