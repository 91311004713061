import React from 'react';
import { hot } from 'react-hot-loader';
import { ThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import { FlagsProvider } from 'flagged';
import { Switch } from 'react-router-dom';
import { wrapWithNav } from 'lib/utils/wrap-with-nav';
import {
  RoutesWrapper,
  AuthRoute,
  PrivateRoute,
  ToastFeedback,
  Loader,
} from 'components';
import useHotJar from 'lib/hooks/useHotJar';
import { theme } from 'styles/theme';
import { useAuthenticationHandler } from 'components/auth';
import {
  HomePage,
  LoginPage,
  LoginLinkExpiredPage,
  LoginNeedsVerificationPage,
  LoginVerificationEmailErrorPage,
  NotFoundErrorPageRaw,
  AdminLoginPage,
  AdminLandingPage,
  AdminRegistrationPage,
  RegistrationByInvitePage,
  RegistrationSuccessPage,
  RegistrationVerificationErrorPage,
  RegistrationInviteVerificationErrorPage,
  ResetPasswordPage,
  ResetPasswordRequestSuccessPage,
  ResetPasswordSetPasswordPage,
  ResetPasswordSetPasswordSuccessPage,
  CompanyPage,
  CampaignsPage,
  CampaignPage,
} from 'pages';
import SuperAdminHomePage from 'pages/SuperAdminPages/SuperAdminHomePage';
import ReportsPage from 'pages/ReportsPage';
import IntegrationPage from 'pages/IntegrationPage';
import IntegrationsPage from 'pages/IntegrationsPage';
import ConstituentPage from 'pages/ConstituentPage/ConstituentPage';
import lazyWithRetry from 'lib/utils/lazyWithRetry';
import { PUBLIC_ROUTES, AUTH_ROUTES } from 'lib/constants';
import { applyPrototypes } from 'lib/utils';
import { parseFeatureFlags } from 'lib/utils/featureFlags';

const ConstituentsPage = lazyWithRetry(() =>
  import('pages/ConstituentsPage/ConstituentsPage')
);
const DonorBoard = lazyWithRetry(() => import('modules/DonorBoard'));
const UserHomePage = lazyWithRetry(() => import('pages/UserPage/UserHomePage'));

const {
  LOGIN,
  LOGIN_EXPIRED,
  ADMIN_LOGIN,
  ADMIN_LANDING,
  ADMIN_REGISTER,
  VERIFICATION,
  VERIFICATION_ERROR,
  REGISTRATION_FROM_INVITE,
  REGISTRATION_SUCCESS,
  REGISTRATION_VERIFICATION_ERROR,
  REGISTRATION_INVITE_VERIFICATION_ERROR,
  RESET_PASSWORD,
  RESET_PASSWORD_REQUEST_SUCCESS,
  RESET_PASSWORD_SET_PASSWORD,
  RESET_PASSWORD_SET_PASSWORD_SUCCESS,
} = PUBLIC_ROUTES;

const {
  LANDING,
  DONORS,
  FEED,
  ADMIN,
  COMPANY,
  COMPANY_TEAM,
  COMPANY_INVITES,
  CONSTITUENT,
  CONSTITUENT_NEW,
  CONSTITUENT_HISTORY,
  CONSTITUENT_INFO,
  CONSTITUENTS,
  CAMPAIGNS,
  CAMPAIGN,
  CAMPAIGN_FEED,
  CAMPAIGN_DONATIONS,
  CAMPAIGN_REPORTS,
  REPORTS,
  INTEGRATIONS,
  INTEGRATION,
  USER,
} = AUTH_ROUTES;

const SuperAdminHomePageWithNav = wrapWithNav(SuperAdminHomePage);
const HomePageWithNav = wrapWithNav(HomePage);
const CompanyPageWithNav = wrapWithNav(CompanyPage);
const CampaignsPageWithNav = wrapWithNav(CampaignsPage);
const CampaignPageWithNav = wrapWithNav(CampaignPage);
const ConstituentPageWithNav = wrapWithNav(ConstituentPage);
const ErrorPageWrapper = wrapWithNav(NotFoundErrorPageRaw);
const ReportsPageWrapper = wrapWithNav(ReportsPage);
const IntegrationsPageWithNav = wrapWithNav(IntegrationsPage);
const IntegrationPageWithNav = wrapWithNav(IntegrationPage);

function App() {
  applyPrototypes();
  useAuthenticationHandler();
  useHotJar();

  return (
    <FlagsProvider features={parseFeatureFlags()}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <ToastFeedback />
        <React.Suspense
          fallback={<Loader isCentered className="h-screen items-center" />}
        >
          <RoutesWrapper>
            <Switch>
              <PrivateRoute exact path={LANDING} component={HomePageWithNav} />
              <PrivateRoute
                exact
                path={[COMPANY, COMPANY_TEAM, COMPANY_INVITES]}
                component={CompanyPageWithNav}
              />
              <PrivateRoute path={FEED} component={HomePageWithNav} />
              <PrivateRoute
                exact
                path={DONORS}
                component={wrapWithNav(DonorBoard)}
              />
              <PrivateRoute
                exact
                path={[
                  CONSTITUENT_NEW,
                  CONSTITUENT,
                  CONSTITUENT_HISTORY,
                  CONSTITUENT_INFO,
                ]}
                component={ConstituentPageWithNav}
              />
              <PrivateRoute
                exact
                path={CAMPAIGNS}
                component={CampaignsPageWithNav}
              />
              <PrivateRoute
                exact
                path={[
                  CAMPAIGN,
                  CAMPAIGN_FEED,
                  CAMPAIGN_DONATIONS,
                  CAMPAIGN_REPORTS,
                ]}
                component={CampaignPageWithNav}
              />
              <PrivateRoute
                exact
                path={CONSTITUENTS}
                component={wrapWithNav(ConstituentsPage)}
              />
              <PrivateRoute
                exact
                path={INTEGRATIONS}
                component={IntegrationsPageWithNav}
              />
              <PrivateRoute
                exact
                path={INTEGRATION}
                component={IntegrationPageWithNav}
              />
              <PrivateRoute path={USER} component={wrapWithNav(UserHomePage)} />
              <PrivateRoute
                path={ADMIN}
                component={SuperAdminHomePageWithNav}
              />
              <PrivateRoute
                exact
                path={REPORTS}
                component={ReportsPageWrapper}
              />
              <AuthRoute exact path={LOGIN} component={LoginPage} />
              <AuthRoute exact path={ADMIN_LOGIN} component={AdminLoginPage} />
              <AuthRoute
                path={LOGIN_EXPIRED}
                component={LoginLinkExpiredPage}
              />
              <AuthRoute
                exact
                path={VERIFICATION}
                component={LoginNeedsVerificationPage}
              />
              <AuthRoute
                path={VERIFICATION_ERROR}
                component={LoginVerificationEmailErrorPage}
              />
              <AuthRoute
                exact
                path={ADMIN_LANDING}
                component={AdminLandingPage}
              />
              <AuthRoute
                exact
                path={ADMIN_REGISTER}
                component={AdminRegistrationPage}
              />
              <AuthRoute
                path={REGISTRATION_SUCCESS}
                component={RegistrationSuccessPage}
              />
              <AuthRoute
                path={REGISTRATION_VERIFICATION_ERROR}
                component={RegistrationVerificationErrorPage}
              />
              <AuthRoute
                path={REGISTRATION_INVITE_VERIFICATION_ERROR}
                component={RegistrationInviteVerificationErrorPage}
              />
              <AuthRoute
                path={REGISTRATION_FROM_INVITE}
                component={RegistrationByInvitePage}
              />
              <AuthRoute
                exact
                path={RESET_PASSWORD}
                component={ResetPasswordPage}
              />
              <AuthRoute
                path={RESET_PASSWORD_REQUEST_SUCCESS}
                component={ResetPasswordRequestSuccessPage}
              />
              <AuthRoute
                exact
                path={RESET_PASSWORD_SET_PASSWORD}
                component={ResetPasswordSetPasswordPage}
              />
              <AuthRoute
                path={RESET_PASSWORD_SET_PASSWORD_SUCCESS}
                component={ResetPasswordSetPasswordSuccessPage}
              />
              <PrivateRoute component={ErrorPageWrapper} />
            </Switch>
          </RoutesWrapper>
        </React.Suspense>
      </ThemeProvider>
    </FlagsProvider>
  );
}

export default hot(module)(App);
