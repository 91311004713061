import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { formatUrl } from 'lib/utils/urls';
import { announcementActions } from 'rdx/actions';
import { DURATION_OPTIONS } from 'components/common/AnnouncementModalConfig';

const useAnnouncementModal = ({ handleClose, isGlobal }) => {
  const dispatch = useDispatch();

  const { ownerOrgId, isLoading, hasError, isCreationSuccessful } = useSelector(
    (state) => ({
      ownerOrgId: state.authentication?.ownerOrgId || '',
      isLoading: state.announcements?.isLoading,
      hasError: !!state.announcements?.errors,
      isCreationSuccessful: state.announcements?.isCreationSuccessful,
    })
  );

  const resetAnnouncement = () =>
    dispatch(announcementActions.resetAnnouncement());

  const onClose = () => {
    resetAnnouncement();
    handleClose();
  };

  const onSubmit = ({
    isPinned = false,
    link,
    message,
    pinnedDuration = DURATION_OPTIONS[0],
  }) => {
    if (isLoading) {
      return;
    }

    const formattedData = {
      message,
      pin: isPinned,
      pinDurationDays: pinnedDuration.value,
    };

    // Link is an optional parameter, format if present
    if (link) {
      formattedData.link = formatUrl(link);
    }

    dispatch(
      announcementActions.createAnnouncement(
        ownerOrgId,
        formattedData,
        isGlobal
      )
    );
  };

  React.useEffect(() => {
    if (isCreationSuccessful) {
      onClose();
    }
  }, [isCreationSuccessful]);

  return {
    hasError,
    isLoading,
    onClose,
    onSubmit,
    resetAnnouncement,
  };
};

export { useAnnouncementModal };
