import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Box } from '@material-ui/core';

export default function MainContent({ children, className, ...props }) {
  return (
    <Box
      className={classNames(
        className,
        'bg-white border-t flex-grow md:border-none border-grey-300 pb-32 md:overflow-auto p-3 md:p-4'
      )}
      {...props}
    >
      {children}
    </Box>
  );
}

MainContent.propTypes = {
  ...Box.propTypes,
  className: PropTypes.string,
};

MainContent.defaultProps = {
  className: '',
};
