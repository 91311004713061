import React from 'react';
import { Box, Typography, makeStyles } from '@material-ui/core';
import {
  Loader,
  ReportErrorBoundary,
  DonationPerformanceReport,
} from 'components';
import { loadD3Script } from 'lib/utils';
import { mockDonationData } from 'reports';

const useStyles = makeStyles((theme) => ({
  container: {
    padding: `${theme.spacing(2)}px`,
  },
}));

const mockedReport = mockDonationData();

const CampaignReports = () => {
  const styles = useStyles();
  const [hasDependencies, setHasDependencies] = React.useState(false);
  const [hasScriptsError, setHasScriptsError] = React.useState(false);

  async function loadD3() {
    try {
      await loadD3Script();
      setHasDependencies(true);
    } catch (e) {
      setHasScriptsError(true);
      // eslint-disable-next-line no-console
      console.error('load script error', e);
    }
  }

  React.useEffect(() => {
    loadD3();
  }, []);

  return (
    <Box className={styles.container}>
      <Typography>Reports</Typography>

      {!hasScriptsError && !hasDependencies && <Loader isCentered />}
      {/* TODO: show proper message when script error */}
      {/* TODO: add error boundary to catch d3 errors */}
      {hasDependencies && (
        <ReportErrorBoundary>
          <DonationPerformanceReport data={mockedReport.data} />
        </ReportErrorBoundary>
      )}
    </Box>
  );
};

export default CampaignReports;
