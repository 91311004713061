import userActionTypes from 'rdx/actionTypes';

const {
  ORG_INFO_REQUEST,
  ORG_INFO_SUCCESS,
  ORG_INFO_FAILURE,

  CREATE_INTEGRATION_KEY_REQUEST,
  CREATE_INTEGRATION_KEY_SUCCESS,
  CREATE_INTEGRATION_KEY_FAILURE,

  REMOVE_INTEGRATION_KEY_REQUEST,
  REMOVE_INTEGRATION_KEY_SUCCESS,
  REMOVE_INTEGRATION_KEY_FAILURE,

  UPDATE_INTEGRATION_KEY_REQUEST,
  UPDATE_INTEGRATION_KEY_SUCCESS,
  UPDATE_INTEGRATION_KEY_FAILURE,
} = userActionTypes;

const initialState = {
  dataLoaded: false,
  isFetching: false,
  error: null,
  data: {},
  keySaved: false,
};

export function orgInfo(state = initialState, action) {
  switch (action.type) {
    case ORG_INFO_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: null,
      };
    case ORG_INFO_SUCCESS:
      return {
        ...state,
        data: action.data,
        dataLoaded: true,
        isFetching: false,
        error: null,
      };
    case ORG_INFO_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.error,
      };
    case CREATE_INTEGRATION_KEY_REQUEST:
    case REMOVE_INTEGRATION_KEY_REQUEST:
    case UPDATE_INTEGRATION_KEY_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case CREATE_INTEGRATION_KEY_SUCCESS:
    case UPDATE_INTEGRATION_KEY_SUCCESS: {
      const integrations = {};
      Object.keys(action.data).forEach((integration) => {
        integrations[integration] = action.data[integration];
      });
      return {
        ...state,
        isLoading: false,
        keySaved: true,
        data: {
          ...state.data,
          ...integrations,
        },
      };
    }
    case REMOVE_INTEGRATION_KEY_SUCCESS:
      return {
        ...state,
        isLoading: false,
        keySaved: false,
        data: {
          ...state.data,
          [action.data]: {},
        },
      };
    case CREATE_INTEGRATION_KEY_FAILURE:
    case REMOVE_INTEGRATION_KEY_FAILURE:
    case UPDATE_INTEGRATION_KEY_FAILURE:
      return {
        ...state,
        isLoading: false,
        keySaved: false,
        error: action.error,
      };
    default:
      return state;
  }
}
