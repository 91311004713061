import {
  AttributeCorporationIcon,
  AttributeDonorIcon,
  AttributeFoundationIcon,
  AttributePartnerIcon,
  AttributeRecipientIcon,
  BoardMemberIcon,
  MajorDonorIcon,
  RecurringDonorIcon,
  VolunteerIcon,
  MemberIcon,
} from 'lib/configs/donor-icons';
import { translate } from 'lib/intl';
import {
  ConstituentCategories,
  ConstituentRules,
} from 'lib/types/ConstituentFilterPropType';

export const CONSTITUENT_CATEGORIES = 'constituentCategories';
export const CONSTITUENT_RULES = 'constituentRules';
export const CONSTITUENT_UNCATEGORIZED = 'uncategorized';

const ConstituentIcons = [
  {
    icon: BoardMemberIcon,
    group: CONSTITUENT_CATEGORIES,
    value: ConstituentCategories.CATEGORY_BOARD_MEMBER,
    label: translate('CONSTITUENT_BOARD_MEMBER_LABEL'),
  },
  {
    icon: AttributeRecipientIcon,
    group: CONSTITUENT_CATEGORIES,
    value: ConstituentCategories.CATEGORY_RECIPIENT,
    label: translate('CONSTITUENT_RECIPIENT_LABEL'),
  },
  {
    icon: AttributeCorporationIcon,
    group: CONSTITUENT_CATEGORIES,
    value: ConstituentCategories.CATEGORY_CORPORATION,
    label: translate('CONSTITUENT_CORPORATION_LABEL'),
  },
  {
    icon: AttributeDonorIcon,
    group: CONSTITUENT_RULES,
    value: ConstituentRules.IS_DONOR_RULESET,
    label: translate('CONSTITUENT_DONOR_LABEL'),
  },
  {
    icon: MajorDonorIcon,
    group: CONSTITUENT_RULES,
    value: ConstituentRules.IS_MAJOR_DONOR_RULESET,
    label: translate('CONSTITUENT_MAJOR_DONOR_LABEL'),
  },
  {
    icon: RecurringDonorIcon,
    group: CONSTITUENT_RULES,
    value: ConstituentRules.IS_RECURRING_DONOR_RULESET,
    label: translate('CONSTITUENT_RECURRING_LABEL'),
  },
  {
    icon: AttributeFoundationIcon,
    group: CONSTITUENT_CATEGORIES,
    value: ConstituentCategories.CATEGORY_FOUNDATION,
    label: translate('CONSTITUENT_FOUNDATION_LABEL'),
  },
  {
    icon: MemberIcon,
    group: CONSTITUENT_CATEGORIES,
    value: ConstituentCategories.CATEGORY_MEMBER,
    label: translate('CONSTITUENT_MEMBER_LABEL'),
  },
  {
    icon: AttributePartnerIcon,
    group: CONSTITUENT_CATEGORIES,
    value: ConstituentCategories.CATEGORY_PARTNER,
    label: translate('CONSTITUENT_PARTNER_LABEL'),
  },
  {
    icon: VolunteerIcon,
    group: CONSTITUENT_CATEGORIES,
    value: ConstituentCategories.CATEGORY_VOLUNTEER,
    label: translate('CONSTITUENT_VOLUNTEER_LABEL'),
  },
];

export default ConstituentIcons;
