import userActionTypes from 'rdx/actionTypes';
import { addUniqueFeedItems } from 'lib/utils';
import { DEFAULT_PAGE_SIZE } from 'lib/constants/common';

const {
  FEED_REQUEST,
  FEED_SUCCESS,
  FEED_FAILURE,
  FEED_REFRESH_SUCCESS,
  AUTH_SET_ORG_ID_PUB,
} = userActionTypes;

const initialState = {
  isFetching: false,
  error: null,
  activeItemId: null,
  hasNextPage: false,
  pageFrom: 0,
  items: [],
};

export function orgFeed(state = initialState, action) {
  let items;

  switch (action.type) {
    case FEED_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case FEED_SUCCESS:
      items = addUniqueFeedItems(state.items, action.data.feedItems);

      return {
        ...state,
        isFetching: false,
        items,
        pageFrom: action.data.searchParams.pageFrom + DEFAULT_PAGE_SIZE,
        hasNextPage: action.data.totalHits > items.length,
      };
    case FEED_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.error,
      };
    case FEED_REFRESH_SUCCESS:
      items = addUniqueFeedItems(action?.data.feedItems, state.items);

      return {
        ...state,
        isFetching: false,
        items,
      };
    case AUTH_SET_ORG_ID_PUB:
      return initialState;
    default:
      return state;
  }
}
