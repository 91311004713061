import { makeStyles } from '@material-ui/core/styles';
import { ICON_SIZES } from 'lib/constants';

const useStyles = makeStyles((theme) => ({
  mainDialog: {
    '& .MuiDialog-paper': {
      position: 'absolute',
      right: 0,
      bottom: 0,
      margin: 0,
      background: 'transparent',
      boxShadow: 'none',
    },
  },
  contentDialog: {
    '& .MuiDialog-paperFullWidth': {
      width: '530px',
    },
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    margin: `0 ${theme.spacing(9)}px ${theme.spacing(6)}px 0`,
    [theme.breakpoints.down('sm')]: {
      margin: `0 ${theme.spacing(2)}px ${theme.spacing(2)}px 0`,
    },
  },
  item: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    width: '300px',
    paddingTop: theme.spacing(2.5),
  },
  itemTitle: {
    paddingRight: theme.spacing(2.5),
    fontWeight: theme.typography.fontWeightMedium,
    letterSpacing: '.75px',
    textShadow: `0px ${theme.spacing(0.5)}px ${theme.spacing(
      0.5
    )}px rgba(0, 0, 0, 0.25)`,
    color: theme.palette.background.white,
    cursor: 'pointer',
  },
  button: {
    backgroundColor: theme.palette.background.white,
  },
  buttonSmall: {
    backgroundColor: theme.palette.background.white,
    marginRight: theme.spacing(1),
  },
  icon: {
    height: ICON_SIZES.LG,
    width: ICON_SIZES.LG,
  },
}));

export { useStyles };
