import React from 'react';
import { generatePath, Link as RouterLink } from 'react-router-dom';
import ConstituentPropType from 'lib/types/ConstituentPropType';
import { Avatar } from 'components';
import { AUTH_ROUTES } from 'lib/constants';
import { CONSTITUENT_TYPES } from 'lib/constants/constituentConstants';
import { getInitials } from 'lib/utils';

const ConstituentAvatar = ({ constituent }) => {
  const initials = React.useMemo(
    () =>
      getInitials(
        constituent.displayName,
        constituent.constituentType === CONSTITUENT_TYPES.PERSON
      ),
    [constituent]
  );

  return (
    <div className="flex items-center">
      <Avatar src={constituent.avatar}>{initials}</Avatar>
      <div className="ml-2">
        <RouterLink
          to={generatePath(AUTH_ROUTES.CONSTITUENT, {
            constituentId: constituent.constituentId,
          })}
        >
          <div className="type-body2 text-primaryLink-main">
            {constituent.displayName}
          </div>
        </RouterLink>
      </div>
    </div>
  );
};

ConstituentAvatar.propTypes = {
  constituent: ConstituentPropType.isRequired,
};

export default ConstituentAvatar;
