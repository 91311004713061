import React from 'react';
import { shape, string } from 'prop-types';
import { generatePath } from 'react-router-dom';
import { makeStyles } from '@material-ui/core';
import { RouterLink } from 'components';
import { AUTH_ROUTES } from 'lib/constants';
import { CampaignDonationType } from 'lib/configs/donations';

const useStyles = makeStyles((theme) => ({
  link: {
    ...theme.typography.body2,
    fontWeight: theme.typography.fontWeightMedium,
    [theme.breakpoints.down('sm')]: {
      ...theme.typography.body1,
      color: theme.palette.text.primary,
    },
  },
}));

const DonationDonor = ({ data, value }) => {
  const styles = useStyles();

  const { donorId } = data;

  const path = generatePath(AUTH_ROUTES.CONSTITUENT, {
    constituentId: donorId,
  });

  return (
    <RouterLink className={styles.link} to={path}>
      {value}
    </RouterLink>
  );
};

DonationDonor.propTypes = {
  data: shape(CampaignDonationType).isRequired,
  value: string.isRequired,
};

export { DonationDonor };
