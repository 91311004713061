import userActionTypes from 'rdx/actionTypes';
import { showErrorMessage } from 'lib/utils';
import { translate } from 'lib/intl';

const {
  UPDATE_ORG_INFO_REQUEST,
  UPDATE_ORG_INFO_SUCCESS,
  UPDATE_ORG_INFO_FAILURE,
  UPDATE_ORG_INFO_RESET,
} = userActionTypes;

const initialState = {
  dataLoaded: false,
  isFetching: false,
  error: null,
  data: {},
};

export function updatingOrgInfo(state = initialState, action) {
  switch (action.type) {
    case UPDATE_ORG_INFO_REQUEST:
      return {
        ...state,
        dataLoaded: false,
        isFetching: true,
        error: null,
      };
    case UPDATE_ORG_INFO_SUCCESS:
      return {
        ...state,
        data: action.data,
        dataLoaded: true,
        isFetching: false,
        error: null,
      };
    case UPDATE_ORG_INFO_FAILURE:
      showErrorMessage({
        message: translate('ERROR_UPDATING_ORG_INFO'),
      });

      return {
        ...state,
        isFetching: false,
        error: action.error,
      };
    case UPDATE_ORG_INFO_RESET:
      return initialState;
    default:
      return state;
  }
}
