export const DONOR = {
  BACK_TO_MY_DONORS_LABEL: 'Back to My Donors',
  DONOR_EDIT_PROFILE: 'Edit Profile',
  NEW_INTERACTION_REPORT_LABEL: 'New Interaction Report',
  DONOR_KEY_INFO: 'Key Info',
  DONOR_KEY_NOTES: '3 Key Notes',
  DONOR_ASSIGNEE_INFO_TITLE: 'Assigned',
  DONOR_ASSIGNEE_INFO_CHANGE: 'Change',
  DONOR_STAGE_INFO_TITLE: 'Donor Stage',
  DONOR_STAGE_INFO_MOVE: 'Move',
  PERSONAL_INFO_TAB: 'Personal info',
  BUSINESS_INFO_TAB: 'Business info',
  DONOR_HISTORY_TAB: 'Donor History',
  DONOR_NOTE_LABEL: 'Note',
  DONOR_NOTE_PLACEHOLDER_1: 'Goal with this donor',
  DONOR_NOTE_PLACEHOLDER_2: 'Interesting tidbit',
  DONOR_NOTE_PLACEHOLDER_3: 'Things to Avoid',
  DONOR_PERSONAL_INFORMATION: 'Personal Information',
  DONOR_WORK_INFORMATION: 'Work Information',
  DONOR_ADD_WORK_LABEL: 'Add Work Information',
  DONOR_PRIMARY_WORK_LABEL: 'Primary Work',
  DONOR_BUSINESS_INFORMATION: 'Business Information',
  DONOR_COMPANY_INFORMATION: 'Company Information',
  DONOR_HOUSEHOLD_LABEL: 'Household',
  DONOR_ADD_HOUSEHOLD_LABEL: 'Add Household Member',
  DONOR_CONTACTS_LABEL: 'Contact Information',
  DONOR_PRIMARY_CONTACT_LABEL: 'Primary Contact',
  DONOR_ADD_CONTACT_LABEL: 'Add Contact',
  DONOR_AFFILIATIONS_LABEL: 'Affiliations',
  DONOR_ADD_AFFILIATION_LABEL: 'Add Affiliation',
  DONOR_EMPLOYEES_LABEL: 'Employees',
  DONOR_ADD_EMPLOYEE_LABEL: 'Add Employee',
  DONOR_CONNECTIONS_LABEL: 'Connections',
  DONOR_ADD_CONNECTION_LABEL: 'Add Connection',
  DONOR_PRIMARY_CONTACT_INFORMATION: 'Primary Contact Information',
  DONOR_FAMILY_AND_AFFILIATIONS: 'Family and Affiliations',
  DONOR_EMPLOYEES_AND_AFFILIATIONS: 'Employees and Affiliations',
  DONOR_DIVING_SUMMARY: 'Giving Summary',
  EDIT_DONOR_PROFILE_TITLE: 'Edit Donor Profile',
  CREATE_DONOR_PROFILE_TITLE: 'Create Donor Profile',
  FIRST_NAME_LABEL: 'First Name',
  LAST_NAME_LABEL: 'Last Name',
  DISPLAY_NAME_LABEL: 'Display Name',
  EMAIL_ADDRESS_LABEL: 'Email Address',
  WORK_EMAIL_ADDRESS_LABEL: 'Work Email Address',
  PHONE_NUMBER_LABEL: 'Phone Number',
  COUNTRY_LABEL: 'Country',
  COUNTRY_ERROR_MESSAGE: 'Use two-letter country code',
  STREET_ADDRESS_LABEL: 'Street Address',
  APT_LABEL: 'Apt/Suite',
  CITY_LABEL: 'City',
  STATE_LABEL: 'State',
  ZIP_LABEL: 'ZIP',
  BOARD_MEMBER_LABEL: 'Board Member',
  MAJOR_DONOR_LABEL: 'Major Donor',
  RECURRING_LABEL: 'Recurring',
  VOLUNTEER_LABEL: 'Volunteer',
  MEMBER_LABEL: 'Member',
  INSTAGRAM_LABEL: 'Instagram Profile',
  TWITTER_LABEL: 'Twitter Profile',
  LINKEDIN_LABEL: 'LinkedIn Profile',
  FACEBOOK_LABEL: 'Facebook Profile',
  BIRTHDAY_LABEL: 'Birthday',
  BIRTHDAY_PLACEHOLDER: 'Birthday MM/DD/YY',
  EMPLOYER_LABEL: 'Employer',
  EMPLOYEES_LABEL: 'Employees',
  TITLE_LABEL: 'Title',
  TYPE_LABEL: 'Type',
  WEBSITE_LABEL: 'Website',
  SPOUSE_LABEL: 'Spouse',
  FAMILY_MEMBER_LABEL: 'Family Member',
  CONNECTIONS_LABEL: 'Connections',
  COMPANY_NAME_LABEL: 'Company Name',
  COMPANY_INDUSTRY_LABEL: 'Company Industry',
  DONOR_INDUSTRY_LABEL: 'Industry',
  ADD_INDUSTRY: 'Add Industry',
  AFFILIATIONS_LABEL: 'Company Affiliations',
  COMPANY_AFFILIATION_LABEL: 'Company Affiliation',
  PRIMARY_LABEL: 'Primary',
  MOBILE_LABEL: 'Mobile',
  HOME_LABEL: 'Home',
  THREE_KEY_NOTES_LABEL: '3 Key Notes',
  DONOR_FORM_FIX_ERRORS_MESSAGE: 'Fix errors to save the donor',
  EDIT_DONOR_FORM_PERSON_LABEL: 'Person',
  EDIT_DONOR_FORM_ORG_LABEL: 'Organization',
  FRONT_DESK_LABEL: 'Front Desk',
  BILLING_LABEL: 'Billing',
  SALES_LABEL: 'Sales',
  PRIMARY_CONTACT_INFO: 'Primary Contact Information',
  SECONDARY_CONTACT_INFO: 'Secondary Contact Information',
  PRIMARY_CONTACT_NAME: 'Primary Contact Name',
  SECONDARY_CONTACT_NAME: 'Secondary Contact Name',
  ADD_SECONDARY_CONTACT: 'Add Secondary Contact',
  REMOVE_CONTACT: 'Remove Contact',
  DONOR_UPDATED_LABEL: 'Donor Updated!',
  DONOR_UPDATED_MESSAGE: 'Donor was successfully updated',
  DONOR_BOARD_ACTIONS: 'Donor Card Actions',
  DONOR_BOARD_ACTION_INTERACTION: 'New Interaction',
  DONOR_BOARD_ACTION_DONATION: 'New Donation',
  DONOR_BOARD_ACTION_MOVE_CARD: 'Move Card',
  DONOR_BOARD_ACTION_CHANGE_ASSIGNED: 'Change Assigned',
  DONOR_BOARD_ACTION_EDIT_PROFILE: 'Edit Profile',
  DONOR_BOARD_KANBAN_UPDATE_ERROR_GENERIC: 'Error updating kanban donor',
  DONOR_NAME_LABEL: 'Donor Name',
  LAST_INTERACTION_DEFAULT_LABEL: '0 days ago',
  FETCH_DONOR_INFO_ERROR_MESSAGE: 'There was a problem fetching a donor',
  CONNECTION_TYPE_LABEL: 'Connection Type',
  RELATIONSHIP_TYPE_LABEL: 'Relationship',
  DONOR_REMOVE_CONNECTION_ERROR_MESSAGE: 'There was a problem removing {name}',
  DONOR_CREATE_CONNECTION_ERROR_MESSAGE: 'There was a problem creating {name}',
  DONOR_CREATE_UPDATING_ERROR_MESSAGE: 'There was a problem updating {name}',
  CREATE_DONOR_ERROR_MESSAGE: 'There was a problem creating {name}',
  UPDATE_DONOR_ERROR_MESSAGE: 'There was a problem updating {name}',
  INVALID_EMAIL_FORMAT_MESSAGE: 'Invalid email format',
  INVALID_PHONE_FORMAT_MESSAGE: 'Invalid phone format',
  INVALID_URL_FORMAT_MESSAGE: 'Invalid URL format',
  INVALID_DATE_FORMAT_MESSAGE: 'Invalid date format',
  DONOR_FIRST_NAME: 'First Name',
  DONOR_MIDDLE_NAME: 'Middle Name',
  DONOR_LAST_NAME: 'Last Name',
};
