const getFullName = (
  { firstName = '', lastName = '', middleName = '' } = {},
  withMiddleName = false
) => {
  const nameParts = [firstName, lastName];

  if (withMiddleName) {
    // inserts a middle name in the second position
    nameParts.splice(1, 0, middleName);
  }

  return nameParts.reduce((fullName, part) => {
    if (part) {
      return fullName?.length ? `${fullName} ${part}` : part;
    }

    return fullName;
  }, '');
};

export { getFullName };
