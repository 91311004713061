import React from 'react';
import { bool, func, node, string } from 'prop-types';
import { Box, Typography } from '@material-ui/core';
import styled from 'styled-components';
import { Avatar } from 'components/common';
import {
  CustomButton,
  Gap,
  Loader,
  PrimaryCheckbox,
  Visible,
} from 'components';
import { colors } from 'styles/theme';
import { EMPTY_FUNC } from 'lib/constants';
import { CloseIcon } from 'assets/images';
import { isMobileView, skipPassingEvent } from 'lib/utils';
import { useEscHandler } from 'lib/hooks';
import { translate } from 'lib/intl';

const StyledContainer = styled(Box)`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  z-index: -1;
  visibility: hidden;
  opacity: 0;
  transition: opacity 100ms cubic-bezier(0.4, 0, 0.2, 1) 80ms,
    z-index 100ms cubic-bezier(0.4, 0, 0.2, 1) 80ms,
    visibility 100ms cubic-bezier(0.4, 0, 0.2, 1) 80ms;
  visibility: hidden;

  &.open {
    visibility: visible;
    opacity: 1;
    z-index: 1;
  }

  ${({ mobile }) =>
    mobile
      ? `
    position: fixed;
    left: 6px;
    right: 6px;
    top: 6px;
    height: 100%;

    &.open {
      z-index: 1301;
    }
  `
      : ''}

  form {
    padding-right: ${({ mobile }) => (mobile ? '80px' : '90px')};
    padding-top: 20px;
  }

  .MuiFormControl-root {
    margin-top: ${({ mobile }) => (mobile ? '10px' : '0px')};
  }
`;

const ShadowedContainer = styled(Box)`
  background-color: ${colors.white};
  box-shadow: none;
  border-radius: 8px;
  transition: box-shadow 100ms cubic-bezier(0.4, 0, 0.2, 1) 80ms;

  &.open {
    box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.2), 0px 2px 8px rgba(0, 0, 0, 0.12),
      0px 6px 10px rgba(0, 0, 0, 0.14);
  }

  ${({ mobile }) =>
    mobile
      ? `
    height: calc(100% - 12px);
`
      : ''}
`;

const StyledMainContent = styled(Box)`
  overflow-y: auto;
`;

const StyledBottomContent = styled(Box)`
  border-top: 1px solid ${colors.progressLightGrey};
  height: 104px;
`;

const StyledCloseButton = styled(Box)`
  cursor: pointer;
  position: absolute;
  top: 22px;
  right: 16px;
`;

const AvatarContainer = styled(Box)`
  position: relative;
  height: 0px;
`;

const StyledAvatar = styled(Avatar)`
  left: ${({ mobile }) => (mobile ? '12px' : '30px')};
  position: absolute;
  top: ${({ mobile }) => (mobile ? '64px' : '48px')};
`;

/**
  This is a wrapper component for rendering donor forms
  @param {Node} $0.avatar - src
  @param {Node} $0.avatarInitials - a text to show if no avatar is provided
  @param {Node} $0.children - are form inputs, use SectionsGenerator for it
  @param {bool} $0.error - a flag for showing or not errorMessage
  @param {string} $0.errorMessage - an error message to show at the bottom
  @param {bool} $0.isOpen - to understand whether the popup should be open or closed
  @param {bool} $0.isLoading - to show Loader at the bottom instead of buttons
  @param {func} $0.onClose - onClose handler
  @param {func} $0.onSave - onSave handler
  @param {func} $0.onPrimaryChange - on Primary value change handler
  @param {bool} $0.primary - a checkbox to show at the top of the popup
    (used for Contact Information)
  @param {string} $0.primaryLabel - a primary checkbox label
  @param {bool} $0.showAvatar - a checkbox to show or not avatar
  @param {string} $0.title - a title
  @returns {Node} - rendered popup
*/
const ConstituentFormPopup = ({
  avatar,
  avatarInitials,
  children,
  apiError,
  validationError,
  isOpen,
  isLoading,
  onClose,
  onSave,
  onPrimaryChange,
  primary,
  primaryLabel,
  showAvatar,
  title,
}) => {
  const isMobile = isMobileView();

  useEscHandler(onClose);

  return (
    <StyledContainer className={isOpen ? 'open' : ''} mobile={Number(isMobile)}>
      <ShadowedContainer
        className={isOpen ? 'open' : ''}
        display="flex"
        flexDirection="column"
        mobile={Number(isMobile)}
        mb={isMobile ? 0 : 1}
      >
        <StyledMainContent flex="1" py={isMobile ? 2 : 4}>
          <Visible when={Boolean(showAvatar)}>
            <AvatarContainer>
              <StyledAvatar
                mobile={Number(isMobile)}
                src={avatar}
                size={isMobile ? '40px' : '64px'}
              >
                {avatarInitials}
              </StyledAvatar>
            </AvatarContainer>
          </Visible>
          <Box
            alignItems="center"
            display="flex"
            justifyContent={isMobile ? 'flex-start' : 'space-between'}
            px={isMobile ? 2 : 4}
          >
            <Typography variant="subtitle1">{title}</Typography>
            <Visible when={Boolean(primaryLabel)}>
              <PrimaryCheckbox
                primary={primary}
                onChange={onPrimaryChange}
                label={isMobile ? '' : primaryLabel}
                ml={isMobile ? '4px' : 0}
                checkboxProps={{
                  name: 'primary',
                }}
              />
            </Visible>
            <Visible when={isMobile}>
              <StyledCloseButton
                alignItems="center"
                display="flex"
                onClick={onClose}
              >
                <CloseIcon />
              </StyledCloseButton>
            </Visible>
          </Box>
          <form onSubmit={onSave}>{children}</form>
        </StyledMainContent>
        <StyledBottomContent
          display="flex"
          alignItems="center"
          justifyContent={isLoading ? 'center' : 'flex-start'}
          px={2}
          py={3}
        >
          <Visible fallback={<Loader />} when={!isLoading}>
            <Visible when={apiError || validationError}>
              <Box px={1}>
                <Typography color="error">
                  {translate(
                    apiError ? 'API_DEFAULT_ERROR_2' : 'FORM_VALIDATION_ERROR'
                  )}
                </Typography>
              </Box>
            </Visible>
            <Box
              display="flex"
              flex="1"
              flexDirection="row"
              justifyContent="flex-end"
            >
              <CustomButton
                buttonType="link"
                color="primary"
                dataTestId="donor-form-popup-cancel-button"
                label={translate('CANCEL')}
                onClick={skipPassingEvent(onClose)}
              />
              <Gap />
              <CustomButton
                buttonType="primary"
                dataTestId="donor-form-popup-save-button"
                label={translate('SAVE')}
                onClick={onSave}
              />
            </Box>
          </Visible>
        </StyledBottomContent>
      </ShadowedContainer>
    </StyledContainer>
  );
};

ConstituentFormPopup.propTypes = {
  avatar: string,
  avatarInitials: string,
  children: node,
  apiError: bool,
  validationError: bool,
  isOpen: bool.isRequired,
  isLoading: bool,
  onClose: func.isRequired,
  onSave: func.isRequired,
  onPrimaryChange: func,
  primaryLabel: string,
  primary: bool,
  showAvatar: bool,
  title: string.isRequired,
};

ConstituentFormPopup.defaultProps = {
  avatar: '',
  avatarInitials: '',
  children: null,
  apiError: false,
  validationError: false,
  isLoading: false,
  onPrimaryChange: EMPTY_FUNC,
  primaryLabel: '',
  primary: false,
  showAvatar: false,
};

export default ConstituentFormPopup;
