import React, { useCallback, useState } from 'react';
import { Box, Checkbox, makeStyles } from '@material-ui/core';
import { isMobileView } from 'lib/utils';
import { translate } from 'lib/intl';
import { func, shape } from 'prop-types';
import {
  BoardMemberIcon,
  MajorDonorIcon,
  RecurringDonorIcon,
  VolunteerIcon,
  MemberIcon,
} from '../../lib/configs/donor-icons';

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    paddingTop: '24px',
  },
  label: {
    display: 'flex',
    alignItems: 'center',
    paddingRight: '8px',
  },
}));

const EditDonorAttributes = ({ data, updateData }) => {
  const isMobile = isMobileView();
  const styles = useStyles();

  const {
    donorTypeBoardMember,
    donorTypeMajor,
    donorTypeRecurring,
    donorTypeVolunteer,
    donorTypeMember,
  } = data?.summary;
  const [checkedItems, setCheckedItems] = useState({
    donorTypeBoardMember,
    donorTypeMajor,
    donorTypeRecurring,
    donorTypeVolunteer,
    donorTypeMember,
  });

  const onChange = useCallback(
    ({ target }) => {
      setCheckedItems({
        ...checkedItems,
        [target.name]: target.checked,
      });
      updateData({ ...data, [target.name]: target.checked }, target.name);
      updateData(
        {
          ...data,
          summary: { ...data.summary, [target.name]: target.checked },
        },
        target.name
      );
    },
    [data, updateData]
  );

  const checkboxes = [
    {
      name: 'donorTypeBoardMember',
      label: translate('BOARD_MEMBER_LABEL'),
      icon: BoardMemberIcon,
    },
    {
      name: 'donorTypeMajor',
      label: translate('MAJOR_DONOR_LABEL'),
      icon: MajorDonorIcon,
    },
    {
      name: 'donorTypeRecurring',
      label: translate('RECURRING_LABEL'),
      icon: RecurringDonorIcon,
    },
    {
      name: 'donorTypeVolunteer',
      label: translate('VOLUNTEER_LABEL'),
      icon: VolunteerIcon,
    },
    {
      name: 'donorTypeMember',
      label: translate('MEMBER_LABEL'),
      icon: MemberIcon,
    },
  ];

  return (
    <Box
      data-testid="donor-attributes"
      className={styles.container}
      flexDirection={isMobile ? 'column' : 'row'}
    >
      {checkboxes.map((item) => (
        <label
          className={styles.label}
          htmlFor={`donor-attributes-checkbox-${item.name}`}
          key={item.name}
        >
          <Checkbox
            data-testid={`donor-attributes-checkbox-${item.name}`}
            checked={!!checkedItems[item.name]}
            color="primary"
            id={`donor-attributes-checkbox-${item.name}`}
            name={item.name}
            onChange={onChange}
          />
          <span className="mr-1">{item.icon}</span>
          {item.label}
        </label>
      ))}
    </Box>
  );
};

EditDonorAttributes.propTypes = {
  data: shape({ summary: shape({}) }).isRequired,
  updateData: func.isRequired,
};

export { EditDonorAttributes };
