import { translate } from 'lib/intl';

const GOALS_DURATION_FIELD_CONFIG_CUSTOM_VALUE = 'custom';

const DEFAULT_GOALS_DURATION_ENUM = 'yearly';

const GOALS_DURATION_FIELD_CONFIG = [
  {
    value: 'monthly',
    text: translate('GOALS_DURATION_MONTHLY'),
  },
  {
    value: 'quarterly',
    text: translate('GOALS_DURATION_QUARTERLY'),
  },
  {
    value: 'yearly',
    text: translate('GOALS_DURATION_YEARLY'),
  },
  {
    value: GOALS_DURATION_FIELD_CONFIG_CUSTOM_VALUE,
    text: translate('GOALS_DURATION_CUSTOM'),
  },
];

export {
  GOALS_DURATION_FIELD_CONFIG_CUSTOM_VALUE,
  DEFAULT_GOALS_DURATION_ENUM,
  GOALS_DURATION_FIELD_CONFIG,
};
