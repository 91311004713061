import { upperCase } from 'voca';
import { translate } from 'lib/intl';
import { formatCurrency, formatDate, isDonationTimeType } from 'lib/utils';
import { CUSTOM_DATE_FORMAT } from 'lib/constants';
import {
  DONATION_PAYMENT_METHODS,
  DONATION_TYPES,
  DONATION_VOLUNTEER_CONFIG,
} from 'lib/configs/donations';

const mapDonationsForExport = (donations) =>
  donations.map(
    ({
      amount,
      donationDateString,
      donorDisplayName,
      donationTypeEnum,
      paymentTypeEnum,
      time,
      timeCategory,
    }) => {
      const isTimeType = isDonationTimeType({ donationTypeEnum });

      return {
        amount: isTimeType
          ? `${time} ${translate('DONATION_HOURS')}`
          : formatCurrency(amount, true),
        method: isTimeType
          ? translate('DONATION_TIME')
          : DONATION_PAYMENT_METHODS.find(
              ({ value }) => value === paymentTypeEnum
            )?.text,
        donorName: donorDisplayName,
        type: isTimeType
          ? DONATION_VOLUNTEER_CONFIG.find(
              ({ value }) => value === timeCategory
            ).text
          : DONATION_TYPES[upperCase(donationTypeEnum)].displayText,
        // TODO: add Source once available
        // source: '',
        date: formatDate(donationDateString, CUSTOM_DATE_FORMAT),
      };
    }
  );

export { mapDonationsForExport };
