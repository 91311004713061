import userActionTypes from 'rdx/actionTypes';

const initialState = {
  isFetching: false,
  error: null,
};

export function resendVerification(state = initialState, action) {
  switch (action.type) {
    case userActionTypes.RESEND_VERIFICATION_EMAIL_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: null,
      };
    case userActionTypes.RESEND_VERIFICATION_EMAIL_SUCCESS:
      return {
        ...state,
        isFetching: false,
        error: null,
      };
    case userActionTypes.RESEND_VERIFICATION_EMAIL_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.error,
      };
    default:
      return state;
  }
}
