import userActionTypes from 'rdx/actionTypes';

const {
  USERS_REQUEST,
  USERS_SUCCESS,
  USERS_FAILURE,
  USERS_UPDATE_INFO_REQUEST,
  USERS_UPDATE_INFO_SUCCESS,
  USERS_UPDATE_INFO_FAILURE,
} = userActionTypes;

const initialState = {
  isFetching: false,
  isUpdating: false,
  error: null,
  updateError: null,
  visits: {},
  goals: [],
  roles: [],
  user: {},
};

let baseUserData;

export function user(state = initialState, action) {
  switch (action.type) {
    case USERS_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: null,
      };
    case USERS_SUCCESS:
      baseUserData = action?.data?.user;

      return {
        ...state,
        isFetching: false,
        visits: baseUserData?.visits,
        goals: baseUserData?.goals,
        roles: baseUserData?.roles,
        user: {
          ...baseUserData,
        },
      };
    case USERS_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.error,
      };
    case USERS_UPDATE_INFO_REQUEST:
      return {
        ...state,
        isUpdating: true,
        updateError: null,
      };
    case USERS_UPDATE_INFO_SUCCESS:
      baseUserData = action?.data?.user;

      return {
        ...state,
        isUpdating: false,
        user: {
          ...baseUserData,
        },
      };
    case USERS_UPDATE_INFO_FAILURE:
      return {
        ...state,
        isUpdating: false,
        updateError: action.error.message,
      };
    default:
      return state;
  }
}
