import React from 'react';
import { bool, string } from 'prop-types';
import classNames from 'classnames';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import makeStyles from '@material-ui/styles/makeStyles';
import { LOADER_SIZES } from 'lib/constants/common';

const useStyles = makeStyles(() => ({
  centeredInContent: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
  },
}));

const Loader = ({ className, size, isVisible, isCentered, ...rest }) => {
  if (!isVisible) {
    return null;
  }

  const classes = useStyles();

  return (
    <Box
      className={
        isCentered
          ? classNames(classes.centeredInContent, className)
          : className
      }
      {...rest}
    >
      <CircularProgress size={size} data-testid="loader" color="primary" />
    </Box>
  );
};

Loader.propTypes = {
  className: string,
  isVisible: bool,
  size: string,
  isCentered: bool,
};

Loader.defaultProps = {
  className: '',
  isVisible: true,
  size: LOADER_SIZES.LG,
  isCentered: false,
};

export { Loader };
