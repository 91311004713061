import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { arrayOf, func, string } from 'prop-types';
import { Box, MenuItem, Select, Typography } from '@material-ui/core';
import {
  formatIncompletePhoneNumber,
  getCountryCallingCode,
  parseIncompletePhoneNumber,
  parseDigits,
} from 'libphonenumber-js';
import styled from 'styled-components';
import {
  DonorType,
  isMobileView,
  formatDialCode,
  getPhoneInputCursorPos,
  getNumberWithoutDialCode,
  getPhoneValue,
  isPhoneValueLengthValid,
  isValidPhoneNumber,
} from 'lib/utils';
import { translate } from 'lib/intl';
import { PHONE_NUMBERS_CONFIG, PHONE_NUMBERS_CONFIG_VALUES } from 'lib/configs';
import { PHONE_NUMBER_LENGTH, AREA_CODE_LENGTH } from 'lib/constants';
import { Gap } from 'components';
import { colors } from 'styles/theme';
import { DonorTextInput } from './donor-text-input';

const StyledSelect = styled(Select)`
  height: fit-content;
  position: relative;
  top: ${({ mobile }) => (mobile ? '32px' : '24px')};

  &:before,
  &:after {
    display: none;
  }

  .MuiSelect-root {
    padding-bottom: 0px;
    padding-top: 0px;
  }

  .MuiSelect-select.MuiSelect-select:focus {
    background-color: ${colors.white};
  }
`;

const TextInputWrapper = styled(Box)`
  .MuiInputBase-input {
    padding-left: 24px;
  }

  .MuiInputLabel-formControl:not(.MuiInputLabel-shrink) {
    transform: translate(24px, 30px) scale(1);
  }
`;

const DialCodeText = styled(Box)`
  font-size: 16px;
  position: absolute;
  bottom: 3px;
`;

const renderSelectedDialCodeItem = (selectedConfigId) => {
  const { flag: FlagIcon } = PHONE_NUMBERS_CONFIG[selectedConfigId];

  return (
    <Box px="4px">
      <FlagIcon />
    </Box>
  );
};

// TODO: revisit the logic once we support more than one Country code

const EditDonorPhoneInput = ({ data, errors, path, setErrors, updateData }) => {
  const [countryCodeId, setCountryCodeId] = useState(
    PHONE_NUMBERS_CONFIG_VALUES[0].id
  );
  const [cursorPos, setCursorPos] = useState();
  const inputRef = useRef();

  useEffect(() => {
    if (inputRef && cursorPos) {
      inputRef.current.selectionEnd = cursorPos;
      inputRef.current.selectionStart = cursorPos;
    }
  }, [cursorPos]);

  const handleCountryCodeChange = useCallback(({ target: { value } }) => {
    setCountryCodeId(value);
  }, []);

  const dialCode = getCountryCallingCode(countryCodeId);

  const mapPhoneNumber = useCallback(
    (isUpdating = true) =>
      (value, _prevValue, target) => {
        setCursorPos(getPhoneInputCursorPos(target?.selectionEnd));

        let number = value?.trim() || '';

        // makes sure not to add prefix in case of an empty value
        // necessary for removing the field
        if (isUpdating && !number) {
          return number;
        }

        number = getPhoneValue(getNumberWithoutDialCode(number, dialCode));

        // Prevent too many phone characters from being input
        // Strip the input character from the input if it is over the phone length limit
        if (number && !isPhoneValueLengthValid(number)) {
          number = number.slice(0, PHONE_NUMBER_LENGTH);
        }

        const prefix = isUpdating ? formatDialCode(dialCode) : '';

        return parseDigits(number).length === AREA_CODE_LENGTH
          ? `${prefix}${parseIncompletePhoneNumber(number)}`
          : `${prefix}${formatIncompletePhoneNumber(number, countryCodeId)}`;
      },
    [dialCode]
  );

  const isMobile = isMobileView();

  const PhoneInputMenuItems = useMemo(
    () =>
      PHONE_NUMBERS_CONFIG_VALUES?.map(({ id, flag: FlagIcon, label }) => (
        <MenuItem key={id} value={id}>
          <FlagIcon />
          <Gap />
          <Box display="flex" flex="1" justifyContent="space-between">
            <Typography color="textPrimary">{label}</Typography>
            <Gap />
            <Typography color="textSecondary">
              {formatDialCode(getCountryCallingCode(id))}
            </Typography>
          </Box>
        </MenuItem>
      )),
    []
  );

  const InputPrefix = useMemo(
    () => (
      <DialCodeText color="textSecondary">
        {formatDialCode(dialCode)}
      </DialCodeText>
    ),
    [dialCode]
  );

  return (
    <Box display="flex" width="100%">
      <StyledSelect
        data-testid="phone-input-select"
        defaultValue=""
        id="phone-input-select"
        mobile={Number(isMobile)}
        onChange={handleCountryCodeChange}
        renderValue={renderSelectedDialCodeItem}
        value={countryCodeId}
      >
        {PhoneInputMenuItems}
      </StyledSelect>
      <TextInputWrapper width="100%">
        <DonorTextInput
          data={data}
          errorMessage={translate('INVALID_PHONE_FORMAT_MESSAGE')}
          errors={errors}
          inputRef={inputRef}
          label={translate('PHONE_NUMBER_LABEL')}
          newValueMapper={mapPhoneNumber()}
          path={path}
          prefix={InputPrefix}
          setErrors={setErrors}
          updateData={updateData}
          validate={(value) => isValidPhoneNumber(value, dialCode)}
          valueMapper={mapPhoneNumber(false)}
        />
      </TextInputWrapper>
    </Box>
  );
};

EditDonorPhoneInput.propTypes = {
  data: DonorType.isRequired,
  errors: arrayOf(string).isRequired,
  path: string.isRequired,
  setErrors: func.isRequired,
  updateData: func.isRequired,
};

export { EditDonorPhoneInput };
