import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';
import { CircularProgress, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import { authNavLinkStyle } from 'components/auth/authStyles';
import { translate } from 'lib/intl';
import { PUBLIC_ROUTES } from 'lib/constants';

const ResendVerificationLink = ({
  resendVerificationEmail,
  isFetching,
  usernameToReset,
  error,
}) => {
  const [hasRequestedEmail, setHasRequestedEmail] = useState(false);

  // If no username provided, redirect to the login page
  if (!usernameToReset) {
    return <Redirect to={PUBLIC_ROUTES.LOGIN} />;
  }

  if (error && hasRequestedEmail) {
    return <Redirect to={PUBLIC_ROUTES.VERIFICATION_ERROR} />;
  }

  if (hasRequestedEmail) {
    return (
      <Typography variant="body1">
        {translate('CHECK_EMAIL_VERIFICATION_LINK')}
      </Typography>
    );
  }

  if (isFetching) {
    return (
      <CircularProgress
        color="primary"
        style={{ height: '54px', width: '54px' }}
      />
    );
  }

  const sendEmail = (username) => {
    setHasRequestedEmail(true);
    resendVerificationEmail(username);
  };

  return (
    <a
      data-testid="resend-email-verification"
      role="button"
      disabled={isFetching}
      onClick={() => sendEmail(usernameToReset)}
      onKeyDown={() => sendEmail(usernameToReset)}
      tabIndex={0}
      style={authNavLinkStyle}
    >
      {translate('RESENT_VERIFICATION_EMAIL')}
    </a>
  );
};

ResendVerificationLink.propTypes = {
  isFetching: PropTypes.bool,
  error: PropTypes.shape({ message: {} }),
  usernameToReset: PropTypes.string,
  resendVerificationEmail: PropTypes.func.isRequired,
};

ResendVerificationLink.defaultProps = {
  isFetching: false,
  error: null,
  usernameToReset: null,
};

export { ResendVerificationLink };
