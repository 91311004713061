import { memo } from 'react';
import { node, bool } from 'prop-types';

/**
 * Conditionally show children, with an optional fallback component.
 * @param {React.Node} children - child component(s).
 * @param {JSX.Element} fallback - Singleton fallback component.
 * @param {Any} when - Condition to be met in order for children to show..
 * @returns {React.Node|Null} children, fallback, or null
 */
const Visible = memo(function Visible({ children, fallback, when }) {
  if (when) {
    return children;
    // eslint-disable-next-line no-else-return
  } else if (fallback) {
    return fallback;
  }

  return null;
});

Visible.propTypes = {
  children: node,
  fallback: node,
  when: bool.isRequired,
};

Visible.defaultProps = {
  children: null,
  fallback: null,
};

export { Visible };
