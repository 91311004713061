/**
  Focuses and scrolls to a form field
  @param {string} fieldToFocus value
  @param {number} timeout value
*/
const focusFormField = (fieldToFocus, timeout = 400) => {
  if (!fieldToFocus) {
    return;
  }

  setTimeout(() => {
    const field = document.getElementById(fieldToFocus);
    const inputs = field?.querySelectorAll('input.MuiInputBase-input');
    const inputToFocus = inputs?.length && inputs[0];

    if (inputToFocus) {
      inputToFocus.scrollIntoView({ behavior: 'smooth', block: 'center' });
      inputToFocus.focus();
    }
  }, timeout);
};

export { focusFormField };
