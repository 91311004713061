import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography, Box, FormHelperText } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { FormProvider, useForm } from 'react-hook-form';
import { userActions } from 'rdx/actions';
import { connect } from 'react-redux';
import queryString from 'query-string';
import { translate } from 'lib/intl';
import { AuthActionButton, Visible, PasswordForm } from 'components';
import { PUBLIC_ROUTES } from 'lib/constants';

const { RESET_PASSWORD_SET_PASSWORD_SUCCESS } = PUBLIC_ROUTES;

const ResetPasswordSubmitForm = ({
  isFetching,
  resetPasswordSubmitAction,
  isResetSubmitRequestSuccessful,
  resetPasswordResetSubmitState,
  error,
  location,
}) => {
  const history = useHistory();
  const formMethods = useForm();
  const { handleSubmit } = formMethods;

  React.useEffect(() => {
    if (isResetSubmitRequestSuccessful) {
      resetPasswordResetSubmitState();
      history.push(RESET_PASSWORD_SET_PASSWORD_SUCCESS);
    }
  }, [isResetSubmitRequestSuccessful]);

  const params = queryString.parse(location?.search);
  const code = params.reset_code;
  const username = params.email;

  return (
    <FormProvider {...formMethods}>
      <Box display="flex" flexDirection="column">
        <Box pb={4} display="flex">
          <Typography variant="h5">
            {translate('RESET_PASSWORD_CHOOSE_NEW')}
          </Typography>
        </Box>
        <Box display="flex" flexDirection="column">
          <form
            onSubmit={handleSubmit((data) => {
              const { password: enteredPassword } = data;

              return resetPasswordSubmitAction(username, code, enteredPassword);
            })}
          >
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <PasswordForm variant="outlined" />
              </Grid>
            </Grid>
            <Visible when={!!error}>
              <Grid container item xs={12}>
                <FormHelperText
                  error={!!error}
                  style={{
                    textAlign: 'center',
                    marginTop: '20px',
                    width: '100%',
                  }}
                >
                  {error?.message}
                </FormHelperText>
              </Grid>
            </Visible>
            <AuthActionButton
              isFetching={isFetching}
              text={translate('SUBMIT')}
            />
          </form>
        </Box>
      </Box>
    </FormProvider>
  );
};

ResetPasswordSubmitForm.propTypes = {
  isFetching: PropTypes.bool.isRequired,
  resetPasswordSubmitAction: PropTypes.func.isRequired,
  resetPasswordResetSubmitState: PropTypes.func.isRequired,
  isResetSubmitRequestSuccessful: PropTypes.bool.isRequired,
  error: PropTypes.shape({ code: {}, message: {} }),
  location: PropTypes.shape({
    search: PropTypes.string.isRequired,
  }).isRequired,
};

ResetPasswordSubmitForm.defaultProps = {
  error: null,
};

const mapStateToProps = (state) => {
  const { isFetching, error, isResetSubmitRequestSuccessful } =
    state.resetPasswordSubmit;

  return { isFetching, error, isResetSubmitRequestSuccessful };
};

const actionCreators = {
  resetPasswordSubmitAction: userActions.resetPasswordSubmit,
  resetPasswordResetSubmitState: userActions.resetPasswordResetSubmitState,
};

const connectedResetPasswordSubmitForm = connect(
  mapStateToProps,
  actionCreators
)(ResetPasswordSubmitForm);

export { connectedResetPasswordSubmitForm as ResetPasswordSubmitForm };
