import React from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import { ControlledCurrencyInput } from 'components/common/form';
import { translate } from 'lib/intl';

export default function ReportDonationFilter({
  control,
  errors,
  trigger,
  watch,
}) {
  const watchMinDonation = watch('minDonation');
  const watchMaxDonation = watch('maxDonation');

  const validate = React.useCallback(() => {
    if (!watchMinDonation || !watchMaxDonation) {
      return;
    }

    const minNumber = parseInt(watchMinDonation.replace('$', ''), 10);
    const maxNumber = parseInt(watchMaxDonation.replace('$', ''), 10);

    // eslint-disable-next-line consistent-return
    return minNumber > maxNumber
      ? translate('ERRORS_INVALID_MIN_DONATION_LT_MAX_DONATION')
      : undefined;
  }, [watchMinDonation, watchMaxDonation]);

  React.useEffect(() => {
    if (watchMinDonation && watchMaxDonation) {
      trigger(['maxDonation', 'minDonation']);
    }
  }, [watchMinDonation, watchMaxDonation]);

  const rules = {
    validate: {
      minGTMax: validate,
    },
  };

  return (
    <FormControl fullWidth margin="dense">
      <Box display="flex" flexDirection="row">
        <Box mr={1}>
          <ControlledCurrencyInput
            control={control}
            defaultValue={watchMinDonation}
            label={translate('FORMS_MIN_DONATION_LABEL')}
            name="minDonation"
            rules={rules}
          />
        </Box>
        <Box ml={1}>
          <ControlledCurrencyInput
            control={control}
            defaultValue={watchMaxDonation}
            label={translate('FORMS_MAX_DONATION_LABEL')}
            name="maxDonation"
            rules={rules}
          />
        </Box>
      </Box>

      <FormHelperText error={!!errors?.minDonation?.message}>
        {errors?.minDonation?.message}
      </FormHelperText>
    </FormControl>
  );
}

ReportDonationFilter.propTypes = {
  control: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  trigger: PropTypes.func.isRequired,
  watch: PropTypes.func.isRequired,
};
