import userActionTypes from 'rdx/actionTypes';

const initialState = {
  isLoading: false,
  onSuccess: false,
  errors: null,
};

const {
  DONATION_CREATE_REQUEST,
  DONATION_CREATE_SUCCESS,
  DONATION_CREATE_FAILURE,
  DONATION_CREATE_RESET,
} = userActionTypes;

export function newDonation(state = initialState, action) {
  switch (action.type) {
    case DONATION_CREATE_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case DONATION_CREATE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        onSuccess: true,
      };
    case DONATION_CREATE_FAILURE:
      return {
        ...state,
        isLoading: false,
        errors: action.error,
      };
    case DONATION_CREATE_RESET:
      return {
        ...state,
        onSuccess: false,
        errors: null,
      };
    default:
      return state;
  }
}
