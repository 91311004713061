import React from 'react';
import PropTypes from 'prop-types';
import ClearIcon from '@material-ui/icons/Clear';
import CheckIcon from '@material-ui/icons/Check';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  icon: {
    fontSize: '1rem',
    paddingRight: theme.spacing(0.5),
    color({ hasError }) {
      return hasError && theme.palette.error.main;
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: theme.spacing(2.125),
    },
  },
  text: {
    [theme.breakpoints.down('xs')]: {
      fontSize: theme.spacing(1.5),
    },
  },
  container: {
    display: 'flex',
    flexWrap: 'nowrap',
    alignItems: 'center',
  },
}));

export default function PasswordValidationCase({
  hasPasswordError,
  isValid,
  text,
}) {
  /**
   * hasPasswordError refers to the validation message that comes from when the
   * input has triggered the validation on blur. inValid refers to the specific
   * condition that this component itself is concerned about. It's possible for
   * isValid to be true and hasPasswordError to be false. We want to communicate
   * that the specific condition might be invalid, but we only want to show the
   * full error state (both the check and the accompanying text are red) when
   * hasPasswordError is true
   */
  const hasError = hasPasswordError && !isValid;

  const classes = useStyles({
    hasError,
  });

  let icon;

  if (isValid === null) {
    // Pristine state
    icon = <ClearIcon color="disabled" className={classes.icon} />;
  } else if (isValid) {
    icon = (
      <CheckIcon
        data-testid="password-condition-success"
        color="primary"
        className={classes.icon}
      />
    );
  } else if (!isValid) {
    icon = (
      <ClearIcon
        data-testid="password-condition-error"
        className={classes.icon}
      />
    );
  }

  return (
    <Grid className={classes.container} item container xs={6}>
      {icon}
      <Typography
        variant="caption"
        className={classes.text}
        {...(hasError && { color: 'error' })}
      >
        {text}
      </Typography>
    </Grid>
  );
}

PasswordValidationCase.propTypes = {
  hasPasswordError: PropTypes.bool.isRequired,
  isValid: PropTypes.bool,
  text: PropTypes.string.isRequired,
};

PasswordValidationCase.defaultProps = {
  isValid: null,
};
