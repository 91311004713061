import PropTypes from 'prop-types';
import { values } from 'rambdax';
import {
  ADDRESS_TYPE,
  EMAIL_TYPE,
  PHONE_TYPE,
  DonorWorkType,
  DonorUserType,
  SocialTypes,
} from 'lib/utils/custom-prop-types';
import { CONSTITUENT_TYPES } from 'lib/constants/constituentConstants';

export const constituentCategoryPropType = PropTypes.shape({
  categoryBoardMember: PropTypes.bool,
  categoryMajor: PropTypes.bool,
  categoryMember: PropTypes.bool,
  categoryRecurring: PropTypes.bool,
  categoryVolunteer: PropTypes.bool,
});

export const CONSTITUENT_CONNECTION_TYPES = {
  AFFILIATIONS: 'affiliations',
  EMPLOYEES: 'employees',
  CONNECTIONS: 'connections',
  HOUSEHOLD: 'household',
  CONTACTS: 'contacts',
};

export const CONSTITUENT_CONNECTION_SINGULAR_TYPES = {
  AFFILIATION: 'affiliation',
  EMPLOYEE: 'employee',
  CONNECTION: 'connection',
  CONTACT: 'contact',
};

export const CONSTITUENT_SECTION_TYPES = {
  AFFILIATIONS: CONSTITUENT_CONNECTION_TYPES.AFFILIATIONS,
  EMPLOYEES: CONSTITUENT_CONNECTION_TYPES.EMPLOYEES,
  CONNECTIONS: CONSTITUENT_CONNECTION_TYPES.CONNECTIONS,
  HOUSEHOLD: CONSTITUENT_CONNECTION_TYPES.HOUSEHOLD,
  PERSONAL_INFORMATION: 'personalInformation',
  COMPANY_INFORMATION: 'companyInformation',
  CONTACT_INFORMATION: 'contactInformation',
  WORK_INFORMATION: 'workInformation',
  GIVING_SUMMARY: 'givingSummary',
};

export const ConstituentTypes = PropTypes.oneOf(values(CONSTITUENT_TYPES));
export const ConstituentSectionTypes = PropTypes.oneOf(
  values(CONSTITUENT_SECTION_TYPES)
);

export const ConstituentType = PropTypes.shape({
  avatar: PropTypes.string,
  work: PropTypes.arrayOf(PropTypes.shape(DonorWorkType)),
  household: PropTypes.arrayOf(PropTypes.shape(DonorUserType)),
  affiliations: PropTypes.arrayOf(PropTypes.shape(DonorWorkType)),
  connections: PropTypes.arrayOf(PropTypes.shape(DonorUserType)),
  summary: PropTypes.shape({
    donorType: ConstituentTypes,
    created: PropTypes.string,
    primaryEmail: PropTypes.string,
    deleted: PropTypes.bool,
    displayName: PropTypes.string,
    ownerOrgId: PropTypes.string,
    primaryPhone: PropTypes.string,
    donorId: PropTypes.string,
  }),
  lastName: PropTypes.string,
  addresses: PropTypes.arrayOf(PropTypes.shape(ADDRESS_TYPE)),
  emails: PropTypes.arrayOf(PropTypes.shape(EMAIL_TYPE)),
  donorType: ConstituentTypes,
  created: PropTypes.string,
  primaryEmail: PropTypes.string,
  deleted: PropTypes.bool,
  displayName: PropTypes.string,
  phones: PropTypes.arrayOf(PropTypes.shape(PHONE_TYPE)),
  ownerOrgId: PropTypes.string,
  updated: PropTypes.string,
  firstName: PropTypes.string,
  primaryPhone: PropTypes.string,
  note1: PropTypes.string,
  note2: PropTypes.string,
  note3: PropTypes.string,
  donorId: PropTypes.string,
  acquaintances: PropTypes.arrayOf(PropTypes.shape(DonorUserType)),
  contacts: PropTypes.arrayOf(PropTypes.shape(DonorUserType)),
  employees: PropTypes.arrayOf(PropTypes.shape(DonorUserType)),
  planned: PropTypes.number,
  referredBy: PropTypes.shape({
    displayName: PropTypes.string,
    userId: PropTypes.string,
  }),
  lastDonationDate: PropTypes.string,
  lifetimeCount: PropTypes.number,
  lifetimeSum: PropTypes.number,
  lastYearSum: PropTypes.number,
  thisYearCount: PropTypes.number,
  thisYearSum: PropTypes.number,
  fromN: PropTypes.number,
  toN: PropTypes.number,
  ...SocialTypes,
});

export default {
  CONSTITUENT_CONNECTION_TYPES,
  CONSTITUENT_CONNECTION_SINGULAR_TYPES,
  CONSTITUENT_SECTION_TYPES,
  ConstituentType,
  ConstituentSectionTypes,
  constituentCategoryPropType,
};
