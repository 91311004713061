import { AUTH_ROUTES, USER_VIEW_ENUMS } from 'lib/constants';
import { translate } from 'lib/intl';
import {
  homeIcon,
  donorIcon,
  campaignIcon,
  LockedWithPen,
  reportsIcon,
  settingsIcon,
  IntegrationsIcon,
  constituentsIcon,
} from 'assets/images';
import {
  FEATURE_FLAG_REPORTS,
  FEATURE_FLAG_INTEGRATIONS,
} from 'lib/constants/featureFlags';

const {
  ADMIN,
  LANDING,
  DONORS,
  CAMPAIGNS,
  REPORTS,
  COMPANY,
  INTEGRATIONS,
  CONSTITUENTS,
} = AUTH_ROUTES;

const UPPER_NAV_ITEMS = [
  {
    navRoute: LANDING,
    exact: true,
    icon: homeIcon,
    iconAlt: 'Home Icon',
    itemText: translate('HOME_NAV_LABEL'),
  },
  {
    navRoute: DONORS,
    icon: donorIcon,
    iconAlt: 'Donor Icon',
    itemText: translate('DONORS_NAV_LABEL'),
  },
  {
    navRoute: CONSTITUENTS,
    icon: constituentsIcon,
    iconAlt: 'Constituents Icon',
    itemText: translate('CONSTITUENTS_NAV_LABEL'),
  },
  {
    navRoute: CAMPAIGNS,
    icon: campaignIcon,
    iconAlt: 'Campaign Icon',
    itemText: translate('CAMPAIGNS_NAV_LABEL'),
  },
  {
    navRoute: REPORTS,
    featureFlag: FEATURE_FLAG_REPORTS,
    icon: reportsIcon,
    iconAlt: 'Report Icon',
    itemText: translate('REPORTS_NAV_LABEL'),
  },
  {
    navRoute: INTEGRATIONS,
    featureFlag: FEATURE_FLAG_INTEGRATIONS,
    icon: IntegrationsIcon,
    iconAlt: 'Integrations Icon',
    itemText: translate('INTEGRATIONS_NAV_LABEL'),
  },
];

const LOWER_NAV_ITEMS = [
  {
    navRoute: ADMIN,
    icon: LockedWithPen,
    iconAlt: translate('SUPER_ADMIN_NAV_ITEM_ICON_ALT'),
    itemText: translate('SUPER_ADMIN_NAV_LABEL'),
    showFor: [USER_VIEW_ENUMS.SUPER_ADMIN],
  },
  {
    navRoute: COMPANY,
    icon: settingsIcon,
    iconAlt: 'Settings Icon',
    itemText: translate('INVITES_NAV_LABEL'),
    showFor: [USER_VIEW_ENUMS.ADMIN],
  },
];

export { UPPER_NAV_ITEMS, LOWER_NAV_ITEMS };
