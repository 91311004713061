import React from 'react';
import { string } from 'prop-types';
import { Box, Typography } from '@material-ui/core';
import { translate } from 'lib/intl';
import { isMobileView } from 'lib/utils';
import { Loader } from 'components/common/loader';
import { LOADER_SIZES } from 'lib/constants';
import { useStyles } from './integrationPageStyles';

export default function CredentialsLoader({ integrationId }) {
  const isMobile = isMobileView();
  const styles = useStyles();
  return (
    <Box className={styles.infoBox}>
      <Box className={styles.loader}>
        <Typography className={styles.loaderHeader}>
          {translate('INTEGRATIONS_CONNECTING')}{' '}
          <span className={styles.loaderIntegrationId}>{integrationId}</span>
        </Typography>
        <Typography
          variant={isMobile ? 'body2' : 'body1'}
          className={styles.loaderDescription}
        >
          {translate('INTEGRATIONS_VERIFY')}
        </Typography>
        <Loader size={LOADER_SIZES.XL} />
      </Box>
    </Box>
  );
}

CredentialsLoader.propTypes = {
  integrationId: string,
};

CredentialsLoader.defaultProps = {
  integrationId: '',
};
