import React from 'react';
import { AuthContainer } from 'components';
import { Grid, Typography } from '@material-ui/core';
import { thinkingEmoji } from 'assets/images';
import {
  authNavLinkStyle,
  errorPageIconStyle,
} from 'components/auth/authStyles';
import { translate } from 'lib/intl';

const LoginLinkExpiredPage = () => (
  <AuthContainer size="md" navLinkText="Sign in" navLinkRoute="/login">
    <Grid container>
      <Grid item sm={6} xs={12}>
        <Typography variant="h5">Oops!</Typography>
        <Typography variant="h6" style={{ marginTop: '30px' }}>
          {translate('LOGIN_LINK_EXPIRED')}
        </Typography>
        <div style={{ marginTop: '30px' }}>
          <a role="button" tabIndex={0} style={authNavLinkStyle}>
            {translate('LOGIN_RESEND_VERIFICATION_EMAIL')}
          </a>
        </div>
        <Typography variant="body1" style={{ marginTop: '30px' }}>
          {translate('PERSISTENT_AUTH_ISSUE')}
          &nbsp;
          <a role="button" tabIndex={0} style={authNavLinkStyle}>
            {translate('CONTACT_US')}
          </a>
        </Typography>
      </Grid>
      <Grid item sm={6} xs={12} style={errorPageIconStyle}>
        <img alt="Thinking emoji" src={thinkingEmoji} />
      </Grid>
    </Grid>
  </AuthContainer>
);

export { LoginLinkExpiredPage };
