import React from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Radio from '@material-ui/core/Radio';
import makeStyles from '@material-ui/styles/makeStyles';
import { CheckedIcon, UncheckedIcon } from 'assets/images';
import { Visible, PrimaryCheckbox } from 'components/common';
import { EMPTY_FUNC } from 'lib/constants';
import { TextSelectFieldValueType } from 'lib/utils';
import TextSelectFieldItemImage from './TextSelectFieldItemImage';

const MULTIPLE_RADIO_ICONS = {
  checkedIcon: <CheckedIcon />,
  icon: <UncheckedIcon />,
};

const useStyles = makeStyles(() => ({
  itemContainer: {
    alignItems: 'center',
    height: '100%',
    display: 'flex',
    paddingRight: ({ multiple }) => (multiple ? '64px' : '50px'),
    position: 'relative',
    width: '100%',
  },
  radio: {
    position: 'absolute',
    right: '-8px',
  },
  primaryCheckbox: {
    position: 'absolute',
    right: '42px',
    top: '1px',
  },
}));

export default function TextSelectFieldItem({
  id,
  item,
  multiple,
  value,
  primaryValue,
  setPrimaryValue,
  usePrimary,
}) {
  const styles = useStyles();

  const { value: itemValue, text } = item;

  const isChecked = !!(multiple ? value : [value])?.find(
    (el) => el?.value === itemValue
  );

  return (
    <Box key={item.text} value={itemValue} width="100%">
      <Box className={styles.itemContainer} multiple={multiple}>
        <TextSelectFieldItemImage id={id} {...item} />
        <Typography variant="body1">{text}</Typography>
        <Visible when={usePrimary && isChecked}>
          <Box className={styles.primaryCheckbox}>
            <PrimaryCheckbox
              checkboxProps={{
                id: `${id}-${itemValue}-option-primary-checkbox`,
                name: `${id}-${itemValue}-option-primary-checkbox`,
              }}
              primary={itemValue === primaryValue}
              onChange={(isPrimary) =>
                setPrimaryValue(isPrimary ? itemValue : null)
              }
            />
          </Box>
        </Visible>
        <Radio
          id={`${id}-${itemValue}-option-${multiple ? 'checkbox' : 'radio'}`}
          className={styles.radio}
          checked={isChecked}
          value={itemValue}
          {...(multiple ? MULTIPLE_RADIO_ICONS : {})}
        />
      </Box>
    </Box>
  );
}

TextSelectFieldItem.propTypes = {
  id: PropTypes.string.isRequired,
  item: TextSelectFieldValueType.isRequired,
  multiple: PropTypes.bool,
  value: PropTypes.oneOfType([
    TextSelectFieldValueType,
    PropTypes.arrayOf(TextSelectFieldValueType),
  ]),
  primaryValue: PropTypes.string,
  setPrimaryValue: PropTypes.func,
  usePrimary: PropTypes.bool,
};

TextSelectFieldItem.defaultProps = {
  multiple: false,
  primaryValue: '',
  setPrimaryValue: EMPTY_FUNC,
  value: null,
  usePrimary: false,
};
