import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  arrayOf,
  bool,
  func,
  node,
  number,
  oneOfType,
  object,
  shape,
  string,
} from 'prop-types';
import styled from 'styled-components';
import { Box, TextField, Typography } from '@material-ui/core';
import { assocPath, path as getByPath, update, uniq } from 'rambdax';
import { DonorType } from 'lib/utils';
import { translate } from 'lib/intl';
import { colors } from 'styles/theme';
import { Visible } from 'components';

const StyledTextField = styled(TextField)`
  .MuiInputBase-root {
    background: none;

    &:before {
      border-bottom: 1px solid ${colors.grey500};
    }
  }

  .MuiInputLabel-underline {
    transform: translate(0px, 30px) scale(1);
  }

  .MuiInputLabel-underline.MuiInputLabel-shrink {
    transform: translate(0px, 10px) scale(0.75);
  }

  .MuiFilledInput-input {
    border-radius: 0px;
    padding: 28px 12px 4px 0px;
    text-overflow: ellipsis;
  }
`;

// TODO: @anna - check if I need arrays here at all
const DonorTextInput = ({
  data,
  errors,
  errorMessage,
  idx,
  inputRef,
  label,
  newValueMapper,
  path,
  prefix,
  required,
  showErrors,
  setErrors,
  updateData,
  valueMapper,
  validate,
  width,
  containerProps,
  disabled,
}) => {
  const [isDirty, setIsDirty] = useState(false);

  const isArray = typeof idx === 'number';
  const valueByPath = path ? getByPath(path, data) : data;

  // eslint-disable-next-line no-nested-ternary
  const value = isArray
    ? valueByPath?.length
      ? valueByPath[idx]
      : ''
    : valueByPath;

  const onChange = useCallback(
    ({ target }) => {
      const newValue = newValueMapper(target?.value, value, target);
      updateData(
        path
          ? assocPath(
              path,
              isArray
                ? update(
                    idx,
                    newValue,
                    valueByPath?.length ? valueByPath : ['']
                  )
                : newValue,
              data
            )
          : newValue,
        path
      );
    },
    [data, errors, idx, valueByPath, updateData]
  );

  const onBlur = useCallback(() => {
    setIsDirty(true);
  }, []);

  const mappedValue = valueMapper(value);
  const isRequredButEmpty = required && !mappedValue;

  const hasError = useMemo(
    () =>
      isRequredButEmpty || (validate && mappedValue && !validate(mappedValue)),
    [mappedValue, isRequredButEmpty]
  );

  useEffect(() => {
    const shoulUpdateErrors =
      (hasError && !errors.includes(path)) ||
      (!hasError && errors.includes(path));

    if (shoulUpdateErrors) {
      setErrors(
        hasError
          ? uniq([...errors, path])
          : errors.filter((error) => error !== path)
      );
    }
  }, [hasError]);

  const showError = hasError && (isDirty || showErrors);

  return (
    <Box {...containerProps}>
      <Box width="100%" style={{ position: 'relative' }}>
        {prefix}
        <StyledTextField
          data-testid={`donor-text-input-${path}`}
          error={Boolean(showError)}
          fullWidth
          inputRef={inputRef}
          onChange={onChange}
          onBlur={onBlur}
          label={label}
          placeholder={label}
          required={required}
          value={mappedValue}
          width={width}
          disabled={disabled}
        />
      </Box>
      <Visible when={Boolean(showError)}>
        <Typography color="error">
          {isRequredButEmpty ? translate('REQUIRED') : errorMessage}
        </Typography>
      </Visible>
    </Box>
  );
};

DonorTextInput.propTypes = {
  data: DonorType.isRequired,
  errors: arrayOf(string),
  errorMessage: string,
  label: string,
  idx: oneOfType([object, number]),
  inputRef: shape({}),
  newValueMapper: func,
  path: string,
  prefix: node,
  required: bool,
  setErrors: func,
  showErrors: bool,
  updateData: func.isRequired,
  valueMapper: func,
  validate: func,
  width: string,
  containerProps: shape({}),
  disabled: bool,
};

DonorTextInput.defaultProps = {
  errors: [],
  errorMessage: '',
  label: '',
  idx: null,
  inputRef: null,
  newValueMapper: (value) => value,
  path: '',
  prefix: null,
  required: false,
  setErrors: null,
  showErrors: false,
  valueMapper: (value) => value || '',
  validate: null,
  width: '100%',
  containerProps: null,
  disabled: false,
};

export { DonorTextInput };
