import React from 'react';
import makeStyles from '@material-ui/styles/makeStyles';
import { BookmarkedIcon, NotBookmarkedIcon } from 'assets/images';

const useStyles = makeStyles((theme) => ({
  card: {
    background: theme.palette.background.white,
    border: `1px solid ${theme.palette.grey.light}`,
    borderRadius: theme.shape.borderRadius[0],
    cursor: 'pointer',
    marginBottom: theme.spacing(2),
    paddingBottom: theme.spacing(3),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    paddingTop: theme.spacing(2),
    position: 'relative',
    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(1),
      paddingBottom: theme.spacing(1),
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(2),
    },
  },
  title: {
    ...theme.typography.h5,
    [theme.breakpoints.down('sm')]: {
      fontSize: '20px',
    },
  },
  archived: {
    background: theme.palette.grey.light,
    borderColor: theme.palette.grey[500],
    color: `${theme.palette.grey.dark} !important`,
  },
  coloredLine: {
    borderBottomLeftRadius: theme.shape.borderRadius[0],
    borderTopLeftRadius: theme.shape.borderRadius[0],
    height: '100%',
    left: 0,
    position: 'absolute',
    top: 0,
    width: theme.spacing(1),
  },
  showMoreBtn: {
    bottom: theme.spacing(0.5),
    color: theme.palette.grey.dark,
    position: 'absolute',
    right: theme.spacing(1.25),
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  showMoreBtnText: {
    fontSize: '12px',
    fontWeight: 500,
    letterSpacing: '1px',
    textTransform: 'none',
  },
  showMoreBtnIcon: {
    margin: 0,
  },
  completedLabel: {
    ...theme.typography.body2,
    fontWeight: 600,
  },
  controlElements: {
    alignItems: 'center',
    display: 'flex',
    padding: theme.spacing(2),
    position: 'absolute',
    top: 0,
    right: 0,
    [theme.breakpoints.down('sm')]: {
      right: theme.spacing(0.5),
      top: theme.spacing(0.5),
    },
  },
  showActionsBtn: {
    minWidth: 'auto',
    padding: theme.spacing(1),
    '&:hover': {
      backgroundColor: 'transparent',
    },
    [theme.breakpoints.down('sm')]: {
      bottom: theme.spacing(1.75),
      position: 'absolute',
      right: theme.spacing(1.25),
    },
  },
  paperFullWidth: {
    margin: theme.spacing(1),
    width: '100%',
  },
  linearProgress: {
    height: '8px',
    [theme.breakpoints.down('sm')]: {
      height: '12px',
    },
  },
}));

const LEFT_COLUMN_MAX_WIDTH = '220px';
const DONATION_AMOUNTS_FLEX = '1 1 600px';
const CONTROL_ELS_WIDTH = '48px';
const CONTROL_ELS_MOBILE_WIDTH = '24px';

const BOOKMARKED_CHECKBOX_PROPS = {
  checkedIcon: <BookmarkedIcon />,
  icon: <NotBookmarkedIcon />,
};

const ARCHIVED_LABEL_STYLE = {
  fontWeight: '600',
};

const POPOVER_TRANSFORM_ORIGIN = {
  horizontal: 0,
  vertical: -20,
};

const POPOVER_ANCHOR_ORIGIN = {
  horizontal: 'left',
  vertical: 'top',
};

const CONFIRM_POPOVER_ANCHOR_ORIGIN = {
  horizontal: 'right',
  vertical: 'top',
};

const CONFIRM_POPOVER_TRANSFORM_ORIGIN = {
  horizontal: 0,
  vertical: 4,
};

const LOADER_SIZE = '24px';

export {
  useStyles,
  LEFT_COLUMN_MAX_WIDTH,
  DONATION_AMOUNTS_FLEX,
  CONTROL_ELS_WIDTH,
  CONTROL_ELS_MOBILE_WIDTH,
  BOOKMARKED_CHECKBOX_PROPS,
  ARCHIVED_LABEL_STYLE,
  POPOVER_TRANSFORM_ORIGIN,
  POPOVER_ANCHOR_ORIGIN,
  CONFIRM_POPOVER_ANCHOR_ORIGIN,
  CONFIRM_POPOVER_TRANSFORM_ORIGIN,
  LOADER_SIZE,
};
