import React from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import Box from '@material-ui/core/Box';
import FormHelperText from '@material-ui/core/FormHelperText';
import CustomDialog from 'components/common/CustomDialog';
import { ControlledTextField } from 'components/common/form';
import { translate } from 'lib/intl';
import { FEATURE_FLAG_RESPONSE_TYPES } from 'lib/configs/superAdmin';
import { featureFlagActions } from 'rdx/actions/featureFlags';

const FEATURE_FLAG_NAME = 'featureFlagName';
const defaultValues = {
  featureFlagName: '',
};

export default function CreateFeatureFlagDialog({
  open,
  handleClose,
  setOpen,
  onApiResponse,
  isLoading,
}) {
  const dispatch = useDispatch();

  const { control, errors, handleSubmit, getValues } = useForm({
    mode: 'onChange',
    defaultValues,
  });

  const handleCreate = async (formData) => {
    try {
      const response = await dispatch(
        featureFlagActions.createFeatureFlag(formData.featureFlagName)
      );
      onApiResponse(response, FEATURE_FLAG_RESPONSE_TYPES.NEW);
    } catch (error) {
      onApiResponse(error, FEATURE_FLAG_RESPONSE_TYPES.NEW);
    } finally {
      handleClose();
    }
  };
  return (
    <CustomDialog
      actionButtonLabel={translate('CREATE')}
      open={open}
      onClose={handleClose}
      handleActionButton={handleSubmit(handleCreate)}
      disableActionButton={
        !getValues(FEATURE_FLAG_NAME) || !!errors.featureFlagName || isLoading
      }
      dialogHeader={translate('FEATURE_FLAGS_CREATE')}
      setOpen={setOpen}
    >
      <Box width={500}>
        <form>
          <ControlledTextField
            control={control}
            disabled={isLoading}
            errors={errors}
            fullWidth
            label={translate('FEATURE_FLAGS_CREATE_NAME')}
            name={FEATURE_FLAG_NAME}
            required
            rules={{
              required: translate('FORMS_VALIDATION_REQUIRED'),
              pattern: {
                value: /^[a-z]+((\d)|([A-Z0-9][a-z0-9]+))*([A-Z])?$/,
                message: translate('FORMS_VALIDATION_CAMEL'),
              },
            }}
          />
        </form>
        <FormHelperText error={!!errors.featureFlagName}>
          {translate('FORMS_VALIDATION_CAMEL')}
        </FormHelperText>
      </Box>
    </CustomDialog>
  );
}

CreateFeatureFlagDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onApiResponse: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  setOpen: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
};
CreateFeatureFlagDialog.defaultProps = {
  isLoading: false,
};
