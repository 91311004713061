import { createAction, showErrorMessage } from 'lib/utils';
import { translate } from 'lib/intl';
import { featureFlagService } from 'services/featureFlags';
import userActionTypes from 'rdx/actionTypes';

const {
  FEATURE_FLAGS_LIST_REQUEST,
  FEATURE_FLAGS_LIST_SUCCESS,
  FEATURE_FLAGS_LIST_FAILURE,

  CREATE_FEATURE_FLAG_REQUEST,
  CREATE_FEATURE_FLAG_SUCCESS,
  CREATE_FEATURE_FLAG_FAILURE,

  UPDATE_FEATURE_FLAG_REQUEST,
  UPDATE_FEATURE_FLAG_SUCCESS,
  UPDATE_FEATURE_FLAG_FAILURE,

  REMOVE_FEATURE_FLAG_REQUEST,
  REMOVE_FEATURE_FLAG_SUCCESS,
  REMOVE_FEATURE_FLAG_FAILURE,
} = userActionTypes;

const fetchFeatureFlags = () => async (dispatch) => {
  try {
    dispatch(createAction({ type: FEATURE_FLAGS_LIST_REQUEST }));
    const response = await featureFlagService.fetchFeatureFlags();
    dispatch(
      createAction({ type: FEATURE_FLAGS_LIST_SUCCESS, data: response?.data })
    );
  } catch (err) {
    showErrorMessage({
      message: `${translate('FEATURE_FLAGS_GENERIC_ERROR_MESSAGE', {
        msg: translate('FEATURE_FLAGS_ERR_MSG_GET'),
      })}`,
    });
    dispatch(createAction({ type: FEATURE_FLAGS_LIST_FAILURE, error: err }));
  }
};

const createFeatureFlag = (flagName) => async (dispatch) => {
  try {
    dispatch(createAction({ type: CREATE_FEATURE_FLAG_REQUEST }));
    const response = await featureFlagService.createFeatureFlag(flagName);
    dispatch(
      createAction({ type: CREATE_FEATURE_FLAG_SUCCESS, data: response?.data })
    );
    return response.data;
  } catch (err) {
    dispatch(createAction({ type: CREATE_FEATURE_FLAG_FAILURE, error: err }));
    return { error: err || 'error undefined' };
  }
};

const removeFeatureFlag = (flagName) => async (dispatch) => {
  try {
    dispatch(createAction({ type: REMOVE_FEATURE_FLAG_REQUEST }));
    const response = await featureFlagService.removeFeatureFlag(flagName);
    dispatch(createAction({ type: REMOVE_FEATURE_FLAG_SUCCESS, flagName }));
    return response;
  } catch (err) {
    dispatch(
      createAction({
        type: REMOVE_FEATURE_FLAG_FAILURE,
        error: err?.message
          ? err.message
          : translate('FEATURE_FLAGS_GENERIC_ERROR_MESSAGE', {
              msg: translate('FEATURE_FLAGS_ERR_MSG_DEL'),
            }),
      })
    );
    return { error: err || 'error undefined' };
  }
};

const updateFeatureFlag = (data) => async (dispatch) => {
  try {
    dispatch(createAction({ type: UPDATE_FEATURE_FLAG_REQUEST }));
    const response = await featureFlagService.updateFeatureFlag(data);
    dispatch(
      createAction({ type: UPDATE_FEATURE_FLAG_SUCCESS, data: response?.data })
    );
    return response.data;
  } catch (err) {
    dispatch(
      createAction({
        type: UPDATE_FEATURE_FLAG_FAILURE,
        error: err?.message
          ? err.message
          : translate('FEATURE_FLAGS_GENERIC_ERROR_MESSAGE', {
              msg: translate('FEATURE_FLAGS_ERR_MSG_UPD'),
            }),
      })
    );
    return { error: err || 'error undefined' };
  }
};

export const featureFlagActions = {
  fetchFeatureFlags,
  createFeatureFlag,
  removeFeatureFlag,
  updateFeatureFlag,
};
