import userActionTypes from 'rdx/actionTypes';

const initialState = {
  dialogKey: '',
  data: null,
  config: null,
  open: false,
};

export function actionDialogReducer(state = initialState, action) {
  switch (action.type) {
    case userActionTypes.OPEN_ACTION_DIALOG:
      return {
        dialogKey: action.dialogKey,
        open: true,
        data: action.data ?? initialState.data,
        config: action.config ?? initialState.config,
      };
    case userActionTypes.CLOSE_ACTION_DIALOG:
      return initialState;
    default:
      return state;
  }
}
