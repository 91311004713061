const userActionTypes = {
  REGISTER_FORM_EIN_VALUE: 'USERS_REGISTER_FORM_EIN_VALUE',
  REGISTER_FORM_PHONE_VALUE: 'USERS_REGISTER_FORM_PHONE_VALUE',
  REGISTER_REQUEST: 'USERS_REGISTER_REQUEST',
  REGISTER_SUCCESS: 'USERS_REGISTER_SUCCESS',
  REGISTER_ADMIN_SUCCESS: 'USERS_REGISTER_ADMIN_SUCCESS',
  REGISTER_FAILURE: 'USERS_REGISTER_FAILURE',
  REGISTER_RESET_ALL: 'USERS_REGISTER_RESET',

  LOGIN_REQUEST: 'USERS_LOGIN_REQUEST',
  LOGIN_SUCCESS: 'USERS_LOGIN_SUCCESS',
  LOGIN_FAILURE: 'USERS_LOGIN_FAILURE',
  LOGOUT: 'USERS_LOGOUT',
  LOGIN_CLEAR_ERRORS: 'USERS_LOGIN_CLEAR_ERRORS',

  RESEND_VERIFICATION_EMAIL_REQUEST: 'USERS_RESEND_VERIFICATION_EMAIL_REQUEST',
  RESEND_VERIFICATION_EMAIL_SUCCESS: 'USERS_RESEND_VERIFICATION_EMAIL_SUCCESS',
  RESEND_VERIFICATION_EMAIL_FAILURE: 'USERS_RESEND_VERIFICATION_EMAIL_FAILURE',

  RESET_PASSWORD_REQUEST: 'USERS_RESET_PASSWORD_REQUEST',
  RESET_PASSWORD_SUCCESS: 'USERS_RESET_PASSWORD_SUCCESS',
  RESET_PASSWORD_FAILURE: 'USERS_RESET_PASSWORD_FAILURE',
  RESET_PASSWORD_RESET: 'USERS_RESET_PASSWORD_RESET',

  RESET_PASSWORD_SUBMIT_REQUEST: 'USERS_RESET_PASSWORD_SUBMIT_REQUEST',
  RESET_PASSWORD_SUBMIT_SUCCESS: 'USERS_RESET_PASSWORD_SUBMIT_SUCCESS',
  RESET_PASSWORD_SUBMIT_FAILURE: 'USERS_RESET_PASSWORD_SUBMIT_FAILURE',
  RESET_PASSWORD_SUBMIT_RESET: 'USERS_RESET_PASSWORD_SUBMIT_RESET',

  INVITE_FORM_UPDATE_GROUPS: 'INVITE_FORM_UPDATE_GROUPS',
  INVITE_FORM_UPDATE_EMAILS: 'INVITE_FORM_UPDATE_EMAILS',
  INVITE_FORM_SET_ERROR: 'INVITE_FORM_SET_ERROR',
  INVITE_FORM_RESET_ALL: 'INVITE_FORM_RESET_ALL',

  SENDING_INVITES_REQUEST: 'SENDING_INVITES_REQUEST',
  SENDING_INVITES_SUCCESS: 'SENDING_INVITES_SUCCESS',
  SENDING_INVITES_FAILURE: 'SENDING_INVITES_FAILURE',
  SENDING_INVITES_RESET: 'SENDING_INVITES_RESET',

  INVITES_REQUEST: 'INVITES_REQUEST',
  INVITES_SUCCESS: 'INVITES_SUCCESS',
  INVITES_FAILURE: 'INVITES_FAILURE',

  UPDATE_INVITES_REQUEST: 'UPDATE_INVITES_REQUEST',
  UPDATE_INVITES_SUCCESS: 'UPDATE_INVITES_SUCCESS',
  UPDATE_INVITES_FAILURE: 'UPDATE_INVITES_FAILURE',

  REMOVE_INVITES_REQUEST: 'REMOVE_INVITES_REQUEST',
  REMOVE_INVITES_SUCCESS: 'REMOVE_INVITES_SUCCESS',
  REMOVE_INVITES_FAILURE: 'REMOVE_INVITES_FAILURE',

  FEED_REQUEST: 'FEED_REQUEST',
  FEED_SUCCESS: 'FEED_SUCCESS',
  FEED_FAILURE: 'FEED_FAILURE',
  FEED_REFRESH_SUCCESS: 'FEED_REFRESH_SUCCESS',

  RECENTLY_VIEWED_DONORS_REQUEST: 'RECENTLY_VIEWED_DONORS_REQUEST',
  RECENTLY_VIEWED_DONORS_SUCCESS: 'RECENTLY_VIEWED_DONORS_SUCCESS',
  RECENTLY_VIEWED_DONORS_FAILURE: 'RECENTLY_VIEWED_DONORS_FAILURE',

  SET_LOCATION: 'SET_LOCATION',

  DONOR_INFO_REQUEST: 'DONOR_INFO_REQUEST',
  DONOR_INFO_SUCCESS: 'DONOR_INFO_SUCCESS',
  DONOR_INFO_FAILURE: 'DONOR_INFO_FAILURE',

  REFRESH_ACCESS_TOKEN: 'REFRESH_ACCESS_TOKEN',
  REFRESH_ACCESS_TOKEN_TIMER_SETUP: 'REFRESH_ACCESS_TOKEN_TIMER_SETUP',

  DONOR_FORM_UPDATE_EDITED_FIELDS: 'DONOR_FORM_UPDATE_EDITED_FIELDS',

  DONOR_UPDATE_REQUEST: 'DONOR_UPDATE_REQUEST',
  DONOR_UPDATE_SUCCESS: 'DONOR_UPDATE_SUCCESS',
  DONOR_UPDATE_FAILURE: 'DONOR_UPDATE_FAILURE',

  DONOR_WITH_DEVELOPERS_REQUEST: 'DONOR_WITH_DEVELOPERS_REQUEST',
  DONOR_WITH_DEVELOPERS_SUCCESS: 'DONOR_WITH_DEVELOPERS_SUCCESS',
  DONOR_WITH_DEVELOPERS_FAILURE: 'DONOR_WITH_DEVELOPERS_FAILURE',

  DONATION_CREATE_REQUEST: 'DONATION_CREATE_REQUEST',
  DONATION_CREATE_SUCCESS: 'DONATION_CREATE_SUCCESS',
  DONATION_CREATE_FAILURE: 'DONATION_CREATE_FAILURE',
  DONATION_CREATE_RESET: 'DONATION_CREATE_RESET',

  DONATION_REQUEST: 'DONATION_REQUEST',
  DONATION_SUCCESS: 'DONATION_SUCCESS',
  DONATION_FAILURE: 'DONATION_FAILURE',

  DONATION_DELETE_REQUEST: 'DONATION_DELETE_REQUEST',
  DONATION_DELETE_SUCCESS: 'DONATION_DELETE_SUCCESS',
  DONATION_DELETE_FAILURE: 'DONATION_DELETE_FAILURE',

  INTERACTION_REQUEST: 'INTERACTION_REQUEST',
  INTERACTION_SUCCESS: 'INTERACTION_SUCCESS',
  INTERACTION_FAILURE: 'INTERACTION_FAILURE',

  ADD_INTERACTION_REQUEST: 'ADD_INTERACTION_REQUEST',
  ADD_INTERACTION_SUCCESS: 'ADD_INTERACTION_SUCCESS',
  ADD_INTERACTION_FAILURE: 'ADD_INTERACTION_FAILURE',
  ADD_INTERACTION_RESET: 'ADD_INTERACTION_RESET',

  EDIT_INTERACTION_REQUEST: 'EDIT_INTERACTION_REQUEST',
  EDIT_INTERACTION_SUCCESS: 'EDIT_INTERACTION_SUCCESS',
  EDIT_INTERACTION_FAILURE: 'EDIT_INTERACTION_FAILURE',
  EDIT_INTERACTION_RESET: 'EDIT_INTERACTION_RESET',

  GOALS_REQUEST: 'GOALS_REQUEST',
  GOALS_SUCCESS: 'GOALS_SUCCESS',
  GOALS_FAILURE: 'GOALS_FAILURE',

  USERS_REQUEST: 'USERS_REQUEST',
  USERS_SUCCESS: 'USERS_SUCCESS',
  USERS_FAILURE: 'USERS_FAILURE',

  USERS_LIST_REQUEST: 'USERS_LIST_REQUEST',
  USERS_LIST_SUCCESS: 'USERS_LIST_SUCCESS',
  USERS_LIST_FAILURE: 'USERS_LIST_FAILURE',

  USERS_UPDATE_INFO_REQUEST: 'USERS_UPDATE_INFO_REQUEST',
  USERS_UPDATE_INFO_SUCCESS: 'USERS_UPDATE_INFO_SUCCESS',
  USERS_UPDATE_INFO_FAILURE: 'USERS_UPDATE_INFO_FAILURE',

  ORGS_LIST_REQUEST: 'ORGS_LIST_REQUEST',
  ORGS_LIST_SUCCESS: 'ORGS_LIST_SUCCESS',
  ORGS_LIST_FAILURE: 'ORGS_LIST_FAILURE',
  ORGS_LIST_SET_FILTER: 'ORGS_LIST_SET_FILTER',

  ORGS_CREATE_SUCCESS: 'ORGS_CREATE_SUCCESS',

  AUTH_SET_ORG_ID_PUB: 'AUTH_SET_ORG_ID_PUB',

  PUBLIC_USERS_REQUEST: 'PUBLIC_USERS_REQUEST',
  PUBLIC_USERS_SUCCESS: 'PUBLIC_USERS_SUCCESS',
  PUBLIC_USERS_FAILURE: 'PUBLIC_USERS_FAILURE',

  COMPANY_TEAM_MEMBERS_REQUEST: 'COMPANY_TEAM_MEMBERS_REQUEST',
  COMPANY_TEAM_MEMBERS_SUCCESS: 'COMPANY_TEAM_MEMBERS_SUCCESS',
  COMPANY_TEAM_MEMBERS_FAILURE: 'COMPANY_TEAM_MEMBERS_FAILURE',

  UPDATE_COMPANY_TEAM_MEMBERS_REQUEST: 'UPDATE_COMPANY_TEAM_MEMBERS_REQUEST',
  UPDATE_COMPANY_TEAM_MEMBERS_SUCCESS: 'UPDATE_COMPANY_TEAM_MEMBERS_SUCCESS',
  UPDATE_COMPANY_TEAM_MEMBERS_FAILURE: 'UPDATE_COMPANY_TEAM_MEMBERS_FAILURE',

  REMOVE_COMPANY_TEAM_MEMBERS_REQUEST: 'REMOVE_COMPANY_TEAM_MEMBERS_REQUEST',
  REMOVE_COMPANY_TEAM_MEMBERS_SUCCESS: 'REMOVE_COMPANY_TEAM_MEMBERS_SUCCESS',
  REMOVE_COMPANY_TEAM_MEMBERS_FAILURE: 'REMOVE_COMPANY_TEAM_MEMBERS_FAILURE',

  SET_USER_REG_ADMIN_DATA: 'SET_USER_REG_ADMIN_DATA',

  ORG_INFO_REQUEST: 'ORG_INFO_REQUEST',
  ORG_INFO_SUCCESS: 'ORG_INFO_SUCCESS',
  ORG_INFO_FAILURE: 'ORG_INFO_FAILURE',

  UPDATE_ORG_INFO_REQUEST: 'UPDATE_ORG_INFO_REQUEST',
  UPDATE_ORG_INFO_SUCCESS: 'UPDATE_ORG_INFO_SUCCESS',
  UPDATE_ORG_INFO_FAILURE: 'UPDATE_ORG_INFO_FAILURE',
  UPDATE_ORG_INFO_RESET: 'UPDATE_ORG_INFO_RESET',

  OPEN_DONOR_FORM_POPUP: 'OPEN_DONOR_FORM_POPUP',
  CLOSE_DONOR_FORM_POPUP: 'CLOSE_DONOR_FORM_POPUP',

  GET_VISIT_STREAK_REQUEST: 'GET_VISIT_STREAK_REQUEST',
  GET_VISIT_STREAK_SUCCESS: 'GET_VISIT_STREAK_SUCCESS',
  GET_VISIT_STREAK_FAILURE: 'GET_VISIT_STREAK_FAILURE',

  UPDATE_VISIT_STREAK_REQUEST: 'UPDATE_VISIT_STREAK_REQUEST',
  UPDATE_VISIT_STREAK_SUCCESS: 'UPDATE_VISIT_STREAK_SUCCESS',
  UPDATE_VISIT_STREAK_FAILURE: 'UPDATE_VISIT_STREAK_FAILURE',

  CREATE_DONOR_CONNECTION_REQUEST: 'CREATE_DONOR_CONNECTION_REQUEST',
  CREATE_DONOR_CONNECTION_SUCCESS: 'CREATE_DONOR_CONNECTION_SUCCESS',
  CREATE_DONOR_CONNECTION_FAILURE: 'CREATE_DONOR_CONNECTION_FAILURE',

  CREATE_DONOR_INFO_REQUEST: 'CREATE_DONOR_INFO_REQUEST',
  CREATE_DONOR_INFO_SUCCESS: 'CREATE_DONOR_INFO_SUCCESS',
  CREATE_DONOR_INFO_FAILURE: 'CREATE_DONOR_INFO_FAILURE',

  UPDATE_DONOR_CONNECTION_REQUEST: 'UPDATE_DONOR_CONNECTION_REQUEST',
  UPDATE_DONOR_CONNECTION_SUCCESS: 'UPDATE_DONOR_CONNECTION_SUCCESS',
  UPDATE_DONOR_CONNECTION_FAILURE: 'UPDATE_DONOR_CONNECTION_FAILURE',

  UPDATE_DONOR_INFO_REQUEST: 'UPDATE_DONOR_INFO_REQUEST',
  UPDATE_DONOR_INFO_SUCCESS: 'UPDATE_DONOR_INFO_SUCCESS',
  UPDATE_DONOR_INFO_FAILURE: 'UPDATE_DONOR_INFO_FAILURE',

  REMOVE_DONOR_CONNECTION_REQUEST: 'REMOVE_DONOR_CONNECTION_REQUEST',
  REMOVE_DONOR_CONNECTION_SUCCESS: 'REMOVE_DONOR_CONNECTION_SUCCESS',
  REMOVE_DONOR_CONNECTION_FAILURE: 'REMOVE_DONOR_CONNECTION_FAILURE',

  CREATE_ANNOUNCEMENT_REQUEST: 'CREATE_ANNOUNCEMENT_REQUEST',
  CREATE_ANNOUNCEMENT_SUCCESS: 'CREATE_ANNOUNCEMENT_SUCCESS',
  CREATE_ANNOUNCEMENT_FAILURE: 'CREATE_ANNOUNCEMENT_FAILURE',
  CREATE_ANNOUNCEMENT_RESET: 'CREATE_ANNOUNCEMENT_RESET',

  FETCH_FEED_ENTITY_REQUEST: 'FETCH_FEED_ENTITY_REQUEST',
  FETCH_FEED_ENTITY_SUCCESS: 'FETCH_FEED_ENTITY_SUCCESS',
  FETCH_FEED_ENTITY_FAILURE: 'FETCH_FEED_ENTITY_FAILURE',
  FETCH_FEED_ENTITY_RESET: 'FETCH_FEED_ENTITY_RESET',

  DELETE_FEED_ENTITY_REQUEST: 'DELETE_FEED_ENTITY_REQUEST',
  DELETE_FEED_ENTITY_SUCCESS: 'DELETE_FEED_ENTITY_SUCCESS',
  DELETE_FEED_ENTITY_FAILURE: 'DELETE_FEED_ENTITY_FAILURE',

  FETCH_CAMPAIGNS_REQUEST: 'FETCH_CAMPAIGNS_REQUEST',
  FETCH_CAMPAIGNS_SUCCESS: 'FETCH_CAMPAIGNS_SUCCESS',
  FETCH_CAMPAIGNS_FAILURE: 'FETCH_CAMPAIGNS_FAILURE',

  CREATE_CAMPAIGN_REQUEST: 'CREATE_CAMPAIGN_REQUEST',
  CREATE_CAMPAIGN_SUCCESS: 'CREATE_CAMPAIGN_SUCCESS',
  CREATE_CAMPAIGN_FAILURE: 'CREATE_CAMPAIGN_FAILURE',

  UPDATE_CAMPAIGN_REQUEST: 'UPDATE_CAMPAIGN_REQUEST',
  UPDATE_CAMPAIGN_SUCCESS: 'UPDATE_CAMPAIGN_SUCCESS',
  UPDATE_CAMPAIGN_FAILURE: 'UPDATE_CAMPAIGN_FAILURE',
  UPDATE_CAMPAIGN_RESET: 'UPDATE_CAMPAIGN_RESET',

  UPDATE_MULTIPLE_CAMPAIGNS_REQUEST: 'UPDATE_MULTIPLE_CAMPAIGNS_REQUEST',
  UPDATE_MULTIPLE_CAMPAIGNS_SUCCESS: 'UPDATE_MULTIPLE_CAMPAIGNS_SUCCESS',
  UPDATE_MULTIPLE_CAMPAIGNS_FAILURE: 'UPDATE_MULTIPLE_CAMPAIGNS_FAILURE',

  FETCH_CAMPAIGN_REQUEST: 'FETCH_CAMPAIGN_REQUEST',
  FETCH_CAMPAIGN_SUCCESS: 'FETCH_CAMPAIGN_SUCCESS',
  FETCH_CAMPAIGN_FAILURE: 'FETCH_CAMPAIGN_FAILURE',

  REMOVE_CAMPAIGN_REQUEST: 'REMOVE_CAMPAIGN_REQUEST',
  REMOVE_CAMPAIGN_SUCCESS: 'REMOVE_CAMPAIGN_SUCCESS',
  REMOVE_CAMPAIGN_FAILURE: 'REMOVE_CAMPAIGN_FAILURE',

  FETCH_CAMPAIGN_FEED_REQUEST: 'FETCH_CAMPAIGN_FEED_REQUEST',
  FETCH_CAMPAIGN_FEED_SUCCESS: 'FETCH_CAMPAIGN_FEED_SUCCESS',
  FETCH_CAMPAIGN_FEED_REFRESH_SUCCESS: 'FETCH_CAMPAIGN_FEED_REFRESH_SUCCESS',
  FETCH_CAMPAIGN_FEED_FAILURE: 'FETCH_CAMPAIGN_FEED_FAILURE',

  UPDATE_CAMPAIGNS_FISCAL_GOAL_REQUEST: 'UPDATE_CAMPAIGNS_FISCAL_GOAL_REQUEST',
  UPDATE_CAMPAIGNS_FISCAL_GOAL_SUCCESS: 'UPDATE_CAMPAIGNS_FISCAL_GOAL_SUCCESS',
  UPDATE_CAMPAIGNS_FISCAL_GOAL_FAILURE: 'UPDATE_CAMPAIGNS_FISCAL_GOAL_FAILURE',

  FETCH_CAMPAIGN_DONATIONS_REQUEST: 'FETCH_CAMPAIGN_DONATIONS_REQUEST',
  FETCH_CAMPAIGN_DONATIONS_SUCCESS: 'FETCH_CAMPAIGN_DONATIONS_SUCCESS',
  FETCH_CAMPAIGN_DONATIONS_FAILURE: 'FETCH_CAMPAIGN_DONATIONS_FAILURE',

  CREATE_INTEGRATION_KEY_REQUEST: 'CREATE_INTEGRATION_KEY_REQUEST',
  CREATE_INTEGRATION_KEY_SUCCESS: 'CREATE_INTEGRATION_KEY_SUCCESS',
  CREATE_INTEGRATION_KEY_FAILURE: 'CREATE_INTEGRATION_KEY_FAILURE',

  UPDATE_INTEGRATION_KEY_REQUEST: 'UPDATE_INTEGRATION_KEY_REQUEST',
  UPDATE_INTEGRATION_KEY_SUCCESS: 'UPDATE_INTEGRATION_KEY_SUCCESS',
  UPDATE_INTEGRATION_KEY_FAILURE: 'UPDATE_INTEGRATION_KEY_FAILURE',

  REMOVE_INTEGRATION_KEY_REQUEST: 'REMOVE_INTEGRATION_KEY_REQUEST',
  REMOVE_INTEGRATION_KEY_SUCCESS: 'REMOVE_INTEGRATION_KEY_SUCCESS',
  REMOVE_INTEGRATION_KEY_FAILURE: 'REMOVE_INTEGRATION_KEY_FAILURE',

  FEATURE_FLAGS_LIST_REQUEST: 'FEATURE_FLAGS_LIST_REQUEST',
  FEATURE_FLAGS_LIST_SUCCESS: 'FEATURE_FLAGS_LIST_SUCCESS',
  FEATURE_FLAGS_LIST_FAILURE: 'FEATURE_FLAGS_LIST_FAILURE',

  CREATE_FEATURE_FLAG_REQUEST: 'CREATE_FEATURE_FLAG_REQUEST',
  CREATE_FEATURE_FLAG_SUCCESS: 'CREATE_FEATURE_FLAG_SUCCESS',
  CREATE_FEATURE_FLAG_FAILURE: 'CREATE_FEATURE_FLAG_FAILURE',

  UPDATE_FEATURE_FLAG_REQUEST: 'UPDATE_FEATURE_FLAG_REQUEST',
  UPDATE_FEATURE_FLAG_SUCCESS: 'UPDATE_FEATURE_FLAG_SUCCESS',
  UPDATE_FEATURE_FLAG_FAILURE: 'UPDATE_FEATURE_FLAG_FAILURE',

  REMOVE_FEATURE_FLAG_REQUEST: 'REMOVE_FEATURE_FLAG_REQUEST',
  REMOVE_FEATURE_FLAG_SUCCESS: 'REMOVE_FEATURE_FLAG_SUCCESS',
  REMOVE_FEATURE_FLAG_FAILURE: 'REMOVE_FEATURE_FLAG_FAILURE',

  OPEN_ACTION_DIALOG: 'OPEN_ACTION_DIALOG',
  CLOSE_ACTION_DIALOG: 'CLOSE_ACTION_DIALOG',
};

export default userActionTypes;
