import React from 'react';
import PropTypes from 'prop-types';
import { kebabCase } from 'voca';
import { Box, makeStyles } from '@material-ui/core';
import { EMPTY_FUNC } from 'lib/constants';
import { Visible } from 'components';
import { FeedType } from 'lib/types';
import { isMobileView } from 'lib/utils';
import { FeedItemTitle } from './FeedItemTitle';
import { MarkdownItems } from './MarkdownItems';

const useStyles = makeStyles((theme) => ({
  feedItem: {
    background: theme.palette.background.white,
    border: `1px solid ${theme.palette.grey.light}`,
    borderRadius: theme.shape.borderRadius,
    cursor: 'pointer',
  },
  active: {
    border: `1px solid ${theme.palette.primary.main}`,
  },
  feedContent: {
    '& > *': {
      fontSize: '16px',
    },
  },
}));

const FeedItem = React.forwardRef(
  ({ id, type, date, body, pinned, isActive, onClick }, ref) => {
    const styles = useStyles();
    const feedItemClassName = isActive
      ? `${styles.feedItem} ${styles.active}`
      : styles.feedItem;
    const isMobile = isMobileView();

    return (
      <Box
        data-testid={`feed-item-${kebabCase(type)}`}
        ref={ref}
        onClick={() => onClick(id)}
        className={feedItemClassName}
        p={2}
        m={isMobile ? 1 : 2}
      >
        <FeedItemTitle type={type} date={date} pinned={pinned} />
        <Visible when={Boolean(body)}>
          <Box className={styles.feedContent}>
            <MarkdownItems body={body} />
          </Box>
        </Visible>
      </Box>
    );
  }
);

FeedItem.propTypes = {
  id: PropTypes.string.isRequired,
  date: PropTypes.string,
  type: FeedType,
  isActive: PropTypes.bool,
  pinned: PropTypes.bool,
  onClick: PropTypes.func,
  body: PropTypes.arrayOf(
    PropTypes.shape({
      do: PropTypes.number,
      t: PropTypes.string,
      txt: PropTypes.string,
    })
  ),
};

FeedItem.defaultProps = {
  date: null,
  type: null,
  isActive: false,
  pinned: false,
  onClick: EMPTY_FUNC,
  body: [],
};

export { FeedItem };
