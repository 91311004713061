import React, { useCallback, useRef } from 'react';
import { arrayOf, bool, func, shape } from 'prop-types';
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  Typography,
} from '@material-ui/core';
import styled from 'styled-components';
import { formatCurrency, formatCurrencyInputToCents } from 'lib/utils/currency';
import { values } from 'rambdax';
import {
  CustomButton,
  CurrencyInput,
  Gap,
  Loader,
  TextSelectField,
  Visible,
} from 'components';
import { DateInput } from 'components/common/form';
import { colors } from 'styles/theme';
import { CloseIcon } from 'assets/images';
import { translate } from 'lib/intl';
import { isMobileView, TextSelectFieldValueType } from 'lib/utils';
import { formatDateObject, parseDateValue } from 'lib/utils/dates';
import { API_DATE_FORMAT, NUMBER_FORMAT_RETURNED_VALUES } from 'lib/constants';
import {
  CampaignType,
  CAMPAIGN_CATEGORIES,
  CAMPAIGN_GOALS,
  CAMPAIGN_EVENT_TYPES,
} from 'lib/configs';

const StyledDialog = styled(Dialog)`
  ${({ mobile }) =>
    mobile
      ? `
  .MuiDialog-paperFullWidth {
    border-radius: 8px;
    height: 100%;
    margin: 6px;
    max-height: calc(100vh - 12px);
    width: 100%;
  }

  .MuiFormControl-root {
    margin-top: 10px;
  }
  `
      : ''}
`;

const StyledDialogTitle = styled(DialogTitle)`
  padding: 20px 12px 12px 16px;

  h2 {
    font-size: 20px;
    font-weight: 600;
  }
`;

const StyledDialogContent = styled(DialogContent)`
  padding: 0px;
`;

const StyledBottomContent = styled(Box)`
  border-top: 1px solid ${colors.progressLightGrey};
`;

const StyledCloseButton = styled(Box)`
  cursor: pointer;
`;

const CampaignModal = ({
  data,
  teamMembers,
  errors,
  handleClose,
  handleSave,
  hasApiError,
  isCreating,
  isLoading,
  open,
  updateData,
  updateErrors,
}) => {
  const isMobile = isMobileView();
  const modalRef = useRef();

  const {
    name,
    displayName,
    description,
    url,
    category,
    goalType,
    goalAmount,
    stretchAmount,
    startDate,
    endDate,
    location,
    date,
    ticketPrice,
    ticketsSold,
    itemType,
    itemQuantity,
    itemValue,
    assignees,
    primaryAssigneeId,
  } = data;

  const onTextFieldChange = useCallback(
    (fieldName, hasEvent = true, format) =>
      (res) => {
        const newValue = hasEvent ? res?.target?.value : res;

        updateData({
          ...data,
          [fieldName]: format ? format(newValue) : newValue,
        });

        updateErrors({
          ...errors,
          [fieldName]: false,
        });
      },
    [data, errors]
  );

  const onSelect = useCallback(
    (fieldName, useValue = true) =>
      (selectedOption) => {
        updateData({
          ...data,
          [fieldName]: useValue ? selectedOption?.value : selectedOption,
        });

        updateErrors({
          ...errors,
          [fieldName]: false,
        });
      },
    [data, errors]
  );

  const onDateChange = useCallback(
    (fieldName) => (newDate) => {
      updateData({
        ...data,
        [fieldName]: formatDateObject(newDate, API_DATE_FORMAT),
      });
    },
    [data]
  );

  const onPrimaryAdministratorChange = useCallback(
    (value) => {
      let updatedAdministrators = assignees;

      // makes sure to select an administrator if it's primary
      if (value && !assignees.find((admin) => admin.value === value)) {
        updatedAdministrators = [
          ...assignees,
          teamMembers.find((admin) => admin.value === value),
        ];
      }

      updateData({
        ...data,
        primaryAssigneeId: value,
        assignees: updatedAdministrators,
      });
    },
    [assignees, teamMembers]
  );

  const isInKindGoal = goalType === CAMPAIGN_GOALS[1].value;

  return (
    <StyledDialog
      data-testid="campaign-modal"
      fullWidth
      maxWidth="md"
      mobile={Number(isMobile)}
      onClose={handleClose}
      open={open}
      ref={modalRef}
    >
      <Box
        alignItems="center"
        display="flex"
        justifyContent="space-between"
        pr={3}
      >
        <StyledDialogTitle id="CampaignModal-title">
          {translate(
            isCreating ? 'CREATE_NEW_CAMPAIGN_TITLE' : 'EDIT_CAMPAIGN_TITLE'
          )}
        </StyledDialogTitle>
        <StyledCloseButton
          alignItems="center"
          data-testid="CampaignModal-close-button"
          display="flex"
          onClick={handleClose}
        >
          <CloseIcon />
        </StyledCloseButton>
      </Box>
      <StyledDialogContent>
        <Box px={2} pb={4}>
          <TextField
            error={errors?.name}
            fullWidth
            helperText={
              errors?.name ? translate('CAMPAIGN_NAME_REQUIRED_LABEL') : ''
            }
            inputProps={{ 'data-testid': 'campaign-name-input' }}
            label={translate('CAMPAIGN_NAME_LABEL')}
            onChange={onTextFieldChange('name')}
            required
            value={name}
          />
          <Gap size={2} vertical />
          <TextField
            fullWidth
            inputProps={{ 'data-testid': 'campaign-display-name-input' }}
            label={translate('CAMPAIGN_DISPLAY_NAME_LABEL')}
            onChange={onTextFieldChange('displayName')}
            value={displayName}
          />
          <Gap size={2} vertical />
          <TextField
            fullWidth
            inputProps={{ 'data-testid': 'campaign-description-input' }}
            label={translate('CAMPAIGN_DESCRIPTION_LABEL')}
            onChange={onTextFieldChange('description')}
            value={description}
          />
          <Gap size={2} vertical />
          <TextField
            fullWidth
            inputProps={{ 'data-testid': 'campaign-url-input' }}
            label={translate('CAMPAIGN_WEBSITE_LABEL')}
            onChange={onTextFieldChange('url')}
            type="url"
            value={url}
          />
          <Gap size={2} vertical />
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextSelectField
                disableClearable
                id="campaign-category-selector"
                items={CAMPAIGN_CATEGORIES}
                label={translate('CAMPAIGN_CATEGORY_LABEL')}
                setValue={onSelect('category')}
                value={CAMPAIGN_CATEGORIES.find(
                  (item) => item.value === category
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextSelectField
                disableClearable
                id="campaign-goal-selector"
                items={CAMPAIGN_GOALS}
                label={translate('CAMPAIGN_GOAL_LABEL')}
                setValue={onSelect('goalType')}
                value={CAMPAIGN_GOALS.find((item) => item.value === goalType)}
              />
            </Grid>
          </Grid>
          <Gap size={2} vertical />
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <CurrencyInput
                amount={formatCurrency(goalAmount, true)}
                error={errors?.goalAmount}
                errorHelperText={translate(
                  'CAMPAIGN_GOAL_AMOUT_REQUIRED_LABEL'
                )}
                label={translate('CAMPAIGN_GOAL_AMOUNT_LABEL')}
                required
                returnedValue={NUMBER_FORMAT_RETURNED_VALUES.FLOAT_VALUE}
                setAmount={onTextFieldChange(
                  'goalAmount',
                  false,
                  formatCurrencyInputToCents
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <CurrencyInput
                amount={formatCurrency(stretchAmount, true)}
                error={errors?.stretchAmount}
                errorHelperText={translate(
                  'CAMPAIGN_STRETCH_GOAL_REQUIRED_LABEL'
                )}
                label={translate('CAMPAIGN_STRETCH_GOAL_LABEL')}
                returnedValue={NUMBER_FORMAT_RETURNED_VALUES.FLOAT_VALUE}
                setAmount={onTextFieldChange(
                  'stretchAmount',
                  false,
                  formatCurrencyInputToCents
                )}
              />
            </Grid>
          </Grid>
          <Gap size={2} vertical />
          <Visible when={isInKindGoal}>
            <TextField
              error={errors?.itemType}
              helperText={
                errors?.itemType
                  ? translate('CAMPAIGN_ITEM_TYPE_REQUIRED_LABEL')
                  : ''
              }
              fullWidth
              inputProps={{ 'data-testid': 'campaign-item-type-input' }}
              label={translate('CAMPAIGN_ITEM_TYPE_LABEL')}
              onChange={onTextFieldChange('itemType')}
              required
              value={itemType}
            />
            <Gap size={2} vertical />
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TextField
                  error={errors?.itemQuantity}
                  helperText={
                    errors?.itemQuantity
                      ? translate('CAMPAIGN_NUMBER_OF_ITEMS_REQUIRED_LABEL')
                      : ''
                  }
                  fullWidth
                  inputProps={{ 'data-testid': 'campaign-item-quantity-input' }}
                  label={translate('CAMPAIGN_NUMBER_OF_ITEMS_LABEL')}
                  min={0}
                  onChange={onTextFieldChange('itemQuantity', true, parseInt)}
                  required
                  value={itemQuantity}
                  type="number"
                />
              </Grid>
              <Grid item xs={6}>
                <CurrencyInput
                  amount={itemValue}
                  error={errors?.itemValue}
                  errorHelperText={translate(
                    'CAMPAIGN_ESTIMATED_ITEM_VALUE_REQUIRED_LABEL'
                  )}
                  label={translate(
                    isMobile
                      ? 'CAMPAIGN_ESTIMATED_VALUE_LABEL'
                      : 'CAMPAIGN_ESTIMATED_ITEM_VALUE_LABEL'
                  )}
                  required
                  returnedValue={NUMBER_FORMAT_RETURNED_VALUES.FLOAT_VALUE}
                  setAmount={onTextFieldChange('itemValue', false)}
                />
              </Grid>
            </Grid>
            <Gap size={2} vertical />
          </Visible>
          <Visible when={CAMPAIGN_EVENT_TYPES.includes(category)}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <TextField
                  error={errors?.location}
                  helperText={
                    errors?.location
                      ? translate('CAMPAIGN_EVENT_LOCATION_REQUIRED_LABEL')
                      : ''
                  }
                  fullWidth
                  inputProps={{
                    'data-testid': 'campaign-event-location-input',
                  }}
                  label={translate('CAMPAIGN_EVENT_LOCATION_LABEL')}
                  onChange={onTextFieldChange('location')}
                  required
                  value={location}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <DateInput
                  fullWidth
                  id="campaign-date-picker-event-date"
                  label={translate('CAMPAIGN_EVENT_DATE_LABEL')}
                  margin="none"
                  onChange={onDateChange('date')}
                  value={parseDateValue(date)}
                />
              </Grid>
            </Grid>
            <Gap size={2} vertical />
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <CurrencyInput
                  amount={ticketPrice}
                  error={errors?.ticketPrice}
                  errorHelperText={translate(
                    'CAMPAIGN_TICKET_PRICE_REQUIRED_LABEL'
                  )}
                  label={translate('CAMPAIGN_TICKET_PRICE_LABEL')}
                  required
                  returnedValue={NUMBER_FORMAT_RETURNED_VALUES.FLOAT_VALUE}
                  setAmount={onTextFieldChange('ticketPrice', false)}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  error={errors?.ticketsSold}
                  helperText={
                    errors?.ticketsSold
                      ? translate('CAMPAIGN_TICKET_SOLD_REQUIRED_LABEL')
                      : ''
                  }
                  fullWidth
                  inputProps={{ 'data-testid': 'campaign-tickets-sold-input' }}
                  label={translate('CAMPAIGN_TICKET_SOLD_LABEL')}
                  min={0}
                  onChange={onTextFieldChange('ticketsSold', true, parseInt)}
                  required
                  type="number"
                  value={ticketsSold}
                />
              </Grid>
            </Grid>
            <Gap size={2} vertical />
          </Visible>
          <Visible when={!CAMPAIGN_EVENT_TYPES.includes(category)}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <DateInput
                  fullWidth
                  id="campaign-date-picker-start-date"
                  label={translate('CAMPAIGN_START_DATE_LABEL')}
                  margin="none"
                  onChange={onDateChange('startDate')}
                  value={parseDateValue(startDate)}
                />
              </Grid>
              <Grid item xs={6}>
                <DateInput
                  fullWidth
                  id="campaign-date-picker-end-date"
                  label={translate('CAMPAIGN_END_DATE_LABEL')}
                  margin="none"
                  onChange={onDateChange('endDate')}
                  value={parseDateValue(endDate)}
                />
              </Grid>
            </Grid>
          </Visible>
          <Gap size={2} vertical />
          <TextSelectField
            id="campaign-assignees-selector"
            error={errors?.assignees}
            errorHelperText={translate(
              'CAMPAIGN_ADMINISTRATORS_REQUIRED_LABEL'
            )}
            items={teamMembers}
            multiple
            value={assignees}
            setValue={onSelect('assignees', false)}
            label={translate('CAMPAIGN_ADMINISTRATORS_LABEL')}
            primaryValue={primaryAssigneeId}
            required
            setPrimaryValue={onPrimaryAdministratorChange}
            usePrimary
          />
        </Box>
      </StyledDialogContent>
      <StyledBottomContent
        display="flex"
        alignItems="center"
        justifyContent={isLoading ? 'center' : 'flex-start'}
        p={2}
      >
        <Visible fallback={<Loader />} when={!isLoading}>
          <Visible
            when={Boolean(values(errors).find((error) => error) || hasApiError)}
          >
            <Box px={1}>
              <Typography data-testid="campaign-modal-error" color="error">
                {translate(
                  hasApiError
                    ? 'API_DEFAULT_ERROR_2'
                    : 'CAMPAIGN_FORM_FIX_ERRORS_MESSAGE'
                )}
              </Typography>
            </Box>
          </Visible>
          <Box
            display="flex"
            flex="1"
            flexDirection="row"
            justifyContent="flex-end"
          >
            <CustomButton
              buttonType="link"
              color="primary"
              dataTestId="campaign-modal-cancel-button"
              label={translate('CANCEL')}
              onClick={handleClose}
            />
            <Gap />
            <CustomButton
              buttonType="primary"
              dataTestId="campaign-modal-save-button"
              label={translate('SAVE')}
              onClick={handleSave}
            />
          </Box>
        </Visible>
      </StyledBottomContent>
    </StyledDialog>
  );
};

CampaignModal.propTypes = {
  data: shape(CampaignType).isRequired,
  teamMembers: arrayOf(TextSelectFieldValueType),
  errors: shape({}),
  handleClose: func.isRequired,
  handleSave: func.isRequired,
  hasApiError: bool,
  isCreating: bool.isRequired,
  isLoading: bool,
  open: bool.isRequired,
  updateData: func.isRequired,
  updateErrors: func.isRequired,
};

CampaignModal.defaultProps = {
  teamMembers: [],
  errors: {},
  hasApiError: false,
  isLoading: false,
};

export { CampaignModal };
