import { useMemo } from 'react';
import { CONSTITUENT_TYPES } from 'lib/constants/constituentConstants';

const useDonorConfig = ({ constituentType, orgConfig, userConfig }) => {
  const isUserType = useMemo(
    () => constituentType === CONSTITUENT_TYPES.PERSON,
    [constituentType]
  );
  const isOrgType = useMemo(
    () => constituentType === CONSTITUENT_TYPES.ORG,
    [constituentType]
  );

  const config = useMemo(() => {
    if (isUserType) {
      return userConfig;
    }
    return isOrgType ? orgConfig : [];
  }, [constituentType]);

  return {
    config,
    isOrgType,
    isUserType,
  };
};

export { useDonorConfig };
