import React, { useState } from 'react';
import { func } from 'prop-types';
import { EMPTY_FUNC, USER_VIEW_ENUMS } from 'lib/constants';
import { ShowForViews } from './ShowForViews';
import { MultiActionDialog } from './MultiActionDialog';
import { AddButton } from './AddButton';

const MultiActionDialogManager = ({ onEntityCreated }) => {
  const [actionDialogOpen, setActionDialogOpen] = useState(false);

  return (
    <ShowForViews showFor={[USER_VIEW_ENUMS.ADMIN, USER_VIEW_ENUMS.EDITOR]}>
      <MultiActionDialog
        open={actionDialogOpen}
        handleClose={() => setActionDialogOpen(false)}
        onEntityCreated={onEntityCreated}
      />
      <AddButton onClick={() => setActionDialogOpen(true)} />
    </ShowForViews>
  );
};

MultiActionDialogManager.propTypes = {
  onEntityCreated: func,
};

MultiActionDialogManager.defaultProps = {
  onEntityCreated: EMPTY_FUNC,
};

export { MultiActionDialogManager };
