import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { usersActions } from 'rdx/actions';

export default function useConstituentWithTeamMembers(constituent) {
  const dispatch = useDispatch();
  const [teamMemberFilter, setTeamMemberFilter] = React.useState('');
  const [assignedTeamMembers, setAssignedTeamMembers] = React.useState([]);
  const { developers = [] } = constituent;

  const { teamMembers, isTeamMembersLoading, ownerOrgId } = useSelector(
    (state) => ({
      teamMembers: state.usersList.users,
      isTeamMembersLoading: state.usersList.isFetching,
      ownerOrgId: state.authentication?.ownerOrgId,
    })
  );

  // Sort helper function sorting by primary, then secondary
  const sortTeamMembers = (teamMembersToSort) =>
    teamMembersToSort?.sort((a, b) => {
      if (a.isPrimary) {
        return -1;
      }

      if (a.isSecondary && !b.isPrimary && !b.isSecondary) {
        return -1;
      }

      return 1;
    });

  const teamMembersWithConstituentAssignment = () => {
    const createdAssignedTeamMembers = teamMembers.map((originalUser) => {
      const user = { ...originalUser };
      developers.forEach((developer) => {
        if (developer.userId === user.userId) {
          user.isPrimary = developer.primary;
          user.isSecondary = !developer.primary;
        }
      });

      return user;
    });

    setAssignedTeamMembers(sortTeamMembers(createdAssignedTeamMembers));
  };

  React.useEffect(() => {
    teamMembersWithConstituentAssignment();
  }, [teamMembers]);

  React.useEffect(() => {
    dispatch(usersActions.fetchUsersList(ownerOrgId));
  }, []);

  const setPrimary = (userId, isChecked) => {
    let newPrimaryFoundIndex;
    let shouldClearAll;

    let updatedAssignedTeamMembers = assignedTeamMembers?.map(
      (teamMember, index) => {
        const assignedTeamMember = { ...teamMember };

        // Allow one primary assignee
        assignedTeamMember.isPrimary =
          userId === teamMember.userId && isChecked;

        // Set secondary if primary is set
        if (assignedTeamMember.isPrimary) {
          assignedTeamMember.isSecondary = true;
        }

        // Handle unselecting the primary user
        if (userId === teamMember.userId && !isChecked) {
          // If another secondary user is selected 'below' this user, make them the primary
          newPrimaryFoundIndex = assignedTeamMembers.findIndex(
            (newPTeamMember, secondaryIndex) =>
              newPTeamMember.isSecondary && secondaryIndex > index
          );

          // If no new primary is found, clear all
          if (newPrimaryFoundIndex < 0) {
            shouldClearAll = true;
          }
        }

        return assignedTeamMember;
      }
    );

    if (newPrimaryFoundIndex >= 0) {
      updatedAssignedTeamMembers[newPrimaryFoundIndex].isPrimary = true;
    }

    // If no match was found, clear all
    if (shouldClearAll) {
      updatedAssignedTeamMembers = assignedTeamMembers?.map((teamMember) => {
        const assignedTeamMember = { ...teamMember };

        assignedTeamMember.isPrimary = false;
        assignedTeamMember.isSecondary = false;

        return assignedTeamMember;
      });
    }

    setAssignedTeamMembers(updatedAssignedTeamMembers);
  };

  const setSecondary = (userId, isChecked) => {
    const updatedAssignedTeamMembers = assignedTeamMembers?.map(
      (teamMember) => {
        const assignedTeamMember = { ...teamMember };

        // Allow multiple secondary assignees
        assignedTeamMember.isSecondary =
          (userId === teamMember.userId && isChecked) ||
          (userId !== teamMember.userId && teamMember.isSecondary);

        // If no primary exists, set the secondary user selected as the primary as well
        if (
          !assignedTeamMembers.find((member) => member.isPrimary === true) &&
          userId === teamMember.userId &&
          isChecked
        ) {
          assignedTeamMember.isPrimary = true;
        }

        return assignedTeamMember;
      }
    );

    setAssignedTeamMembers(updatedAssignedTeamMembers);
  };

  // Filter helper function
  const filterTeamMembers = (teamMembersToFilter) => {
    const filteredItems = teamMembersToFilter?.filter((item) => {
      const isEmptyFilter = teamMemberFilter === '';
      const hasFilterDisplayNameValue =
        item.displayName &&
        item.displayName.toLowerCase().includes(teamMemberFilter.toLowerCase());

      return isEmptyFilter || hasFilterDisplayNameValue;
    });

    return filteredItems;
  };

  return {
    teamMemberFilter,
    setTeamMemberFilter,
    filterTeamMembers,
    assignedTeamMembers,
    isTeamMembersLoading,
    setPrimary,
    setSecondary,
  };
}
