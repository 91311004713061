import React from 'react';
import { func } from 'prop-types';
import { AUTH_ROUTES } from 'lib/constants';
import { Avatar, NavigationListItem } from 'components';
import { useCompanyNavListItem } from './hooks';

const COMPANY_NAV_LIST_ITEM_SIZE = '48px';

const CompanyNavListItem = ({ onClick }) => {
  const { avatar, orgNameInitials, itemText } = useCompanyNavListItem();

  return (
    <NavigationListItem
      exact
      navRoute={AUTH_ROUTES.LANDING}
      itemText={itemText}
      onClick={onClick}
    >
      <Avatar
        data-testid="navigation-org-id-icon"
        alt="OrgIcon"
        src={avatar}
        size={COMPANY_NAV_LIST_ITEM_SIZE}
      >
        {orgNameInitials}
      </Avatar>
    </NavigationListItem>
  );
};

CompanyNavListItem.propTypes = {
  onClick: func,
};

CompanyNavListItem.defaultProps = {
  onClick: null,
};

export { CompanyNavListItem };
