import React from 'react';
import { func, string, number } from 'prop-types';
import Box from '@material-ui/core/Box';
import LinearProgress from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/styles/makeStyles';
import { ListItemContainer, CustomLink } from 'components';
import { ShowForViews } from 'components/common/ShowForViews';
import { translate } from 'lib/intl';
import { USER_VIEW_ENUMS } from 'lib/constants/common';
import { formatCurrency } from 'lib/utils';
import { getNormalizedPercentage } from 'lib/utils/numberUtils';

const useStyles = makeStyles((theme) => ({
  goalTitle: {
    fontWeight: theme.typography.fontWeightBold,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  goalDurationText: {
    color: theme.palette.grey.dark,
  },
  goalCurrentAmount: {
    fontWeight: theme.typography.fontWeightBold,
  },
  editButton: {
    fontWeight: theme.typography.fontWeightMedium,
    marginLeft: theme.spacing(1),
  },
}));

const GoalsProgress = ({
  daysLeft,
  onEditClick,
  goalTitle,
  goalAmount,
  goalCurrentAmount,
  goalProjectedRate,
}) => {
  const classes = useStyles();

  return (
    <ListItemContainer>
      <Box
        alignItems="center"
        display="flex"
        flex="1"
        justifyContent="space-between"
        overflow="hidden"
      >
        <Typography className={classes.goalTitle} variant="body2">
          {goalTitle}
        </Typography>
        {onEditClick && (
          <ShowForViews
            showFor={[USER_VIEW_ENUMS.ADMIN, USER_VIEW_ENUMS.EDITOR]}
          >
            <CustomLink
              className={classes.editButton}
              data-testid="edit-goal-btn"
              onClick={onEditClick}
              variant="body2"
            >
              {translate('GOALS_EDIT')}
            </CustomLink>
          </ShowForViews>
        )}
      </Box>

      <Typography variant="body2" className={classes.goalDurationText}>
        {translate('DAYS_LEFT', { number: daysLeft })}
      </Typography>

      <Box pb={0.5} pt={1}>
        <LinearProgress
          value={getNormalizedPercentage(goalCurrentAmount, goalAmount)}
          valueBuffer={getNormalizedPercentage(goalProjectedRate, goalAmount)}
          variant="buffer"
          color="primary"
        />
      </Box>

      <Box display="flex" flex="1" justifyContent="flex-end">
        <Typography className={classes.goalCurrentAmount} variant="body2">
          {formatCurrency(goalCurrentAmount, true)}
        </Typography>
        <Typography variant="body2">&nbsp;/&nbsp;</Typography>
        <Typography variant="body2">
          {formatCurrency(goalAmount, true)}
        </Typography>
      </Box>
    </ListItemContainer>
  );
};

GoalsProgress.propTypes = {
  daysLeft: number,
  goalTitle: string.isRequired,
  goalAmount: number.isRequired,
  goalCurrentAmount: number,
  goalProjectedRate: number,
  onEditClick: func,
};

GoalsProgress.defaultProps = {
  daysLeft: 0,
  goalCurrentAmount: 0,
  goalProjectedRate: 0,
  onEditClick: null,
};

export { GoalsProgress };
