import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { AUTH_ROUTES, PUBLIC_ROUTES } from 'lib/constants';

const mapStateToProps = (state) => {
  const { authToken } = state.authentication;
  const { prevPath } = state.location;

  return {
    authToken,
    prevPath,
  };
};

const useBackButton = ({ path, callback }) => {
  const history = useHistory();

  const { prevPath, authToken } = useSelector(mapStateToProps);

  const currentPath = history?.location?.pathname;

  const onBackClick = useCallback(() => {
    if (callback) {
      callback();
    }

    if (path) {
      history.push(path);
      return;
    }

    if (currentPath === prevPath) {
      return;
    }

    if (Object.values(AUTH_ROUTES).includes(prevPath) && !authToken) {
      history.push(PUBLIC_ROUTES.LANDING);
    } else {
      history.goBack();
    }
  }, [path, prevPath, authToken, currentPath]);

  return {
    onBackClick,
  };
};

export { useBackButton };
