import React from 'react';
import PropTypes from 'prop-types';
import Autocomplete, {
  createFilterOptions,
} from '@material-ui/lab/Autocomplete';
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import classNames from 'classnames';
import cuid from 'cuid';
import { EMPTY_FUNC } from 'lib/constants';
import { translate } from 'lib/intl';
import { uniq } from 'rambdax';
import { useSelector } from 'react-redux';

const filter = createFilterOptions();

const ConstituentLabelSelect = ({
  className,
  freeSolo,
  id,
  name,
  onChange,
  value,
  ...rest
}) => {
  const [uid] = React.useState(id || cuid());
  const labels = useSelector((state) => state.orgInfo?.data?.labels || []);

  const handleChange = (event, val, reason) => {
    let isCreating = reason === 'create-option';
    const newValue = val.map((item) => {
      // The `val` is an array of strings. A newly created item however, will be
      // an object with an `inputValue`. It's possible for a new item to be
      // created if the user selects the item by clicking on it in the dropdown
      // rather which would trigger a "select-option" reason rather than hitting
      // the reutrn key which would trigger a "create-option" reason.
      if (item.inputValue) {
        isCreating = true;
        return item.inputValue.trim();
      }
      return item.trim();
    });

    if (isCreating) {
      // If the user is creating a new option not on the list, we need to do a
      // little validation.
      const createdOption = newValue[newValue.length - 1].toLowerCase();

      // Check that the newly created option is not a case insensitive duplicate
      // of an existing label. If it is, then replace it with that option.
      const lowerCaseLabels = labels.map((label) => label.toLowerCase());
      const indexOfMatch = lowerCaseLabels.indexOf(createdOption);
      if (indexOfMatch >= 0) {
        newValue[newValue.length - 1] = labels[indexOfMatch];
      }

      // Check that the newly created option is not a case insenstivie duplicate
      // of a previously selected value. If it is, remove it.
      const previouslySelectedValues = newValue
        .slice(0, -1)
        .map((label) => label.toLowerCase());
      if (previouslySelectedValues.includes(createdOption)) {
        newValue.pop();
      }
    }
    onChange({ ...event, target: { name, value: newValue } });
  };

  return (
    <Autocomplete
      multiple
      className={classNames(className, 'mb-0 w-full')}
      id={uid}
      options={uniq([...value, ...labels])}
      value={value}
      onChange={handleChange}
      name={name}
      freeSolo={freeSolo}
      filterOptions={(options, params) => {
        const filtered = filter(options, params);
        // Suggest the creation of a new value
        if (params.inputValue !== '' && !filtered.length && freeSolo) {
          filtered.push({
            inputValue: params.inputValue,
            title: translate('ADD_ITEM', { item: params.inputValue }),
          });
        }
        return filtered;
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          label={translate('CONSTITUENT_LABELS')}
          placeholder={translate('CONSTITUENT_LABELS')}
          size="small"
        />
      )}
      renderOption={(option) => (
        <div className="flex items-center w-full">
          <div>{option.title || option}</div>
          {!option.title && (
            <Checkbox className="ml-auto" checked={value.includes(option)} />
          )}
        </div>
      )}
      {...rest}
    />
  );
};

ConstituentLabelSelect.propTypes = {
  ...Autocomplete.propTypes,
  className: PropTypes.string,
  freeSolo: PropTypes.bool,
  id: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.arrayOf(PropTypes.string),
};

ConstituentLabelSelect.defaultProps = {
  className: '',
  freeSolo: false,
  id: null,
  name: 'labels',
  onChange: EMPTY_FUNC,
  value: [],
};

export default ConstituentLabelSelect;
