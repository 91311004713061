import { max } from 'rambdax';
import { differenceInDays } from 'date-fns';
import { colors } from 'styles/theme';

const getLineColor = ({
  isArchived,
  isCompleted,
  isEventType,
  isGoalReachable,
}) => {
  if (isArchived || isCompleted) {
    return 'none';
  }
  if (isEventType) {
    return colors.lightBlue;
  }
  if (isGoalReachable) {
    return colors.green;
  }
  return colors.red;
};

const getDateInfoDistance = ({
  isArchived,
  isEventType,
  eventDate,
  endDate,
  startDate,
}) => {
  const today = new Date();
  const formattedStartDate = new Date(startDate);
  const formattedEndDate = new Date(endDate);
  const formattedEventDate = new Date(eventDate);

  const daysBeforeEnd = max(
    differenceInDays(
      isEventType ? formattedEventDate : formattedEndDate,
      today
    ),
    0
  );
  const durationInDays = isEventType
    ? 1
    : differenceInDays(formattedEndDate, formattedStartDate);

  if (isArchived) {
    return {
      days: durationInDays,
      label: 'CAMPAIGN_TOTAL_DAYS_LABEL',
    };
  }

  if (isEventType) {
    return {
      days: daysBeforeEnd,
      label: 'CAMPAIGN_DAYS_TILL_EVENT_LABEL',
    };
  }

  const daysBeforeStart = differenceInDays(formattedStartDate, today);

  return {
    days: daysBeforeStart > 0 ? daysBeforeStart : daysBeforeEnd,
    label:
      daysBeforeStart > 0
        ? 'CAMPAIGN_DAYS_TILL_START_LABEL'
        : 'CAMPAIGN_DAYS_LEFT_LABEL',
  };
};

export { getLineColor, getDateInfoDistance };
