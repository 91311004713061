import { feedService } from 'services/feed';
import { createAction } from 'lib/utils';

export default function createRefreshFeedAction(types) {
  return ({ ownerOrgId }) =>
    async (dispatch) => {
      try {
        dispatch(createAction({ type: types.request }));

        // Fetch the first page, as this is a 'refresh' of the feed
        const response = await feedService.fetchFeed({
          ownerOrgId,
          pageFrom: 0,
        });

        dispatch(createAction({ type: types.success, data: response?.data }));
      } catch (error) {
        dispatch(createAction({ type: types.failure, error }));
      }
    };
}
