import React from 'react';
import { func, number, shape, string } from 'prop-types';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/styles/makeStyles';
import {
  EMPTY_FUNC,
  NUMBER_FORMAT_RETURNED_VALUES,
} from 'lib/constants/common';
import { Loader, CustomButton, CurrencyInput } from 'components';
import { translate } from 'lib/intl';
import { useGoalForm } from 'lib/hooks/goals';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    background: theme.palette.background.white,
    width: '100%',
    flexDirection: 'column',
  },
  title: {
    ...theme.typography.subtitle1,
    fontWeight: '500',
  },
  form: {
    padding: `0 ${theme.spacing(2)}px ${theme.spacing(3)}px`,
  },
  buttonContainer: {
    alignItems: 'flex-end',
    borderTop: `1px solid ${theme.palette.progressBar.lightGrey}`,
    display: 'flex',
    padding: theme.spacing(2),
  },
  loaderContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
  },
}));

const GoalsForm = ({ onClose, existingGoal }) => {
  const classes = useStyles();

  const {
    goalTotal,
    setGoalTotal,
    errors,
    isUpdating,
    hasError,
    handleSave,
    handleDelete,
  } = useGoalForm(existingGoal, onClose);

  const isEditing = Boolean(existingGoal);
  const currentYear = new Date().getFullYear();

  return (
    <Box className={classes.container}>
      <Box px={2} py={1.5}>
        <Typography className={classes.title}>
          {translate('GOALS_SET', { year: currentYear })}
        </Typography>
      </Box>
      <form className={classes.form}>
        <CurrencyInput
          label={translate('GOALS_TOTAL')}
          amount={goalTotal}
          setAmount={setGoalTotal}
          error={hasError}
          errorHelperText={translate('GOALS_TOTAL_ERROR_REQUIRED')}
          returnedValue={NUMBER_FORMAT_RETURNED_VALUES.FLOAT_VALUE}
        />
      </form>
      <Box
        className={classes.buttonContainer}
        justifyContent={isUpdating ? 'center' : 'flex-start'}
      >
        {isUpdating ? (
          <Loader isCentered />
        ) : (
          <>
            {Boolean(errors?.length) && (
              <Box px={1}>
                <Typography color="error">
                  {translate('GENERIC_ERROR')}
                </Typography>
              </Box>
            )}
            <Box
              display="flex"
              flex="1"
              flexDirection="row"
              justifyContent="flex-end"
            >
              {isEditing && (
                <Box pr={1}>
                  <CustomButton
                    buttonType="link"
                    dataTestId="goals-modal-delete-button"
                    label={translate('GOALS_DELETE')}
                    onClick={handleDelete}
                  />
                </Box>
              )}
              <Box display="flex" flex="1" justifyContent="space-between">
                <Box pr={1}>
                  <CustomButton
                    buttonType="link"
                    dataTestId="goals-modal-cancel-button"
                    label={translate('GOALS_CANCEL')}
                    onClick={onClose}
                  />
                </Box>
                <CustomButton
                  dataTestId="goals-modal-save-button"
                  label={translate('GOALS_SAVE')}
                  onClick={handleSave}
                />
              </Box>
            </Box>
          </>
        )}
      </Box>
    </Box>
  );
};

GoalsForm.propTypes = {
  onClose: func,
  existingGoal: shape({
    name: string,
    target: number,
  }),
};

GoalsForm.defaultProps = {
  onClose: EMPTY_FUNC,
  existingGoal: {},
};

export { GoalsForm };
