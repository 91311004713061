export const DONATIONS = {
  ADD_NEW_DONATION: 'Add New Donation',
  DONATION_GENERAL: 'General',
  DONATION_PLEDGE: 'Pledge',
  DONATION_RECURRING: 'Recurring',
  DONATION_RESTRICTED: 'Restricted Gift',
  DONATION_IN_KIND: 'In Kind',
  DONATION_PLANNED: 'Planned Giving',
  DONATION_TIME: 'Time',
  DONATION_GRANT: 'Grant',
  DONATION_AMOUNT_LABEL: 'Donation Amount',
  DONATION_AMOUNT_ERROR_REQUIRED: 'Donation Amount Required',
  DONATION_NOTES_LABEL: 'Notes',
  DONATION_CONTACT_NAME_LABEL: 'Contact Name',
  DONATION_CONTACT_NAME_ERROR_REQUIRED: 'Contact Name Required',
  DONATION_TYPE_LABEL: 'Donation Type',
  DONATION_TYPE_ERROR_REQUIRED: 'Donation Type Required',
  DONATION_DATE_LABEL: 'Donation Date',
  DONATION_PAYMENT_CASH: 'Cash',
  DONATION_PAYMENT_CHECK: 'Check',
  DONATION_PAYMENT_CREDIT_CARD: 'Credit Card',
  DONATION_PAYMENT_BANK_WIRE: 'Bank Wire',
  DONATION_PAYMENT_APP: 'Payment App',
  DONATION_PAYMENT_CRYPTO: 'Cryptocurrency',
  DONATION_PAYMENT_LABEL: 'Payment Method',
  DONATION_PAYMENT_ERROR_REQUIRED: 'Payment Method Required',
  PLEDGE_AMOUNT_LABEL: 'Pledge Amount',
  PLEDGE_AMOUNT_ERROR_REQUIRED: 'Pledge Amount Required',
  PLEDGE_DUE_DATE_LABEL: 'Pledge Due Date',
  PLEDGE_DUE_DATE_ERROR_REQUIRED: 'Pledge Due Date Required',
  RECURRING_CADENCE_LABEL: 'Cadence',
  RECURRING_CADENCE_ERROR_REQUIRED: 'Cadence Required',
  RECURRING_START_DATE_LABEL: 'Start Date',
  RECURRING_START_DATE_ERROR_REQUIRED: 'Start Date Required',
  RESTRICTION_AREA_LABEL: 'Area of Restriction',
  RESTRICTION_AREA_ERROR_REQUIRED: 'Area of Restriction Required',
  IN_KIND_ITEM_DONATED_LABEL: 'Item Donated',
  IN_KIND_ITEM_DONATED_ERROR_REQUIRED: 'Item Donated Required',
  IN_KIND_ITEM_QUANTITY_LABEL: 'Quantity',
  IN_KIND_ITEM_QUANTITY_ERROR_REQUIRED: 'Quantity Required',
  IN_KIND_ITEM_VALUE_LABEL: 'Value',
  IN_KIND_ITEM_VALUE_ERROR_REQUIRED: 'Value Required',
  PLANNED_GIVING_ESTIMATED_VALUE_LABEL: 'Estimated Donation Value',
  PLANNED_GIVING_ESTIMATED_VALUE_ERROR_REQUIRED:
    'Estimated Donation Value Required',
  DONATION_CREATION_SUCCESS: 'Donation successfully created',
  DONATION_CREATION_FAILURE: 'Error, donation creation failed',
  DONATION_DELETE_CONFIRMATION:
    'Are you sure you want to delete this donation?',
  DONATION_DELETE_DONATION: 'Delete Donation',
  DONATION_DELETE_SUCCESS: 'Donation successfully deleted',
  DONATION_DELETE_FAILURE: 'Error, donation deletion failed',
  DONATION_VOLUNTEER_LABEL: 'Volunteer Type',
  DONATION_VOLUNTEERED_EVENTS: 'Events',
  DONATION_VOLUNTEERED_CAMPAIGNS: 'Campaigns',
  DONATION_VOLUNTEERED_GENERAL: 'General',
  DONATION_VOLUNTEERED_GIFTS: 'Gifts',
  DONATION_VOLUNTEERED_OPERATIONS: 'Operations',
  DONATION_VOLUNTEERED_GRANTS: 'Grants',
  DONATION_VOLUNTEERED_GOVERNANCE: 'Governance',
  DONATION_VOLUNTEERED_ADVOCACY: 'Advocacy',
  DONATION_VOLUNTEERED_OTHER: 'Other',
  DONATION_VOLUNTEER_ERROR_REQUIRED: 'Donation Volunteer Type Required',
  DONATION_TIME_VALUE_LABEL: 'Value',
  DONATION_VERY_HIGH_VALUE: 'Very High',
  DONATION_HIGH_VALUE: 'High',
  DONATION_MODERATE_VALUE: 'Moderate',
  DONATION_LOW_VALUE: 'Low',
  DONATION_MINOR_VALUE: 'Minor',
  DONATION_VALUE_REQUIRED: 'Value Required',
  DONATION_TIME_AMOUNT: 'Amount of Time',
  DONATION_TIME_AMOUNT_REQUIRED: 'Amount of Time Required',
  DONATION_TIME_TYPE: 'Type',
  DONATION_TIME_TYPE_REQUIRED: 'Type Required',
  DONATION_MINUTES: 'Minutes',
  DONATION_HOURS: 'Hours',
  DONATION_DAYS: 'Days',
  DONATION_CAMPAIGN: 'Campaign',
  DONATION_EVENTS: 'Events',
  DONATION_CAMPAIGNS: 'Campaigns',
  DONATION_GENERAL_CORPORATE: 'General Corporate',
  DONATION_GIFTS: 'Major Gifts',
  DONATION_OPERATIONS: 'Admin/Operations',
  DONATION_FOUNDATIONS: 'Foundations',
  DONATION_ADVOCACY: 'Advocacy',
  DONATION_START_DATE_CADENCE: 'Start Date - Cadence',
  RECURRING_DONATION_MONTHLY_LABEL: 'Monthly',
  RECURRING_DONATION_QUARTERLY_LABEL: 'Quarterly',
  RECURRING_DONATION_YEARLY_LABEL: 'Yearly',
  GRANT_AMOUNT_LABEL: 'Grant Amount',
  GRANT_NAME_LABEL: 'Grant Name',
  FUNDING_ORGANIZATION_LABEL: 'Funding Organization',
  RESTRICTED_LABEL: 'Restricted',
  CONDITIONS_OF_RESTRICTION_LABEL: 'Conditions of Restriction',
  ASSOCIATED_TEAM_MEMBERS_LABEL: 'Associated Team Members',
};
