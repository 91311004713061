import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box, List, ListItem, Menu, Fade } from '@material-ui/core';
import {
  Avatar,
  CompanyNavListItem,
  UserProfileBottomLinks,
  UserProfileSignOutButton,
  UserProfileUpper,
  EditProfileModal,
} from 'components';
import { useCurrentUser } from 'lib/hooks';
import { getInitials } from 'lib/utils';
import NavigationList from './NavigationList';
import { UPPER_NAV_ITEMS, LOWER_NAV_ITEMS } from './navigationConfig';
import useStyles from './desktopNavigationStyles';

const DesktopNavigation = ({ children }) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const { user } = useCurrentUser();
  const [isEditModalOpen, setEditModalOpen] = useState(false);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box className={classes.root}>
      <Box className={classes.navContainer}>
        <List component="nav" aria-label="upper-main">
          <CompanyNavListItem />
          <NavigationList items={UPPER_NAV_ITEMS} />
        </List>
        <List component="nav" aria-label="lower-main">
          <NavigationList items={LOWER_NAV_ITEMS} />
          <ListItem
            button
            className={classes.employeeItem}
            onClick={handleMenu}
          >
            <Avatar alt="UserAvatar" src="userAvatarImage">
              {getInitials(user?.displayName)}
            </Avatar>
          </ListItem>
        </List>
      </Box>
      <Box className={classes.contentContainer}>{children}</Box>
      <Menu
        data-testid="desktop-navigation-menu-button"
        id="menu-appbar"
        anchorEl={anchorEl}
        keepMounted
        style={{
          width: '350px',
        }}
        getContentAnchorEl={null}
        transformOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        open={open}
        onClose={handleClose}
        TransitionComponent={Fade}
      >
        <UserProfileUpper
          setEditModalOpen={setEditModalOpen}
          closeProfile={handleClose}
        />
        <UserProfileSignOutButton />
        <UserProfileBottomLinks />
      </Menu>
      <EditProfileModal
        open={isEditModalOpen}
        handleClose={() => setEditModalOpen(false)}
      />
    </Box>
  );
};

DesktopNavigation.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

DesktopNavigation.defaultProps = {
  children: null,
};

export default DesktopNavigation;
