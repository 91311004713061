import React from 'react';
import PropTypes from 'prop-types';
import { Box, makeStyles, Typography } from '@material-ui/core';
import { FeedTypeMap, FeedType } from 'lib/types';
import { formatDateObject } from 'lib/utils';
import { Visible } from 'components';
import { colors } from 'styles/theme';
import { translate } from 'lib/intl';

const useStyles = makeStyles((theme) => ({
  feedItem: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    '& > *': {
      paddingRight: '8px',
    },
    paddingBottom: '6px',
  },
  typeFont: {
    fontSize: '12px',
    fontWeight: '600',
    letterSpacing: '.25px',
  },
  active: {
    border: `1px solid ${theme.palette.primary.main}`,
  },
}));

const FeedItemTitle = ({ type, date, pinned }) => {
  const styles = useStyles();

  return (
    <Box className={styles.feedItem}>
      <Visible when={Boolean(type && date)}>
        <img
          alt="Type Icon"
          style={{ height: '16px' }}
          src={FeedTypeMap[type]?.icon}
        />
        <Typography
          className={styles.typeFont}
          style={{
            color: FeedTypeMap[type]?.color,
          }}
        >
          {FeedTypeMap[type]?.label}
        </Typography>
        <Typography variant="caption">
          {formatDateObject(new Date(date))}
        </Typography>
        <Visible when={Boolean(pinned)}>
          <Typography variant="caption" style={{ color: colors.lightBlue }}>
            {translate('PINNED')}
          </Typography>
        </Visible>
      </Visible>
    </Box>
  );
};

FeedItemTitle.propTypes = {
  type: FeedType,
  date: PropTypes.string,
  pinned: PropTypes.bool,
};

FeedItemTitle.defaultProps = {
  type: null,
  date: null,
  pinned: false,
};

export { FeedItemTitle };
