import React, { useRef } from 'react';
import Box from '@material-ui/core/Box';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import {
  BackButton,
  CampaignModal,
  CampaignStatistics,
  CustomLink,
  PageTabs,
  Visible,
  Feed,
  Loader,
  MultiActionDialogManager,
  PageLoader,
} from 'components';
import {
  BasicPageLayout,
  GoalsProgress,
  CommonInfoSection,
} from 'components/common';
import { ShowForViews } from 'components/common/ShowForViews';
import { AUTH_ROUTES, PAGE_TABS_HEIGHT, ZERO } from 'lib/constants';
import { USER_VIEW_ENUMS } from 'lib/constants/common';
import { translate } from 'lib/intl';
import { formatUrl, isMobileView } from 'lib/utils';
import { getPercentage } from 'lib/utils/numberUtils';
import { formatWebAddress } from 'lib/configs';
import { useCampaignFeed } from 'lib/hooks';
import { useCampaignForm } from 'lib/hooks/useCampaignForm';
import { generatePath } from 'react-router-dom';

import { useCampaignPage } from './campaignPageHooks';
import { useStyles } from './campaignPageStyles';

const CampaignDonations = React.lazy(() =>
  import(
    /* webpackChunkName: "campaign-donations" */
    '../../components/campaign/CampaignDonations'
  )
);

const CampaignReports = React.lazy(() =>
  import(
    /* webpackChunkName: "campaign-reports" */
    '../../components/campaign/CampaignReports'
  )
);

const CampaignPage = () => {
  const isMobile = isMobileView();
  const styles = useStyles();
  const containerRef = useRef(null);
  const topContainerId = 'campaign-page-container';

  const { campaignId, campaign, tabIdx, tabs, onTabClick, showPageLoader } =
    useCampaignPage();

  const {
    // modal state related
    isCampaignModalOpen,
    openCampaignModal,
    closeCampaignModal,
    modalData,
    setModalData,
    modalErrors,
    setModalErrors,

    // api related
    compaignApiError,
    isCampaignSaving,
    saveCampaign,
    teamMembers,
  } = useCampaignForm();

  const { feed, getFeedPage, setActiveFeedItem, activeFeedItemId } =
    useCampaignFeed(campaignId);

  return (
    <BasicPageLayout
      data-testid="campaign-details-page"
      id={topContainerId}
      ref={containerRef}
      width="100%"
    >
      <CommonInfoSection>
        <div className="flex justify-between pb-2">
          <BackButton
            label={translate('ALL_CAMPAIGNS_LABEL')}
            path={AUTH_ROUTES.CAMPAIGNS}
          />
          <ShowForViews
            showFor={[USER_VIEW_ENUMS.ADMIN, USER_VIEW_ENUMS.EDITOR]}
          >
            <CustomLink
              data-testid="edit-campaign"
              onClick={() => openCampaignModal(campaign)}
            >
              {translate('EDIT')}
            </CustomLink>
          </ShowForViews>
        </div>
        <Typography className={styles.campaignName} variant="h5">
          {campaign.displayName}
        </Typography>
        <Typography className={styles.campaignDescription} variant="body2">
          {campaign.description}
        </Typography>
        <Link
          className={styles.campaignUrl}
          data-testid="campaign-url"
          href={formatUrl(campaign.url)}
          rel="noopener noreferrer"
          target="_blank"
          variant="body2"
        >
          {formatWebAddress(campaign.url)}
        </Link>
        <GoalsProgress
          daysLeft={campaign.daysLeft}
          goalTitle={translate('CAMPAIGN_GOAL_LABEL')}
          goalAmount={campaign.goalAmount}
          goalCurrentAmount={campaign.totalAmount}
          goalProjectedRate={campaign.expectedProgress}
        />
        <CampaignStatistics
          avgDonation={campaign.avgDonation}
          totalAmount={campaign.totalAmount}
          numDonorConstituents={campaign.numDonorConstituents}
          totalPercentage={getPercentage(
            campaign.totalAmount,
            campaign.goalAmount
          )}
        />
      </CommonInfoSection>

      <PageTabs.Wrapper>
        <Box width="100%">
          <PageTabs
            defaultTabIdx={tabIdx}
            tabs={tabs}
            onChange={onTabClick}
            tabIdx={tabIdx}
          />
        </Box>
        <PageTabs.Content>
          <Visible when={Boolean(tabIdx === ZERO)}>
            <Feed
              isMobile={isMobile}
              layoutContainerId={topContainerId}
              layoutContainerRef={containerRef}
              feed={feed}
              getFeedPage={getFeedPage}
              activeFeedItemId={activeFeedItemId}
              onFeedItemClicked={(id) => setActiveFeedItem(campaignId, id)}
              closeSliderRedirectRoute={generatePath(AUTH_ROUTES.CAMPAIGN, {
                campaignId,
              })}
              topOffset={PAGE_TABS_HEIGHT}
            />
          </Visible>
          <React.Suspense fallback={<Loader isCentered py={2} />}>
            {tabIdx === 1 && <CampaignDonations />}
            {tabIdx === 2 && <CampaignReports />}
          </React.Suspense>
        </PageTabs.Content>
      </PageTabs.Wrapper>
      <CampaignModal
        data={modalData}
        teamMembers={teamMembers}
        errors={modalErrors}
        handleClose={closeCampaignModal}
        handleSave={saveCampaign}
        hasApiError={Boolean(compaignApiError)}
        isCreating={!modalData.campaignId}
        isLoading={isCampaignSaving}
        open={isCampaignModalOpen}
        updateData={setModalData}
        updateErrors={setModalErrors}
      />
      <MultiActionDialogManager />
      <PageLoader isVisible={showPageLoader} />
    </BasicPageLayout>
  );
};

export { CampaignPage };
