import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { isEmpty } from 'rambdax';
import { titleCase } from 'voca';
import { format } from 'date-fns';
import { reportsIcon } from 'assets/images';
import {
  BasicPageLayout,
  BasicPageTitle,
  CommonInfoSection,
  Loader,
  MainContent,
} from 'components/common';
import ReportsFilterDialog from 'components/reports/ReportsFilterDialog';
import { ReportsFilterForm } from 'components/reports';
import { translate } from 'lib/intl';
import { isMobileView } from 'lib/utils';
import useLoadD3 from 'lib/hooks/useLoadD3';
import FilterButton from 'components/common/FilterButton';
import { withNewThemeProvider } from 'components/common/NewThemeProvider';
import { DateType } from 'lib/types/ReportFilterPropType';
import { CUSTOM_DATE_FORMAT } from 'lib/constants/common';
import useReportFilters from './useReportFilters';
import { useFetchReportPageData } from './useFetchReportPageData';
import ReportModules from './ReportModules';

const useStyles = makeStyles((theme) => ({
  filterButton: {
    [theme.breakpoints.down('sm')]: {
      paddingRight: 0,
    },
  },
}));

function ReportsPage() {
  const classes = useStyles();
  const isMobile = isMobileView();
  const { hasD3, hasScriptError: hasD3ScriptError } = useLoadD3();
  const {
    areReportsLoaded,
    areTeamMembersLoaded,
    fetchReportData,
    reportModules,
  } = useFetchReportPageData();
  const { filters, resetFilters, updateFilters } = useReportFilters();
  const [isModalOpen, setIsModalOpen] = React.useState(false);

  React.useEffect(() => {
    fetchReportData();
  }, []);

  const handleModalClose = () => {
    setIsModalOpen(false);
  };
  const handleFilterClick = () => {
    setIsModalOpen(true);
  };

  const hasDependencies = hasD3 && areReportsLoaded;
  const isEmptyReport = isEmpty(reportModules?.donationSummary?.report);
  const reportTitle = titleCase(filters?.reportType.replaceAll('-', ' '));
  const dateStartDate = new Date(filters.startDate);
  const dateEndDate = new Date(filters.endDate);
  const reportTimePeriod =
    filters.dateType === DateType.CALENDAR_YTD
      ? translate('REPORTS_FILTER_DATE_TYPE_CALENDAR_YTD')
      : `${format(dateStartDate, CUSTOM_DATE_FORMAT)} - ${format(
          dateEndDate,
          CUSTOM_DATE_FORMAT
        )}`;

  return (
    <BasicPageLayout>
      <CommonInfoSection>
        {!areTeamMembersLoaded ? (
          <Loader className="w-full" isCentered />
        ) : (
          <>
            <div className="flex justify-between">
              <BasicPageTitle
                iconSrc={reportsIcon}
                iconAlt={translate('REPORTS_PAGE_TITLE_ICON_ALT')}
                title={translate('REPORTS_PAGE_TITLE')}
              >
                {isMobile && (
                  <FilterButton
                    className={classes.filterButton}
                    color="primary"
                    onClick={handleFilterClick}
                  />
                )}
              </BasicPageTitle>
            </div>

            {isMobile ? (
              <ReportsFilterDialog
                isOpen={isModalOpen}
                onClose={handleModalClose}
              />
            ) : (
              <ReportsFilterForm
                filters={filters}
                onResetFilter={resetFilters}
                onFilterUpdate={updateFilters}
              />
            )}
          </>
        )}
      </CommonInfoSection>

      {/* TODO: show proper message when script error */}
      {!hasD3ScriptError && !hasDependencies ? (
        <Loader className="w-full" isCentered />
      ) : (
        <MainContent>
          {isEmptyReport ? (
            <div className="flex flex-col items-center pt-12">
              <div className="text-xl font-semibold">
                {translate('REPORTS_NO_RESULTS_FOUND')}
              </div>
              <div className="text-base font-normal text-grey-900">
                {translate('REPORTS_PLEASE_ADJUST')}
              </div>
            </div>
          ) : (
            <div>
              <div className="flex flex-col sm:flex-row items-center sm:items-start justify-between">
                <h2 className="text-xl sm:pb-4">{reportTitle}</h2>
                <span className="text-base text-grey-900 pb-6 sm:pb-0">
                  Time Period: {reportTimePeriod}
                </span>
              </div>
              <ReportModules
                dateType={filters.dateType}
                filters={filters}
                modules={reportModules}
                onSort={updateFilters}
              />
            </div>
          )}
        </MainContent>
      )}
    </BasicPageLayout>
  );
}

export default withNewThemeProvider(ReportsPage);
