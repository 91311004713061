import React from 'react';
import Box from '@material-ui/core/Box';
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import { Avatar, Loader, ViewFieldsColumn } from 'components';
import { getInitials } from 'lib/utils';
import { usePublicUser } from 'lib/hooks/user-data';
import { ICON_SIZES } from 'lib/constants/common';
import { USER_PROFILE_DESCRIPTOR_CONFIG_1 } from 'lib/configs';

const POPOVER_MAX_WIDTH = '380px';

const ProfileDescriptor = ({
  anchor,
  isOpen,
  onClose,
  displayName,
  ownerOrgId,
  userId,
}) => {
  if (!isOpen && !userId) {
    return null;
  }

  const { publicUser, isFetching } = usePublicUser(ownerOrgId, userId, isOpen);

  const data = {
    jobTitle: publicUser?.jobTitle,
    workEmails: publicUser?.emails?.filter((email) => email.label === 'work'),
    mobilePhones: publicUser?.phones?.filter(
      (phone) => phone.label === 'mobile'
    ),
    workPhones: publicUser?.phones?.filter((phone) => phone.label === 'work'),
  };

  return (
    <Popover
      data-testid="profile-descriptor"
      open={isOpen}
      onClick={(event) => event.stopPropagation()}
      anchorEl={anchor}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'center',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'center',
        horizontal: 'left',
      }}
    >
      <Box maxWidth={POPOVER_MAX_WIDTH} p={3}>
        {isFetching ? (
          <Loader />
        ) : (
          <>
            <Box alignItems="center" display="flex" flexDirection="row" pb={2}>
              <Box pr={2}>
                <Avatar size={ICON_SIZES.XXL}>
                  {getInitials(displayName)}
                </Avatar>
              </Box>
              <Typography variant="h5">{displayName}</Typography>
            </Box>
            <ViewFieldsColumn
              config={USER_PROFILE_DESCRIPTOR_CONFIG_1}
              data={data}
            />
            {/* Social accounts are disabled in the current design
            Keeping this around in case they are added again in the future */}
            {/* <Box pt={2}>
              <SocialAccounts
                boxProps={SocialAccountsBoxProps}
                facebook={facebook}
                instagram={instagram}
                linkedin={linkedin}
                twitter={twitter}
              />
            </Box> */}
          </>
        )}
      </Box>
    </Popover>
  );
};

ProfileDescriptor.propTypes = {
  anchor: PropTypes.shape({}),
  isOpen: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  displayName: PropTypes.string.isRequired,
  ownerOrgId: PropTypes.string.isRequired,
  userId: PropTypes.string.isRequired,
};

ProfileDescriptor.defaultProps = {
  anchor: null,
  isOpen: false,
};

export { ProfileDescriptor };
