import React from 'react';
import { AuthContainer, ResendVerificationLink } from 'components';
import { Grid, Typography } from '@material-ui/core';
import { translate } from 'lib/intl';
import { PUBLIC_ROUTES } from 'lib/constants';
import { useLogin } from './loginPagesHooks';

const LoginNeedsVerificationPage = () => {
  const { isFetching, error, usernameToReset, resendVerificationEmail } =
    useLogin();

  return (
    <AuthContainer
      size="sm"
      navLinkText={translate('SIGN_IN')}
      navLinkRoute={PUBLIC_ROUTES.LOGIN}
    >
      <Grid container>
        <Typography variant="h5">
          {translate('LOGIN_VERIFY_ACCOUNT')}
        </Typography>
        <Typography variant="h6" style={{ marginTop: '30px' }}>
          {translate('LOGIN_CHECK_VERIFY_EMAIL')}
        </Typography>
        <div style={{ marginTop: '30px' }}>
          <ResendVerificationLink
            isFetching={isFetching}
            error={error}
            usernameToReset={usernameToReset}
            resendVerificationEmail={resendVerificationEmail}
          />
        </div>
      </Grid>
    </AuthContainer>
  );
};

export { LoginNeedsVerificationPage };
