import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { prop } from 'rambdax';
import classNames from 'classnames';
import styled from 'styled-components';
import makeStyles from '@material-ui/styles/makeStyles';
import { isMobileView } from 'lib/utils';
import { colors } from 'styles/theme';
import {
  affiliationsIcon,
  BlackCircleIcon,
  birthdayIcon,
  buildingIcon,
  cabinetIcon,
  connectionsIcon,
  employeeIdIcon,
  emailIcon,
  employeesIcon,
  facebookIcon,
  familyIcon,
  giftIcon,
  homeIcon,
  hourGlassIcon,
  jobIcon,
  jobPositionIcon,
  inIcon,
  instagramIcon,
  moneyBagIcon,
  notesIcon,
  phoneIcon,
  phoneNumberIcon,
  spouseIcon,
  personIcon,
  primaryPhoneIcon,
  redPhoneNumber,
  tagIcon,
  twitterIcon,
  webIcon,
  winningIcon,
  concernsIcon,
  prohibitionIcon,
  industryIcon,
  mailingBoxIcon,
  clocksIcon,
  BoardMemberImage,
  MajorDonorImage,
  RecurringDonorImage,
  VolunteerImage,
  MemberImage,
  AttributeAdvocateIcon as AttributeAdvocate,
  AttributeClientIcon as AttributeClient,
  AttributeCorporationIcon as AttributeCorporation,
  AttributeDonorIcon as AttributeDonor,
  AttributeFoundationIcon as AttributeFoundation,
  AttributeMajorDonorIcon as AttributeMajorDonor,
  AttributePartnerIcon as AttributePartner,
  AttributeRecurringIcon as AttributeRecurring,
  AttributeVipIcon as AttributeVip,
} from 'assets/images';

const StyledEditIcon = styled.img`
  height: ${prop('size')};
  width: ${prop('size')};
`;

const EditDonorIcon = ({ src, ...rest }) => {
  const isMobile = isMobileView();

  const size = useMemo(() => (isMobile ? '16px' : '24px'), [isMobile]);

  return <StyledEditIcon size={size} src={src} {...rest} />;
};

EditDonorIcon.propTypes = {
  src: PropTypes.string.isRequired,
};

const StyledIcon = styled.img`
  height: ${({ social }) => (social ? 24 : 16)}px;
  margin-top: ${({ summary }) => (summary ? 0 : 2)}px;
  width: ${({ social }) => (social ? 24 : 16)}px;
`;

const StyledDonorAttributeIcon = styled.div`
  align-items: center;
  background-color: ${({ color }) => color || colors.recurring};
  border-radius: 4px;
  display: flex;
  height: 20px;
  justify-content: center;
  width: 20px;
  img {
    max-width: 14px;
    max-height: 14px;
  }
`;

const useAttributeStyles = makeStyles((theme) => {
  const dimension = ({ size }) => theme.spacing(size === 'sm' ? 1.75 : 2.25);

  return {
    svg: {
      height: dimension,
      maxHeight: dimension,
      maxWidth: dimension,
      width: dimension,
    },
  };
});

const ConstituentAttributeIcon = ({ className, size, type }) => {
  const classes = useAttributeStyles({ size });

  return (
    <div
      className={classNames(
        'inline-flex justify-center items-center h-5 w-5 rounded text-primary-contrastText',
        {
          [className]: className,
          'bg-attribute-automation': [
            'donor',
            'majorDonor',
            'recurring',
          ].includes(type),
          'bg-attribute-advocate': type === 'advocate',
          'bg-attribute-corporation': type === 'corporation',
          'bg-attribute-foundation': type === 'foundation',
          'bg-attribute-recipient': type === 'recipient',
          'bg-attribute-partner': type === 'partner',
          'bg-attribute-vip': type === 'vip',
        }
      )}
    >
      {type === 'advocate' && <AttributeAdvocate className={classes.svg} />}
      {type === 'corporation' && (
        <AttributeCorporation className={classes.svg} />
      )}
      {type === 'donor' && <AttributeDonor className={classes.svg} />}
      {type === 'foundation' && <AttributeFoundation className={classes.svg} />}
      {type === 'majorDonor' && <AttributeMajorDonor className={classes.svg} />}
      {type === 'recipient' && <AttributeClient className={classes.svg} />}
      {type === 'recurring' && <AttributeRecurring className={classes.svg} />}
      {type === 'partner' && <AttributePartner className={classes.svg} />}
      {type === 'vip' && <AttributeVip className={classes.svg} />}
    </div>
  );
};

ConstituentAttributeIcon.propTypes = {
  className: PropTypes.string,
  size: PropTypes.oneOf(['sm', 'md']),
  type: PropTypes.string.isRequired,
};

ConstituentAttributeIcon.defaultProps = {
  className: '',
  size: 'sm',
};

const AffiliationsIcon = (
  <StyledIcon
    alt="affiliations-icon"
    data-testid="affiliations-icon"
    src={affiliationsIcon}
  />
);
const EditAffiliationsIcon = (
  <EditDonorIcon
    alt="affiliations-icon"
    data-testid="affiliations-icon"
    src={affiliationsIcon}
  />
);
const BirthdayIcon = (
  <StyledIcon
    alt="birthday-icon"
    data-testid="birthday-icon"
    src={birthdayIcon}
  />
);
const EditBirthdayIcon = (
  <EditDonorIcon
    alt="birthday-icon"
    data-testid="birthday-icon"
    src={birthdayIcon}
  />
);
const BuildingIcon = (
  <StyledIcon
    alt="building-icon"
    data-testid="building-icon"
    src={buildingIcon}
  />
);
const EditBuildingIcon = (
  <EditDonorIcon
    alt="building-icon"
    data-testid="building-icon"
    src={buildingIcon}
  />
);
const ConnectionsIcon = (
  <StyledIcon
    alt="connections-icon"
    data-testid="connections-icon"
    src={connectionsIcon}
  />
);
const EditConnectionsIcon = (
  <EditDonorIcon
    alt="connections-icon"
    data-testid="connections-icon"
    src={connectionsIcon}
  />
);
const FacebookIcon = (
  <StyledIcon
    alt="facebook-icon"
    data-testid="facebook-icon"
    src={facebookIcon}
    social="true"
  />
);
const EditFacebookIcon = (
  <EditDonorIcon
    alt="facebook-icon"
    data-testid="facebook-icon"
    src={facebookIcon}
    social="true"
  />
);
const FamilyIcon = (
  <StyledIcon alt="family-icon" data-testid="family-icon" src={familyIcon} />
);
const EditFamilyIcon = (
  <EditDonorIcon alt="family-icon" data-testid="family-icon" src={familyIcon} />
);
const GiftIcon = (
  <StyledIcon
    alt="gift-icon"
    data-testid="gift-icon"
    src={giftIcon}
    summary="true"
  />
);
const HomeIcon = (
  <StyledIcon alt="home-icon" data-testid="home-icon" src={homeIcon} />
);
const EditHomeIcon = (
  <EditDonorIcon alt="home-icon" data-testid="home-icon" src={homeIcon} />
);
const HourGlassIcon = (
  <StyledIcon
    alt="hour-glass-icon"
    data-testid="hour-glass-icon"
    src={hourGlassIcon}
  />
);
const LinkedInIcon = (
  <StyledIcon
    alt="linked-in-icon"
    data-testid="linked-in-icon"
    src={inIcon}
    social="true"
  />
);
const EditLinkedInIcon = (
  <EditDonorIcon
    alt="linked-in-icon"
    data-testid="linked-in-icon"
    src={inIcon}
    social="true"
  />
);
const MoneyBagIcon = (
  <StyledIcon
    alt="money-bag-icon"
    data-testid="money-bag-icon"
    src={moneyBagIcon}
    summary="true"
  />
);
const ClocksIcon = (
  <StyledIcon
    alt="clocks-icon"
    data-testid="locks-icon"
    src={clocksIcon}
    summary="true"
  />
);
const InstagramIcon = (
  <StyledIcon
    alt="instagram-icon"
    data-testid="instagram-icon"
    src={instagramIcon}
    social="true"
  />
);
const EditInstagramIcon = (
  <EditDonorIcon
    alt="instagram-icon"
    data-testid="instagram-icon"
    src={instagramIcon}
    social="true"
  />
);
const PersonIcon = (
  <StyledIcon alt="person-icon" data-testid="person-icon" src={personIcon} />
);
const TwitterIcon = (
  <StyledIcon
    alt="twitter-icon"
    data-testid="twitter-icon"
    src={twitterIcon}
    social="true"
  />
);
const EditTwitterIcon = (
  <EditDonorIcon
    alt="twitter-icon"
    data-testid="twitter-icon"
    src={twitterIcon}
    social="true"
  />
);
const WebIcon = (
  <StyledIcon alt="web-icon" data-testid="web-icon" src={webIcon} />
);
const EditWebIcon = (
  <EditDonorIcon alt="web-icon" data-testid="web-icon" src={webIcon} />
);
const NotesIcon = (
  <StyledIcon alt="notes-icon" data-testid="notes-icon" src={notesIcon} />
);
const EmailIcon = (
  <StyledIcon alt="email-icon" data-testid="email-icon" src={emailIcon} />
);
const EditEmailIcon = (
  <EditDonorIcon alt="email-icon" data-testid="email-icon" src={emailIcon} />
);
const JobIcon = (
  <StyledIcon alt="job-icon" data-testid="job-icon" src={jobIcon} />
);
const EditJobIcon = (
  <EditDonorIcon alt="job-icon" data-testid="job-icon" src={jobIcon} />
);
const JobPositionIcon = (
  <StyledIcon
    alt="job-position-icon"
    data-testid="job-position-icon"
    src={jobPositionIcon}
  />
);
const EditJobPositionIcon = (
  <EditDonorIcon
    alt="job-position-icon"
    data-testid="job-position-icon"
    src={jobPositionIcon}
  />
);
const SpouseIcon = (
  <StyledIcon alt="spouse-icon" data-testid="spouse-icon" src={spouseIcon} />
);
const EditSpouseIcon = (
  <EditDonorIcon alt="spouse-icon" data-testid="spouse-icon" src={spouseIcon} />
);
const PhoneIcon = (
  <StyledIcon alt="phone-icon" data-testid="phone-icon" src={phoneNumberIcon} />
);
const PrimaryPhoneIcon = (
  <StyledIcon
    alt="primary-phone-icon"
    data-testid="primary-phone-icon"
    src={primaryPhoneIcon}
  />
);
const RedPhoneIcon = (
  <StyledIcon
    alt="red-phone-icon"
    data-testid="red-phone-icon"
    src={redPhoneNumber}
  />
);
const BlackCircle = <BlackCircleIcon height="4px" width="4px" />;
const WorkPhoneIcon = (
  <StyledIcon
    alt="work-phone-icon"
    data-testid="work-phone-icon"
    src={phoneIcon}
  />
);
const EditWorkPhoneIcon = (
  <EditDonorIcon
    alt="work-phone-icon"
    data-testid="work-phone-icon"
    src={phoneIcon}
  />
);
const WinningIcon = (
  <StyledIcon alt="winning-icon" data-testid="winning-icon" src={winningIcon} />
);
const EditWinningIcon = (
  <EditDonorIcon
    alt="winning-icon"
    data-testid="winning-icon"
    src={winningIcon}
  />
);
const ConcernsIcon = (
  <StyledIcon
    alt="concerns-icon"
    data-testid="concerns-icon"
    src={concernsIcon}
  />
);
const EditConcernsIcon = (
  <EditDonorIcon
    alt="concerns-icon"
    data-testid="concerns-icon"
    src={concernsIcon}
  />
);
const ProhibitionsIcon = (
  <StyledIcon
    alt="prohibitions-icon"
    data-testid="prohibitions-icon"
    src={prohibitionIcon}
  />
);
const EditProhibitionsIcon = (
  <EditDonorIcon
    alt="prohibitions-icon"
    data-testid="prohibitions-icon"
    src={prohibitionIcon}
  />
);
const IndustryIcon = (
  <StyledIcon
    alt="industry-icon"
    data-testid="industry-icon"
    src={industryIcon}
  />
);
const EditIndustryIcon = (
  <EditDonorIcon
    alt="industry-icon"
    data-testid="industry-icon"
    src={industryIcon}
  />
);
const EmployeesIcon = (
  <StyledIcon
    alt="employees-icon"
    data-testid="employees-icon"
    src={employeesIcon}
  />
);
const EditEmployeesIcon = (
  <StyledIcon
    alt="employees-icon"
    data-testid="employees-icon"
    src={employeesIcon}
  />
);
const EmployeeIdIcon = (
  <StyledIcon
    alt="employee-id-icon"
    data-testid="employee-id-icon"
    src={employeeIdIcon}
  />
);
const EditEmployeeIdIcon = (
  <EditDonorIcon
    alt="employee-id-icon"
    data-testid="employee-id-icon"
    src={employeeIdIcon}
  />
);
const MailingBoxIcon = (
  <EditDonorIcon
    alt="mailing-box-icon"
    data-testid="mailing-box-icon"
    src={mailingBoxIcon}
  />
);
const EditMailingBoxIcon = (
  <EditDonorIcon
    alt="mailing-box-icon"
    data-testid="mailing-box-icon"
    src={mailingBoxIcon}
  />
);
const LabelIcon = (
  <EditDonorIcon alt="label-icon" data-testid="label-icon" src={cabinetIcon} />
);
const TagIcon = (
  <EditDonorIcon alt="tag-icon" data-testid="tag-icon" src={tagIcon} />
);
const BoardMemberIcon = (
  <StyledDonorAttributeIcon
    data-testid="board-member-icon"
    id="board-member-icon"
    color={colors.boardMember}
  >
    <img src={BoardMemberImage} alt="board-member-icon" />
  </StyledDonorAttributeIcon>
);
const MajorDonorIcon = (
  <StyledDonorAttributeIcon
    data-testid="major-donor-icon"
    id="major-donor-icon"
    color={colors.majorDonor}
  >
    <img src={MajorDonorImage} alt="major-donor-icon" />
  </StyledDonorAttributeIcon>
);
const RecurringDonorIcon = (
  <StyledDonorAttributeIcon
    data-testid="recurring-donor-icon"
    id="recurring-donor-icon"
    color={colors.recurring}
  >
    <img src={RecurringDonorImage} alt="recurring-donor-icon" />
  </StyledDonorAttributeIcon>
);
const VolunteerIcon = (
  <StyledDonorAttributeIcon
    data-testid="volunteer-icon"
    id="volunteer-icon"
    color={colors.volunteer}
  >
    <img src={VolunteerImage} alt="volunteer-icon" />
  </StyledDonorAttributeIcon>
);
const MemberIcon = (
  <StyledDonorAttributeIcon
    data-testid="member-icon"
    id="member-icon"
    color={colors.member}
  >
    <img src={MemberImage} alt="member-icon" />
  </StyledDonorAttributeIcon>
);

const AttributeAdvocateIcon = <ConstituentAttributeIcon type="advocate" />;
const AttributeCorporationIcon = (
  <ConstituentAttributeIcon type="corporation" />
);
const AttributeDonorIcon = <ConstituentAttributeIcon type="donor" />;
const AttributeFoundationIcon = <ConstituentAttributeIcon type="foundation" />;
const AttributeMajorDonorIcon = <ConstituentAttributeIcon type="majorDonor" />;
const AttributePartnerIcon = <ConstituentAttributeIcon type="partner" />;
const AttributeRecipientIcon = <ConstituentAttributeIcon type="recipient" />;
const AttributeRecurringIcon = <ConstituentAttributeIcon type="recurring" />;
const AttributeVipIcon = <ConstituentAttributeIcon type="vip" />;

export {
  AffiliationsIcon,
  AttributeAdvocateIcon,
  AttributeCorporationIcon,
  AttributeDonorIcon,
  AttributeFoundationIcon,
  AttributeMajorDonorIcon,
  AttributePartnerIcon,
  AttributeRecipientIcon,
  AttributeRecurringIcon,
  AttributeVipIcon,
  ConstituentAttributeIcon,
  EditAffiliationsIcon,
  BirthdayIcon,
  EditBirthdayIcon,
  BlackCircle,
  BuildingIcon,
  EditBuildingIcon,
  ConnectionsIcon,
  EditConnectionsIcon,
  EmailIcon,
  EditEmailIcon,
  EmployeesIcon,
  EditEmployeesIcon,
  EmployeeIdIcon,
  EditEmployeeIdIcon,
  FacebookIcon,
  EditFacebookIcon,
  FamilyIcon,
  EditFamilyIcon,
  GiftIcon,
  JobIcon,
  EditJobIcon,
  JobPositionIcon,
  EditJobPositionIcon,
  HomeIcon,
  EditHomeIcon,
  HourGlassIcon,
  RedPhoneIcon,
  InstagramIcon,
  EditInstagramIcon,
  LabelIcon,
  LinkedInIcon,
  EditLinkedInIcon,
  MoneyBagIcon,
  ClocksIcon,
  NotesIcon,
  SpouseIcon,
  EditSpouseIcon,
  PersonIcon,
  PhoneIcon,
  PrimaryPhoneIcon,
  TagIcon,
  TwitterIcon,
  EditTwitterIcon,
  WebIcon,
  EditWebIcon,
  WorkPhoneIcon,
  EditWorkPhoneIcon,
  WinningIcon,
  EditWinningIcon,
  ConcernsIcon,
  EditConcernsIcon,
  ProhibitionsIcon,
  EditProhibitionsIcon,
  IndustryIcon,
  EditIndustryIcon,
  EditDonorIcon,
  MailingBoxIcon,
  EditMailingBoxIcon,
  BoardMemberIcon,
  MajorDonorIcon,
  RecurringDonorIcon,
  VolunteerIcon,
  MemberIcon,
};
