import React from 'react';
import Box from '@material-ui/core/Box';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import env from '@beam-australia/react-env';
import { translate } from 'lib/intl';
import { isMobileView } from 'lib/utils';
import { arrayOf, func, shape, string } from 'prop-types';
import { useStyles } from './integrationPageStyles';

const IntegrationHelperSteps = ({ helperSteps, createMarkup, name }) => {
  const isMobile = isMobileView();
  const styles = useStyles();

  const helpLinkAddress = env('HELP_LINK_ADDRESS');

  return (
    <>
      {helperSteps.map((step) => (
        <Box key={step.id}>
          <Box className={styles.helperContainer}>
            <Box className={styles.helperContainerInner} p={isMobile ? 1.5 : 3}>
              <Typography className={styles.helperSubtitle}>
                {step.title}
              </Typography>
              <Typography
                variant={isMobile ? 'body2' : 'body1'}
                dangerouslySetInnerHTML={createMarkup(step.description)}
              />
            </Box>
          </Box>
          <Box className={styles.line} />
        </Box>
      ))}
      <Box className={styles.helperContainer}>
        <Typography
          className={styles.helperEndText}
          variant={isMobile ? 'body2' : 'body1'}
        >
          {translate('INTEGRATIONS_TROUBLE', { name })}
        </Typography>
        <Typography
          className={styles.helperEndText}
          variant={isMobile ? 'body2' : 'body1'}
        >
          {translate('INTEGRATIONS_CONTACT')}{' '}
          <Link href={`mailto:${helpLinkAddress}`}>{helpLinkAddress}</Link>{' '}
          {translate('INTEGRATIONS_FOR_HELP')}
        </Typography>
      </Box>
    </>
  );
};

IntegrationHelperSteps.propTypes = {
  helperSteps: arrayOf(
    shape({
      title: string,
      description: string,
      id: string,
    })
  ).isRequired,
  createMarkup: func.isRequired,
  name: string,
};
IntegrationHelperSteps.defaultProps = {
  name: '',
};

export default IntegrationHelperSteps;
