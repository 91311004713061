import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { integrationActions } from 'rdx/actions/integrations';

const useIntegrationPage = () => {
  const dispatch = useDispatch();
  const { integrationId } = useParams();

  const { isLoading, keySaved, error, ownerOrgPubId, classyIntegration } =
    useSelector((state) => ({
      isLoading: state.orgInfo.isLoading,
      keySaved: state.orgInfo.keySaved,
      error: state.orgInfo.error,
      ownerOrgPubId: state.orgInfo.data.orgId,
      classyIntegration: state.orgInfo.data.classy,
    }));

  const hasClassyCredentials =
    classyIntegration && !!classyIntegration.clientId;

  const handleSave = (data) => {
    const { organizationId } = data;
    if (classyIntegration.clientId) {
      dispatch(
        integrationActions.updateIntegrationKey(
          ownerOrgPubId,
          integrationId,
          organizationId,
          data
        )
      );
    } else {
      dispatch(
        integrationActions.createIntegrationKey(
          ownerOrgPubId,
          integrationId,
          organizationId,
          data
        )
      );
    }
  };

  const handleRemove = () => {
    const { organizationId } = classyIntegration;
    dispatch(
      integrationActions.removeIntegrationKey(
        ownerOrgPubId,
        integrationId,
        organizationId
      )
    );
  };

  let showHelperSteps = true;
  switch (integrationId) {
    case 'classy':
    default:
      showHelperSteps = !hasClassyCredentials;
  }

  return {
    integrationId,
    isLoading,
    error,
    classyIntegration,
    hasClassyCredentials,
    handleSave,
    handleRemove,
    showHelperSteps,
    keySaved,
  };
};

export { useIntegrationPage };
