import React from 'react';
import PropTypes from 'prop-types';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import { translate } from 'lib/intl';
import { DateType } from 'lib/types/ReportFilterPropType';
import { DonationSummary } from 'lib/types/ReportModulesPropType';
import { formatNumber } from 'lib/utils/formatNumber';
import { convertCentsToDollars } from 'lib/utils/currency';

const DonationSummaryReport = ({ module, dateType }) => {
  const data = [
    {
      heading: translate('REPORTS_TOTAL_VALUE'),
      number: `$${formatNumber(convertCentsToDollars(module.totalValue))}`,
      change: module.totalValueYoy,
    },
    {
      heading: translate('REPORTS_TOTAL_DONATIONS'),
      number: formatNumber(module.totalDonations),
      change: module.totalDonationsYoy,
    },
    {
      heading: translate('REPORTS_TOTAL_DONORS'),
      number: formatNumber(module.totalDonors),
      change: module.totalDonorsYoy,
    },
    {
      heading: translate('REPORTS_AVERAGE_DONATION'),
      number: `$${formatNumber(
        convertCentsToDollars(module.averageDonationAmount)
      )}`,
      change: module.averageDonationAmountYoy,
    },
    {
      heading: translate('REPORTS_TOTAL_HOURS'),
      number: formatNumber(module.totalHours),
      change: module.totalHoursYoy,
    },
    {
      heading: translate('REPORTS_INKIND_VALUE'),
      number: `$${formatNumber(convertCentsToDollars(module.totalInKind))}`,
      change: module.totalInKindYoy,
    },
  ];
  const changeLabel = dateType === DateType.CALENDAR_YTD ? 'YTD' : 'YOY';
  const renderChangeWithIcon = (number) => {
    switch (Math.sign(number)) {
      case 1:
        return (
          <>
            <ArrowDropUpIcon className="text-success-main" />
            {number}% {changeLabel}
          </>
        );
      case -1:
        return (
          <>
            <ArrowDropDownIcon className="text-error-main" />
            {number}% {changeLabel}
          </>
        );
      case 0:
      default:
        return null;
    }
  };
  return (
    <div className="flex flex-wrap">
      {data.map((item) => (
        <div className="h-28 w-1/2 sm:w-1/3 mb-4" key={item.heading}>
          <div className="border border-grey-300 rounded-lg p-4 flex flex-col h-full mr-4">
            <h3 className="text-base pb-1">{item.heading}</h3>
            <div className="text-2xl font-semibold">{item.number}</div>
            <div className="text-sm font-normal">
              {item.change ? renderChangeWithIcon(item.change) : null}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

DonationSummaryReport.propTypes = {
  dateType: PropTypes.oneOf(Object.values(DateType)),
  module: DonationSummary,
};
DonationSummaryReport.defaultProps = {
  dateType: DateType.CALENDAR_YTD,
  module: {},
};

export default DonationSummaryReport;
