// Shallow object clean
const cleanObjectValues = (obj) => {
  const newObj = {};

  Object.keys(obj).forEach((key) => {
    if (obj[key] != null) {
      newObj[key] = obj[key];
    }
  });

  return newObj;
};

export { cleanObjectValues };
