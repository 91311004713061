export const FEED = {
  EMPTY_FEED: 'No current activity',
  FEED_SLIDER_GENERIC_ASSIGNED_TITLE: 'Assigned',
  FEED_SLIDER_GENERIC_NOTES_TITLE: 'Notes',
  FEED_SLIDER_DETAILS_TITLE: 'Details',
  FEED_INTERACTION_LABEL: 'Interaction',
  FEED_ANNOUNCEMENT_LABEL: 'Announcement',
  FEED_DONATION_LABEL: 'Donation',
  FEED_TREND_LABEL: 'Trend',
  FEED_CAMPAIGN_LABEL: 'Campaign',
  FEED_CONTRIBUTION_LABEL: 'Donation',
  DELETE_FEED_ENTITY_SUCCESS_MESSAGE: 'The {entity} was succesfully removed',
  DELETE_FEED_ENTITY_ERROR_MESSAGE: "Couldn't remove the {entity}",
  FEED_ENTITY_DELETED: 'This {entity} was deleted.',
};
