import { useEffect, useState, useCallback } from 'react';
import { useFeature } from 'flagged';
import {
  generatePath,
  useHistory,
  useParams,
  useLocation,
} from 'react-router-dom';
import { campaignsActions } from 'rdx/actions';
import { useDispatch, useSelector } from 'react-redux';
import { FEATURE_FLAG_REPORTS } from 'lib/constants';
import { DEFAULT_CAMPAIGN_PROPS } from 'lib/configs/campaigns';
import {
  CAMPAIGN_PAGE_TABS,
  CAMPAIGN_PAGE_TABS_WITH_REPORTS,
  getCampaignPageTabId,
} from './campaignPageUtils';

const useCampaignPage = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const hasReports = useFeature(FEATURE_FLAG_REPORTS);

  const { campaignId = '' } = useParams();

  const [tabIdx, setTabIdx] = useState(
    getCampaignPageTabId(CAMPAIGN_PAGE_TABS_WITH_REPORTS, location)
  );

  const { campaignState } = useSelector((state) => ({
    campaignState: state.campaign[campaignId] || {},
  }));

  const {
    data: campaign = DEFAULT_CAMPAIGN_PROPS,
    dataLoaded: isCampaignLoaded,
    isFetching: isCampaignLoading,
  } = campaignState;

  const onTabClick = useCallback(
    (idx) => {
      const { path } = CAMPAIGN_PAGE_TABS_WITH_REPORTS[idx];

      if (path) {
        setTabIdx(idx);
        history.push(generatePath(path, { campaignId }));
      }
    },
    [campaignId]
  );

  useEffect(() => {
    if (campaignId) {
      dispatch(campaignsActions.fetchCampaign(campaignId));
    }
  }, [campaignId]);

  const showPageLoader = Boolean(!isCampaignLoaded && isCampaignLoading);

  return {
    campaignId,
    campaign,
    tabIdx,
    tabs: hasReports ? CAMPAIGN_PAGE_TABS_WITH_REPORTS : CAMPAIGN_PAGE_TABS,
    onTabClick,
    showPageLoader,
  };
};

export { useCampaignPage };
