import { Dialog } from '@material-ui/core';
import styled from 'styled-components';

const StyledDialog = styled(Dialog)`
  ${({ mobile }) =>
    mobile
      ? `
  .MuiDialog-paperFullWidth {
    border-radius: 0px;
    height: 100%;
    margin: 0px;
    max-height: 100vh;
    width: 530px;
  }
  `
      : `
  .MuiDialog-paperFullWidth {
    max-width: 530px;
  }
  `}
`;

export { StyledDialog };
