import React from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import { translate } from 'lib/intl';
import { Avatar, Loader, ViewFieldsColumn } from 'components';
import { DonorType, getInitials, isMobileView } from 'lib/utils';
import { VIEW_COMPANY_INFO_CONFIG } from 'lib/configs';

const AVATAR_MOB_SIZE = '64px';
const AVATAR_DESKTOP_SIZE = '100px';

// Info Section for companies
const CompanyInfo = ({ data, dataLoaded, openEditModal }) => {
  const { avatar, orgName } = data;

  const isMobile = isMobileView();

  const initials = React.useMemo(
    () => getInitials(orgName || '', false),
    [orgName]
  );

  const avatarSize = React.useMemo(
    () => (isMobile ? AVATAR_MOB_SIZE : AVATAR_DESKTOP_SIZE),
    [isMobile]
  );

  return (
    <Box>
      <Box>
        <Box display="flex" justifyContent="space-between" pt={2}>
          <Box
            alignItems="flex-start"
            display="flex"
            justifyContent="space-between"
            pb={1}
          >
            {dataLoaded ? (
              <Avatar size={avatarSize} src={avatar}>
                {initials}
              </Avatar>
            ) : (
              <Loader size={avatarSize} />
            )}
          </Box>
          {dataLoaded && (
            <Link data-testid="edit-org-button" onClick={openEditModal}>
              {translate('COMPANY_EDIT_LABEL')}
            </Link>
          )}
        </Box>
      </Box>
      <Box pb={2} pt={1}>
        <Typography variant="h5">{orgName}</Typography>
      </Box>
      <ViewFieldsColumn
        config={VIEW_COMPANY_INFO_CONFIG}
        data={data}
        onNoDataLabelClick={openEditModal}
      />
    </Box>
  );
};

CompanyInfo.propTypes = {
  data: DonorType,
  dataLoaded: PropTypes.bool.isRequired,
  openEditModal: PropTypes.func.isRequired,
};

CompanyInfo.defaultProps = {
  data: {},
};

export { CompanyInfo };
