import React from 'react';
import classNames from 'classnames';
import { number } from 'prop-types';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/styles/makeStyles';
import { translate } from 'lib/intl';
import { CampaignStatisticsBlock } from './CampaignStatisticsBlock';

const useStyles = makeStyles(() => ({
  container: {
    msOverflowStyle: 'none',
    scrollbarWidth: 'none',
    width: 'calc(100% + 1.5rem)',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
}));

const CampaignStatistics = ({
  avgDonation,
  totalAmount,
  numDonorConstituents,
  totalPercentage,
}) => {
  const styles = useStyles();

  return (
    <div
      className={classNames(
        '-mx-3 md:mx-0 overflow-x-auto px-4 md:px-0 md:w-auto',
        styles.container
      )}
    >
      <div className="flex w-max md:block md:w-full">
        <CampaignStatisticsBlock
          isCurrencyBased
          className="mr-4 mt-4 md:mr-0"
          label={translate('CAMPAIGNS_TOTAL_REVENUE_LABEL')}
          value={totalAmount}
        >
          {totalPercentage !== null && (
            <Box display="flex" justifyContent="flex-end">
              <Typography className="font-semibold mr-1">
                {`%${totalPercentage}`}
              </Typography>
              <Typography>
                {translate('CAMPAIGNS_TOTAL_GOAL_PERCENTAGE_LABEL')}
              </Typography>
            </Box>
          )}
        </CampaignStatisticsBlock>
        <CampaignStatisticsBlock
          label={translate('CAMPAIGNS_TOTAL_DONORS_LABEL')}
          className="mr-4 mt-4 md:mr-0"
          value={numDonorConstituents}
        />
        <CampaignStatisticsBlock
          isCurrencyBased
          label={translate('CAMPAIGNS_AVG_DONATION_LABEL')}
          className="mr-4 mt-4 md:mr-0"
          value={avgDonation}
        />
      </div>
    </div>
  );
};

CampaignStatistics.propTypes = {
  avgDonation: number,
  totalAmount: number,
  numDonorConstituents: number,
  totalPercentage: number,
};

CampaignStatistics.defaultProps = {
  avgDonation: 0,
  totalAmount: 0,
  numDonorConstituents: 0,
  totalPercentage: null,
};

export { CampaignStatistics };
