import React, { useMemo } from 'react';
import Box from '@material-ui/core/Box';
import {
  BasicPageLayout,
  CommonInfoSection,
  CompanyInfo,
  CompanyTeamMembers,
  CustomLink,
  EditCompanyModal,
  PageTabs,
  SendInvitesModal,
  SearchInput,
  SortPicker,
} from 'components';
import { SEARCH_INPUT_MAX_WIDTH } from 'lib/constants/common';
import { isMobileView } from 'lib/utils';
import { translate } from 'lib/intl';
import {
  CONPANY_PAGE_TABS,
  COMPANY_TEAM_MEMBER_CONFIG,
  COMPANY_INVITES_CONFIG,
  TEAM_MEMBERS_SORT_OPTIONS,
  INVITES_SORT_OPTIONS,
  SORT_PICKER_MOBILE_LENGTH,
} from './companyPageConfig';
import { useCompanyPage, useCompanyForm } from './companyPageHooks';

const CompanyPage = () => {
  const {
    // Common props
    companyInfo,
    groups,
    ownerOrgId,
    ownerOrgName,
    isTeamMembersTab,
    onTabClick,
    tabIdx,
    filter,
    setFilter,
    orgIsSuperAdmin,
    userOrgId,

    // Team members related props
    isTeamMembersLoaded,
    teamMembers,

    // Invites related props
    invites,
    isInviteFormLoading,
    isInvitesLoaded,
    sendInvites,
    showSendInvitesDialog,
    openSendInvitesModal,
    closeSendInvitesModal,
  } = useCompanyPage();

  const {
    errors,
    formData,
    isEditModalOpen,
    isFailed,
    isLoading,
    openEditModal,
    orgInfoLoaded,
    closeEditModal,
    updateForm,
    setErrors,
    saveCompanyInfo,
    showErrors,
  } = useCompanyForm({
    defaultData: companyInfo,
    ownerOrgId,
  });

  const isMobile = isMobileView();

  const InvitesTable = useMemo(
    () => (
      <CompanyTeamMembers
        config={COMPANY_INVITES_CONFIG}
        ownerOrgId={ownerOrgId}
        ownerOrgName={ownerOrgName}
        groups={groups}
        teamMembers={invites}
        isLoading={!isInvitesLoaded}
        filter={filter}
        setFilter={setFilter}
      />
    ),
    [ownerOrgId, ownerOrgName, groups, invites, isInvitesLoaded, filter]
  );

  const hideInviteButton = userOrgId === companyInfo.orgId && orgIsSuperAdmin;

  return (
    <>
      <BasicPageLayout>
        <CommonInfoSection>
          <CompanyInfo
            data={companyInfo}
            openEditModal={openEditModal}
            dataLoaded={orgInfoLoaded}
          />
        </CommonInfoSection>
        <PageTabs.Wrapper>
          <Box pt={1} width="100%">
            <PageTabs
              tabs={CONPANY_PAGE_TABS}
              onChange={onTabClick}
              tabIdx={tabIdx}
            />
          </Box>
          <Box
            alignItems="center"
            display="flex"
            justifyContent="space-between"
            mx={2}
            py={3}
            flexWrap="wrap"
          >
            <Box display="flex" flex="1 1 400px" mr={3}>
              <Box flex={`0 1 ${SEARCH_INPUT_MAX_WIDTH}`} pr={3}>
                <SearchInput
                  onChange={(searchValue) =>
                    setFilter({ ...filter, searchValue })
                  }
                  placeholder={translate('COMPANY_SEARCH_LABEL')}
                  value={filter?.searchValue}
                />
              </Box>
              {isMobile && (
                <SortPicker
                  filter={filter}
                  justifyContent="flex-start"
                  options={
                    isTeamMembersTab
                      ? TEAM_MEMBERS_SORT_OPTIONS
                      : INVITES_SORT_OPTIONS
                  }
                  setFilter={setFilter}
                  width={SORT_PICKER_MOBILE_LENGTH}
                />
              )}
            </Box>
            {Boolean(groups?.length) && !hideInviteButton && (
              <Box py={1}>
                <CustomLink onClick={openSendInvitesModal}>
                  {translate('COMPANY_INVITE_BTN_LABEL')}
                </CustomLink>
              </Box>
            )}
          </Box>
          <PageTabs.Content>
            {isTeamMembersTab ? (
              <CompanyTeamMembers
                config={COMPANY_TEAM_MEMBER_CONFIG}
                groups={groups}
                ownerOrgId={ownerOrgId}
                ownerOrgName={ownerOrgName}
                teamMembers={teamMembers}
                isLoading={!isTeamMembersLoaded}
                filter={filter}
                setFilter={setFilter}
              />
            ) : (
              InvitesTable
            )}
          </PageTabs.Content>
        </PageTabs.Wrapper>
        <EditCompanyModal
          data={formData}
          errors={errors}
          handleClose={closeEditModal}
          handleSave={saveCompanyInfo}
          isLoading={isLoading}
          open={isEditModalOpen}
          setErrors={setErrors}
          showErrors={showErrors}
          error={isFailed}
          updateData={updateForm}
        />
        {Boolean(groups?.length) && (
          <SendInvitesModal
            groups={groups}
            handleClose={closeSendInvitesModal}
            isLoading={isInviteFormLoading}
            open={showSendInvitesDialog}
            sendInvites={sendInvites}
          />
        )}
      </BasicPageLayout>
    </>
  );
};

export { CompanyPage };
