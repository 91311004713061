import React from 'react';
import { AuthContainer, ResetPasswordForm } from 'components/auth';
import NewThemeProvider from 'components/common/NewThemeProvider';

export const ResetPasswordPage = () => (
  <NewThemeProvider>
    <AuthContainer size="sm">
      <ResetPasswordForm />
    </AuthContainer>
  </NewThemeProvider>
);
