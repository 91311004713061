import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createAction } from 'lib/utils';
import { USER_VIEW_ENUMS } from 'lib/constants/common';
import { usersActions, publicUsersActions } from 'rdx/actions';
import userActionTypes from 'rdx/actionTypes';

const allowedEmployeePhoneTypes = ['work', 'mobile'];

const buildFormData = (user) => {
  const workEmailFiltered = user?.emails?.find(
    (email) => email.label === 'work'
  )?.email;
  const phonesNumbersFiltered = user?.phones?.filter((phone) =>
    allowedEmployeePhoneTypes.includes(phone.label)
  );

  return {
    firstName: user?.firstName,
    lastName: user?.lastName,
    displayName: user?.displayName,
    jobTitle: user?.jobTitle,
    workEmail: workEmailFiltered,
    phones: phonesNumbersFiltered,
  };
};

const useAvailableCurrentUser = () => {
  const { isSuperAdmin } = useSelector((state) => ({
    isSuperAdmin: state.authentication?.isSuperAdmin,
  }));

  return {
    isSuperAdmin,
  };
};

const useCurrentUser = (skipFetchingUser = false) => {
  const dispatch = useDispatch();

  const { user, goals, visits, userId, ownerOrgId, isSuperAdmin, views } =
    useSelector((state) => ({
      user: state.user?.user,
      goals: state.user?.goals,
      visits: state.user?.visits,
      userId: state.authentication?.userId,
      ownerOrgId: state.authentication?.ownerOrgId,
      isSuperAdmin: state.authentication?.isSuperAdmin,
      views: state.authentication?.views,
    }));

  useEffect(() => {
    if (!skipFetchingUser) {
      dispatch(usersActions.fetchUser(ownerOrgId, userId));
    }
  }, []);

  return {
    user,
    goals,
    visits,
    isSuperAdmin: Boolean(isSuperAdmin),
    isOrgAdmin: views?.includes(USER_VIEW_ENUMS.ADMIN),
    isOrgEditor: views?.includes(USER_VIEW_ENUMS.EDITOR),
    views,
  };
};

const usePublicUser = (ownerOrgId, userId, shouldFetch) => {
  const dispatch = useDispatch();

  const { users, isFetching, error } = useSelector((state) => ({
    users: state.publicUsers?.users,
    isFetching: state.publicUsers?.isFetching,
    error: state.publicUsers?.error,
  }));

  const publicUser = users[userId];

  useEffect(() => {
    if (ownerOrgId && userId && shouldFetch) {
      dispatch(publicUsersActions.fetchPublicUser(ownerOrgId, userId));
    }
  }, [shouldFetch]);

  return {
    publicUser,
    isFetching,
    error,
  };
};

const useEmployeeEditForm = ({ onSuccessfulUpdate }) => {
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({});
  const [errors, setErrors] = useState([]);
  const [showErrors, setShowErrors] = useState(false);

  const { user, userId, ownerOrgId, isUpdating, updateError } = useSelector(
    (state) => ({
      user: state.user?.user,
      userId: state.authentication?.userId,
      ownerOrgId: state.authentication?.ownerOrgId,
      isUpdating: state.user?.isUpdating,
      updateError: state.user?.updateError,
    })
  );

  useEffect(() => {
    if (!isUpdating && !updateError && onSuccessfulUpdate) {
      onSuccessfulUpdate();
    }
  }, [isUpdating]);

  useEffect(() => {
    setFormData(buildFormData(user));
  }, [user]);

  // TODO run form validation from form configuration
  const validateEmployeeEditForm = (
    validateCampaignFormData,
    validateErrors
  ) => {
    if (!validateCampaignFormData.firstName) {
      validateErrors.push('firstName');
    }

    return validateErrors;
  };

  const saveEmployeeInfo = (oldPassword, newPassword) => {
    const dataSaving = { ...formData };
    // Pass along the display name to the API
    dataSaving.displayName =
      dataSaving.displayName ||
      `${dataSaving.firstName} ${dataSaving.lastName}`;
    dataSaving.emails = [{ email: dataSaving.workEmail, label: 'work' }];
    delete dataSaving.workEmail;

    setErrors(validateEmployeeEditForm(dataSaving, errors));

    // Both passwords required for update
    if (oldPassword && newPassword) {
      dataSaving.oldPassword = oldPassword;
      dataSaving.newPassword = newPassword;
    }

    if (errors.length) {
      setShowErrors(true);
    } else {
      dispatch(usersActions.updateEmployeeInfo(ownerOrgId, userId, dataSaving));
    }
  };

  return {
    formData,
    setFormData,
    errors,
    setErrors,
    showErrors,
    setShowErrors,
    validateEmployeeEditForm,
    saveEmployeeInfo,
    isUpdating,
    updateError,
  };
};

const useSetOwnerOrgPubId = () => {
  const dispatch = useDispatch();

  const setOwnerOrgPubId = (id) => {
    dispatch(createAction({ type: userActionTypes.AUTH_SET_ORG_ID_PUB, id }));
  };

  return {
    setOwnerOrgPubId,
  };
};

const useOwnerOrgPubId = () => {
  const { ownerOrgId } = useSelector((state) => ({
    ownerOrgId: state.authentication?.ownerOrgId,
  }));

  return {
    ownerOrgId,
  };
};

export {
  useAvailableCurrentUser,
  useCurrentUser,
  usePublicUser,
  useEmployeeEditForm,
  useSetOwnerOrgPubId,
  useOwnerOrgPubId,
};
