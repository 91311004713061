import cuid from 'cuid';
import userActionTypes from 'rdx/actionTypes';
import {
  AUTH_USER_ID_TOKEN,
  AUTH_X_INSTIL,
  CORRELATION_ID,
  OWNER_ORG_ID_PUB,
  OWNER_ORG_NAME,
  OWNER_ORG_VIEWS,
  USER_ID_PUB,
  IS_SUPER_ADMIN,
  USER_EMAIL,
  RECENTLY_VIEWED_CONSTITUENTS,
  USER_VIEWS,
  FEATURE_FLAGS,
  USER_NAME_PUB,
} from 'lib/constants/localStorageKeys';
import { userService } from 'services/user';

// Both token and user data required for authenticated access
// Cognito provides the token
// Dotorg context service provides the user data
// Both are needed for a complete user session
const isAuthenticated = () =>
  !!localStorage.getItem(AUTH_USER_ID_TOKEN) &&
  !!localStorage.getItem(USER_ID_PUB);
const getAuthToken = () => localStorage.getItem(AUTH_USER_ID_TOKEN);

const setUpRefreshInterval = () => {
  if (isAuthenticated()) {
    return userService.refreshAndCreateRefreshInterval();
  }

  return null;
};

const initialState = {
  isAuthenticated: isAuthenticated(),
  authToken: getAuthToken(),
  tokenExpiration: null,
  userEmail: localStorage.getItem(USER_EMAIL),
  lastUsernameUsed: '',
  isFetching: false,
  error: null,
  refreshTokenTimerRef: setUpRefreshInterval(),
  ownerOrgId: localStorage.getItem(OWNER_ORG_ID_PUB),
  ownerOrgName: localStorage.getItem(OWNER_ORG_NAME),
  ownerOrgViews: JSON.parse(localStorage.getItem(OWNER_ORG_VIEWS)),
  userId: localStorage.getItem(USER_ID_PUB),
  isSuperAdmin: JSON.parse(localStorage.getItem(IS_SUPER_ADMIN)),
  views: JSON.parse(localStorage.getItem(USER_VIEWS)),
};

/* eslint-disable no-case-declarations */
export function authentication(state = initialState, action) {
  switch (action.type) {
    case userActionTypes.LOGIN_REQUEST:
      return {
        ...state,
        isFetching: true,
        lastUsernameUsed: action.user?.username,
      };
    case userActionTypes.LOGIN_SUCCESS:
      const {
        userResponse: { userSession: user, expiration: initialTokenExpiration },
      } = action;

      const {
        cognito: { idToken },
        org: { ownerOrgId, ownerOrgName, featureFlags, views: ownerOrgViews },
        user: { userId, displayName },
        isSuperAdmin,
        dotOrgAuthHeader,
      } = user;

      const views = user?.user?.views || [];

      // Store user details and jwt token in local storage to keep user logged in
      localStorage.setItem(AUTH_USER_ID_TOKEN, idToken);
      localStorage.setItem(AUTH_X_INSTIL, dotOrgAuthHeader);
      localStorage.setItem(OWNER_ORG_ID_PUB, ownerOrgId);
      localStorage.setItem(OWNER_ORG_NAME, ownerOrgName);
      localStorage.setItem(OWNER_ORG_VIEWS, JSON.stringify(ownerOrgViews));
      localStorage.setItem(USER_NAME_PUB, displayName);
      // correlation ID aids in tracking a user's session API calls in the
      // logs. By using this ID, a narrative of what calls were made across
      // multiple services on the back-end can be created.
      localStorage.setItem(CORRELATION_ID, cuid());
      localStorage.setItem(USER_ID_PUB, userId);
      localStorage.setItem(USER_ID_PUB, userId);
      localStorage.setItem(USER_VIEWS, JSON.stringify(views));

      // Store feature flags in local storage
      Object.keys(featureFlags).forEach((flag) =>
        localStorage.setItem(`${FEATURE_FLAGS}_${flag}`, featureFlags[flag])
      );

      if (isSuperAdmin) {
        localStorage.setItem(IS_SUPER_ADMIN, true);
      }

      return {
        ...state,
        isAuthenticated: isAuthenticated(),
        authToken: getAuthToken(),
        isFetching: false,
        tokenExpiration: initialTokenExpiration,
        ownerOrgId,
        ownerOrgName,
        userId,
        isSuperAdmin,
        views,
      };
    case userActionTypes.LOGIN_FAILURE:
      clearInterval(state.refreshTokenTimerRef);
      localStorage.removeItem(AUTH_USER_ID_TOKEN);
      localStorage.removeItem(AUTH_X_INSTIL);
      localStorage.removeItem(OWNER_ORG_ID_PUB);
      localStorage.removeItem(OWNER_ORG_VIEWS);
      localStorage.removeItem(USER_ID_PUB);
      localStorage.removeItem(IS_SUPER_ADMIN);
      localStorage.removeItem(USER_VIEWS);
      localStorage.removeItem(CORRELATION_ID);
      localStorage.removeItem(USER_NAME_PUB);

      return {
        ...state,
        isFetching: false,
        error: action.error,
      };
    case userActionTypes.LOGOUT:
      // Disconnect the refresh token timer interval
      clearInterval(state.refreshTokenTimerRef);
      localStorage.removeItem(AUTH_USER_ID_TOKEN);
      localStorage.removeItem(AUTH_X_INSTIL);
      localStorage.removeItem(OWNER_ORG_ID_PUB);
      localStorage.removeItem(OWNER_ORG_VIEWS);
      localStorage.removeItem(USER_ID_PUB);
      localStorage.removeItem(USER_EMAIL);
      localStorage.removeItem(IS_SUPER_ADMIN);
      localStorage.removeItem(RECENTLY_VIEWED_CONSTITUENTS);
      localStorage.removeItem(USER_VIEWS);
      localStorage.removeItem(CORRELATION_ID);
      localStorage.removeItem(USER_NAME_PUB);

      const localFeatureFlags = Object.keys(localStorage).filter((local) =>
        local.includes(FEATURE_FLAGS)
      );
      localFeatureFlags.forEach((flag) => {
        localStorage.removeItem(flag);
      });

      return {
        ...state,
        isAuthenticated: isAuthenticated(),
        authToken: getAuthToken(),
        isFetching: false,
        refreshTokenTimerRef: null,
      };
    case userActionTypes.LOGIN_CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    case userActionTypes.REFRESH_ACCESS_TOKEN_TIMER_SETUP:
      // Timer should only be set once
      if (state.refreshTokenTimerRef) {
        // eslint-disable-next-line no-console
        console.warn('Refresh timer already set');

        return {
          ...state,
        };
      }

      if (!state.tokenExpiration) {
        // eslint-disable-next-line no-console
        console.warn('Token expiration not set');

        return {
          ...state,
        };
      }

      // Calculate interval duration as half of the expiration time
      const msUntilExpiration =
        (state.tokenExpiration - new Date().getTime() / 1000) * 1000;
      // Refresh token halfway to expiration for safety
      const refreshInterval = msUntilExpiration / 2;

      return {
        ...state,
        refreshTokenTimerRef:
          userService.createRefreshInterval(refreshInterval),
      };
    case userActionTypes.REFRESH_ACCESS_TOKEN:
      const {
        tokenResponse: { token, expiration },
      } = action;

      localStorage.removeItem(AUTH_USER_ID_TOKEN);
      localStorage.setItem(AUTH_USER_ID_TOKEN, token);

      return {
        ...state,
        authToken: getAuthToken(),
        tokenExpiration: expiration,
      };
    case userActionTypes.AUTH_SET_ORG_ID_PUB:
      const orgId = action.id;

      localStorage.setItem(OWNER_ORG_ID_PUB, orgId);

      return {
        ...state,
        ownerOrgId: orgId,
      };
    case userActionTypes.SET_USER_REG_ADMIN_DATA:
      const { data } = action;
      const {
        signInUserSession: {
          idToken: {
            jwtToken,
            payload: { email },
          },
        },
      } = data;

      localStorage.setItem(AUTH_USER_ID_TOKEN, jwtToken);
      localStorage.setItem(AUTH_X_INSTIL, jwtToken);
      localStorage.setItem(USER_EMAIL, email);
      localStorage.setItem(CORRELATION_ID, cuid());

      return {
        ...state,
        authToken: getAuthToken(),
        userEmail: email,
      };
    case userActionTypes.REGISTER_ADMIN_SUCCESS:
      const adminUserId = action?.data?.user?.userId;
      const adminOwnerOrgId = action?.data?.user?.ownerOrgId;

      localStorage.setItem(USER_ID_PUB, adminUserId);
      localStorage.setItem(OWNER_ORG_ID_PUB, adminOwnerOrgId);

      return {
        ...state,
        isAuthenticated: isAuthenticated(),
        authToken: getAuthToken(),
        isFetching: false,
        ownerOrgId: adminOwnerOrgId,
        userId: adminUserId,
      };
    default:
      return state;
  }
}
