import { createApiRequest } from 'lib/utils/services';
import { joinNonEmptyParams } from 'lib/utils/joinNonEmptyParams';

const fetchReport = ({ ownerOrgId, reportType, moduleType, ...params }) =>
  createApiRequest({
    method: 'get',
    path: `reporting/${ownerOrgId}/dashboards/${reportType}/${moduleType}?${joinNonEmptyParams(
      { ...params }
    )}`,
    throwErrors: true,
  });

export const reportsService = {
  fetchReport,
};
