import { generatePath } from 'react-router-dom';
import { translate } from 'lib/intl';
import { personIcon, jobIcon } from 'assets/images';
import { AUTH_ROUTES } from './routes';

const CONSTITUENT_TYPES = {
  PERSON: 'prs',
  ORG: 'org',
};

const ADD_NEW_CONSTITUENT_CONFIG = [
  {
    iconSrc: personIcon,
    path: generatePath(AUTH_ROUTES.CONSTITUENT_NEW, {
      constituentType: CONSTITUENT_TYPES.PERSON,
    }),
    label: translate('CONSTITUENTS_NEW_CONSTITUENT_INDIVIDUAL_LABEL'),
  },
  {
    iconSrc: jobIcon,
    path: generatePath(AUTH_ROUTES.CONSTITUENT_NEW, {
      constituentType: CONSTITUENT_TYPES.ORG,
    }),
    label: translate('CONSTITUENTS_NEW_CONSTITUENT_ORG_LABEL'),
  },
];

export { CONSTITUENT_TYPES, ADD_NEW_CONSTITUENT_CONFIG };
