import userActionTypes from 'rdx/actionTypes';

const {
  ADD_INTERACTION_REQUEST,
  ADD_INTERACTION_SUCCESS,
  ADD_INTERACTION_FAILURE,
  ADD_INTERACTION_RESET,

  EDIT_INTERACTION_REQUEST,
  EDIT_INTERACTION_SUCCESS,
  EDIT_INTERACTION_FAILURE,
  EDIT_INTERACTION_RESET,
} = userActionTypes;

const initialState = {
  isFetching: false,
  error: null,
  data: [],
  dataLoaded: false,
};

/* eslint-disable no-case-declarations */
export function addInteraction(state = initialState, action) {
  switch (action.type) {
    case ADD_INTERACTION_REQUEST:
    case EDIT_INTERACTION_REQUEST:
      return {
        ...state,
        isFetching: true,
        dataLoaded: false,
      };
    case ADD_INTERACTION_SUCCESS:
    case EDIT_INTERACTION_SUCCESS:
      return {
        ...state,
        isFetching: false,
        data: action.data.donors,
        dataLoaded: true,
      };
    case ADD_INTERACTION_FAILURE:
    case EDIT_INTERACTION_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.error,
        dataLoaded: false,
      };
    case ADD_INTERACTION_RESET:
    case EDIT_INTERACTION_RESET:
      return initialState;
    default:
      return state;
  }
}
