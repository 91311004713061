import { values } from 'rambdax';
import PropTypes from 'prop-types';
import { FEATURE_FLAG_GRANTS } from 'lib/constants';
import {
  DonationGeneralIcon,
  DonationPledge,
  DonationRecurring,
  DonationRestricted,
  DonationInKind,
  DonationPlanned,
  DonationTime,
  DonationGrant,
  PaymentCash,
  PaymentCheck,
  PaymentCard,
  PaymentBankWire,
  PaymentApp,
  PaymentCryptoIcon,
  VeryHighValueIcon,
  HighValueIcon,
  MediumValueIcon,
  SoSoValueIcon,
  LowValueIcon,
  VolunteerEventsIcon,
  VolunteerCampaignsIcon,
  VolunteerGeneralIcon,
  VolunteerGiftsIcon,
  VolunteerOperationsIcon,
  VolunteerFoundationIcon,
  VolunteerGovernanceIcon,
  VolunteerAdvocacyIcon,
  VolunteerOtherIcon,
} from 'assets/images';
import {
  DONATION_TYPE_ENUMS,
  PAYMENT_TYPE_ENUMS,
  TIME_CATEGORY_ENUMS,
  TIME_TYPE_ENUM,
} from 'lib/constants/common';
import { translate } from 'lib/intl';
import { CompanyTeamMember } from 'lib/utils';

const CampaignDonationType = {
  donorId: PropTypes.string.isRequired,
  donationId: PropTypes.string.isRequired,
  amount: PropTypes.number,
  donorDisplayName: PropTypes.string.isRequired,
  donationDateString: PropTypes.string.isRequired,
  donationTypeEnum: PropTypes.oneOf(values(DONATION_TYPE_ENUMS)).isRequired,
  paymentTypeEnum: PropTypes.oneOf([...values(PAYMENT_TYPE_ENUMS), '']),
  timeCategory: PropTypes.oneOf([...values(TIME_CATEGORY_ENUMS), '']),
  time: PropTypes.number,
};

const DONATION_FIELD_NAMES = {
  selectedDonor: {
    key: 'selectedDonor',
    isRequired: true,
  },
  donationType: {
    key: 'donationType',
    isRequired: true,
  },
  selectedDate: {
    key: 'selectedDate',
    isRequired: true,
  },
  selectedCampaign: {
    key: 'selectedCampaign',
    isRequired: true,
  },
  paymentMethod: {
    key: 'paymentMethod',
    isRequired: true,
  },
  donationAmount: {
    key: 'donationAmount',
    isRequired: true,
  },
  notes: {
    key: 'notes',
    isRequired: false,
  },
  pledgeDueDate: {
    key: 'pledgeDueDate',
    isRequired: true,
  },
  recurringCadence: {
    key: 'recurringCadence',
    isRequired: true,
  },
  recurringStartDate: {
    key: 'recurringStartDate',
    isRequired: true,
  },
  areaOfRestriction: {
    key: 'areaOfRestriction',
    isRequired: true,
  },
  itemDonated: {
    key: 'itemDonated',
    isRequired: true,
  },
  itemQuantity: {
    key: 'itemQuantity',
    isRequired: true,
  },
  timeCategory: {
    key: 'timeCategory',
    isRequired: true,
  },
  timeValue: {
    key: 'timeValue',
    isRequired: true,
  },
  amountOfTime: {
    key: 'amountOfTime',
    isRequired: true,
  },
  timeType: {
    key: 'timeType',
    isRequired: true,
  },
  grantName: {
    key: 'grantName',
    isRequired: false,
  },
  restricted: {
    key: 'restricted',
    isRequired: false,
  },
  restrictionCondition: {
    key: 'restrictionCondition',
    isRequired: false,
  },
  developers: {
    key: 'developers',
    isRequired: false,
  },
};

const DONATION_TYPES = {
  GENERAL: {
    value: DONATION_TYPE_ENUMS.GENERAL,
    icon: DonationGeneralIcon,
    iconAlt: translate('DONATION_GENERAL_ICON_ALT'),
    displayText: translate('DONATION_GENERAL'),
  },
  /*
    Api doesn't support creating major_gift donations,
    though we have to support previously created donations of this type.
    Currently config for MAJOR_GIFT mirrors GENERAL.
    TODO: revisit after clering dbs
  */
  MAJOR_GIFT: {
    value: DONATION_TYPE_ENUMS.GENERAL,
    icon: DonationGeneralIcon,
    iconAlt: translate('DONATION_GENERAL_ICON_ALT'),
    displayText: translate('DONATION_GENERAL'),
  },
  PLEDGE: {
    value: DONATION_TYPE_ENUMS.PLEDGE,
    icon: DonationPledge,
    iconAlt: translate('DONATION_PLEDGE_ICON_ALT'),
    displayText: translate('DONATION_PLEDGE'),
  },
  RECURRING: {
    value: DONATION_TYPE_ENUMS.RECURRING,
    icon: DonationRecurring,
    iconAlt: translate('DONATION_RECURRING_ICON_ALT'),
    displayText: translate('DONATION_RECURRING'),
  },
  RESTRICTED: {
    value: DONATION_TYPE_ENUMS.RESTRICTED,
    icon: DonationRestricted,
    iconAlt: translate('DONATION_RESTRICTED_ICON_ALT'),
    displayText: translate('DONATION_RESTRICTED'),
  },
  IN_KIND: {
    value: DONATION_TYPE_ENUMS.IN_KIND,
    icon: DonationInKind,
    iconAlt: translate('DONATION_IN_KIND_ICON_ALT'),
    displayText: translate('DONATION_IN_KIND'),
  },
  PLANNED_GIVING: {
    value: DONATION_TYPE_ENUMS.PLANNED_GIVING,
    icon: DonationPlanned,
    iconAlt: translate('DONATION_PLANNED_ICON_ALT'),
    displayText: translate('DONATION_PLANNED'),
  },
  TIME: {
    value: DONATION_TYPE_ENUMS.TIME,
    icon: DonationTime,
    iconAlt: translate('DONATION_TIME_ICON_ALT'),
    displayText: translate('DONATION_TIME'),
  },
  GRANT: {
    value: DONATION_TYPE_ENUMS.GRANT,
    icon: DonationGrant,
    iconAlt: translate('DONATION_GRANT_ICON_ALT'),
    displayText: translate('DONATION_GRANT'),
    featureFlag: FEATURE_FLAG_GRANTS,
  },
};

const DONATION_TYPES_VALIDATION_FIELDS = {
  GENERAL: [
    DONATION_FIELD_NAMES.selectedDonor.key,
    DONATION_FIELD_NAMES.donationType.key,
    DONATION_FIELD_NAMES.selectedDate.key,
    DONATION_FIELD_NAMES.paymentMethod.key,
    DONATION_FIELD_NAMES.donationAmount.key,
    DONATION_FIELD_NAMES.notes.key,
  ],
  PLEDGE: [
    DONATION_FIELD_NAMES.selectedDonor.key,
    DONATION_FIELD_NAMES.donationType.key,
    DONATION_FIELD_NAMES.selectedDate.key,
    DONATION_FIELD_NAMES.paymentMethod.key,
    DONATION_FIELD_NAMES.donationAmount.key,
    DONATION_FIELD_NAMES.notes.key,
    DONATION_FIELD_NAMES.pledgeDueDate.key,
  ],
  RECURRING: [
    DONATION_FIELD_NAMES.selectedDonor.key,
    DONATION_FIELD_NAMES.donationType.key,
    DONATION_FIELD_NAMES.selectedDate.key,
    DONATION_FIELD_NAMES.paymentMethod.key,
    DONATION_FIELD_NAMES.donationAmount.key,
    DONATION_FIELD_NAMES.notes.key,
    DONATION_FIELD_NAMES.recurringCadence.key,
    DONATION_FIELD_NAMES.recurringStartDate.key,
  ],
  RESTRICTED: [
    DONATION_FIELD_NAMES.selectedDonor.key,
    DONATION_FIELD_NAMES.donationType.key,
    DONATION_FIELD_NAMES.selectedDate.key,
    DONATION_FIELD_NAMES.paymentMethod.key,
    DONATION_FIELD_NAMES.donationAmount.key,
    DONATION_FIELD_NAMES.notes.key,
    DONATION_FIELD_NAMES.areaOfRestriction.key,
  ],
  IN_KIND: [
    DONATION_FIELD_NAMES.selectedDonor.key,
    DONATION_FIELD_NAMES.donationType.key,
    DONATION_FIELD_NAMES.selectedDate.key,
    DONATION_FIELD_NAMES.donationAmount.key,
    DONATION_FIELD_NAMES.notes.key,
    DONATION_FIELD_NAMES.itemDonated.key,
    DONATION_FIELD_NAMES.itemQuantity.key,
  ],
  PLANNED_GIVING: [
    DONATION_FIELD_NAMES.selectedDonor.key,
    DONATION_FIELD_NAMES.donationType.key,
    DONATION_FIELD_NAMES.selectedDate.key,
    DONATION_FIELD_NAMES.donationAmount.key,
    DONATION_FIELD_NAMES.notes.key,
  ],
  TIME: [
    DONATION_FIELD_NAMES.selectedDonor.key,
    DONATION_FIELD_NAMES.donationType.key,
    DONATION_FIELD_NAMES.timeCategory.key,
    DONATION_FIELD_NAMES.timeValue.key,
    DONATION_FIELD_NAMES.amountOfTime.key,
    DONATION_FIELD_NAMES.timeType.key,
  ],
  GRANT: [
    DONATION_FIELD_NAMES.selectedDonor.key,
    DONATION_FIELD_NAMES.selectedDate.key,
    DONATION_FIELD_NAMES.donationType.key,
    DONATION_FIELD_NAMES.donationAmount.key,
    DONATION_FIELD_NAMES.grantName.key,
    DONATION_FIELD_NAMES.restricted.key,
    DONATION_FIELD_NAMES.restrictionCondition.key,
    DONATION_FIELD_NAMES.developers.key,
    DONATION_FIELD_NAMES.paymentMethod.key,
  ],
};

const DONATION_PAYMENT_METHODS = [
  {
    value: PAYMENT_TYPE_ENUMS.CASH,
    icon: PaymentCash,
    iconAlt: translate('PAYMENT_CASH_ICON_ALT'),
    text: translate('DONATION_PAYMENT_CASH'),
  },
  {
    value: PAYMENT_TYPE_ENUMS.CHECK,
    icon: PaymentCheck,
    iconAlt: translate('PAYMENT_CHECK_ICON_ALT'),
    text: translate('DONATION_PAYMENT_CHECK'),
  },
  {
    value: PAYMENT_TYPE_ENUMS.CREDIT,
    icon: PaymentCard,
    iconAlt: translate('PAYMENT_CARD_ICON_ALT'),
    text: translate('DONATION_PAYMENT_CREDIT_CARD'),
  },
  {
    value: PAYMENT_TYPE_ENUMS.WIRE,
    icon: PaymentBankWire,
    iconAlt: translate('PAYMENT_BANK_WIRE_ICON_ALT'),
    text: translate('DONATION_PAYMENT_BANK_WIRE'),
  },
  {
    value: PAYMENT_TYPE_ENUMS.APP,
    icon: PaymentApp,
    iconAlt: translate('PAYMENT_APP_ICON_ALT'),
    text: translate('DONATION_PAYMENT_APP'),
  },
  {
    value: PAYMENT_TYPE_ENUMS.CRYPTO,
    icon: PaymentCryptoIcon,
    iconAlt: translate('PAYMENT_CRYPTO_ICON_ALT'),
    text: translate('DONATION_PAYMENT_CRYPTO'),
  },
];

const RECURRING_CADENCES_ENUM = {
  MONTH: 'month',
  QUARTER: 'quarter',
  YEAR: 'year',
};

const DONATION_RECURRING_CADENCES = [
  {
    value: RECURRING_CADENCES_ENUM.MONTH,
    text: translate('RECURRING_DONATION_MONTHLY_LABEL'),
  },
  {
    value: RECURRING_CADENCES_ENUM.QUARTER,
    text: translate('RECURRING_DONATION_QUARTERLY_LABEL'),
  },
  {
    value: RECURRING_CADENCES_ENUM.YEAR,
    text: translate('RECURRING_DONATION_YEARLY_LABEL'),
  },
];

const DONATION_VOLUNTEER_CONFIG = [
  {
    value: TIME_CATEGORY_ENUMS.EVENTS,
    icon: VolunteerEventsIcon,
    iconAlt: translate('VOLUNTEER_EVENTS_ICON_ALT'),
    text: translate('DONATION_EVENTS'),
    enum: TIME_CATEGORY_ENUMS.EVENTS,
  },
  {
    value: TIME_CATEGORY_ENUMS.CAMPAIGNS,
    icon: VolunteerCampaignsIcon,
    iconAlt: translate('VOLUNTEER_CAMPAIGNS_ICON_ALT'),
    text: translate('DONATION_CAMPAIGNS'),
    enum: TIME_CATEGORY_ENUMS.CAMPAIGNS,
  },
  {
    value: TIME_CATEGORY_ENUMS.GENERAL,
    icon: VolunteerGeneralIcon,
    iconAlt: translate('VOLUNTEER_GENERAL_ICON_ALT'),
    text: translate('DONATION_GENERAL_CORPORATE'),
    enum: TIME_CATEGORY_ENUMS.GENERAL,
  },
  {
    value: TIME_CATEGORY_ENUMS.GIFTS,
    icon: VolunteerGiftsIcon,
    iconAlt: translate('VOLUNTEER_GIFTS_ICON_ALT'),
    text: translate('DONATION_GIFTS'),
    enum: TIME_CATEGORY_ENUMS.GIFTS,
  },
  {
    value: TIME_CATEGORY_ENUMS.OPERATIONS,
    icon: VolunteerOperationsIcon,
    iconAlt: translate('VOLUNTEER_OPERATIONS_ICON_ALT'),
    text: translate('DONATION_OPERATIONS'),
    enum: TIME_CATEGORY_ENUMS.OPERATIONS,
  },
  {
    value: TIME_CATEGORY_ENUMS.GRANTS,
    icon: VolunteerFoundationIcon,
    iconAlt: translate('VOLUNTEER_FOUNDATION_ICON_ALT'),
    text: translate('DONATION_FOUNDATIONS'),
    enum: TIME_CATEGORY_ENUMS.GRANTS,
  },
  {
    value: TIME_CATEGORY_ENUMS.GOVERNANCE,
    icon: VolunteerGovernanceIcon,
    iconAlt: translate('VOLUNTEER_GOVERNANCE_ICON_ALT'),
    text: translate('DONATION_VOLUNTEERED_GOVERNANCE'),
    enum: TIME_CATEGORY_ENUMS.GOVERNANCE,
  },
  {
    value: TIME_CATEGORY_ENUMS.ADVOCACY,
    icon: VolunteerAdvocacyIcon,
    iconAlt: translate('VOLUNTEER_ADVOCACY_ICON_ALT'),
    text: translate('DONATION_ADVOCACY'),
    enum: TIME_CATEGORY_ENUMS.ADVOCACY,
  },
  {
    value: TIME_CATEGORY_ENUMS.OTHER,
    icon: VolunteerOtherIcon,
    iconAlt: translate('VOLUNTEER_OTHER_ICON_ALT'),
    text: translate('DONATION_VOLUNTEERED_OTHER'),
    enum: TIME_CATEGORY_ENUMS.OTHER,
  },
];

const DONATION_VALUE_ENUMS = {
  SUPER: 5,
  HIGH: 4,
  MEDIUM: 3,
  SOSO: 2,
  LOW: 1,
};

const DONATION_VALUE_OF_TIME_CONFIG = [
  {
    value: DONATION_VALUE_ENUMS.SUPER,
    icon: VeryHighValueIcon,
    iconAlt: translate('DONATION_VERY_HIGH_VALUE_ICON_ALT'),
    text: translate('DONATION_VERY_HIGH_VALUE'),
  },
  {
    value: DONATION_VALUE_ENUMS.HIGH,
    icon: HighValueIcon,
    iconAlt: translate('DONATION_HIGH_VALUE_ICON_ALT'),
    text: translate('DONATION_HIGH_VALUE'),
  },
  {
    value: DONATION_VALUE_ENUMS.MEDIUM,
    icon: MediumValueIcon,
    iconAlt: translate('DONATION_MODERATE_VALUE_ICON_ALT'),
    text: translate('DONATION_MODERATE_VALUE'),
  },
  {
    value: DONATION_VALUE_ENUMS.SOSO,
    icon: SoSoValueIcon,
    iconAlt: translate('DONATION_LOW_VALUE_ICON_ALT'),
    text: translate('DONATION_LOW_VALUE'),
  },
  {
    value: DONATION_VALUE_ENUMS.LOW,
    icon: LowValueIcon,
    iconAlt: translate('DONATION_MINOR_VALUE_ICON_ALT'),
    text: translate('DONATION_MINOR_VALUE'),
  },
];

const DONATION_TIME_TYPE_CONFIG = [
  {
    value: TIME_TYPE_ENUM.MINUTES,
    text: translate('DONATION_MINUTES'),
  },
  {
    value: TIME_TYPE_ENUM.HOURS,
    text: translate('DONATION_HOURS'),
  },
  {
    value: TIME_TYPE_ENUM.DAYS,
    text: translate('DONATION_DAYS'),
  },
];

const DonationDetailsType = {
  actualValue: PropTypes.number,
  campaignDisplayName: PropTypes.string,
  created: PropTypes.string.isRequired,
  developers: PropTypes.PropTypes.arrayOf(CompanyTeamMember),
  // used for recurring donaitons
  donationId: PropTypes.string.isRequired,
  donationPlan: PropTypes.shape({
    recurringCadenceEnum: PropTypes.oneOf(values(RECURRING_CADENCES_ENUM)),
    recurringStartDateString: PropTypes.string,
  }),
  donationTypeEnum: PropTypes.oneOf([...values(DONATION_TYPE_ENUMS), ''])
    .isRequired,
  estimatedValue: PropTypes.number,
  grantData: PropTypes.shape({
    grantName: PropTypes.string,
    restricted: PropTypes.bool,
    restrictionCondition: PropTypes.string,
  }),
  inKind: PropTypes.arrayOf(
    PropTypes.shape({
      inKindItemDescription: PropTypes.string,
      inKindItemQuantity: PropTypes.number,
      inKindTotalValue: PropTypes.number,
    })
  ),
  notes: PropTypes.arrayOf(PropTypes.shape({ text: PropTypes.string })),
  paymentTypeEnum: PropTypes.oneOf([...values(PAYMENT_TYPE_ENUMS), '']),
  pledgeData: PropTypes.shape({
    pledgeDueDateString: PropTypes.string,
  }),
  restrictions: PropTypes.arrayOf(
    PropTypes.shape({
      restrictionDescription: PropTypes.string,
    })
  ),
  timeData: PropTypes.shape({
    timeCategory: PropTypes.oneOf([...values(TIME_CATEGORY_ENUMS), '']),
    timeValue: PropTypes.oneOf([...values(DONATION_VALUE_ENUMS), 0]),
    time: PropTypes.number,
    timeUnit: PropTypes.string,
  }),
  updated: PropTypes.string.isRequired,
};

export {
  CampaignDonationType,
  DonationDetailsType,
  DONATION_FIELD_NAMES,
  DONATION_TYPES,
  DONATION_TYPES_VALIDATION_FIELDS,
  DONATION_PAYMENT_METHODS,
  DONATION_RECURRING_CADENCES,
  DONATION_VOLUNTEER_CONFIG,
  DONATION_VALUE_ENUMS,
  DONATION_VALUE_OF_TIME_CONFIG,
  TIME_TYPE_ENUM,
  DONATION_TIME_TYPE_CONFIG,
};
