import React, { useMemo } from 'react';
import { any, string, arrayOf, func, node, shape } from 'prop-types';
import { prop } from 'rambdax';
import styled from 'styled-components';
import { Box, Link, Typography } from '@material-ui/core';
import { colors } from 'styles/theme';
import { Visible } from 'components';
import { EMPTY_FUNC, STOP_PROPAGATION } from 'lib/constants';
import { GapType } from 'lib/utils';

const StyledText = styled(Typography)`
  cursor: text;
  font-size: 16px;

  ${({ url }) =>
    url
      ? `
    cursor: pointer;
    font-weight: 500;
  `
      : ''}

  ${({ placeholder }) =>
    placeholder
      ? `
    cursor: pointer;
    color: ${colors.darkGrey};
    font-style: italic;
  `
      : ''}
`;

const StyledLinkContainer = styled(Box)`
  color: ${prop('color')};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const ViewFields = ({
  icon,
  data,
  gapSize,
  iconContainerWidth,
  noDataLabel,
  onNoDataLabelClick,
}) => {
  const Values = useMemo(
    () =>
      data?.reduce((acc, { value, url }, idx) => {
        const color = url ? colors.primaryGreen : colors.black;

        if (value) {
          const Text = (
            <StyledText as="span" color={color} url={url}>
              {value}
            </StyledText>
          );

          acc.push(
            <Visible
              fallback={Text}
              // eslint-disable-next-line
              key={idx}
              when={Boolean(url)}
            >
              <StyledLinkContainer color={color}>
                <Link href={url} target="_blank">
                  {Text}
                </Link>
              </StyledLinkContainer>
            </Visible>
          );
        }

        return acc;
      }, []),
    [data]
  );

  const NoDataMessage = useMemo(
    () => (
      <Visible when={Boolean(noDataLabel)}>
        <StyledText onClick={onNoDataLabelClick} placeholder={1}>
          {noDataLabel}
        </StyledText>
      </Visible>
    ),
    [noDataLabel, onNoDataLabelClick]
  );

  return (
    <Visible when={Boolean(Values?.length || noDataLabel)}>
      <Box data-testid="view-fields" display="flex" py="4px">
        <Visible when={Boolean(icon)}>
          <Box
            display="flex"
            justifyContent="center"
            className="flex-shrink-0"
            mr={gapSize}
            width={iconContainerWidth}
          >
            {icon}
          </Box>
        </Visible>
        <Visible when={Boolean(Values?.length)} fallback={NoDataMessage}>
          <Box display="flex" flexDirection="column" onClick={STOP_PROPAGATION}>
            {Values}
          </Box>
        </Visible>
      </Box>
    </Visible>
  );
};

ViewFields.propTypes = {
  icon: node,
  iconContainerWidth: string,
  gapSize: GapType.size,
  data: arrayOf(
    shape({
      value: any,
      url: string,
    })
  ),
  noDataLabel: string,
  onNoDataLabelClick: func,
};

ViewFields.defaultProps = {
  icon: null,
  iconContainerWidth: 'auto',
  data: [],
  gapSize: 2,
  noDataLabel: '',
  onNoDataLabelClick: EMPTY_FUNC,
};

export { ViewFields };
