export const CAMPAIGNS = {
  CAMPAIGNS_TITLE: 'Campaigns',
  CAMPAIGNS_TITLE_ICON_ALT: 'Campaigns icon',
  FETCH_CAMPAIGNS_ERROR: 'There was a problem fetching campaigns',
  FETCH_CAMPAIGN_DONATIONS_ERROR:
    'There was a problem fetching campaign donations',
  CREATE_CAMPAIGN_ERROR_MESSAGE: 'There was a problem creating {name}',
  UPDATE_CAMPAIGN_ERROR_MESSAGE: 'There was a problem updating {name}',
  EDIT_CAMPAIGN_TITLE: 'Edit Campaign',
  CREATE_NEW_CAMPAIGN_TITLE: 'Create New Campaign',
  CAMPAIGN_FORM_FIX_ERRORS_MESSAGE: 'Fix errors to save the data',
  CAMPAIGN_NAME_LABEL: 'Campaign Name',
  CAMPAIGN_NAME_REQUIRED_LABEL: 'Campaign Name Required',
  CAMPAIGN_DISPLAY_NAME_LABEL: 'Display Name',
  CAMPAIGN_DESCRIPTION_LABEL: 'Campaign Description',
  CAMPAIGN_WEBSITE_LABEL: 'Campaign Website',
  CAMPAIGN_CATEGORY_LABEL: 'Campaign Category',
  CAMPAIGN_GOAL_LABEL: 'Campaign Goal',
  CAMPAIGN_GENERAL_LABEL: 'General',
  CAMPAIGN_CROWDFUNDING_LABEL: 'Crowdfunding',
  CAMPAIGN_PEER_TO_PEER_LABEL: 'Peer-to-Peer',
  CAMPAIGN_EVENT_LABEL: 'Event',
  CAMPAIGN_REGISTRATION_LABEL: 'Registration w/ fundraisng',
  CAMPAIGN_MONETARY_LABEL: 'Monetary',
  CAMPAIGN_IN_KIND_LABEL: 'In-kind',
  CAMPAIGN_GOAL_AMOUNT_LABEL: 'Goal',
  CAMPAIGN_GOAL_AMOUT_REQUIRED_LABEL: 'Goal Required',
  CAMPAIGN_STRETCH_GOAL_LABEL: 'Stretch Goal',
  CAMPAIGN_STRETCH_GOAL_REQUIRED_LABEL: 'Stretch Goal Required',
  CAMPAIGN_START_DATE_LABEL: 'Start Date',
  CAMPAIGN_END_DATE_LABEL: 'End Date',
  CAMPAIGN_EVENT_LOCATION_LABEL: 'Event Location',
  CAMPAIGN_EVENT_LOCATION_REQUIRED_LABEL: 'Event Location Required',
  CAMPAIGN_EVENT_DATE_LABEL: 'Event Date',
  CAMPAIGN_TICKET_PRICE_LABEL: 'Ticket Price',
  CAMPAIGN_TICKET_PRICE_REQUIRED_LABEL: 'Ticket Price Required',
  CAMPAIGN_TICKET_SOLD_LABEL: 'Tickets Available',
  CAMPAIGN_TICKET_SOLD_REQUIRED_LABEL: 'Tickets Sold Required',
  CAMPAIGN_ITEM_TYPE_LABEL: 'Item Type',
  CAMPAIGN_ITEM_TYPE_REQUIRED_LABEL: 'Item Type Required',
  CAMPAIGN_NUMBER_OF_ITEMS_LABEL: 'Number of Items',
  CAMPAIGN_NUMBER_OF_ITEMS_REQUIRED_LABEL: 'Number of Items Required',
  CAMPAIGN_ESTIMATED_ITEM_VALUE_LABEL: 'Estimated Item Value',
  CAMPAIGN_ESTIMATED_ITEM_VALUE_REQUIRED_LABEL: 'Estimated Item Value Required',
  CAMPAIGN_ESTIMATED_VALUE_LABEL: 'Estimated Value',
  CAMPAIGN_ADMINISTRATORS_LABEL: 'Campaign Administrators',
  CAMPAIGN_ADMINISTRATORS_REQUIRED_LABEL: 'Campaign Administrators Required',
  CAMPAIGN_OVERVIEW_TAB: 'Overview',
  CAMPAIGN_DONATIONS_TAB: 'Donations',
  CAMPAIGN_REPORTS_TAB: 'Reports',
  CAMPAIGN_TOTAL_AMOUNT_DONATED_LABEL: 'Total Amount Donated',
  CAMPAIGN_TOTAL_NUMBER_OF_DONORS_LABEL: 'Total # of Donors',
  CAMPAIGN_AVG_DONATION_LABEL: 'Avg Donation',
  CAMPAIGN_LARGEST_DONATION_LABEL: 'Largest Donation',
  CAMPAIGN_NEW_DONORS_LABEL: 'New Donors',
  CAMPAIGN_RETURN_DONORS_LABEL: 'Return Donors',
  CAMPAIGN_GOAL_AMOUNT_VALUE_LABEL: 'Goal: {value}',
  CAMPAIGN_DAYS_TILL_EVENT_LABEL: '{days} till event',
  CAMPAIGN_DAYS_TILL_START_LABEL: '{days} till start',
  CAMPAIGN_DAYS_LEFT_LABEL: '{days} left',
  CAMPAIGN_TOTAL_DAYS_LABEL: 'Total: {days}',
  CAMPAIGNS_SEARCH_PLACEHOLDER: 'Search Campaigns',
  ADD_CAMPAIGN_LABEL: 'Add New Campaign',
  CAMPAIGNS_SHOW_ARCHIVED_LABEL: 'Show Archived',
  CAMPAIGNS_CARD_ACTIONS_TITLE: 'Campaign Card Actions',
  CAMPAIGNS_EDIT_ACTION_LABEL: 'Edit',
  CAMPAIGNS_DUPLICATE_ACTION_LABEL: 'Duplicate',
  CAMPAIGNS_ACTIVATE_ACTION_LABEL: 'Activate Capaign',
  CAMPAIGNS_ARCHIVE_ACTION_LABEL: 'Archive',
  CAMPAIGNS_COMPLETE_ACTION_LABEL: 'Mark as Complete',
  CAMPAIGNS_DELETE_ACTION_LABEL: 'Delete',
  CAMPAIGN_DETAILS: 'Details',
  CAMPAIGN_DETAILS_EDIT: 'Edit',
  CAMPAIGN_DETAILS_WEBSITE: 'Campaign Website',
  CAMPAIGN_DETAILS_CATEGORY: 'Campaign Category',
  CAMPAIGN_DETAILS_GOAL_TYPE: 'Goal Type',
  CAMPAIGN_DETAILS_GOAL: 'Goal',
  CAMPAIGN_DETAILS_STRETCH_GOAL: 'Stretch Goal',
  CAMPAIGN_DETAILS_START_DATE: 'Start Date',
  CAMPAIGN_DETAILS_END_DATE: 'End Date',
  CAMPAIGN_DETAILS_ADMINISTRATORS: 'Administrators',
  CAMPAIGNS_REMOVE_DESCRIPTION:
    'Are you sure you want to delete this this campaign?',
  REMOVE_CAMPAIGN_ERROR: 'There was a problem removing a campaign',
  CAMPAIGNS_FISCAL_GOAL_TITLE: 'Fiscal Goal',
  CAMPAIGNS_GOAL_TOTAL_LABEL: 'Goal Total',
  UPDATE_CAMPAIGN_FISCAL_GOAL_ERROR:
    'There was a problem updating a fiscal goal',
  CAMPAIGNS_SET_ANNUAL_GOAL_TITLE: 'Set Your Annual Goal - {year}',
  CAMPAIGNS_TOTAL_REVENUE_LABEL: 'Total Campaign Revenue',
  CAMPAIGNS_TOTAL_DONORS_LABEL: 'Total Donors',
  CAMPAIGNS_AVG_DONATION_LABEL: 'Avg Donation',
  CAMPAIGNS_TOTAL_GOAL_PERCENTAGE_LABEL: 'of total goal',
  CAMPAIGN_DONATION_AMOUNT_LABEL: 'Amount',
  CAMPAIGN_DONATION_MENTOD_LABEL: 'Method',
  CAMPAIGN_DONATION_DONOR_NAME_LABEL: 'Donor Name',
  CAMPAIGN_DONATION_TYPE_LABEL: 'Type',
  CAMPAIGN_DONATION_SOURCE_LABEL: 'Source',
  CAMPAIGN_DONATION_DATE_LABEL: 'Date',
  CAMPAIGN_SEARCH_DONORS_LABEL: 'Search Donors',
  ALL_CAMPAIGNS_LABEL: 'All Campaigns',
  CAMPAIGN_BAR_GOAL_LABEL: 'Goal',
};
