import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Controller } from 'react-hook-form';
import PhoneInput from 'react-phone-input-2';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  '@global': {
    '.react-tel-input': {
      marginTop: `${theme.spacing(2) - 3}px`,
    },
    '.react-tel-input .flag-dropdown:hover, .react-tel-input .flag-dropdown:focus':
      {
        cursor: 'auto',
      },
    '.react-tel-input .form-control': {
      width: 'calc(100% - 62px)', // 62px is the combined left & right padding used internally on <input>
      borderRadius: 0,
      borderStyle: 'none',
      paddingBottom: theme.spacing(1),
      paddingTop: theme.spacing(1),
      paddingLeft: theme.spacing(6),
    },
    '.react-tel-input.Mui-error .form-control': {
      color: theme.palette.error.main,
    },
    '.react-tel-input .form-control + div:before': {
      left: '-5px',
      top: '-11px',
      content({ required, label }) {
        return label && required && `'${label}*' !important`;
      },
    },
    '.react-tel-input.Mui-error .form-control + div:before': {
      color: `${theme.palette.error.main} !important`,
    },
    '.react-tel-input .selected-flag': {
      paddingLeft: 0,
      marginTop: theme.spacing(1),
      height: '20px',
    },
  },
}));

export default function ControlledPhoneInput({
  control,
  dataTestid,
  defaultValue,
  errors,
  label,
  name,
  required,
  rules,
  ...props
}) {
  useStyles({
    label,
    required,
  });

  const [hasFocus, setHasFocus] = React.useState(false);
  const handleFocus = () => setHasFocus(true);
  const handleBlur = () => setHasFocus(false);
  const invalid = !!errors?.[name];

  return (
    <Controller
      control={control}
      defaultValue={defaultValue}
      name={name}
      {...(rules && { rules })}
      render={({ onChange, value }) => {
        return (
          <PhoneInput
            containerClass={classnames('MuiInput-underline', {
              'Mui-focused': hasFocus,
              'Mui-error': invalid,
            })}
            country="us"
            countryCodeEditable={false}
            inputClass="MuiInputBase-input MuiInput-input"
            inputProps={{
              'data-testid': dataTestid,
              name,
              required: !!rules?.required,
            }}
            disableDropdown
            onBlur={handleBlur}
            onlyCountries={['us']}
            onChange={onChange}
            onFocus={handleFocus}
            value={value}
            {...props}
          />
        );
      }}
    />
  );
}

ControlledPhoneInput.propTypes = {
  control: PropTypes.object.isRequired,
  dataTestid: PropTypes.string,
  defaultValue: PropTypes.string.isRequired,
  errors: PropTypes.object,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  required: PropTypes.bool,
  rules: PropTypes.object,
};

ControlledPhoneInput.defaultProps = {
  dataTestid: 'phone-input',
  errors: null,
  required: false,
  rules: null,
};
