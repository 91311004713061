import React from 'react';
import propTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { translate } from 'lib/intl';
import { CompanyTeamMember } from 'lib/utils';
import { invitesActions } from 'rdx/actions';
import { INVITE_STATUSES } from 'lib/constants/common';
import { CustomLink } from 'components/common/CustomLink';

const { sendInvites } = invitesActions;

const TeamMemberResendCell = ({ data, ownerOrgId, ownerOrgName }) => {
  if (data?.status === INVITE_STATUSES.JOINED) {
    return null;
  }

  const dispatch = useDispatch();

  const onClick = React.useCallback(
    (e) => {
      e.preventDefault();
      e.stopPropagation();

      dispatch(
        sendInvites(
          ownerOrgId,
          {
            emails: [data?.email],
            groups: data?.groups,
            ownerOrgName,
          },
          true
        )
      );
    },
    [data]
  );

  return (
    <CustomLink onClick={onClick}>
      {translate('COMPANY_RESEND_LABEL')}
    </CustomLink>
  );
};

TeamMemberResendCell.propTypes = {
  data: CompanyTeamMember.isRequired,
  ownerOrgId: propTypes.string,
  ownerOrgName: propTypes.string,
};

TeamMemberResendCell.defaultProps = {
  ownerOrgId: '',
  ownerOrgName: '',
};

export { TeamMemberResendCell };
