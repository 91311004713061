/* global d3 */
/* eslint no-shadow: 0 */
import { formatDateForReport } from 'reports/donationReportUtils';
import { REPORTS_DAILY_TIME_PERIOD } from 'lib/constants';

export default class DonationPerformanceReport {
  constructor(options) {
    this.id = options.id;
    this.transitionDuration = 500;
    this.margin = {
      top: 20,
      right: 20,
      bottom: 30,
      left: 40,
    };
    this.width = 960 - this.margin.left - this.margin.right;
    this.height = 500 - this.margin.top - this.margin.bottom;
    this.x = null;
    this.y = null;
    this.timePeriod = REPORTS_DAILY_TIME_PERIOD;
  }

  setTimePeriod(timePeriod) {
    this.timePeriod = timePeriod;
  }

  setRanges() {
    // set the ranges
    this.x = d3.scaleBand().rangeRound([0, this.width], 0.5).padding(0.1);
    this.y = d3.scaleLinear().range([this.height, 0]);
  }

  setAxes() {
    this.xAxis = d3
      .axisBottom()
      .scale(this.x)
      .tickFormat(formatDateForReport(this.timePeriod));

    this.yAxis = d3.axisLeft().scale(this.y).ticks(10);
  }

  scaleRanges(data) {
    // Scale the range of the data in the domains
    this.x.domain(data.map((d) => d.date));
    this.y.domain([0, d3.max(data, (d) => d.donations)]);
  }

  create(data) {
    this.setRanges();
    this.setAxes();

    // append the svg object to the body of the page
    // append a 'group' element to 'svg'
    // moves the 'group' element to the top left margin
    const svg = d3
      .select(`#${this.id}`)
      .append('svg')
      .attr('width', this.width + this.margin.left)
      .attr('height', this.height + this.margin.bottom);

    svg
      .append('g')
      .attr('id', `${this.id}-bar-container`)
      .attr('fill', 'steelblue')
      .attr('transform', `translate(${this.margin.left},${this.margin.top})`);

    this.scaleRanges(data);

    const t = svg.transition().duration(this.transitionDuration);

    this.updateBars(data, t);

    this.addAxes();
  }

  addAxes() {
    const container = d3.selectAll(`#${this.id}-bar-container`);

    // add the x Axis
    container
      .append('g')
      .attr('class', 'x-axis')
      .attr('transform', `translate(0,${this.height - this.margin.top})`)
      .call(this.xAxis.ticks(null).tickSize(0));

    // add the y Axis
    container
      .append('g')
      .attr('class', 'y-axis')
      .attr('transform', `translate(0,-${this.margin.top})`)
      .call(this.yAxis);
  }

  updateAxes() {
    d3.selectAll('g.x-axis').call(this.xAxis.ticks(null).tickSize(0));

    d3.selectAll('g.y-axis').call(this.yAxis);
  }

  calculateBarYOffScreenPosition() {
    return this.height - this.margin.top;
  }

  calculateBarYPosition(d) {
    return this.y(d.donations);
  }

  calculateBarXPosition(d) {
    return this.x(d.date);
  }

  calculateBarHeight(d) {
    return this.height - this.y(d.donations) - this.margin.top;
  }

  updateBars(data, t) {
    d3.selectAll(`#${this.id}-bar-container`)
      .selectAll('rect.bar')
      .data(data, (d) => d.date)
      .join(
        (enter) =>
          enter
            .append('rect')
            .attr('class', 'bar')
            .attr('x', (d) => this.calculateBarXPosition(d))
            .attr('y', () => this.calculateBarYOffScreenPosition())
            .attr('width', this.x.bandwidth())
            .attr('height', 0)
            .style('opacity', 0)
            .call((enter) =>
              enter
                .transition(t)
                .style('opacity', 1.0)
                .attr('height', (d) => this.calculateBarHeight(d))
                .attr('y', (d) => this.calculateBarYPosition(d))
            ),
        (update) =>
          update
            .attr('y', (d, i, group) => group[i].getAttribute('y'))
            .attr('x', (d, i, group) => group[i].getAttribute('x'))
            .attr('width', (d, i, group) => group[i].getAttribute('width'))
            .attr('height', (d, i, group) => group[i].getAttribute('height'))
            .call((update) =>
              update
                .transition(t)
                .attr('width', () => this.x.bandwidth())
                .attr('height', (d) => this.calculateBarHeight(d))
                .attr('x', (d) => this.calculateBarXPosition(d))
                .attr('y', (d) => this.calculateBarYPosition(d))
            ),
        (exit) =>
          exit.call((exit) =>
            exit
              .transition(t)
              .attr('y', () => this.calculateBarYOffScreenPosition())
              .attr('height', 0)
              .style('opacity', 0)
              .remove()
          )
      )
      .attr('class', 'bar')
      .attr('style', 'stroke-width: 1; stroke: rgb(0,0,0)');
  }

  update(data) {
    const svg = d3.select(`#${this.id} svg`);
    const t = svg.transition().duration(this.transitionDuration);

    this.setRanges();
    this.setAxes();
    this.scaleRanges(data);
    this.updateAxes();
    this.updateBars(data, t);
  }
}
