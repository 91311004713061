import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  checkedIcon,
  expiredIcon,
  hourGlassIcon,
  uncertaintyIcon,
} from 'assets/images';
import { INVITE_STATUSES } from 'lib/constants';

const ICON_STATUS_MAPPER = {
  [INVITE_STATUSES.PENDING]: hourGlassIcon,
  [INVITE_STATUSES.SENT]: hourGlassIcon,
  [INVITE_STATUSES.REJECTED]: uncertaintyIcon,
  [INVITE_STATUSES.JOINED]: checkedIcon,
  [INVITE_STATUSES.CANCELLED]: expiredIcon,
};

const StyledInviteStatusCell = styled.img`
  width: 16px;
`;

const TeamMemberInviteStatusCell = ({ value: status }) => (
  <StyledInviteStatusCell
    alt={`${status} icon`}
    data-testid={`${status}-icon`}
    src={ICON_STATUS_MAPPER[status]}
  />
);

TeamMemberInviteStatusCell.propTypes = {
  value: PropTypes.string,
};

TeamMemberInviteStatusCell.defaultProps = {
  value: '',
};

export { TeamMemberInviteStatusCell };
