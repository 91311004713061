import userActionTypes from 'rdx/actionTypes';

const initialState = {
  isFetching: false,
  isResetSubmitRequestSuccessful: false,
  error: null,
};

export function resetPasswordSubmit(state = initialState, action) {
  switch (action.type) {
    case userActionTypes.RESET_PASSWORD_SUBMIT_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case userActionTypes.RESET_PASSWORD_SUBMIT_SUCCESS:
      return {
        ...state,
        isFetching: false,
        isResetSubmitRequestSuccessful: true,
      };
    case userActionTypes.RESET_PASSWORD_SUBMIT_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.error,
      };
    case userActionTypes.RESET_PASSWORD_SUBMIT_RESET:
      return {
        ...state,
        isFetching: false,
        isResetSubmitRequestSuccessful: false,
        error: null,
      };
    default:
      return state;
  }
}
