const DONOR_LIST = 'donor-list';
const DONATION_SUMMARY = 'donation-summary';
const DONATIONS_OVER_TIME = 'donations-over-time';
const CURRENT_STATE_DASHBOARD = 'current-state-dashboard';
const NEW_DONOR_GROWTH = 'new-donor-growth';
const CAMPAIGN_OVERVIEW = 'campaign-overview';

const ORDERED_REPORT_MODULE_TYPES = {
  [CURRENT_STATE_DASHBOARD]: [
    DONATION_SUMMARY,
    // TODO: add feature flag to properly show / hide donations over time
    // report
    // DONATIONS_OVER_TIME,
    DONOR_LIST,
  ],
};

// The keys used for storing the report data in redux and used for rendering in
// the <ReportModules> component.
const REPORT_DATA_KEYS = Object.freeze({
  DONATION_SUMMARY: 'donationSummary',
  DONATIONS_OVER_TIME: 'donationsOverTime',
  DONOR_LIST: 'donorList',
});

export {
  CAMPAIGN_OVERVIEW,
  CURRENT_STATE_DASHBOARD,
  DONOR_LIST,
  DONATION_SUMMARY,
  DONATIONS_OVER_TIME,
  NEW_DONOR_GROWTH,
  ORDERED_REPORT_MODULE_TYPES,
  REPORT_DATA_KEYS,
};
