import React from 'react';
import { shape, oneOf } from 'prop-types';
import { values } from 'rambdax';
import { makeStyles } from '@material-ui/core';
import { isDonationTimeType } from 'lib/utils';
import { clocksIcon } from 'assets/images';
import { translate } from 'lib/intl';
import { PAYMENT_TYPE_ENUMS } from 'lib/constants/common';
import {
  CampaignDonationType,
  DONATION_PAYMENT_METHODS,
} from 'lib/configs/donations';
import { TABLE_SORT_ICON_SIZE } from 'lib/constants';
import { DonationIcon } from './DonationIcon';

const TIME_METHOD_CELL_CONFIG = {
  icon: clocksIcon,
  iconAlt: translate('CLOCKS_ICON_ALT'),
  value: 'time',
};

const useStyles = makeStyles((theme) => ({
  img: {
    position: 'relative',
    /*
      makes sure an icon is centered
      relative to the heaader text + sort icon
      in case DonationMethod is a part of CustomTable
    */
    right: `${parseInt(TABLE_SORT_ICON_SIZE, 10) / 2}px`,
    [theme.breakpoints.down('sm')]: {
      right: 0,
      top: theme.spacing(0.5),
    },
  },
}));

const DonationMethod = ({ data, value }) => {
  const styles = useStyles();
  const isTimeType = isDonationTimeType(data);

  if (!(isTimeType || value)) {
    return null;
  }

  const {
    icon,
    iconAlt,
    value: key,
  } = isTimeType
    ? TIME_METHOD_CELL_CONFIG
    : DONATION_PAYMENT_METHODS.find((method) => method.value === value);

  return (
    <DonationIcon
      className={`mx-auto ${styles.img}`}
      data-testid={`${key}-donation-method`}
      alt={iconAlt}
      src={icon}
    />
  );
};

DonationMethod.propTypes = {
  data: shape(CampaignDonationType).isRequired,
  value: oneOf([...values(PAYMENT_TYPE_ENUMS), '']),
};

DonationMethod.defaultProps = {
  value: '',
};

export { DonationMethod };
