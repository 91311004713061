import { createAction } from 'lib/utils';
import userActionTypes from 'rdx/actionTypes';
import { feedService } from 'services/feed';

const {
  FETCH_CAMPAIGN_FEED_REQUEST,
  FETCH_CAMPAIGN_FEED_SUCCESS,
  FETCH_CAMPAIGN_FEED_REFRESH_SUCCESS,
  FETCH_CAMPAIGN_FEED_FAILURE,
} = userActionTypes;

const getCampaignFeed =
  (ownerOrgId, campaignId, pageFrom = 0) =>
  async (dispatch) => {
    try {
      dispatch(
        createAction({
          type: FETCH_CAMPAIGN_FEED_REQUEST,
          data: { campaignId },
        })
      );

      const response = await feedService.fetchFeed({
        ownerOrgId,
        campaignId,
        pageFrom,
      });

      dispatch(
        createAction({
          type: FETCH_CAMPAIGN_FEED_SUCCESS,
          data: {
            campaignId,
            ...response.data,
          },
        })
      );
    } catch (error) {
      dispatch(createAction({ type: FETCH_CAMPAIGN_FEED_FAILURE, error }));
    }
  };

const refreshCampaignFeed = (ownerOrgId, campaignId) => async (dispatch) => {
  try {
    dispatch(
      createAction({ type: FETCH_CAMPAIGN_FEED_REQUEST, data: { campaignId } })
    );

    const response = await feedService.fetchFeed({
      ownerOrgId,
      campaignId,
      pageFrom: 0,
    });

    dispatch(
      createAction({
        type: FETCH_CAMPAIGN_FEED_REFRESH_SUCCESS,
        data: {
          campaignId,
          ...response.data,
        },
      })
    );
  } catch (error) {
    dispatch(createAction({ type: FETCH_CAMPAIGN_FEED_FAILURE, error }));
  }
};

export const campaignFeedActions = {
  getCampaignFeed,
  refreshCampaignFeed,
};

export default campaignFeedActions;
