import React from 'react';

import { func, bool } from 'prop-types';
import { EMPTY_FUNC, TEXT_SELECT_FIELD_EMPTY_VALUE } from 'lib/constants';
import { TextSelectField } from 'components';
import { translate } from 'lib/intl';
import { TextSelectFieldValueType } from 'lib/utils';
import { DONATION_PAYMENT_METHODS } from 'lib/configs/donations';

const DonationPaymentMethodInput = ({
  paymentMethod,
  setPaymentMethod,
  error,
}) => (
  <TextSelectField
    id="text-select-field-payment"
    items={DONATION_PAYMENT_METHODS}
    value={paymentMethod}
    setValue={setPaymentMethod}
    label={translate('DONATION_PAYMENT_LABEL')}
    error={error}
    errorHelperText={translate('DONATION_PAYMENT_ERROR_REQUIRED')}
  />
);

DonationPaymentMethodInput.propTypes = {
  paymentMethod: TextSelectFieldValueType,
  setPaymentMethod: func,
  error: bool,
};

DonationPaymentMethodInput.defaultProps = {
  paymentMethod: TEXT_SELECT_FIELD_EMPTY_VALUE,
  setPaymentMethod: EMPTY_FUNC,
  error: false,
};

export { DonationPaymentMethodInput };
