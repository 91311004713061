/* eslint-disable no-param-reassign */
import { omit, zipObj } from 'rambdax';

const constituentKeys = [
  'constituentId',
  'constituentType',
  'isMajorDonorRuleset',
  'categoryVolunteer',
  'categoryBoardMember',
  'categoryMember',
  'isRecurringDonorRuleset',
  'kanbanPriority',
  'kanbanCategory',
];
const donorKeys = [
  'donorId',
  'donorTypeMajor',
  'donorTypeVolunteer',
  'donorTypeBoardMember',
  'donorTypeMember',
  'donorTypeRecurring',
  'priority',
  'category',
];
const donorToConstituentKeys = zipObj(donorKeys, constituentKeys);
const constituentToDonorKeys = zipObj(constituentKeys, donorKeys);

const constituentDonationKeys = ['constituentId', 'constituentDisplayName'];
const donorDonationKeys = ['donorId', 'donorDisplayName'];

const donorToConstituentDonationKeys = zipObj(
  donorDonationKeys,
  constituentDonationKeys
);

function convertDonorType(donorType) {
  return donorType === 'odu' ? 'prs' : 'org';
}

function convertConstituentType(constituentType) {
  return constituentType === 'prs' ? 'odu' : 'odo';
}

function transformKeys(data, keys, keepFalsy) {
  return Object.keys(data).reduce((memo, key) => {
    const value = data[key];

    if (keepFalsy || (typeof value !== 'undefined' && value !== null)) {
      const newKey = keys[key];

      memo[newKey || key] = value;
    }

    return memo;
  }, {});
}

export function transformConstituentDonation(donation) {
  return {
    ...omit(donorDonationKeys, donation),
    ...transformKeys(donation, donorToConstituentDonationKeys),
  };
}

export function transformConstituentToDonor(constituent) {
  const donor = transformKeys(constituent, constituentToDonorKeys, true);

  if (constituent.constituentType) {
    donor.donorType = convertConstituentType(constituent.constituentType);
  }

  return omit(constituentKeys, donor);
}

export function transformDonorToConstituent(donor) {
  const constituent = transformKeys(donor, donorToConstituentKeys, true);

  if (donor.donorType) {
    constituent.constituentType = convertDonorType(donor.donorType);
  }

  return omit(donorKeys, constituent);
}

export function transformConstituentInteraction(interaction) {
  return {
    ...omit(donorDonationKeys, interaction),
    ...transformKeys(interaction, donorToConstituentDonationKeys),
  };
}

export default {
  transformDonorToConstituent,
  transformConstituentDonation,
  transformConstituentToDonor,
};
