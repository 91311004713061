import React from 'react';
import PropTypes from 'prop-types';
import Link from '@material-ui/core/Link';
import withStyles from '@material-ui/styles/withStyles';

const styles = {
  link: {
    fontWeight: 500,
  },
};

const TeamMemberLinkCell = withStyles(styles)(({ classes, value }) => (
  <Link
    {...(classes?.link && { className: classes.link })}
    href={`mailto:${value}`}
    onClick={(e) => e.stopPropagation()}
    variant="body1"
  >
    {value}
  </Link>
));

TeamMemberLinkCell.propTypes = {
  value: PropTypes.string,
  classes: PropTypes.object,
};

TeamMemberLinkCell.defaultProps = {
  classes: {},
  value: '',
};

export { TeamMemberLinkCell };
