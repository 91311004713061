import { flatten, path as getByPath } from 'rambdax';
import { lowerCase } from 'voca';
import { formatDate, formatCurrency } from 'lib/utils';
/**
  Main mapper for extracting donor data
  @param {bool} $0.addUrl - a way to add a url in a result value
  @param {Object} $0.data - donor data
  @param {func} $0.formatValue - formats a value that will be shown if necessary
  @param {func} $0.splitValue - splits an extracted value on several values if necessary
  @param {Array.<string>} $0.labels - filters array like extracted values based on labels
  @param {string} $0.path - used to extract an initial value from data
  @param {string} $0.urlPrefix - used to add a prefix before a url
  @param {string} $0.fieldToExtract - field name that should be extarcted from extracted value
  @param {bool} $0.usePrimary - a way to get a primary value
  @returns {Node} - rendered list of nav items
*/
const mapDonorData = ({
  addUrl = false,
  data,
  formatValue,
  splitValue,
  labels,
  path,
  urlPrefix = '',
  fieldToExtract = '',
  usePrimary = false,
}) => {
  let donorData = getByPath(path, data);

  if (donorData?.length && labels?.length) {
    donorData = donorData.filter((val) =>
      labels.includes(lowerCase(val?.label))
    );
  }

  if (donorData?.length && usePrimary) {
    donorData = donorData.find((val) => val?.primary) || donorData[0];
  }

  donorData = donorData ? flatten([donorData]) : [];

  if (donorData?.length) {
    return donorData?.reduce((acc, val) => {
      const values = flatten(splitValue ? splitValue(val) : [val])?.map(
        (value) => {
          const extractedValue = fieldToExtract ? value[fieldToExtract] : value;
          const formattedValue = formatValue
            ? formatValue(extractedValue)
            : extractedValue;
          const label = value?.label;

          const valueToAdd = {
            value: formattedValue,
          };

          if (addUrl) {
            valueToAdd.url = `${urlPrefix}${extractedValue}`;
          }

          if (label) {
            valueToAdd.label = label;
          }

          return valueToAdd;
        }
      );

      acc.push(...values);

      return acc;
    }, []);
  }

  return null;
};

// a helper function that splits an address for View Fields
const splitAddressValue = (value) => {
  const {
    city = '',
    country = '',
    line1 = '',
    postalCode = '',
    state = '',
    unit = '',
  } = value || {};

  const address = [];

  // eslint-disable-next-line
  [line1, unit]?.forEach((value) => {
    if (value) {
      address.push(value);
    }
  });

  if (city) {
    address.push(
      `${city}${postalCode || state ? `, ${state} ${postalCode}` : ''}`
    );
  }

  if (country) {
    address.push(country);
  }

  return address;
};

const mapAddresses = (props) =>
  mapDonorData({
    splitValue: splitAddressValue,
    ...props,
  });

const mapEmails = (props) =>
  mapDonorData({
    urlPrefix: 'mailto:',
    addUrl: true,
    ...props,
  });

const mapPhoneNumbers = (props) =>
  mapDonorData({
    urlPrefix: 'tel:',
    addUrl: true,
    ...props,
  });

const mapWebsite = (props) =>
  mapDonorData({
    addUrl: true,
    ...props,
  });

const mapLastDonationDate = (lastDonationDate) =>
  lastDonationDate ? formatDate(lastDonationDate) : '';
const mapCapacity = (fromN, toN) =>
  fromN || toN ? `${formatCurrency(fromN)} - ${formatCurrency(toN)}` : '';

export {
  // generic
  mapDonorData,
  // type spesific
  mapAddresses,
  mapEmails,
  mapPhoneNumbers,
  mapWebsite,
  // for Donor Summary
  mapLastDonationDate,
  mapCapacity,
};
