import { RECENTLY_VIEWED_DONORS } from 'lib/constants/localStorageKeys';

// Retrieve recently viewed donors data from local storage
const getRecentlyViewedDonors = () => {
  let recentlyViewedDonors = [];

  try {
    recentlyViewedDonors =
      JSON.parse(localStorage.getItem(RECENTLY_VIEWED_DONORS)) || [];
  } catch (e) {
    if (process.env.NODE_ENV !== 'test') {
      // eslint-disable-next-line no-console
      console.warn('Failed to parse recently viewed donors data');
    }
  }

  return recentlyViewedDonors;
};

// Given a donor object, store in local storage at the start of the recently
// viewed donors local storage array
const recordDonorView = (donor) => {
  const { donorId } = donor;

  const formattedDonor = { ...donor };
  formattedDonor.lastViewed = new Date().toISOString();

  // Add the provided donor information to the cookie
  const currentRecentDonors = getRecentlyViewedDonors();

  // Filter out the donor being recorded from the already recorded donors
  const recentlyViewedDonors = currentRecentDonors.filter(
    (storedDonor) => storedDonor.donorId !== donorId
  );

  // Add the current donor to the front of the donors array
  recentlyViewedDonors.unshift(formattedDonor);

  // Set the updated array to local storage
  try {
    localStorage.setItem(
      RECENTLY_VIEWED_DONORS,
      JSON.stringify(recentlyViewedDonors)
    );
  } catch (e) {
    // eslint-disable-next-line no-console
    console.warn('Failed to set recently viewed donor');
  }

  return recentlyViewedDonors;
};

export const recentlyViewedDonorsService = {
  recordDonorView,
  getRecentlyViewedDonors,
};
