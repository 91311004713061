import { createApiRequest } from 'lib/utils/services';
import { joinNonEmptyParams } from 'lib/utils/joinNonEmptyParams';
import { DEFAULT_PAGE_SIZE } from 'lib/constants/common';

const fetch = ({ ownerOrgId, ...params }) =>
  createApiRequest({
    method: 'get',
    path: `orgs/${ownerOrgId}/constituents/search?${joinNonEmptyParams({
      pageSize: DEFAULT_PAGE_SIZE,
      ...params,
    })}`,
    throwErrors: true,
  });

const fetchEntity = (entityType, entityId) =>
  createApiRequest({
    method: 'get',
    path: `${entityType}/${entityId}`,
    throwErrors: true,
  });

const deleteEntity = (entityType, entityId) =>
  createApiRequest({
    method: 'delete',
    path: `${entityType}/${entityId}`,
    throwErrors: true,
  });

export default {
  fetch,
  fetchEntity,
  deleteEntity,
};
