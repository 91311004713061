export const ERRORS = {
  ERRORS_INVALID_CONFIRM_PASSWORD: 'Passwords are not matching',
  ERRORS_INVALID_PASSWORD: 'Password should contain at least 8 characters',
  ERRORS_INVALID_START_DATE_BEFORE_END_DATE:
    'Start date must come before end date',
  ERRORS_INVALID_MIN_DONATION_LT_MAX_DONATION:
    'Min donation must not be greater than max donation',
  API_ERROR_500: 'We messed up on our end, try reloading the page.',
  API_ERROR_400: 'Something strange is happening, try reloading the page.',
  API_ERROR_401: "Sorry, looks like you're not allowed to do that.",
  API_ERROR_504: 'Sorry, something went wrong on our end.',
  API_ERROR_404: "Oops, we can't find what you have requested",
  API_DEFAULT_ERROR: 'Something went wrong, try reloading the page.',
  API_DEFAULT_ERROR_2: 'Something went wrong, try again.',
  GENERAL_EXCLAMATORY: 'Oops!',
  REGISTER_VERIFICATION_ERROR: 'Something happened with your registration.',
  REGISTER_VERIFICATION_ERROR_CONTACT:
    'Please contact support to resolve the issue.',
  REGISTER_INVITE_VERIFICATION_ERROR: 'Something happened with your invite.',
  REGISTER_INVITE_VERIFICATION_ERROR_CONTACT:
    'Please contact your organization administrator to request a new invite.',
  DEFAULT_ERROR: 'An error occured',
  TRY_AGAIN: 'Try again',
  OOPS_EXCLAMATION: 'Oops!',
  NOTHING_HERE: "Looks like there's nothing here.",
  SEARCHING_FOR_HELP: 'Searching for help? Please',
  FORM_VALIDATION_ERROR: 'Fix fields before saving',
};
