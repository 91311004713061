import React, { useMemo } from 'react';
import { shape, string } from 'prop-types';
import { Box, Link } from '@material-ui/core';
import styled from 'styled-components';
import { formatUrl, isMobileView } from 'lib/utils';
import { SOCIAL_ACCOUNTS } from 'lib/constants';
import {
  FacebookIcon,
  InstagramIcon,
  LinkedInIcon,
  TwitterIcon,
} from 'lib/configs/donor-icons';

const SOCIAL_ACCOUNTS_ICONS = {
  facebook: FacebookIcon,
  instagram: InstagramIcon,
  linkedin: LinkedInIcon,
  twitter: TwitterIcon,
};

const StyledContainer = styled(Box)`
  img {
    height: ${({ mobile }) => (mobile ? 36 : 24)}px;
    width: ${({ mobile }) => (mobile ? 36 : 24)}px;
  }
`;

const SocialAccounts = ({ boxProps, ...data }) => {
  const isMobile = isMobileView();

  const Accounts = useMemo(
    () =>
      SOCIAL_ACCOUNTS?.map((account) => {
        // formatUrl makes sure that a link always contains http(s)
        const link = formatUrl(data[account]);

        if (link) {
          return (
            <Link
              data-testid={`social-account-${account}`}
              href={link}
              key={account}
              rel="noopener noreferrer"
              target="_blank"
            >
              {SOCIAL_ACCOUNTS_ICONS[account]}
            </Link>
          );
        }

        return null;
      }),
    [data]
  );

  return (
    <StyledContainer
      display="flex"
      flexDirection={isMobile ? 'row' : 'column'}
      height="100%"
      justifyContent={isMobile ? 'space-evenly' : 'flex-start'}
      mobile={isMobile ? 1 : 0}
      {...boxProps}
    >
      {Accounts}
    </StyledContainer>
  );
};

SocialAccounts.propTypes = {
  facebook: string,
  instagram: string,
  linkedin: string,
  twitter: string,
  boxProps: shape({
    alignItems: string,
    flexDirection: string,
    justifyContent: string,
    width: string,
  }),
};

SocialAccounts.defaultProps = {
  facebook: '',
  instagram: '',
  linkedin: '',
  twitter: '',
  boxProps: {},
};

export { SocialAccounts };
