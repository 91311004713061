import userActionTypes from 'rdx/actionTypes';

const initialState = {
  isFetching: false,
  error: null,
  entity: null,
};

const {
  DELETE_FEED_ENTITY_REQUEST,
  DELETE_FEED_ENTITY_SUCCESS,
  DELETE_FEED_ENTITY_FAILURE,
} = userActionTypes;

export function deleteFeedEntityReducer(state = initialState, action) {
  switch (action.type) {
    case DELETE_FEED_ENTITY_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: null,
        entity: null,
      };
    case DELETE_FEED_ENTITY_SUCCESS:
      return {
        ...state,
        isFetching: false,
        entity: action?.data,
      };
    case DELETE_FEED_ENTITY_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action?.error,
      };
    default:
      return state;
  }
}
