import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import CustomDialog from 'components/common/CustomDialog';
import { translate } from 'lib/intl';
import { FEATURE_FLAG_RESPONSE_TYPES } from 'lib/configs/superAdmin';
import { featureFlagActions } from 'rdx/actions/featureFlags';

export default function DeleteFeatureFlagModal({
  open,
  flagName,
  handleClose,
  setOpen,
  onApiResponse,
}) {
  const dispatch = useDispatch();
  const handleDelete = async () => {
    try {
      const response = await dispatch(
        featureFlagActions.removeFeatureFlag(flagName)
      );
      onApiResponse(response, FEATURE_FLAG_RESPONSE_TYPES.DELETE);
    } catch (error) {
      onApiResponse(error, FEATURE_FLAG_RESPONSE_TYPES.DELETE);
    } finally {
      handleClose();
    }
  };
  return (
    <CustomDialog
      actionButtonLabel={translate('DELETE')}
      open={open}
      onClose={handleClose}
      handleActionButton={handleDelete}
      dialogHeader={translate('FEATURE_FLAGS_DELETE')}
      setOpen={setOpen}
    >
      <Box display="flex" flexWrap="wrap" flexDirection="row">
        <Box>
          <Typography variant="body1">
            {translate('FEATURE_FLAGS_DELETE_TEXT_1')}
          </Typography>
        </Box>
        <Box>&nbsp;</Box>
        <Box>
          <Typography variant="body2">{flagName}</Typography>
        </Box>
        <Box>
          <Typography variant="body1">
            {translate('FEATURE_FLAGS_DELETE_TEXT_2')}
          </Typography>
        </Box>
      </Box>
    </CustomDialog>
  );
}

DeleteFeatureFlagModal.propTypes = {
  open: PropTypes.bool.isRequired,
  flagName: PropTypes.string.isRequired,
  handleClose: PropTypes.func.isRequired,
  onApiResponse: PropTypes.func.isRequired,
  setOpen: PropTypes.func.isRequired,
};
