import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import Button from '@material-ui/core/Button';
import { useForm } from 'react-hook-form';
import { format } from 'date-fns';
import { useFeature } from 'flagged';
import { FEATURE_FLAG_ADVANCED_REPORTS } from 'lib/constants/featureFlags';
import { ORDERED_REPORT_MODULE_TYPES } from 'lib/constants/reports';
import { fetchReport } from 'rdx/actions/reportActions';
import { ControlledTextSelectField } from 'components/common/form';
import {
  DONATION_TYPE_OPTIONS,
  DONOR_ATTRIBUTE_OPTIONS,
  DONOR_TYPE_OPTIONS,
  REPORT_TYPE_OPTIONS,
} from 'lib/configs/selectOptionsConfig';
import { API_DATE_FORMAT } from 'lib/constants/common';
import { translate } from 'lib/intl';
import ReportFilterPropType from 'lib/types/ReportFilterPropType';
import { useFetchReportPageData } from 'pages/ReportsPage/useFetchReportPageData';
import ReportDateFilter from './ReportDateFilter';
import ReportDonationFilter from './ReportDonationFilter';

export default function ReportsFilterForm({
  filters,
  onFilterUpdate,
  onResetFilter,
}) {
  const dispatch = useDispatch();
  const hasAdvancedFiltering = useFeature(FEATURE_FLAG_ADVANCED_REPORTS);
  const { defaultValues, teamMembers } = useFetchReportPageData();
  const {
    control,
    errors,
    getValues,
    handleSubmit,
    register,
    reset,
    setValue,
    trigger,
    watch,
  } = useForm({
    mode: 'onChange',
    defaultValues,
  });
  const values = getValues();

  React.useEffect(() => {
    Object.keys(filters).forEach((filter) => {
      if (filter === 'reportType') {
        const idx = REPORT_TYPE_OPTIONS.findIndex(
          (option) => option.value === filters[filter]
        );
        setValue(filter, REPORT_TYPE_OPTIONS[idx]);
      } else {
        setValue(filter, filters[filter]);
      }
    });
  }, []);

  const handleFormSubmit = (formData) => {
    const data = {
      dateType: formData.dateType,
      reportType: formData.reportType.value,
      startDate: format(new Date(formData.startDate), API_DATE_FORMAT),
      endDate: format(new Date(formData.endDate), API_DATE_FORMAT),
    };
    onFilterUpdate(data);
    ORDERED_REPORT_MODULE_TYPES[formData.reportType.value].forEach((module) => {
      dispatch(fetchReport({ ...data, moduleType: module }));
    });
  };

  const handleResetClick = () => {
    onResetFilter();
    reset(defaultValues);
  };

  return (
    <form onSubmit={handleSubmit(handleFormSubmit)}>
      <ControlledTextSelectField
        control={control}
        name="reportType"
        disableClearable
        id="reports-filter-report-type"
        items={
          hasAdvancedFiltering ? REPORT_TYPE_OPTIONS : [REPORT_TYPE_OPTIONS[0]]
        }
        label={translate('REPORTS_FILTER_REPORT_TYPE')}
        value={values.reportType}
      />

      <ReportDateFilter
        {...{
          control,
          errors,
          setValue,
          trigger,
          register,
          watch,
        }}
      />
      {hasAdvancedFiltering ? (
        <>
          <ControlledTextSelectField
            control={control}
            formControlProps={{
              margin: 'dense',
            }}
            multiple
            name="assignedEmployee"
            id="reports-filter-assigned-employee"
            items={teamMembers}
            label={translate('REPORTS_FILTER_ASSIGNED_EMPLOYEE')}
            value={values.assignedEmployee}
          />

          <ReportDonationFilter
            {...{
              control,
              errors,
              trigger,
              register,
              watch,
            }}
          />

          <ControlledTextSelectField
            control={control}
            label={translate('FORMS_DONOR_TYPE_LABEL')}
            name="donorType"
            id="reports-filter-donor-type"
            items={DONOR_TYPE_OPTIONS}
            value={values.donorType}
          />

          <ControlledTextSelectField
            control={control}
            formControlProps={{
              margin: 'dense',
            }}
            defaultValue={values.donationType}
            id="reports-filter-donation-type"
            items={DONATION_TYPE_OPTIONS}
            name="donationType"
            label={translate('DONATION_TYPE_LABEL')}
          />

          <ControlledTextSelectField
            control={control}
            formControlProps={{
              margin: 'dense',
            }}
            defaultValue={values.donorAttribute}
            id="reports-filter-donor-attribute"
            items={DONOR_ATTRIBUTE_OPTIONS}
            label={translate('FORMS_DONOR_ATTRIBUTES_LABEL')}
            name="donorAttribute"
          />
        </>
      ) : null}

      <Button
        className="w-full mt-5"
        color="primary"
        disableElevation
        type="submit"
        variant="contained"
      >
        {translate('REPORTS_UPDATE_REPORT')}
      </Button>
      <Button
        className="text-error-main w-full"
        type="button"
        onClick={handleResetClick}
        disableElevation
        variant="text"
      >
        {translate('REPORTS_FILTER_RESET')}
      </Button>
    </form>
  );
}
ReportsFilterForm.propTypes = {
  filters: ReportFilterPropType.isRequired,
  onFilterUpdate: PropTypes.func.isRequired,
  onResetFilter: PropTypes.func.isRequired,
};
