import { createApiRequest } from 'lib/utils';

const createAnnouncement = (ownerOrgId, data) =>
  createApiRequest({
    data,
    method: 'post',
    path: `orgs/${ownerOrgId}/announcements`,
    throwErrors: true,
  });

const createDotOrgAnnouncement = (data) =>
  createApiRequest({
    data,
    method: 'post',
    path: 'announcements',
    throwErrors: true,
  });

export const announcementsService = {
  createAnnouncement,
  createDotOrgAnnouncement,
};
