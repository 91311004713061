import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    height: '100%',
    background: theme.palette.background.white,
    flex: 1,
    overflow: 'hidden',
  },
  navContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    flex: '0 0 72px',
    background: theme.palette.background.light,
    boxShadow: `inset -2px 0 3px -2px ${theme.palette.grey.light}`,
  },
  contentContainer: {
    display: 'flex',
    flexGrow: 1,
    overflow: 'auto',
  },
  avatar: {
    background: 'none',
  },
  employeeItem: {
    justifyContent: 'center',
  },
}));

export default useStyles;
