import userActionTypes from 'rdx/actionTypes';

const initialState = {
  isFetching: false,
  isUpdating: false,
  error: null,
  visitStreakText: null,
  visitStreakEncouragementText: null,
};

const {
  GET_VISIT_STREAK_REQUEST,
  GET_VISIT_STREAK_SUCCESS,
  GET_VISIT_STREAK_FAILURE,
  UPDATE_VISIT_STREAK_REQUEST,
  UPDATE_VISIT_STREAK_SUCCESS,
  UPDATE_VISIT_STREAK_FAILURE,
} = userActionTypes;

/* eslint-disable no-case-declarations */
export function visitStreak(state = initialState, action) {
  switch (action.type) {
    case GET_VISIT_STREAK_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case GET_VISIT_STREAK_SUCCESS:
      const weeklyStreakData = action?.data?.visits?.weekly;

      return {
        ...state,
        isFetching: false,
        visitStreakText: weeklyStreakData?.title,
        visitStreakEncouragementText: weeklyStreakData?.text,
      };
    case GET_VISIT_STREAK_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.error,
      };
    case UPDATE_VISIT_STREAK_REQUEST:
      return {
        ...state,
        isUpdating: true,
      };
    case UPDATE_VISIT_STREAK_SUCCESS:
      return {
        ...state,
        isUpdating: false,
      };
    case UPDATE_VISIT_STREAK_FAILURE:
      return {
        ...state,
        isUpdating: false,
      };
    default:
      return state;
  }
}
