export const SUPER_ADMIN = {
  SUPER_ADMIN_PAGE_TITLE: 'Admin Tools',
  SUPER_ADMIN_NAV_ITEM_ICON_ALT: 'Admin Page',
  SUPER_ADMIN_NAV_LABEL: 'Admin Tools',
  SUPER_ADMIN_PAGE_TABS_ORGS: 'Organizations',
  SUPER_ADMIN_PAGE_TABS_FEATURE_FLAGS: 'Feature Flags',
  RETURN_TO_DOTORG_LABEL: 'Return to Instil',
  CURRENTLY_VIEWING_LABEL: 'Currently Viewing',
};

export const ORG_MANAGEMENT = {
  ORG_MGMT_TABLE_HEADER_NAME: 'Name',
  ORG_MGMT_TABLE_HEADER_CREATED: 'Created',
  ORG_MGMT_TABLE_HEADER_ADMIN: 'Administrator',
  ORG_MGMT_SHOW_MORE: 'Show More',
  ORG_MGMT_SEARCH_LABEL: 'Find Organization',
  ORG_MGMT_CREATE_ORG_CTA: 'Create Organization',
  ORG_MGMT_CREATE_ORG_DIALOG_TITLE: 'Create Organization',
  ORG_MGMT_CREATE_ORG_DIALOG_NAME: 'Organization Name',
  ORG_MGMT_CREATE_ORG_DIALOG_EIN: 'Employee ID Number',
  ORG_MGMT_CREATE_ORG_DIALOG_PHONE: 'Org Phone Number',
  ORG_MGMT_CREATE_ORG_DIALOG_NO_EIN: 'No EIN?',
  ORG_MGMT_CREATE_ORG_DIALOG_USE_EIN: 'Use EIN',
  ORG_MGMT_CREATE_ORG_SUCCESS: 'Your new organization has been created',
  ORG_MGMT_CREATE_ORG_ERROR:
    'There was a problem creating your new organization. Please try again.',
  ORG_MGMT_FETCH_ORGS_ERROR:
    'There was a problem with fetching the organizations. This might be related to you not having sufficient permissions to access this info.',
  ORG_MGMT_CREATE_ORG_ERROR_ALREADY_EXISTS:
    'An org already exists with the EIN or phone number provided. Please try again.',
};

export const FEATURE_FLAGS = {
  FEATURE_FLAGS_DESCRIPTION:
    'Feature Flags are used to turn on and off development features.',
  FEATURE_FLAGS_DETAILS: 'Details available here.',
  FEATURE_FLAGS_CREATE: 'Create Feature Flag',
  FEATURE_FLAGS_DELETE: 'Delete Feature Flag',
  FEATURE_FLAGS_DISABLE: 'Disable Feature Flag',
  FEATURE_FLAGS_ENABLE: 'Approved Organizations',
  FEATURE_FLAGS_TABLE_HEADER_NAME: 'Name',
  FEATURE_FLAGS_TABLE_HEADER_STATUS: 'Status',
  FEATURE_FLAGS_CREATE_NAME: 'Feature Flag Name',
  FEATURE_FLAGS_CREATE_HELPER_TEXT:
    'Must consist of letters, numbers, and no spaces.',
  FEATURE_FLAGS_DELETE_TEXT_1: 'Are you sure you want to delete the ',
  FEATURE_FLAGS_DELETE_TEXT_2:
    'feature flag? Access will be removed for any approved organizations.',
  FEATURE_FLAGS_GENERIC_ERROR_MESSAGE:
    'There was a problem {msg}. Please try again.',
  FEATURE_FLAGS_NO_FLAGS: "Looks like you haven't added any feature flags yet.",
  FEATURE_FLAGS_ERR_MSG_GET: 'fetching feature flags',
  FEATURE_FLAGS_ERR_MSG_DEL: 'deleting your feature flag',
  FEATURE_FLAGS_ERR_MSG_UPD: 'updating your feature flag',
  FEATURE_FLAGS_DISABLE_TEXT_1: 'Are you sure you want to disable the ',
  FEATURE_FLAGS_DISABLE_TEXT_2:
    'feature flag? Once switched off, this feature will no longer be available to all approved organizations.',
  FEATURE_FLAGS_ENABLE_FLAG_LABEL: 'Flag',
  FEATURE_FLAGS_ENABLE_ALL: 'Enable for ALL Organizations',
  FEATURE_FLAGS_ENABLE_LIST: 'Enable for specific list of Organizations',
  FEATURE_FLAGS_VIEW_EDIT_LIST: 'View/Edit List',
  FEATURE_FLAGS_ALERT_SUCCESS_NEW: 'Your new feature flag has been created.',
  FEATURE_FLAGS_ALERT_SUCCESS_EDIT: 'Your feature flag has been updated.',
  FEATURE_FLAGS_ALERT_SUCCESS_DELETE: 'Your feature flag was deleted.',
  FEATURE_FLAGS_ALERT_ERROR_NEW:
    'There was a problem creating your new feature flag. Please try again.',
  FEATURE_FLAGS_ALERT_ERROR_EDIT:
    'There was a problem updating your feature flag. Please try again.',
  FEATURE_FLAGS_ALERT_ERROR_DELETE:
    'There was a problem deleting your feature flag. Please try again.',
  FEATURE_FLAGS_SEARCH_ORGS: 'Search Organizations',
  FEATURE_FLAGS_NO_ORGS:
    'No organziations have been given access to this feature yet.',
};
