import React from 'react';
import PropTypes from 'prop-types';
import Icons from 'lib/configs/ConstituentIcons';
import ConstituentPropType, {
  ConstituentType,
} from 'lib/types/ConstituentPropType';
import Tooltip from '@material-ui/core/Tooltip';
import classNames from 'classnames';
import { ConstituentCategories } from 'lib/types/ConstituentFilterPropType';
import { camelCase } from 'voca';

const ConstituentIconTiles = ({ className, constituent }) => {
  const icons = React.useMemo(
    () =>
      Icons.filter(({ value }) =>
        [
          ConstituentCategories.CATEGORY_CORPORATION,
          ConstituentCategories.CATEGORY_FOUNDATION,
        ].includes(value)
          ? constituent.constituentType === ConstituentType.ORG &&
            constituent[camelCase(value)]
          : constituent[camelCase(value)]
      ),
    [constituent]
  );

  return icons.length ? (
    <div className={classNames('flex flex-wrap', className)}>
      {icons.map(({ icon, value, label }) => (
        <Tooltip title={label} key={label} arrow>
          <div key={value} className="flex-shrink-0 mb-1 mr-1">
            {icon}
          </div>
        </Tooltip>
      ))}
    </div>
  ) : null;
};

ConstituentIconTiles.propTypes = {
  className: PropTypes.string,
  constituent: ConstituentPropType.isRequired,
};

ConstituentIconTiles.defaultProps = {
  className: '',
};

export default ConstituentIconTiles;
