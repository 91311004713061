import React from 'react';
import PropTypes from 'prop-types';
import { Auth } from 'aws-amplify';

import { Box, Typography } from '@material-ui/core';
import { CustomButton } from 'components';
import { googleIcon } from 'assets/images';
import { colors } from 'styles/theme';
import { translate } from 'lib/intl';

const AdminLoginForm = () => {
  const googleIconElement = (
    <img alt="Google icon" style={{ borderRadius: '50%' }} src={googleIcon} />
  );

  return (
    <Box display="flex" flexDirection="column">
      <Typography
        style={{
          paddingBottom: '10px',
          textTransform: 'none',
          letterSpacing: '.25px',
          textAlign: 'center',
        }}
        variant="button"
      >
        {translate('SIGN_IN_DOTORG_ACCOUNT')}
      </Typography>
      <CustomButton
        style={{
          marginBottom: '13px',
        }}
        prefix={googleIconElement}
        buttonType="primary"
        onClick={() => Auth.federatedSignIn({ provider: 'Google' })}
        label={translate('SIGN_IN_CONTINUE_GOOGLE')}
      />
      <Typography
        style={{
          color: colors.darkGrey,
          textAlign: 'center',
        }}
        variant="caption"
      >
        {translate('SIGN_IN_POST_PRIVACY')}
      </Typography>
    </Box>
  );
};

AdminLoginForm.propTypes = {
  error: PropTypes.shape({ code: {}, message: {} }),
};

AdminLoginForm.defaultProps = {
  error: null,
};

export { AdminLoginForm };
