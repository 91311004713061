import DONOR_BOARD from 'modules/DonorBoard/translations/en';
import { FEATURE_FLAGS, ORG_MANAGEMENT, SUPER_ADMIN } from './superAdmin';
import { AUTH } from './auth';
import { BUTTONS } from './buttons';
import { COMMON } from './common';
import { ERRORS } from './errors';
import { NAVIGATION } from './navigation';
import { DONOR } from './donor';
import { HOME } from './home';
import { DONORS } from './donors';
import { EMPLOYEE } from './employee';
import { COMPANY } from './company';
import { DONATIONS } from './donations';
import { FEED } from './feed';
import { INTERACTIONS } from './interactions';
import { GOALS } from './goals';
import { ANNOUNCEMENTS } from './announcements';
import { CAMPAIGNS } from './campaigns';
import FORMS from './forms';
import { ICONS } from './icons';
import { REPORTS } from './reports';
import { INTEGRATIONS } from './integrations';
import { CONSTITUENTS } from './constituents';
import { USER } from './user';

const en = {
  ...AUTH,
  ...BUTTONS,
  ...COMMON,
  ...COMPANY,
  ...CONSTITUENTS,
  ...FORMS,
  ...ERRORS,
  ...NAVIGATION,
  ...DONOR,
  ...HOME,
  ...DONORS,
  ...EMPLOYEE,
  ...DONATIONS,
  ...FEED,
  ...INTERACTIONS,
  ...GOALS,
  ...ANNOUNCEMENTS,
  ...CAMPAIGNS,
  ...ICONS,
  ...REPORTS,
  ...INTEGRATIONS,
  ...SUPER_ADMIN,
  ...ORG_MANAGEMENT,
  ...FEATURE_FLAGS,
  ...DONOR_BOARD,
  ...USER,
};

export { en };
