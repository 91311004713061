import React, { useCallback } from 'react';
import { prop } from 'rambdax';
import { string, func } from 'prop-types';
import InputAdornment from '@material-ui/core/InputAdornment';

import styled from 'styled-components';
import TextField from '@material-ui/core/TextField';
import { SearchIcon } from 'assets/images';
import { colors } from 'styles/theme';

const StyledSearchInput = styled(TextField)`
  width: 100%;
  
  .MuiOutlinedInput-root {
    background-color: ${prop('color')};
    height: 48px;
  }

  input[type="search"]::-webkit-search-cancel-button {
    display: none;
  }

  .MuiInputBase-input,
  .MuiInputBase-input::-webkit-input-placeholder {
    opacity: 1;
  }
  .MuiInputBase-input:focus::-webkit-input-placeholder {
    opacity: 0;
  }
}
`;

export const SearchInput = ({
  backgroundColor,
  className,
  id,
  onChange,
  placeholder,
  value,
  ...rest
}) => {
  const handleChange = useCallback(
    ({ target }) => {
      onChange(target?.value);
    },
    [onChange]
  );

  return (
    <StyledSearchInput
      color={backgroundColor}
      className={className}
      placeholder={placeholder}
      margin="none"
      name="search-input"
      onChange={handleChange}
      type="search"
      variant="outlined"
      size="small"
      value={value}
      InputProps={{
        'data-testid': id,
        startAdornment: (
          <InputAdornment>
            <SearchIcon className="text-grey-900" />
          </InputAdornment>
        ),
      }}
      {...rest}
    />
  );
};

SearchInput.propTypes = {
  backgroundColor: string,
  className: string,
  id: string,
  onChange: func.isRequired,
  placeholder: string.isRequired,
  value: string,
};

SearchInput.defaultProps = {
  backgroundColor: colors.transparentGreyCustom2,
  className: '',
  id: 'search-input',
  value: '',
};

export default SearchInput;
