import React from 'react';
import { func, oneOf } from 'prop-types';
import { values } from 'rambdax';
import { Typography } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { translate } from 'lib/intl';
import { CAMPAIGN_STATUS_ENUM, STOP_PROPAGATION } from 'lib/constants';
import { Gap, Visible } from 'components';
import { CAMPAIGN_ACTIONS_CONFIG } from 'lib/configs';
import { isMobileView } from 'lib/utils';
import {
  CampaignActionsContainer,
  CampaignActionsRow,
  CampaignActionsTitle,
  CampaignActionsIcon,
} from './CampaignActions.styles';

const CampaignActions = ({
  onActivateClick,
  onArchiveClick,
  onCompleteClick,
  onCloseClick,
  onDeleteClick,
  onDuplicateClick,
  onEditClick,
  status,
}) => {
  const isMobile = isMobileView();

  const actionHandlers = {
    onActivateClick,
    onArchiveClick,
    onCompleteClick,
    onDeleteClick,
    onDuplicateClick,
    onEditClick,
  };

  const Items = CAMPAIGN_ACTIONS_CONFIG.map(
    ({ handlerName, hideFor, icon, key, text }) => {
      const onClickHandler = () => {
        actionHandlers[handlerName]();
        onCloseClick();
      };

      return (
        <Visible key={key} when={!hideFor || !hideFor.includes(status)}>
          <CampaignActionsRow
            data-testid={`campaign-action-item-${key}`}
            onClick={onClickHandler}
            cursor="pointer"
          >
            <CampaignActionsIcon alt={`${icon}-alt`} src={icon} />
            <Gap size={1} />
            <Typography variant="body1">{text}</Typography>
          </CampaignActionsRow>
        </Visible>
      );
    }
  );

  return (
    <CampaignActionsContainer
      mobile={Number(isMobile)}
      onClick={STOP_PROPAGATION}
    >
      <CampaignActionsRow cursor="auto" justifyContent="space-between">
        <CampaignActionsTitle>
          {translate('CAMPAIGNS_CARD_ACTIONS_TITLE')}
        </CampaignActionsTitle>
        <Close
          cursor="pointer"
          data-testid="campaign-actions-close"
          onClick={onCloseClick}
        />
      </CampaignActionsRow>
      {Items}
    </CampaignActionsContainer>
  );
};

CampaignActions.propTypes = {
  onActivateClick: func.isRequired,
  onArchiveClick: func.isRequired,
  onCompleteClick: func.isRequired,
  onCloseClick: func.isRequired,
  onDeleteClick: func.isRequired,
  onDuplicateClick: func.isRequired,
  onEditClick: func.isRequired,
  status: oneOf(values(CAMPAIGN_STATUS_ENUM)).isRequired,
};

export { CampaignActions };
