import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import { Loader } from 'components/common';
import FeatureFlagListTable from 'components/featureFlagManagement/FeatureFlagListTable';
import CreateFeatureFlagDialog from 'components/featureFlagManagement/CreateFeatureFlagDialog';
import FeatureFlagAlert from 'components/featureFlagManagement/FeatureFlagAlert';
import NewThemeProvider from 'components/common/NewThemeProvider';
import { translate } from 'lib/intl';
import { isMobileView } from 'lib/utils';
import { featureFlagActions } from 'rdx/actions/featureFlags';

export default function FeatureFlagManagementPage() {
  const isMobile = isMobileView();
  const dispatch = useDispatch();
  const [openCreateFF, setOpenCreateFF] = React.useState(false);
  const [apiResponse, setApiResponse] = React.useState(null);
  const [alertType, setAlertType] = React.useState(null);
  const handleApiResponse = (response, type) => {
    setApiResponse(response);
    setAlertType(type);
  };

  const { featureFlags, isLoading } = useSelector((state) => ({
    featureFlags: state.featureFlags.data,
    isLoading: state.featureFlags.isLoading,
  }));

  React.useEffect(() => {
    dispatch(featureFlagActions.fetchFeatureFlags());
  }, []);

  const handleClose = () => {
    setOpenCreateFF(false);
  };
  const handleOpenCreateFF = () => {
    setOpenCreateFF(true);
  };
  const handleAlertClose = () => {
    setApiResponse(null);
  };

  return (
    <NewThemeProvider>
      <Box>
        <Box
          display="flex"
          flexDirection={isMobile ? 'column' : 'row'}
          justifyContent="space-between"
        >
          <Box display="flex" flexDirection="column">
            <Typography>{translate('FEATURE_FLAGS_DESCRIPTION')}</Typography>
            <Link
              variant="body1"
              href="https://dotorgtechnology.atlassian.net/wiki/x/IgB3Dw"
              target="_blank"
            >
              {translate('FEATURE_FLAGS_DETAILS')}
            </Link>
          </Box>
          <Box py={isMobile ? 2 : 0} display="flex" justifyContent="flex-end">
            <Button
              color="primary"
              variant="contained"
              onClick={handleOpenCreateFF}
            >
              <Typography variant="body1">
                {translate('FEATURE_FLAGS_CREATE')}
              </Typography>
            </Button>
          </Box>
        </Box>
        <Box pb={2}>
          {(!featureFlags || featureFlags.length === 0) && !isLoading ? (
            <Box pt={2}>
              <Typography variant="body2">
                {translate('FEATURE_FLAGS_NO_FLAGS')}
              </Typography>
            </Box>
          ) : (
            <>
              <FeatureFlagAlert
                response={apiResponse}
                type={alertType}
                onClose={handleAlertClose}
              />
              <FeatureFlagListTable
                items={featureFlags}
                onApiResponse={handleApiResponse}
              />
            </>
          )}
          {isLoading && <Loader isCentered pt={2} />}
        </Box>
        <CreateFeatureFlagDialog
          open={openCreateFF}
          handleClose={handleClose}
          setOpen={setOpenCreateFF}
          onApiResponse={handleApiResponse}
          isLoading={isLoading}
        />
      </Box>
    </NewThemeProvider>
  );
}
