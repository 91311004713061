import { DEFAULT_PAGE_SIZE } from 'lib/constants/common';
import { fetchTypes as types } from 'rdx/actions/constituentsSearchActions';

const initialState = {
  isFetching: false,
  error: null,
  dataLoaded: false,
  hasNextPage: null,
  pageFrom: 0,
  items: [],
};

/* eslint-disable no-case-declarations */
export default function constituentsSearchReducer(
  state = initialState,
  action
) {
  switch (action.type) {
    case types.CONSTITUENT_SEARCH_REQUEST:
      return {
        ...state,
        dataLoaded: false,
        isFetching: true,
      };
    case types.CONSTITUENT_SEARCH_SUCCESS: {
      const items = action?.data?.constituents || [];
      return {
        ...state,
        isFetching: false,
        dataLoaded: true,
        items,
        pageFrom: action.data.searchParams.pageFrom + DEFAULT_PAGE_SIZE,
        hasNextPage: action?.data.totalHits > items?.length,
      };
    }
    case types.CONSTITUENT_SEARCH_FAILURE:
      return {
        ...state,
        dataLoaded: false,
        isFetching: false,
        error: action.error,
      };
    case types.CONSTITUENT_SEARCH_REFRESH_SUCCESS:
      return {
        ...state,
        isFetching: false,
        dataLoaded: true,
        items: action?.data?.constituents || [],
      };
    default:
      return state;
  }
}
