import React from 'react';
import propTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import {
  MultipleInputs,
  SectionsGenerator,
  ViewFieldsColumn,
} from 'components';
import { DonorType, isMobileView } from 'lib/utils';
import { DONOR_SECTION_CONFIGS } from 'lib/configs';
import { ConstituentSectionTypes } from './constituentTypes';
import useConstituentForm from './useConstituentForm';
import ConstituentSectionWrapper from './ConstituentSectionWrapper';
import ConstituentCardWrapper from './ConstituentCardWrapper';
import AddMissingDataLink from './AddMissingDataLink';
import ConstituentFormPopup from './ConstituentFormPopup';

// TODO: remove this method when connections are removed by backend
const getExistingConnectios = (connectios) =>
  connectios?.reduce((acc, connection) => {
    if (!connection?.deleted) {
      acc.push(connection);
    }

    return acc;
  }, []);

/**
 * This is a component for rendering donor sections
 * such as Household, Affiliations, Employees and Connections
 */
const ConstituentConnectionSection = ({ data, isEditable, type }) => {
  const { actionLabel, config, key, title } = DONOR_SECTION_CONFIGS[type];

  const {
    isPopupOpen,
    idField,
    openForm,
    showForm,
    closeForm,
    onSave,
    onRemove,
    sectionGeneratorConfig,
    formData,
    formErrors,
    removingDonorInfo,
    setErrors,
    showErrors,
    setFormData,
    updatingConstituent,
  } = useConstituentForm({ type });

  const connections = getExistingConnectios(data[key]) || [];

  const isMobile = isMobileView();

  const Cards = React.useMemo(
    () =>
      connections?.map((connectionData, idx) => {
        const onEditClick = () =>
          openForm({
            type,
            isCreating: false,
            data: connectionData,
          });

        const id = connectionData[idField];

        return (
          <Box
            data-testid={`connection-card-${idx}`}
            key={id || idx}
            mobile={Number(isMobile)}
            width={isMobile ? '100%' : '50%'}
          >
            <ConstituentCardWrapper
              loading={removingDonorInfo[id]?.isFetching}
              onClick={onEditClick}
              onDeleteClick={() => onRemove(connectionData)}
              onEditClick={onEditClick}
            >
              <ViewFieldsColumn config={config} data={connectionData} />
            </ConstituentCardWrapper>
          </Box>
        );
      }),
    [connections, idField, isMobile, removingDonorInfo]
  );

  const {
    dataLoaded: isDataUpdated,
    error: formHasError,
    isFetching: isDataLoading,
  } = updatingConstituent;

  return (
    <ConstituentSectionWrapper
      actionLabel={actionLabel}
      boxProps={{ px: isMobile ? 0 : 2, pt: 2 }}
      id={key}
      isEditable={isEditable}
      onActionClick={() => openForm({ type })}
      title={title}
    >
      {connections?.length ? (
        <Box
          display="flex"
          flexDirection={isMobile ? 'column' : 'row'}
          flexWrap="wrap"
        >
          {Cards}
        </Box>
      ) : (
        isEditable && (
          <AddMissingDataLink
            label={actionLabel}
            onClick={() => openForm({ type })}
          />
        )
      )}
      <ConstituentFormPopup
        title={title}
        // makes sure to show loading until the form is closed
        isLoading={isDataLoading || isDataUpdated}
        isOpen={isPopupOpen}
        onClose={closeForm}
        onSave={onSave}
        apiError={Boolean(formHasError)}
        validationError={showErrors}
      >
        {showForm && (
          <SectionsGenerator
            MultipleInputsComponent={MultipleInputs}
            config={sectionGeneratorConfig}
            data={formData}
            errors={formErrors}
            setErrors={setErrors}
            showErrors={showErrors}
            updateData={setFormData}
          />
        )}
      </ConstituentFormPopup>
    </ConstituentSectionWrapper>
  );
};

ConstituentConnectionSection.propTypes = {
  data: DonorType,
  isEditable: propTypes.bool,
  type: ConstituentSectionTypes.isRequired,
};

ConstituentConnectionSection.defaultProps = {
  data: {},
  isEditable: false,
};

export default ConstituentConnectionSection;
