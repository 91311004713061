import { createApiRequest } from 'lib/utils';

const fetchFeatureFlags = () =>
  createApiRequest({
    method: 'get',
    path: 'feature-flags?scope=all',
    throwErrors: true,
  });

const createFeatureFlag = (flagName) =>
  createApiRequest({
    data: { flag_name: flagName },
    method: 'post',
    path: 'feature-flags',
    throwErrors: true,
  });

const updateFeatureFlag = ({ flagName, flagValue, orgIds, flagScope }) =>
  createApiRequest({
    data: {
      flag_name: flagName,
      flag_value: flagValue,
      org_ids: orgIds,
      flag_scope: flagScope,
    },
    method: 'put',
    path: `feature-flags/${flagName}`,
    throwErrors: true,
  });

const removeFeatureFlag = (flagName) =>
  createApiRequest({
    method: 'delete',
    path: `feature-flags/${flagName}`,
    throwErrors: true,
  });

export const featureFlagService = {
  fetchFeatureFlags,
  createFeatureFlag,
  updateFeatureFlag,
  removeFeatureFlag,
};
