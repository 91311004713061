import { createApiRequest } from 'lib/utils/services';
import { joinNonEmptyParams } from 'lib/utils/joinNonEmptyParams';
import { DEFAULT_PAGE_SIZE } from 'lib/constants/common';

function buildPath({ campaignId, constituentId, ownerOrgId, ...params }) {
  let path = `orgs/${ownerOrgId}/feed-items/search`;

  // We need to manually add the constituent ID outside of
  // `joinNonEmptyParams`, or else it will be url encoded, converting the "=="
  // in id to a value that corrects an invalid URL.
  if (constituentId) {
    path = `${path}?constituent_id=${constituentId}&`;
  } else if (campaignId) {
    path = `${path}?campaign_id=${campaignId}&`;
  } else {
    path = `${path}?`;
  }

  return `${path}${joinNonEmptyParams(params)}`;
}

/**
 * A universal function for fetching feeds.
 * @param {Object} params
 * @param {string} params.ownerOrgId - the only required field
 * @param {string} [params.campaignId]
 * @param {string} [params.donorId]
 * @param {'announcement'|'interaction'|'donation'} [params.contentType]
 * @param {string} [params.text]
 * @param {'updated'|'content_type'} [params.sortBy]
 * @param {'desc'|'asc'} [params.sortOrder]
 * @param {number} [params.pageSize]
 * @param {number} [params.pageFrom]
 */
const fetchFeed = ({ constituentId, ownerOrgId, ...params }) =>
  createApiRequest({
    method: 'get',
    path: buildPath({
      constituentId,
      ownerOrgId,
      pageSize: DEFAULT_PAGE_SIZE,
      ...params,
    }),
    throwErrors: true,
  });

const fetchFeedEntity = (entityType, entityId) =>
  createApiRequest({
    method: 'get',
    path: `${entityType}/${entityId}`,
    throwErrors: true,
  });

const deleteFeedEntity = (entityType, entityId) =>
  createApiRequest({
    method: 'delete',
    path: `${entityType}/${entityId}`,
    throwErrors: true,
  });

export const feedService = {
  fetchFeed,
  fetchFeedEntity,
  deleteFeedEntity,
};
