import React from 'react';
import { generatePath, useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import actions from 'rdx/actions/constituentFeedActions';
import { AUTH_ROUTES } from 'lib/constants';

export default function useConstituentFeed(constituentId) {
  const history = useHistory();
  const { id: activeConstituentId, historyId: activeHistoryItemId } =
    useParams();
  const dispatch = useDispatch();

  const { feed, ownerOrgId } = useSelector((state) => ({
    feed: state.constituentFeed,
    ownerOrgId: state.authentication?.ownerOrgId,
  }));

  const getFeedPage = React.useCallback(
    (pageFrom) =>
      dispatch(actions.fetch({ ownerOrgId, constituentId, pageFrom })),
    [ownerOrgId, constituentId]
  );

  const setActiveFeedItem = (id, historyId) =>
    history.push(
      generatePath(AUTH_ROUTES.CONSTITUENT_HISTORY, {
        constituentId: id,
        historyId,
      })
    );

  return {
    feed,
    getFeedPage,
    setActiveFeedItem,
    activeConstituentId,
    activeFeedItemId: activeHistoryItemId,
  };
}
