import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { PUBLIC_ROUTES } from 'lib/constants';
import { useUpdateVisitStreak } from 'lib/hooks/goals';

const PrivateRoute = ({ isAuthenticated, component: Component, ...rest }) => {
  useUpdateVisitStreak();

  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated ? (
          <Component {...props} />
        ) : (
          <Redirect to={PUBLIC_ROUTES.LOGIN} />
        )
      }
    />
  );
};

PrivateRoute.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
  component: PropTypes.elementType.isRequired,
};

const mapStateToProps = (state) => {
  const { isAuthenticated } = state.authentication;

  return { isAuthenticated };
};

const connectedPrivateRoute = connect(mapStateToProps)(PrivateRoute);

export { connectedPrivateRoute as PrivateRoute };
