import { matchPath } from 'react-router-dom';
import { translate } from 'lib/intl';
import { AUTH_ROUTES } from 'lib/constants';

const { CONSTITUENT_NEW, CONSTITUENT_HISTORY, CONSTITUENT_INFO } = AUTH_ROUTES;

const getConstituentPageTabs = (isNewConstituent = false, isPerson = true) => [
  {
    id: 'constituentActivity',
    default: !isNewConstituent,
    disabled: isNewConstituent,
    label: translate('CONSTITUENTS_ACTIVITY_TAB'),
    path: isNewConstituent ? '' : CONSTITUENT_HISTORY,
  },
  {
    id: isPerson ? 'personalInfo' : 'businessInfo',
    default: isNewConstituent,
    label: translate(isPerson ? 'PERSONAL_INFO_TAB' : 'BUSINESS_INFO_TAB'),
    path: isNewConstituent ? CONSTITUENT_NEW : CONSTITUENT_INFO,
  },
];

const getConstituentPageTabId = (tabsConfig = [], location) => {
  let defaultTabIdx;
  let tabIdx;

  // eslint-disable-next-line no-unused-expressions
  tabsConfig?.some((tab, idx) => {
    if (tab?.default) {
      defaultTabIdx = idx;
    }

    const matchRes = matchPath(location?.pathname, {
      path: tab?.path,
      exact: true,
      strict: false,
    });

    if (matchRes?.isExact) {
      tabIdx = idx;
    }

    return matchRes?.isExact;
  });

  return tabIdx || defaultTabIdx;
};

export { getConstituentPageTabs, getConstituentPageTabId };
