import React from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import makeStyles from '@material-ui/styles/makeStyles';
import { DeleteIcon } from 'assets/images';

const useStyles = makeStyles((theme) => ({
  container: {
    height: '100%',
    position: 'relative',
    padding: theme.spacing(3),
    width: '100%',
  },
  deleteIcon: {
    position: 'absolute',
    right: theme.spacing(3),
    top: theme.spacing(3),
    cursor: 'pointer',
  },
}));

const FeedSlider = ({ children, closeSlider }) => {
  const classes = useStyles();

  return (
    <Box className={classes.container} data-testid="feed-slider">
      <DeleteIcon
        data-testid="feed-slider-close-btn"
        className={classes.deleteIcon}
        onClick={closeSlider}
      />
      {children}
    </Box>
  );
};

FeedSlider.propTypes = {
  children: PropTypes.node,
  closeSlider: PropTypes.func.isRequired,
};

FeedSlider.defaultProps = {
  children: null,
};

export { FeedSlider };
