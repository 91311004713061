import React from 'react';
import { func } from 'prop-types';
import { Box } from '@material-ui/core';
import styled from 'styled-components';
import { CloseIcon } from 'assets/images';

const StyledCloseButton = styled(Box)`
  cursor: pointer;
`;

const CloseButton = ({ closeToast }) => (
  <StyledCloseButton
    alignItems="center"
    data-testid="toast-feedback-close-button"
    display="flex"
    onClick={closeToast}
  >
    <CloseIcon />
  </StyledCloseButton>
);

CloseButton.propTypes = {
  closeToast: func.isRequired,
};

export { CloseButton };
