import { createStore, applyMiddleware, compose } from 'redux';
import thunkMiddleware from 'redux-thunk';
import rootReducer from 'rdx/reducers';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

/* eslint-disable no-underscore-dangle */
export default createStore(
  rootReducer /* preloadedState, */,
  composeEnhancers(applyMiddleware(thunkMiddleware))
);
/* eslint-enable */
