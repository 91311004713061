import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/styles/makeStyles';
import { RESOURCE_NOT_FOUND } from 'lib/constants/error-enums';
import { USER_VIEW_ENUMS } from 'lib/constants/common';
import { capitalizeFirstLetter } from 'lib/utils';
import { FEED_CONTENT_TYPES } from 'lib/configs/feedTypes';
import { Loader } from 'components/common';
import { ShowForViews } from 'components/common/ShowForViews';
import { translate } from 'lib/intl';
import { FeedType } from 'lib/types';
import { FeedItemTitle } from './FeedItemTitle';
import { FeedSliderDonationContent } from './FeedSliderDonationContent';
import { FeedSliderCampaignContent } from './FeedSliderCampaignContent';
import { FeedSliderGenericContent } from './FeedSliderGenericContent';
import { FeedSliderBottomContent } from './FeedSliderBottomContent';
import { MarkdownItems } from './MarkdownItems';
import { useFeedContent } from './feedHooks';

const useStyles = makeStyles((theme) => ({
  bodyContainer: {
    paddingTop: theme.spacing(2.5),
    paddingBottom: theme.spacing(2.5),
    marginBottom: theme.spacing(2.5),
    borderBottom: `1px solid ${theme.palette.grey.light}`,
  },
  error: {
    color: theme.palette.error.main,
  },
}));

const EntityFeedItem = ({
  closeSlider,
  content,
  contentId,
  contentType,
  created,
  ownerOrgId,
}) => {
  const { entity, isFetching, error } = useFeedContent(contentId, contentType);

  const classes = useStyles();

  const Content = React.useMemo(() => {
    if (isFetching) {
      return <Loader isCentered />;
    }

    if (error) {
      return (
        <Typography className={classes.error}>
          {translate(
            // Even though "RESOURCE_NOT_FOUND" is a generic 404 error, this
            // should only be popping up in a feed if the item has been deleted
            // and the feed index on elastic search hasn't yet been updated.
            error.errorEnum === RESOURCE_NOT_FOUND
              ? 'FEED_ENTITY_DELETED'
              : 'GENERIC_ERROR',
            { entity: contentType }
          )}
        </Typography>
      );
    }

    if (entity?.contentId !== contentId) {
      return null;
    }

    switch (contentType) {
      case FEED_CONTENT_TYPES.DONATION:
      case FEED_CONTENT_TYPES.CONTRIBUTION:
        return <FeedSliderDonationContent {...entity} />;
      case FEED_CONTENT_TYPES.CAMPAIGN:
        return <FeedSliderCampaignContent {...entity} />;
      default:
        return <FeedSliderGenericContent ownerOrgId={ownerOrgId} {...entity} />;
    }
  }, [entity, error, isFetching]);

  const showBottomContent =
    !isFetching && !error && entity?.contentId === contentId;

  return (
    <>
      <FeedItemTitle type={capitalizeFirstLetter(contentType)} date={created} />
      <Typography variant="body1" className={classes.bodyContainer}>
        <MarkdownItems body={content} />
      </Typography>
      {Content}
      <ShowForViews showFor={[USER_VIEW_ENUMS.ADMIN, USER_VIEW_ENUMS.EDITOR]}>
        {showBottomContent && (
          <FeedSliderBottomContent
            contentId={contentId}
            contentType={contentType}
            closeSlider={closeSlider}
            {...entity}
          />
        )}
      </ShowForViews>
    </>
  );
};

EntityFeedItem.propTypes = {
  closeSlider: PropTypes.func.isRequired,
  content: PropTypes.array,
  contentId: PropTypes.string.isRequired,
  contentType: FeedType.isRequired,
  created: PropTypes.string,
  ownerOrgId: PropTypes.string.isRequired,
};

EntityFeedItem.defaultProps = {
  content: [],
  created: '',
};

export { EntityFeedItem };
