const EMPTY_FUNC = () => {};

const ZERO = 0;

const SOCIAL_ACCOUNTS = ['facebook', 'instagram', 'linkedin', 'twitter'];

const COLON = ':';

// supported two-letter ISO country codes (like US)
const COUNTRY_CODES = {
  US: 'US',
};

const DONOR_TYPES = {
  USER: 'odu',
  ORG: 'odo',
};

const KEY_ENUMS = {
  TAB: 'Tab',
};

const DONOR_CONNECTION_TYPES = {
  AFFILIATIONS: 'affiliations',
  EMPLOYEES: 'employees',
  CONNECTIONS: 'connections',
  HOUSEHOLD: 'household',
};

const DONOR_SECTION_TYPES = {
  AFFILIATIONS: DONOR_CONNECTION_TYPES.AFFILIATIONS,
  EMPLOYEES: DONOR_CONNECTION_TYPES.EMPLOYEES,
  CONNECTIONS: DONOR_CONNECTION_TYPES.CONNECTIONS,
  HOUSEHOLD: DONOR_CONNECTION_TYPES.HOUSEHOLD,
  PERSONAL_INFORMATION: 'personalInformation',
  COMPANY_INFORMATION: 'companyInformation',
  CONTACT_INFORMATION: 'contactInformation',
  WORK_INFORMATION: 'workInformation',
  GIVING_SUMMARY: 'givingSummary',
};

const TEAM_MEMBER_GROUPS = [
  {
    groupDisplayName: 'viewers',
    groupId: '1',
  },
  {
    groupDisplayName: 'editors',
    groupId: '2',
  },
  {
    groupDisplayName: 'admins',
    groupId: '3',
  },
];

const DEFAULT_DATE_FORMAT = 'd MMM y';
const API_DATE_FORMAT = 'yyyy-MM-dd';
const CUSTOM_DATE_FORMAT = 'MM/dd/yyyy';
const FULL_DATE_FORMAT = "MMMM d, y 'at' p";

// Be aware that fields are ordered for invites sorting
const INVITE_STATUSES = {
  CANCELLED: 'cancelled',
  REJECTED: 'rejected',
  PENDING: 'pending',
  SENT: 'sent',
  JOINED: 'joined',
};

const TOAST_TYPES = {
  ERROR: 'error',
  SUCCESS: 'success',
};

const TOAST_AUTO_CLOSE_TIME = 5000;

const TOASTS_LIMIT = 3;

const TOAST_FEEDBACK_IDS = {
  APP: 'app-toast-feedback',
  TEAM_MEMBERS: 'team-members-toast-feedback',
};

const STOP_PROPAGATION = (e) => e?.stopPropagation();

const INFO_COLUMN_WIDTH = '320px';

const RELATIVES = {
  AUNT: 'aunt',
  BROTHER: 'brother',
  COUSIN: 'cousin',
  DAUGHTER: 'daughter',
  FATHER: 'father',
  FATHER_IN_LOW: 'father-in-law',
  GRANDCHILD: 'grandchild',
  GRANDFATHER: 'grandfather',
  GRANDMOTHER: 'grandmother',
  HUSBAND: 'husband',
  MOTHER: 'mother',
  MOTHER_IN_LOW: 'mother-in-law',
  NEPHEW: 'nephew',
  NIECE: 'niece',
  SISTER: 'sister',
  SON: 'son',
  STEPBROTHER: 'stepbrother',
  STEPSISTER: 'stepsister',
  UNCLE: 'uncle',
  WIFE: 'wife',
  SPOUSE: 'spouse',
};

const LABELS = {
  PERSONAL: 'personal',
  HOME: 'home',
  MOBILE: 'mobile',
  BUSINESS: 'business',
  OFFICE: 'office',
  FAX: 'fax',
  WORK: 'work',
};

const EMAIL_LABELS = [LABELS.PERSONAL, LABELS.BUSINESS];
const PHONE_LABELS = [LABELS.HOME, LABELS.MOBILE, LABELS.BUSINESS];
const WORK_PHONE_LABELS = [LABELS.OFFICE, LABELS.MOBILE, LABELS.FAX];

const STANDARD_API_DATE_FORMAT = 'YYYY-MM-DD';

const TEXT_SELECT_FIELD_EMPTY_VALUE = null;

// without a dial code
const PHONE_NUMBER_LENGTH = 10;
const AREA_CODE_LENGTH = 3;

const PLUS_SIGN = '+';

const EID_FIRST_PART_LENGTH = 2;
const EID_NUM_LENGTH = 9;

const ASC = 'asc';
const DESC = 'desc';

const DEFAULT_COMPANY_FILTER = {
  searchValue: '',
  order: ASC,
  orderBy: '',
};

const EXPERIENCE_RATINGS = ['angry', 'sad', 'neutral', 'happy', 'excited'];

const DESKTOP_NAV_WIDTH = '80px';

const NUMBER_FORMAT_RETURNED_VALUES = {
  FORMATTED: 'formattedValue',
  FLOAT_VALUE: 'floatValue',
  VALUE: 'value',
};

const DEFAULT_CURRENCY = 'USD';

const CAMPAIGN_TYPE_ENUM = {
  GENERAL: 'gen',
  CROWDFUNDING: 'cf',
  PEER_TO_PEER: 'pp',
  EVENT: 'ev',
  REGISTRATION: 'rwf',
};

const CAMPAIGN_GOAL_TYPE_ENUM = {
  MONETARY: 'monetary',
  IN_KIND: 'in_kind',
};

const CAMPAIGN_STATUS_ENUM = {
  ACTIVE: 'active',
  COMPLETED: 'completed',
  ARCHIVED: 'archived',
};

const CAMPAIGN_MAIN_TYPE = 'cmp';
const MINUTES_IN_HOUR = 60;
const HOURS_IN_DAY = 24;

const TIME_TYPE_ENUM = {
  MINUTES: 'minutes',
  HOURS: 'hours',
  DAYS: 'days',
};

const DONATION_TYPE_ENUMS = {
  GENERAL: 'general',
  MAJOR_GIFT: 'major_gift',
  PLEDGE: 'pledge',
  RECURRING: 'recurring',
  RESTRICTED: 'restricted',
  IN_KIND: 'in_kind',
  PLANNED_GIVING: 'planned_giving',
  TIME: 'time',
  GRANT: 'grant',
};

const PAYMENT_TYPE_ENUMS = {
  CASH: 'cash',
  CHECK: 'check',
  CREDIT: 'credit',
  WIRE: 'wire',
  APP: 'app',
  CRYPTO: 'crypto',
};

const TIME_CATEGORY_ENUMS = {
  EVENTS: 'events',
  CAMPAIGNS: 'campaigns',
  GENERAL: 'general',
  GIFTS: 'major',
  OPERATIONS: 'admin',
  GRANTS: 'foundations',
  GOVERNANCE: 'governance',
  ADVOCACY: 'advocacy',
  OTHER: 'other',
};

const TABLE_SORT_ICON_SIZE = '24px';
const SEARCH_INPUT_MAX_WIDTH = '360px';

const CUSTOM_TABLE_DEFAULT_SORTING = {
  orderBy: '',
  order: ASC,
};

const MOBILE_NAV_HEIGHT = '56px';
const PAGE_TABS_HEIGHT = '48px';

const SUPER_ADMIN_HEADER_HEIGHT = '62px';
const MOBILE_SUPER_ADMIN_HEADER_HEIGHT = '52px';

const COMPANY_PAGE_TABS_ENUMS = {
  TEAM_MEMBERS: 'companyTeamMembers',
  INVITES: 'companyInvites',
};

const ICON_SIZES = {
  XS: '12px',
  SM: '16px',
  MD: '20px',
  LG: '24px',
  XL: '48px',
  XXL: '68px',
};

const USER_VIEW_ENUMS = {
  ADMIN: 'admin',
  EDITOR: 'editor',
  SUPER_ADMIN: 'super-admin',
};

const MODAL_CLOSING_REASONS = {
  BACKDROP_CLICK: 'backdropClick',
  ESCAPE_KEY_DOWN: 'escapeKeyDown',
};

const LOADER_SIZES = {
  XS: '20px',
  SM: '24px',
  MD: '48px',
  LG: '57px',
  XL: '100px',
};

const GROUP_TYPES = {
  MEMBER: 'member',
  PERMISSION: 'permission',
};

const DEFAULT_PAGE_SIZE = 20;

const CUSTOM_BUTTON_TYPES = {
  PRIMARY: 'primary',
  SECONDARY: 'secondary',
  LINK: 'link',
  ERROR: 'error',
};

const MULTI_ACTION_DIALOG_KEYS = {
  INTERACTION: 'interaction',
  DONATION: 'donation',
  MOVE: 'move',
  ASSIGN: 'assign',
  EDIT: 'edit',
  ADD_CONSTITUENT: 'add-constituent',
  DELETE_FEED_ITEM_CONFIRMATION: 'delete-confirmaiton',
  REMOVE: 'remove-constituent-card',
};

export {
  DEFAULT_DATE_FORMAT,
  API_DATE_FORMAT,
  CUSTOM_DATE_FORMAT,
  FULL_DATE_FORMAT,
  DONOR_TYPES,
  DONOR_CONNECTION_TYPES,
  DONOR_SECTION_TYPES,
  EMPTY_FUNC,
  COLON,
  COUNTRY_CODES,
  SOCIAL_ACCOUNTS,
  ZERO,
  TEAM_MEMBER_GROUPS,
  INVITE_STATUSES,
  TOAST_TYPES,
  TOAST_AUTO_CLOSE_TIME,
  TOASTS_LIMIT,
  TOAST_FEEDBACK_IDS,
  STOP_PROPAGATION,
  INFO_COLUMN_WIDTH,
  RELATIVES,
  LABELS,
  EMAIL_LABELS,
  PHONE_LABELS,
  WORK_PHONE_LABELS,
  STANDARD_API_DATE_FORMAT,
  TEXT_SELECT_FIELD_EMPTY_VALUE,
  PHONE_NUMBER_LENGTH,
  AREA_CODE_LENGTH,
  PLUS_SIGN,
  EID_FIRST_PART_LENGTH,
  EID_NUM_LENGTH,
  ASC,
  DESC,
  DEFAULT_COMPANY_FILTER,
  KEY_ENUMS,
  EXPERIENCE_RATINGS,
  DESKTOP_NAV_WIDTH,
  NUMBER_FORMAT_RETURNED_VALUES,
  DEFAULT_CURRENCY,
  CAMPAIGN_TYPE_ENUM,
  CAMPAIGN_GOAL_TYPE_ENUM,
  CAMPAIGN_MAIN_TYPE,
  CAMPAIGN_STATUS_ENUM,
  MINUTES_IN_HOUR,
  HOURS_IN_DAY,
  TIME_TYPE_ENUM,
  TABLE_SORT_ICON_SIZE,
  SEARCH_INPUT_MAX_WIDTH,
  CUSTOM_TABLE_DEFAULT_SORTING,
  MOBILE_NAV_HEIGHT,
  PAGE_TABS_HEIGHT,
  SUPER_ADMIN_HEADER_HEIGHT,
  MOBILE_SUPER_ADMIN_HEADER_HEIGHT,
  COMPANY_PAGE_TABS_ENUMS,
  ICON_SIZES,
  USER_VIEW_ENUMS,
  MODAL_CLOSING_REASONS,
  LOADER_SIZES,
  GROUP_TYPES,
  DONATION_TYPE_ENUMS,
  PAYMENT_TYPE_ENUMS,
  TIME_CATEGORY_ENUMS,
  DEFAULT_PAGE_SIZE,
  CUSTOM_BUTTON_TYPES,
  MULTI_ACTION_DIALOG_KEYS,
};
