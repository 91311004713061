import { addUniqueFeedItems } from 'lib/utils';
import { DEFAULT_PAGE_SIZE } from 'lib/constants/common';
import { types } from 'rdx/actions/constituentFeedActions';

const initialState = {
  isFetching: false,
  error: null,
  activeFeedItemId: null,
  activeConstituentId: null,
  dataLoaded: false,
  hasNextPage: null,
  pageFrom: 0,
  items: [],
};

/* eslint-disable no-case-declarations */
export default function constituentFeedReducer(state = initialState, action) {
  switch (action.type) {
    case types.request:
      return {
        ...state,
        dataLoaded:
          state.activeConstituentId ===
          action?.data?.searchParams?.constituentId,
        isFetching: true,
      };
    case types.success: {
      const feedItems = action?.data?.feedItems || [];
      const constituentId = action?.data?.searchParams?.constituentId;
      // Detect when a different constituent is active to clear the constituent history
      const notActiveConstituent = constituentId !== state.activeConstituentId;
      const items = notActiveConstituent
        ? feedItems
        : addUniqueFeedItems(state.items, feedItems);

      return {
        ...state,
        isFetching: false,
        activeConstituentId: constituentId,
        dataLoaded: true,
        items,
        pageFrom: action.data.searchParams.pageFrom + DEFAULT_PAGE_SIZE,
        hasNextPage: action?.data.totalHits > items?.length,
      };
    }
    case types.failure:
      return {
        ...state,
        dataLoaded: false,
        isFetching: false,
        error: action.error,
      };
    case types.CONSTITUENT_HISTORY_FEED_REFRESH_SUCCESS:
      return {
        ...state,
        isFetching: false,
        dataLoaded: true,
        items: action?.data?.feedItems || [],
      };
    default:
      return state;
  }
}
