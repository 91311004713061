import React from 'react';
import { Hub } from 'aws-amplify';
import userActionTypes from 'rdx/actionTypes';
import { useDispatch } from 'react-redux';
import { userService } from 'services/user';
import { useHistory } from 'react-router-dom';
import { UNAUTHORIZED, RESOURCE_NOT_FOUND, PUBLIC_ROUTES } from 'lib/constants';
import { orgInfoActions } from 'rdx/actions';

const googleProviderString = 'google';

// Array of errors that, when encountered from a valid google auth, we need to
// direct the user to registration so they can create a user.
const REGISTRATION_NEEDED_ERRORS = [UNAUTHORIZED, RESOURCE_NOT_FOUND];

export default function useAuthenticationHandler() {
  const dispatch = useDispatch();
  const history = useHistory();

  function handleAdminRegistration(regAdminData) {
    dispatch({
      type: userActionTypes.SET_USER_REG_ADMIN_DATA,
      data: regAdminData,
    });

    history.push(PUBLIC_ROUTES.ADMIN_REGISTER);

    return null;
  }

  React.useEffect(() => {
    Hub.listen('auth', (data) => {
      const { payload } = data;
      const { message } = payload;
      const isFromGoogle = message.includes(googleProviderString);

      if (payload.event === 'signIn') {
        userService.setAccessIdTokenFromCognitoUserResponse(payload?.data);

        userService
          .getContext(null, { isFromLogin: true })
          .then((contextResponse) => {
            // This handles admin registration coming from federated cognito google authentication
            if (isFromGoogle && !contextResponse?.data?.context) {
              return handleAdminRegistration(payload?.data);
            }

            // check for a non-registered user login
            // Handle normal login condition
            const userSession = userService.buildUserSessionObject(
              payload?.data,
              contextResponse
            );
            const tokenExpiration =
              payload?.data?.signInUserSession?.idToken?.payload?.exp;

            const userResponse = { userSession, tokenExpiration };

            dispatch({ type: userActionTypes.LOGIN_SUCCESS, userResponse });
            dispatch({
              type: userActionTypes.REFRESH_ACCESS_TOKEN_TIMER_SETUP,
            });
            dispatch(orgInfoActions.fetchOrgInfo(userSession?.org?.ownerOrgId));

            return userResponse;
          })
          .catch((errorResponse) => {
            // This handles admin registration coming from federated cognito google authentication
            if (
              isFromGoogle &&
              REGISTRATION_NEEDED_ERRORS.includes(errorResponse?.errorEnum)
            ) {
              return handleAdminRegistration(payload?.data);
            }

            // Handle context error condition
            if (
              typeof errorResponse === 'undefined' ||
              (errorResponse && errorResponse.error)
            ) {
              // eslint-disable-next-line no-console
              console.warn('Context error, fail login');

              const error = { message: 'No user context found.' };
              dispatch({ type: userActionTypes.LOGIN_FAILURE, error });
              return userService.logout();
            }

            return errorResponse;
          });
      }
    });
  }, []);
}
