import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import AppBar from '@material-ui/core/AppBar';
import Box from '@material-ui/core/Box';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import MenuIcon from '@material-ui/icons/Menu';
import ImageIcon from 'components/common/ImageIcon';
import { translate } from 'lib/intl';
import {
  EditProfileModal,
  UserProfileBottomLinks,
  UserProfileSignOutButton,
  UserProfileUpper,
} from 'components';
import { colors } from 'styles/theme';
import { EMPTY_FUNC } from 'lib/constants';
import NavigationList from './NavigationList';
import { LOWER_NAV_ITEMS, UPPER_NAV_ITEMS } from './navigationConfig';
import { useCompanyNavListItem } from './hooks';

const NAV_ITEMS = [...UPPER_NAV_ITEMS, ...LOWER_NAV_ITEMS];

const useStyles = makeStyles((theme) => ({
  container: {
    background: theme.palette.background.white,
    borderRadius: theme.shape.borderRadius,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    overflowY: 'hidden',
    msOverflowStyle: 'none',
    scrollbarWidth: 'none',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  appBar: {
    zIndex: 4,
  },
  children: {
    overflowY: 'auto',
  },
  buttonWrap: {
    margin: theme.spacing(2),
    marginTop: 0,
  },
  drawerPaper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%',
    borderTopRightRadius: theme.shape.borderRadius[0],
  },
  navItems: {
    borderTop: `1px solid ${colors.lightGrey}`,
  },
}));

const MobileNavigation = ({ children }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [isEditModalOpen, setEditModalOpen] = useState(false);

  const { orgName } = useCompanyNavListItem();

  const appBarRef = useRef();

  const classes = useStyles();

  const handleMenu = () => {
    setAnchorEl(appBarRef.current);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box className={classes.container} ref={appBarRef}>
      <AppBar
        color="inherit"
        position="static"
        classes={{ root: classes.appBar }}
      >
        <Toolbar>
          <IconButton
            data-testid="mobile-navigation-menu-button"
            edge="start"
            className={classes.menuButton}
            color="inherit"
            aria-label="menu"
            onClick={handleMenu}
          >
            <MenuIcon />
          </IconButton>
          <ImageIcon
            alt={translate('INSTIL_LOGO_ALT')}
            type="instil"
            height={24}
            className="h-6"
          />
          <Box pl={1.5}>
            <ImageIcon
              alt={translate('VERTICAL_LINE_ALT')}
              type="verticalLine"
            />
          </Box>
          <Typography
            variant="body1"
            style={{
              fontWeight: 500,
              paddingLeft: '12px',
            }}
          >
            {orgName}
          </Typography>
          <SwipeableDrawer
            classes={{ paper: classes.drawerPaper }}
            container={appBarRef.current}
            id="menu-appbar"
            onOpen={EMPTY_FUNC}
            onClose={handleClose}
            open={Boolean(anchorEl)}
            ModalProps={{ BackdropProps: { invisible: true } }}
          >
            <Box>
              <UserProfileUpper
                setEditModalOpen={setEditModalOpen}
                closeProfile={handleClose}
              />
              <List
                classes={{ root: classes.navItems }}
                aria-label="upper-main"
              >
                <NavigationList items={NAV_ITEMS} onClick={handleClose} />
              </List>
            </Box>
            <Box>
              <UserProfileSignOutButton />
              <UserProfileBottomLinks />
            </Box>
          </SwipeableDrawer>
        </Toolbar>
      </AppBar>
      <Box flex="1" className={classes.children}>
        {children}
      </Box>
      <EditProfileModal
        open={isEditModalOpen}
        handleClose={() => setEditModalOpen(false)}
      />
    </Box>
  );
};

MobileNavigation.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

MobileNavigation.defaultProps = {
  children: null,
};

export default MobileNavigation;
