import React from 'react';
import PropTypes from 'prop-types';
import Alert from '@material-ui/lab/Alert';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { userActions } from 'rdx/actions';
import { connect } from 'react-redux';
import { PUBLIC_ROUTES } from 'lib/constants';
import { translate } from 'lib/intl';
import { ControlledTextField } from 'components/common/form';
import { LoadingButton } from 'components/common';
import { emailRegex } from './authConstants';

const { RESET_PASSWORD_REQUEST_SUCCESS } = PUBLIC_ROUTES;

const ResetPasswordForm = ({
  isFetching,
  isResetRequestSuccessful,
  resetPasswordAction,
  resetPasswordResetState,
  error,
}) => {
  const history = useHistory();
  const { control, errors, handleSubmit } = useForm({
    mode: 'onBlur',
    defaultValues: {
      orgEmail: '',
    },
  });

  if (isResetRequestSuccessful) {
    resetPasswordResetState();
    history.push(RESET_PASSWORD_REQUEST_SUCCESS);
  }

  return (
    <Box display="flex" flexDirection="column">
      <Box mb={3}>
        <Typography variant="h1">
          {translate('RESET_PASSWORD_EMAIL')}
        </Typography>
      </Box>

      <form
        onSubmit={handleSubmit((data) => {
          const { orgEmail } = data;

          return resetPasswordAction(orgEmail);
        })}
      >
        {error && <Alert severity="error">{error.message}</Alert>}

        <ControlledTextField
          error={!!errors?.orgEmail}
          control={control}
          fullWidth
          name="orgEmail"
          label={translate('CHANGE_PASSWORD_ORG_EMAIL_LABEL')}
          rules={{
            required: translate('FORMS_VALIDATION_EMAIL_REQUIRED'),
            pattern: {
              value: emailRegex,
              message: translate('FORMS_VALIDATION_EMAIL_REQUIRED'),
            },
          }}
          helperText={!!errors?.orgEmail && errors.orgEmail.message}
        />
        <Box mt={4.5} textAlign="center">
          <LoadingButton
            isLoading={isFetching}
            type="submit"
            variant="contained"
          >
            {translate('RESET_PASSWORD')}
          </LoadingButton>
        </Box>
      </form>
    </Box>
  );
};

ResetPasswordForm.propTypes = {
  isFetching: PropTypes.bool.isRequired,
  resetPasswordAction: PropTypes.func.isRequired,
  isResetRequestSuccessful: PropTypes.bool.isRequired,
  resetPasswordResetState: PropTypes.func.isRequired,
  error: PropTypes.shape({ code: {}, message: {} }),
};

ResetPasswordForm.defaultProps = {
  error: null,
};

const mapStateToProps = (state) => {
  const { isFetching, isResetRequestSuccessful, error } = state.resetPassword;

  return { isFetching, isResetRequestSuccessful, error };
};

const actionCreators = {
  resetPasswordAction: userActions.resetPassword,
  resetPasswordResetState: userActions.resetPasswordResetState,
};

const connectedResetPasswordForm = connect(
  mapStateToProps,
  actionCreators
)(ResetPasswordForm);

export { connectedResetPasswordForm as ResetPasswordForm };
