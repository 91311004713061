import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import ButtonBase from '@material-ui/core/ButtonBase';
import Typography from '@material-ui/core/Typography';
import classNames from 'classnames';
import { ReactComponent as FilterIcon } from 'assets/images/filter.svg';
import { translate } from 'lib/intl';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  counter: {
    background: 'rgba(255,255,255,0.5)',
  },
}));

export default function FilterButton({ onClick, count, ...buttonProps }) {
  const styles = useStyles();
  return (
    <Button onClick={onClick} startIcon={<FilterIcon />} {...buttonProps}>
      <Typography component="span">{translate('BUTTON_FILTER')}</Typography>
      {!!count && (
        <div
          className={classNames(
            styles.counter,
            'h-5 ml-2 w-5 flex font-bold items-center justify-center rounded-full text-white text-xs leading-none'
          )}
        >
          {count}
        </div>
      )}
    </Button>
  );
}

FilterButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  count: PropTypes.number,
  ...ButtonBase.propTypes,
  ...Button.propTypes,
};

FilterButton.defaultProps = {
  count: 0,
};

FilterButton.defaultProps = {};
