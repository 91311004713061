import React from 'react';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/styles/makeStyles';
import { AuthContainer } from 'components/auth';
import { translate } from 'lib/intl';
import { withNewThemeProvider } from 'components/common/NewThemeProvider';

const useStyles = makeStyles((theme) => ({
  thankYou: {
    marginBottom: theme.spacing(3),
  },
}));

export const ResetPasswordRequestSuccessPageNoTheme = () => {
  const classes = useStyles();

  return (
    <AuthContainer size="sm">
      <Typography variant="h1" className={classes.thankYou}>
        {`${translate('THANK_YOU')}!`}
      </Typography>

      <Typography variant="body1">
        {translate('RESET_PASSWORD_REQUEST_SUCCESS')}
      </Typography>
    </AuthContainer>
  );
};

export const ResetPasswordRequestSuccessPage = withNewThemeProvider(
  ResetPasswordRequestSuccessPageNoTheme
);
