// TODO remove this eslint disable on API integration
/* eslint-disable no-unused-vars */
/* eslint-disable no-console */

import { createApiRequest } from 'lib/utils';

const fetchInteraction = (interactionId) => {
  console.warn('fetchInteraction to be implemented', interactionId);

  return {};
};

const submitInteraction = ({ data, interactionId }) =>
  createApiRequest({
    method: interactionId ? 'put' : 'post',
    path: interactionId ? `interactions/${interactionId}` : 'interactions',
    data,
    throwErrors: true,
  });

export const interactionsService = {
  fetchInteraction,
  submitInteraction,
};
