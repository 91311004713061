import { camelCaseKeys, createAction, showErrorMessage } from 'lib/utils';
import { translate } from 'lib/intl';
import constituentService from 'services/constituentServices';
import {
  CONSTITUENT_CONNECTION_TYPES,
  CONSTITUENT_CONNECTION_SINGULAR_TYPES,
} from 'components/constituents/constituentTypes';

export const types = {
  CONSTITUENT_REQUEST: 'CONSTITUENT_REQUEST',
  CONSTITUENT_REQUEST_SUCCESS: 'CONSTITUENT_REQUEST_SUCCESS',
  CONSTITUENT_REQUEST_FAILURE: 'CONSTITUENT_REQUEST_FAILURE',
  CREATE_CONSTITUENT_REQUEST: 'CREATE_CONSTITUENT_REQUEST',
  CREATE_CONSTITUENT_SUCCESS: 'CREATE_CONSTITUENT_SUCCESS',
  CREATE_CONSTITUENT_FAILURE: 'CREATE_CONSTITUENT_FAILURE',
  UPDATE_CONSTITUENT_REQUEST: 'UPDATE_CONSTITUENT_REQUEST',
  UPDATE_CONSTITUENT_SUCCESS: 'UPDATE_CONSTITUENT_SUCCESS',
  UPDATE_CONSTITUENT_FAILURE: 'UPDATE_CONSTITUENT_FAILURE',
  REMOVE_CONSTITUENT_CONNECTION_REQUEST:
    'REMOVE_CONSTITUENT_CONNECTION_REQUEST',
  REMOVE_CONSTITUENT_CONNECTION_SUCCESS:
    'REMOVE_CONSTITUENT_CONNECTION_SUCCESS',
  REMOVE_CONSTITUENT_CONNECTION_FAILURE:
    'REMOVE_CONSTITUENT_CONNECTION_FAILURE',
  CREATE_CONSTITUENT_CONNECTION_REQUEST:
    'CREATE_CONSTITUENT_CONNECTION_REQUEST',
  CREATE_CONSTITUENT_CONNECTION_SUCCESS:
    'CREATE_CONSTITUENT_CONNECTION_SUCCESS',
  CREATE_CONSTITUENT_CONNECTION_FAILURE:
    'CREATE_CONSTITUENT_CONNECTION_FAILURE',
  UPDATE_CONSTITUENT_CONNECTION_REQUEST:
    'UPDATE_CONSTITUENT_CONNECTION_REQUEST',
  UPDATE_CONSTITUENT_CONNECTION_SUCCESS:
    'UPDATE_CONSTITUENT_CONNECTION_SUCCESS',
  UPDATE_CONSTITUENT_CONNECTION_FAILURE:
    'UPDATE_CONSTITUENT_CONNECTION_FAILURE',
};

export const fetchConstituentsTypes = {
  failure: 'CONSTITUENTS_REQUEST_FAILURE',
  request: 'CONSTITUENTS_REQUEST',
  success: 'CONSTITUENTS_REQUEST_SUCCESS',
};

// a helper function to find an id of a newly created connection
const findConnectionId = (data = {}, idField) => {
  const keys = Object.keys(data);

  for (let i = 0; i < keys.length; i += 1) {
    const keyValue = data[keys[i]];

    if (typeof keyValue === 'object' && keyValue[idField]) {
      return keyValue[idField];
    }
  }

  return '';
};

const fetchConstituent =
  ({ constituentId }) =>
  async (dispatch, getState) => {
    try {
      const { ownerOrgId } = getState().authentication;
      dispatch(createAction({ type: types.CONSTITUENT_REQUEST }));

      const response = await constituentService.fetchConstituent({
        ownerOrgId,
        constituentId,
      });

      const data = {
        constituent: response?.data?.constituent,
      };

      dispatch(
        createAction({
          type: types.CONSTITUENT_REQUEST_SUCCESS,
          data,
        })
      );
    } catch (err) {
      showErrorMessage({
        message: translate('FETCH_CONSTITUENT_ERROR_MESSAGE'),
      });

      dispatch(
        createAction({
          type: types.CONSTITUENT_REQUEST_FAILURE,
          err,
        })
      );
    }
  };

const fetchConstituents = () => async (dispatch, getState) => {
  try {
    const { ownerOrgId } = getState().authentication;
    dispatch(createAction({ type: fetchConstituentsTypes.request }));

    const response = await constituentService.fetchConstituents({
      ownerOrgId,
    });

    const data = {
      constituents: response?.data?.constituents,
    };

    dispatch(
      createAction({
        type: types.CONSTITUENT_REQUEST_SUCCESS,
        success: fetchConstituentsTypes.success,
        data,
      })
    );
  } catch (err) {
    showErrorMessage({
      message: translate('FETCH_CONSTITUENT_ERROR_MESSAGE'),
    });

    dispatch(
      createAction({
        type: fetchConstituentsTypes.failure,
        err,
      })
    );
  }
};

const removeConnection =
  ({ apiType, constituentId, data }) =>
  async (dispatch, getState) => {
    const connectionId = data.constituentId;

    try {
      const { ownerOrgId } = getState().authentication;

      dispatch(
        createAction({
          type: types.REMOVE_CONSTITUENT_CONNECTION_REQUEST,
          id: data.constituentId,
        })
      );

      const response = await constituentService.removeConnection({
        apiType,
        constituentId,
        connectionId,
        ownerOrgId,
      });

      dispatch(
        createAction({
          type: types.REMOVE_CONSTITUENT_CONNECTION_SUCCESS,
          id: connectionId,
          data: camelCaseKeys(response?.data),
          apiType,
        })
      );
    } catch (error) {
      showErrorMessage({
        message: translate('CONSTITUENT_REMOVE_CONNECTION_ERROR_MESSAGE', {
          name: data.displayName,
        }),
      });

      dispatch(
        createAction({
          type: types.REMOVE_CONSTITUENT_CONNECTION_FAILURE,
          id: connectionId,
          error,
        })
      );
    }
  };

const updateConnection =
  ({ constituentId, apiType, data }) =>
  async (dispatch, getState) => {
    const connectionId = data.constituentId;

    try {
      const { ownerOrgId } = getState().authentication;

      dispatch(
        createAction({
          type: types.UPDATE_CONSTITUENT_CONNECTION_REQUEST,
          id: connectionId,
        })
      );

      await constituentService.updateConstituent({
        apiType,
        connectionId,
        constituentId,
        data,
        ownerOrgId,
      });

      dispatch(
        createAction({
          type: types.UPDATE_CONSTITUENT_CONNECTION_SUCCESS,
          id: connectionId,
          data,
          apiType,
        })
      );
    } catch (error) {
      showErrorMessage({
        message: translate('CONSTITUENT_CREATE_UPDATING_ERROR_MESSAGE', {
          name: data.displayName,
        }),
      });

      dispatch(
        createAction({
          type: types.UPDATE_CONSTITUENT_CONNECTION_FAILURE,
          id: connectionId,
          error,
        })
      );
    }
  };

export const createConstituent =
  ({ idField, data, onSuccess }) =>
  async (dispatch, getState) => {
    try {
      const { ownerOrgId } = getState().authentication;

      dispatch(createAction({ type: types.CREATE_CONSTITUENT_REQUEST }));

      const constituent = await constituentService.createConstituent({
        // TODO: setting kanbanView=true so that any/all constituent shows up on the kanban board
        // there is a future feature request to be able to set this to false (ticket #2314)
        // which will need design updates on the board and within the constituent page view
        data: { ...data, kanbanView: true },
        ownerOrgId,
      });

      dispatch(
        createAction({
          type: types.CREATE_CONSTITUENT_SUCCESS,
          idField,
          data,
        })
      );

      if (onSuccess) {
        onSuccess(constituent?.data?.constituent);
      }
    } catch (error) {
      showErrorMessage({
        message: translate('CREATE_CONSTITUENT_ERROR_MESSAGE', {
          name: data.displayName,
        }),
      });

      dispatch(
        createAction({
          type: types.CREATE_CONSTITUENT_FAILURE,
          error,
        })
      );
    }
  };

const createConnection =
  ({ constituentId, apiType, idField, connectionId, data }) =>
  async (dispatch, getState) => {
    const { ownerOrgId } = getState().authentication;

    try {
      dispatch(
        createAction({
          type: types.CREATE_CONSTITUENT_CONNECTION_REQUEST,
          id: connectionId,
        })
      );

      const response = await constituentService.createConstituent({
        apiType,
        connectionId,
        constituentId,
        data,
        ownerOrgId,
      });
      let singularApiType;
      switch (apiType) {
        case CONSTITUENT_CONNECTION_TYPES.CONNECTIONS:
          singularApiType = CONSTITUENT_CONNECTION_SINGULAR_TYPES.CONNECTION;
          break;
        case CONSTITUENT_CONNECTION_TYPES.AFFILIATIONS:
          singularApiType = CONSTITUENT_CONNECTION_SINGULAR_TYPES.AFFILIATION;
          break;
        case CONSTITUENT_CONNECTION_TYPES.EMPLOYEES:
          singularApiType = CONSTITUENT_CONNECTION_SINGULAR_TYPES.EMPLOYEE;
          break;
        case CONSTITUENT_CONNECTION_TYPES.CONTACTS:
          singularApiType = CONSTITUENT_CONNECTION_SINGULAR_TYPES.CONTACT;
          break;
        default:
          singularApiType = apiType;
          break;
      }

      dispatch(
        createAction({
          type: types.CREATE_CONSTITUENT_CONNECTION_SUCCESS,
          id: findConnectionId(response?.data, idField),
          idField,
          data: {
            ...data,
            constituentId: response?.data?.[singularApiType]?.constituentId,
          },
          apiType,
        })
      );
    } catch (error) {
      showErrorMessage({
        message: translate('CONSTITUENT_CREATE_CONNECTION_ERROR_MESSAGE', {
          name: data.displayName,
        }),
      });

      dispatch(
        createAction({
          type: types.CREATE_CONSTITUENT_CONNECTION_FAILURE,
          id: connectionId,
          error,
        })
      );
    }
  };

const updateConstituent =
  ({ constituentId, idField, data, onSuccess }) =>
  async (dispatch, getState) => {
    try {
      const { ownerOrgId } = getState().authentication;

      dispatch(
        createAction({
          type: types.UPDATE_CONSTITUENT_REQUEST,
        })
      );

      const response = await constituentService.updateConstituent({
        ownerOrgId,
        constituentId,
        data,
      });

      dispatch(
        createAction({
          type: types.UPDATE_CONSTITUENT_SUCCESS,
          idField,
          data,
        })
      );

      if (onSuccess) {
        onSuccess(response?.data?.constituent);
      }
    } catch (error) {
      showErrorMessage({
        message: translate('UPDATE_CONSTITUENT_ERROR_MESSAGE', {
          name: data.displayName,
        }),
      });

      dispatch(
        createAction({
          type: types.UPDATE_CONSTITUENT_FAILURE,
          error,
        })
      );
    }
  };

export default {
  createConnection,
  createConstituent,
  fetchConstituent,
  fetchConstituents,
  removeConnection,
  types,
  updateConnection,
  updateConstituent,
};
