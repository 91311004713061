import React from 'react';
import { kebabCase } from 'voca';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { CompanyTeamMember } from 'lib/utils';
import { TeamMemberLinkCell } from './team-member-link-cell';

const TeamMemberMainInfoCell = ({ data }) => (
  <Box>
    <Typography data-testid={`main-info-${kebabCase(data?.displayName)}`}>
      {data?.displayName}
    </Typography>
    <TeamMemberLinkCell value={data?.email} />
  </Box>
);

TeamMemberMainInfoCell.propTypes = {
  data: CompanyTeamMember.isRequired,
};

export { TeamMemberMainInfoCell };
