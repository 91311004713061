export const REPORTS = {
  REPORTS_D3_ERROR: 'There was an error with rendering this report',
  REPORTS_CAMPAIGN_OVERVIEW: 'Campaign Overview',
  REPORTS_CURRENT_STATE_DASHBOARD: 'Current State Dashboard',
  REPORTS_DONATIONS_OVER_TIME: 'Donations Over Time',
  REPORTS_FILTER_ASSIGNED_EMPLOYEE: 'Assigned Employee',
  REPORTS_FILTER_DAILY: 'Daily',
  REPORTS_FILTER_END_DATE_LABEL: 'End Date',
  REPORTS_FILTER_MONTHLY: 'Monthly',
  REPORTS_FILTER_REPORT_TYPE: 'Report Type',
  REPORTS_FILTER_START_DATE_LABEL: 'Start Date',
  REPORTS_FILTER_DATE_TYPE_LABEL: 'Date',
  REPORTS_FILTER_DATE_TYPE_FISCAL_YTD: 'Fiscal YTD',
  REPORTS_FILTER_DATE_TYPE_CALENDAR_YTD: 'Calendar YTD',
  REPORTS_FILTER_DATE_TYPE_CUSTOM_DATES: 'Custom Dates',
  REPORTS_FILTER_RESET: 'Reset Filters',
  REPORTS_FILTER_WEEKLY: 'Weekly',
  REPORTS_NEW_DONOR_GROWTH: 'New Donor Growth',
  REPORTS_PAGE_FILTER_MODAL_TITLE: 'Filter Report',
  REPORTS_PAGE_TITLE: 'Reports',
  REPORTS_PAGE_TITLE_ICON_ALT: 'Reports Icon',
  REPORTS_UPDATE_REPORT: 'Update Report',
  REPORTS_TOTAL_VALUE: 'Total Value',
  REPORTS_TOTAL_DONATIONS: 'Total Donations',
  REPORTS_TOTAL_DONORS: 'Total Donors',
  REPORTS_AVERAGE_DONATION: 'Average Donation',
  REPORTS_TOTAL_HOURS: 'Total Hours',
  REPORTS_INKIND_VALUE: 'In-Kind Value',
  REPORTS_DONOR_LIST: 'Donor List',
  REPORTS_NO_RESULTS_FOUND: 'No Results Found',
  REPORTS_PLEASE_ADJUST: 'Please adjust your report settings and try again.',
};
