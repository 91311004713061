import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  tabWrapper: {
    display: 'flex',
    flex: '1',
    flexDirection: 'column',
    overflow: 'auto',
  },
  tabContent: {
    overflow: 'auto',
    flex: 1,
  },
  campaignName: {
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      marginBottom: theme.spacing(1),
    },
  },
  campaignDescription: {
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      marginBottom: theme.spacing(1),
    },
  },
  campaignUrl: {
    fontWeight: theme.typography.fontWeightMedium,
  },
}));

export { useStyles };
