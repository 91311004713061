import React from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import propTypes from 'prop-types';
import { getFullName, getInitials } from 'lib/utils';
import { translate } from 'lib/intl';
import { DONOR_SECTION_CONFIGS } from 'lib/configs';
import { MultipleInputs, SectionsGenerator, Visible } from 'components';
import { CONSTITUENT_TYPES } from 'lib/constants/constituentConstants';
import { isPersonConstituentType } from 'lib/utils/constituentUtils';
import {
  ConstituentSectionTypes,
  ConstituentType,
  ConstituentTypes,
} from './constituentTypes';
import useConstituentForm from './useConstituentForm';
import { EDIT_CONSTITUENT_NOTES_CONFIG } from './constituentFormConfigs';
import ConstituentSectionWrapper from './ConstituentSectionWrapper';
import ConstituentInformationCard from './ConstituentInformationCard';
import ConstituentFormPopup from './ConstituentFormPopup';
import ShowMoreContent from './ShowMoreContent';

/**
 * This is a component for rendering donor sections
 * such as Personal Information and Company Information
 */
const ConstituentInformationSection = ({
  data,
  constituentType,
  isEditable,
  isNewConstituent,
  type,
}) => {
  const { key, title, firstColConfig, secondColConfig } =
    DONOR_SECTION_CONFIGS[type];

  const {
    isPopupOpen,
    openForm,
    showForm,
    closeForm,
    onSave,
    sectionGeneratorConfig,
    sectionGeneratorShowMoreConfig,
    formData,
    formErrors,
    setErrors,
    showErrors,
    setFormData,
    updatingConstituent,
    showMoreSection,
    setShowMoreSection,
  } = useConstituentForm({
    type,
    constituentType,
    isNewConstituent,
  });

  const {
    dataLoaded: isDataUpdated,
    error: formHasError,
    isFetching: isDataLoading,
  } = updatingConstituent;

  const avatarInitials = React.useMemo(
    () =>
      getInitials(
        formData.orgName || getFullName(formData),
        isPersonConstituentType(formData)
      ),
    [formData]
  );

  const onEditClick = React.useCallback(
    (fieldToFocus) =>
      openForm({
        type,
        isCreating: false,
        data,
        fieldToFocus,
      }),
    [data]
  );
  return (
    <ConstituentSectionWrapper
      actionLabel={translate('EDIT')}
      id={key}
      isEditable={isEditable}
      onActionClick={onEditClick}
      title={title}
    >
      <ConstituentInformationCard
        data={data}
        firstColConfig={firstColConfig}
        secondColConfig={secondColConfig}
        isEditable={isEditable}
        openForm={onEditClick}
      />
      <ConstituentFormPopup
        avatar={formData?.avatar}
        avatarInitials={avatarInitials}
        title={title}
        // makes sure to show loading until the form is closed
        isLoading={
          isNewConstituent ? isDataLoading : isDataLoading || isDataUpdated
        }
        isOpen={isPopupOpen}
        onClose={closeForm}
        onSave={onSave}
        showAvatar
        apiError={Boolean(formHasError)}
        validationError={showErrors}
      >
        <Visible when={showForm}>
          <SectionsGenerator
            MultipleInputsComponent={MultipleInputs}
            config={sectionGeneratorConfig}
            data={formData}
            errors={formErrors}
            setErrors={setErrors}
            showErrors={showErrors}
            updateData={setFormData}
          />
          <ShowMoreContent
            showMore={showMoreSection}
            setShowMore={setShowMoreSection}
          >
            <SectionsGenerator
              config={sectionGeneratorShowMoreConfig}
              data={formData}
              errors={formErrors}
              setErrors={setErrors}
              showErrors={showErrors}
              updateData={setFormData}
            />
          </ShowMoreContent>
          <Box pt={2} pl={4}>
            <Typography variant="subtitle1">
              {translate('DONOR_KEY_NOTES')}
            </Typography>
          </Box>
          <SectionsGenerator
            config={EDIT_CONSTITUENT_NOTES_CONFIG}
            data={formData}
            errors={formErrors}
            setErrors={setErrors}
            showErrors={showErrors}
            updateData={setFormData}
          />
        </Visible>
      </ConstituentFormPopup>
    </ConstituentSectionWrapper>
  );
};

ConstituentInformationSection.propTypes = {
  data: ConstituentType,
  constituentType: ConstituentTypes,
  isEditable: propTypes.bool,
  isNewConstituent: propTypes.bool,
  type: ConstituentSectionTypes.isRequired,
};

ConstituentInformationSection.defaultProps = {
  data: {},
  constituentType: CONSTITUENT_TYPES.PERSON,
  isEditable: false,
  isNewConstituent: false,
};

export default ConstituentInformationSection;
