const AUTH_USER_ID_TOKEN = 'idToken';
const AUTH_X_INSTIL = 'dotOrgXAuth';
const OWNER_ORG_ID_PUB = 'ownerOrgId';
const OWNER_ORG_NAME = 'ownerOrgName';
const OWNER_ORG_VIEWS = 'ownerOrgViews';
const USER_ID_PUB = 'userId';
const USER_DISPLAY_NAME = 'userDisplayName';
const IS_SUPER_ADMIN = 'isSuperAdmin';
const USER_EMAIL = 'userEmail';
const RECENTLY_VIEWED_DONORS = 'recentlyViewedDonors';
const RECENTLY_VIEWED_CONSTITUENTS = 'recentlyViewedConstituents';
const VISIT_STREAK_CHECK_IN = 'visitStreakCheckIn';
const CORRELATION_ID = 'correlationId';
const USER_VIEWS = 'views';
const FEATURE_FLAGS = 'featureFlags';
const USER_NAME_PUB = 'userName';

export {
  AUTH_USER_ID_TOKEN,
  AUTH_X_INSTIL,
  CORRELATION_ID,
  OWNER_ORG_ID_PUB,
  OWNER_ORG_NAME,
  OWNER_ORG_VIEWS,
  USER_ID_PUB,
  USER_DISPLAY_NAME,
  IS_SUPER_ADMIN,
  USER_EMAIL,
  RECENTLY_VIEWED_DONORS,
  RECENTLY_VIEWED_CONSTITUENTS,
  VISIT_STREAK_CHECK_IN,
  USER_VIEWS,
  FEATURE_FLAGS,
  USER_NAME_PUB,
};
