import React, { useCallback, useState } from 'react';
import Box from '@material-ui/core/Box';
import Checkbox from '@material-ui/core/Checkbox';
import ConstituentPropType, {
  ConstituentType,
} from 'lib/types/ConstituentPropType';
import InputLabel from '@material-ui/core/InputLabel';
import { isMobileView } from 'lib/utils';
import { translate } from 'lib/intl';
import PropTypes from 'prop-types';
import {
  BoardMemberIcon,
  VolunteerIcon,
  MemberIcon,
  AttributePartnerIcon,
  AttributeRecipientIcon,
  AttributeCorporationIcon,
  AttributeFoundationIcon,
} from '../../lib/configs/donor-icons';

const EditConstituentAttributes = ({ data, updateData }) => {
  const isMobile = isMobileView();

  const {
    categoryBoardMember,
    categoryCorporation,
    categoryFoundation,
    categoryPartner,
    categoryRecipient,
    categoryVolunteer,
    categoryMember,
    constituentType,
  } = data;
  const [checkedItems, setCheckedItems] = useState({
    categoryBoardMember,
    categoryCorporation,
    categoryFoundation,
    categoryPartner,
    categoryRecipient,
    categoryVolunteer,
    categoryMember,
  });

  const onChange = useCallback(
    ({ target }) => {
      setCheckedItems({
        ...checkedItems,
        [target.name]: target.checked,
      });
      updateData({ ...data, [target.name]: target.checked });
    },
    [data, updateData]
  );

  const checkboxes = [
    {
      name: 'categoryBoardMember',
      label: translate('CONSTITUENT_BOARD_MEMBER_LABEL'),
      icon: BoardMemberIcon,
    },
    {
      name: 'categoryRecipient',
      label: translate('CONSTITUENT_RECIPIENT_LABEL'),
      icon: AttributeRecipientIcon,
    },
    {
      name: 'categoryMember',
      label: translate('CONSTITUENT_MEMBER_LABEL'),
      icon: MemberIcon,
    },
    {
      name: 'categoryPartner',
      label: translate('CONSTITUENT_PARTNER_LABEL'),
      icon: AttributePartnerIcon,
    },
    {
      name: 'categoryVolunteer',
      label: translate('CONSTITUENT_VOLUNTEER_LABEL'),
      icon: VolunteerIcon,
    },
  ];

  if (constituentType === ConstituentType.ORG) {
    checkboxes.push(
      {
        name: 'categoryCorporation',
        label: translate('CONSTITUENT_CORPORATION_LABEL'),
        icon: AttributeCorporationIcon,
      },
      {
        name: 'categoryFoundation',
        label: translate('CONSTITUENT_FOUNDATION_LABEL'),
        icon: AttributeFoundationIcon,
      }
    );
  }

  return (
    <div data-testid="constituent-attributes" className="pt-6">
      <InputLabel className="block">
        {translate('CONSTITUENT_CATEGORY')}
      </InputLabel>

      <Box
        className="flex flex-wrap font-normal"
        flexDirection={isMobile ? 'column' : 'row'}
      >
        {checkboxes.map((item) => (
          <label
            className="flex items-center pr-2"
            htmlFor={`donor-attributes-checkbox-${item.name}`}
            key={item.name}
          >
            <Checkbox
              data-testid={`donor-attributes-checkbox-${item.name}`}
              checked={!!checkedItems[item.name]}
              color="primary"
              id={`donor-attributes-checkbox-${item.name}`}
              name={item.name}
              onChange={onChange}
            />
            <span className="mr-1">{item.icon}</span>
            {item.label}
          </label>
        ))}
      </Box>
    </div>
  );
};

EditConstituentAttributes.propTypes = {
  data: ConstituentPropType.isRequired,
  updateData: PropTypes.func.isRequired,
};

export default EditConstituentAttributes;
