import { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import {
  companyTeamMembersActions,
  invitesActions,
  orgInfoActions,
} from 'rdx/actions';
import { useDispatch, useSelector } from 'react-redux';
import userActionTypes from 'rdx/actionTypes';
import { DEFAULT_COMPANY_FILTER, INVITE_STATUSES, ZERO } from 'lib/constants';
import { translate } from 'lib/intl';
import {
  focusFormField,
  isValidEmail,
  lowerCase,
  sortByCategories,
} from 'lib/utils';
import { OWNER_ORG_VIEWS } from 'lib/constants/localStorageKeys';
import { CONPANY_PAGE_TABS } from './companyPageConfig';
import { getCompanyPageTabId, formCompanyInfoData } from './companyPageUtils';

const { INVITE_FORM_SET_ERROR, SENDING_INVITES_RESET } = userActionTypes;

const useCompanyPage = () => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();

  const [showSendInvitesDialog, setShowSendInvitesDialog] = useState(false);

  const {
    companyInfo,
    isCompanyInfoLoading,
    teamMembers,
    isTeamMembersLoaded,
    isTeamMembersLoading,
    sendingInvites,
    invites,
    isInvitesLoaded,
    isInvitesLoading,
    updatingInvites,
    ownerOrgId,
    ownerOrgName,
    orgInfo,
    userOrgId,
  } = useSelector((state) => ({
    companyInfo: state.orgInfo.data,
    isCompanyInfoLoading: state.orgInfo.isFetching,
    isTeamMembersLoaded: state.companyTeamMembers.dataLoaded,
    isTeamMembersLoading: state.companyTeamMembers.isFetching,
    teamMembers: state.companyTeamMembers.data?.users || [],
    sendingInvites: state.sendingInvites,
    invites: state.invites.data.invites || [],
    isInvitesLoaded: state.invites.dataLoaded,
    isInvitesLoading: state.invites.isFetching,
    updatingInvites: state.updatingInvites,
    ownerOrgId: state.authentication?.ownerOrgId,
    ownerOrgName:
      state?.authentication?.ownerOrgName ||
      state?.user?.ownerOrgName ||
      state?.orgInfo?.data?.orgName,
    orgInfo: state?.orgInfo?.data,
    userOrgId: state.user?.user?.ownerOrgId,
  }));

  const [tabIdx, setTabIdx] = useState(getCompanyPageTabId(location));
  const isTeamMembersTab = tabIdx === ZERO;
  const [filter, setFilter] = useState(DEFAULT_COMPANY_FILTER);

  const onTabClick = useCallback(
    (idx) => {
      setFilter(DEFAULT_COMPANY_FILTER);

      setTabIdx(idx);

      const newLocation = CONPANY_PAGE_TABS[idx]?.location;

      if (newLocation) {
        history.push(CONPANY_PAGE_TABS[idx]?.location);
      }
    },
    [isTeamMembersTab, teamMembers, invites]
  );

  const fetchCompanyInfo = useCallback(() => {
    if (!isCompanyInfoLoading) {
      dispatch(orgInfoActions.fetchOrgInfo(ownerOrgId));
    }
  }, [isCompanyInfoLoading]);

  const fetchCompanyTeamMembers = useCallback(() => {
    if (!isTeamMembersLoading) {
      dispatch(companyTeamMembersActions.fetchCompanyTeamMembers(ownerOrgId));
    }
  }, [isTeamMembersLoading]);

  const fetchInvites = () => dispatch(invitesActions.fetchInvites(ownerOrgId));

  useEffect(() => {
    fetchCompanyInfo();
    fetchCompanyTeamMembers();
    fetchInvites();
  }, []);

  useEffect(() => {
    if (ownerOrgId && orgInfo?.orgId !== ownerOrgId) {
      fetchCompanyInfo();
    }
  }, [orgInfo, ownerOrgId]);

  const isInviteFormLoading = useMemo(
    () => sendingInvites.isFetching,
    [sendingInvites]
  );

  const openSendInvitesModal = useCallback(() => {
    setShowSendInvitesDialog(true);
  }, []);

  const closeSendInvitesModal = useCallback(() => {
    setShowSendInvitesDialog(false);
    dispatch({ type: INVITE_FORM_SET_ERROR, error: null });
  }, []);

  useEffect(() => {
    if (sendingInvites.dataLoaded) {
      closeSendInvitesModal();
      dispatch({ type: SENDING_INVITES_RESET });
      fetchInvites();
    }
  }, [sendingInvites]);

  useEffect(() => {
    if (updatingInvites.dataLoaded) {
      fetchInvites(ownerOrgId);
    }
  }, [updatingInvites]);

  const sendInvites = useCallback(
    ({ emails, groups }) => {
      const valid =
        emails?.length && emails?.every((email) => isValidEmail(email));

      if (valid) {
        dispatch(
          invitesActions.sendInvites(ownerOrgId, {
            emails,
            groups: groups?.map(({ groupDisplayName, groupId }) => ({
              groupDisplayName: lowerCase(groupDisplayName),
              groupId,
            })),
            ownerOrgName,
          })
        );
      } else {
        dispatch({
          type: INVITE_FORM_SET_ERROR,
          error: translate('COMPANY_INVITE_FORM_FIX_EMAILS_MESSAGE'),
        });
      }
    },
    [ownerOrgId, ownerOrgName]
  );

  const sortedInvites = useMemo(
    () => sortByCategories(invites, 'status', Object.values(INVITE_STATUSES)),
    [invites]
  );

  const orgViews = JSON.parse(localStorage.getItem(OWNER_ORG_VIEWS)) || [];
  const orgIsSuperAdmin = orgViews.includes('super-admin');

  return {
    // common
    companyInfo,
    groups: orgInfo?.groups || [],
    ownerOrgId,
    ownerOrgName,
    isCompanyInfoLoading,
    isTeamMembersTab,
    onTabClick,
    tabIdx,
    filter,
    setFilter,
    orgIsSuperAdmin,
    userOrgId,

    // team members
    fetchCompanyInfo,
    isTeamMembersLoaded,
    teamMembers,

    // invites
    invites: sortedInvites,
    isInviteFormLoading,
    isInvitesLoaded,
    isInvitesLoading,
    sendInvites,
    showSendInvitesDialog,
    openSendInvitesModal,
    closeSendInvitesModal,
  };
};

const useCompanyForm = ({ defaultData, ownerOrgId }) => {
  const [isEditModalOpen, setEditModalOpen] = useState(false);
  const [formData, setFormData] = useState(defaultData);
  const [formErrors, setErrors] = useState([]);
  const [showErrors, setShowErrors] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    setFormData(defaultData);
  }, [defaultData]);

  const { isLoading, isDataLoaded, isFailed, orgInfoLoaded } = useSelector(
    (state) => ({
      isLoading: state.updatingOrgInfo.isFetching,
      isDataLoaded: state.updatingOrgInfo.dataLoaded,
      isFailed: Boolean(state.updatingOrgInfo.error),
      orgInfoLoaded: state.orgInfo.dataLoaded,
    })
  );

  const resetCompanyInfoUpdating = () =>
    dispatch({ type: 'UPDATE_ORG_INFO_RESET' });

  const updateForm = useCallback((data) => setFormData(data), [formErrors]);

  const resetForm = useCallback(() => {
    setErrors([]);
    resetCompanyInfoUpdating();
  }, []);

  const openEditModal = useCallback((fieldToFocus) => {
    setEditModalOpen(true);
    focusFormField(fieldToFocus);
  }, []);

  const closeEditModal = useCallback(() => {
    setEditModalOpen(false);
    setShowErrors(false);
    resetForm();
  }, []);

  useEffect(() => {
    if (isDataLoaded) {
      closeEditModal();
    }
  }, [isDataLoaded]);

  const saveCompanyInfo = useCallback(() => {
    if (formErrors.length) {
      setShowErrors(true);
    } else {
      dispatch(
        orgInfoActions.updateOrgInfo(ownerOrgId, formCompanyInfoData(formData))
      );
    }
  }, [formData, formErrors, ownerOrgId]);

  return {
    errors: formErrors,
    formData,
    isDataLoaded,
    isEditModalOpen,
    isFailed,
    isLoading,
    openEditModal,
    orgInfoLoaded,
    closeEditModal,
    updateForm,
    setErrors,
    showErrors,
    saveCompanyInfo,
  };
};

export { useCompanyPage, useCompanyForm };
