import React from 'react';
import { makeStyles, Typography, Grid } from '@material-ui/core';
import { translate } from 'lib/intl';
import { ListItemContainer, Gap, Loader, Visible } from 'components';
import { string, bool } from 'prop-types';
import { LOADER_SIZES } from 'lib/constants';

const useStyles = makeStyles(() => ({
  titleSectionContainer: {
    paddingBottom: '4px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
}));

const VisitStreak = ({
  streakCountText,
  streakEncouragementText,
  isFetching,
}) => {
  const classes = useStyles();

  return (
    <ListItemContainer>
      <Visible
        when={!isFetching}
        fallback={<Loader size={LOADER_SIZES.SM} isCentered />}
      >
        <Grid container className={classes.titleSectionContainer}>
          <Typography variant="body1">{translate('VISIT_STREAK')}:</Typography>
          <Gap size={0.5} />
          <Typography variant="subtitle1">{streakCountText}</Typography>
        </Grid>
        <Grid container>
          <Typography variant="caption">{streakEncouragementText}</Typography>
        </Grid>
      </Visible>
    </ListItemContainer>
  );
};

VisitStreak.propTypes = {
  streakCountText: string,
  streakEncouragementText: string,
  isFetching: bool,
};

VisitStreak.defaultProps = {
  streakCountText: '',
  streakEncouragementText: '',
  isFetching: false,
};

export { VisitStreak };
