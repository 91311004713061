import { createTheme } from '@material-ui/core/styles';
import newColors from './newThemeColors';

const primaryBackground = '#fff';

/* Style guide specific colors */
const darkGreen = '#01562F';
const primaryGreen = '#00A85D';
const darkerGreenHover = '#008F4F';
const secondaryGreen = '#00D473';
const lightGreen = '#E1F9E5';
const darkerLightGreenHover = '#A6E7BA';
const darkerLightGreenPressed = '#7AD593';

// Used on button hover style
const mediumGreen = '#008F4F';

const black = '#000';
const darkGrey = '#666';
const lightGrey = '#ECECEC';
const offWhite = '#F8F9F9';
const red = '#FF0500';
const lightBlue = '#0094FF';
const green = '#5DD79F';

// Donor attribute icon fill colors
const boardMember = '#9029CF';
const majorDonor = '#DC0C70';
const recurring = '#DC0C70';
const automation = '#DC0C70';
const volunteer = '#2C68DD';
const member = '#EF870C';
const partner = '#6EC517';
const recipient = '#14AAEA';
const advocate = '#D42CB9';
const vip = '#EBB814';
/* End Style guide specific colors */

/* Let's move away from using the below styles going forward, or check with designer for usage */
const transparentGreen = 'rgba(0, 168, 93, 0.1)';
const grey300 = '#F8F9FA';
const grey500 = '#C4C4C4';
const transparentGrey = 'rgba(0, 0, 0, 0.12)';
const transparentBack = 'rgba(0, 0, 0, 0.4)';
const transparentBlack2 = 'rgba(0, 0, 0, 0.6)';
const transparentBlack3 = 'rgba(0, 0, 0, 0.8)';
const white = '#fff';
const errorMain = '#ED6257';
const transparentError = 'rgba(255, 12, 62, 0.5)';
const greyCustom2 = '#F3F5F5';
const transparentGreyCustom2 = 'rgba(243, 245, 245, 0.6)';
const greyCustom3 = '#696969';

const progressGreen = '#00A85D';
const progressDarkGrey = '#D5D5D5';
const progressLightGrey = '#E4E4E4';

const feedInteraction = '#FF7A00';
const feedAnnouncement = '#0038FF';
const feedDonation = '#FF0500';
const feedTrend = '#0256B7';
const feedCampaign = '#9319DD';

const scrollbarColor = '#C4C4CC';

const { adminColor } = newColors;
/* End non-official styles */

const theme = createTheme({
  props: {
    MuiButton: {
      color: 'primary',
      disableRipple: true,
    },
    secondaryButtonClass: 'MuiButton-customSecondary',
    tertiaryButtonClass: 'MuiButton-customTertiary',
    MuiLink: {
      underline: 'none',
    },
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        html: {
          height: '100%',
        },
        body: {
          backgroundColor: primaryBackground,
          height: '100%',
        },
        '#root': {
          height: '100%',
          overflowX: 'hidden',
        },
        '.MuiTab-wrapper': {
          textTransform: 'capitalize',
        },
        '.MuiTabs-flexContainer': {
          borderBottom: `1px solid ${transparentGrey}`,
        },
      },
    },
    MuiAlert: {
      root: {
        fontWeight: 500,
      },
    },
    MuiLinearProgress: {
      root: {
        height: '16px',
        borderRadius: '8px',
        zIndex: 1,
      },
      bar: {
        borderRadius: '8px',
      },
      // background styles
      dashed: {
        animation: 'none',
        backgroundImage: 'none !important',
      },
      dashedColorPrimary: {
        backgroundColor: lightGrey,
      },
      dashedColorSecondary: {
        backgroundColor: grey300,
      },
      // current progress styles
      barColorPrimary: {
        backgroundColor: newColors.primary.main,
      },
      barColorSecondary: {
        backgroundColor: darkGrey,
      },
      // expected progress styles
      colorPrimary: {
        backgroundColor: grey500,
      },
      colorSecondary: {
        backgroundColor: grey500,
      },
    },
    MuiLink: {
      root: {
        color: newColors.primaryLink.main,
        cursor: 'pointer',
        fontWeight: 500,
        textTransform: 'initial',
        '&:hover': {
          color: newColors.primaryLink.light,
        },
        '&:focus': {
          color: newColors.primaryLink.light,
        },
        '&:active': {
          color: newColors.primaryLink.dark,
        },
        '&:disabled': {
          color: newColors.darkGrey,
        },
      },
    },
    MuiButton: {
      root: {
        textTransform: 'capitalize',
      },
      label: {
        fontWeight: 500,
        '& > *': {
          fontWeight: 500,
        },
      },
      contained: {
        boxShadow: 'none',
        '&$disabled': {
          color: newColors.darkGrey,
          backgroundColor: newColors.grey[100],
        },
        '&:hover': {
          boxShadow: 'none',
        },
        '&:hover&$disabled': {
          backgroundColor: newColors.grey[100],
        },
      },
      containedPrimary: {
        backgroundColor: newColors.primaryButton.main,
        color: newColors.primaryButton.contrastText,
        '&:hover': {
          backgroundColor: newColors.primaryButton.light,
        },
        '&:focus': {
          backgroundColor: newColors.primaryButton.light,
        },
        '&:active': {
          backgroundColor: newColors.primaryButton.dark,
        },
      },
      containedSecondary: {
        backgroundColor: newColors.secondaryButton.main,
        color: newColors.secondaryButton.contrastText,
        '&:hover': {
          backgroundColor: newColors.secondaryButton.light,
        },
        '&:focus': {
          backgroundColor: newColors.secondaryButton.light,
        },
        '&:active': {
          backgroundColor: newColors.secondaryButton.dark,
        },
      },
      text: {
        '&:disabled': {
          color: newColors.darkGrey,
          backgroundColor: 'transparent',
        },
        '&:hover&$disabled': {
          backgroundColor: 'transparent',
        },
      },
      textPrimary: {
        color: newColors.primaryLink.main,
        '&:hover': {
          backgroundColor: 'transparent',
          color: newColors.primaryLink.light,
        },
        '&:focus': {
          color: newColors.primaryLink.light,
        },
        '&:active': {
          color: newColors.primaryLink.dark,
        },
      },
    },
    MuiInput: {
      underline: {
        '&.Mui-disabled:before': {
          borderBottom: `1px solid ${newColors.darkGrey}`,
          opacity: 0.5,
        },
      },
    },
    MuiInputBase: {
      root: {
        letterSpacing: '0.15px',
      },
      input: {
        lineHeight: 1.5,
        marginTop: '3px',
      },
    },
    MuiFilledInput: {
      root: {
        backgroundColor: `${newColors.grey[100]} !important`,
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
        transition: 'none',

        '&:hover': {
          backgroundColor: `${newColors.grey[100]} !important`,
        },
        '&$focused': {
          backgroundColor: `${newColors.grey[100]} !important`,
        },
      },
    },
    MuiFormHelperText: {
      contained: {
        marginLeft: 0,
        marginRight: 0,
      },
    },
    MuiPaginationItem: {
      rounded: {
        color: newColors.primaryLink.main,
        '&$selected': {
          backgroundColor: newColors.primaryLink.dark,
          color: newColors.primary.contrastText,

          '&:hover': {
            backgroundColor: newColors.primary.main,
          },
        },
      },
    },
    MuiSelect: {
      select: {
        '&:focus': {
          backgroundColor: 'transparent',
        },
      },
    },
  },
  palette: {
    primary: {
      main: newColors.primary.main,
    },
    secondary: {
      main: newColors.secondary.main,
    },
    grey: {
      300: grey300,
      500: grey500,
      light: lightGrey,
      dark: darkGrey,
      transparent: transparentGrey,
      ...newColors.grey,
    },
    green: {
      light: lightGreen,
    },
    text: {
      primary: black,
      secondary: transparentBack,
    },
    error: {
      main: errorMain,
      transparentError,
    },
    background: {
      offWhite,
      light: 'rgba(243, 245, 245, 0.6)',
      white,
      superAdmin: adminColor,
    },
    donorAttributes: {
      automation,
      boardMember,
      majorDonor,
      recurring,
      volunteer,
      member,
      partner,
      recipient,
      advocate,
      vip,
    },
    progressBar: {
      darkGrey: progressDarkGrey,
      lightGrey: progressLightGrey,
    },
    primaryLink: newColors.primaryLink,
    primaryButton: newColors.primaryButton,
    secondaryButton: newColors.secondaryButton,
  },
  /* Typography as per styleguide */
  typography: {
    color: black,
    fontFamily: ['Poppins', 'Roboto'],
    h1: {
      fontSize: '96px',
      fontWeight: '300',
      letterSpacing: '-1.5px',
    },
    h2: {
      fontSize: '60px',
      fontWeight: '300',
      letterSpacing: '-0.5px',
    },
    h3: {
      fontSize: '48px',
      fontWeight: '400',
      letterSpacing: '0px',
    },
    h4: {
      fontSize: '34px',
      fontWeight: '400',
      letterSpacing: '0px',
    },
    h5: {
      fontSize: '24px',
      fontWeight: '600',
      letterSpacing: '0px',
    },
    h6: {
      fontSize: '24px',
      fontWeight: '400',
      letterSpacing: '0px',
    },
    // h7 is an invalid variant
    h7: {
      fontSize: '20px',
      fontWeight: '500',
      letterSpacing: '0.15px',
    },
    subtitle1: {
      fontSize: '16px',
      fontWeight: '600',
      letterSpacing: '0.15px',
    },
    subtitle2: {
      fontSize: '16px',
      fontWeight: '400',
      letterSpacing: '0.1px',
    },
    subtitle3: {
      fontSize: '14px',
      fontWeight: '500',
      letterSpacing: '0.1px',
    },
    body1: {
      fontSize: '16px',
      fontWeight: '400',
      letterSpacing: '0.5px',
    },
    body2: {
      fontSize: '14px',
      fontWeight: '400',
      letterSpacing: '0.5px',
    },
    button: {
      fontSize: '16px',
      fontWeight: '500',
      letterSpacing: '0.75px',
    },
    caption: {
      fontSize: '12px',
      fontWeight: '400',
      letterSpacing: '0.25px',
    },
    /*
    // Currently only supports 'caption' not variations of caption
    // If a second caption variation is used, make custom class with styles
    caption1: {
      fontSize: '12px',
      fontWeight: '600',
      letterSpacing: '0.25px',
    },
    caption2: {
      fontSize: '12px',
      fontWeight: '400',
      letterSpacing: '0.25px',
    },
    */
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 780,
      lg: 1280,
      xl: 1920,
    },
  },
  shape: {
    borderRadius: ['8px'],
  },
});

const colors = {
  primaryBackground,
  primaryGreen,
  secondaryGreen,
  grey300,
  grey500,
  greyCustom2,
  transparentGreyCustom2,
  greyCustom3,
  darkGrey,
  lightGrey,
  red,
  lightBlue,
  green,
  transparentGrey,
  transparentBack,
  transparentGreen,
  black,
  errorMain,
  transparentError,
  white,
  progressGreen,
  progressDarkGrey,
  progressLightGrey,
  feedInteraction,
  feedAnnouncement,
  feedDonation,
  feedTrend,
  feedCampaign,
  lightGreen,
  mediumGreen,
  darkGreen,
  offWhite,
  transparentBlack2,
  transparentBlack3,
  darkerGreenHover,
  darkerLightGreenHover,
  darkerLightGreenPressed,
  scrollbarColor,
  boardMember,
  majorDonor,
  recurring,
  volunteer,
  member,
};

export { theme, colors };
