import { getCompanyTeamMemberState } from 'lib/utils';
import userActionTypes from 'rdx/actionTypes';

const {
  UPDATE_COMPANY_TEAM_MEMBERS_REQUEST,
  UPDATE_COMPANY_TEAM_MEMBERS_SUCCESS,
  UPDATE_COMPANY_TEAM_MEMBERS_FAILURE,
  REMOVE_COMPANY_TEAM_MEMBERS_REQUEST,
  REMOVE_COMPANY_TEAM_MEMBERS_SUCCESS,
  REMOVE_COMPANY_TEAM_MEMBERS_FAILURE,
} = userActionTypes;

const initialState = {};

export function updatingCompanyTeamMembers(state = initialState, action) {
  switch (action.type) {
    case UPDATE_COMPANY_TEAM_MEMBERS_REQUEST:
    case REMOVE_COMPANY_TEAM_MEMBERS_REQUEST:
      return {
        ...state,
        ...getCompanyTeamMemberState(action, {
          dataLoaded: false,
          isFetching: true,
          error: null,
        }),
      };
    case UPDATE_COMPANY_TEAM_MEMBERS_SUCCESS:
    case REMOVE_COMPANY_TEAM_MEMBERS_SUCCESS:
      return {
        ...state,
        ...getCompanyTeamMemberState(action, {
          data: action.data,
          dataLoaded: true,
          isFetching: false,
          error: null,
        }),
      };
    case UPDATE_COMPANY_TEAM_MEMBERS_FAILURE:
    case REMOVE_COMPANY_TEAM_MEMBERS_FAILURE:
      return {
        ...state,
        ...getCompanyTeamMemberState(action, {
          isFetching: false,
          error: action.error || 'error',
        }),
      };
    default:
      return state;
  }
}
