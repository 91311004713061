import { translate } from 'lib/intl';
import { createAction, showSuccessMessage, showErrorMessage } from 'lib/utils';
import { donationsService } from 'services/donations';
import userActionTypes from 'rdx/actionTypes';
import { transformConstituentDonation } from 'lib/utils/constituentDataTransformers';

const {
  DONATION_REQUEST,
  DONATION_SUCCESS,
  DONATION_FAILURE,
  DONATION_CREATE_REQUEST,
  DONATION_CREATE_SUCCESS,
  DONATION_CREATE_FAILURE,
  DONATION_CREATE_RESET,
  DONATION_DELETE_REQUEST,
  DONATION_DELETE_SUCCESS,
  DONATION_DELETE_FAILURE,
} = userActionTypes;

const fetchDonation = (donationId) => async (dispatch) => {
  try {
    dispatch(createAction({ type: DONATION_REQUEST }));

    const response = await donationsService.fetchDonation(donationId);

    dispatch(
      createAction({
        type: DONATION_SUCCESS,
        data: response?.data || {},
      })
    );
  } catch (error) {
    dispatch(
      createAction({
        type: DONATION_FAILURE,
        error,
      })
    );
  }
};

const createDonation = (donationData, isTimeType) => async (dispatch) => {
  try {
    dispatch(createAction({ type: DONATION_CREATE_REQUEST }));

    const response = await donationsService.createDonation(
      transformConstituentDonation(donationData),
      isTimeType
    );

    dispatch(
      createAction({
        type: DONATION_CREATE_SUCCESS,
        data: response?.data || {},
      })
    );

    showSuccessMessage({
      message: translate('DONATION_CREATION_SUCCESS'),
    });
  } catch (error) {
    showErrorMessage({
      message: translate('DONATION_CREATION_FAILURE'),
    });

    dispatch(
      createAction({
        type: DONATION_CREATE_FAILURE,
        error,
      })
    );
  }
};

const deleteDonation = (donationId) => async (dispatch) => {
  try {
    dispatch(createAction({ type: DONATION_DELETE_REQUEST }));
    await donationsService.deleteDonation(donationId);
    dispatch(createAction({ type: DONATION_DELETE_SUCCESS, donationId }));
    showSuccessMessage({
      message: translate('DONATION_DELETE_SUCCESS'),
    });
  } catch (error) {
    showErrorMessage({
      message: translate('DONATION_DELETE_FAILURE'),
    });
    dispatch(createAction({ type: DONATION_DELETE_FAILURE, error }));
  }
};
const resetDonationForm = () => (dispatch) => {
  dispatch(createAction({ type: DONATION_CREATE_RESET }));
};

export const donationActions = {
  createDonation,
  deleteDonation,
  fetchDonation,
  resetDonationForm,
};
