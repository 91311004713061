import { camelCase } from 'voca';
import PageSizes from 'lib/constants/PageSizes';
import { DONOR_LIST } from 'lib/constants/reports';
import { createAction } from 'lib/utils';
import { reportsService } from 'services/reportsService';

export const fetchReportTypes = {
  REPORT_LIST_REQUEST: 'REPORTS_LIST_REQUEST',
  REPORT_LIST_SUCCESS: 'REPORTS_LIST_SUCCESS',
  REPORT_LIST_FAILURE: 'REPORTS_LIST_FAILURE',
};

export const fetchReport =
  ({ reportType, moduleType, page, sortDirection, sortField, ...rest } = {}) =>
  async (dispatch, getState) => {
    try {
      const { ownerOrgId } = getState().authentication;
      dispatch(createAction({ type: fetchReportTypes.REPORT_LIST_REQUEST }));

      const pageParams =
        moduleType === DONOR_LIST
          ? {
              pageFrom: (page - 1) * PageSizes[0] || 0,
              pageSize: PageSizes[0],
              sortDirection,
              sortField,
            }
          : {};
      const response = await reportsService.fetchReport({
        ownerOrgId,
        reportType,
        moduleType,
        ...pageParams,
        ...rest,
      });

      const data = {
        [camelCase(moduleType)]: { ...response?.data },
      };

      dispatch(
        createAction({
          type: fetchReportTypes.REPORT_LIST_SUCCESS,
          data,
        })
      );

      return response;
    } catch (err) {
      dispatch(
        createAction({
          type: fetchReportTypes.REPORT_LIST_FAILURE,
          err,
        })
      );

      return err;
    }
  };

export default {
  fetchReport,
  fetchReportTypes,
};
