import React from 'react';
import { bool, func } from 'prop-types';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Modal from '@material-ui/core/Modal';
import Typography from '@material-ui/core/Typography';
import { CustomButton } from 'components/common/custom-button';
import ImageIcon from 'components/common/ImageIcon';
import { ICON_SIZES } from 'lib/constants';
import { translate } from 'lib/intl';
import { isMobileView } from 'lib/utils';
import { useStyles } from './integrationPageStyles';

export default function CredentialsConfirmRemoveModal({
  open,
  handleClose,
  handleRemove,
  setOpen,
}) {
  const isMobile = isMobileView();
  const styles = useStyles();
  return (
    <Modal open={open} onClose={handleClose}>
      <Box className={styles.credentialsModal}>
        <Box className={styles.credentialsModalHeaderContainer}>
          <Typography variant="body1" className={styles.credentialsModalHeader}>
            {translate('INTEGRATIONS_CONFIRM_HEADER')}
          </Typography>
          <Button onClick={handleClose}>
            <ImageIcon
              width={ICON_SIZES.SM}
              height={ICON_SIZES.SM}
              type="close"
              alt={translate('MULTI_ACTION_CLOSE')}
            />
          </Button>
        </Box>
        <Box className={styles.line} />
        <Typography
          variant="body1"
          className={styles.credentialsModalDescription}
        >
          {translate('INTEGRATIONS_CONFIRM_DESCRIPTION')}
        </Typography>
        <Box className={styles.line} />
        <Box className={styles.credentialsModalButtonContainer}>
          <CustomButton
            id="credentials-remove-cancel"
            classes={{ root: styles.credentialsButton }}
            onClick={handleClose}
            buttonType="primary"
            fullWidth={isMobile}
            className={styles.credentialsModalCancelButton}
            label={translate('INTEGRATIONS_CANCEL')}
          />
          <CustomButton
            id="credentials-remove-confirm"
            classes={{ root: styles.credentialsButton }}
            onClick={() => {
              handleRemove();
              setOpen(false);
            }}
            buttonType="primary"
            fullWidth={isMobile}
            label={translate('INTEGRATIONS_REMOVE')}
          />
        </Box>
      </Box>
    </Modal>
  );
}

CredentialsConfirmRemoveModal.propTypes = {
  open: bool.isRequired,
  handleClose: func.isRequired,
  handleRemove: func.isRequired,
  setOpen: func.isRequired,
};
