import PropTypes from 'prop-types';

export const ConstituentType = Object.freeze({
  ORG: 'org',
  PRS: 'prs',
});

const ConstituentPropType = PropTypes.shape({
  categoryBoardMember: PropTypes.bool,
  categoryCorporation: PropTypes.bool,
  categoryFoundation: PropTypes.bool,
  categoryMember: PropTypes.bool,
  categoryPartner: PropTypes.bool,
  categoryRecipient: PropTypes.bool,
  categoryVolunteer: PropTypes.bool,
  constituentId: PropTypes.string,
  constituentType: PropTypes.oneOf(Object.values(ConstituentType)),
  displayName: PropTypes.string,
  isDonorRuleset: PropTypes.bool,
  isMajorDonorRuleset: PropTypes.bool,
  isRecurringDonorRuleset: PropTypes.bool,
  kanbanCategory: PropTypes.number,
  kanbanPriority: PropTypes.number,
  kanbanView: PropTypes.bool,
  labels: PropTypes.arrayOf(PropTypes.string),
});

export default ConstituentPropType;
