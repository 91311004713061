import React, { useEffect, useState } from 'react';
import Box from '@material-ui/core/Box';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import env from '@beam-australia/react-env';
import { useForm } from 'react-hook-form';
import { translate } from 'lib/intl';
import { isMobileView } from 'lib/utils';
import { ControlledTextField } from 'components/common/form';
import { CustomButton } from 'components/common/custom-button';
import ImageIcon from 'components/common/ImageIcon';
import { ICON_SIZES } from 'lib/constants';
import { useStyles } from './integrationPageStyles';
import { useIntegrationPage } from './integrationPageHooks';
import CredentialsLoader from './CredentialsLoader';
import CredentialsConfirmRemoveModal from './CredentialsConfirmRemoveModal';
import CredentialsSuccessMessage from './CredentialsSuccessMessage';

const ClassyCredentials = () => {
  const isMobile = isMobileView();
  const styles = useStyles();
  const {
    classyIntegration,
    keySaved,
    handleRemove,
    handleSave,
    hasClassyCredentials,
    integrationId,
    isLoading,
  } = useIntegrationPage();
  const helpLinkAddress = env('HELP_LINK_ADDRESS');

  const defaultValues = {
    organizationId: '',
    clientId: '',
    clientSecret: '',
  };

  const { control, handleSubmit, setValue, errors, watch } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues,
  });

  const [disableFormFields, setDisableFormFields] =
    useState(hasClassyCredentials);
  const [isEditing, setIsEditing] = useState(false);
  const [open, setOpen] = useState(false);

  const values = watch();

  useEffect(() => {
    if (hasClassyCredentials) {
      setValue('organizationId', classyIntegration.organizationId);
      setValue('clientId', classyIntegration.clientId);
      setValue('clientSecret', '****************');
      setDisableFormFields(true);
    } else {
      setDisableFormFields(false);
    }
  }, [hasClassyCredentials, classyIntegration]);

  const disableSaveButton =
    Object.keys(errors).length > 0 ||
    !values?.organizationId ||
    !values?.clientId ||
    !values?.clientSecret;

  const handleFormSubmit = (formData) => {
    handleSave(formData);
  };
  const editFields = (edit) => {
    setIsEditing(edit);
    setValue('clientSecret', edit ? '' : '****************');
    setDisableFormFields(!edit);
  };
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (keySaved) {
      setIsEditing(false);
    }
  }, [keySaved]);

  const classyCredentials = [
    {
      iconType: 'building',
      id: 'integrations-classy-org-id',
      name: 'organizationId',
      label: translate('INTEGRATIONS_CLASSY_ORG_ID'),
      type: '',
      disabled:
        disableFormFields ||
        (isEditing && values?.organizationId && values?.clientId),
    },
    {
      iconType: 'key',
      id: 'integrations-classy-client-id',
      name: 'clientId',
      label: translate('INTEGRATIONS_CLASSY_CLIENT_ID'),
      type: 'password',
      disabled: disableFormFields,
    },
    {
      iconType: 'secret',
      id: 'integrations-classy-client-secret',
      name: 'clientSecret',
      label: translate('INTEGRATIONS_CLASSY_CLIENT_SECRET'),
      type: 'password',
      disabled: disableFormFields,
    },
  ];

  if (isLoading) return <CredentialsLoader integrationId={integrationId} />;

  return (
    <Box className={styles.infoBox}>
      <form noValidate>
        <Box p={isMobile ? 1.5 : 3}>
          <Typography className={styles.credentialsHeading}>
            {translate('INTEGRATIONS_CLASSY_HEADING')}
          </Typography>
          {keySaved ? <CredentialsSuccessMessage /> : null}
          {classyCredentials.map((credential) => (
            <Box className={styles.credentialContainer} key={credential.id}>
              <ImageIcon
                className={styles.credentialIcon}
                width={ICON_SIZES.LG}
                height={ICON_SIZES.LG}
                type={credential.iconType}
                alt={credential.iconType}
              />
              <ControlledTextField
                control={control}
                name={credential.name}
                id={credential.id}
                label={credential.label}
                fullWidth
                disabled={credential.disabled}
                InputProps={
                  disableFormFields ? { disableUnderline: true } : null
                }
                className={disableFormFields ? 'has-credentials' : ''}
                type={disableFormFields ? credential.type : ''}
                error={!!errors[credential.name]}
                helperText={
                  errors[credential.name] ? errors[credential.name].message : ''
                }
                required
                rules={{
                  required: 'Required',
                  pattern: {
                    value: /^\S*$/,
                    message: 'This field cannot contain spaces',
                  },
                }}
              />
            </Box>
          ))}
        </Box>
        <Box className={styles.credentialsHelperText}>
          <Typography variant={isMobile ? 'body2' : 'body1'}>
            {translate('INTEGRATIONS_NEED_HELP')}{' '}
            <Link href={`mailto:${helpLinkAddress}`}>{helpLinkAddress}</Link>.
          </Typography>
        </Box>
        <Box className={styles.line} />
        <Box className={styles.credentialsButtonContainer}>
          {hasClassyCredentials && !isEditing ? (
            <CustomButton
              id="credentials-remove"
              classes={{ root: styles.credentialsButton }}
              buttonType="error"
              fullWidth={isMobile}
              label={translate('INTEGRATIONS_REMOVE_CONNECTION')}
              onClick={handleOpen}
            />
          ) : null}
          {hasClassyCredentials && isEditing ? (
            <CustomButton
              id="credentials-edit-cancel"
              classes={{ root: styles.credentialsButton }}
              buttonType="primary"
              fullWidth={isMobile}
              label={translate('INTEGRATIONS_CANCEL')}
              className={styles.credentialsModalCancelButton}
              onClick={() => {
                editFields(false);
              }}
            />
          ) : null}
          {disableFormFields ? (
            <CustomButton
              id="credentials-edit"
              classes={{ root: styles.credentialsButton }}
              buttonType="primary"
              fullWidth={isMobile}
              label={translate('INTEGRATIONS_EDIT')}
              onClick={() => {
                editFields(true);
              }}
            />
          ) : (
            <CustomButton
              id="credentials-save"
              disabled={disableSaveButton}
              classes={{ root: styles.credentialsButton }}
              buttonType="primary"
              fullWidth={isMobile}
              label={
                !isEditing
                  ? translate('INTEGRATIONS_SAVE')
                  : translate('INTEGRATIONS_UPDATE')
              }
              onClick={handleSubmit(handleFormSubmit)}
            />
          )}
        </Box>
      </form>
      <CredentialsConfirmRemoveModal
        open={open}
        handleClose={handleClose}
        handleRemove={handleRemove}
        setOpen={setOpen}
      />
    </Box>
  );
};

export default ClassyCredentials;
