import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Alert from '@material-ui/lab/Alert';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/styles/makeStyles';
import { useHistory, Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import LoadingButton from 'components/common/LoadingButton';
import { PUBLIC_ROUTES } from 'lib/constants';
import { translate } from 'lib/intl';
import { emailRegex, userNotConfirmedError } from './authConstants';

const { VERIFICATION, RESET_PASSWORD } = PUBLIC_ROUTES;

const useStyles = makeStyles((theme) => ({
  forgotPasswordLink: {
    ...theme.typography.subtitle2,
    letterSpacing: '0.25px',
    textDecoration: 'none',
    color: theme.palette.grey[900],
    paddingTop: theme.spacing(0.5),
  },
  submitButton: {
    paddingLeft: theme.spacing(8),
    paddingRight: theme.spacing(8),
  },
}));

const LoginForm = ({ isFetching, error, loginAction, clearLoginErrors }) => {
  const classes = useStyles();
  const history = useHistory();
  const { register, handleSubmit, errors } = useForm();

  const [submittedOrgEmail, setSubmittedOrgEmail] = useState();
  const [password, setPassword] = useState({
    value: '',
    showPassword: false,
  });

  if (error && error.code === userNotConfirmedError) {
    clearLoginErrors();
    history.push(
      `${VERIFICATION}?username=${encodeURIComponent(submittedOrgEmail)}`
    );
  }

  const handlePasswordChange = () => (event) => {
    clearLoginErrors();
    setPassword({
      ...password,
      value: event.target.value,
    });
  };

  const handleClickShowPassword = () => {
    setPassword({ ...password, showPassword: !password.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleFormSubmit = (data) => {
    clearLoginErrors();

    const { orgEmail, password: enteredPassword } = data;
    setSubmittedOrgEmail(orgEmail);

    return loginAction(orgEmail, enteredPassword);
  };

  return (
    <Box data-testid="login-form" display="flex" flexDirection="column">
      <Box pb={2} display="flex">
        <Typography variant="h1">{translate('SIGN_IN')}</Typography>
      </Box>
      <Box display="flex" flexDirection="column">
        <Collapse in={!!error}>
          <Box pb={2}>
            <Alert severity="error">{error?.message}</Alert>
          </Box>
        </Collapse>

        <form onSubmit={handleSubmit(handleFormSubmit)}>
          <TextField
            fullWidth
            error={!!errors.orgEmail}
            helperText={errors.orgEmail ? errors.orgEmail.message : ''}
            name="orgEmail"
            label={translate('LOGIN_EMAIL_LABEL')}
            onChange={clearLoginErrors}
            inputRef={register({
              required: translate('VALIDATION_ORG_EMAIL_REQUIRED'),
              pattern: {
                // eslint-disable-next-line
                value: emailRegex,
                message: translate('VALIDATION_INVALID_EMAIL'),
              },
            })}
          />

          <TextField
            fullWidth
            error={!!errors.password}
            helperText={errors.password ? errors.password.message : ''}
            id="outlined-adornment-password"
            inputRef={register({
              required: {
                value: true,
                message: translate('VALIDATION_PASSWORD_REQUIRED'),
              },
            })}
            InputProps={{
              endAdornment: (
                <InputAdornment>
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {password.showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            label={translate('PASSWORD')}
            name="password"
            onChange={handlePasswordChange('password')}
            type={password.showPassword ? 'text' : 'password'}
            value={password.value}
          />

          <Box display="flex" justifyContent="flex-end" width="100%">
            <Link
              to={{ pathname: RESET_PASSWORD }}
              className={classes.forgotPasswordLink}
            >
              {translate('FORGOT_PASSWORD')}
            </Link>
          </Box>

          <Box textAlign="center" mt={4} width="100%">
            <LoadingButton
              className={classes.submitButton}
              isLoading={isFetching}
              type="submit"
              variant="contained"
            >
              {translate('SIGN_IN')}
            </LoadingButton>
          </Box>
        </form>
      </Box>
    </Box>
  );
};

LoginForm.propTypes = {
  isFetching: PropTypes.bool.isRequired,
  loginAction: PropTypes.func.isRequired,
  clearLoginErrors: PropTypes.func.isRequired,
  error: PropTypes.shape({ code: {}, message: {} }),
};

LoginForm.defaultProps = {
  error: null,
};

export { LoginForm };
