const sources = ['paypal', 'stripe', 'classy'];

const ids = ['abc', 'def', 'hij'];

function mockDonationData({
  numOfRows = 20,
  startDate = new Date(),
  numOfDays = 30,
} = {}) {
  const data = [];
  const endDate = new Date(startDate);
  endDate.setDate(endDate.getDate() + numOfDays);

  for (let i = 0; i < numOfRows; i += 1) {
    data.push({
      id: ids[Math.floor(Math.random() * 3)],
      source: sources[Math.floor(Math.random() * 3)],
      date: new Date(
        +startDate + Math.random() * (endDate - startDate)
      ).toISOString(),
      donation: Math.floor(Math.random() * 124) + 1,
    });
  }

  data.sort((a, b) => {
    if (a.date > b.date) {
      return 1;
    }

    if (a.date < b.date) {
      return -1;
    }

    return 0;
  });

  return {
    report: 'donation-performance',
    type: 'timeseries',
    data,
  };
}

export { mockDonationData };
