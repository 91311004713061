import React from 'react';
import { Box, Typography, makeStyles, Checkbox } from '@material-ui/core';
import { Star, StarBorder } from '@material-ui/icons';
import { bool, string, func } from 'prop-types';
import { colors } from 'styles/theme';
import { EMPTY_FUNC } from 'lib/constants';

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    width: '100%',
    height: '56px',
    padding: '0 7px 0 16px',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottom: `1px solid ${colors.lightGrey}`,
  },
}));

const ConstituentCardTeamMemberRowItem = ({
  teamMemberId,
  title,
  primary,
  setPrimary,
  secondary,
  setSecondary,
}) => {
  const styles = useStyles();

  const handlePrimaryChange = () => {
    setPrimary(teamMemberId, !primary);
  };

  const handleSecondaryChange = () => {
    // Only alter secondary state if the developer is not primary
    // Primary state already forces secondary state
    if (!primary) {
      setSecondary(teamMemberId, !secondary);
    }
  };

  return (
    <Box className={styles.container}>
      <Typography variant="body1">@{title}</Typography>
      <Box>
        <Checkbox
          data-testid="constituent-card-team-member-primary-checkbox"
          checked={primary}
          icon={<StarBorder />}
          checkedIcon={<Star />}
          color="primary"
          name="primary"
          onChange={handlePrimaryChange}
        />
        <Checkbox
          data-testid="constituent-card-team-member-secondary-checkbox"
          // Secondary checked if developer is secondary OR primary
          checked={secondary || primary}
          color="primary"
          name="secondary"
          onChange={handleSecondaryChange}
          inputProps={{ 'aria-label': 'primary checkbox' }}
        />
      </Box>
    </Box>
  );
};

ConstituentCardTeamMemberRowItem.propTypes = {
  teamMemberId: string.isRequired,
  title: string.isRequired,
  primary: bool,
  setPrimary: func,
  secondary: bool,
  setSecondary: func,
};

ConstituentCardTeamMemberRowItem.defaultProps = {
  primary: false,
  setPrimary: EMPTY_FUNC,
  secondary: false,
  setSecondary: EMPTY_FUNC,
};

export default ConstituentCardTeamMemberRowItem;
