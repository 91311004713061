export const COMMON = {
  APP_NAME: 'DotOrg',
  GO_BACK: 'Back',
  ALL: 'All',
  YES: 'Yes',
  NO: 'No',
  THANK_YOU: 'Thank you',
  APPLY: 'Apply',
  FILTER: 'Filter',
  SUBMIT: 'Submit',
  CONTACT_US: 'contact us',
  LAST_LABEL: 'Last',
  LAST_YEAR_LABEL: 'Last Year',
  THIS_YEAR_LABEL: 'This Year',
  THIS_YEAR_TOTAL_LABEL: 'This Year Total',
  LIFETIME_LABEL: 'Lifetime',
  LIFETIME_TOTAL_LABEL: 'Lifetime Total',
  REFERRED_BY_LABEL: 'Referred by:',
  CAPACITY_LABEL: 'Cap',
  PLANNED_LABEL: 'Planned',
  SHOW_MORE_OPTIONS_LABEL: 'Show More Options',
  SHOW_LESS_OPTIONS_LABEL: 'Show Less Options',
  PINNED: 'Pinned',
  GENERIC_ERROR: 'An error occurred.',
  LAST_VISIT: 'Last visit: {when}',
  NA: 'N/A',
  FIRST: 'First',
  LAST: 'Last',
  MIDDLE: 'Middle',
  REQUIRED: 'Required',
  BAD: 'Bad',
  NOT_GOOD: 'Not good',
  OK: 'Ok',
  GOOD: 'Good',
  GREAT: 'Great',
  NONE: 'None',
  HOUR: 'h',
  HOURS: 'hrs',
  EXPAND: 'Expand',
  COLLAPSE: 'Collapse',
  COMPLETED: 'Completed',
  DAYS: 'days',
  DAY: 'day',
  MONTH: 'month',
  MONTHS: 'months',
  EXPORT: 'Export',
  LEFT: 'left',
  DAYS_LEFT: '{number} days left',
  ADDED_DATE: 'Added {date}',
  LAST_EDITED_DATE: 'Last Edited {date}',
  CHARACTERS: 'Characters',
  SEARCH: 'Search',
  NAME: 'Name',
  CATEGORIES: 'Categories',
  ASSIGNEE: 'Assignee',
  LAST_INTERACTION: 'Last Interaction',
  ACTIONS: 'Actions',
  SHOW_MORE: 'Show More',
  COPIED: 'Copied',
  PER_PAGE: 'Per Page',
  ADD_ITEM: 'Add {item}',
};
