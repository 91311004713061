import { values } from 'rambdax';
import { LABELS } from 'lib/constants';
import { formatUrl } from 'lib/utils/urls';
import { replaceEmptyValues } from 'lib/utils/replaceEmptyValues';
import { CONPANY_PAGE_TAB_IDX_MAPPER } from './companyPageConfig';

const getCompanyPageTabId = ({ pathname }) =>
  CONPANY_PAGE_TAB_IDX_MAPPER.findIndex((locations) =>
    locations.includes(pathname)
  );

const withoutPrimary = (list) => list?.filter(({ primary }) => !primary) || [];

const formCompanyInfoData = (formData = {}) => {
  const { ein, orgName, primaryAddress, primaryPhone } = formData;

  const addresses = withoutPrimary(formData?.addresses);
  // checks if an address is not empty before adding it
  const {
    label = LABELS.WORK,
    primary = true,
    ...addressProps
  } = primaryAddress || {};

  if (primaryAddress && values(addressProps)?.filter((val) => val)?.length) {
    addresses.push({
      ...replaceEmptyValues(primaryAddress),
      label,
      primary,
    });
  }

  const website = formatUrl(formData?.website) || null;

  return {
    addresses,
    ein,
    orgName,
    primaryPhone,
    website,
  };
};

export { getCompanyPageTabId, formCompanyInfoData };
