import React from 'react';
import PropTypes from 'prop-types';
import ConstituentLabelSelect from 'components/filters/ConstituentLabelSelect';
import ConstituentPropType from 'lib/types/ConstituentPropType';
import { EMPTY_FUNC } from 'lib/constants';
import { useCurrentUser } from 'lib/hooks/user-data';

const EditConstituentLabels = ({ data, updateData }) => {
  const { isOrgAdmin, isOrgEditor } = useCurrentUser(true);

  const handleChange = (e) => {
    const { name, value } = e.target;
    updateData({
      ...data,
      [name]: value,
    });
  };
  return (
    <ConstituentLabelSelect
      className="pt-6"
      onChange={handleChange}
      value={data?.labels || []}
      freeSolo={isOrgAdmin || isOrgEditor}
    />
  );
};

EditConstituentLabels.propTypes = {
  data: ConstituentPropType.isRequired,
  updateData: PropTypes.func,
};

EditConstituentLabels.defaultProps = {
  updateData: EMPTY_FUNC,
};

export default EditConstituentLabels;
