const FORMS = {
  FORMS_MAX_DONATION_LABEL: 'Max Donation',
  FORMS_MIN_DONATION_LABEL: 'Min Donation',
  FORMS_DONOR_ATTRIBUTES_LABEL: 'Donor Attributes',
  FORMS_DONOR_TYPE_LABEL: 'Donor Type',
  FORMS_DONOR_TYPE_OPTION_INDIVIDUAL: 'Individual',
  FORMS_DONOR_TYPE_OPTION_ORGANIZATION: 'Organization',
  FORMS_SUBMIT_CREATE: 'Create',
  FORMS_BUTTON_CANCEL: 'Cancel',
  FORMS_EIN_LABEL: 'Employee ID Number',
  FORMS_ORG_PHONE_LABEL: 'Organization Phone Number',
  FORMS_TOGGLE_NO_EIN: 'No EIN?',
  FORMS_TOGGLE_USE_EIN: 'Use EIN',
  FORMS_VALIDATION_REQUIRED: 'Required Field',
  FORMS_VALIDATION_FORMAT_EIN: 'Improper EIN Formatting',
  FORMS_VALIDATION_FORMAT_PHONE: 'Improper Phone Number Formatting',
  FORMS_VALIDATION_EMAIL_REQUIRED: 'Email Required',
  FORMS_VALIDATION_NO_SPACES: 'This field cannot contain spaces',
  FORMS_VALIDATION_CAMEL:
    'Must be camelCased letters and/or numbers with no spaces.',
};

export default FORMS;
