const FEATURE_FLAG_RESPONSE_TYPES = {
  DELETE: 'delete',
  NEW: 'new',
};

const FEATURE_FLAG_SCOPE_TYPES = {
  ORG: 'org',
  GLOBAL: 'global',
};

export { FEATURE_FLAG_RESPONSE_TYPES, FEATURE_FLAG_SCOPE_TYPES };
