import React from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import propTypes from 'prop-types';
import { ViewFields } from 'components';
import { mapDonorData } from 'lib/mappers';
import { EMPTY_FUNC } from 'lib/constants';
import { GapType } from 'lib/utils';

const ViewFieldsColumn = ({
  config,
  data,
  gapSize,
  onNoDataLabelClick,
  iconContainerWidth,
  showNoDataLabel,
  title,
  ...rest
}) => {
  const Column = React.useMemo(
    () =>
      config.reduce((acc, configProp) => {
        const {
          key,
          fields,
          icon,
          iconMapper = {},
          mapper = mapDonorData,
          noDataLabel,
          path,
          multiline,
        } = configProp;

        const viewFieldsData = mapper({
          data,
          fields: fields?.length ? fields : [path],
          ...configProp,
        });

        const fieldHasData = viewFieldsData?.length;

        const viewFieldsCommonProps = {
          gapSize,
          key,
          icon,
          iconContainerWidth,
        };

        if (!fieldHasData && showNoDataLabel && noDataLabel) {
          acc.push(
            <ViewFields
              {...viewFieldsCommonProps}
              noDataLabel={noDataLabel}
              onNoDataLabelClick={() => onNoDataLabelClick(key)}
            />
          );
        } else if (fieldHasData && multiline) {
          acc.push(
            <ViewFields {...viewFieldsCommonProps} data={viewFieldsData} />
          );
        } else if (fieldHasData) {
          // eslint-disable-next-line
          viewFieldsData?.map(({ label, ...fieldData }, fieldKey) => {
            acc.push(
              <ViewFields
                {...viewFieldsCommonProps}
                // eslint-disable-next-line
                key={`${viewFieldsCommonProps.key}-${fieldKey}`}
                icon={iconMapper[label] || icon}
                data={[{ ...fieldData }]}
              />
            );
          });
        }

        return acc;
      }, []),
    [config, data, iconContainerWidth, onNoDataLabelClick]
  );

  if (Column?.length) {
    return (
      <Box {...rest}>
        {title && (
          <Box pb={1}>
            <Typography variant="subtitle1">{title}</Typography>
          </Box>
        )}
        {Column}
      </Box>
    );
  }

  return null;
};

ViewFieldsColumn.propTypes = {
  config: propTypes.arrayOf(
    propTypes.shape({
      key: propTypes.string.isRequired,
      icon: propTypes.node,
      path: propTypes.string,
      fields: propTypes.arrayOf(propTypes.string),
      mapper: propTypes.func,
      extract: propTypes.func,
      noDataLabel: propTypes.string,
    })
  ),
  data: propTypes.shape({}),
  gapSize: GapType.size,
  iconContainerWidth: propTypes.string,
  onNoDataLabelClick: propTypes.func,
  showNoDataLabel: propTypes.bool,
  title: propTypes.string,
};

ViewFieldsColumn.defaultProps = {
  config: [],
  data: {},
  gapSize: 2,
  onNoDataLabelClick: EMPTY_FUNC,
  iconContainerWidth: 'auto',
  showNoDataLabel: true,
  title: '',
};

export { ViewFieldsColumn };
