import React from 'react';
import { Box, Link, Typography, makeStyles } from '@material-ui/core';
import { shape, string } from 'prop-types';
import { AUTH_ROUTES } from 'lib/constants/routes';

const useStyles = makeStyles((theme) => ({
  integrationContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '300px',
    border: `1px solid ${theme.palette.grey.light}`,
    boxSizing: 'border-box',
    borderRadius: theme.shape.borderRadius,
    marginBottom: theme.spacing(2),
    marginRight: theme.spacing(2),

    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  integrationContent: {
    display: 'flex',
    padding: theme.spacing(2),
  },
  integrationLogo: {
    borderRadius: '4px',
    height: '52px',
    maxWidth: '52px',
  },
  integrationDescription: {
    color: theme.palette.grey.dark,
  },
  integrationLink: {
    backgroundColor: theme.palette.grey.light,
    cursor: 'pointer',
    display: 'flex',
    fontWeight: '500',
    justifyContent: 'center',
    padding: theme.spacing(2),
  },
}));

const IntegrationCard = ({ integration, cta }) => {
  const { shortDescription, id, logo, name } = integration;
  const styles = useStyles();

  return (
    <Box className={styles.integrationContainer} key={id}>
      <Box className={styles.integrationContent}>
        <img className={styles.integrationLogo} src={logo} alt={name} />
        <Box ml={1.5}>
          <Typography variant="subtitle1">{name}</Typography>
          <Typography variant="body2" className={styles.integrationDescription}>
            {shortDescription}
          </Typography>
        </Box>
      </Box>
      <Link
        className={styles.integrationLink}
        href={`${AUTH_ROUTES.INTEGRATIONS}/${id}`}
      >
        {cta}
      </Link>
    </Box>
  );
};

IntegrationCard.propTypes = {
  integration: shape({
    description: string,
    id: string,
    logo: string,
    name: string,
  }).isRequired,
  cta: string,
};
IntegrationCard.defaultProps = {
  cta: 'Connect',
};

export default IntegrationCard;
