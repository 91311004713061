import React from 'react';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import { TextSelectFieldValueType } from 'lib/utils';
import TextSelectFieldItemImage from './TextSelectFieldItemImage';

export default function TextSelectFieldInput({
  error,
  id,
  label,
  loading,
  multiple,
  required,
  value,
  ...rest
}) {
  let inputProps = rest.InputProps;

  if (!multiple && value) {
    inputProps = {
      ...inputProps,
      startAdornment: <TextSelectFieldItemImage id={id} {...value} />,
    };
  }

  if (loading) {
    inputProps.endAdornment = <CircularProgress color="primary" size={20} />;
  }

  return (
    <TextField
      {...rest}
      error={error}
      label={label}
      required={required}
      InputProps={inputProps}
    />
  );
}

TextSelectFieldInput.propTypes = {
  error: PropTypes.bool,
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  loading: PropTypes.bool,
  multiple: PropTypes.bool,
  required: PropTypes.bool,
  value: PropTypes.oneOfType([
    TextSelectFieldValueType,
    PropTypes.arrayOf(TextSelectFieldValueType),
  ]),
};

TextSelectFieldInput.defaultProps = {
  error: false,
  label: '',
  loading: false,
  multiple: false,
  required: false,
  value: null,
};
