import React from 'react';
import { useDispatch } from 'react-redux';
import propTypes from 'prop-types';
import { companyTeamMembersActions, invitesActions } from 'rdx/actions';
import {
  CompanyTeamMember,
  CompanyTeamMemberStatus,
  GroupType,
} from 'lib/utils';
import { INVITE_STATUSES } from 'lib/constants/common';
import { InviteSelector } from 'components/invite/invite-selector';

const { updateCompanyTeamMembers } = companyTeamMembersActions;

const { updateInvites } = invitesActions;

const TeamMemberPermissionCell = ({
  data,
  groups,
  isForInvites,
  ownerOrgId,
  status,
}) => {
  const [teamMemberData, setTeamMemberData] = React.useState(data);
  const dispatch = useDispatch();

  const { dataLoaded, data: updatedData } = status;

  const updateAction = React.useMemo(
    () => (isForInvites ? updateInvites : updateCompanyTeamMembers),
    [isForInvites]
  );

  const idField = React.useMemo(
    () => (isForInvites ? 'inviteId' : 'userId'),
    [isForInvites]
  );

  React.useEffect(() => {
    if (dataLoaded && updatedData) {
      setTeamMemberData(updatedData);
    }
  }, [updatedData]);

  const onChange = React.useCallback(
    (selectedPermisisons) => {
      const dataToUpdate = {
        [idField]: teamMemberData[idField],
      };

      if (!isForInvites) {
        dataToUpdate.displayName = data?.displayName;
        dataToUpdate.group = selectedPermisisons[0].groupDisplayName;
      } else {
        dataToUpdate.groups = selectedPermisisons;
      }

      dispatch(updateAction(ownerOrgId, dataToUpdate));
    },
    [teamMemberData, updateAction]
  );

  return (
    <InviteSelector
      disabled={isForInvites && data?.status === INVITE_STATUSES.JOINED}
      handleChange={onChange}
      options={groups}
      selectedOptions={teamMemberData?.groups}
    />
  );
};

TeamMemberPermissionCell.propTypes = {
  data: CompanyTeamMember.isRequired,
  groups: GroupType,
  ownerOrgId: propTypes.string,
  status: CompanyTeamMemberStatus,
  isForInvites: propTypes.bool,
};

TeamMemberPermissionCell.defaultProps = {
  ownerOrgId: '',
  groups: [],
  status: {
    dataLoaded: false,
    isFetching: false,
    error: null,
  },
  isForInvites: false,
};

export { TeamMemberPermissionCell };
