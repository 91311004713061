import React from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import { DONOR_ATTRIBUTES } from 'lib/configs/donorAttributesConfig';
import ImageIcon from './ImageIcon';

const OPTION_RENDER_TYPE = 'option';
const CONTAINER_HEIGHT = 20;
const IMAGE_HEIGHT = 14;

const useStyles = makeStyles((theme) => ({
  donorAttribute: {
    alignItems: 'center',
    backgroundColor({ attributeType }) {
      return theme.palette.donorAttributes[attributeType || 'recurring'];
    },
    borderRadius: theme.spacing(0.5),
    display: 'flex',
    justifyContent: 'center',
    marginRight({ renderType }) {
      return renderType !== OPTION_RENDER_TYPE ? theme.spacing(0.5) : '';
    },
    height: `${CONTAINER_HEIGHT}px`,
    width: `${CONTAINER_HEIGHT}px`,
  },
  donorAttributeImg: {
    maxHeight: `${IMAGE_HEIGHT}px`,
    maxWidth: `${IMAGE_HEIGHT}px`,
  },
}));

export default function DonorAttributeImageIcon({ attributeType, renderType }) {
  const styles = useStyles({
    attributeType,
    renderType,
  });
  const imageInfo = DONOR_ATTRIBUTES[attributeType];

  return (
    <Box className={styles.donorAttribute}>
      <ImageIcon
        className={styles.donorAttributeImg}
        width={IMAGE_HEIGHT}
        height={IMAGE_HEIGHT}
        type={attributeType}
        alt={imageInfo.text}
      />
    </Box>
  );
}

DonorAttributeImageIcon.propTypes = {
  attributeType: PropTypes.oneOf(Object.keys(DONOR_ATTRIBUTES)).isRequired,
  renderType: PropTypes.oneOf([OPTION_RENDER_TYPE]).isRequired,
};

export function DonorAttributeOptionImageIcon({ value }) {
  return (
    <DonorAttributeImageIcon
      attributeType={value}
      renderType={OPTION_RENDER_TYPE}
    />
  );
}

DonorAttributeOptionImageIcon.propTypes = {
  // eslint-disable-next-line react/require-default-props
  value: DonorAttributeImageIcon.propTypes.attributeType,
};
