import { parsePhoneNumberFromString } from 'libphonenumber-js';
import { COUNTRY_CODES, PHONE_NUMBER_LENGTH, PLUS_SIGN } from 'lib/constants';

// TODO: revisit the logic once we support more than one Country code

/**
  Checks if a phone number is valid
  @param {string} number value
  @param {string} dialCode value
  @returns {boolean} result
*/
const isValidPhoneNumber = (number, dialCode) => {
  const isEmpty = number === `+${dialCode} `;

  if (isEmpty) {
    return true;
  }

  const parsedNumber = parsePhoneNumberFromString(number, COUNTRY_CODES.US);
  return parsedNumber && parsedNumber.isValid();
};

/**
  Removes + and a dial code if any
  @param {string} number value
  @param {string} dialCode value
  @returns {boolean} result
*/
const getNumberWithoutDialCode = (number, dialCode) => {
  let numberWithoutDialCode = number;

  [PLUS_SIGN, dialCode].forEach((str) => {
    if (numberWithoutDialCode?.startsWith(str)) {
      numberWithoutDialCode = numberWithoutDialCode.replace(str, '');
    }
  });

  return numberWithoutDialCode;
};

/**
  Removes any extra formatting characters
  @param {string} number string value
  @returns {boolean} result
*/
const getPhoneValue = (number) => number?.replace(/\+|-|\(|\)|\s/g, '');

/**
  Removes any extra formatting characters
  @param {string} number string value
  @returns {boolean} result
*/
const isPhoneValueLengthValid = (number) =>
  getPhoneValue(number).length <= PHONE_NUMBER_LENGTH;

/**
  Adds + to a dial code
  @param {string} code string value
  @returns {string} result
*/
const formatDialCode = (dialCode) => `+${dialCode}`;

/**
  A helper util to get a correct cursor position
  on editing an EditDonorPhoneInput value.

  A value gets formatted (using (XXX) XXX-XXXX) on the fly
  which breaks the expected cursor position

  @param {number} currentPos cursor position after editing
  @returns {number} result
*/
const getPhoneInputCursorPos = (currentPos) => {
  let pos = currentPos;

  // corresponds to `) `
  if (pos >= 4 && pos <= 6) {
    pos = 7;
  }

  // corresponds to `-`
  if (pos === 10) {
    pos = 11;
  }

  return pos;
};

export {
  formatDialCode,
  getPhoneInputCursorPos,
  getNumberWithoutDialCode,
  getPhoneValue,
  isPhoneValueLengthValid,
  isValidPhoneNumber,
};
