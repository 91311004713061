import React from 'react';
import { useHistory } from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroll-component';
import Box from '@material-ui/core/Box';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/styles/makeStyles';
import { EMPTY_FUNC, ICON_SIZES } from 'lib/constants/common';
import { isMobileView, capitalizeFirstLetter } from 'lib/utils';
import PropTypes from 'prop-types';
import { translate } from 'lib/intl';
import { useOwnerOrgPubId } from 'lib/hooks';
import { feedControl } from './feedHooks';
import { FeedItem } from './FeedItem';
import { FeedSlider } from './FeedSlider';
import { EntityFeedItem } from './EntityFeedItem';

const desktopScrollContainer = 'infinite-scroll-container';

const useStyles = makeStyles((theme) => ({
  container: {
    position: 'relative',
    overflow: 'hidden',
    [theme.breakpoints.down('sm')]: {
      position: 'static',
    },
  },
  feedVerticalContentContainer: {
    height: (props) =>
      props.offset ? `calc(100vh - ${props.offset})` : '100vh',
    background: theme.palette.background.light,
    overflowY: 'auto',
    position: 'relative',
    paddingBottom: theme.spacing(6.75),
    [theme.breakpoints.down('sm')]: {
      height: 'auto !important',
      overflowY: 'inherit',
    },
  },
  loading: {
    display: 'block',
    height: ICON_SIZES.XL,
    width: ICON_SIZES.XL,
    margin: '0 auto',
  },
  overlayLoader: {
    display: 'flex',
    alignItems: 'center',
    position: 'absolute',
    right: 0,
    left: 0,
    top: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.2)',
    zIndex: 2,
    [theme.breakpoints.down('xs')]: {
      minHeight: '500px',
    },
  },
  emptyState: {
    textAlign: 'center',
    paddingTop: theme.spacing(2.5),
  },
  drawerPaper: {
    borderTopLeftRadius: theme.shape.borderRadius[0],
    boxShadow: `0 0 6px 0 ${theme.palette.grey.dark}`,
    width: '40%',
    [theme.breakpoints.down('sm')]: {
      width: `calc(100% - ${theme.spacing(1.25)}px)`,
    },
  },
}));

const Feed = ({
  layoutContainerId,
  layoutContainerRef,
  feed,
  getFeedPage,
  activeFeedItemId,
  onFeedItemClicked,
  closeSliderRedirectRoute,
  topOffset,
}) => {
  const isMobile = isMobileView();
  const history = useHistory();
  const classes = useStyles({ offset: topOffset });

  const { ownerOrgId } = useOwnerOrgPubId();
  const { isFetching, items } = feed;

  // Drawer related code
  const [isDrawerOpen, setDrawerOpen] = React.useState(
    Boolean(activeFeedItemId) && !isFetching
  );

  const onDrawerClose = () => setDrawerOpen(false);
  const onDrawerExited = () => history.push(closeSliderRedirectRoute);

  React.useEffect(() => {
    setDrawerOpen(Boolean(activeFeedItemId) && !isFetching);
  }, [activeFeedItemId, isFetching]);

  // Refs
  const containerRef = React.useRef();

  const itemRefs = [];
  const setRef = (itemRef, id) => {
    itemRefs[id] = itemRef;
  };

  const scrollToRef = (ref) => {
    if (ref && containerRef?.current) {
      containerRef.current.scrollTop = ref.offsetTop;
    }
  };

  const getNextPage = () => {
    if (feed.hasNextPage) {
      getFeedPage(feed.pageFrom);
    }
  };

  // Takes over fetching if there is an active feed item on page load
  feedControl(
    activeFeedItemId,
    isMobile ? activeFeedItemId : null, // mobileActiveItem
    isMobile,
    isFetching,
    itemRefs,
    layoutContainerRef,
    getFeedPage,
    feed,
    scrollToRef
  );

  const Items = React.useMemo(
    () =>
      items?.map(
        ({
          feedItemId: id,
          contentType: type,
          content: body,
          created: date,
          pinned,
        }) => (
          <FeedItem
            id={id}
            key={id}
            date={date}
            body={body}
            type={type ? capitalizeFirstLetter(type) : null}
            pinned={pinned}
            isActive={id === activeFeedItemId}
            ref={(itemRef) => {
              setRef(itemRef, id);
            }}
            onClick={onFeedItemClicked}
          />
        )
      ),
    [items, activeFeedItemId]
  );

  const activeItem = React.useMemo(
    () => items.find((item) => item.feedItemId === activeFeedItemId),
    [items, activeFeedItemId]
  );

  return (
    <>
      <Box className={classes.container}>
        {isFetching && (
          <Box className={classes.overlayLoader}>
            <CircularProgress
              data-testid="auth-action-button-loading"
              color="primary"
              className={classes.loading}
            />
          </Box>
        )}
        <Box
          id={desktopScrollContainer}
          ref={containerRef}
          className={classes.feedVerticalContentContainer}
        >
          {/*
            Warning in console regarding InfiniteScroll currently has an open issue:
            https://github.com/ankeetmaini/react-infinite-scroll-component/issues/140
          */}
          <InfiniteScroll
            key={isMobile ? layoutContainerId : desktopScrollContainer}
            dataLength={items.length}
            next={getNextPage}
            hasMore={!activeFeedItemId}
            scrollableTarget={
              isMobile ? layoutContainerId : desktopScrollContainer
            }
            endMessage={<div />}
          >
            {Items || (
              <Typography className={classes.emptyState}>
                {translate('EMPTY_FEED')}
              </Typography>
            )}
          </InfiniteScroll>
        </Box>
        <SwipeableDrawer
          anchor="right"
          container={containerRef?.current}
          classes={{ paper: classes.drawerPaper }}
          onClose={onDrawerClose}
          onOpen={EMPTY_FUNC}
          open={isDrawerOpen}
          SlideProps={{ onExited: onDrawerExited }}
        >
          {Boolean(activeItem) && (
            <FeedSlider closeSlider={onDrawerClose}>
              <EntityFeedItem
                closeSlider={onDrawerClose}
                ownerOrgId={ownerOrgId}
                {...activeItem}
              />
            </FeedSlider>
          )}
        </SwipeableDrawer>
      </Box>
    </>
  );
};
Feed.propTypes = {
  layoutContainerId: PropTypes.string,
  layoutContainerRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]),
  feed: PropTypes.shape({
    items: PropTypes.arrayOf(PropTypes.shape({})),
    currentPage: PropTypes.number,
    isFetching: PropTypes.bool,
    hasNextPage: PropTypes.bool,
    pageFrom: PropTypes.number,
  }),
  getFeedPage: PropTypes.func,
  activeFeedItemId: PropTypes.string,
  onFeedItemClicked: PropTypes.func,
  closeSliderRedirectRoute: PropTypes.string,
  topOffset: PropTypes.string,
};

Feed.defaultProps = {
  layoutContainerId: null,
  layoutContainerRef: null,
  feed: {
    items: [],
  },
  getFeedPage: EMPTY_FUNC,
  activeFeedItemId: null,
  onFeedItemClicked: EMPTY_FUNC,
  closeSliderRedirectRoute: null,
  topOffset: '',
};

export { Feed };
