/**
 * This is a "controlled" Select component, made to work with
 * react-hooks-form.
 */
import React from 'react';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';
import Select from '@material-ui/core/Select';

export default function ControlledSelect({
  children,
  control,
  defaultValue,
  name,
  rules,
  ...props
}) {
  return (
    <Controller
      defaultValue={defaultValue}
      {...(rules && { rules })}
      control={control}
      name={name}
      render={({ onChange, value }) => {
        return <Select {...{ ...props, value, onChange }}>{children}</Select>;
      }}
    />
  );
}

ControlledSelect.propTypes = {
  control: PropTypes.object.isRequired,
  defaultValue: PropTypes.string,
  name: PropTypes.string.isRequired,
  rules: PropTypes.object,
  ...Select.propTypes,
};

ControlledSelect.defaultProps = {
  defaultValue: '',
  rules: null,
};
