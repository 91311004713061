import PropTypes from 'prop-types';
import { SortDirection, SortField } from './ReportFilterPropType';

export const DonationSummary = PropTypes.shape({
  averageDonationAmount: PropTypes.number,
  averageDonationAmountYoy: PropTypes.number,
  campaignId: PropTypes.string,
  ownerOrgId: PropTypes.string,
  totalDonations: PropTypes.number,
  totalDonationsYoy: PropTypes.number,
  totalDonors: PropTypes.number,
  totalDonorsYoy: PropTypes.number,
  totalHours: PropTypes.number,
  totalHoursYoy: PropTypes.number,
  totalInKind: PropTypes.number,
  totalInKindYoy: PropTypes.number,
  totalValue: PropTypes.number,
  totalValueYoy: PropTypes.number,
});

export const DonationsOverTimePropType = PropTypes.arrayOf(
  PropTypes.shape({
    campaignId: PropTypes.string,
    ownerOrgId: PropTypes.string,
    timeUnitStart: PropTypes.string,
    totalDonations: PropTypes.number,
    totalDonors: PropTypes.number,
    totalValue: PropTypes.number,
  })
);

export const ListedDonor = PropTypes.shape({
  averageDonationAmount: PropTypes.number,
  campaignId: PropTypes.string,
  categories: PropTypes.arrayOf(PropTypes.string),
  donated: PropTypes.number,
  donationsMade: PropTypes.number,
  donorCreatedAt: PropTypes.instanceOf(Date),
  donorDisplayName: PropTypes.string,
  donorId: PropTypes.string,
  donorType: PropTypes.string,
  ownerOrgId: PropTypes.string,
  totalHours: PropTypes.number,
  totalInKind: PropTypes.number,
});

export const ReportMetaPropType = PropTypes.shape({
  count: PropTypes.number,
  moduleName: PropTypes.string,
  pageFrom: PropTypes.string,
  pageSize: PropTypes.string,
  rollup: null,
  sortDirection: PropTypes.oneOf(Object.values(SortDirection)),
  sortField: PropTypes.oneOf(Object.values(SortField)),
});

const ReportModulesPropType = PropTypes.shape({
  donationSummary: PropTypes.shape({
    report: DonationSummary,
  }),
  donationsOverTime: PropTypes.shape({
    report: DonationsOverTimePropType,
  }),
  donorList: PropTypes.shape({
    report: PropTypes.arrayOf(ListedDonor),
    meta: ReportMetaPropType,
  }),
});

export default ReportModulesPropType;
