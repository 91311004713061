import React from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Typography from '@material-ui/core/Typography';
import { translate } from 'lib/intl';
import { CustomTableFilterProps } from 'lib/utils';
import { ASC, DESC, DEFAULT_COMPANY_FILTER } from 'lib/constants';
import { SortIcon } from 'assets/images';

const SortPicker = ({ filter, options, setFilter, ...rest }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const { order, orderBy } = filter || {};

  const selectedValueConfig = React.useMemo(
    () => options?.find((option) => option?.value === orderBy),
    [options, orderBy]
  );

  const openMenu = (event) => setAnchorEl(event.currentTarget);

  const closeMenu = () => setAnchorEl(null);

  const selectOption = (newOption = null) => setFilter(newOption);

  const Options = React.useMemo(
    () =>
      options?.map(({ label, value }) => {
        const isSelected = orderBy === value;

        const optionToSelect = {
          ...filter,
          order: isSelected && order === ASC ? DESC : ASC,
          orderBy: value,
        };

        return (
          <MenuItem
            data-testid={`sort-picker-item-${value}`}
            key={value}
            onClick={() => selectOption(optionToSelect)}
            selected={isSelected}
          >
            <TableSortLabel active={isSelected} direction={order}>
              {label}
            </TableSortLabel>
          </MenuItem>
        );
      }),
    [options, filter]
  );

  const resetSorting = React.useCallback(
    () =>
      setFilter({
        ...filter,
        order: ASC,
        orderBy: '',
      }),
    [filter]
  );

  return (
    <Box display="flex" justifyContent="center" {...rest}>
      <Box
        alignItems="center"
        data-testid="sort-picker-button"
        display="flex"
        onClick={openMenu}
        style={{ cursor: 'pointer' }}
      >
        <Box pr={1}>
          <SortIcon />
        </Box>
        {selectedValueConfig ? (
          <TableSortLabel active={Boolean(orderBy)} direction={order}>
            {selectedValueConfig?.label}
          </TableSortLabel>
        ) : (
          <Typography>{translate('SORT')}</Typography>
        )}
      </Box>
      <Menu
        id="sort-picker-menu"
        data-testid="sort-picker-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={closeMenu}
        getContentAnchorEl={null}
        transformOrigin={{ vertical: 'center', horizontal: 'left' }}
      >
        <MenuItem onClick={resetSorting} selected={!selectedValueConfig}>
          {translate('NONE')}
        </MenuItem>
        {Options}
      </Menu>
    </Box>
  );
};

SortPicker.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    })
  ),
  setFilter: PropTypes.func.isRequired,
  filter: CustomTableFilterProps,
};

SortPicker.defaultProps = {
  filter: DEFAULT_COMPANY_FILTER,
  options: [],
};

export { SortPicker };
