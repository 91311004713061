import userActionTypes from 'rdx/actionTypes';

const initialState = {
  isFetching: false,
  isRegistrationSuccessful: false,
  einValue: '',
  phoneNumberValue: '',
  error: null,
};

/* eslint-disable no-case-declarations */
export function registration(state = initialState, action) {
  switch (action.type) {
    case userActionTypes.REGISTER_FORM_EIN_VALUE:
      return {
        ...state,
        einValue: action.einValue,
      };
    case userActionTypes.REGISTER_FORM_PHONE_VALUE:
      return {
        ...state,
        phoneNumberValue: action.phoneNumberValue,
      };
    case userActionTypes.REGISTER_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: null,
      };
    case userActionTypes.REGISTER_SUCCESS:
      return {
        ...state,
        isFetching: false,
        isRegistrationSuccessful: true,
        einValue: '',
      };
    case userActionTypes.REGISTER_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action?.error,
      };
    case userActionTypes.REGISTER_RESET_ALL:
      return {
        ...state,
        isRegistrationSuccessful: false,
        error: null,
        einValue: '',
      };
    default:
      return state;
  }
}
