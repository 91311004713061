import theme from 'styles/newTheme';
import { remToPxNumber } from 'styles/styleUtils';

const FONT_SIZE = remToPxNumber(theme.typography.body3.fontSize);
const LINE_HEIGHT = FONT_SIZE * theme.typography.body3.lineHeight;
const MARGIN_BOTTOM = theme.spacing(5);

export const BAR_RX = theme.spacing(0.25);
export const BAR_WIDTH = theme.spacing(2);
export const BAR_WIDTH_MOBILE = theme.spacing(1);
export const MARGIN_LEFT = theme.spacing(5);
export const MARGIN_TOP = LINE_HEIGHT - FONT_SIZE;
export const Y_AXIS_GROUP_Y = LINE_HEIGHT - FONT_SIZE;
export const Y_AXIS_GROUP_MARGIN_BOTTOM = MARGIN_BOTTOM - FONT_SIZE;
export const Y_AXIS_GROUP_MARGIN_BOTTOM_LARGE =
  Y_AXIS_GROUP_MARGIN_BOTTOM + theme.spacing(1.5);
export const Y_GRID_GROUP_Y = LINE_HEIGHT - FONT_SIZE;
export const Y_TICK_PADDING = theme.spacing(1.5);
export const X_AXIS_GROUP_HEIGHT = theme.spacing(1.5) + LINE_HEIGHT;
export const X_AXIS_GROUP_HEIGHT_TILTED = theme.spacing(2) + LINE_HEIGHT;
