import { DEFAULT_LOCALES } from 'translations/locales';

const defaultFormatterProps = {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 0,
};

// Creates currency formatters
const currencyFormatter = new Intl.NumberFormat(
  DEFAULT_LOCALES,
  defaultFormatterProps
);

const fractionCurrencyFormatter = new Intl.NumberFormat(DEFAULT_LOCALES, {
  ...defaultFormatterProps,
  minimumFractionDigits: 2,
});

/**
 * A helper util which converts cents to dollars
 *
 * @param {any} value cents
 * @returns {number} dollars
 */
const convertCentsToDollars = (value) => Number(value) * 0.01 || 0;

/**
 * formats a numeric value to be a string with the following format: $2,500 or $2,500.25
 *
 * @param {any} [value=0] value to be formatted
 * @param {boolean} [areCents=false] used to convert to dollars if necessary
 * @returns {string} formatted value
 */
const formatCurrency = (val = '0', areCents = false) => {
  let value = Number(val) || 0;

  if (areCents) {
    value = convertCentsToDollars(value);
  }

  return value % 1 === 0
    ? currencyFormatter.format(value)
    : fractionCurrencyFormatter.format(value);
};

const formatCurrencyWithDecimals = (val = 0, areCents = false) => {
  let value = Number(val);

  if (areCents) {
    value = convertCentsToDollars(value);
  }

  return fractionCurrencyFormatter.format(value);
};

// Method helps with formatting string from NumberInput
// Check for typeof as if the field is left unaltered, the value in state will
// remain a number, changing input creates a string in state which needs conversion
const formatCurrencyInputToCents = (value) => {
  if (typeof value === 'number') {
    return value * 100;
  }

  let formattedCurrency;

  try {
    // Format target amount to a number in cents for submission
    formattedCurrency =
      parseFloat(value.replace(/\$/g, '').replace(/,/g, '')) * 100;
  } catch (e) {
    // eslint-disable-next-line no-console
    console.warn('Could not format currency', e);
    return null;
  }

  return formattedCurrency;
};

// formatCurrencyAbbrev taken from https://stackoverflow.com/a/9462382
const abbrevCurrencyLookUp = [
  { value: 1, symbol: '' },
  { value: 1e3, symbol: 'k' },
  { value: 1e6, symbol: 'm' },
  { value: 1e9, symbol: 'b' },
  { value: 1e12, symbol: 't' },
  { value: 1e15, symbol: 'p' },
  { value: 1e18, symbol: 'e' },
];
const abbrevCurrencyRx = /\.0+$|(\.[0-9]*[1-9])0+$/;

const formatCurrencyAbbrev = (num, digits) => {
  const item = abbrevCurrencyLookUp
    .slice()
    .reverse()
    .find((element) => {
      return num >= element.value;
    });

  return item
    ? (num / item.value).toFixed(digits).replace(abbrevCurrencyRx, '$1') +
        item.symbol
    : '0';
};

export {
  currencyFormatter,
  fractionCurrencyFormatter,
  formatCurrency,
  formatCurrencyAbbrev,
  formatCurrencyInputToCents,
  formatCurrencyWithDecimals,
  convertCentsToDollars,
};
