import React from 'react';
import PropTypes from 'prop-types';
import TextSelectField from 'components/common/form/TextSelectField';
import useConstituentById from 'lib/hooks/useConstituentById';
import useConstituentSearch from 'lib/hooks/useConstituentSearch';
import { EMPTY_FUNC } from 'lib/constants';

const formatConstituent = (constituent) => {
  return {
    value: constituent.constituentId,
    icon: '',
    text: constituent.displayName,
    constituentType: constituent.constituentType,
    isAvatar: true,
  };
};

const ConstituentSearchField = ({
  initialConstituentId,
  loading,
  setValue,
  value,
  ...rest
}) => {
  const { isFetching, results, searchParams, setSearchParams } =
    useConstituentSearch();

  const { constituent, isFetching: isConstituentFetching } =
    useConstituentById(initialConstituentId);

  React.useEffect(() => {
    if (constituent) {
      setValue(formatConstituent(constituent));
    }
  }, [constituent]);

  const onInputChange = (e) => {
    const displayName = e?.target?.value;
    setSearchParams({ displayName });
  };

  React.useEffect(() => {
    if (value?.text) {
      setSearchParams({
        displayName: value.text,
      });
    }
  }, [value]);

  const items = React.useMemo(() => results.map(formatConstituent), [results]);

  return (
    <TextSelectField
      items={items}
      loading={loading || isFetching || isConstituentFetching}
      value={value}
      inputValue={searchParams.displayName || ''}
      onInputChange={onInputChange}
      setValue={setValue}
      {...rest}
    />
  );
};

ConstituentSearchField.propTypes = {
  initialConstituentId: PropTypes.string,
  loading: PropTypes.bool,
  setValue: PropTypes.func,
  value: PropTypes.shape({
    icon: PropTypes.string,
    isAvatar: PropTypes.bool,
    text: PropTypes.string,
    value: PropTypes.string,
  }),
};

ConstituentSearchField.defaultProps = {
  initialConstituentId: null,
  loading: false,
  setValue: EMPTY_FUNC,
  value: null,
};

export default ConstituentSearchField;
