import { RECENTLY_VIEWED_CONSTITUENTS } from 'lib/constants/localStorageKeys';

// Retrieve recently viewed constituents data from local storage
export function getRecentlyViewedConstituents() {
  let recentlyViewedConstituents = [];

  try {
    recentlyViewedConstituents =
      JSON.parse(localStorage.getItem(RECENTLY_VIEWED_CONSTITUENTS)) || [];
  } catch (e) {
    // eslint-disable-next-line no-console
    console.warn('Failed to parse recently viewed constituents data');
  }

  return recentlyViewedConstituents;
}

// Given a constituent object, store in local storage at the start of the recently
// viewed constituents local storage array
export function recordConstituentView(constituent) {
  const { constituentId } = constituent;

  const formattedConstituent = { ...constituent };
  formattedConstituent.lastViewed = new Date().toISOString();

  // Add the provided constituent information to the cookie
  const currentRecentConstituents = getRecentlyViewedConstituents();

  // Filter out the constituent being recorded from the already recorded constituents
  const recentlyViewedConstituents = currentRecentConstituents.filter(
    (storedConstituent) => storedConstituent.constituentId !== constituentId
  );

  // Add the current constituent to the front of the constituents array
  recentlyViewedConstituents.unshift(formattedConstituent);

  // Set the updated array to local storage
  try {
    localStorage.setItem(
      RECENTLY_VIEWED_CONSTITUENTS,
      JSON.stringify(recentlyViewedConstituents)
    );
  } catch (e) {
    // eslint-disable-next-line no-console
    console.warn('Failed to set recently viewed constituent');
  }

  return recentlyViewedConstituents;
}

export default {
  recordConstituentView,
};
