// API error codes located at:
// https://github.com/dotorg-tech/services/blob/main/packages/main/dotorg/main/exceptions.py

/* eslint-disable no-multi-spaces */
const RESOURCE_NOT_FOUND = 'RESOURCE_NOT_FOUND'; // 404
const VALIDATION_ERROR = 'VALIDATION_ERROR'; // 400
const RESOURCE_EXISTS = 'RESOURCE_EXISTS'; // 500
const INVALID_INVITE = 'INVALID_INVITE'; // 400 and 302, if used verify right error
const UNAUTHORIZED = 'UNAUTHORIZED'; // 403
const EMAIL_VERIFY_ERROR = 'EMAIL_VERIFY_ERROR'; // 302
const COGNITO_TOKEN_ERROR = 'COGNITO_TOKEN_ERROR'; // 401
const APP_CONFIG_ERROR = 'APP_CONFIG_ERROR'; // 500
const UNKNOWN_ERROR = 'UNKNOWN_ERROR'; // 500

export {
  RESOURCE_NOT_FOUND,
  VALIDATION_ERROR,
  RESOURCE_EXISTS,
  INVALID_INVITE,
  UNAUTHORIZED,
  EMAIL_VERIFY_ERROR,
  COGNITO_TOKEN_ERROR,
  APP_CONFIG_ERROR,
  UNKNOWN_ERROR,
};
