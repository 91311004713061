import React from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/styles/makeStyles';
import { translate } from 'lib/intl';
import { isMobileView } from 'lib/utils';
import {
  Feed,
  MultiActionDialogManager,
  GoalsSection,
  CustomLink,
  AnnouncementModal,
} from 'components';
import {
  EMPTY_FUNC,
  AUTH_ROUTES,
  INFO_COLUMN_WIDTH,
  MOBILE_NAV_HEIGHT,
} from 'lib/constants';
import { useCurrentUser, useHomeFeedRefresh } from 'lib/hooks';
import { RecentlyViewedDonors } from './RecentlyViewedDonors';

// TODO remove test styles once real content is introduced
const useStyles = makeStyles((theme) => ({
  navVerticalContainer: {
    borderRight: `1px solid ${theme.palette.grey.light}`,
  },
  goalsAndDonorsContainer: {
    flex: `0 0 ${INFO_COLUMN_WIDTH}`,
    width: INFO_COLUMN_WIDTH,
    [theme.breakpoints.down('sm')]: {
      flex: '0 0 auto',
      width: 'auto',
    },
  },
  hideScrollbar: {
    msOverflowStyle: 'none',
    scrollbarWidth: 'none',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  navHorizontalHeader: {
    alignItems: 'center',
    borderBottom: `1px solid ${theme.palette.grey.light}`,
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(1.5),
    [theme.breakpoints.down('sm')]: {
      display: 'block',
    },
  },
  announcementLinkContainer: {
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      flex: 1,
      justifyContent: 'space-between',
    },
  },
  goalsVerticalContentContainer: {
    height: '100vh',
    overflowY: 'auto',
    [theme.breakpoints.down('sm')]: {
      height: 'auto',
      overflowY: 'inherit',
    },
  },
  horizontalScrollContainer: {
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      flexWrap: 'nowrap',
      overflowY: 'hidden',
      overflowX: 'auto',
      paddingLeft: theme.spacing(1.5),
      whiteSpace: 'nowrap',
      msOverflowStyle: 'none',
      webkitOverflowScrolling: 'touch',
      scrollbarWidth: 'none',
      '&::-webkit-scrollbar': {
        display: 'none',
      },
      '&::after': {
        content: '""',
        minWidth: theme.spacing(1.5),
      },
    },
  },
}));

const HomeLayout = ({
  feed,
  getFeedPage,
  setActiveFeedItem,
  activeFeedItemId,
}) => {
  const containerRef = React.useRef(null);
  const isMobile = Boolean(isMobileView());
  const styles = useStyles();
  const { isSuperAdmin, isOrgAdmin } = useCurrentUser();
  const { refreshFeed } = useHomeFeedRefresh();

  const [announcementModal, setAnnouncementModal] = React.useState({
    open: false,
    isGlobal: false,
  });

  const topContainerId = 'home-layout-container';

  const openAnnouncementModal = (isGlobal) => {
    setAnnouncementModal({ isGlobal, open: true });
  };

  const closeAnnouncementModal = () => {
    setAnnouncementModal({ ...announcementModal, open: false });
  };

  return (
    <Box
      data-testid="home-layout-container"
      id={topContainerId}
      ref={containerRef}
      display="flex"
      fill={1}
      flexDirection={isMobile ? 'column' : 'row'}
      width="100%"
    >
      <Box
        className={`${styles.navVerticalContainer} ${styles.goalsAndDonorsContainer}`}
      >
        <Box
          px={isMobile ? 0 : 2}
          py={2}
          className={`${styles.goalsVerticalContentContainer} ${styles.hideScrollbar}`}
        >
          <GoalsSection />
          <Box pt={1.5}>
            <Box px={isMobile ? 2 : 0} pb={1.5}>
              <Typography color="textPrimary" variant="subtitle1">
                {translate('RECENTLY_VIEWED_TITLE')}
              </Typography>
            </Box>
            <Box className={styles.horizontalScrollContainer}>
              <RecentlyViewedDonors />
            </Box>
          </Box>
        </Box>
      </Box>
      <Box
        data-testid="nav-vertical-container"
        className={styles.navVerticalContainer}
        flex={1}
      >
        <Box className={styles.navHorizontalHeader}>
          <Box pr={3}>
            <Typography color="textPrimary" variant="subtitle1">
              {translate('FEED_TITLE')}
            </Typography>
          </Box>
          <Box className={styles.announcementLinkContainer}>
            {isSuperAdmin && (
              <CustomLink onClick={() => openAnnouncementModal(true)}>
                {translate('ADD_GLOBAL_ANNOUNCEMENT')}
              </CustomLink>
            )}
            {(isOrgAdmin || isSuperAdmin) && (
              <Box pl={2.5}>
                <CustomLink onClick={() => openAnnouncementModal(false)}>
                  {translate('ADD_ANNOUNCEMENT')}
                </CustomLink>
              </Box>
            )}
          </Box>
        </Box>
        <AnnouncementModal
          handleClose={closeAnnouncementModal}
          {...announcementModal}
        />
        <Feed
          layoutContainerId={topContainerId}
          layoutContainerRef={containerRef}
          feed={feed}
          getFeedPage={getFeedPage}
          activeFeedItemId={activeFeedItemId}
          onFeedItemClicked={(id) => setActiveFeedItem(id)}
          closeSliderRedirectRoute={AUTH_ROUTES.LANDING}
          topOffset={MOBILE_NAV_HEIGHT}
        />
      </Box>
      <MultiActionDialogManager onEntityCreated={refreshFeed} />
    </Box>
  );
};

HomeLayout.propTypes = {
  feed: PropTypes.shape({
    items: PropTypes.arrayOf(PropTypes.shape({})),
    currentPage: PropTypes.number,
    isFetching: PropTypes.bool,
  }),
  getFeedPage: PropTypes.func,
  setActiveFeedItem: PropTypes.func,
  activeFeedItemId: PropTypes.string,
};

HomeLayout.defaultProps = {
  feed: {
    items: [],
  },
  getFeedPage: EMPTY_FUNC,
  setActiveFeedItem: EMPTY_FUNC,
  activeFeedItemId: null,
};

export { HomeLayout };
