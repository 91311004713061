import React from 'react';
import { AuthContainer, LoginForm } from 'components';
import NewThemeProvider from 'components/common/NewThemeProvider';
import { useLogin } from './loginPagesHooks';

const LoginPage = () => {
  const { isFetching, error, loginAction, clearLoginErrors } = useLogin();

  return (
    <NewThemeProvider>
      <AuthContainer size="sm">
        <LoginForm
          isFetching={isFetching}
          error={error}
          loginAction={loginAction}
          clearLoginErrors={clearLoginErrors}
        />
      </AuthContainer>
    </NewThemeProvider>
  );
};

export { LoginPage };
