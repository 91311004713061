import React from 'react';
import PropTypes from 'prop-types';
import Chip from '@material-ui/core/Chip';
import ConstituentFilterPropType from 'lib/types/ConstituentPropType';
import classNames from 'classnames';

const ConstituentLabelChips = ({ className, constituent }) => {
  const { labels = [] } = constituent;

  return labels.length ? (
    <div className={classNames(className, 'flex flex-wrap -m-1')}>
      {(constituent.labels || []).map((label) => (
        <Chip
          label={label}
          color="primary"
          className="bg-secondaryButton-dark m-1"
        />
      ))}
    </div>
  ) : null;
};

ConstituentLabelChips.propTypes = {
  className: PropTypes.string,
  constituent: ConstituentFilterPropType.isRequired,
};

ConstituentLabelChips.defaultProps = {
  className: '',
};

export default ConstituentLabelChips;
