import React from 'react';
import { number, oneOfType, bool, shape, string } from 'prop-types';
import { Box, Typography } from '@material-ui/core';
import styled from 'styled-components';
import { colors } from 'styles/theme';
import { formatCurrency, formatNumber } from 'lib/utils';

const StyledLabel = styled(Typography)`
  color: ${colors.greyCustom3};
  font-family: Poppins;
  font-size: 10px;
  font-weight: 600;
  letter-spacing: 1px;
  text-transform: uppercase;
`;

const StyledValue = styled(Typography)`
  font-family: Poppins;
  font-size: 24px;
  letter-spacing: 0.25px;
`;

const DonationAmount = ({ label, value, isCurrencyBased, valueProps }) => (
  <Box
    display="flex"
    flexDirection="column"
    alignItems="flex-end"
    width="180px"
    px={1}
  >
    <StyledLabel>{label}</StyledLabel>
    <StyledValue {...valueProps}>
      {isCurrencyBased ? formatCurrency(value, true) : formatNumber(value)}
    </StyledValue>
  </Box>
);

DonationAmount.propTypes = {
  isCurrencyBased: bool,
  label: string.isRequired,
  value: oneOfType([number, string]),
  valueProps: shape({}),
};

DonationAmount.defaultProps = {
  isCurrencyBased: false,
  value: 0,
  valueProps: {},
};

export { DonationAmount };
