import PropTypes from 'prop-types';
import {
  CURRENT_STATE_DASHBOARD,
  DONATIONS_OVER_TIME,
  NEW_DONOR_GROWTH,
  CAMPAIGN_OVERVIEW,
} from 'lib/constants/reports';

export const DateType = Object.freeze({
  CALENDAR_YTD: 'calendar-ytd',
  FISCAL_YTD: 'fiscal-ytd',
  CUSTOM_DATES: 'custom-dates',
});

export const ReportType = Object.freeze({
  CURRENT_STATE_DASHBOARD,
  DONATIONS_OVER_TIME,
  NEW_DONOR_GROWTH,
  CAMPAIGN_OVERVIEW,
});

export const ReportDonorType = Object.freeze({
  INDIVIDUAL: 'individual',
  ORGANIZATION: 'organization',
});

export const SortDirection = Object.freeze({
  ASC: 'asc',
  DESC: 'desc',
});

export const SortField = Object.freeze({
  CREATED_ON: 'created',
  DISPLAY_NAME: 'display_name',
  DONATED: 'donated',
});

const ReportFilterPropType = PropTypes.shape({
  dateType: PropTypes.oneOf(Object.values(DateType)),
  reportType: PropTypes.oneOf(Object.values(ReportType)),
  startDate: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(Date),
  ]),
  endDate: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
  sortField: PropTypes.oneOf(Object.values(SortField)),
  sortDirection: PropTypes.oneOf(Object.values(SortDirection)),
});

export default ReportFilterPropType;
