import React from 'react';
import { Grid, Box, makeStyles, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import ConstituentSearchField from 'components/common/form/ConstituentSearchField';
import { EMPTY_FUNC, INTERACTION_METHOD_FIELD_CONFIG } from 'lib/constants';
import {
  TextSelectField,
  Visible,
  Loader,
  CustomButton,
  Gap,
  NotesInput,
  ExperienceRating,
} from 'components';
import { Close } from '@material-ui/icons';
import { DateInput } from 'components/common/form';
import { translate } from 'lib/intl';
import { colors } from 'styles/theme';
import { useNewInteractionForm } from 'lib/hooks';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    background: colors.white,
    width: '360px',
    flexDirection: 'column',
    [theme.breakpoints.down('xs')]: {
      width: 'auto',
    },
  },
  itemContainer: {
    height: '56px',
    width: '100%',
    borderBottom: `1px solid ${colors.lightGrey}`,
    position: 'relative',
  },
  item: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    padding: '0 16px',
  },
  icon: {
    height: '18px',
    width: '18px',
  },
  formContainer: {
    padding: '20px 20px 0',
    overflowY: 'auto',
  },
  rowItem: {
    paddingTop: '12px',
  },
  experienceItem: {
    paddingTop: '24px',
  },
  notesContainer: {
    paddingTop: '30px',
  },
  buttonContainer: {
    borderTop: `1px solid ${colors.progressLightGrey}`,
  },
  loaderContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
  },
}));

const InteractionForm = ({ data, onClose, preSelectedDonorId, onSuccess }) => {
  const styles = useStyles();
  const {
    // common
    isEditing,
    teamMembers,
    campaigns,

    // statuses
    error,
    isLoading,
    showFormContent,

    // form fields and their setters
    selectedDonor,
    setSelectedDonor,
    selectedDate,
    setSelectedDate,
    interactionMethod,
    setInteractionMethod,
    narrative,
    setNarrative,
    employeesInvolved,
    setEmployeesInvolved,
    experienceRating,
    setExperienceRating,
    selectedCampaign,
    setSelectedCampaign,

    // form erros
    fieldErrors,
    showFieldErrors,

    // handlers
    handleSave,
  } = useNewInteractionForm({ data, onClose, onSuccess });

  return (
    <>
      <Box className={styles.itemContainer}>
        <Box
          className={styles.item}
          style={{ justifyContent: 'space-between' }}
        >
          <Typography variant="button" style={{ textTransform: 'capitalize' }}>
            {translate(isEditing ? 'EDIT_INTERACTION' : 'ADD_NEW_INTERACTION')}
          </Typography>
          <Close
            data-testid="interaction-close"
            className={styles.icon}
            style={{ cursor: 'pointer' }}
            onClick={onClose}
          />
        </Box>
      </Box>
      <Grid className={styles.formContainer} container>
        <Visible
          fallback={
            <Box className={styles.loaderContainer}>
              <Loader />
            </Box>
          }
          when={showFormContent}
        >
          <Grid container spacing={2} style={{ alignItems: 'flex-start' }}>
            <Grid item xs={12} sm={8}>
              <ConstituentSearchField
                id="contact-name-select-field"
                initialConstituentId={data?.constituentId || preSelectedDonorId}
                disabled={isEditing}
                value={selectedDonor}
                setValue={setSelectedDonor}
                label={translate('INTERACTION_CONTACT_NAME')}
                error={showFieldErrors && fieldErrors?.selectedDonor}
                errorHelperText={translate(
                  'INTERACTION_CONTACT_NAME_ERROR_REQUIRED'
                )}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <DateInput
                id="interaction-date-picker-inline"
                label={translate('INTERACTION_DATE')}
                onChange={setSelectedDate}
                value={selectedDate}
              />
            </Grid>
          </Grid>
          <Grid className={styles.rowItem} item container xs={12}>
            <TextSelectField
              id="interaction-method-select-field"
              items={INTERACTION_METHOD_FIELD_CONFIG}
              value={interactionMethod}
              setValue={setInteractionMethod}
              label={translate('INTERACTION_METHOD')}
              error={showFieldErrors && fieldErrors?.interactionMethod}
              errorHelperText={translate('INTERACTION_METHOD_ERROR_REQUIRED')}
            />
          </Grid>
          <Grid className={styles.rowItem} item container xs={12}>
            <TextSelectField
              id="campaign-select-field"
              items={campaigns}
              value={selectedCampaign}
              setValue={setSelectedCampaign}
              label={translate('INTERACTION_CAMPAIGN')}
              error={showFieldErrors && fieldErrors?.campaign}
              errorHelperText={translate('INTERACTION_CAMPAIGN_ERROR_REQUIRED')}
            />
          </Grid>
          <Grid className={styles.notesContainer} item container xs={12}>
            <NotesInput
              label={translate('INTERACTION_NARRATIVE')}
              notes={narrative}
              setNotes={setNarrative}
              maxLength={10000}
            />
          </Grid>
          <Grid className={styles.rowItem} item container xs={12}>
            <TextSelectField
              id="interaction-employees-select-field"
              items={teamMembers}
              multiple
              value={employeesInvolved}
              setValue={setEmployeesInvolved}
              label={translate('INTERACTION_EMPLOYEES_INVOLVED')}
              error={showFieldErrors && fieldErrors?.employeesInvolved}
              errorHelperText={translate(
                'INTERACTION_EMPLOYEES_INVOLVED_ERROR_REQUIRED'
              )}
            />
          </Grid>
          <Grid className={styles.experienceItem} item container xs={12}>
            <ExperienceRating
              experience={experienceRating}
              error={showFieldErrors && fieldErrors?.experienceRating}
              errorHelperText={translate(
                'INTERACTION_EXPERIENCE_ERROR_REQUIRED'
              )}
              setExperience={setExperienceRating}
            />
          </Grid>
        </Visible>
        {/* Spacer div to preserve 'padding space on safari' as it is collapsed */}
        <div style={{ height: '20px', width: '100%' }} />
      </Grid>
      <Box
        className={styles.buttonContainer}
        display="flex"
        alignItems="center"
        justifyContent={isLoading ? 'center' : 'flex-start'}
        px={2}
        py={3}
      >
        <Visible fallback={<Loader />} when={!isLoading}>
          <Visible when={Boolean(error)}>
            <Box px={1}>
              <Typography color="error">
                {translate('GENERIC_ERROR')}
              </Typography>
            </Box>
          </Visible>
          <Box
            display="flex"
            flex="1"
            flexDirection="row"
            justifyContent="flex-end"
          >
            <CustomButton
              buttonType="link"
              color="primary"
              dataTestId="new-donation-modal-cancel-button"
              label={translate('CANCEL')}
              onClick={onClose}
            />
            <Gap />
            <CustomButton
              buttonType="primary"
              dataTestId="new-donation-modal-save-button"
              label={translate('SAVE')}
              onClick={handleSave}
            />
          </Box>
        </Visible>
      </Box>
    </>
  );
};

InteractionForm.propTypes = {
  data: PropTypes.shape({
    constituentId: PropTypes.string,
  }),
  onClose: PropTypes.func,
  preSelectedDonorId: PropTypes.string,
  onSuccess: PropTypes.func,
};

InteractionForm.defaultProps = {
  data: null,
  onClose: EMPTY_FUNC,
  preSelectedDonorId: null,
  onSuccess: EMPTY_FUNC,
};

export { InteractionForm };
