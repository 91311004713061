import React from 'react';
import { bool } from 'prop-types';
import { prop } from 'rambdax';
import { Box } from '@material-ui/core';
import styled from 'styled-components';
import { colors } from 'styles/theme';

const StyledUnderline = styled(Box)`
  background-color: ${colors.grey500};
  height: 2px;
  position: relative;
  top: 2px;
`;

const AnimatedLine = styled(Box)`
  background-color: ${prop('color')};
  height: 2px;
  left: 0;
  position: absolute;
  right: 0;
  transform: scaleX(0);
  transition: transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;

  &.active {
    transform: scaleX(1);
  }
`;

const AnimatedUnderline = ({ active, error }) => (
  <StyledUnderline>
    <AnimatedLine
      className={active ? 'active' : ''}
      color={error ? colors.errorMain : colors.primaryGreen}
    />
  </StyledUnderline>
);

AnimatedUnderline.propTypes = {
  active: bool,
  error: bool,
};

AnimatedUnderline.defaultProps = {
  active: false,
  error: false,
};

export { AnimatedUnderline };
