import React, { useState } from 'react';
import { bool } from 'prop-types';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Star from '@material-ui/icons/Star';
import { CustomLink, ProfileDescriptorLink, Loader } from 'components';
import { ShowForViews } from 'components/common/ShowForViews';
import { translate } from 'lib/intl';
import { StyledDialog } from 'lib/utils';
import { USER_VIEW_ENUMS } from 'lib/constants/common';
import ConstituentPropType from 'lib/types/ConstituentPropType';
import ConstituentCardItemActionsAssign from './ConstituentCardItemActionsAssign';
import useUpdateConstituentAssignment from './useUpdateConstituentAssignment';

const ConstituentInfoAssigned = ({ constituent, dataFetching }) => {
  const [isOpen, setIsOpen] = useState(false);
  const { isUpdating } = useUpdateConstituentAssignment();

  const { developers, ownerOrgId } = constituent;

  const AssignedDevelopers = developers
    // Sort primary developer to the top of the list
    ?.sort((a) => (a.primary ? -1 : 1))
    ?.map((developer) => (
      <Box className="flex pt-2 items-center" key={developer.userId}>
        <ProfileDescriptorLink
          displayName={developer.displayName}
          ownerOrgId={ownerOrgId}
          userId={developer.userId}
        />
        {developer.primary && <Star className="w-4 h-4 ml-3" color="primary" />}
      </Box>
    ));

  const closeActionDialog = () => setIsOpen(false);

  return (
    <Box>
      <Box className="flex justify-between">
        <Typography variant="subtitle1">
          {translate('DONOR_ASSIGNEE_INFO_TITLE')}
        </Typography>
        <ShowForViews showFor={[USER_VIEW_ENUMS.ADMIN, USER_VIEW_ENUMS.EDITOR]}>
          <CustomLink
            data-testid="assign-constituent-btn"
            onClick={() => setIsOpen(true)}
          >
            {translate('DONOR_ASSIGNEE_INFO_CHANGE')}
          </CustomLink>
        </ShowForViews>
      </Box>
      {dataFetching || isUpdating ? (
        <Box className="flex justify-center pt-10">
          <Loader />
        </Box>
      ) : (
        AssignedDevelopers
      )}
      <StyledDialog
        fullWidth
        maxWidth="md"
        open={isOpen}
        onClose={closeActionDialog}
      >
        <ConstituentCardItemActionsAssign
          constituent={constituent}
          onClose={closeActionDialog}
        />
      </StyledDialog>
    </Box>
  );
};

ConstituentInfoAssigned.propTypes = {
  constituent: ConstituentPropType,
  dataFetching: bool,
};

ConstituentInfoAssigned.defaultProps = {
  constituent: {},
  dataFetching: false,
};

export default ConstituentInfoAssigned;
