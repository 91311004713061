import { createApiRequest } from 'lib/utils';

const sendInvites = (ownerOrgPubId, data) =>
  createApiRequest({
    data,
    path: `orgs/${ownerOrgPubId}/invites/bulk-create`,
    throwErrors: true,
  });

const fetchInvites = (ownerOrgPubId) =>
  createApiRequest({
    path: `orgs/${ownerOrgPubId}/invites`,
    method: 'get',
    throwErrors: true,
  });

const updateInvite = (ownerOrgPubId, { inviteId, ...data }) =>
  createApiRequest({
    data,
    path: `orgs/${ownerOrgPubId}/invites/${inviteId}`,
    method: 'put',
    throwErrors: true,
  });

const removeInvites = (ownerOrgPubId, { inviteId }) =>
  createApiRequest({
    method: 'delete',
    path: `orgs/${ownerOrgPubId}/invites/${inviteId}`,
    throwErrors: true,
  });

export const invitesService = {
  fetchInvites,
  sendInvites,
  updateInvite,
  removeInvites,
};
