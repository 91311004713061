import React from 'react';
import { AuthContainer } from 'components/auth';
import Box from '@material-ui/core/Box';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { withNewThemeProvider } from 'components/common/NewThemeProvider';
import { translate } from 'lib/intl';
import { PUBLIC_ROUTES } from 'lib/constants';
import { useRegistration } from './registrationPagesHooks';

const useStyles = makeStyles((theme) => ({
  checkEmail: {
    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(3),
    },
  },
  instructions: {
    fontSize: 'inherit',
    fontWeight: 'inherit',
    letterSpacing: 'inherit',
    lineHeight: 'inherit',
  },
}));

const RegistrationSuccessPageNoTheme = () => {
  const classes = useStyles();
  const { orgName } = useRegistration();

  return (
    <AuthContainer
      size="sm"
      navLinkText={translate('SIGN_IN')}
      navLinkRoute={PUBLIC_ROUTES.LOGIN}
      orgName={orgName}
    >
      <Box mb={3}>
        <Typography variant="h1">
          {translate('REGISTER_SUCCESS_HEADER')}
        </Typography>
      </Box>

      <Box mb={3}>
        <Typography variant="h2" className={classes.checkEmail}>
          {translate('REGISTER_EMAIL_LINK_CHECK')}
        </Typography>
      </Box>

      <Typography>
        {translate('REGISTER_EMAIL_LINK_CLOSE_TAB', {
          a: (chunks) => (
            <Link
              key="sign-in-link"
              className={classes.instructions}
              href={PUBLIC_ROUTES.LOGIN}
              color="primary"
            >
              {chunks}
            </Link>
          ),
        })}
      </Typography>
    </AuthContainer>
  );
};

export const RegistrationSuccessPage = withNewThemeProvider(
  RegistrationSuccessPageNoTheme
);
