// import { DEFAULT_PAGE_SIZE } from 'lib/constants/common';
import { fetchReportTypes } from 'rdx/actions/reportActions';

const initialState = {
  data: {},
  dataLoaded: false,
  error: null,
  hasNextPage: null,
  isFetching: false,
  pageFrom: 0,
};

const { REPORT_LIST_REQUEST, REPORT_LIST_SUCCESS, REPORT_LIST_FAILURE } =
  fetchReportTypes;

export default function reportsReducer(state = initialState, action) {
  switch (action.type) {
    case REPORT_LIST_REQUEST: {
      return {
        ...state,
        dataLoaded: false,
        isFetching: true,
      };
    }
    case REPORT_LIST_SUCCESS: {
      return {
        ...state,
        isFetching: false,
        dataLoaded: true,
        data: { ...state.data, ...action.data },
        // pageFrom: action.data.searchParams.pageFrom + DEFAULT_PAGE_SIZE,
        // hasNextPage: action?.data.totalHits > items?.length,
      };
    }
    case REPORT_LIST_FAILURE: {
      return {
        ...state,
        dataLoaded: false,
        isFetching: false,
        error: action.error,
      };
    }
    default:
      return state;
  }
}
