import React from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import Alert from '@material-ui/lab/Alert';
import Box from '@material-ui/core/Box';
// import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/styles/makeStyles';
import { translate } from 'lib/intl';
import CustomDialog from 'components/common/CustomDialog';
import { withNewThemeProvider } from 'components/common/NewThemeProvider';
import {
  // ControlledSwitch,
  ControlledTextField,
  // ControlledTextSelectField
} from 'components/common/form';
import { URL_REGEXP } from 'lib/utils/urls';
import { useAnnouncementModal } from 'lib/hooks/announcements';
import {
  MESSAGE_MAX_LENGTH,
  DURATION_OPTIONS,
} from './AnnouncementModalConfig';

const useStyles = makeStyles((theme) => ({
  paper: {
    maxWidth: 600,
    [theme.breakpoints.down('xs')]: {
      margin: `${theme.spacing(4)}px ${theme.spacing(1.25)}px`,
      maxWidth: '100%',
      width: '100%',
    },
  },
  multilineInput: {
    marginBottom: 0,
  },
  formControl: {
    marginBottom: 0,
    top: theme.spacing(0.625),
  },
  formHelperText: {
    textAlign: 'right',
  },
}));

const AnnouncementModalNoTheme = ({ isGlobal, handleClose, open }) => {
  const classes = useStyles();

  const { control, errors, handleSubmit, watch } = useForm({
    defaultValues: {
      isPinned: false,
      link: '',
      message: '',
      pinnedDuration: DURATION_OPTIONS[0],
    },
    mode: 'onBlur',
  });

  const { hasError, isLoading, onClose, onSubmit, resetAnnouncement } =
    useAnnouncementModal({ handleClose, isGlobal });

  // const isPinned = watch('isPinned');
  const messageLength = watch('message').length;
  const messageHelperText = translate('ANNOUNCEMENT_MESSAGE_CHARACTER_COUNT', {
    value: `${messageLength}/${MESSAGE_MAX_LENGTH}`,
  });

  return (
    <CustomDialog
      actionButtonLabel={translate('PUBLISH')}
      dialogHeader={translate(
        isGlobal ? 'ADD_GLOBAL_ANNOUNCEMENT' : 'ADD_ANNOUNCEMENT'
      )}
      disableActionButton={isLoading}
      fullWidth
      handleActionButton={handleSubmit(onSubmit)}
      classes={{ paper: classes.paper }}
      onClose={onClose}
      open={open}
    >
      <form>
        {isGlobal && (
          <Box mb={2}>
            <Alert icon={false} severity="warning">
              {translate('GLOBAL_ANNOUNCEMENT_INFO')}
            </Alert>
          </Box>
        )}
        {hasError && (
          <Box mb={2}>
            <Alert icon={false} onClose={resetAnnouncement} severity="error">
              {translate('ANNOUNCEMENT_CREATION_ERROR')}
            </Alert>
          </Box>
        )}
        <ControlledTextField
          classes={{ root: classes.multilineInput }}
          control={control}
          disabled={isLoading}
          error={!!errors.message}
          FormHelperTextProps={{ classes: { root: classes.formHelperText } }}
          fullWidth
          helperText={messageHelperText}
          multiline
          inputProps={{
            'data-testid': 'announcement-message-input',
            maxLength: MESSAGE_MAX_LENGTH,
          }}
          name="message"
          label={translate('ANNOUNCEMENT_MESSAGE_LABEL')}
          required
          rows={4}
          rules={{ required: messageHelperText }}
          variant="filled"
        />
        <ControlledTextField
          error={!!errors.link}
          control={control}
          disabled={isLoading}
          FormHelperTextProps={{ classes: { root: classes.formHelperText } }}
          fullWidth
          helperText={errors.link?.message}
          inputProps={{ 'data-testid': 'announcement-link-input' }}
          name="link"
          label={translate('ANNOUNCEMENT_LINK_LABEL')}
          rules={{
            pattern: {
              value: URL_REGEXP,
              message: translate('INVALID_ANNOUNCEMENT_LINK_LABEL'),
            },
          }}
        />
        {/* TODO: uncomment once unpinning logic is fixed on api side */}
        {/* <Box display="flex" flexWrap="wrap" justifyContent="space-between" minHeight={60}>
          <Box alignItems="center" display="flex" mr={1}>
            <Box mr={1.5}>
              <Typography>{translate('PIN_ANNOUNCEMENT_LABEL')}</Typography>
            </Box>
            <Box
              alignItems="center"
              component="label"
              display="flex"
              for="announcement-pinned-switch"
            >
              <Typography>{translate('NO')}</Typography>
              <ControlledSwitch
                color="primary"
                control={control}
                inputProps={{ 'data-testid': 'announcement-pinned-switch' }}
                disabled={isLoading}
                id="announcement-pinned-switch"
                name="isPinned"
              />
              <Typography>{translate('YES')}</Typography>
            </Box>
          </Box>
          {isPinned && (
            <Box alignItems="center" display="flex">
              <Box component="label" for="announcement-pinned-duration-selector" mr={1}>
                <Typography>{translate('ANNOUNCEMENT_PINNED_DURATION')}</Typography>
              </Box>
              <Box width={100}>
                <ControlledTextSelectField
                  control={control}
                  disabled={isLoading}
                  disableClearable
                  formControlProps={{ classes: { root: classes.formControl } }}
                  id="announcement-pinned-duration-selector"
                  items={DURATION_OPTIONS}
                  name="pinnedDuration"
                />
              </Box>
            </Box>
          )}
        </Box> */}
      </form>
    </CustomDialog>
  );
};

AnnouncementModalNoTheme.propTypes = {
  isGlobal: PropTypes.bool,
  handleClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

AnnouncementModalNoTheme.defaultProps = {
  isGlobal: false,
};

export const AnnouncementModal = withNewThemeProvider(AnnouncementModalNoTheme);
