import userActionTypes from 'rdx/actionTypes';

const initialState = {
  isFetching: false,
  error: null,
  entity: null,
};

const {
  FETCH_FEED_ENTITY_REQUEST,
  FETCH_FEED_ENTITY_SUCCESS,
  FETCH_FEED_ENTITY_FAILURE,
  FETCH_FEED_ENTITY_RESET,

  UPDATE_CAMPAIGN_SUCCESS,
} = userActionTypes;

export function feedEntity(state = initialState, action) {
  switch (action.type) {
    case FETCH_FEED_ENTITY_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: null,
        entity: null,
      };
    case FETCH_FEED_ENTITY_SUCCESS:
      return {
        ...state,
        isFetching: false,
        entity: action?.data,
      };
    case FETCH_FEED_ENTITY_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action?.error,
      };
    case FETCH_FEED_ENTITY_RESET:
      return {
        ...state,
        isFetching: false,
        error: null,
        entity: null,
      };
    case UPDATE_CAMPAIGN_SUCCESS: {
      return {
        ...state,
        entity: action?.data,
      };
    }
    default:
      return state;
  }
}
