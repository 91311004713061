import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Box } from '@material-ui/core';

const BasicPageLayout = React.forwardRef(
  ({ children, className, ...props }, ref) => {
    return (
      <Box
        className={classNames(
          className,
          'flex flex-1 flex-col md:flex-row w-full'
        )}
        {...props}
        ref={ref}
      >
        {children}
      </Box>
    );
  }
);

BasicPageLayout.propTypes = {
  ...Box.propTypes,
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
};

BasicPageLayout.defaultProps = {
  className: '',
};

export default BasicPageLayout;
