import React from 'react';
import { ThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import PropTypes from 'prop-types';
import newTheme from 'styles/newTheme';

export default function NewThemeProvider({ children }) {
  return (
    <ThemeProvider theme={newTheme}>
      <CssBaseline />
      {children}
    </ThemeProvider>
  );
}

NewThemeProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const withNewThemeProvider = (Component) => (props) => {
  return (
    <NewThemeProvider>
      <Component {...props} />
    </NewThemeProvider>
  );
};
