import React from 'react';
import { Route, Switch, useHistory, useLocation } from 'react-router-dom';
import { LockedWithPen } from 'assets/images';
import {
  BasicPageLayout,
  BasicPageTitle,
  CommonInfoSection,
} from 'components/common';
import { PageTabs } from 'components/common/PageTabs';
import { translate } from 'lib/intl';
import { getTabId } from 'lib/utils/tabsUtils';
import { AUTH_ROUTES } from 'lib/constants/routes';
import OrgManagementPage from './OrgManagementPage';
import FeatureFlagManagementPage from './FeatureFlagManagementPage';

const PAGE_TABS = [
  {
    id: 'orgManagement',
    default: true,
    label: translate('SUPER_ADMIN_PAGE_TABS_ORGS'),
    path: AUTH_ROUTES.ORG_MANAGEMENT,
  },
  {
    id: 'featureFlagManagement',
    label: translate('SUPER_ADMIN_PAGE_TABS_FEATURE_FLAGS'),
    path: AUTH_ROUTES.FEATURE_FLAG_MANAGEMENT,
  },
];

export default function SuperAdminHomePage() {
  const location = useLocation();
  const history = useHistory();
  const tabs = PAGE_TABS;

  const [tabIdx, setTabIdx] = React.useState(getTabId(tabs, location)) || 0;

  const onTabClick = React.useCallback((idx) => {
    const { path: tabPath } = tabs[idx];
    if (tabPath) {
      setTabIdx(idx);
      history.push(tabPath);
    }
  }, []);

  return (
    <BasicPageLayout>
      <CommonInfoSection>
        <div className="flex justify-between">
          <BasicPageTitle
            iconSrc={LockedWithPen}
            iconAlt={translate('SUPER_ADMIN_NAV_ITEM_ICON_ALT')}
            title={translate('SUPER_ADMIN_PAGE_TITLE')}
          />
        </div>
      </CommonInfoSection>

      <PageTabs.Wrapper>
        <div className="w-full">
          <PageTabs
            tabs={tabs}
            defaultTabIdx={tabIdx}
            onChange={onTabClick}
            tabIdx={tabIdx}
          />
        </div>

        <PageTabs.Content>
          <div className="p-4">
            <Switch>
              <Route
                exact
                path={[AUTH_ROUTES.ADMIN, AUTH_ROUTES.ORG_MANAGEMENT]}
              >
                <OrgManagementPage />
              </Route>
              <Route exact path={AUTH_ROUTES.FEATURE_FLAG_MANAGEMENT}>
                <FeatureFlagManagementPage />
              </Route>
            </Switch>
          </div>
        </PageTabs.Content>
      </PageTabs.Wrapper>
    </BasicPageLayout>
  );
}
