import React from 'react';
import PropTypes from 'prop-types';
import { isMobileView } from 'lib/utils';
import { Feed } from 'components';
import { AUTH_ROUTES } from 'lib/constants';
import { generatePath } from 'react-router-dom';
import useConstituentFeed from 'components/constituents/useConstituentFeed';

const ConstituentHistoryContent = ({
  constituentId,
  layoutContainerId,
  layoutContainerRef,
  topOffset,
}) => {
  const isMobile = isMobileView();
  const { feed, getFeedPage, setActiveFeedItem, activeFeedItemId } =
    useConstituentFeed(constituentId);

  return (
    <Feed
      isMobile={isMobile}
      layoutContainerId={layoutContainerId}
      layoutContainerRef={layoutContainerRef}
      feed={feed}
      getFeedPage={getFeedPage}
      activeFeedItemId={activeFeedItemId}
      onFeedItemClicked={(id) => setActiveFeedItem(constituentId, id)}
      closeSliderRedirectRoute={generatePath(AUTH_ROUTES.CONSTITUENT, {
        constituentId,
      })}
      topOffset={topOffset}
    />
  );
};

ConstituentHistoryContent.propTypes = {
  constituentId: PropTypes.string,
  layoutContainerId: PropTypes.string,
  layoutContainerRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]),
  topOffset: PropTypes.string,
};

ConstituentHistoryContent.defaultProps = {
  constituentId: '',
  layoutContainerId: null,
  layoutContainerRef: null,
  topOffset: '',
};

export default ConstituentHistoryContent;
