import React from 'react';
import PropTypes from 'prop-types';
import Alert from '@material-ui/lab/Alert';
import Box from '@material-ui/core/Box';
import { translate } from 'lib/intl';
import { RESOURCE_EXISTS } from 'lib/constants';

export default function CreateOrgAlert({ response, onClose }) {
  if (!response) {
    return null;
  }

  let message = translate('ORG_MGMT_CREATE_ORG_SUCCESS');

  if (response.error) {
    message =
      response.error_enum === RESOURCE_EXISTS
        ? translate('ORG_MGMT_CREATE_ORG_ERROR_ALREADY_EXISTS')
        : translate('ORG_MGMT_CREATE_ORG_ERROR');
  }

  return (
    <Box mt={1} mb={1}>
      <Alert
        icon={false}
        onClose={onClose}
        severity={!response.error ? 'success' : 'error'}
      >
        {message}
      </Alert>
    </Box>
  );
}

CreateOrgAlert.propTypes = {
  response: PropTypes.shape({
    data: PropTypes.object,
    error: PropTypes.bool.isRequired,
    error_enum: PropTypes.string,
    message: PropTypes.string,
    ops: PropTypes.object,
  }),
  onClose: PropTypes.func.isRequired,
};

CreateOrgAlert.defaultProps = {
  response: null,
};
