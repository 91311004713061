import React from 'react';
import { pluck } from 'rambdax';
import { translate } from 'lib/intl';
import { TeamMemberCellWrapper } from './team-member-cell-wrapper';
import { TeamMemberPermissionCell } from './TeamMemberPermissionCell';
import { TeamMemberLoaderCell } from './team-member-loader-cell';
import { TeamMemberLinkCell } from './team-member-link-cell';
import { TeamMemberTrashcanCell } from './team-member-trashcan-cell';
import { TeamMemberInviteStatusCell } from './team-member-invite-status-cell';
import { TeamMemberResendCell } from './TeamMemberResendCell';
import { TeamMemberMainInfoCell } from './team-member-main-info-cell';

// Components for Team Members
const TextCell = TeamMemberCellWrapper({ cursor: 'pointer' });
const MainInfoCell = TeamMemberCellWrapper({
  CellComponent: TeamMemberMainInfoCell,
  cursor: 'pointer',
});
const LinkCell = TeamMemberCellWrapper({
  CellComponent: TeamMemberLinkCell,
  cursor: 'pointer',
});
const PermissionCell = TeamMemberCellWrapper({
  CellComponent: TeamMemberPermissionCell,
  cursor: 'pointer',
});
const LoaderCell = TeamMemberCellWrapper({
  CellComponent: TeamMemberLoaderCell,
});
const TrashcanCell = TeamMemberCellWrapper({
  CellComponent: TeamMemberTrashcanCell,
  cursor: 'pointer',
});

// Components for Invites
const TextCellForInvites = TeamMemberCellWrapper({
  isForInvites: true,
});
const ResendCellForInvites = TeamMemberCellWrapper({
  CellComponent: TeamMemberResendCell,
  isForInvites: true,
});
const TrashcanCellForInvites = TeamMemberCellWrapper({
  CellComponent: TeamMemberTrashcanCell,
  isForInvites: true,
});
const PermissionCellForInvites = TeamMemberCellWrapper({
  CellComponent: TeamMemberPermissionCell,
  isForInvites: true,
});
const StatusCellForInvites = TeamMemberCellWrapper({
  CellComponent: TeamMemberInviteStatusCell,
  isForInvites: true,
});
const LoaderCellForInvites = TeamMemberCellWrapper({
  CellComponent: TeamMemberLoaderCell,
  isForInvites: true,
});

// a helper util for rendering cells
const renderCell = (Component) => (props) => <Component {...props} />;

const getGroupsComparableValue = (value) =>
  pluck('groupDisplayName', value).join(', ');

const TEAM_MEMBERS_COLUMNS = [
  {
    id: 'team-member-column',
    field: 'displayName',
    headerName: translate('COMPANY_TEAM_MEMBER_NAME_LABEL'),
    renderCell: renderCell(MainInfoCell),
  },
  // {
  //   id: 'team-member-last-visit-column',
  //   field: 'lastVisit',
  //   getComparableValue: value => new Date(value).getTime(),
  //   headerName: translate('COMPANY_TEAM_MEMBER_LAST_VISIT_LABEL'),
  //   valueFormatter: ({ value } = { value: '' }) => (value ? formatDistance(value) : ''),
  //   renderCell: renderCell(TextCell),
  //   style: { minWidth: '140px' },
  // },
  {
    id: 'team-member-groups-column',
    field: 'groups',
    getComparableValue: getGroupsComparableValue,
    headerName: translate('COMPANY_TEAM_MEMBER_PERMISSIONS_LABEL'),
    renderCell: renderCell(PermissionCell),
    disableClickEventBubbling: true,
  },
  {
    id: 'team-member-remove-column',
    field: 'id',
    headerName: '',
    renderCell: renderCell(TrashcanCell),
    sortable: false,
    disableClickEventBubbling: true,
    style: { width: '50px' },
  },
];

const INVITES_COLUMNS = [
  {
    id: 'invites-status-column',
    field: 'status',
    headerName: translate('COMPANY_TEAM_MEMBER_STATUS_LABEL'),
    renderCell: renderCell(StatusCellForInvites),
  },
  {
    id: 'invites-email-column',
    field: 'email',
    headerName: translate('COMPANY_TEAM_MEMBER_EMAIL_LABEL'),
    renderCell: renderCell(TextCellForInvites),
    disableClickEventBubbling: true,
  },
  {
    id: 'invites-groups-column',
    field: 'groups',
    getComparableValue: getGroupsComparableValue,
    headerName: translate('COMPANY_TEAM_MEMBER_PERMISSIONS_LABEL'),
    renderCell: renderCell(PermissionCellForInvites),
    disableClickEventBubbling: true,
  },
  {
    id: 'invites-resend-column',
    field: 'id',
    headerName: '',
    renderCell: renderCell(ResendCellForInvites),
    sortable: false,
    disableClickEventBubbling: true,
  },
  {
    id: 'invites-trashcan-column',
    field: 'inviteId',
    headerName: '',
    renderCell: renderCell(TrashcanCellForInvites),
    sortable: false,
    disableClickEventBubbling: true,
    style: { width: '50px' },
  },
];

const SEARCHABLE_FIELDS = ['displayName', 'email', 'groups', 'status'];

export {
  TEAM_MEMBERS_COLUMNS,
  INVITES_COLUMNS,
  TextCell,
  LinkCell,
  PermissionCell,
  LoaderCell,
  TrashcanCell,
  TextCellForInvites,
  ResendCellForInvites,
  TrashcanCellForInvites,
  PermissionCellForInvites,
  StatusCellForInvites,
  LoaderCellForInvites,
  SEARCHABLE_FIELDS,
};
