import userActionTypes from 'rdx/actionTypes';

const {
  FETCH_CAMPAIGN_REQUEST,
  FETCH_CAMPAIGN_SUCCESS,
  FETCH_CAMPAIGN_FAILURE,
  UPDATE_CAMPAIGN_SUCCESS,
} = userActionTypes;

const initialState = {};

export function campaignReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_CAMPAIGN_REQUEST:
      return {
        ...state,
        [action.campaignId]: {
          dataLoaded: false,
          isFetching: true,
          error: null,
        },
      };
    case FETCH_CAMPAIGN_SUCCESS:
      return {
        ...state,
        [action.campaignId]: {
          data: action.data,
          dataLoaded: true,
          isFetching: false,
          error: null,
        },
      };
    case FETCH_CAMPAIGN_FAILURE:
      return {
        ...state,
        [action.campaignId]: {
          dataLoaded: false,
          isFetching: false,
          error: action.error,
        },
      };
    case UPDATE_CAMPAIGN_SUCCESS: {
      const campaignId = action.data?.campaignId;

      return campaignId && state[campaignId]
        ? {
            ...state,
            [campaignId]: {
              data: action.data,
              dataLoaded: true,
              isFetching: false,
              error: null,
            },
          }
        : state;
    }
    default:
      return state;
  }
}
