import React from 'react';
import PageSizes from 'lib/constants/PageSizes';
import { useHistory, useLocation } from 'react-router-dom';

const usePaginationParams = () => {
  const history = useHistory();
  const location = useLocation();

  const search = new URLSearchParams(location.search);
  const page = +(search.get('page') || 1);
  const pageSize = +(search.get('page_size') || PageSizes[0]);

  const updatePage = React.useCallback(
    (newPage) => {
      if (newPage === page) return;
      const newSearch = new URLSearchParams(location.search);
      newSearch.set('page', newPage);
      history.push({
        pathname: location.pathname,
        search: `?${newSearch.toString()}`,
      });
    },
    [page, location]
  );

  const updatePageSize = React.useCallback(
    (newPageSize) => {
      if (newPageSize === pageSize) return;
      const newSearch = new URLSearchParams(location.search);
      newSearch.set('page', 1);
      newSearch.set('page_size', newPageSize);
      history.replace({
        pathname: location.pathname,
        search: `?${newSearch.toString()}`,
      });
    },
    [pageSize, location]
  );

  return {
    page,
    pageSize,
    updatePage,
    updatePageSize,
  };
};

export default usePaginationParams;
