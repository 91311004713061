import { createApiRequest } from 'lib/utils';

const fetchUsers = (ownerOrgId, userId) =>
  createApiRequest({
    method: 'get',
    path: `orgs/${ownerOrgId}/users/${userId}`,
  });

// TODO: add pagination
const fetchUsersList = (ownerOrgId) =>
  createApiRequest({
    method: 'get',
    path: `orgs/${ownerOrgId}/users`,
  });

const updateUsers = (ownerOrgId, userId, data) =>
  createApiRequest({
    data,
    method: 'put',
    path: `orgs/${ownerOrgId}/users/${userId}`,
    throwErrors: true,
  });

export const usersService = {
  fetchUsers,
  fetchUsersList,
  updateUsers,
};
