import React from 'react';
import { AuthContainer, RegistrationByInviteForm } from 'components/auth';
import NewThemeProvider from 'components/common/NewThemeProvider';
import { useRegistration } from './registrationPagesHooks';

const RegistrationByInvitePage = () => {
  const {
    code,
    email,
    orgName,
    registerAction,
    resetRegistrationState,
    isFetching,
    isRegistrationSuccessful,
    error,
  } = useRegistration();

  return (
    <NewThemeProvider>
      <AuthContainer size="sm" orgName={orgName}>
        <RegistrationByInviteForm
          code={code}
          email={email}
          orgName={orgName}
          registerAction={registerAction}
          resetRegistrationState={resetRegistrationState}
          isFetching={isFetching}
          isRegistrationSuccessful={isRegistrationSuccessful}
          registrationError={error}
        />
      </AuthContainer>
    </NewThemeProvider>
  );
};

export { RegistrationByInvitePage };
