import React from 'react';
import PropTypes from 'prop-types';
import Alert from '@material-ui/lab/Alert';
import Box from '@material-ui/core/Box';
import { determineAlertMessage } from 'components/featureFlagManagement/featureFlagUtils';

export default function FeatureFlagAlert({ response, type, onClose }) {
  if (!response) {
    return null;
  }

  const message = determineAlertMessage(type, response.error);

  return (
    <Box mt={3} mb={1}>
      <Alert
        icon={false}
        onClose={onClose}
        severity={!response.error ? 'success' : 'error'}
      >
        {message}
      </Alert>
    </Box>
  );
}

FeatureFlagAlert.propTypes = {
  response: PropTypes.shape({
    error: PropTypes.object,
    featureFlag: PropTypes.object,
  }),
  type: PropTypes.string,
  onClose: PropTypes.func.isRequired,
};

FeatureFlagAlert.defaultProps = {
  response: {
    error: null,
    featureFlag: null,
  },
  type: null,
};
