import { min } from 'rambdax';

/**
 * Rounds a number to N decemal places
 *
 * @param {number} number
 * @param {number} digits
 * @returns {number}
 */
const roundToFixed = (number, digits = 1) => {
  const powOfTen = 10 ** digits;

  return Math.floor(number * powOfTen) / powOfTen;
};

/**
 * Calculates Percent Of value Out Of total
 *
 * @param {number} value
 * @param {number} total
 * @returns {number|null}
 */
const getPercentage = (value, total) => {
  if (typeof value !== 'number' || typeof total !== 'number' || total === 0) {
    return null;
  }

  return roundToFixed((value / total) * 100, 2);
};

const getNormalizedPercentage = (value, total) => {
  return min(getPercentage(value, total), 100);
};

export { roundToFixed, getPercentage, getNormalizedPercentage };
