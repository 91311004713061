import recentlyViewedActions from 'rdx/actions/recentlyViewedActions';
import { useParams, useHistory } from 'react-router-dom';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { feedActions } from 'rdx/actions';

const useHome = () => {
  const history = useHistory();
  const { id: activeFeedItemId } = useParams();
  const dispatch = useDispatch();

  const { feed, ownerOrgId } = useSelector((state) => ({
    feed: state.orgFeed,
    ownerOrgId: state.authentication?.ownerOrgId,
  }));

  const getFeedPage = (pageFrom) =>
    dispatch(feedActions.fetchFeed({ ownerOrgId, pageFrom }));

  useEffect(() => {
    dispatch(recentlyViewedActions.fetchRecentlyViewedDonors());
  }, []);

  const setActiveFeedItem = (itemId) => history.push(`/feed/${itemId}`);

  return {
    feed,
    getFeedPage,
    setActiveFeedItem,
    activeFeedItemId,
  };
};

const useRecentlyViewedDonors = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(recentlyViewedActions.fetchRecentlyViewedDonors());
  }, []);

  const { recentlyViewedDonors } = useSelector((state) => ({
    recentlyViewedDonors: state.recentlyViewedDonors,
  }));

  return {
    recentlyViewedDonors,
  };
};

export { useHome, useRecentlyViewedDonors };
