import React from 'react';
import { loadD3Script } from 'lib/utils';

export default function useLoadD3() {
  const [hasD3, setHasD3] = React.useState(false);
  const [hasScriptError, setHasScriptError] = React.useState(false);

  async function loadD3() {
    try {
      await loadD3Script();
      setHasD3(true);
    } catch (e) {
      setHasScriptError(true);
      // eslint-disable-next-line no-console
      console.error('load script error', e);
    }
  }

  React.useEffect(() => {
    loadD3();
  }, []);

  return {
    hasD3,
    hasScriptError,
  };
}
