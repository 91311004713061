import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { translate } from 'lib/intl';

const CharacterCounter = ({ className, count, limit }) => {
  return (
    <div
      className={classNames(
        className,
        count >= limit ? 'text-error-main' : 'text-grey-900',
        'text-2xs'
      )}
    >
      {`${translate('CHARACTERS')}: ${count} / ${limit}`}
    </div>
  );
};

CharacterCounter.propTypes = {
  className: PropTypes.string,
  count: PropTypes.number.isRequired,
  limit: PropTypes.number.isRequired,
};

CharacterCounter.defaultProps = {
  className: '',
};

export default CharacterCounter;
