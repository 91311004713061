const FEATURE_FLAG_ADVANCED_REPORTS = 'advancedFilterReports';
const FEATURE_FLAG_DELETE_DONATIONS = 'deleteDonations';
const FEATURE_FLAG_EDIT_DONATIONS = 'editDonations';
const FEATURE_FLAG_INTEGRATIONS = 'turnOnIntegrations';
const FEATURE_FLAG_REPORTS = 'turnOnReports';
const FEATURE_FLAG_GRANTS = 'turnOnGrants';

const ALL_FLAGS_ARRAY = [
  FEATURE_FLAG_ADVANCED_REPORTS,
  FEATURE_FLAG_DELETE_DONATIONS,
  FEATURE_FLAG_EDIT_DONATIONS,
  FEATURE_FLAG_GRANTS,
  FEATURE_FLAG_INTEGRATIONS,
  FEATURE_FLAG_REPORTS,
];

export {
  FEATURE_FLAG_ADVANCED_REPORTS,
  FEATURE_FLAG_DELETE_DONATIONS,
  FEATURE_FLAG_EDIT_DONATIONS,
  FEATURE_FLAG_GRANTS,
  FEATURE_FLAG_INTEGRATIONS,
  FEATURE_FLAG_REPORTS,
  ALL_FLAGS_ARRAY,
};
