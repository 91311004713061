export const EMPLOYEE = {
  EMPLOYEE_PERSONAL_INFORMATION: 'Personal Information',
  EMPLOYEE_CHANGE_PASSWORD: 'Change Password',
  EMPLOYEE_ORIGINAL_PASSWORD: 'Original Password',
  EMPLOYEE_NEW_PASSWORD: 'New Password',
  EMPLOYEE_EDIT_FORM_ERRORS_GENERAL: 'Fix errors to submit',
  EMPLOYEE_EDIT_SUCCESS_MESSAGE: 'Successfully updated employee information',
  EMPLOYEE_UPDATE_PASSWORD_SUCCESS_MESSAGE:
    'Successfully updated user password',
};
