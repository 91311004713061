import queryString from 'query-string';
import { userService } from 'services/user';
import userActionTypes from 'rdx/actionTypes';
import { useSelector, useDispatch } from 'react-redux';
import { translate } from 'lib/intl';

const useRegistration = () => {
  const params = queryString.parse(location?.search);
  const { code, email, org_name: orgName } = params;
  // TODO DOT-87 refactor all dispatch actions to hook file
  const dispatch = useDispatch();

  const { isFetching, isRegistrationSuccessful, error, userEmail } =
    useSelector((state) => ({
      isFetching: state.registration.isFetching,
      isRegistrationSuccessful: state.registration.isRegistrationSuccessful,
      error: state.registration.error,
      userEmail: state.authentication.userEmail,
    }));

  const registerAction = (user) => {
    dispatch({ type: userActionTypes.REGISTER_REQUEST, user });

    userService.register(user).then(
      () => {
        dispatch({ type: userActionTypes.REGISTER_SUCCESS });
      },
      (registerError) => {
        // We do not want to fail the registration flow on the DotOrg Custom request at this time
        // Detect a failure on the instil request and pass the user through to be handled later
        // This may change in the future
        if (registerError && registerError.error_enum) {
          dispatch({ type: userActionTypes.REGISTER_SUCCESS });
        } else {
          dispatch({
            type: userActionTypes.REGISTER_FAILURE,
            error: registerError,
          });
        }
      }
    );
  };

  const adminRegisterAction = (user) => {
    dispatch({ type: userActionTypes.REGISTER_REQUEST }, user);

    userService.instilAdminRegisterAction(user).then(
      (response) => {
        if (!response) {
          dispatch({
            type: userActionTypes.REGISTER_FAILURE,
            error: { message: translate('GENERIC_ERROR') },
          });
        } else {
          const { data } = response;

          if (data && data.error) {
            dispatch({ type: userActionTypes.REGISTER_FAILURE, error: data });
          } else {
            dispatch({ type: userActionTypes.REGISTER_ADMIN_SUCCESS, data });
          }
        }
      },
      (registerError) => {
        dispatch({
          type: userActionTypes.REGISTER_FAILURE,
          error: registerError,
        });
      }
    );
  };

  const resetRegistrationState = () => ({
    type: userActionTypes.REGISTER_RESET_ALL,
  });

  return {
    code,
    email,
    orgName,
    registerAction,
    adminRegisterAction,
    resetRegistrationState,
    isFetching,
    isRegistrationSuccessful,
    error,
    userEmail,
  };
};

export { useRegistration };
