export const ICONS = {
  INSTIL_LOGO_ALT: 'Instil logo',
  CLOCKS_ICON_ALT: 'Clock icon',
  PAYMENT_CASH_ICON_ALT: 'Payment Cash Icon',
  PAYMENT_CHECK_ICON_ALT: 'Payment Check Icon',
  PAYMENT_CARD_ICON_ALT: 'Payment Card Icon',
  PAYMENT_BANK_WIRE_ICON_ALT: 'Payment Bank Wire Icon',
  PAYMENT_APP_ICON_ALT: 'Payment App Icon',
  PAYMENT_CRYPTO_ICON_ALT: 'Payment Crypto Icon',
  VOLUNTEER_EVENTS_ICON_ALT: 'Volunteer Events Icon',
  VOLUNTEER_CAMPAIGNS_ICON_ALT: 'Volunteer Campaigns Icon',
  VOLUNTEER_GENERAL_ICON_ALT: 'Volunteer General Icon',
  VOLUNTEER_GIFTS_ICON_ALT: 'Volunteer Gifts Icon',
  VOLUNTEER_OPERATIONS_ICON_ALT: 'Volunteer Operations Icon',
  VOLUNTEER_FOUNDATION_ICON_ALT: 'Volunteer Foundation Icon',
  VOLUNTEER_GOVERNANCE_ICON_ALT: 'Volunteer Governance Icon',
  VOLUNTEER_ADVOCACY_ICON_ALT: 'Volunteer Advocacy Icon',
  VOLUNTEER_OTHER_ICON_ALT: 'Volunteer Other Icon',
  DONATION_GENERAL_ICON_ALT: 'Donation General Icon',
  DONATION_PLEDGE_ICON_ALT: 'Donation Pledge Icon',
  DONATION_RECURRING_ICON_ALT: 'Donation Recurring Icon',
  DONATION_RESTRICTED_ICON_ALT: 'Donation Restricted Icon',
  DONATION_IN_KIND_ICON_ALT: 'Donation In Kind Icon',
  DONATION_PLANNED_ICON_ALT: 'Donation Planned Icon',
  DONATION_TIME_ICON_ALT: 'Donation Time Icon',
  DONATION_GRANT_ICON_ALT: 'Donation Grant Icon',
  DONATION_VERY_HIGH_VALUE_ICON_ALT: 'Very High Value Icon',
  DONATION_HIGH_VALUE_ICON_ALT: 'High Value Icon',
  DONATION_MODERATE_VALUE_ICON_ALT: 'Moderate Value Icon',
  DONATION_LOW_VALUE_ICON_ALT: 'Low Value Icon',
  DONATION_MINOR_VALUE_ICON_ALT: 'Minor Value Icon',
  INTERACTION_ICON_ALT: 'Interaction Icon',
  VERTICAL_LINE_ALT: 'Vertical line Icon',
};
