import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { ICON_SIZES } from 'lib/constants/common';

export default function BasicPageTitle({
  children,
  iconAlt,
  iconSrc,
  iconTestId,
  title,
}) {
  const defaultContent = (
    <div className={classNames('flex items-center', { 'pb-4': !children })}>
      <img
        {...{
          className: 'mr-2',
          alt: iconAlt,
          src: iconSrc,
          height: ICON_SIZES.LG,
          width: ICON_SIZES.LG,
          ...(iconTestId && { 'data-testid': iconTestId }),
        }}
      />
      <div className="text-2xl font-semibold">{title}</div>
    </div>
  );

  if (children) {
    return (
      <div className="flex items-center justify-between w-full pb-4">
        {defaultContent}
        {children}
      </div>
    );
  }

  return defaultContent;
}

BasicPageTitle.propTypes = {
  children: PropTypes.node,
  iconAlt: PropTypes.string.isRequired,
  iconSrc: PropTypes.string.isRequired,
  iconTestId: PropTypes.string,
  title: PropTypes.string.isRequired,
};

BasicPageTitle.defaultProps = {
  children: null,
  iconTestId: '',
};
