import React from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import userActionTypes from 'rdx/actionTypes';

// Saves location to rdx store to keep track of previous and current locations
const useRoutesWrapper = () => {
  const { pathname } = useLocation();
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch({ type: userActionTypes.SET_LOCATION, path: pathname });
  }, [pathname]);
};

export { useRoutesWrapper };
