import React from 'react';
import { useCurrentUser } from 'lib/hooks';
import { HomeLayout } from 'components';
import { useHome } from './homePageHooks';

const HomePage = () => {
  const { feed, getFeedPage, setActiveFeedItem, activeFeedItemId } = useHome();
  const { goals } = useCurrentUser();

  return (
    <HomeLayout
      feed={feed}
      getFeedPage={getFeedPage}
      setActiveFeedItem={setActiveFeedItem}
      activeFeedItemId={activeFeedItemId}
      goals={goals}
    />
  );
};

export { HomePage };
