import React from 'react';
import Markdown from 'markdown-to-jsx';
import Box from '@material-ui/core/Box';
import Link from '@material-ui/core/Link';
import styled from '@material-ui/styles/styled';
import { CustomLink } from 'components/common/CustomLink';
import { EmployeeLink } from 'components/common/EmployeeLink';
import { RouterLink } from 'components/common/RouterLink';
import TimeValue from 'components/common/TimeValue';
import { STOP_PROPAGATION } from 'lib/constants';
import { formatWebAddress } from 'lib/configs';

// eslint-disable-next-line no-confusing-arrow
const sortByDisplay = (items) =>
  items.sort((a, b) => (a.displayOrder > b.displayOrder ? 1 : -1));

const ExternalLink = (markup) => {
  const { href, children } = markup;

  return (
    <Link
      href={href}
      onClick={STOP_PROPAGATION}
      rel="noopener noreferrer"
      target="_blank"
    >
      <CustomLink>
        {formatWebAddress(children?.length ? children[0] : href)}
      </CustomLink>
    </Link>
  );
};

const Image = styled('img')(({ theme }) => ({
  borderRadius: theme.shape.borderRadius[0],
  width: '250px',
}));

const MarkdownItems = ({ body }) =>
  sortByDisplay(body).map((item) => {
    const { txt, do: displayOrder } = item;

    // Return if no markdown to render
    if (!txt) {
      return null;
    }

    return (
      <Box key={`markdown-${displayOrder}`}>
        <Markdown
          options={{
            overrides: {
              a: ExternalLink,
              EmployeeLink,
              RouterLink,
              TimeValue,
              img: Image,
            },
          }}
        >
          {txt}
        </Markdown>
      </Box>
    );
  });

export { MarkdownItems };
