import React from 'react';
import { Box, Checkbox, FormControlLabel } from '@material-ui/core';
import {
  CampaignCard,
  CampaignModal,
  CustomLink,
  ExportButton,
  Gap,
  MultiActionDialogManager,
  PageLoader,
  SearchInput,
} from 'components';
import {
  BasicPageLayout,
  BasicPageTitle,
  CommonInfoSection,
  MainContent,
} from 'components/common';
import { ShowForViews } from 'components/common/ShowForViews';
import { CampaignStatistics } from 'components/campaign';
import { DEFAULT_DATE_FORMAT, USER_VIEW_ENUMS } from 'lib/constants/common';
import { colors } from 'styles/theme';
import { formatDateObject, isMobileView, skipPassingEvent } from 'lib/utils';
import { useCampaignForm } from 'lib/hooks';
import { campaignIcon } from 'assets/images';
import { translate } from 'lib/intl';
import { CSV_LINK_HEADERS } from './campaignsPageConfig';
import { mapCampaignsForExport } from './campaignsPageUtils';
import { useStyles } from './campaignsPageStyles';
import { useCampaignsPage } from './campaignsPageHooks';

const CampaignsPage = () => {
  const {
    campaigns,
    openCampaignPage,
    removeCampaign,
    removeCampaignsState,
    showArchived,
    setShowArchived,
    search,
    setSearch,
    updateCampaignsState,
    updateCampaign,
    showPageLoader,
    // metrics related
    totalCampaignRevenue,
    numDonorConstituents,
    avgCampaignDonation,
  } = useCampaignsPage();

  const {
    // modal state related
    isCampaignModalOpen,
    openCampaignModal,
    closeCampaignModal,
    modalData,
    setModalData,
    modalErrors,
    setModalErrors,

    // api related
    compaignApiError,
    isCampaignSaving,
    saveCampaign,
    teamMembers,
  } = useCampaignForm();

  const styles = useStyles();
  const isMobile = isMobileView();

  const CampaignsList = campaigns?.map((data) => {
    const { campaignId } = data;
    const isLoading =
      updateCampaignsState[campaignId]?.isFetching ||
      removeCampaignsState[campaignId]?.isFetching;

    return (
      <CampaignCard
        key={campaignId}
        onClick={openCampaignPage}
        openCampaignModal={openCampaignModal}
        isLoading={isLoading}
        removeCampaign={removeCampaign}
        updateCampaign={updateCampaign}
        {...data}
      />
    );
  });

  const pageTitle = translate('CAMPAIGNS_TITLE');

  return (
    <>
      <BasicPageLayout data-testid="campaigns-page">
        <CommonInfoSection>
          <BasicPageTitle
            iconAlt={translate('CAMPAIGNS_TITLE_ICON_ALT')}
            iconSrc={campaignIcon}
            iconTestId="campaign-icon"
            title={pageTitle}
          />
          <CampaignStatistics
            avgDonation={avgCampaignDonation}
            totalAmount={totalCampaignRevenue}
            numDonorConstituents={numDonorConstituents}
            // totalPercentage={totalPercentage}
          />
        </CommonInfoSection>
        <MainContent>
          <Box
            alignItems="center"
            display="flex"
            flexWrap="wrap"
            pb={isMobile ? 0.5 : 1}
          >
            <Box display="flex" flex="1 1 400px" pb={1}>
              <Box className={styles.searchWrapper} flex="1" pr={3}>
                <SearchInput
                  backgroundColor={colors.white}
                  onChange={setSearch}
                  placeholder={translate('CAMPAIGNS_SEARCH_PLACEHOLDER')}
                  value={search}
                />
              </Box>
              <FormControlLabel
                className={styles.label}
                control={
                  <Checkbox
                    checked={showArchived}
                    inputProps={{ 'data-testid': 'archived-checkbox' }}
                    onChange={(e) => setShowArchived(e.target.checked)}
                    name="archived-checkbox"
                  />
                }
                label={translate('CAMPAIGNS_SHOW_ARCHIVED_LABEL')}
              />
            </Box>
            <Box
              display="flex"
              flex="1 1 300px"
              justifyContent="space-between"
              pb={1}
            >
              <ShowForViews
                showFor={[USER_VIEW_ENUMS.ADMIN, USER_VIEW_ENUMS.EDITOR]}
              >
                <CustomLink
                  data-testid="add-campaign"
                  onClick={skipPassingEvent(openCampaignModal)}
                >
                  {translate('ADD_CAMPAIGN_LABEL')}
                </CustomLink>
              </ShowForViews>
              <Gap />
              <ExportButton
                data={mapCampaignsForExport(campaigns)}
                filename={`${pageTitle}_${formatDateObject(
                  Date.now(),
                  DEFAULT_DATE_FORMAT
                )}`}
                headers={CSV_LINK_HEADERS}
              />
            </Box>
          </Box>
          {CampaignsList}
        </MainContent>
      </BasicPageLayout>
      <CampaignModal
        data={modalData}
        teamMembers={teamMembers}
        errors={modalErrors}
        handleClose={closeCampaignModal}
        handleSave={saveCampaign}
        hasApiError={Boolean(compaignApiError)}
        isCreating={!modalData.campaignId}
        isLoading={isCampaignSaving}
        open={isCampaignModalOpen}
        updateData={setModalData}
        updateErrors={setModalErrors}
      />
      <MultiActionDialogManager />
      <PageLoader isVisible={showPageLoader} />
    </>
  );
};

export { CampaignsPage };
