import userActionTypes from 'rdx/actionTypes';

const initialState = {
  donations: {},
  isLoading: false,
  errors: null,
};

const { INTERACTION_REQUEST, INTERACTION_SUCCESS, INTERACTION_FAILURE } =
  userActionTypes;

export function interactions(state = initialState, action) {
  switch (action.type) {
    case INTERACTION_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case INTERACTION_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case INTERACTION_FAILURE:
      return {
        ...state,
        isLoading: false,
        errors: action.errors,
      };
    default:
      return state;
  }
}
