import React from 'react';
import PropTypes from 'prop-types';
import { AuthContainer } from 'components';
import { Grid, Typography } from '@material-ui/core';
import { thinkingEmoji } from 'assets/images';
import { errorPageIconStyle } from 'components/auth/authStyles';
import { translate } from 'lib/intl';
import { PUBLIC_ROUTES } from 'lib/constants';

const GenericErrorPage = ({ mainTitle, content }) => (
  <AuthContainer
    size="md"
    navLinkText={translate('SIGN_IN')}
    navLinkRoute={PUBLIC_ROUTES.LOGIN}
  >
    <Grid container>
      <Grid item sm={6} xs={12}>
        <Typography variant="h5">{translate('GENERAL_EXCLAMATORY')}</Typography>
        <Typography variant="h6" style={{ marginTop: '30px' }}>
          {mainTitle}
        </Typography>
        <Typography variant="body1" style={{ marginTop: '30px' }}>
          {content}
        </Typography>
      </Grid>
      <Grid item sm={6} xs={12} style={errorPageIconStyle}>
        <img alt="Thinking emoji" src={thinkingEmoji} />
      </Grid>
    </Grid>
  </AuthContainer>
);

GenericErrorPage.propTypes = {
  mainTitle: PropTypes.string,
  content: PropTypes.string,
};

GenericErrorPage.defaultProps = {
  mainTitle: null,
  content: null,
};

export { GenericErrorPage };
