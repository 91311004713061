const mapCampaignDonations = (donations) =>
  donations?.map(
    ({
      actualValue = 0,
      donationId,
      donationDateString,
      donationTypeEnum,
      constituents: [donor],
      paymentTypeEnum,
      timeData: { time = 0, timeCategory, timeUnit, timeValue } = {},
    }) => ({
      amount: actualValue,
      donationDateString,
      donationId,
      donationTypeEnum,
      donorId: donor.constituentId,
      donorDisplayName: donor.displayName,
      paymentTypeEnum,
      time,
      timeCategory,
      timeUnit,
      timeValue,
    })
  );

export { mapCampaignDonations };
