import React, { useCallback, useMemo, useState } from 'react';
import { bool, func } from 'prop-types';
import styled from 'styled-components';
import { prop } from 'rambdax';
import {
  Box,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from '@material-ui/core';
import { colors } from 'styles/theme';
import { EMPTY_FUNC, STOP_PROPAGATION } from 'lib/constants';
import { Visible } from 'components';
import { translate } from 'lib/intl';
import { capitalize, GroupType } from 'lib/utils';
import { TickIcon } from 'assets/images';

const StyledInviteSelector = styled(FormControl)`
  border-bottom: ${({ border }) =>
    border ? `1px solid ${colors.primaryGreen}` : 'none'};

  max-width: ${prop('width')};
  width: ${prop('width')};

  .MuiInputLabel-formControl {
    left: 0px;
  }

  .MuiSelect-select {
    border-radius: 8px;
  }

  .MuiSelect-select:focus {
    background: none;
  }

  .MuiList-root .MuiButtonBase-root.MuiListItem-root {
    line-height: 1.5;
    min-height: 40px;
    width: 6px;
  }

  .MuiInput-underline:before,
  .MuiInput-underline:after {
    display: none;
  }

  label + .MuiInput-formControl {
    margin-top: 0px;
  }

  .MuiInputLabel-formControl {
    top: -16px;
  }

  .MuiInputLabel-animated {
    transition: none;
  }

  .MuiInputLabel-shrink {
    transform: none;
  }

  .MuiInputLabel {
    zindex: 10;
  }

  .MuiFormLabel-filled,
  .MuiFormLabel-root.Mui-focused:not(.MuiInputLabel) {
    display: none;
  }
`;

const renderSelectValue = (selectedOptions = []) =>
  selectedOptions
    ?.map(({ groupDisplayName }) => capitalize(groupDisplayName))
    ?.join(', ');

const InviteSelector = ({
  BottomComponent,
  disabled,
  multiple,
  onClose,
  options,
  selectedOptions,
  handleChange,
  showBorder,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const openSelector = useCallback(() => setIsOpen(true), []);
  const closeSelector = useCallback(() => {
    if (onClose) {
      onClose(selectedOptions);
    }

    setIsOpen(false);
  }, [selectedOptions]);

  const Options = useMemo(
    () =>
      options?.map((value = {}) => {
        const { groupDisplayName } = value;

        const isSelected = selectedOptions?.find(
          (option) => option.groupDisplayName === groupDisplayName
        );

        return (
          <MenuItem key={groupDisplayName} value={groupDisplayName}>
            <Visible when={Boolean(isSelected)}>
              <TickIcon />
            </Visible>
            <Box ml={isSelected ? 2 : 4}>
              <Typography variant="body1">
                {capitalize(groupDisplayName)}
              </Typography>
            </Box>
          </MenuItem>
        );
      }),
    [options, selectedOptions]
  );

  const onChange = useCallback(
    ({ target: { value } }) => {
      const newOptions = multiple
        ? value?.filter((val) => Boolean(val))
        : [value];

      handleChange(
        newOptions.map((groupDisplayName) =>
          options.find((option) => option.groupDisplayName === groupDisplayName)
        )
      );
    },
    [multiple, options]
  );

  const BottomPart = useMemo(() => {
    if (BottomComponent) {
      return (
        <Box>
          <Divider />
          <BottomComponent onClose={closeSelector} />
        </Box>
      );
    }

    return null;
  }, [BottomComponent, closeSelector]);

  const values = useMemo(() => {
    const groupDisplayNames =
      selectedOptions?.map(({ groupDisplayName }) => groupDisplayName) || [];

    return multiple ? groupDisplayNames : groupDisplayNames[0] || '';
  }, [selectedOptions]);

  return (
    <StyledInviteSelector
      border={showBorder ? 1 : 0}
      onClick={STOP_PROPAGATION}
      width={selectedOptions?.length ? 'fit-content' : '170px'}
    >
      <InputLabel id="options-label">
        {translate('COMPANY_PAGE_PERMISSION_TYPE_LABEL')}
      </InputLabel>
      <Select
        id="options-selector"
        labelId="options-label"
        disabled={disabled}
        multiple={multiple}
        onChange={onChange}
        onClose={closeSelector}
        onOpen={openSelector}
        open={isOpen}
        renderValue={() => renderSelectValue(selectedOptions)}
        value={values}
      >
        {Options}
        {BottomPart}
      </Select>
    </StyledInviteSelector>
  );
};

InviteSelector.propTypes = {
  BottomComponent: func,
  disabled: bool,
  multiple: bool,
  onClose: func,
  options: GroupType,
  selectedOptions: GroupType,
  handleChange: func,
  showBorder: bool,
};

InviteSelector.defaultProps = {
  BottomComponent: null,
  disabled: false,
  handleChange: EMPTY_FUNC,
  multiple: false,
  onClose: EMPTY_FUNC,
  options: [],
  selectedOptions: [],
  showBorder: false,
};

export { InviteSelector };
