import { createTheme } from '@material-ui/core/styles';
import createBreakpoints from '@material-ui/core/styles/createBreakpoints';
import newColors from './newThemeColors';
import screens from './screens';
import type from './typography';

const { fontWeight, variants: typography } = type;

const customBreakpoints = {
  values: {
    xs: 0,
    ...screens,
  },
};
const breakpoints = createBreakpoints({ ...customBreakpoints });

export default createTheme({
  props: {
    MuiButton: {
      color: 'primary',
      disableRipple: true,
    },
    secondaryButtonClass: 'MuiButton-customSecondary',
    tertiaryButtonClass: 'MuiButton-customTertiary',
    MuiLink: {
      underline: 'none',
    },
  },
  overrides: {
    MuiCssBaseline: {
      // All new Material UI specific styles should be added as a
      // "Mui*" key. See the "MuiFormControl" for an example of how to
      // do this.
      '@global': {
        html: {
          height: '100%',
        },
        body: {
          backgroundColor: newColors.primaryBackground,
          height: '100%',
        },
        '#root': {
          height: '100%',
          overflowX: 'hidden',
        },
      },
    },
    MuiAlert: {
      root: {
        padding: '4px 16px',
      },
      message: {
        ...typography.body4,
      },
      standardSuccess: {
        color: newColors.success.contrastText,
        backgroundColor: newColors.success.light,
      },
      standardError: {
        color: newColors.error.contrastText,
        backgroundColor: newColors.error.light,
        '& $icon': {
          color: newColors.error.contrastText,
        },
      },
      standardWarning: {
        backgroundColor: newColors.warning.light,
        color: newColors.black,
      },
    },
    MuiButton: {
      root: {
        borderRadius: '8px',
        padding: '0 32px',
        letterSpacing: '0.15px',
        textTransform: 'capitalize',
        [breakpoints.down('xs')]: {
          minWidth: 'auto',
        },
        '&$disabled': {
          cursor: 'not-allowed',
          pointerEvents: 'auto',
          backgroundColor: newColors.grey.lighter.backgroundColor,
          color: newColors.grey.lighter.color,
        },
        '&:hover&$disabled': {
          color: newColors.grey.lighter.color,
        },
      },
      label: {
        padding: '16px 12px',
        fontSize: typography.body1.fontSize,
        fontWeight: fontWeight.medium,
        '& > *': {
          fontWeight: fontWeight.medium,
        },
      },
      contained: {
        boxShadow: 'none',
        '&$disabled': {
          color: newColors.darkGrey,
          backgroundColor: newColors.grey[100],
        },
        '&:hover': {
          boxShadow: 'none',
        },
        '&:hover&$disabled': {
          backgroundColor: newColors.grey[100],
        },
      },
      containedPrimary: {
        backgroundColor: newColors.primaryButton.main,
        color: newColors.primaryButton.contrastText,
        '& path': {
          fill: newColors.primaryButton.contrastText,
        },
        '&:hover': {
          backgroundColor: newColors.primaryButton.light,
        },
        '&:focus': {
          backgroundColor: newColors.primaryButton.light,
        },
        '&:active': {
          backgroundColor: newColors.primaryButton.dark,
        },
      },
      containedSecondary: {
        backgroundColor: newColors.secondaryButton.main,
        color: newColors.secondaryButton.contrastText,
        '& path': {
          fill: newColors.secondaryButton.contrastText,
        },
        '&:hover': {
          backgroundColor: newColors.secondaryButton.light,
        },
        '&:focus': {
          backgroundColor: newColors.secondaryButton.light,
        },
        '&:active': {
          backgroundColor: newColors.secondaryButton.dark,
        },
      },
      text: {
        '&:disabled': {
          color: newColors.darkGrey,
          backgroundColor: 'transparent',
          '& path': {
            fill: newColors.darkGrey,
          },
        },
        '&:hover&$disabled': {
          backgroundColor: 'transparent',
        },
      },
      textPrimary: {
        color: newColors.primaryLink.main,
        '& path': {
          fill: newColors.primaryLink.main,
        },
        '&:hover': {
          backgroundColor: 'transparent',
          color: newColors.primaryLink.light,
          '& path': {
            fill: newColors.primaryLink.light,
          },
        },
        '&:focus': {
          color: newColors.primaryLink.light,
          '& path': {
            fill: newColors.primaryLink.light,
          },
        },
        '&:active': {
          color: newColors.primaryLink.dark,
          '& path': {
            fill: newColors.primaryLink.dark,
          },
        },
      },
    },
    MuiDisabled: {
      cursor: 'not-allowed',
    },
    MuiDivider: {
      light: {
        backgroundColor: newColors.transparentWhite,
      },
      vertical: {
        backgroundColor: newColors.black,
      },
    },
    MuiFormControl: {
      root: {
        marginBottom: '12px',
      },
    },
    MuiFormLabel: {
      root: {
        lineHeight: 1.5,
      },
    },
    MuiInput: {
      underline: {
        '&:before': {
          bottom: '0px',
        },
        '&:after': {
          bottom: '0px',
        },
        '&.Mui-disabled:before': {
          borderBottom: `1px solid ${newColors.darkGrey}`,
          opacity: 0.5,
        },
      },
    },
    MuiFilledInput: {
      root: {
        backgroundColor: `${newColors.grey[100]} !important`,
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
        transition: 'none',

        '&:hover': {
          backgroundColor: `${newColors.grey[100]} !important`,
        },
        '&$focused': {
          backgroundColor: `${newColors.grey[100]} !important`,
        },
      },
    },
    MuiFormHelperText: {
      contained: {
        marginLeft: 0,
        marginRight: 0,
      },
    },
    MuiInputLabel: {
      shrink: {
        transform: 'translate(0, 4px) scale(0.75)',
        minWidth: '100px',
      },
    },
    MuiInputBase: {
      root: {
        letterSpacing: '0.15px',
      },
      input: {
        lineHeight: 1.5,
        marginTop: '3px',
      },
    },
    MuiLink: {
      root: {
        ...typography.body2,
        color: newColors.primaryLink.main,
        cursor: 'pointer',
        textTransform: 'initial',
        '&:hover': {
          color: newColors.primaryLink.light,
        },
        '&:focus': {
          color: newColors.primaryLink.light,
        },
        '&:active': {
          color: newColors.primaryLink.dark,
        },
        '&:disabled': {
          color: newColors.darkGrey,
        },
      },
    },
    MuiOutlinedInput: {
      root: {
        minHeight: '48px',
      },
    },
    MuiPaginationItem: {
      rounded: {
        color: newColors.primaryLink.main,
        '&$selected': {
          backgroundColor: newColors.primaryLink.dark,
          color: newColors.primary.contrastText,

          '&:hover': {
            backgroundColor: newColors.primary.main,
          },
        },
      },
    },
    MuiTab: {
      root: {
        ...typography.body2,
      },
      wrapper: {
        textTransform: 'capitalize',
      },
    },
    MuiTabs: {
      flexContainer: {
        borderBottom: `1px solid ${newColors.transparentGrey}`,
      },
      indicator: {
        borderBottom: `2px solid ${newColors.subNav.active}`,
      },
    },
    MuiLinearProgress: {
      root: {
        height: '16px',
        borderRadius: '8px',
        zIndex: 1,
      },
      bar: {
        borderRadius: '8px',
      },
      // background styles
      dashed: {
        animation: 'none',
        backgroundImage: 'none !important',
      },
      dashedColorPrimary: {
        backgroundColor: newColors.lightGrey,
      },
      dashedColorSecondary: {
        backgroundColor: newColors.grey[300],
      },
      // current progress styles
      barColorPrimary: {
        backgroundColor: newColors.primary.main,
      },
      barColorSecondary: {
        backgroundColor: newColors.darkGrey,
      },
      // expected progress styles
      colorPrimary: {
        backgroundColor: newColors.grey[600],
      },
      colorSecondary: {
        backgroundColor: newColors.grey[600],
      },
    },
    MuiDialogTitle: {
      root: {
        padding: '24px',
        [breakpoints.down('xs')]: {
          padding: '16px',
        },
      },
    },
    MuiDialogActions: {
      root: {
        padding: '24px',
        [breakpoints.down('xs')]: {
          padding: '16px',
        },
      },
      spacing: {
        marginLeft: '8px',
        [breakpoints.down('xs')]: {
          marginLeft: '0',
        },
      },
    },
    MuiDialogContent: {
      root: {
        padding: '24px',
      },
      dividers: {
        padding: '24px',
      },
    },
    MuiRadio: {
      root: {
        color: `${newColors.primaryLink.main} !important`,
        '&$checked': {
          color: newColors.primaryLink.main,
        },
      },
    },
    MuiSwitch: {
      track: {
        backgroundColor: newColors.grey[600],
      },
    },
    MuiSelect: {
      select: {
        '&:focus': {
          backgroundColor: 'transparent',
        },
      },
    },
  },
  palette: {
    primary: newColors.primary,
    secondary: newColors.secondary,
    grey: {
      300: newColors.grey300,
      500: newColors.grey500,
      light: newColors.lightGrey,
      dark: newColors.darkGrey,
      transparent: newColors.transparentGrey,
      ...newColors.grey,
    },
    green: {
      light: newColors.lightGreen,
    },
    text: newColors.text,
    error: {
      transparentError: newColors.transparentError,
      ...newColors.error,
    },
    success: {
      ...newColors.success,
    },
    background: {
      offWhite: newColors.offWhite,
      light: 'rgba(243, 245, 245, 0.6)',
      white: newColors.white,
      superAdmin: newColors.adminColor,
    },
    donorAttributes: {
      boardMember: newColors.boardMember,
      majorDonor: newColors.majorDonor,
      recurring: newColors.recurring,
      volunteer: newColors.volunteer,
      member: newColors.member,
    },
    progressBar: {
      darkGrey: newColors.progressDarkGrey,
      lightGrey: newColors.progressLightGrey,
    },
    primaryLink: newColors.primaryLink,
    primaryButton: newColors.primaryButton,
    secondaryButton: newColors.secondaryButton,
  },
  /* Typography as per styleguide */
  typography: {
    color: newColors.black,
    fontFamily: ['Poppins', 'Roboto'],
    fontWeightMedium: fontWeight.medium,
    fontWeightRegular: fontWeight.regular,
    fontWeightSemiBold: fontWeight.semiBold,
    h1: typography.h1,
    h2: typography.h2,
    h3: typography.h3,
    h4: typography.h4,
    // h5: {},
    // h6: {},
    // subtitle1: {},
    // subtitle2: {},
    body1: typography.body1,
    body2: typography.body2,
    body3: typography.body3,
    body4: typography.body4,
    // button: {},
    caption: typography.caption,
    // overline: {},
  },
  breakpoints: {
    ...customBreakpoints,
  },
  shape: {
    borderRadius: ['8px'],
  },
});
