import userActionTypes from 'rdx/actionTypes';

const initialState = {
  isLoading: false,
  isCreationSuccessful: false,
  errors: null,
};

const {
  CREATE_ANNOUNCEMENT_REQUEST,
  CREATE_ANNOUNCEMENT_SUCCESS,
  CREATE_ANNOUNCEMENT_FAILURE,
  CREATE_ANNOUNCEMENT_RESET,
} = userActionTypes;

export function announcements(state = initialState, action) {
  switch (action.type) {
    case CREATE_ANNOUNCEMENT_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case CREATE_ANNOUNCEMENT_SUCCESS:
      return {
        ...state,
        isCreationSuccessful: true,
        isLoading: false,
      };
    case CREATE_ANNOUNCEMENT_FAILURE:
      return {
        ...state,
        isCreationSuccessful: false,
        isLoading: false,
        errors: action.error,
      };
    case CREATE_ANNOUNCEMENT_RESET:
      return {
        ...state,
        isCreationSuccessful: false,
        isLoading: false,
        errors: null,
      };
    default:
      return state;
  }
}
