import userActionTypes from 'rdx/actionTypes';
import { recentlyViewedDonorsService } from 'services/recently-viewed-donors';

const {
  RECENTLY_VIEWED_DONORS_REQUEST,
  RECENTLY_VIEWED_DONORS_SUCCESS,
  RECENTLY_VIEWED_DONORS_FAILURE,
} = userActionTypes;

const initialState = {
  isFetching: false,
  error: null,
  data: recentlyViewedDonorsService.getRecentlyViewedDonors() || [],
};

export function recentlyViewedDonors(state = initialState, action) {
  switch (action.type) {
    case RECENTLY_VIEWED_DONORS_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: null,
      };
    case RECENTLY_VIEWED_DONORS_SUCCESS:
      return {
        ...state,
        data: action.data,
        isFetching: false,
        error: null,
      };
    case RECENTLY_VIEWED_DONORS_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.error,
      };
    default:
      return state;
  }
}
