import userActionTypes from 'rdx/actionTypes';
import { feedService } from 'services/feed';
import { createAction, showErrorMessage, showSuccessMessage } from 'lib/utils';
import { translate } from 'lib/intl';
import createFetchFeedAction from './createFetchFeedAction';
import createRefreshFeedAction from './createRefreshFeedAction';

const {
  FEED_REQUEST,
  FEED_SUCCESS,
  FEED_FAILURE,
  FEED_REFRESH_SUCCESS,
  FEED_REMOVE_ITEM,
  FETCH_FEED_ENTITY_REQUEST,
  FETCH_FEED_ENTITY_SUCCESS,
  FETCH_FEED_ENTITY_FAILURE,
  DELETE_FEED_ENTITY_REQUEST,
  DELETE_FEED_ENTITY_SUCCESS,
  DELETE_FEED_ENTITY_FAILURE,
} = userActionTypes;

const fetchFeed = createFetchFeedAction({
  request: FEED_REQUEST,
  success: FEED_SUCCESS,
  failure: FEED_FAILURE,
});

const refreshFeed = createRefreshFeedAction({
  request: FEED_REQUEST,
  success: FEED_REFRESH_SUCCESS,
  failure: FEED_FAILURE,
});

const fetchFeedEntity = (feedEntityType, feedEntityId) => async (dispatch) => {
  try {
    dispatch(createAction({ type: FETCH_FEED_ENTITY_REQUEST }));

    const response = await feedService.fetchFeedEntity(
      feedEntityType,
      feedEntityId
    );

    dispatch(
      createAction({
        type: FETCH_FEED_ENTITY_SUCCESS,
        data: {
          ...response.data,
          contentId: feedEntityId,
        },
      })
    );
  } catch (error) {
    dispatch(createAction({ type: FETCH_FEED_ENTITY_FAILURE, error }));
  }
};

const deleteFeedEntity = (feedEntityType, feedEntityId) => async (dispatch) => {
  try {
    dispatch(createAction({ type: DELETE_FEED_ENTITY_REQUEST }));

    const response = await feedService.deleteFeedEntity(
      feedEntityType,
      feedEntityId
    );

    dispatch(
      createAction({
        type: DELETE_FEED_ENTITY_SUCCESS,
        data: {
          ...response.data,
          contentId: feedEntityId,
        },
      })
    );

    showSuccessMessage({
      message: translate('DELETE_FEED_ENTITY_SUCCESS_MESSAGE', {
        entity: feedEntityType,
      }),
    });
  } catch (error) {
    showErrorMessage({
      message: translate('DELETE_FEED_ENTITY_ERROR_MESSAGE', {
        entity: feedEntityType,
      }),
    });

    dispatch(createAction({ type: DELETE_FEED_ENTITY_FAILURE, error }));
  }
};

const removeItem = (contentId) => (dispatch) =>
  dispatch(createAction({ type: FEED_REMOVE_ITEM, contentId }));

export const feedActions = {
  fetchFeed,
  refreshFeed,
  fetchFeedEntity,
  deleteFeedEntity,
  removeItem,
};

export default feedActions;
