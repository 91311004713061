import React from 'react';
import PropTypes from 'prop-types';
import { MenuItem, Select } from '@material-ui/core';
import {
  REPORTS_DAILY_TIME_PERIOD,
  REPORTS_WEEKLY_TIME_PERIOD,
  REPORTS_MONTHLY_TIME_PERIOD,
} from 'lib/constants';
import Report from 'reports/DonationPerformanceReport';
import { sumByDay, sumByWeek, sumByMonth } from 'reports/donationReportUtils';
import { translate } from 'lib/intl';

function groupByTimePeriod(period, arr) {
  switch (period) {
    default:
    case REPORTS_DAILY_TIME_PERIOD:
      return sumByDay(arr);
    case REPORTS_WEEKLY_TIME_PERIOD:
      return sumByWeek(arr);
    case REPORTS_MONTHLY_TIME_PERIOD:
      return sumByMonth(arr);
  }
}

const CHART_ID = 'campaign-distribution-report';

export default function DonationPerformanceReport({ data }) {
  const [graphData, setGraphData] = React.useState(() => {
    return groupByTimePeriod(REPORTS_WEEKLY_TIME_PERIOD, data);
  });

  const [filter, setFilter] = React.useState(REPORTS_WEEKLY_TIME_PERIOD);

  const reportRef = React.useRef(
    new Report({
      id: CHART_ID,
    })
  );

  React.useEffect(() => {
    reportRef.current.setTimePeriod(filter);
    reportRef.current.create(graphData);
  }, []);

  React.useEffect(() => {
    reportRef.current.update(graphData);
  }, [graphData]);

  React.useEffect(() => {
    reportRef.current.setTimePeriod(filter);
  }, [filter]);

  const handleChange = React.useCallback(
    ({ target: { value } }) => {
      setFilter(value);
      setGraphData(groupByTimePeriod(value, data));
    },
    [data, setFilter, setGraphData]
  );

  return (
    <>
      <Select onChange={handleChange} value={filter}>
        <MenuItem value={REPORTS_DAILY_TIME_PERIOD}>
          {translate('REPORTS_FILTER_DAILY')}
        </MenuItem>
        <MenuItem value={REPORTS_WEEKLY_TIME_PERIOD}>
          {translate('REPORTS_FILTER_WEEKLY')}
        </MenuItem>
        <MenuItem value={REPORTS_MONTHLY_TIME_PERIOD}>
          {translate('REPORTS_FILTER_MONTHLY')}
        </MenuItem>
      </Select>

      <div id={CHART_ID} />
    </>
  );
}

DonationPerformanceReport.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      date: PropTypes.string.isRequired,
      donation: PropTypes.number.isRequired,
      source: PropTypes.string.isRequired,
    })
  ).isRequired,
};
