import { createApiRequest } from 'lib/utils';

const createIntegrationKey = (
  ownerOrgPubId,
  integrationName,
  integrationId,
  data
) =>
  createApiRequest({
    data,
    method: 'post',
    path: `orgs/${ownerOrgPubId}/external_application/${integrationName}/${integrationId}/key`,
    throwErrors: true,
  });

const updateIntegrationKey = (
  ownerOrgPubId,
  integrationName,
  integrationId,
  data
) =>
  createApiRequest({
    data,
    method: 'patch',
    path: `orgs/${ownerOrgPubId}/external_application/${integrationName}/${integrationId}/key`,
    throwErrors: true,
  });

const removeIntegrationKey = (ownerOrgPubId, integrationName, integrationId) =>
  createApiRequest({
    method: 'delete',
    path: `orgs/${ownerOrgPubId}/external_application/${integrationName}/${integrationId}/key`,
    throwErrors: true,
  });

export const integrationService = {
  createIntegrationKey,
  updateIntegrationKey,
  removeIntegrationKey,
};
