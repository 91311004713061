import React, { useMemo } from 'react';
import { func, shape, string } from 'prop-types';
import { Box } from '@material-ui/core';
import { isMobileView } from 'lib/utils';
import { translate } from 'lib/intl';
import { Gap } from 'components';

import { DonorTextInput } from './donor-text-input';

const ADDRESS_PARTS = [
  'country',
  'line1',
  'unit',
  'city',
  'state',
  'postalCode',
];

const COUNTRY_CODE_LENGTH = 2;

const getAddressPartPath = (path, part) => (path ? `${path}.${part}` : part);

const getAddressInputsPaths = (path) =>
  ADDRESS_PARTS.map((inputPath) => getAddressPartPath(path, inputPath));

const AddressInputs = ({ data, path, updateData, ...rest }) => {
  const isMobile = isMobileView();

  const paths = useMemo(() => getAddressInputsPaths(path), [path]);

  return (
    <Box pb={2}>
      <DonorTextInput
        data={data}
        path={paths[0]}
        label={translate('COUNTRY_LABEL')}
        updateData={updateData}
        // Country should have an Alpha-2 format
        validate={(value) => value?.length === COUNTRY_CODE_LENGTH}
        errorMessage={translate('COUNTRY_ERROR_MESSAGE')}
        {...rest}
      />
      <Box display={isMobile ? 'block' : 'flex'}>
        <DonorTextInput
          data={data}
          path={paths[1]}
          label={translate('STREET_ADDRESS_LABEL')}
          updateData={updateData}
          containerProps={{ flex: '2' }}
          {...rest}
        />
        <Gap size={2} />
        <DonorTextInput
          data={data}
          path={paths[2]}
          label={translate('APT_LABEL')}
          updateData={updateData}
          containerProps={{ flex: '1' }}
          {...rest}
        />
      </Box>
      <Box display={isMobile ? 'block' : 'flex'}>
        <Box display={isMobile ? 'block' : 'flex'} flex="2">
          <DonorTextInput
            data={data}
            path={paths[3]}
            label={translate('CITY_LABEL')}
            updateData={updateData}
            containerProps={{ flex: '1' }}
            {...rest}
          />
          <Gap size={2} />
          <DonorTextInput
            data={data}
            path={paths[4]}
            label={translate('STATE_LABEL')}
            updateData={updateData}
            containerProps={{ flex: '1' }}
            {...rest}
          />
        </Box>
        <Gap size={2} />
        <DonorTextInput
          data={data}
          path={paths[5]}
          label={translate('ZIP_LABEL')}
          updateData={updateData}
          containerProps={{ flex: '1' }}
          {...rest}
        />
      </Box>
    </Box>
  );
};

AddressInputs.propTypes = {
  data: shape({}).isRequired,
  path: string.isRequired,
  updateData: func.isRequired,
};

export { AddressInputs };
