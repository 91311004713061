import React, { useState } from 'react';
import { bool, func, string } from 'prop-types';
import { Box, Typography } from '@material-ui/core';
import { NUMBER_FORMAT_RETURNED_VALUES } from 'lib/constants';
import { colors } from 'styles/theme';
import { translate } from 'lib/intl';
import { isMobileView } from 'lib/utils';
import { Visible, Loader, CustomButton, Gap, CurrencyInput } from 'components';

const FORM_BORDER = `1px solid ${colors.progressLightGrey}`;
const MOBILE_WIDTH = '304px';

const CampaignGoalForm = ({ isLoading, onClose, onSave, title }) => {
  const isMobile = isMobileView();
  const [goal, setGoal] = useState(0);

  return (
    <Box width={isMobile ? 'auto' : MOBILE_WIDTH}>
      <Box p={2} pb={0}>
        <Typography variant="subtitle1">{title}</Typography>
        <Box mb={3} mt={1.5}>
          <CurrencyInput
            label={translate('GOALS_TOTAL')}
            amount={goal}
            setAmount={setGoal}
            returnedValue={NUMBER_FORMAT_RETURNED_VALUES.FLOAT_VALUE}
          />
        </Box>
      </Box>
      <Box
        borderTop={FORM_BORDER}
        display="flex"
        flex="1"
        justifyContent="flex-end"
        p={2}
      >
        <Visible when={!isLoading} fallback={<Loader />}>
          <CustomButton
            buttonType="link"
            color="primary"
            dataTestId="goals-modal-cancel-button"
            label={translate('GOALS_CANCEL')}
            onClick={onClose}
          />
          <Gap />
          <CustomButton
            buttonType="primary"
            dataTestId="goals-modal-save-button"
            label={translate('GOALS_SAVE')}
            onClick={() => onSave(goal)}
          />
        </Visible>
      </Box>
    </Box>
  );
};

CampaignGoalForm.propTypes = {
  isLoading: bool.isRequired,
  onClose: func.isRequired,
  onSave: func.isRequired,
  title: string.isRequired,
};

export { CampaignGoalForm };
