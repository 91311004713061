import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  companyTeamMembersActions,
  interactionActions,
  campaignsActions,
} from 'rdx/actions';
import actionTypes from 'rdx/actionTypes';
import {
  API_DATE_FORMAT,
  DONOR_TYPES,
  EXPERIENCE_RATINGS,
  INTERACTION_FIELD_CONFIG,
  TEXT_SELECT_FIELD_EMPTY_VALUE,
} from 'lib/constants';
import { INTERACTION_METHOD_FIELD_CONFIG } from 'lib/constants/interactions';
import { formatCampaignForTextSelect } from 'lib/utils/campaigns';
import { formatDateObject, parseDateValue } from 'lib/utils/dates';

const VALIDATION_FIELDS = Object.keys(INTERACTION_FIELD_CONFIG);

// returns an object with errors based on fieldValues
const getErrors = (fieldValues) => {
  const newFieldErrors = {};

  VALIDATION_FIELDS.forEach((field) => {
    const { isValid, isRequired } = INTERACTION_FIELD_CONFIG[field];

    const data = fieldValues[field];

    if (isValid ? !isValid(data) : isRequired && !data) {
      newFieldErrors[field] = true;
    }
  });

  return newFieldErrors;
};

const formatTeamMember = (memberToFormat) => ({
  value: memberToFormat.userId,
  icon: '',
  text: `@${memberToFormat.displayName}`,
  isAvatar: true,
  donorType: DONOR_TYPES.USER,
});

const processDataBeforeSubmit = ({
  existingInteraction,
  ownerOrgId,
  fieldValues,
}) => {
  const {
    selectedDonor,
    selectedDate,
    interactionMethod,
    narrative,
    employeesInvolved,
    experienceRating,
    selectedCampaign,
  } = fieldValues;

  const donorId = selectedDonor?.value;
  const donorDisplayName = selectedDonor?.text;
  const constituentType = selectedDonor?.constituentType;

  const data = {
    ownerOrgId,
    donorId,
    donorDisplayName,
    constituentType,
    developers: employeesInvolved?.map(({ value, text }) => ({
      userId: value,
      displayName: text.replace(/^@/, ''),
    })),
    interactionTypeEnum: interactionMethod?.value,
    interactionDate: formatDateObject(selectedDate, API_DATE_FORMAT),
    sentiment: EXPERIENCE_RATINGS[experienceRating],
  };

  // Campaign is currently optional
  if (selectedCampaign?.value) {
    data.campaignId = selectedCampaign.value;
  }

  if (existingInteraction?.interactionId) {
    data.interactionId = existingInteraction.interactionId;
  }

  if (existingInteraction) {
    const newNotes = [{ ...existingInteraction.notes[0], text: narrative }];
    data.notes = newNotes;
  } else {
    data.notes = narrative;
  }

  return { data };
};

const formatInteractionData = (
  {
    campaignId,
    developers,
    interactionDate,
    interactionId,
    interactionTypeEnum,
    notes,
    sentiment,
  },
  campaigns
) => {
  return {
    campaign: campaigns.find(({ value }) => value === campaignId),
    narrative: notes?.[0]?.text,
    notes,
    employeesInvolved: developers.map(formatTeamMember),
    experienceRating: EXPERIENCE_RATINGS.findIndex(
      (rating) => rating === sentiment
    ),
    interactionId,
    interactionMethod: INTERACTION_METHOD_FIELD_CONFIG.find(
      ({ value }) => value === interactionTypeEnum
    ),
    selectedDate: parseDateValue(interactionDate),
  };
};

const useNewInteractionForm = ({ data, onClose, onSuccess }) => {
  const dispatch = useDispatch();
  const {
    error,
    isLoading,
    dataLoaded,
    team,
    areTeamMembersLoaded,
    ownerOrgId,
    currentUserId,
    campaigns,
    areCampaignsLoaded,
  } = useSelector((state) => ({
    error: state.addInteraction?.error,
    isLoading: state.addInteraction?.isFetching,
    dataLoaded: state.addInteraction?.dataLoaded,
    team: state.companyTeamMembers.data?.users || [],
    areTeamMembersLoaded: state.companyTeamMembers.dataLoaded,
    ownerOrgId: state.authentication?.ownerOrgId,
    currentUserId: state.authentication?.userId,
    campaigns: state.campaigns?.data,
    areCampaignsLoaded: state.campaigns?.dataLoaded,
  }));
  const formattedCampaigns = campaigns?.map(formatCampaignForTextSelect);
  const existingInteraction =
    data && formatInteractionData(data, formattedCampaigns);

  const [selectedDonor, setSelectedDonor] = React.useState(
    TEXT_SELECT_FIELD_EMPTY_VALUE
  );
  const [selectedDate, setSelectedDate] = React.useState(
    existingInteraction?.selectedDate ?? new Date()
  );
  const [interactionMethod, setInteractionMethod] = React.useState(
    existingInteraction?.interactionMethod ?? TEXT_SELECT_FIELD_EMPTY_VALUE
  );
  const [selectedCampaign, setSelectedCampaign] = React.useState(
    TEXT_SELECT_FIELD_EMPTY_VALUE
  );
  const [narrative, setNarrative] = React.useState(
    existingInteraction?.narrative ?? ''
  );
  const [employeesInvolved, setEmployeesInvolved] = React.useState([]);
  const [experienceRating, setExperienceRating] = React.useState(
    existingInteraction?.experienceRating ?? TEXT_SELECT_FIELD_EMPTY_VALUE
  );
  const [fieldErrors, setFieldErrors] = React.useState({});
  const [showFieldErrors, setShowFieldErrors] = React.useState(false);
  const formattedTeamMembers = team?.map(formatTeamMember);
  const isEditing = Boolean(existingInteraction?.interactionId);

  React.useEffect(() => {
    if (areCampaignsLoaded && existingInteraction?.campaign) {
      setSelectedCampaign(existingInteraction.campaign);
    }
  }, [areCampaignsLoaded]);

  React.useEffect(() => {
    if (areTeamMembersLoaded && existingInteraction) {
      setEmployeesInvolved(existingInteraction.employeesInvolved);
    } else if (areTeamMembersLoaded && !employeesInvolved?.value) {
      // makes sure to preselect a current employee
      const currentEmployee = team?.find(
        (teamMember) => teamMember.userId === currentUserId
      );

      if (currentEmployee) {
        setEmployeesInvolved([formatTeamMember(currentEmployee)]);
      }
    }
  }, [areTeamMembersLoaded]);

  const fieldValues = React.useMemo(
    () => ({
      selectedDonor,
      selectedDate,
      selectedCampaign,
      interactionMethod,
      narrative,
      employeesInvolved,
      experienceRating,
    }),
    [
      selectedDonor,
      selectedDate,
      selectedCampaign,
      interactionMethod,
      narrative,
      employeesInvolved,
      experienceRating,
    ]
  );

  // makes sure to fetch all the necessary data to use the form
  React.useEffect(() => {
    if (!areCampaignsLoaded) {
      dispatch(campaignsActions.fetchCampaigns(ownerOrgId));
    }

    if (!areTeamMembersLoaded) {
      dispatch(companyTeamMembersActions.fetchCompanyTeamMembers(ownerOrgId));
    }
  }, []);

  // On update check the error state for values
  React.useEffect(() => {
    setFieldErrors(getErrors(fieldValues));
  }, [fieldValues]);

  // closes the modal on a successful adding
  React.useEffect(() => {
    if (dataLoaded && onClose) {
      dispatch({
        type: isEditing
          ? actionTypes.EDIT_INTERACTION_RESET
          : actionTypes.ADD_INTERACTION_RESET,
      });
      onSuccess();
      onClose();
    }
  }, [dataLoaded]);

  const handleSave = React.useCallback(() => {
    const newFieldErrors = getErrors(fieldValues);

    if (Object.keys(newFieldErrors).length > 0) {
      setFieldErrors(newFieldErrors);
      setShowFieldErrors(true);
    } else {
      dispatch(
        interactionActions.submitInteraction(
          processDataBeforeSubmit({
            fieldValues,
            existingInteraction,
            ownerOrgId,
          })
        )
      );
    }
  }, [fieldValues, ownerOrgId]);

  return {
    // common
    isEditing,
    teamMembers: formattedTeamMembers,
    campaigns: formattedCampaigns,

    // statuses
    error,
    isLoading,
    showFormContent: areTeamMembersLoaded,

    // form fields and their setters
    selectedDonor,
    setSelectedDonor,
    selectedDate,
    setSelectedDate,
    interactionMethod,
    setInteractionMethod,
    narrative,
    setNarrative,
    employeesInvolved,
    setEmployeesInvolved,
    experienceRating,
    setExperienceRating,
    selectedCampaign,
    setSelectedCampaign,

    // form erros
    fieldErrors,
    showFieldErrors,

    // handlers
    handleSave,
  };
};

export { useNewInteractionForm };
