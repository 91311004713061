import ClassyCredentials from './ClassyCredentials';

const getIntegrationCredentialsComponent = (integrationId) => {
  let IntegrationCredentialsComponent;

  switch (integrationId) {
    case 'classy':
    default:
      IntegrationCredentialsComponent = ClassyCredentials;
  }
  return IntegrationCredentialsComponent;
};

export { getIntegrationCredentialsComponent };
