import { createAction, cleanObjectValues, showSuccessMessage } from 'lib/utils';
import { usersService } from 'services/users';
import { translate } from 'lib/intl';
import { userService } from 'services/user';
import userActionTypes from 'rdx/actionTypes';

const {
  USERS_REQUEST,
  USERS_SUCCESS,
  USERS_FAILURE,
  USERS_LIST_REQUEST,
  USERS_LIST_SUCCESS,
  USERS_LIST_FAILURE,
  USERS_UPDATE_INFO_REQUEST,
  USERS_UPDATE_INFO_SUCCESS,
  USERS_UPDATE_INFO_FAILURE,
} = userActionTypes;

const fetchUser = (ownerOrgId, userId) => async (dispatch) => {
  try {
    dispatch(createAction({ type: USERS_REQUEST }));

    const response = await usersService.fetchUsers(ownerOrgId, userId);

    dispatch(
      createAction({
        type: USERS_SUCCESS,
        data: response?.data || {},
      })
    );
  } catch (err) {
    dispatch(
      createAction({
        type: USERS_FAILURE,
        err,
      })
    );
  }
};

// TODO: add pagination
const fetchUsersList = (ownerOrgId) => async (dispatch) => {
  try {
    dispatch(createAction({ type: USERS_LIST_REQUEST }));

    const response = await usersService.fetchUsersList(ownerOrgId);

    dispatch(
      createAction({
        type: USERS_LIST_SUCCESS,
        data: response?.data || {},
      })
    );
  } catch (err) {
    dispatch(
      createAction({
        type: USERS_LIST_FAILURE,
        err,
      })
    );
  }
};

const updateEmployeeInfo = (ownerOrgId, userId, data) => async (dispatch) => {
  const formattedData = data;
  const { emails, phones, newPassword, oldPassword, jobTitle } = formattedData;

  // Format email and phone fields for submission
  formattedData.emails = emails;
  formattedData.phones = phones;
  formattedData.jobTitle = jobTitle;

  // Remove any undefined or null values
  const cleanedData = cleanObjectValues(formattedData);

  try {
    dispatch(createAction({ type: USERS_UPDATE_INFO_REQUEST }));

    const response = await usersService.updateUsers(
      ownerOrgId,
      userId,
      cleanedData
    );

    showSuccessMessage({
      message: translate('EMPLOYEE_EDIT_SUCCESS_MESSAGE'),
    });

    if (newPassword && oldPassword) {
      await userService.changePassword(oldPassword, newPassword);

      showSuccessMessage({
        message: translate('EMPLOYEE_UPDATE_PASSWORD_SUCCESS_MESSAGE'),
      });
    }

    dispatch(
      createAction({
        type: USERS_UPDATE_INFO_SUCCESS,
        data: response?.data || {},
      })
    );
  } catch (error) {
    dispatch(
      createAction({
        type: USERS_UPDATE_INFO_FAILURE,
        error,
      })
    );
  }
};

export const usersActions = {
  fetchUser,
  fetchUsersList,
  updateEmployeeInfo,
};
