export default {
  DONOR_CATEGORY_EMPTY_TITLE: 'No donors in {title}',
  DONOR_CATEGORY_EMPTY_DESCRIPTION_0:
    'Donors you are currently researching, screening, and categorizing on inclination, capacity, and readiness.',
  DONOR_CATEGORY_EMPTY_DESCRIPTION_1:
    'Top donors you are evaluating based on gratest inclination, capacity, and readiness.',
  DONOR_CATEGORY_EMPTY_DESCRIPTION_2:
    'Donors that are in development, scheduling appointments, and receiving requests.',
  DONOR_CATEGORY_EMPTY_DESCRIPTION_3:
    'Follow up with donors to convey gratitude and provide ongoing insight into the image their gift will create.',
  DONOR_CLEAR_FILTERS: 'Clear Filters',
};
