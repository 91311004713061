import React from 'react';
import { Alert } from '@material-ui/lab';
import PropTypes from 'prop-types';
import { translate } from 'lib/intl';

export default class ReportErrorBoundary extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      hasError: false,
    };
  }

  static getDerivedStateFromError() {
    return {
      hasError: true,
    };
  }

  componentDidCatch(error, errorInfo) {
    const componentName = this.props.children.displayName || 'Report';

    // eslint-disable-next-line no-console
    console.error(`Error with rendering ${componentName}`, error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return <Alert severity="error">{translate('REPORTS_D3_ERROR')}</Alert>;
    }

    return this.props.children;
  }
}

ReportErrorBoundary.propTypes = {
  children: PropTypes.node.isRequired,
};
