/**
 * This is a "controller" TextField component, made to work with
 * react-hooks-form.
 */
import React from 'react';
import { object, string } from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Controller } from 'react-hook-form';
import TextField from '@material-ui/core/TextField';

const useStyles = makeStyles((theme) => ({
  required: {
    '& span': {
      display: 'none',
    },
    '&:after': {
      content: '""',
      width: '6px',
      height: '6px',
      background: theme.palette.error.main,
      display: 'inline-block',
      marginBottom: theme.spacing(0.25),
      marginLeft: theme.spacing(0.75),
      borderRadius: '50%',
    },
  },
}));

export default function ControlledTextField({
  control,
  defaultValue,
  name,
  rules,
  ...props
}) {
  const classes = useStyles();
  return (
    <Controller
      {...(defaultValue && { defaultValue })}
      name={name}
      control={control}
      rules={rules}
      render={(field) => (
        <TextField
          InputLabelProps={{
            classes: { required: classes.required },
          }}
          {...props}
          {...field}
        />
      )}
    />
  );
}

ControlledTextField.propTypes = {
  control: object.isRequired,
  defaultValue: string,
  name: string.isRequired,
  rules: object,
};

ControlledTextField.defaultProps = {
  defaultValue: '',
  rules: {},
};
